//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { ChangeEvent } from "react";
import { Button, TextArea, TextAreaProps } from "semantic-ui-react";
import { FlexBox } from "../../ui/lib";
import { ICommentInputFieldArgs } from "./CommentTypes";
import styled from "@emotion/styled";

export const StyledCommentInputField = styled(TextArea)`
  width: 100%;
  height: 50px;
  padding: 10px;
  line-height: 1.5;
`;

const StyledButton = styled(Button)`
  margin: 0 !important;
`;

const CommentInputField = ({
  commentCreationInProcess,
  comment,
  handleCommentChange,
  handleAddComment,
  showOnlyInputField = false,
  autoFocus = false,
}: ICommentInputFieldArgs) => {
  return (
    <FlexBox justify="space-around" width="100%">
      <StyledCommentInputField
        fluid
        autoFocus={autoFocus}
        disabled={commentCreationInProcess}
        value={comment}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
          const { value = "" } = data;
          handleCommentChange && handleCommentChange(value.toString());
        }}
      />
      {!showOnlyInputField && (
        <StyledButton basic onClick={handleAddComment} loading={commentCreationInProcess}>
          Add
        </StyledButton>
      )}
    </FlexBox>
  );
};

export default CommentInputField;
