//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Header } from "semantic-ui-react";
import { Organization as ApiOrganization } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";

interface IFreeToTryViewArgs {
  organization: ApiOrganization;
}

export const FreeToTryView = ({ ...args }: IFreeToTryViewArgs) => {
  // The typescript representation for total_deployments is a wrong.
  // In protobuf, it is a map<string, int32>.
  const totalDeployments = args.organization.total_deployments || {};
  const totalFreeDeployments = (totalDeployments as any)["free"] || 0;
  return (
    <>
      <Header sub>Free-to-try behavior</Header>
      <Field>
        <FL>Deployments created</FL>
        <FC>{totalFreeDeployments || "None"}</FC>
      </Field>
    </>
  );
};

export const FreeToTryColumnView = ({ ...args }: IFreeToTryViewArgs) => {
  // The typescript representation for total_deployments is a wrong.
  // In protobuf, it is a map<string, int32>.
  const totalDeployments = args.organization.total_deployments || {};
  const totalFreeDeployments = (totalDeployments as any)["free"] || 0;
  return <span>{totalFreeDeployments || "-"}</span>;
};
