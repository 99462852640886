//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { ErrorMessage, FlexBox, StyledInlineLoader } from "../../ui/lib";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";

export interface IConfigurationDiskSizeViewArgs extends CommentCreationArgs {
  title: string;
  canEdit: boolean;
  onEdit: () => void;
  editing: boolean;
  currentDiskSize: string;
  onSaveEdit: (newValue: string) => Promise<{ errorMessage: string }>;
  onCancelEdit: () => void;
  processingUpdate: boolean;
}

export const ConfigurationDiskSizeView = ({ ...args }: IConfigurationDiskSizeViewArgs) => {
  const [diskSize, setDiskSize] = useState<string>(args.currentDiskSize || "");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);
  const [showModal, setShowModal] = useState(args.editing);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDiskSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiskSize(e.target.value);
  };

  useEffect(() => {
    if (args.currentDiskSize !== diskSize) {
      setComment(`${args.title}: Update disk size to ${diskSize || "<empty-value>"} `);
    } else {
      setComment("");
    }
  }, [diskSize]);

  const saveDiskSizeChange = async () => {
    setLoading(true);
    setErrorMessage("");

    if (!commentCreationFailed) {
      let msg = await args.onSaveEdit(diskSize);
      if (msg.errorMessage) {
        setErrorMessage(msg.errorMessage);
        setLoading(false);
        return;
      }
    }

    setCommentCreationFailed(false);

    const { error: commentError } = await args.createComment(comment);

    if (commentError) {
      setCommentCreationFailed(true);
      setErrorMessage("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }

    setLoading(false);
    setShowModal(false);
  };

  return (
    <span>
      {args.currentDiskSize || "-"}
      {args.canEdit && (
        <span>
          <Icon
            name="pencil"
            onClick={() => {
              args.onEdit();
              setShowModal(true);
            }}
            className="edit-pencil"
          />
        </span>
      )}

      <Modal open={showModal} size="tiny">
        <Modal.Header>
          <FlexBox justify="space-between">
            <span>Edit disk size value for {args.title}</span>
            {loading && <StyledInlineLoader inline />}
          </FlexBox>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ErrorMessage active={!!commentCreationFailed || !!errorMessage} message={errorMessage} />
            <Form>
              <Form.Field>
                <label>Custom disk size (k8s format)</label>
                <Form.Input value={diskSize} onChange={handleDiskSizeChange} disabled={commentCreationFailed} />
              </Form.Field>
              <Form.Field required>
                <label>Provide the reason for this change</label>
                <CommentInputField
                  handleAddComment={() => {}}
                  commentCreationInProcess={false}
                  handleCommentChange={(value: string) => {
                    setComment(value);
                  }}
                  comment={comment}
                  showOnlyInputField
                />
                <sub>
                  Comment box will be empty if there is no change in the <b>Disk Size</b>. Otherwise, a default comment will be provided which can be changed if
                  required.
                </sub>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              setComment("");
              setCommentCreationFailed(false);
              setErrorMessage("");
              setLoading(false);
              args.onCancelEdit();
            }}
            negative
            content="Cancel"
          />
          {commentCreationFailed ? (
            <Button
              onClick={saveDiskSizeChange}
              positive
              labelPosition="right"
              icon="comment"
              content="Retry saving comment"
              loading={args.processingUpdate || loading}
              disabled={!comment.trim()}
            />
          ) : (
            <Button
              onClick={saveDiskSizeChange}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Save"
              loading={args.processingUpdate || loading}
              disabled={args.currentDiskSize === diskSize || !comment.trim()}
            />
          )}
        </Modal.Actions>
      </Modal>
    </span>
  );
};
