//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import moment from "moment";
import React from "react";
import { Header } from "semantic-ui-react";
import { Deployment_Status as ApiDeployment_Status } from "../../api/data/v1/data";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";

export interface IDeploymentStatusViewArgs {
  createdAt?: Date;
  status?: ApiDeployment_Status;
  isUpToDate?: boolean;
}

export const DeploymentStatusView = ({ ...args }: IDeploymentStatusViewArgs) => {
  const isBootstrapped = !!args.status && !!args.status.bootstrapped;
  const bootstrappedAt = args.status && args.status.bootstrapped_at ? moment(args.status.bootstrapped_at).fromNow() : "";
  const bootstrapDuration =
    args.createdAt && args.status && args.status.bootstrapped_at ? moment.duration(moment(args.status.bootstrapped_at).diff(args.createdAt)).humanize() : "?";
  return (
    <div>
      <Header sub>Database Status</Header>
      <Field>
        <FL>Bootstrapped</FL>
        <FC>
          {args.status ? (isBootstrapped ? "Yes" : "No") : "?"}
          {bootstrappedAt != "" && `, ${bootstrappedAt} (${bootstrapDuration})`}
        </FC>
      </Field>
      <Field>
        <FL>Description</FL>
        <FC>{args.status ? args.status.description : "?"}</FC>
      </Field>
      <Field>
        <FL>Servers created</FL>
        <FC>{args.status ? (args.status.created ? "Yes" : "No") : "?"}</FC>
      </Field>
      <Field>
        <FL>All servers and LB ready</FL>
        <FC>{args.status ? (args.status.ready ? "Yes" : "No") : "?"}</FC>
      </Field>
      <Field>
        <FL>Upgrading servers</FL>
        <FC>{args.status ? (args.status.upgrading ? "Yes" : "No") : "?"}</FC>
      </Field>
      <Field>
        <FL>Deployment Up-to-date</FL>
        <FC>{args.isUpToDate ? "Yes" : "No"}</FC>
      </Field>
    </div>
  );
};
