//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import { useEffect, useState } from "react";
import { SchedulingPolciesViewArgs } from "./types";
import { SchedulingPolicy } from "../../api/scheduling/v1/ischeduling";
import { Section, ErrorMessage, ContentSegment, SecondaryMenu, ObjectJSONModalView } from "../../ui/lib";
import { Loader, Menu, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListAllSchedulingPoliciesRequest } from "../../api/lib";

const SchedulingPoliciesView = (props: SchedulingPolciesViewArgs) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<SchedulingPolicy[]>([]);
  const [loading, setLoading] = useState(false);

  const getPolicies = async () => {
    setLoading(true);
    try {
      const req: ListAllSchedulingPoliciesRequest = {};
      const response = await apiClients.idashboardClient.ListAllSchedulingPolicies(req);
      const { items = [] } = response;
      setItems(items);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPolicies();
  }, []);

  return (
    <ContentSegment>
      <SecondaryMenu>
        <Menu.Item header>Scheduling Policies {loading ? <Loader size="mini" inline active /> : ""} </Menu.Item>
      </SecondaryMenu>
      <Section>
        <ErrorMessage active={!!error} onDismiss={() => setError(undefined)} message={error} />
      </Section>
      <Table stripped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((policy) => {
            return (
              <Table.Row key={policy.id}>
                <Table.Cell>
                  <ObjectJSONModalView value={policy} title={"Scheduling policy " + policy.id} text={policy.id || ""} />
                </Table.Cell>
                <Table.Cell>{policy.spec && policy.spec.name}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </ContentSegment>
  );
};
export default SchedulingPoliciesView;
