//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import _ from "lodash";
import { Dropdown, Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  SetOrganizationExcludedFromBillingRequest as ApiSetOrganizationExcludedFromBillingRequest,
  SetOrganizationUsesPurchaseOrdersRequest as ApiSetOrganizationUsesPurchaseOrdersRequest,
} from "../../api/billing/v1/ibilling";
import { IDOptions as ApiIDOptions, Organization as ApiOrganization } from "../../api/lib";
import { Confirm, ConfirmInfo, Field, FieldContent as FC, FieldLabelWide as FL, Processing } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IBillingFieldViewArgs extends IWithRefreshProps {
  organization: ApiOrganization;
  excludedFromBilling?: boolean;
  usesPurchaseOrders?: boolean;
  canEditExcludedFromBilling: boolean;
  canEditUsesPurchaseOrders: boolean;
  onSetExcludedFromBilling: (excluded: boolean) => void;
  onSetUsesPurchaseOrders: (usesPurchaseOrders: boolean) => void;
}

const BillingFieldView = ({ ...args }: IBillingFieldViewArgs) => {
  const has_excludedFromBilling = !_.isUndefined(args.excludedFromBilling);
  const has_usesPurchaseOrders = !_.isUndefined(args.usesPurchaseOrders);
  const excludedFromBilling = !!args.excludedFromBilling;
  const usesPurchaseOrders = !!args.usesPurchaseOrders;
  return (
    <Field>
      <FL>Billing</FL>
      <FC>
        <div>
          {!has_excludedFromBilling && <span>?</span>}
          {has_excludedFromBilling && <span>{excludedFromBilling ? "Excluded" : "Included"} </span>}
          {has_excludedFromBilling && excludedFromBilling && <Icon name="exclamation triangle" color="orange" />}
          {has_excludedFromBilling && args.canEditExcludedFromBilling && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {excludedFromBilling && <Dropdown.Item text="Include" onClick={() => args.onSetExcludedFromBilling(false)} />}
                {!excludedFromBilling && <Dropdown.Item text="Exclude" onClick={() => args.onSetExcludedFromBilling(true)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        {!excludedFromBilling && (
          <div>
            {!has_usesPurchaseOrders && <span>?</span>}
            {has_usesPurchaseOrders && <span>{usesPurchaseOrders ? "Uses purchase orders" : "Uses online payments"} </span>}
            {has_usesPurchaseOrders && (
              <Icon name={usesPurchaseOrders ? "money bill alternate" : "credit card"} color={usesPurchaseOrders ? "orange" : "black"} />
            )}
            {has_usesPurchaseOrders && args.canEditUsesPurchaseOrders && (
              <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
                <Dropdown.Menu>
                  {usesPurchaseOrders && <Dropdown.Item text="Use online payments" onClick={() => args.onSetUsesPurchaseOrders(false)} />}
                  {!usesPurchaseOrders && <Dropdown.Item text="Use purchase orders" onClick={() => args.onSetUsesPurchaseOrders(true)} />}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        )}
      </FC>
    </Field>
  );
};

interface IBillingFieldProps extends IOrganizationDetailsFieldProps {}

interface IBillingFieldState extends IOrganizationDetailsFieldState {
  excludedFromBilling?: boolean;
  usesPurchaseOrders?: boolean;
  confirmSetExcludedFromBilling?: ConfirmInfo;
  confirmSetUsesPurchaseOrders?: ConfirmInfo;
  processing: boolean;
}

class BillingField extends OrganizationDetailsField<IBillingFieldProps, IBillingFieldState> {
  state: IBillingFieldState = {
    excludedFromBilling: undefined,
    usesPurchaseOrders: undefined,
    confirmSetExcludedFromBilling: undefined,
    confirmSetUsesPurchaseOrders: undefined,
    processing: false,
    refreshNeeded: false,
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      if (this.hasPermission("internal-dashboard.organization.get-excluded-from-billing")) {
        const excludedFromBilling = await apiClients.idashboardClient.GetOrganizationExcludedFromBilling(idOptions);
        this.setState({ excludedFromBilling: !!excludedFromBilling.result });
      }

      if (this.hasPermission("internal-dashboard.organization.get-uses-purchase-orders")) {
        const usesPurchaseOrders = await apiClients.idashboardClient.GetOrganizationUsesPurchaseOrders(idOptions);
        this.setState({ usesPurchaseOrders: !!usesPurchaseOrders.result });
      }
    }
  };

  onSetExcludedFromBilling = async (excluded: boolean) => {
    const orgName = this.props.organization.name || "";
    const confirmInfo: ConfirmInfo = {
      header: "Change organization exclusion from billing",
      content: `Do you want to ${excluded ? "exclude" : "include"} the '${orgName}' organization from billing?`,
      warning: "This can only be done with explicit consent from management!",
      onConfirm: async () => {
        const req: ApiSetOrganizationExcludedFromBillingRequest = {
          organization_id: this.props.organization.id,
          excluded: excluded,
        };
        try {
          this.setState({ processing: true, confirmSetExcludedFromBilling: undefined });
          await apiClients.idashboardClient.SetOrganizationExcludedFromBilling(req);
          this.refreshOrganizationInfo();
        } catch (e) {
          this.props.onError(`Changing organization excluded from billing failed: ${e}`);
        } finally {
          this.setState({ processing: false });
        }
      },
      onDenied: () => {
        this.setState({ confirmSetExcludedFromBilling: undefined });
      },
    };
    this.setState({
      confirmSetExcludedFromBilling: confirmInfo,
    });
  };

  onSetUsesPurchaseOrders = async (usesPurchaseOrders: boolean) => {
    const orgName = this.props.organization.name || "";
    const confirmInfo: ConfirmInfo = {
      header: "Change organization usage of purchase orders",
      content: `Do you want to set the '${orgName}' organization to ${
        usesPurchaseOrders ? "use purchase orders" : "not use purchase orders"
      } the '${orgName}' for billing?`,
      warning: "This can only be done with explicit consent from management!",
      onConfirm: async () => {
        const req: ApiSetOrganizationUsesPurchaseOrdersRequest = {
          organization_id: this.props.organization.id,
          uses_purchase_order: usesPurchaseOrders,
        };
        try {
          this.setState({ processing: true, confirmSetUsesPurchaseOrders: undefined });
          await apiClients.idashboardClient.SetOrganizationUsesPurchaseOrders(req);
          this.refreshOrganizationInfo();
        } catch (e) {
          this.props.onError(`Changing organizations use of purchase orders for billing failed: ${e}`);
        } finally {
          this.setState({ processing: false });
        }
      },
      onDenied: () => {
        this.setState({ confirmSetUsesPurchaseOrders: undefined });
      },
    };
    this.setState({
      confirmSetUsesPurchaseOrders: confirmInfo,
    });
  };

  render() {
    const can_edit_excluded_from_billing = this.hasPermission("internal-dashboard.organization.manage-excluded-from-billing");
    const can_edit_uses_purchase_orders = this.hasPermission("internal-dashboard.organization.manage-uses-purchase-orders");

    return (
      <div>
        <Confirm confirmInfo={this.state.confirmSetExcludedFromBilling} />
        <Confirm confirmInfo={this.state.confirmSetUsesPurchaseOrders} />
        <Processing active={this.state.processing} message="Changing organization billing settings, please wait..." />
        <BillingFieldView
          {...this}
          {...this.props}
          {...this.state}
          canEditExcludedFromBilling={can_edit_excluded_from_billing}
          canEditUsesPurchaseOrders={can_edit_uses_purchase_orders}
          onSetExcludedFromBilling={this.onSetExcludedFromBilling}
          onSetUsesPurchaseOrders={this.onSetUsesPurchaseOrders}
        />
      </div>
    );
  }
}

export default withRefresh()(BillingField);
