//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Button, Loader, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { DataProcessingAddendum as ApiDataProcessingAddendum } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, LoaderBox, MenuActionBack, SecondaryMenu, Processing, Section, SectionContent, ErrorMessage } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { EditorView } from "./DataProcessingAddendumEditor";

interface ICreateDataProcessingAddendumProps extends IWithRefreshProps, RouteComponentProps {
  onCreated: () => void;
  onCancel: () => void;
}

interface IDataProcessingAddendumState {
  dpa: ApiDataProcessingAddendum;
  saving: boolean;
  errorMessage?: string;
}

// The component to create new DPA
class CreateDataProcessingAddendum extends Component<ICreateDataProcessingAddendumProps, IDataProcessingAddendumState> {
  state: IDataProcessingAddendumState = {
    dpa: {},
    saving: false,
  };

  hasPermission = (p: Permission) => hasSupportPermission(p, this.props.hasPermissionByUrl);

  onContentChange = (content: string) => {
    this.setState({
      dpa: {
        content: content,
      },
    });
  };

  onSave = async () => {
    try {
      this.setState({ saving: true, errorMessage: undefined });
      await apiClients.idashboardClient.CreateDataProcessingAddendum(this.state.dpa);
      this.props.onCreated();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>New Data Processing Addendum</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
          <Processing active={this.state.saving} message="Creating Data Processing Addendum..." />
          <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        </SecondaryMenu>
        <div>
          <Section>
            <SectionContent>
              <EditorView {...this.props} {...this.state} dpa={this.state.dpa} onContentChange={this.onContentChange} />
              <Button icon="check" content="Save" primary onClick={this.onSave} />
              <Button content="Cancel" onClick={this.props.onCancel} />
            </SectionContent>
          </Section>
        </div>
      </ContentSegment>
    );
  }
}

export default withRefresh()(CreateDataProcessingAddendum);
