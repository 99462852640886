//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import React from "react";
import { Header, Modal, Loader } from "semantic-ui-react";

interface IProcessingViewArgs {
  active: boolean;
  message?: string;
}

export const Processing = ({ ...args }: IProcessingViewArgs) => {
  if (args.active)
    return (
      <Modal defaultOpen={true} basic size="small">
        <Header content={args.message || "Processing..."} />
        <Loader />
      </Modal>
    );
  return null;
};
