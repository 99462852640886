//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//

import { ChangeEvent, Component, useState } from "react";
import { Modal, Button, Form, InputOnChangeData, TextAreaProps, Divider } from "semantic-ui-react";
import { StyledCommentInputField } from "../dashboard/comments/CommentInputField";

export class ConfirmInfo {
  public header?: string;
  public content?: string;
  public warning?: string;
  public confirm?: string;
  public invertPositiveNegative?: boolean;
  public showCommentBox?: boolean;
  public reasonRequired?: boolean;
  public onConfirm?: (reason?: string) => void;
  public onDenied?: () => void;
}

interface IConfirmViewArgs extends IConfirmProps, IConfirmState {
  updateConfirmString: (newValue: string) => void;
  clearConfirmString: () => void;
}

export const ConfirmView = ({ ...args }: IConfirmViewArgs) => {
  const [comment, setComment] = useState<string | undefined>("");
  if (args.confirmInfo) {
    return (
      <Modal defaultOpen={true} closeOnEscape={false} closeOnDimmerClick={false} onUnmount={args.clearConfirmString}>
        <Modal.Header content={args.confirmInfo.header || "Please confirm"} />
        <Modal.Content>
          {args.confirmInfo.content || "Are you sure?"}
          {args.confirmInfo.warning && (
            <div>
              <br />
              <b>Notice: {args.confirmInfo.warning}</b>
            </div>
          )}
          <Form>
            {args.confirmInfo.confirm && (
              <div>
                <br />
                <Form.Input
                  autoFocus
                  autoComplete="off"
                  required
                  label={`Enter '${args.confirmInfo.confirm}' to confirm `}
                  placeholder={args.confirmInfo.confirm}
                  name="confirm"
                  value={args.confirmString}
                  onChange={(e: any, id: InputOnChangeData) => {
                    args.updateConfirmString(id.value);
                  }}
                />
              </div>
            )}
            {args.confirmInfo.showCommentBox && (
              <Form.Field required={args.confirmInfo.reasonRequired}>
                <Divider hidden />
                <label>Reason for this action </label>
                <StyledCommentInputField
                  fluid
                  autoFocus
                  value={comment}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                    setComment(data.value as string);
                  }}
                />
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={args.confirmInfo.onDenied}
            negative={!args.confirmInfo.invertPositiveNegative}
            positive={args.confirmInfo.invertPositiveNegative}
            content="No"
          />
          <Button
            onClick={() => {
              args.confirmInfo && args.confirmInfo.onConfirm && args.confirmInfo.onConfirm(comment);
              setComment("");
            }}
            disabled={!args.confirmEnabled || (args.confirmInfo.reasonRequired && !comment)}
            negative={args.confirmInfo.invertPositiveNegative}
            positive={!args.confirmInfo.invertPositiveNegative}
            labelPosition="right"
            icon="checkmark"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

interface IConfirmProps {
  confirmInfo?: ConfirmInfo;
}

interface IConfirmState {
  confirmString?: string;
  confirmEnabled: boolean;
}

export class Confirm extends Component<IConfirmProps, IConfirmState> {
  state: IConfirmState = {
    confirmEnabled: (this.props.confirmInfo && !this.props.confirmInfo.confirm) || false,
  };

  updateConfirmString = (newValue: string) => {
    let confirmEnabled = false;
    if (this.props.confirmInfo && this.props.confirmInfo.confirm) {
      confirmEnabled = this.props.confirmInfo.confirm == newValue;
    }
    this.setState({
      confirmString: newValue,
      confirmEnabled: confirmEnabled,
    });
  };

  clearConfirmString = () => {
    this.setState({ confirmString: undefined });
  };

  static getDerivedStateFromProps(props: IConfirmProps, state: IConfirmState) {
    const confirmInfo = props.confirmInfo || {};
    const confirmEnabled = !confirmInfo.confirm || confirmInfo.confirm == state.confirmString;
    if (confirmEnabled != state.confirmEnabled) {
      return {
        confirmEnabled: confirmEnabled,
      };
    }
    // No state update necessary
    return null;
  }

  render() {
    return <ConfirmView {...this.props} {...this.state} updateConfirmString={this.updateConfirmString} clearConfirmString={this.clearConfirmString} />;
  }
}
