//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import styled from "@emotion/styled";
import { Dropdown, Loader, Menu, Segment } from "semantic-ui-react";
import { css } from "@emotion/core";

export const FieldSet = styled(Segment)`
  padding-top: 0.3em;
  padding-bottom: 0.3em;
`;

export const Field = styled("div")`
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  clear: both;
`;

export const FieldLabel = styled("span")`
  width: 9rem;
  display: inline-block;
  float: left;
`;

export const FieldLabelWide = styled("span")`
  width: 18rem;
  display: inline-block;
  float: left;
`;

export const FieldContent = styled("div")`
  display: inline-block;
`;

export const FieldIcons = styled("div")`
  float: right;
`;

export const LoaderBox = styled("span")`
  float: right;
  width: 0;
  padding-right: 1em;
  margin-right: 1em;
  margin-top: 1em;
  max-width: 0;
  display: inline-block;
`;

export const LoaderBoxForTable = styled("span")`
  float: right;
  width: 0;
  padding-right: 1em;
  max-width: 0;
  display: inline-block;
`;

export const StyledMenu = styled(Menu)`
  width: 15rem !important;
  @media (max-width: 768px) {
    width: 10rem !important;
  }
`;

export const StyledContentBox = styled("div")`
  margin-left: 15rem;
  @media (max-width: 768px) {
    margin-left: 10rem;
  }
`;

export const ContentBelowMenu = styled("div")`
  margin-top: 4em;
`;

export const Footer = styled("div")`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.2em;
  height: 2em;
  text-align: center;
  background-color: #444;
  color: #e8e8e8;
`;

export const SidebarPage = styled("div")`
  position: fixed;
  left: 0;
  width: 100%;
  top: 40px;
  bottom: 0;
`;

export const SidebarMenu = styled(Menu)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0 !important;
  min-height: 100%;
  overflow-y: auto;
  width: 15rem !important;
  @media (max-width: 768px) {
    width: 10rem !important;
  }
`;

export const SidebarContent = styled("div")`
  margin-left: 15rem;
  overflow-y: scroll;
  min-height: 100%;
  max-height: 100%;
  @media (max-width: 768px) {
    margin-left: 10rem;
  }
`;

export const ReportContainer = styled("div")`
  width: 100vh;
  height: 80vh;
  min-width: 640px;
  min-height: 450px;
`;

export const ContentGroup = styled("div")``;

const objektivFont = css`
  font-family: "objektiv-mk1";
`;

const leftMarginHeaderContent = css`
  margin-left: calc(6.25% + 8px);
  @media (max-width: 768px) {
    margin-left: calc(6.25% + 8px);
  }
`;

const yellowUnderlineBlock = css`
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  background-color: #e8ec5d;
`;

export const CommonKeyValuePairs = styled("div")`
  ${leftMarginHeaderContent}
  margin-top: 24px;
`;

export const CommonKeyValuePair = styled("div")`
  margin-right: 16px;
  display: inline-block;
`;

export const CommonKV_Key = styled("div")`
  ${objektivFont}
  color: #879191;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  &::after {
    ${yellowUnderlineBlock}
  }
`;

export const CommonKV_Value = styled("div")`
  ${objektivFont}
  height: 28px;
  color: #272a2a;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
`;

export const StyledStatsSegment = styled(Segment)`
  .statistic .value span {
    font-size: inherit;
  }
  .statistic .label .icon {
    margin-left: 8px;
  }
`;

export const StatisticsContentGroup = styled(ContentGroup)`
  margin-bottom: 0;
  h3 {
    margin-bottom: 24px;
  }
`;

// ContentSegment renders a segment that is to be used as the content part of a page.
interface IChildrenView {
  children?: any;
}
const contentSegment = ({ children }: IChildrenView) => <Segment basic>{children}</Segment>;
export const ContentSegment = styled(contentSegment)``;

// SecondaryMenu renders menu that is to be used in the top of the content part of a page.
const secondaryMenu = ({ children }: IChildrenView) => (
  <Menu pointing secondary>
    {children}
  </Menu>
);
export const SecondaryMenu = styled(secondaryMenu)``;

export const SubID = styled("div")`
  font-size: 80%;
  font-style: oblique;
`;

export const StyledInlineLoader = styled(Loader)`
  &::before {
    border-color: #e9e9e9;
  }
  &::after {
    border-color: #577138 transparent transparent !important;
  }
`;

export const InlineDropdown = styled(Dropdown)`
  display: inline-block;
`;
