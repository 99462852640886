//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import apiClients from "../../api/apiclients";
import { Loader } from "semantic-ui-react";
import { DeploymentCount as ApiDeploymentCount, ListAllDeploymentsRequest as ApiListAllDeploymentsRequest } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";

export interface IRegionStatsViewArgs extends RouteComponentProps {
  loading: boolean;
  count?: ApiDeploymentCount;
}

export const RegionStatsView = ({ ...args }: IRegionStatsViewArgs) => {
  if (!args.count) {
    return <Loader size="mini">Loading</Loader>;
  }
  return <div>{args.count.count || 0}</div>;
};

interface IRegionStatsProps extends IWithRefreshProps, RouteComponentProps {
  region_id: string;
}

interface IRegionStatsState {
  prev_region_id: string;
  refreshNeeded: boolean;
  count?: ApiDeploymentCount;
}

// The component to show statistics of a region.
class RegionStats extends Component<IRegionStatsProps, IRegionStatsState> {
  state: IRegionStatsState = {
    prev_region_id: "",
    refreshNeeded: false,
    count: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadCount = async () => {
    var req: ApiListAllDeploymentsRequest = {
      region_id: this.props.region_id,
    };
    const count = await apiClients.idashboardClient.CountDeployments(req);
    this.setState({
      count: count,
    });
  };

  refreshCount = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCount);
  };

  static getDerivedStateFromProps(props: IRegionStatsProps, state: IRegionStatsState) {
    if (props.region_id !== state.prev_region_id) {
      return {
        refreshNeeded: true,
        prev_region_id: props.region_id || "",
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshCount();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshCount);
    }
  }

  render() {
    return <RegionStatsView {...this.props} {...this.state} />;
  }
}

export default withRefresh()(RegionStats);
