//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { ITabMenuItem } from "./TabTypes";

export const StyledTabMenuItem = styled.div<ITabMenuItem>`
  padding: 12px 24px;
  cursor: pointer;
  font-weight: ${(props) => (props.isActive ? "700" : "normal")};
  border-bottom: 2px solid;
  border-color: ${(props) => (props.isActive ? "#8a9e56 " : "transparent")};
  flex-shrink: 0;
  display: block;

  ${(props) => {
    const { align } = props;
    if (!align) {
      return "";
    }
    if (align === "left") {
      return css`
        margin-right: auto;
      `;
    }
    if (align === "right") {
      return css`
        margin-left: auto;
      `;
    }
  }}
`;

export const StyledLinkTabMenuItem = styled(Link)<ITabMenuItem>`
  padding: 12px 24px;
  cursor: pointer;
  font-weight: ${(props) => (props.isActive ? "700" : "normal")};
  border-bottom: 2px solid;
  border-color: ${(props) => (props.isActive ? "#8a9e56" : "transparent")};
  flex-shrink: 0;
  display: block;

  ${(props) => {
    const { align } = props;
    if (!align) {
      return "";
    }
    if (align === "left") {
      return css`
        margin-right: auto;
      `;
    }
    if (align === "right") {
      return css`
        margin-left: auto;
      `;
    }
  }}
`;

export const StyledTabMenuGroup = styled.div`
  border-bottom: 2px solid rgba(17, 18, 19, 0.15);
  height: 45px;
  margin: 0 auto;
  backgroundn: red;
`;

export const StyledTabContent = styled.div`
  margin-top: 24px;
`;

export const StyledOverflowTabMenuItem = styled(Dropdown)`
  position: absolute !important;
  right: 0;
  top: -6px;
  z-index: 999;

  & > i {
    height: 45px;
    line-height: 52px;
    width: 30px;
  }

  & .tab-menu-item {
    border-color: transparent !important;

    & > .item.tab-header {
      padding: 4px 24px;
    }

    &:hover > .item {
      bakground: #f5f5f5;
    }
  }

  &:hover {
    background: #f5f5f5;
  }
`;
