//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import copy from "copy-to-clipboard";
import moment from "moment";
import { concat, slice } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import apiClients, { Cached as cachedApiClients } from "../../api/apiclients";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import {
  Common,
  UpdateDeploymentAnnotationsRequest as ApiUpdateDeploymentAnnotationsRequest,
  KeyValuePair as ApiKeyValuePair,
  Backup as ApiBackup,
  CreateBackupRequest as ApiCreateBackupRequest,
  BackupInfoList as ApiBackupInfoList,
  BackupPolicyInfoList as ApiBackupPolicyInfoList,
  DeploymentCredentials as ApiDeploymentCredentials,
  DeploymentCredentialsRequest as ApiDeploymentCredentialsRequest,
  DeploymentInfo as ApiDeploymentInfo,
  IDOptions as ApiIDOptions,
  ListBackupPoliciesRequest as ApiListBackupPoliciesRequest,
  ListBackupInfosRequest as ApiListBackupInfosRequest,
  Organization as ApiOrganization,
  PlanList as ApiSupportPlanList,
  Project as ApiProject,
  RegionDetails as ApiRegionDetails,
  SetDeploymentExpirationDateRequest as ApiSetDeploymentExpirationDateRequest,
  UpdateDeploymentCustomCommandLineArgumentsRequest as ApiUpdateDeploymentCustomCommandLineArgumentsRequest,
  UpdateDeploymentEndpointAllowlistsRequest as ApiUpdateDeploymentEndpointAllowlistsRequest,
  UpdateDeploymentHibernationModeRequest as ApiUpdateDeploymentHibernationModeRequest,
  UpdateDeploymentMaintenanceModeRequest as ApiUpdateDeploymentMaintenanceModeRequest,
  UpdateDeploymentAllowTrafficRequest as ApiUpdateDeploymentAllowTrafficRequest,
  UpdateDeploymentSupportPlanRequest as ApiUpdateDeploymentSupportPlanRequest,
  UpdateDeploymentVersionRequest as ApiUpdateDeploymentVersionRequest,
  UpdateDeploymentCPURatioRequest as ApiUpdateDeploymentCPURatioRequest,
  UpdateDeploymentMemoryRatioRequest as ApiUpdateDeploymentMemoryRatioRequest,
  UpdateDeploymentReplicationRequest as ApiUpdateDeploymentReplicationRequest,
  RotateDeploymentServerRequest as ApiRotateDeploymentServerRequest,
  UpdateDeploymentKubeArangodbRequest as ApiUpdateDeploymentKubeArangodbRequest,
  UpdateDeploymentLoadBalancerRequest as ApiUpdateDeploymentLoadBalancerRequest,
  NodeSizeDetails as ApiNodeSizeDetails,
  RotateDeploymentCredentialsRequest as ApiRotateDeploymentCredentialsRequest,
  UpdateDeploymentAgencyResourcesFactorRequest as ApiUpdateDeploymentAgencyResourcesFactorRequest,
  UpdateDeploymentCommunicationMethodRequest as ApiUpdateDeploymentCommunicationMethodRequest,
  UpdateDeploymentFoxxAuthenticationRequest as ApiUpdateDeploymentFoxxAuthenticationRequest,
  GetDeploymentEmailAddressesRequest as ApiGetDeploymentEmailAddressesRequest,
  DeploymentEmailAddresses as ApiDeploymentEmailAddresses,
  UpdateDeploymentMemoryReservesRequest as ApiUpdateDeploymentMemoryReservesRequest,
  UpdateDeploymentTopologyAwarenessRequest as ApiUpdateDeploymentTopologyAwarenessRequest,
  CloneDeploymentFromBackupRequest as ApiCloneDeploymentFromBackupRequest,
  VolumeInfo as ApiVolumeInfo,
  UpdateDeploymentVolumeInfosRequest as ApiUpdateDeploymentVolumeInfosRequest,
  FinalizerRequest as ApiFinalizerRequest,
  Version as ApiVersion,
  ListOptions as ApiListOptions,
  UpdateDeploymentSuppressAlertsRequest as ApiUpdateDeploymentSuppressAlertsRequest,
  UpdateDeploymentUseShardRebalancerRequest as ApiUpdateDeploymentUseShardRebalancerRequest,
  UpdateKubernetesResourcesPatchesRequest as ApiUpdateKubernetesResourcesPatchesRequest,
  SetKubeArangodbAdditionalCommandLineArgumentsRequest as ApiSetKubeArangodbAdditionalCommandLineArgumentsRequest,
  UpdateDeploymentCPUFactorsRequest as ApiUpdateDeploymentCPUFactorsRequest,
  UpdateDeploymentMemoryFactorsRequest as ApiUpdateDeploymentMemoryFactorsRequest,
  UpdateDeploymentMemoryFactorsRequest,
  SetDeploymentSchedulingPoliciesRequest as ApiSetDeploymentSchedulingPoliciesRequest,
  SetDeploymentSchedulingPoliciesEnabledRequest as ApiSetDeploymentSchedulingPoliciesEnabledRequest,
  UpdateBackupPolicyAllowInconsistentRequest as ApiUpdateBackupPolicyAllowInconsistentRequest,
  UpdateBackupPolicyUploadIncrementalRequest as ApiUpdateBackupPolicyUploadIncrementalRequest,
  SetDeploymentPublicBackupsDisabledRequest as ApiSetDeploymentPublicBackupsDisabledRequest,
  UpdateDeploymentDiskSizeRequest as ApiUpdateDeploymentDiskSizeRequest,
  SetCustomNotebookImageRequest as ApiSetCustomNotebookImageRequest,
  UpdateDeploymentToUseJetStreamForAuditLogsRequest as ApiUpdateDeploymentToUseJetStreamForAuditLogsRequest,
} from "../../api/lib";
import Auth from "../../auth/Auth";
import { ConfirmInfo, Loading } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import DeploymentDetailsTabView from "./DeploymentDetailsTabView";
import { ListTokensRequest as ApiListTokensRequest, Token as ApiToken } from "../../api/metrics/v1/metrics";
import {
  CommentList as ApiCommentList,
  CreateCommentRequest as ApiCreateCommentRequest,
  ListCommentsRequest as ApiListCommentsRequest,
} from "../../api/comment/v1/icomment";
import { ICommentPromptStateForIntegrations, ICommentsStateForIntegration } from "../comments/CommentTypes";
import CommentsPrompt from "../comments/CommentsPrompt";
import { AsyncResult } from "../../util/Types";

// Interface decribing the properties of the deployment details component
interface IDeploymentDetailsProps extends IWithRefreshProps, RouteComponentProps {
  api: IAPI;
  onProjectSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onAuditLogSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  auth: Auth;
}

// Interface used to abstract deployment fetching (for sharing between dashboard & idashboard).
interface IAPI {
  GetDeployment(req: ApiIDOptions): Promise<ApiDeploymentInfo>;
  GetDeploymentRootCredentials(req: ApiDeploymentCredentialsRequest): Promise<ApiDeploymentCredentials>;
  GetNodeSizeDetails(req: ApiIDOptions): Promise<ApiNodeSizeDetails>;
  GetRegionDetails(req: ApiIDOptions): Promise<ApiRegionDetails>;
  GetProject(req: ApiIDOptions): Promise<ApiProject>;
  GetOrganization(req: ApiIDOptions): Promise<ApiOrganization>;
  SetDeploymentExpirationDate(req: ApiSetDeploymentExpirationDateRequest): Promise<void>;
  SetKubeArangodbAdditionalCommandLineArguments(req: ApiSetKubeArangodbAdditionalCommandLineArgumentsRequest): Promise<void>;
  UpdateDeploymentHibernationMode(req: ApiUpdateDeploymentHibernationModeRequest): Promise<void>;
  UpdateDeploymentMaintenanceMode(req: ApiUpdateDeploymentMaintenanceModeRequest): Promise<void>;
  UpdateDeploymentAllowTraffic(req: ApiUpdateDeploymentAllowTrafficRequest): Promise<void>;
  UpdateDeploymentAgencyMaintenanceMode: (req: ApiUpdateDeploymentMaintenanceModeRequest) => Promise<void>;
  UpdateDeploymentEndpointAllowlists(req: ApiUpdateDeploymentEndpointAllowlistsRequest): Promise<void>;
  UpdateDeploymentCustomCommandLineArguments(req: ApiUpdateDeploymentCustomCommandLineArgumentsRequest): Promise<void>;
  UpdateDeploymentVersion(req: ApiUpdateDeploymentVersionRequest): Promise<void>;
  UpdateDeploymentCPURatio(req: ApiUpdateDeploymentCPURatioRequest): Promise<void>;
  UpdateDeploymentMemoryRatio(req: ApiUpdateDeploymentMemoryRatioRequest): Promise<void>;
  UpdateDeploymentReplication(req: ApiUpdateDeploymentReplicationRequest): Promise<void>;
  UpdateDataClusterAssignmentProvisionHash(req: ApiIDOptions): Promise<void>;
  UpdateDeploymentSupportPlan(req: ApiUpdateDeploymentSupportPlanRequest): Promise<void>;
  UpdateDeploymentAgencyResourcesFactor: (req: ApiUpdateDeploymentAgencyResourcesFactorRequest) => Promise<void>;
  UpdateDeploymentCommunicationMethod: (req: ApiUpdateDeploymentCommunicationMethodRequest) => Promise<void>;
  UpdateDeploymentFoxxAuthentication: (req: ApiUpdateDeploymentFoxxAuthenticationRequest) => Promise<void>;
  UpdateDeploymentMemoryReserves: (req: ApiUpdateDeploymentMemoryReservesRequest) => Promise<void>;
  UpdateDeploymentTopologyAwareness: (req: ApiUpdateDeploymentTopologyAwarenessRequest) => Promise<void>;
  UpdateDeploymentOperatorMaintenanceMode: (req: ApiUpdateDeploymentMaintenanceModeRequest) => Promise<void>;
  UpdateDeploymentSuppressAlerts: (req: ApiUpdateDeploymentSuppressAlertsRequest) => Promise<void>;
  UpdateDeploymentUseShardRebalancer: (req: ApiUpdateDeploymentUseShardRebalancerRequest) => Promise<void>;
  UpdateKubernetesResourcesPatches: (req: ApiUpdateKubernetesResourcesPatchesRequest) => Promise<void>;
  UpdateDeploymentMemoryFactors: (req: ApiUpdateDeploymentMemoryFactorsRequest) => Promise<void>;
  UpdateDeploymentCPUFactors: (req: ApiUpdateDeploymentCPUFactorsRequest) => Promise<void>;
  SetDeploymentSchedulingPolicies: (req: ApiSetDeploymentSchedulingPoliciesRequest) => Promise<void>;
  SetDeploymentSchedulingPoliciesEnabled: (req: ApiSetDeploymentSchedulingPoliciesRequest) => Promise<void>;
  SetDeploymentPublicBackupsDisabled: (req: ApiSetDeploymentPublicBackupsDisabledRequest) => Promise<void>;
  UpdateDeploymentDiskSize: (req: ApiUpdateDeploymentDiskSizeRequest) => Promise<void>;
  UpdateDeploymentToUseJetStreamForAuditLogs: (req: ApiUpdateDeploymentToUseJetStreamForAuditLogsRequest) => Promise<void>;
}

// Interface decribing the state of the deployment details component
interface IDeploymentDetailsState extends ICommentsStateForIntegration {
  deploymentId?: string;
  deploymentInfo?: ApiDeploymentInfo;
  nodeSizeDetails?: ApiNodeSizeDetails;
  regionDetails?: ApiRegionDetails;
  project?: ApiProject;
  organization?: ApiOrganization;
  copiedRootPassword: boolean;
  loadingRootPassword: boolean;
  processingSetExpirationDate: boolean;
  processingUpdateHibernationMode: boolean;
  processingUpdateMaintenanceMode: boolean;
  processingUpdateAllowTraffic: boolean;
  processingUpdateDCUpdateMode: boolean;
  processingAllowlistedEndpoints: boolean;
  processingCustomCommandLineArguments: boolean;
  processingUpdateVersion: boolean;
  processingUpdateProvisionHash: boolean;
  processingUpdateSupportPlan: boolean;
  processingRotateCredentials: boolean;
  confirmUpdateChaosLevel?: ConfirmInfo;
  processingUpdateChaosLevel: boolean;
  processingBackup: boolean;
  processingBackupPolicy: boolean;
  processingResumeDeployment: boolean;
  processingUpdateSuppressAlerts: boolean;
  processingUpdateUseShardRebalancer: boolean;
  errorMessage?: string;
  showRootPasswordRequestModal: boolean;
  rootPasswordRequestReason: string;
  allowed_endpoints?: string[];

  backups?: ApiBackupInfoList;
  backupsPage: number;
  backupsPageSize: number;
  lastGoodBackups?: ApiBackupInfoList;
  backupPolicies?: ApiBackupPolicyInfoList;

  createBackup: boolean;
  editBackup: boolean;
  editBackupId?: string;
  name: string;
  description: string;
  autoDeleteUpload: boolean;
  autoDeleteUploadInDays: number;
  autoDeleteNoUploadInHours: number;
  uploadBackup: boolean;
  allowInconsistentBackup: boolean;
  isInternalBackup: boolean;
  confirmBackup?: ConfirmInfo;

  agents_custom_command_line_args?: string[];
  dbservers_custom_command_line_args?: string[];
  coordinators_custom_command_line_args?: string[];
  supportPlans?: ApiSupportPlanList;
  confirmSetSupportPlan?: ConfirmInfo;
  rotatingServerID?: string;
  confirmRedeleteDeployment?: ConfirmInfo;
  processingDeploymentRedelete: boolean;
  editArangodbImage: boolean;
  editKubeArangodbImage: boolean;
  editNotebookImage: boolean;
  kubeArangodbImage?: string;
  notebookImage?: string;
  processingUpdateArangodbImage: boolean;
  processingUpdateKubeArangodbImage: boolean;
  processingUpdateNotebookImage: boolean;
  processingUpdateCPURatio: boolean;
  processingUpdateMemoryRatio: boolean;
  processingUpdateReplication: boolean;
  processingUpdateLoadBalancer: boolean;
  confirmRotateCredentials?: ConfirmInfo;
  processingUpdateAgencyResourcesFactor: boolean;
  processingUpdateCommunicationMethod: boolean;
  processingUpdateFoxxAuthentication: boolean;
  deploymentEmails: ApiDeploymentEmailAddresses;
  editAgentsVolumeInfos: boolean;
  editDBServersVolumeInfos: boolean;
  editDiskPerformance: boolean;
  processingUpdateVolumeInfos: boolean;
  processingDiskPerformanceLocked: boolean;
  processingDiskPerformance: boolean;
  processingRemoveFinalizer: boolean;
  versions?: ApiVersion[];
  metricsTokens?: ApiToken[];
  commentPrompt: ICommentPromptStateForIntegrations;
}

class DeploymentDetails extends Component<IDeploymentDetailsProps, IDeploymentDetailsState> {
  state: IDeploymentDetailsState = {
    deploymentId: undefined,
    nodeSizeDetails: undefined,
    project: undefined,
    regionDetails: undefined,
    organization: undefined,
    copiedRootPassword: false,
    loadingRootPassword: false,
    processingSetExpirationDate: false,
    processingUpdateHibernationMode: false,
    processingUpdateMaintenanceMode: false,
    processingUpdateAllowTraffic: false,
    processingUpdateDCUpdateMode: false,
    processingAllowlistedEndpoints: false,
    processingCustomCommandLineArguments: false,
    processingUpdateVersion: false,
    processingUpdateProvisionHash: false,
    processingUpdateSupportPlan: false,
    processingRotateCredentials: false,
    confirmUpdateChaosLevel: undefined,
    processingUpdateChaosLevel: false,
    processingBackup: false,
    processingBackupPolicy: false,
    processingResumeDeployment: false,
    processingUpdateSuppressAlerts: false,
    processingUpdateUseShardRebalancer: false,
    errorMessage: undefined,
    showRootPasswordRequestModal: false,
    rootPasswordRequestReason: "",
    allowed_endpoints: undefined,

    backups: undefined,
    backupsPage: 0,
    backupsPageSize: 10,
    lastGoodBackups: undefined,
    backupPolicies: undefined,

    createBackup: false,
    editBackup: false,
    editBackupId: undefined,
    name: "",
    description: "",
    autoDeleteUpload: false,
    autoDeleteUploadInDays: 31,
    autoDeleteNoUploadInHours: 6,
    uploadBackup: true,
    allowInconsistentBackup: false,
    isInternalBackup: true,
    confirmBackup: undefined,

    agents_custom_command_line_args: undefined,
    dbservers_custom_command_line_args: undefined,
    coordinators_custom_command_line_args: undefined,
    supportPlans: undefined,
    confirmSetSupportPlan: undefined,
    rotatingServerID: undefined,
    confirmRedeleteDeployment: undefined,
    processingDeploymentRedelete: false,
    editArangodbImage: false,
    editKubeArangodbImage: false,
    editNotebookImage: false,
    kubeArangodbImage: undefined,
    notebookImage: undefined,
    processingUpdateArangodbImage: false,
    processingUpdateKubeArangodbImage: false,
    processingUpdateNotebookImage: false,
    processingUpdateCPURatio: false,
    processingUpdateMemoryRatio: false,
    processingUpdateReplication: false,
    processingUpdateLoadBalancer: false,
    confirmRotateCredentials: undefined,
    processingUpdateAgencyResourcesFactor: false,
    processingUpdateCommunicationMethod: false,
    processingUpdateFoxxAuthentication: false,
    deploymentEmails: {},
    editAgentsVolumeInfos: false,
    editDBServersVolumeInfos: false,
    editDiskPerformance: false,
    processingUpdateVolumeInfos: false,
    processingDiskPerformanceLocked: false,
    processingDiskPerformance: false,
    processingRemoveFinalizer: false,
    versions: undefined,
    metricsTokens: undefined,
    commentList: {},
    commentsLoading: false,
    commentsPageOptions: {
      page: 0,
      pageSize: 10,
    },
    commentsFetchError: undefined,
    commentCreationInProcess: false,
    latestComments: {},
    lastestCommentsTriggered: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  reloadVersions = async () => {
    const req: ApiListOptions = {};
    const list = await cachedApiClients.idashboardClient.ListAllVersions(req);
    this.setState({ versions: list.items });
  };

  refreshVersions = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadVersions);
  };

  reloadDeploymentInfo = async () => {
    const deplReq: ApiIDOptions = { id: this.state.deploymentId };
    const deploymentInfo = await this.props.api.GetDeployment(deplReq);
    const deployment = deploymentInfo.deployment || {};

    useDeploymentStore.setState({ deployment });
    useDeploymentStore.setState({ deploymentInfo });

    if (!this.state.deploymentInfo) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadDeploymentInfo, deployment.url);
    }
    this.setState(
      {
        deploymentInfo: deploymentInfo,
        allowed_endpoints: deploymentInfo.allowed_endpoints,
        agents_custom_command_line_args: deploymentInfo.agents_custom_command_line_arguments,
        dbservers_custom_command_line_args: deploymentInfo.dbservers_custom_command_line_arguments,
        coordinators_custom_command_line_args: deploymentInfo.coordinators_custom_command_line_arguments,
        kubeArangodbImage: (deploymentInfo && deploymentInfo.kube_arangodb && deploymentInfo.kube_arangodb.custom_kube_arangodb_image) || "",
        notebookImage: (deploymentInfo && deploymentInfo.custom_notebook_image) || "",
      },
      this.refreshBackupInfo
    );

    const regionReq: ApiIDOptions = { id: deployment.region_id };
    const regionDetails = await this.props.api.GetRegionDetails(regionReq);
    this.setState({ regionDetails: regionDetails });

    const projReq: ApiIDOptions = { id: deployment.project_id };
    const project = await this.props.api.GetProject(projReq);
    this.setState({ project: project });

    const orgReq: ApiIDOptions = { id: project.organization_id };
    const organization = await this.props.api.GetOrganization(orgReq);
    this.setState({ organization: organization });

    // Retrieve node size details if node size ID is known
    if (deployment.model && deployment.model.node_size_id) {
      const nodeSizeReq: ApiIDOptions = { id: deployment.model.node_size_id };
      const nodeSizeDetails = await this.props.api.GetNodeSizeDetails(nodeSizeReq);
      this.setState({ nodeSizeDetails: nodeSizeDetails });
    } else {
      this.setState({ nodeSizeDetails: undefined });
    }

    this.refreshMetricTokens();
    this.reloadLatestComments();

    const deploymentEmailReq: ApiGetDeploymentEmailAddressesRequest = {
      deployment_id: this.state.deploymentId,
    };
    const deploymentEmails = await apiClients.idashboardClient.GetDeploymentEmailAddresses(deploymentEmailReq);
    this.setState({ deploymentEmails: deploymentEmails });
  };

  refreshDeploymentInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDeploymentInfo);
  };

  onUpdateMemoryReserves = async (req: ApiUpdateDeploymentMemoryReservesRequest): Promise<any> => {
    let error = undefined;
    req.deployment_id = this.state.deploymentId;

    try {
      await this.props.api.UpdateDeploymentMemoryReserves(req);
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onUpdateTopologyAwareness = async (req: ApiUpdateDeploymentTopologyAwarenessRequest): Promise<any> => {
    let error = undefined;
    req.deployment_id = this.state.deploymentId;

    try {
      await this.props.api.UpdateDeploymentTopologyAwareness(req);
    } catch (e) {
      error = e;
    }
    return { error };
  };

  reloadRootPassword = async (reason: string, cb: (rootPassword: string) => void) => {
    this.setState({ loadingRootPassword: true });
    try {
      const reqOptions: ApiDeploymentCredentialsRequest = {
        deployment_id: this.state.deploymentId,
        reason: reason,
      };
      const creds = await this.props.api.GetDeploymentRootCredentials(reqOptions);
      cb(creds.password || "");
    } finally {
      this.setState({ loadingRootPassword: false });
    }
  };

  refreshBackupInfo = () => {
    const can_view_backups = this.hasPermission("internal-dashboard.backup.list");
    const can_list_backup_policies = this.hasPermission("internal-dashboard.backuppolicy.list");
    can_view_backups && can_list_backup_policies && this.props.refreshNow && this.props.refreshNow(this.reloadBackupInfo);
  };

  reloadMetricTokens = async () => {
    const req: ApiListTokensRequest = {
      deployment_id: this.state.deploymentId,
    };
    const response = (await apiClients.idashboardClient.ListMetricsTokens(req)) || {};
    this.setState({
      metricsTokens: response.items,
    });
  };

  refreshMetricTokens = async () => {
    const canListMetricsTokens = this.hasPermission("internal-dashboard.metricstoken.list");
    canListMetricsTokens && this.reloadMetricTokens();
  };

  reloadBackupInfo = async () => {
    const listBackupsReq: ApiListBackupInfosRequest = {
      deployment_id: this.state.deploymentId,
      sort_by_created: true,
      sort_descending: true,
      options: {
        page: this.state.backupsPage,
        page_size: this.state.backupsPageSize,
      },
    };
    const backups = await apiClients.idashboardClient.ListBackups(listBackupsReq);
    this.setState({ backups: backups });

    const listGoodBackupsReq: ApiListBackupInfosRequest = {
      deployment_id: this.state.deploymentId,
      sort_by_created: true,
      sort_descending: true,
      good_only: true,
      options: {
        page: 0,
        page_size: 1,
      },
    };
    const lastGoodBackups = await apiClients.idashboardClient.ListBackups(listGoodBackupsReq);
    this.setState({ lastGoodBackups: lastGoodBackups });

    const listBackupPoliciesReq: ApiListBackupPoliciesRequest = {
      deployment_id: this.state.deploymentId,
      include_deleted: true,
    };
    const backupPolicies = await apiClients.idashboardClient.ListBackupPolicies(listBackupPoliciesReq);
    this.setState({ backupPolicies: backupPolicies });
  };

  refreshRootPassword = (reason: string, cb: (rootPassword: string) => void) => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadRootPassword(reason, cb));
  };

  rotateCredentials = async () => {
    const confirmInfo: ConfirmInfo = {
      header: "Rotate deployment credentials",
      content: `Do you want rotate the root password  of this deployment?`,
      warning: "This can only be done with explicit consent from management!",
      onConfirm: async () => {
        this.setState({ processingRotateCredentials: true });
        try {
          const req: ApiRotateDeploymentCredentialsRequest = {
            deployment_id: this.state.deploymentId,
          };
          await apiClients.idashboardClient.RotateDeploymentCredentials(req);
        } catch (e) {
          this.setState({ errorMessage: e });
        } finally {
          this.setState({ processingRotateCredentials: false, confirmRotateCredentials: undefined });
        }
      },
      onDenied: () => {
        this.setState({ confirmRotateCredentials: undefined });
      },
    };
    this.setState({
      confirmRotateCredentials: confirmInfo,
    });
  };

  reloadSupportPlans = async () => {
    const list = await apiClients.idashboardClient.ListSupportPlans({});
    this.setState({ supportPlans: list });
  };

  refreshSupportPlans = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadSupportPlans);
  };

  getComments = async (options: ApiListOptions) => {
    const canListComments = this.hasPermission("internal-dashboard.comment.list");
    if (!canListComments) return;

    if (this.state.commentCreationInProcess) {
      return;
    }

    const { deploymentInfo = {} } = this.state;
    const { deployment = {} } = deploymentInfo;
    const { url = "" } = deployment;

    if (!url) {
      return;
    }

    const req: ApiListCommentsRequest = {
      resource_url: url,
      options,
    };

    try {
      const commentList = (await apiClients.idashboardClient.ListComments(req)) || {};
      this.setState({ commentsLoading: false, commentsFetchError: undefined });
      return commentList;
    } catch (err) {
      this.setState({ commentsFetchError: err, commentsLoading: false });
    }
  };

  reloadLatestComments = async () => {
    const latestComments = await this.getComments({ page: 0, page_size: 10 });
    if (!!latestComments) {
      if (!this.state.lastestCommentsTriggered) {
        this.setState({ commentList: latestComments });
      }
      this.setState({ latestComments, lastestCommentsTriggered: true });
    }
  };

  reloadComments = async () => {
    const { page, pageSize } = this.state.commentsPageOptions;

    const commentList = await this.getComments({ page, page_size: pageSize });
    if (!!commentList) {
      this.setState({ commentList });
    }
  };

  createComment = async (comment: string): Promise<AsyncResult<void>> => {
    this.setState({ commentCreationInProcess: true });
    const { deploymentInfo = {}, commentsPageOptions } = this.state;
    const { pageSize, page } = commentsPageOptions;
    const { deployment = {} } = deploymentInfo;
    const { url = "" } = deployment;
    let error = "";
    const req: ApiCreateCommentRequest = {
      resource_url: url,
      comment,
    };
    try {
      const comment = await apiClients.idashboardClient.CreateComment(req);
      const { items = [] } = this.state.commentList;
      const newCommentList: ApiCommentList = {
        items: [comment, ...slice(items, 0, pageSize - 1)],
      };
      this.setState(
        {
          commentsFetchError: undefined,
          commentCreationInProcess: false,
          commentList: page > 0 ? this.state.commentList : newCommentList,
          latestComments: {
            items: [comment, ...slice(this.state.latestComments.items, 0, pageSize - 1)],
          },
        },
        this.reloadComments
      );
    } catch (e) {
      this.setState({ commentsFetchError: e, commentCreationInProcess: false });
      error = e;
    }
    return { error };
  };

  onCommentsPageChange = (page: number, pageSize: number) => {
    this.setState({ commentsPageOptions: { page, pageSize } }, this.reloadComments);
  };

  onClickCreateBackup = () => {
    this.setState({
      createBackup: true,
      name: "Maintenance backup",
      description: "",
      autoDeleteUpload: true,
      autoDeleteUploadInDays: 7,
      autoDeleteNoUploadInHours: 6,
      uploadBackup: true,
      allowInconsistentBackup: false,
      isInternalBackup: true,
    });
  };

  onClickCancelCreateBackup = () => {
    this.setState({ createBackup: false });
  };

  onClickCancelEditBackup = () => {
    this.setState({ editBackup: false });
  };

  updateName = (newValue: string) => {
    this.setState({ name: newValue });
  };

  updateDescription = (newValue: string) => {
    this.setState({ description: newValue });
  };

  toggleAutoDeleteUpload = () => {
    this.setState((state: IDeploymentDetailsState) => {
      return { autoDeleteUpload: !state.autoDeleteUpload };
    });
  };

  updateAutoDeleteUploadInDays = (newValue: number) => {
    this.setState({ autoDeleteUploadInDays: newValue });
  };

  updateAutoDeleteNoUploadInHours = (newValue: number) => {
    this.setState({ autoDeleteNoUploadInHours: newValue });
  };

  toggleUploadBackup = () => {
    this.setState((state: IDeploymentDetailsState) => {
      return { uploadBackup: !state.uploadBackup };
    });
  };

  toggleAllowInconsistentBackup = () => {
    this.setState((state: IDeploymentDetailsState) => {
      return { allowInconsistentBackup: !state.allowInconsistentBackup };
    });
  };

  getAutoDeleteFromState(): moment.Moment | undefined {
    if (this.state.uploadBackup) {
      if (this.state.autoDeleteUpload) {
        return moment(Date.now()).utc().add(this.state.autoDeleteUploadInDays, "day");
      }
    } else {
      const mom = moment(Date.now()).utc().add(this.state.autoDeleteNoUploadInHours, "hour").subtract(2, "minutes");
      console.log(mom);
      return mom;
    }
    return undefined;
  }

  onClickSaveCreateBackup = async () => {
    try {
      this.setState({ processingBackup: true, errorMessage: undefined });
      const backup: ApiBackup = {
        deployment_id: this.state.deploymentId || "",
        name: this.state.name,
        description: this.state.description,
        upload: this.state.uploadBackup,
        auto_deleted_at: this.getAutoDeleteFromState(),
      } as ApiBackup;
      const req: ApiCreateBackupRequest = {
        backup: backup,
        is_internal: this.state.isInternalBackup,
        allow_inconsistent: this.state.allowInconsistentBackup,
      };
      await apiClients.idashboardClient.CreateBackup(req);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup creation failed: ${e}` });
    }
    this.setState({ processingBackup: false, createBackup: false });
  };

  onClickSaveEditBackup = async () => {
    try {
      this.setState({ processingBackup: true, errorMessage: undefined });
      const backup = {
        id: this.state.editBackupId,
        deployment_id: this.state.deploymentId || "",
        name: this.state.name,
        description: this.state.description,
        upload: this.state.uploadBackup,
        auto_deleted_at: this.getAutoDeleteFromState(),
      } as ApiBackup;
      await apiClients.idashboardClient.UpdateBackup(backup);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup update failed: ${e}` });
    }
    this.setState({ processingBackup: false, editBackup: false });
  };

  getBackupName = (id: string): string => {
    const backups = this.state.backups;
    if (backups && backups.items) {
      const backupInfo = backups.items.find((g) => {
        return g.backup && g.backup.id == id;
      });
      const backup = backupInfo && backupInfo.backup;
      if (backup) {
        return `${backup.name} (created: ${moment(backup.created_at).fromNow()})`;
      }
    }
    return "";
  };

  onClickBackupRestore = (id: string) => {
    const backupName = this.getBackupName(id);
    const confirmInfo: ConfirmInfo = {
      header: "Restore Backup",
      content: `Are you sure you want to restore backup '${backupName}'?`,
      warning: "This implies that your database will be unavailable for some time!",
      confirm: "Restore!",
      invertPositiveNegative: true,
      onConfirm: () => this.onRestoreBackupConfirmed(id),
      onDenied: () => this.setState({ confirmBackup: undefined }),
    };

    this.setState({ confirmBackup: confirmInfo });
  };

  onRestoreBackupConfirmed = async (id: string) => {
    try {
      this.setState({ processingBackup: true, errorMessage: undefined, confirmBackup: undefined });
      const idOptions: ApiIDOptions = { id: id };
      await apiClients.idashboardClient.RestoreBackup(idOptions);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup restore failed: ${e}` });
    }
    this.setState({ processingBackup: false });
  };

  onClickBackupEdit = (id: string) => {
    const backups = this.state.backups;
    if (backups && backups.items) {
      const backupInfo = backups.items.find((g) => {
        return g.backup && g.backup.id == id;
      });
      const backup = backupInfo && backupInfo.backup;
      if (backup) {
        this.setState({
          editBackup: true,
          editBackupId: id,
          name: backup.name || "",
          description: backup.description || "",
          autoDeleteUpload: backup.upload ? !!backup.auto_deleted_at : false,
          autoDeleteUploadInDays: backup.upload && backup.auto_deleted_at ? moment(backup.auto_deleted_at).diff(Date.now(), "day") + 1 : 31,
          autoDeleteNoUploadInHours: !backup.upload && backup.auto_deleted_at ? moment(backup.auto_deleted_at).diff(Date.now(), "hour") + 1 : 6,
          uploadBackup: !!backup.upload,
          isInternalBackup: !!backupInfo.is_internal,
          allowInconsistentBackup: !!backupInfo.allow_inconsistent,
        });
      }
    }
  };

  onClickBackupDownload = (id: string) => {
    const backupName = this.getBackupName(id);
    const confirmInfo: ConfirmInfo = {
      header: "Download Backup",
      content: `Are you sure you want to download backup '${backupName}'?`,
      warning: "This implies that your data-storage in your cluster will grow!",
      onConfirm: () => this.onDownloadBackupConfirmed(id),
      onDenied: () => this.setState({ confirmBackup: undefined }),
    };

    this.setState({ confirmBackup: confirmInfo });
  };

  onDownloadBackupConfirmed = async (id: string) => {
    try {
      this.setState({ processingBackup: true, errorMessage: undefined, confirmBackup: undefined });
      const idOptions: ApiIDOptions = { id: id };
      await apiClients.idashboardClient.DownloadBackup(idOptions);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup download failed: ${e}` });
    }
    this.setState({ processingBackup: false });
  };

  onClickBackupDelete = (id: string) => {
    const backupName = this.getBackupName(id);
    const confirmInfo: ConfirmInfo = {
      header: "Delete Backup",
      content: `Are you sure you want to delete backup '${backupName}'?`,
      warning: "This implies deletion of the data stored in the cloud as well!",
      invertPositiveNegative: true,
      onConfirm: () => this.onDeleteBackupConfirmed(id),
      onDenied: () => this.setState({ confirmBackup: undefined }),
    };

    this.setState({ confirmBackup: confirmInfo });
  };

  onDeleteBackupConfirmed = async (id: string) => {
    try {
      this.setState({ processingBackup: true, errorMessage: undefined, confirmBackup: undefined });
      const idOptions: ApiIDOptions = { id: id };
      await apiClients.idashboardClient.DeleteBackup(idOptions);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup deletion failed: ${e}`, backupsPage: 0 });
    }
    this.setState({ processingBackup: false });
  };

  onClickCloneDeploymentFromBackup = async (id: string) => {
    try {
      this.setState({ processingBackup: true, errorMessage: undefined });
      const req: ApiCloneDeploymentFromBackupRequest = { backup_id: id };
      const clone = await apiClients.idashboardClient.CloneDeploymentFromBackup(req);
      this.setState(
        {
          processingBackup: false,
          deploymentId: clone.id,
        },
        () => {
          this.props.onDeploymentSelected(clone.id || "");
          this.reloadDeploymentInfo();
        }
      );
    } catch (e) {
      this.setState({ errorMessage: `Clone deployment from backup failed: ${e}`, processingBackup: false });
    }
  };

  onResumeDeployment = async () => {
    try {
      this.setState({ processingResumeDeployment: true, errorMessage: undefined });
      const idOptions: ApiIDOptions = { id: this.state.deploymentId || "" };
      await apiClients.idashboardClient.ResumeDeployment(idOptions);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: `Deployment resuming failed: ${e}` });
    }
    this.setState({ processingResumeDeployment: false });
  };

  componentDidMount() {
    this.refreshSupportPlans();
    this.refreshVersions();
    const deploymentId = (this.props.match.params as any).deploymentId;
    this.setState(
      {
        deploymentId: deploymentId,
      },
      () => {
        //notice inside the reloadDeploymentInfo we use the deploymentId
        this.props.refreshNow && this.props.refreshNow(this.reloadDeploymentInfo);
      }
    );
  }

  onOpenRootPasswordRequestModal = () => {
    this.setState({
      showRootPasswordRequestModal: true,
      rootPasswordRequestReason: "",
    });
  };

  onCloseRootPasswordRequestModal = () => {
    this.setState({ showRootPasswordRequestModal: false });
  };

  onRootPasswordRequestReasonChange = (reason: string) => {
    this.setState({ rootPasswordRequestReason: reason });
  };

  onDoCopyRootPassword = () => {
    this.setState({ showRootPasswordRequestModal: false }, () => {
      this.refreshRootPassword(this.state.rootPasswordRequestReason, (rootPassword: string) => {
        copy(rootPassword);
        this.setState({ copiedRootPassword: true }, () => {
          const setTimeout = this.props.setTimeout;
          setTimeout && setTimeout(() => this.setState({ copiedRootPassword: false }), 2000);
        });
      });
    });
  };

  onExpirationAddWeeks = async (weeks: number) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${weeks > 0 ? "Extending" : "Reducing"} expiration by ${Math.abs(weeks)} week(s)`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing expiration date, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiSetDeploymentExpirationDateRequest = {
            deployment_id: this.state.deploymentId,
            days_delta: weeks * 7,
          };
          await this.props.api.SetDeploymentExpirationDate(opts);
          this.reloadDeploymentInfo();
        },
      },
    });
  };

  onExpirationOptOut = async () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: "Disabling deployment expiration (opt-out)",
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Disabling expiration, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiSetDeploymentExpirationDateRequest = {
            deployment_id: this.state.deploymentId,
            opt_out: true,
          };
          await this.props.api.SetDeploymentExpirationDate(opts);
          this.reloadDeploymentInfo();
        },
      },
    });
  };

  onClickUpdateHibernationMode = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${newMode ? "Enabling" : "Disabling"} hibernation mode`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing hibernation mode, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentHibernationModeRequest = {
            deployment_id: this.state.deploymentId,
            in_hibernation_mode: newMode,
          };

          await this.props.api.UpdateDeploymentHibernationMode(opts);
        },
      },
    });
  };

  onClickUpdateMaintenanceMode = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${newMode ? "Enabling" : "Disabling"} maintenance mode`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing maintenance mode, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentMaintenanceModeRequest = {
            deployment_id: this.state.deploymentId,
            in_maintenance_mode: newMode,
          };
          await this.props.api.UpdateDeploymentMaintenanceMode(opts);
        },
      },
    });
  };

  onClickUpdateAllowTraffic = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${newMode ? "Block" : "Not Block"} all traffic`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing allow traffc settings, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentAllowTrafficRequest = {
            deployment_id: this.state.deploymentId,
            block_all_incoming_traffic: newMode,
          };
          await this.props.api.UpdateDeploymentAllowTraffic(opts);
        },
      },
    });
  };

  onClickUpdateAgencyMaintenanceMode = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${newMode ? "Enabling" : "Disabling"} agency maintenance mode`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing agency maintenance mode, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentMaintenanceModeRequest = {
            deployment_id: this.state.deploymentId,
            in_maintenance_mode: newMode,
          };
          await this.props.api.UpdateDeploymentAgencyMaintenanceMode(opts);
        },
      },
    });
  };

  onClickUpdateOperatorMaintenanceMode = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${newMode ? "Enabling" : "Disabling"} operator maintenance mode`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing operator maintenance mode, please wait...",
        onCommentConfirmation: async (): Promise<void> => {
          this.setState({ errorMessage: undefined });
          const opts: ApiUpdateDeploymentMaintenanceModeRequest = {
            deployment_id: this.state.deploymentId,
            in_maintenance_mode: newMode,
          };
          await this.props.api.UpdateDeploymentOperatorMaintenanceMode(opts);
        },
      },
    });
  };

  onUpdateVersion = async (newVersion: string) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Updating to version ${newVersion}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing version, please wait...",
        onCommentConfirmation: async () => {
          const deploymentInfo = this.state.deploymentInfo || {};
          const deployment = deploymentInfo.deployment || {};
          const opts: ApiUpdateDeploymentVersionRequest = {
            deployment_id: this.state.deploymentId,
            version: newVersion,
            custom_image: deployment.custom_image,
          };

          await this.props.api.UpdateDeploymentVersion(opts);
        },
      },
    });
  };

  onClickDCUpdateMode = async (newValue: string) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Changing DC update mode to ${newValue}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing update mode, please wait...",
        onCommentConfirmation: async () => {
          const annotations = (this.state.deploymentInfo && this.state.deploymentInfo.annotations) || [];
          const tmpList = annotations.filter((v: ApiKeyValuePair) => {
            return v.key != Common.dcupdate_mode_annotation_key;
          });
          const newAnnotations: ApiKeyValuePair[] = concat(tmpList, {
            key: Common.dcupdate_mode_annotation_key,
            value: newValue,
          });

          const req: ApiUpdateDeploymentAnnotationsRequest = {
            deployment_id: this.state.deploymentId,
            annotations: newAnnotations,
          };

          await apiClients.idashboardClient.UpdateDeploymentAnnotations(req);
          this.refreshDeploymentInfo();
        },
      },
    });
  };

  onSaveAllowlistedEndpoints = async (endpoints: string[]): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      this.setState({ errorMessage: undefined });
      const opts: ApiUpdateDeploymentEndpointAllowlistsRequest = {
        deployment_id: this.state.deploymentId,
        allowed_endpoints: endpoints,
      };

      await this.props.api.UpdateDeploymentEndpointAllowlists(opts);

      this.refreshDeploymentInfo();
    } catch (e) {
      error = e;
    } finally {
    }
    return { error };
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  hasPermission = (p: Permission) => {
    const deploymentInfo = this.state.deploymentInfo;
    const deployment = deploymentInfo && deploymentInfo.deployment;
    const url = (deployment && deployment.url) || "";
    return !!(this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(url, ResourceType.Deployment, p));
  };

  onSaveCustomCommandLineArguments = async (agentArgs: string[], dbserverArgs: string[], coordinatorArgs: string[]): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const opts: ApiUpdateDeploymentCustomCommandLineArgumentsRequest = {
        deployment_id: this.state.deploymentId,
        agents_custom_command_line_arguments: agentArgs,
        dbservers_custom_command_line_arguments: dbserverArgs,
        coordinators_custom_command_line_arguments: coordinatorArgs,
      };
      await this.props.api.UpdateDeploymentCustomCommandLineArguments(opts);
      this.refreshDeploymentInfo();
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onClickUpdateProvisionHash = async () => {
    try {
      this.setState({ processingUpdateProvisionHash: true, errorMessage: undefined });
      const opts: ApiIDOptions = {
        id: this.state.deploymentId,
      };
      await this.props.api.UpdateDataClusterAssignmentProvisionHash(opts);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingUpdateProvisionHash: false });
    }
  };

  onClickUpdateSupportPlan = async (newSupportPlanID: string, newSupportPlanName: string) => {
    const confirmInfo: ConfirmInfo = {
      header: "Change support plan for deployment",
      content: `Do you want to set the support plan of this deployment to ${newSupportPlanName}? This will change the price for the customer!`,
      warning: "This can only be done with explicit consent from management!",
      onConfirm: async () => {
        try {
          this.setState({ processingUpdateSupportPlan: true, errorMessage: undefined, confirmSetSupportPlan: undefined });
          const req: ApiUpdateDeploymentSupportPlanRequest = {
            deployment_id: this.state.deploymentId,
            support_plan_id: newSupportPlanID,
          };
          await this.props.api.UpdateDeploymentSupportPlan(req);
          this.refreshDeploymentInfo();
        } catch (e) {
          this.setState({ errorMessage: e });
        } finally {
          this.setState({ processingUpdateSupportPlan: false });
        }
      },
      onDenied: () => {
        this.setState({ confirmSetSupportPlan: undefined });
      },
    };
    this.setState({
      confirmSetSupportPlan: confirmInfo,
    });
  };

  onClickRotateServer = async (serverID: string) => {
    try {
      this.setState({ errorMessage: undefined, rotatingServerID: serverID });
      const req: ApiRotateDeploymentServerRequest = {
        deployment_id: this.state.deploymentId,
        server_id: serverID,
      };

      await apiClients.idashboardClient.RotateDeploymentServer(req);
      this.refreshDeploymentInfo();
      const setTimeout = this.props.setTimeout;
      setTimeout &&
        setTimeout(() => {
          this.setState((old) => {
            return { rotatingServerID: old.rotatingServerID == serverID ? undefined : old.rotatingServerID };
          });
        }, 3000);
    } catch (e) {
      this.setState({ errorMessage: e, rotatingServerID: undefined });
    }
  };

  onClickRotateServerWithForce = async (serverID: string) => {
    try {
      this.setState({ errorMessage: undefined, rotatingServerID: serverID });
      const req: ApiRotateDeploymentServerRequest = {
        deployment_id: this.state.deploymentId,
        server_id: serverID,
      };

      await apiClients.idashboardClient.RotateDeploymentServerWithForce(req);
      this.refreshDeploymentInfo();
      const setTimeout = this.props.setTimeout;
      setTimeout &&
        setTimeout(() => {
          this.setState((old) => {
            return { rotatingServerID: old.rotatingServerID == serverID ? undefined : old.rotatingServerID };
          });
        }, 3000);
    } catch (e) {
      this.setState({ errorMessage: e, rotatingServerID: undefined });
    }
  };

  onClickRedeleteDeployment = async () => {
    const confirmInfo: ConfirmInfo = {
      header: "Re-delete a deployment",
      content: `Are you sure you would like to re-delete the deployment?`,
      warning: "Only do this in case of a stuck deployment or a need to re-trigger finalizers in CP!",
      onConfirm: async () => {
        try {
          this.setState({ processingDeploymentRedelete: true, errorMessage: undefined, confirmRedeleteDeployment: undefined });
          const req: ApiIDOptions = {
            id: this.state.deploymentId,
          };
          await apiClients.idashboardClient.ReDeleteDeployment(req);
          const setTimeout = this.props.setTimeout;
          setTimeout &&
            setTimeout(() => {
              this.setState({ processingDeploymentRedelete: false });
            }, 3000);
        } catch (e) {
          this.setState({ errorMessage: e });
        } finally {
          this.setState({ processingDeploymentRedelete: false });
        }
      },
      onDenied: () => {
        this.setState({ confirmRedeleteDeployment: undefined });
      },
    };
    this.setState({
      confirmRedeleteDeployment: confirmInfo,
    });
  };

  onClickUpdateChaosLevel = async (newLevel: string) => {
    const confirmInfo: ConfirmInfo = {
      header: "Update chaos-level for a deployment",
      content: `Are you sure you would like to update the chaos level of the deployment to '${newLevel}'?`,
      warning: "Only do this in case of testing, do NOT use this in production for customer deployments!",
      onConfirm: async () => {
        try {
          this.setState({ processingUpdateChaosLevel: true, errorMessage: undefined, confirmUpdateChaosLevel: undefined });
          // Preserve existing image (if any)
          const image =
            this.state.deploymentInfo && this.state.deploymentInfo.kube_arangodb && this.state.deploymentInfo.kube_arangodb.custom_kube_arangodb_image;
          const req: ApiUpdateDeploymentKubeArangodbRequest = {
            deployment_id: this.state.deploymentId,
            kube_arangodb: {
              custom_kube_arangodb_image: image,
              chaos_level: newLevel,
            },
          };
          await apiClients.idashboardClient.UpdateDeploymentKubeArangodb(req);
          this.refreshDeploymentInfo();
        } catch (e) {
          this.setState({ errorMessage: e });
        } finally {
          this.setState({ processingUpdateChaosLevel: false });
        }
      },
      onDenied: () => {
        this.setState({ confirmUpdateChaosLevel: undefined });
      },
    };
    this.setState({
      confirmUpdateChaosLevel: confirmInfo,
    });
  };

  onClickEditKubeArangodbImage = () => {
    this.setState({
      editKubeArangodbImage: true,
      kubeArangodbImage: this.state.kubeArangodbImage,
    });
  };

  onClickEditNotebookImage = () => {
    this.setState({
      editNotebookImage: true,
      notebookImage: this.state.notebookImage,
    });
  };

  onChangeKubeArangodbImage = async (newValue: string): Promise<AsyncResult<void>> => {
    let error = "";
    const req: ApiUpdateDeploymentKubeArangodbRequest = {
      deployment_id: this.state.deploymentId,
      kube_arangodb: {
        custom_kube_arangodb_image: newValue,
      },
    };
    try {
      await apiClients.idashboardClient.UpdateDeploymentKubeArangodb(req);
      this.setState({ kubeArangodbImage: newValue });
    } catch (err) {
      error = err;
    }
    return { error };
  };

  onChangeNotebookImage = async (newValue: string): Promise<AsyncResult<void>> => {
    let error = "";
    const req: ApiSetCustomNotebookImageRequest = {
      deployment_id: this.state.deploymentId,
      custom_notebook_image: newValue,
    };
    try {
      await apiClients.idashboardClient.SetDeploymentCustomNotebookImage(req);
      this.setState({ notebookImage: newValue });
    } catch (err) {
      error = err;
    }
    return { error };
  };

  onClickCancelEditKubeArangodbImage = () => {
    this.setState({ editKubeArangodbImage: false, kubeArangodbImage: undefined });
  };

  onClickCancelEditNotebookImage = () => {
    this.setState({ editNotebookImage: false, notebookImage: undefined });
  };

  onUpdateCPURatio = async (newCPURatio: string): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const values = newCPURatio.split("/");
      if (values.length != 2) {
        return { error: "expected two percent values separated by '/'. e.g. 25/75" };
      }
      const cpuCoordinator = parseInt(values[0]);
      const cpuDbServer = parseInt(values[1]);
      if (cpuCoordinator + cpuDbServer != 100) {
        return { error: "the sum of the two values must equal 100. e.g. 30/70" };
      }

      const opts: ApiUpdateDeploymentCPURatioRequest = {
        deployment_id: this.state.deploymentId,
        cpu_ratio: {
          coordinator_percent: cpuCoordinator,
          dbserver_percent: cpuDbServer,
        },
      };

      await this.props.api.UpdateDeploymentCPURatio(opts);
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onUpdateCPUFactors = async (newCPUFactor: string): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const values = newCPUFactor.split("/");
      if (values.length != 2) {
        return { error: "CPU factors need to be two float values separated by '/'. e.g. 0.5/0.7" };
      }
      const cpuFactorsCoordinator = parseFloat(values[0]);
      const cpuFactorsDbServer = parseFloat(values[1]);
      if (Number.isNaN(cpuFactorsCoordinator) || Number.isNaN(cpuFactorsDbServer)) {
        return {
          error: `CPU factors need to be two float values separated by '/'. e.g. 0.5/0.7" `,
        };
      }
      const req: ApiUpdateDeploymentCPUFactorsRequest = {
        deployment_id: this.state.deploymentId,
        cpu_factors: {
          coordinator_factor: cpuFactorsCoordinator,
          dbserver_factor: cpuFactorsDbServer,
        },
      };

      // Unset CPU factors if both values are 0, so it got back to the defaults.
      if (cpuFactorsCoordinator == 0 && cpuFactorsDbServer == 0) {
        req.cpu_factors = undefined;
      }

      await this.props.api.UpdateDeploymentCPUFactors(req);
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onUpdateMemoryFactors = async (newMemoryFactor: string): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const values = newMemoryFactor.split("/");
      if (values.length != 2) {
        return { error: "Memory factors need to be two float values separated by '/'. e.g. 0.5/0.7" };
      }

      const memoryFactorsCoordinator = parseFloat(values[0]);
      const memoryFactorsDbServer = parseFloat(values[1]);
      if (Number.isNaN(memoryFactorsCoordinator) || Number.isNaN(memoryFactorsDbServer)) {
        return {
          error: `Memory factors need to be two float values separated by '/'. e.g. 0.5/0.7" `,
        };
      }
      const req: UpdateDeploymentMemoryFactorsRequest = {
        deployment_id: this.state.deploymentId,
        memory_factors: {
          coordinator_factor: memoryFactorsCoordinator,
          dbserver_factor: memoryFactorsDbServer,
        },
      };

      // Unset memory factors if both values are 0, so it got back to the defaults.
      if (memoryFactorsCoordinator == 0 && memoryFactorsDbServer == 0) {
        req.memory_factors = undefined;
      }

      await this.props.api.UpdateDeploymentMemoryFactors(req);
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onUpdateMemoryRatio = async (newMemoryRatio: string): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const values = newMemoryRatio.split("/");
      if (values.length != 2) {
        return { error: "expected two percent values separated by '/'. e.g. 25/75" };
      }
      const memoryCoordinator = parseInt(values[0]);
      const memoryDbServer = parseInt(values[1]);
      if (memoryCoordinator + memoryDbServer != 100) {
        return { error: "the sum of the two values must equal 100. e.g. 30/70" };
      }
      const opts: ApiUpdateDeploymentMemoryRatioRequest = {
        deployment_id: this.state.deploymentId,
        memory_ratio: {
          coordinator_percent: memoryCoordinator,
          dbserver_percent: memoryDbServer,
        },
      };
      await this.props.api.UpdateDeploymentMemoryRatio(opts);
    } catch (e) {
      this.setState({ errorMessage: e });
      error = e;
    }
    return { error };
  };

  onUpdateAgencyResourceFactor = async (newFactor: string): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const value = parseFloat(newFactor);
      if (value < 1.0) {
        return { error: "expected number >= 1.0" };
      }
      const opts: ApiUpdateDeploymentAgencyResourcesFactorRequest = {
        deployment_id: this.state.deploymentId,
        agency_resources_factor: value,
      };
      await this.props.api.UpdateDeploymentAgencyResourcesFactor(opts);
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onUpdateCommunicationMethod = async (newMethod: string) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Update communication method to ${newMethod}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing comminucation method, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentCommunicationMethodRequest = {
            deployment_id: this.state.deploymentId,
            communication_method: newMethod,
          };

          await this.props.api.UpdateDeploymentCommunicationMethod(opts);
        },
      },
    });
  };

  onUpdateFoxxAuthentication = async (disable: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${disable ? "Disabling" : "Enabling"} Foxx authentication`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing fox authentication state, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentFoxxAuthenticationRequest = {
            deployment_id: this.state.deploymentId,
            disable_foxx_authentication: disable,
          };

          await this.props.api.UpdateDeploymentFoxxAuthentication(opts);
          this.refreshDeploymentInfo();
        },
      },
    });
  };

  onEditArangodbImage = () => {
    this.setState({ editArangodbImage: true });
  };

  onCancelEditArangodbImage = () => {
    this.setState({ editArangodbImage: false });
  };

  onSaveEditArangodbImage = async (newImage: string): Promise<AsyncResult<void>> => {
    let error = "";
    const deploymentInfo = this.state.deploymentInfo || {};
    const deployment = deploymentInfo.deployment || {};
    const opts: ApiUpdateDeploymentVersionRequest = {
      deployment_id: this.state.deploymentId,
      // Set new version
      version: deployment.version,
      // Update custom image
      custom_image: newImage,
    };

    try {
      await this.props.api.UpdateDeploymentVersion(opts);
      this.refreshDeploymentInfo();
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onClickSaveEditKubeArangodbImage = async () => {
    try {
      this.setState({ processingUpdateKubeArangodbImage: true, errorMessage: undefined });
      // Preserve existing chaos level (if any)
      const chaosLevel = this.state.deploymentInfo && this.state.deploymentInfo.kube_arangodb && this.state.deploymentInfo.kube_arangodb.chaos_level;
      const req: ApiUpdateDeploymentKubeArangodbRequest = {
        deployment_id: this.state.deploymentId,
        kube_arangodb: {
          custom_kube_arangodb_image: this.state.kubeArangodbImage,
          chaos_level: chaosLevel,
        },
      };
      await apiClients.idashboardClient.UpdateDeploymentKubeArangodb(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingUpdateKubeArangodbImage: false, editKubeArangodbImage: false, kubeArangodbImage: undefined });
    }
  };

  onClickSaveEditNotebookImage = async () => {
    try {
      this.setState({ processingUpdateNotebookImage: true, errorMessage: undefined });
      const req: ApiSetCustomNotebookImageRequest = {
        deployment_id: this.state.deploymentId,
        custom_notebook_image: this.state.notebookImage,
      };
      await apiClients.idashboardClient.SetDeploymentCustomNotebookImage(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingUpdateNotebookImage: false, editNotebookImage: false, notebookImage: undefined });
    }
  };

  onClickUpdateLoadBalancer = async (useSharedLoadbalancer: boolean, useInternalLoadbalancer: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Update load balancer to ${useSharedLoadbalancer ? "shared" : "internal"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing loadbalancer settings, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiUpdateDeploymentLoadBalancerRequest = {
            deployment_id: this.state.deploymentId,
            use_shared_loadbalancer: useSharedLoadbalancer,
            use_internal_loadbalancer: useInternalLoadbalancer,
          };

          await apiClients.idashboardClient.UpdateDeploymentLoadBalancer(req);
          this.refreshDeploymentInfo();
        },
      },
    });
  };

  onSaveVolumeInfos = async (newAgentVolumeInfo?: ApiVolumeInfo, newDbserverVolumeInfo?: ApiVolumeInfo): Promise<{ errorMessage: string }> => {
    let errorMessage = "";
    try {
      // Preserve locked (when setting agent only)
      let locked = ((this.state.deploymentInfo || {}).deployment || {}).disk_performance_locked;
      // And force to set it to locked when updating DB Server settings
      if (newDbserverVolumeInfo) {
        locked = true;
      }
      this.setState({ processingUpdateVolumeInfos: true, errorMessage: undefined });
      const req: ApiUpdateDeploymentVolumeInfosRequest = {
        deployment_id: this.state.deploymentId,
        agents_volume_info: newAgentVolumeInfo,
        dbservers_volume_info: newDbserverVolumeInfo,
        disk_performance_locked: locked,
      };
      await apiClients.idashboardClient.UpdateDeploymentVolumeInfos(req);
      this.setState({ editAgentsVolumeInfos: false, editDBServersVolumeInfos: false });
      this.refreshDeploymentInfo();
    } catch (e) {
      errorMessage = e;
      return { errorMessage };
    } finally {
      this.setState({ processingUpdateVolumeInfos: false });
    }

    return { errorMessage };
  };

  onUpdateDiskPerformanceLocked = async (locked: boolean, useRegularVolumeIinfo: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Turning ${locked ? "on" : "off"} disk performance lock`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing disk performance locked status, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiUpdateDeploymentVolumeInfosRequest = {
            deployment_id: this.state.deploymentId,
            disk_performance_locked: locked,
            use_regular_volume_info: useRegularVolumeIinfo,
          };
          await apiClients.idashboardClient.UpdateDeploymentVolumeInfos(req);
          this.refreshDeploymentInfo();
        },
      },
    });
  };
  onEditDiskPerformance = () => {
    this.setState({ editDiskPerformance: true });
  };
  onCancelEditDiskPerformance = () => {
    this.setState({ editDiskPerformance: false });
  };
  onDiskPerformanceChange = async (id: string) => {
    try {
      const locked = ((this.state.deploymentInfo || {}).deployment || {}).disk_performance_locked;
      this.setState({ processingDiskPerformance: true, errorMessage: undefined });
      const req: ApiUpdateDeploymentVolumeInfosRequest = {
        deployment_id: this.state.deploymentId,
        disk_performance_id: id,
        use_regular_volume_info: true,
        disk_performance_locked: locked,
      };
      await apiClients.idashboardClient.UpdateDeploymentVolumeInfos(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingDiskPerformance: false, editDiskPerformance: false });
    }
  };
  onEditAgentsVolumeInfos = () => {
    this.setState({ editAgentsVolumeInfos: true });
  };
  onCancelEditAgentsVolumeInfos = () => {
    this.setState({ editAgentsVolumeInfos: false });
  };
  onEditDBServersVolumeInfos = () => {
    this.setState({ editDBServersVolumeInfos: true });
  };
  onCancelEditDBServersVolumeInfos = () => {
    this.setState({ editDBServersVolumeInfos: false });
  };

  isRotatingServer = (serverID: string) => {
    return serverID == this.state.rotatingServerID;
  };

  onNextBackupPage = () => {
    this.setState((old) => ({ backupsPage: old.backupsPage + 1 }), this.reloadBackupInfo);
  };

  onPreviousBackupPage = () => {
    this.setState((old) => ({ backupsPage: old.backupsPage - 1 }), this.reloadBackupInfo);
  };

  onRemoveFinalizer = async (finalizer: string) => {
    try {
      this.setState({ processingRemoveFinalizer: true, errorMessage: undefined });
      const req: ApiFinalizerRequest = {
        context_id: this.state.deploymentId,
        finalizers: [finalizer],
      };
      await apiClients.idashboardClient.RemoveDeploymentFinalizers(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingRemoveFinalizer: false });
    }
  };

  onRemoveBackupFinalizer = async (id: string, finalizer: string) => {
    try {
      this.setState({ processingRemoveFinalizer: true, errorMessage: undefined });
      const req: ApiFinalizerRequest = {
        context_id: id,
        finalizers: [finalizer],
      };
      await apiClients.idashboardClient.RemoveBackupFinalizers(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingRemoveFinalizer: false });
    }
  };

  onRemoveBackupPolicyFinalizer = async (id: string, finalizer: string) => {
    try {
      this.setState({ processingRemoveFinalizer: true, errorMessage: undefined });
      const req: ApiFinalizerRequest = {
        context_id: id,
        finalizers: [finalizer],
      };
      await apiClients.idashboardClient.RemoveBackupPolicyFinalizers(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingRemoveFinalizer: false });
    }
  };

  onUpdateBackupPolicyAllowInconsistent = async (id: string, newValue: boolean) => {
    try {
      this.setState({ processingBackupPolicy: true, errorMessage: undefined });
      const req: ApiUpdateBackupPolicyAllowInconsistentRequest = {
        id: id,
        allow_inconsistent: newValue,
      };
      await apiClients.idashboardClient.UpdateBackupPolicyAllowInconsistent(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingBackupPolicy: false });
    }
  };

  onUpdateBackupPolicyUploadIncremental = async (id: string, newValue: boolean) => {
    try {
      this.setState({ processingBackupPolicy: true, errorMessage: undefined });
      const req: ApiUpdateBackupPolicyUploadIncrementalRequest = {
        id: id,
        upload_use_incremental_structure: newValue,
      };
      await apiClients.idashboardClient.UpdateBackupPolicyUploadIncremental(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingBackupPolicy: false });
    }
  };

  onClickUpdateSuppressedAlerts = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Update suppressed alerts to ${newMode ? "enabled" : "disabled"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating supression setting for alerts, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentSuppressAlertsRequest = {
            deployment_id: this.state.deploymentId,
            suppress_alerts: newMode,
          };
          await this.props.api.UpdateDeploymentSuppressAlerts(opts);
        },
      },
    });
  };

  onSetSchedulingPolicies = async (policies: string[]) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Update scheduling policies to ${policies}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating scheduling policies, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetDeploymentSchedulingPoliciesRequest = {
            deployment_id: this.state.deploymentId,
            scheduling_policies_ids: policies,
          };
          await this.props.api.SetDeploymentSchedulingPolicies(req);
        },
      },
    });
  };
  onSetSchedulingPoliciesEnabled = async (enable: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Update scheduling policies enabled to ${enable}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating scheduling policies enabled, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetDeploymentSchedulingPoliciesEnabledRequest = {
            deployment_id: this.state.deploymentId,
            enabled: enable,
          };
          await this.props.api.SetDeploymentSchedulingPoliciesEnabled(req);
        },
      },
    });
  };
  onUpdateDeploymentToUseJetStreamForAuditLogs = async (useJetStream: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Update deployment to use JetStream for audit logs to ${useJetStream}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating deployment to use JetStream for audit logs, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiUpdateDeploymentToUseJetStreamForAuditLogsRequest = {
            deployment_id: this.state.deploymentId,
            use_jetstream_for_auditlogs: useJetStream,
          };
          await this.props.api.UpdateDeploymentToUseJetStreamForAuditLogs(req);
          this.refreshDeploymentInfo();
        },
      },
    });
  };
  onUpdateDiskSize = async (newValue: number, ignoreMax: boolean): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const req: ApiUpdateDeploymentDiskSizeRequest = {
        deployment_id: this.state.deploymentId,
        dbserver_disk_size: newValue,
        ignore_maximum_size: ignoreMax,
      };
      await this.props.api.UpdateDeploymentDiskSize(req);
      this.refreshDeploymentInfo();
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onUpdateDeploymentUseShardRebalancer = async (useShardRebalancer: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${useShardRebalancer ? "Enable" : "Disable"} shard rebalancer usage`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing shard rebalancer usage, please wait...",
        onCommentConfirmation: async () => {
          const opts: ApiUpdateDeploymentUseShardRebalancerRequest = {
            deployment_id: this.state.deploymentId,
            use_shard_rebalancer: useShardRebalancer,
          };

          await this.props.api.UpdateDeploymentUseShardRebalancer(opts);
        },
      },
    });
  };

  onUpdateKubernetesResourcesPatches = async (data: ApiUpdateKubernetesResourcesPatchesRequest): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      await this.props.api.UpdateKubernetesResourcesPatches({
        deployment_id: this.state.deploymentId,
        ...data,
      });
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onSaveKubeArangodbAdditionalCommandLineArguments = async (kubeArangoArgs: string[]): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const opts: ApiSetKubeArangodbAdditionalCommandLineArgumentsRequest = {
        deployment_id: this.state.deploymentId,
        kube_arangodb_additional_command_line_arguments: kubeArangoArgs,
      };
      await this.props.api.SetKubeArangodbAdditionalCommandLineArguments(opts);
      this.refreshDeploymentInfo();
    } catch (e) {
      error = e;
    }
    return { error };
  };

  onClickSetPublicBackupsDisabled = async (disabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Update public backups disabled to ${disabled}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating public backups disabled",
        onCommentConfirmation: async () => {
          const req: ApiSetDeploymentPublicBackupsDisabledRequest = {
            deployment_id: this.state.deploymentId,
            disabled: disabled,
          };
          await this.props.api.SetDeploymentPublicBackupsDisabled(req);
        },
      },
    });
  };

  requireComment = async (commentArgs: { defaultComment: string; loadingMessage: string; onCommentConfirmation: () => Promise<void> }) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: commentArgs.defaultComment,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: commentArgs.loadingMessage,
        onCommentConfirmation: async () => {
          this.setState({ errorMessage: undefined });
          await commentArgs.onCommentConfirmation();
        },
      },
    });
  };

  requirePermission = (permission: Permission): boolean => {
    return this.hasPermission(permission);
  };

  render() {
    const deploymentInfo = this.state.deploymentInfo;
    const finalizers = deploymentInfo && deploymentInfo.finalizers;
    const assignment = deploymentInfo && deploymentInfo.assignment;
    const deployment = deploymentInfo && deploymentInfo.deployment;
    const versions = this.state.versions || [];
    const regionDetails = this.state.regionDetails || {};
    const serverNodeAssignments = (deploymentInfo && deploymentInfo.server_node_assignments) || [];
    const annotations = deploymentInfo && deploymentInfo.annotations;
    const inHibernationMode = (deploymentInfo && deploymentInfo.in_hibernation_mode) || false;
    const isHibernated = (deploymentInfo && deploymentInfo.is_hibernated) || false;
    const inMaintenanceMode = (deploymentInfo && deploymentInfo.in_maintenance_mode) || false;
    const blockAllIncomingTraffic = (deploymentInfo && deploymentInfo.block_all_incoming_traffic) || false;
    const agencyInMaintenanceMode = (deploymentInfo && deploymentInfo.agency_in_maintenance_mode) || false;
    const operatorInMaintenanceMode = (deploymentInfo && deploymentInfo.operator_in_maintenance_mode) || false;
    const isUpToDate = (deploymentInfo && deploymentInfo.is_up_to_date) || false;
    const revision = (deploymentInfo && deploymentInfo.revision) || 0;
    const revisionUpdatedAt = deploymentInfo && deploymentInfo.revision_updated_at;
    const lastReportedRevision = (deploymentInfo && deploymentInfo.last_reported_revision) || 0;
    const lastReportedRevisionUpdatedAt = deploymentInfo && deploymentInfo.last_reported_revision_updated_at;
    const chaosLevel = (deploymentInfo && deploymentInfo.kube_arangodb && deploymentInfo.kube_arangodb.chaos_level) || "";
    const usesLocalSSD = (deploymentInfo && deploymentInfo.use_local_ssd) || false;
    const readOnly = (deployment && deployment.status && deployment.status.read_only) || false;
    const customKubeArangodbImage = this.state.kubeArangodbImage;
    const customNotebookImage = this.state.notebookImage;
    const cpuCoordinatorPercent = (deploymentInfo && deploymentInfo.cpu_ratio && deploymentInfo.cpu_ratio.coordinator_percent) || 0;
    const cpuDbServerPercent = (deploymentInfo && deploymentInfo.cpu_ratio && deploymentInfo.cpu_ratio.dbserver_percent) || 0;
    const memoryCoordinatorPercent = (deploymentInfo && deploymentInfo.memory_ratio && deploymentInfo.memory_ratio.coordinator_percent) || 0;
    const memoryDbServerPercent = (deploymentInfo && deploymentInfo.memory_ratio && deploymentInfo.memory_ratio.dbserver_percent) || 0;
    const useSharedLoadbalancer = (deploymentInfo && deploymentInfo.use_shared_loadbalancer) || false;
    const useInternalLoadbalancer = (deploymentInfo && deploymentInfo.use_internal_loadbalancer) || false;
    const agencyResourceFactor = (deploymentInfo && deploymentInfo.agency_resources_factor) || 1.0;
    const communicationMethod = (deploymentInfo && deploymentInfo.communication_method) || "";
    const allowed_endpoints = this.state.allowed_endpoints || [];
    const project = this.state.project || {};
    const organization = this.state.organization || {};
    const backupsInDataClusterBucket = (deploymentInfo && deploymentInfo.backups_in_datacluster_bucket) || false;
    const tier = organization.tier || {};
    const agentsMemoryReserve = deploymentInfo && deploymentInfo.agents_memory_reserve;
    const coordinatorsMemoryReserve = deploymentInfo && deploymentInfo.coordinators_memory_reserve;
    const dbserversMemoryReserve = deploymentInfo && deploymentInfo.dbservers_memory_reserve;
    const internal_loadbalancer_allowed = tier.has_private_endpoints || false;
    const can_edit_allowlisted_endpoints = this.hasPermission("internal-dashboard.deployment.update-endpoint-allowlists");
    const can_edit_custom_command_line_args = this.hasPermission("internal-dashboard.deployment.update-custom-command-line-arguments");
    const can_edit_maintenance_mode = this.hasPermission("internal-dashboard.deployment.update-maintenance-mode");
    const can_edit_allow_traffic = this.hasPermission("internal-dashboard.deployment.update-allow-traffic");
    const can_edit_agency_maintenance_mode = this.hasPermission("internal-dashboard.deployment.update-agency-maintenance-mode");
    const can_edit_operator_maintenance_mode = this.hasPermission("internal-dashboard.deployment.update-operator-maintenance-mode");
    const can_edit_dcupdate_mode = this.hasPermission("internal-dashboard.deployment.update-annotations");
    const can_edit_hibernation_mode = this.hasPermission("internal-dashboard.deployment.update-hibernation-mode");
    const can_edit_version = this.hasPermission("internal-dashboard.deployment.update-version");
    const can_copy_root_password = this.hasPermission("internal-dashboard.deploymentcredentials.get");
    const can_rotate_credentials = this.hasPermission("internal-dashboard.deploymentcredentials.rotate");
    const can_view_backups = this.hasPermission("internal-dashboard.backup.list");
    const can_update_provision_hash = this.hasPermission("internal-dashboard.dataclusterassignment.update-provision-hash");
    const can_edit_support_plan = this.hasPermission("internal-dashboard.deployment.update-support-plan");
    const can_rotate_server = this.hasPermission("internal-dashboard.deployment.rotate-server");
    const can_rotate_server_with_force = this.hasPermission("internal-dashboard.deployment.rotate-server-with-force");
    const can_redelete_deployment = this.hasPermission("internal-dashboard.deployment.redelete");
    const can_edit_chaos_level = this.hasPermission("internal-dashboard.deployment.update-kube-arangodb");
    const can_edit_arangodb_image = this.hasPermission("internal-dashboard.deployment.update-version");
    const can_edit_kube_arangodb_image = this.hasPermission("internal-dashboard.deployment.update-kube-arangodb");
    const can_edit_notebook_image = this.hasPermission("internal-dashboard.deployment.set-custom-notebook-image");
    const can_edit_cpu_ratio = this.hasPermission("internal-dashboard.deployment.update-cpu-ratio");
    const can_edit_memory_ratio = this.hasPermission("internal-dashboard.deployment.update-memory-ratio");
    const can_edit_loadbalancer = this.hasPermission("internal-dashboard.deployment.update-loadbalancer");
    const can_view_auditlog_archives = this.hasPermission("internal-dashboard.auditlogarchive.list");
    const can_edit_agency_resource_factor = this.hasPermission("internal-dashboard.deployment.update-agency-resources-factor");
    const can_edit_communication_method = this.hasPermission("internal-dashboard.deployment.update-communication-method");
    const can_view_email_messages: boolean = this.hasPermission("internal-dashboard.emailmessage.list");
    const can_view_example_installations = this.hasPermission("internal-dashboard.exampledatasetinstallation.list");
    const can_edit_foxx_authentication = this.hasPermission("internal-dashboard.deployment.update-foxx-authentication");
    const can_view_changelog = this.hasPermission("internal-dashboard.change.list");
    const can_view_coredumps = this.hasPermission("internal-dashboard.coredump.list");
    const canEditMemoryReserves = this.hasPermission("internal-dashboard.deployment.update-memory-reserves");
    const canEditDiskSize = this.hasPermission("internal-dashboard.deployment.update-disk-size");
    const canEditDiskSizeIgnoreMax = this.hasPermission("internal-dashboard.deployment.update-disk-size-ignore-maximum-size");
    const isTopologyAware = (deploymentInfo && deploymentInfo.is_topology_aware) || false;
    const numberOfZonesOverride = deploymentInfo ? deploymentInfo.number_of_zones_override || 0 : 0;
    const isEvenlyDistributed = (deploymentInfo && deploymentInfo.is_evenly_distributed) || false;
    const canUpdateTopologyAwareness = this.hasPermission("internal-dashboard.deployment.update-topology-awareness");
    const canUpdateVolumeInfos = this.hasPermission("internal-dashboard.deployment.update-volume-infos");
    const canRemoveFinalizer = this.hasPermission("internal-dashboard.deployment.manage");
    const canRemoveBackupFinalizer = this.hasPermission("internal-dashboard.backup.manage");
    const canRemoveBackupPolicyFinalizer = this.hasPermission("internal-dashboard.backuppolicy.manage");
    const canUpdateBackupPolicyAllowInconsistent = this.hasPermission("internal-dashboard.backuppolicy.update-allow-inconsistent");
    const canUpdateBackupPolicyUploadIncremental = this.hasPermission("internal-dashboard.backuppolicy.update-upload-incremental");
    const canListMetricsTokens = this.hasPermission("internal-dashboard.metricstoken.list");
    const canViewComments = this.hasPermission("internal-dashboard.comment.list");
    const canCreateComment = this.hasPermission("internal-dashboard.comment.create");
    const canEditSuppressedAlerts = this.hasPermission("internal-dashboard.deployment.update-suppress-alerts");
    const suppressAlerts = (deploymentInfo && deploymentInfo.suppress_alerts) || false;
    const disabledBackups = (deploymentInfo && deploymentInfo.public_backups_disabled) || false;
    const useShardRebalancer = (deploymentInfo && deploymentInfo.use_shard_rebalancer) || false;
    const arangoDeploymentPatches = (deploymentInfo && deploymentInfo.arango_deployment_patches) || [];
    const cloudDeploymentPatches = (deploymentInfo && deploymentInfo.cloud_deployment_patches) || [];
    const useJetStreamForAuditlogs = (deploymentInfo && deploymentInfo.use_jetstream_for_auditlogs) || false;
    const canEditJetStreamForAuditlogs = this.hasPermission("internal-dashboard.deployment.update-use-jetstream-for-auditlogs");
    const canUpdateUseShardRebalacner = this.hasPermission("internal-dashboard.deployment.update-use-shard-rebalancer");
    const canUpdateKubernetesResourcesPatches = this.hasPermission("internal-dashboard.deployment.update-kubernetes-resource-patches");
    const canGetDeploymentReplications = this.hasPermission("internal-dashboard.deploymentreplication.get");

    const canDeleteExistingDeployment = this.hasPermission("internal-dashboard.deployment.delete");

    const canListNotebook = this.hasPermission("internal-dashboard.notebook.list");
    const canListMLServices = this.hasPermission("internal-dashboard.mlservices.get");
    const canEnableMLService = this.hasPermission("internal-dashboard.mlservices.set-ml-service-enabled");
    const canUpdateNotebookImage = this.hasPermission("internal-dashboard.notebook.set-custom-image");
    const canUpdateNotebookMaintenanceMode = this.hasPermission("internal-dashboard.notebook.set-maintenance-mode");
    const canSetKubeArangodbAdditionalCommandLineArgs = this.hasPermission("internal-dashboard.deployment.set-kube-arangodb-additional-command-line-arguments");
    const canUpdateMemoryFactors = this.hasPermission("internal-dashboard.deployment.update-memory-factors");
    const canUpdateCPUFactors = this.hasPermission("internal-dashboard.deployment.update-cpu-factors");
    const canOptOutExpiration = this.hasPermission("internal-dashboard.deployment.opt-out-expiration");
    const canSetSchedulingPolicies = this.hasPermission("internal-dashboard.deployment.set-scheduling-policies");
    const canSetSchedulingPoliciesEnabled = this.hasPermission("internal-dashboard.deployment.set-scheduling-policies-enabled");
    const canSetAllowGPUWorkloads = this.hasPermission("internal-dashboard.mlservices.set-allow-gpu-workloads");
    const canSetMLJobsResourceLimits = this.hasPermission("internal-dashboard.mlservices.set-jobs-resource-limits");
    const canSetPublicBackupsDisabled = this.hasPermission("internal-dashboard.deployment.set-public-backup-disabled");

    if (deployment) {
      return (
        <>
          {!!this.state.commentPrompt.showCommentsPrompt && (
            <CommentsPrompt
              commentPrompt={{
                ...this.state.commentPrompt,
                handleAddComment: this.createComment,
                onClose: this.resetCommentPrompt,
              }}
            />
          )}
          <DeploymentDetailsTabView
            {...this}
            {...this.props}
            {...this.state}
            refreshDeploymentInfo={this.refreshDeploymentInfo}
            deployment={deployment}
            deploymentInfo={deploymentInfo || {}}
            finalizers={finalizers || []}
            versions={versions}
            regionDetails={regionDetails}
            assignment={assignment}
            annotations={annotations}
            inHibernationMode={inHibernationMode}
            isHibernated={isHibernated}
            inMaintenanceMode={inMaintenanceMode}
            blockAllIncomingTraffic={blockAllIncomingTraffic}
            agencyInMaintenanceMode={agencyInMaintenanceMode}
            operatorInMaintenanceMode={operatorInMaintenanceMode}
            isUpToDate={isUpToDate}
            revision={revision}
            revisionUpdatedAt={revisionUpdatedAt}
            lastReportedRevision={lastReportedRevision}
            lastReportedRevisionUpdatedAt={lastReportedRevisionUpdatedAt}
            chaosLevel={chaosLevel}
            serverNodeAssignments={serverNodeAssignments}
            customKubeArangodbImage={customKubeArangodbImage || ""}
            customNotebookImage={customNotebookImage || ""}
            cpuCoordinatorPercent={cpuCoordinatorPercent}
            cpuDbServerPercent={cpuDbServerPercent}
            memoryCoordinatorPercent={memoryCoordinatorPercent}
            memoryDbServerPercent={memoryDbServerPercent}
            useSharedLoadbalancer={useSharedLoadbalancer}
            useInternalLoadbalancer={useInternalLoadbalancer}
            allowed_endpoints={allowed_endpoints}
            can_edit_maintenance_mode={can_edit_maintenance_mode}
            can_edit_allow_traffic={can_edit_allow_traffic}
            can_edit_agency_maintenance_mode={can_edit_agency_maintenance_mode}
            can_edit_operator_maintenance_mode={can_edit_operator_maintenance_mode}
            can_edit_dcupdate_mode={can_edit_dcupdate_mode}
            can_edit_allowlisted_endpoints={can_edit_allowlisted_endpoints}
            can_edit_hibernation_mode={can_edit_hibernation_mode}
            can_view_backups={can_view_backups}
            can_view_email_messages={can_view_email_messages}
            can_view_example_installations={can_view_example_installations}
            can_edit_support_plan={can_edit_support_plan}
            can_edit_chaos_level={can_edit_chaos_level}
            canEditArangodbImage={can_edit_arangodb_image}
            can_edit_kube_arangodb_image={can_edit_kube_arangodb_image}
            can_edit_notebook_image={can_edit_notebook_image}
            can_edit_cpu_ratio={can_edit_cpu_ratio}
            can_edit_memory_ratio={can_edit_memory_ratio}
            can_edit_loadbalancer={can_edit_loadbalancer}
            internal_loadbalancer_allowed={internal_loadbalancer_allowed}
            project={project}
            organization={organization}
            can_copy_root_password={can_copy_root_password}
            can_edit_version={can_edit_version}
            canUpdateProvisionHash={can_update_provision_hash}
            onCopyRootPassword={this.onOpenRootPasswordRequestModal}
            onExpirationAddWeeks={this.onExpirationAddWeeks}
            onExpirationOptOut={this.onExpirationOptOut}
            canOptOutExpiration={canOptOutExpiration}
            onProjectSelected={() => this.props.onProjectSelected(deployment.project_id || "")}
            onOrganizationSelected={() => this.props.onOrganizationSelected(project.organization_id || "")}
            onClickUpdateHibernationMode={this.onClickUpdateHibernationMode}
            onClickUpdateMaintenanceMode={this.onClickUpdateMaintenanceMode}
            onClickUpdateAllowTraffic={this.onClickUpdateAllowTraffic}
            onClickUpdateAgencyMaintenanceMode={this.onClickUpdateAgencyMaintenanceMode}
            onClickUpdateOperatorMaintenanceMode={this.onClickUpdateOperatorMaintenanceMode}
            handleDismissError={this.handleDismissError}
            onCloseRootPasswordRequestModal={this.onCloseRootPasswordRequestModal}
            onCopyPassword={this.onDoCopyRootPassword}
            onRootPasswordRequestReasonChange={this.onRootPasswordRequestReasonChange}
            onSaveAllowlistedEndpoints={this.onSaveAllowlistedEndpoints}
            can_edit_custom_command_line_args={can_edit_custom_command_line_args}
            onUpdateVersion={this.onUpdateVersion}
            onUpdateCPURatio={this.onUpdateCPURatio}
            onUpdateMemoryRatio={this.onUpdateMemoryRatio}
            onClickUpdateProvisionHash={this.onClickUpdateProvisionHash}
            onClickUpdateSupportPlan={this.onClickUpdateSupportPlan}
            onClickDCUpdateMode={this.onClickDCUpdateMode}
            can_rotate_server={can_rotate_server}
            onClickRotateServer={this.onClickRotateServer}
            can_rotate_server_with_force={can_rotate_server_with_force}
            onClickRotateServerWithForce={this.onClickRotateServerWithForce}
            isRotatingServer={this.isRotatingServer}
            onNextBackupPage={this.onNextBackupPage}
            onPreviousBackupPage={this.onPreviousBackupPage}
            can_redelete_deployment={can_redelete_deployment}
            onClickRedeleteDeployment={this.onClickRedeleteDeployment}
            onClickUpdateChaosLevel={this.onClickUpdateChaosLevel}
            onClickEditKubeArangodbImage={this.onClickEditKubeArangodbImage}
            onClickEditNotebookImage={this.onClickEditNotebookImage}
            onChangeKubeArangodbImage={this.onChangeKubeArangodbImage}
            onChangeNotebookImage={this.onChangeNotebookImage}
            onClickCancelEditKubeArangodbImage={this.onClickCancelEditKubeArangodbImage}
            onClickCancelEditNotebookImage={this.onClickCancelEditNotebookImage}
            onClickSaveEditKubeArangodbImage={this.onClickSaveEditKubeArangodbImage}
            onClickSaveEditNotebookImage={this.onClickSaveEditNotebookImage}
            onClickUpdateLoadBalancer={this.onClickUpdateLoadBalancer}
            backupsInDataClusterBucket={backupsInDataClusterBucket}
            can_view_auditlog_archives={can_view_auditlog_archives}
            onAuditLogSelected={this.props.onAuditLogSelected}
            onAuditLogArchiveSelected={this.props.onAuditLogArchiveSelected}
            can_rotate_credentials={can_rotate_credentials}
            onRotateCredentials={this.rotateCredentials}
            can_edit_agency_resource_factor={can_edit_agency_resource_factor}
            agencyResourceFactor={agencyResourceFactor}
            can_edit_communication_method={can_edit_communication_method}
            communicationMethod={communicationMethod}
            can_edit_foxx_authentication={can_edit_foxx_authentication}
            can_view_changelog={can_view_changelog}
            can_view_coredumps={can_view_coredumps}
            deploymentEmails={this.state.deploymentEmails}
            processingUpdateKubeArangodbImage={this.state.processingUpdateKubeArangodbImage}
            processingUpdateNotebookImage={this.state.processingUpdateNotebookImage}
            agentsMemoryReserve={agentsMemoryReserve}
            coordinatorsMemoryReserve={coordinatorsMemoryReserve}
            dbserversMemoryReserve={dbserversMemoryReserve}
            canEditMemoryReserves={canEditMemoryReserves}
            onUpdateMemoryReserves={this.onUpdateMemoryReserves}
            isBackupUploadFeatureAvailable={true}
            isAllowInconsistentBackupAvailable={this.state.createBackup}
            canUpdateTopologyAwareness={canUpdateTopologyAwareness}
            isTopologyAware={isTopologyAware}
            numberOfZonesOverride={numberOfZonesOverride}
            isEvenlyDistributed={isEvenlyDistributed}
            canEditVolumeInfos={canUpdateVolumeInfos}
            diskAutoSizeSettings={deployment.disk_auto_size_settings}
            canRemoveFinalizer={canRemoveFinalizer}
            onRemoveFinalizer={this.onRemoveFinalizer}
            canRemoveBackupFinalizer={canRemoveBackupFinalizer}
            onRemoveBackupFinalizer={this.onRemoveBackupFinalizer}
            canRemoveBackupPolicyFinalizer={canRemoveBackupPolicyFinalizer}
            onRemoveBackupPolicyFinalizer={this.onRemoveBackupPolicyFinalizer}
            canUpdateBackupPolicyAllowInconsistent={canUpdateBackupPolicyAllowInconsistent}
            onUpdateBackupPolicyAllowInconsistent={this.onUpdateBackupPolicyAllowInconsistent}
            canUpdateBackupPolicyUploadIncremental={canUpdateBackupPolicyUploadIncremental}
            onUpdateBackupPolicyUploadIncremental={this.onUpdateBackupPolicyUploadIncremental}
            metricsTokens={this.state.metricsTokens}
            canListMetricsTokens={canListMetricsTokens}
            commentList={this.state.commentList}
            latestComments={this.state.latestComments}
            canViewComments={canViewComments}
            canCreateComment={canCreateComment}
            onCommentsPageChange={this.onCommentsPageChange}
            commentsFetchError={this.state.commentsFetchError}
            createComment={this.createComment}
            commentsLoading={this.state.commentsLoading}
            lastestCommentsTriggered={this.state.lastestCommentsTriggered}
            commentCreationInProcess={this.state.commentCreationInProcess}
            canEditDiskPerformanceLocked={canUpdateVolumeInfos} // DiskPerformanceLocked is a subfield of UpdateVolumeInfo
            canEditDiskPerformance={canUpdateVolumeInfos} // DiskPerformance is a subfield of UpdateVolumeInfo
            canEditSuppressedAlerts={canEditSuppressedAlerts}
            onClickUpdateSuppressedAlerts={this.onClickUpdateSuppressedAlerts}
            suppressAlerts={suppressAlerts}
            useShardRebalancer={useShardRebalancer}
            canUpdateUseShardRebalacner={canUpdateUseShardRebalacner}
            onUpdateDeploymentUseShardRebalancer={this.onUpdateDeploymentUseShardRebalancer}
            canUpdateKubernetesResourcesPatches={canUpdateKubernetesResourcesPatches}
            arangoDeploymentPatches={arangoDeploymentPatches}
            cloudDeploymentPatches={cloudDeploymentPatches}
            onUpdateKubernetesResourcesPatches={this.onUpdateKubernetesResourcesPatches}
            canGetDeploymentReplications={canGetDeploymentReplications}
            canDeleteExistingDeployment={canDeleteExistingDeployment}
            canListNotebook={canListNotebook}
            canUpdateNotebookImage={canUpdateNotebookImage}
            canUpdateNotebookMaintenanceMode={canUpdateNotebookMaintenanceMode}
            onCopyBackup={apiClients.idashboardClient.CopyBackup}
            canSetKubeArangodbAdditionalCommandLineArgs={canSetKubeArangodbAdditionalCommandLineArgs}
            onSaveKubeArangodbAdditionalCommandLineArguments={this.onSaveKubeArangodbAdditionalCommandLineArguments}
            onUpdateCPUFactors={this.onUpdateCPUFactors}
            onUpdateMemoryFactors={this.onUpdateMemoryFactors}
            canUpdateMemoryFactors={canUpdateMemoryFactors}
            canUpdateCPUFactors={canUpdateCPUFactors}
            saveSchedulingPolicies={this.onSetSchedulingPolicies}
            canSetSchedulingPolicies={canSetSchedulingPolicies}
            canSetSchedulingPoliciesEnabled={canSetSchedulingPoliciesEnabled}
            canSetAllowGPUWorkloads={canSetAllowGPUWorkloads}
            canSetMLJobsResourceLimits={canSetMLJobsResourceLimits}
            canEnableMLService={canEnableMLService}
            schedulingPoliciesEnabled={!!deploymentInfo.scheduling_policies_enabled}
            onSetSchedulingPolciiesEnabled={this.onSetSchedulingPoliciesEnabled}
            canListMLServices={canListMLServices}
            canSetPublicBackupsDisabled={canSetPublicBackupsDisabled}
            disabledBackups={disabledBackups}
            canEditDiskSize={canEditDiskSize}
            canEditDiskSizeIgnoreMax={canEditDiskSizeIgnoreMax}
            onUpdateDiskSize={this.onUpdateDiskSize}
            usesLocalSSDs={usesLocalSSD}
            readOnly={readOnly}
            requireComment={this.requireComment}
            reloadDeploymentInfo={this.reloadDeploymentInfo}
            requirePermission={this.requirePermission}
            useJetStreamForAuditlogs={useJetStreamForAuditlogs}
            canEditJetStreamForAuditlogs={canEditJetStreamForAuditlogs}
            onUpdateDeploymentToUseJetStreamForAuditLogs={this.onUpdateDeploymentToUseJetStreamForAuditLogs}
          />
        </>
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(DeploymentDetails);
