//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { User as ApiUser, MobilePhoneVerificationCode as ApiMobilePhoneVerificationCode } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import UserDetails from "./UserDetails";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";

// API used by the UserDetailsWithId component
export interface IUserDetailsWithIdAPI {
  GetUser: (userId: string) => Promise<ApiUser>;
  onGetUserMobilePhoneVerification: (user: ApiUser) => Promise<ApiMobilePhoneVerificationCode>;
  onResetMobilePhone: (user: ApiUser) => Promise<void>;
  onSendVerificationEmail: (user: ApiUser) => Promise<void>;
}

// Interface decribing the properties of the user component
interface IUserDetailsWithIdProps extends IWithRefreshProps, RouteComponentProps {
  userId?: string;
  api: IUserDetailsWithIdAPI;
  onOrganizationSelected: (organizationID: string) => void;
}

// Interface decribing the state of the user component
interface IUserDetailsWithIdState {
  user?: ApiUser;
  userFetchErrorMessage?: string;
  loadingUserDetail: boolean;
}

class UserDetailsWithId extends Component<IUserDetailsWithIdProps, IUserDetailsWithIdState> {
  state: IUserDetailsWithIdState = {
    user: undefined,
    userFetchErrorMessage: undefined,
    loadingUserDetail: false,
  };

  reloadUser = async () => {
    this.setState({
      userFetchErrorMessage: undefined,
      loadingUserDetail: true,
    });
    try {
      const user = await this.props.api.GetUser(this.props.userId || "");
      this.setState({ user });
    } catch (err) {
      this.setState({ user: undefined, userFetchErrorMessage: err });
    } finally {
      this.setState({ loadingUserDetail: false });
    }
  };

  refreshUser = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadUser);
  };

  componentDidMount() {
    this.refreshUser();
  }

  componentDidUpdate(prevProps: IUserDetailsWithIdProps) {
    if (prevProps.userId != this.props.userId) {
      this.refreshUser();
    }
  }

  render() {
    return (
      <UserDetails
        {...this.props}
        {...this.state}
        showId={true}
        onGetUserMobilePhoneVerification={this.props.api.onGetUserMobilePhoneVerification}
        onResetMobilePhone={this.props.api.onResetMobilePhone}
        onSendVerificationEmail={this.props.api.onSendVerificationEmail}
        onRefreshUser={this.refreshUser}
      />
    );
  }
}

export default withRefresh()(UserDetailsWithId);
