//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Dropdown, Grid, Loader, Menu, Segment, Tab } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  Project as ApiProject,
  SetDeploymentModelsRequest as ApiSetDeploymentModelsRequest,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import {
  LoaderBox,
  Field,
  FieldContent as FC,
  FieldLabel as FL,
  ContentSegment,
  SecondaryMenu,
  MenuActionBack,
  Section,
  SectionContent,
  TextLink,
  Locked,
} from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import DeploymentList from "../deployment/DeploymentList";
import QuotaList from "../quota/QuotaList";
import UsageItemList from "../organization/UsageItemList";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import EventList from "../event/EventList";
import AuditLogList from "../auditlog/AuditLogList";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

interface IProjectDetailsViewArgs {
  project: ApiProject;
  organization: ApiOrganization;
  canEditDeploymentModels: boolean;
  onSetFlexibleDeploymentsEnabled: (enabled: boolean) => void;
}

const ProjectDetailsView = ({ ...args }: IProjectDetailsViewArgs) => {
  const is_flexible_deployments_enabled = !!args.project.is_flexible_deployments_enabled;

  return (
    <Segment>
      <Grid width="16">
        <Grid.Column width="5">
          <Field>
            <FL>Name</FL>
            <FC>
              {args.project.name || "-"}
              {!!args.project.locked && <Locked />}
            </FC>
          </Field>
          <Field>
            <FL>Description</FL>
            <FC>{args.project.description || "-"}</FC>
          </Field>
          <Field>
            <FL>ID</FL>
            <FC>
              <div>{args.project.id || "-"}</div>
            </FC>
          </Field>
          <Field>
            <FL>Organization</FL>
            <FC>
              <TextLink href={Routes.dashboard_sales_organization_detailsWithId(args.organization.id as string)}>{args.organization.name || "-"}</TextLink>
            </FC>
          </Field>
        </Grid.Column>
        <Grid.Column width="6">
          <Field>
            <FL>Flexible deployments</FL>
            <FC>
              {is_flexible_deployments_enabled ? "Enabled" : "Disabled"}
              {args.canEditDeploymentModels && (
                <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
                  <Dropdown.Menu>
                    {!is_flexible_deployments_enabled && <Dropdown.Item text="Enable" onClick={() => args.onSetFlexibleDeploymentsEnabled(true)} />}
                    {is_flexible_deployments_enabled && <Dropdown.Item text="Disable" onClick={() => args.onSetFlexibleDeploymentsEnabled(false)} />}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </FC>
          </Field>
        </Grid.Column>
        <Grid.Column width="5">
          <Field>
            <FL>Created</FL>
            <FC>{args.project.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.project.created_at} label="Created at" /> : "-"}</FC>
          </Field>
          <Field>
            <FL>Deleted</FL>
            <FC>{args.project.deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.project.deleted_at} label=" Deleted at" /> : "-"}</FC>
          </Field>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

// Interface decribing the properties of the project details component
interface IProjectDetailsProps extends IWithRefreshProps, RouteComponentProps {
  projectId?: string;
  onDeploymentSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onProjectSelected: (id: string) => void;
  onInvoiceSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onAuditLogSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
}

// Interface decribing the state of the project details component
interface IProjectDetailsState {
  errorMessage?: string;
  processing: boolean;
  project?: ApiProject;
  organization?: ApiOrganization;
  processingSetModels: boolean;
}

class ProjectDetails extends Component<IProjectDetailsProps, IProjectDetailsState> {
  state: IProjectDetailsState = {
    errorMessage: undefined,
    processing: false,
    project: undefined,
    organization: undefined,
    processingSetModels: false,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadProjectInfo = async () => {
    const projReq: ApiIDOptions = { id: this.props.projectId || "" };
    const project = await apiClients.idashboardClient.GetProject(projReq);
    const orgReq: ApiIDOptions = { id: project.organization_id || "" };
    const organization = await apiClients.idashboardClient.GetOrganization(orgReq);
    this.setState({
      project: project,
      organization: organization,
    });
  };

  refreshProject = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadProjectInfo);
  };

  getProjectName = () => {
    return this.state.project ? this.state.project.name : "";
  };

  componentDidMount() {
    this.refreshProject();
  }

  onSetFlexibleDeploymentsEnabled = async (enabled: boolean) => {
    const req: ApiSetDeploymentModelsRequest = {
      context_id: this.props.projectId,
      is_flexible_deployments_enabled: enabled,
    };
    try {
      this.setState({ processingSetModels: true, errorMessage: undefined });
      await apiClients.idashboardClient.SetProjectDeploymentModels(req);
      this.refreshProject();
    } catch (e) {
      this.setState({ errorMessage: `Changing project deployment models failed: ${e}` });
    } finally {
      this.setState({ processingSetModels: false });
    }
  };

  render() {
    const project = this.state.project;
    const organization = this.state.organization || {};
    const can_edit_deployment_models = this.hasPermission("internal-dashboard.project.set-deployment-models");

    return (
      <div>
        <ContentSegment>
          <SecondaryMenu>
            <MenuActionBack />
            <Menu.Item header>Project Details</Menu.Item>
            <LoaderBox>
              <Loader size="mini" active={this.props.loading} inline />
            </LoaderBox>
          </SecondaryMenu>
          {project && (
            <Section>
              <SectionContent>
                <ProjectDetailsView
                  {...this.state}
                  project={project}
                  organization={organization}
                  canEditDeploymentModels={!organization.is_flexible_deployments_enabled && can_edit_deployment_models}
                  onSetFlexibleDeploymentsEnabled={this.onSetFlexibleDeploymentsEnabled}
                />
              </SectionContent>
            </Section>
          )}
          {project && (
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: "Deployments",
                  render: () => <DeploymentList {...this.props} project_id={project.id} />,
                },
                {
                  menuItem: "Quota",
                  render: () => <QuotaList {...this.props} project={project} showHeader={false} />,
                },
                {
                  menuItem: "Usage",
                  render: () => <UsageItemList {...this.props} project_id={project.id} organization_id={project.organization_id} />,
                },
                {
                  menuItem: "Events",
                  render: () => <EventList {...this.props} subject_id={project.id} organization_id={project.organization_id} showHeader={false} />,
                },
                {
                  menuItem: "AuditLog",
                  render: () => <AuditLogList {...this.props} project_id={project.id} />,
                },
              ]}
            />
          )}
        </ContentSegment>
      </div>
    );
  }
}

export default withRefresh()(ProjectDetails);
