//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React, { useEffect, useState } from "react";
import apiClients from "../../api/apiclients";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Header, Icon, Segment, Table } from "semantic-ui-react";
import {
  Address as ApiAddress,
  BillingConfig as ApiBillingConfig,
  IDOptions as ApiIDOptions,
  ListPaymentMethodsRequest as ApiListPaymentMethodsRequest,
  PaymentMethodList as ApiPaymentMethodList,
  PaymentMethod as ApiPaymentMethod,
} from "../../api/lib";
import {
  ErrorMessage,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  Section,
  SectionHeader,
  SectionContent,
  SectionHead,
  SectionButtons,
  ContentActionButtonEdit,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { getName as getCountryName } from "country-list";
import { isVatPossible } from "./vat";
import { usaStates } from "typed-usa-states";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import BillingAddressEditor from "./BillingAddressEditor";

interface IAddressArgs {
  address: ApiAddress;
  is_us: boolean;
}

const AddressView = ({ ...args }: IAddressArgs) => {
  const addressLines = args.address.address || [];
  const us_state = args.is_us ? usaStates.find((x) => x.abbreviation == args.address.state) : undefined;
  return (
    <div>
      {addressLines.map((l, i) => (
        <div key={`address${i}`}>{l}</div>
      ))}
      <div>
        {args.address.zipcode} {args.address.city}
      </div>
      <div>{us_state ? us_state.name : args.address.state}</div>
    </div>
  );
};

interface IDetailsViewArgs {
  config: ApiBillingConfig;
  is_vat_possible: boolean;
}

const DetailsView = ({ ...args }: IDetailsViewArgs) => {
  const address = args.config.address || {};
  const email_addresses = args.config.email_addresses || [];
  const is_empty = _.isEmpty(address) && _.isEmpty(args.config.vat_number) && _.isEmpty(email_addresses);
  if (is_empty) {
    return <div>No billing details yet</div>;
  }
  const is_us = address.country_code === "US";
  return (
    <Segment>
      <Grid width="16">
        <Grid.Column width="5">
          {args.config.company_legal_name && (
            <div>
              <Header sub>Company legal name</Header>
              <div>{args.config.company_legal_name}</div>
            </div>
          )}
          <Header sub>Billing Address</Header>
          <AddressView address={address} is_us={is_us} />
          <div>{address.country_code ? getCountryName(address.country_code) : ""}</div>
        </Grid.Column>
        <Grid.Column width="5">
          <Header sub>Tax</Header>
          {!is_us && (
            <Field>
              <FL>VAT code</FL>
              <FC>
                {args.is_vat_possible && <div>{args.config.vat_number || "-"}</div>}
                {!args.is_vat_possible && <div>VAT is only applicable to EU countries</div>}
              </FC>
            </Field>
          )}
          {is_us && (
            <Field>
              <FL>US sales tax number</FL>
              <FC>
                <div>{args.config.us_tax_number}</div>
              </FC>
            </Field>
          )}
        </Grid.Column>
        <Grid.Column width="6">
          <Header sub>Billing Email Addresses</Header>
          {_.isEmpty(email_addresses) && <div>-</div>}
          {email_addresses.map((x, i) => (
            <div key={`eaddr${i}`}>{x}</div>
          ))}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

interface IPaymentMethodViewArgs {
  paymentMethod: ApiPaymentMethod;
}

const PaymentMethodView = ({ ...args }: IPaymentMethodViewArgs) => {
  const x = args.paymentMethod;
  const cardInfo = x.credit_card_info || {};
  const isDefault = !!x.is_default;
  const isDeleted = !!x.is_deleted;
  const hasValidUntil = !!x.valid_until;
  return (
    <Table.Row key={`row-${x.id}`}>
      <Table.Cell>{x.name}</Table.Cell>
      <Table.Cell>
        {x.type} ({cardInfo.card_type || "?"})
      </Table.Cell>
      <Table.Cell>{x.currency_id || "?"}</Table.Cell>
      <Table.Cell>{hasValidUntil ? <DateTimePopupWithUTCAndLocalTime dateTime={x.valid_until} label="Valid until" /> : "-"}</Table.Cell>
      <Table.Cell>
        <Icon name={isDefault ? "check" : "minus"} />
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={x.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>{isDeleted ? <DateTimePopupWithUTCAndLocalTime dateTime={x.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

interface IPaymentMethodsViewArgs {
  paymentMethods: ApiPaymentMethod[];
}

const PaymentMethodsView = ({ ...args }: IPaymentMethodsViewArgs) => {
  const is_empty = _.isEmpty(args.paymentMethods);
  if (is_empty) {
    return <div>No payment methods yet</div>;
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Currency</Table.HeaderCell>
          <Table.HeaderCell>Expires</Table.HeaderCell>
          <Table.HeaderCell>Default</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>Deleted</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {args.paymentMethods.map((x) => (
          <PaymentMethodView key={`row-${x.id}`} paymentMethod={x} />
        ))}
      </Table.Body>
    </Table>
  );
};

// Interface decribing the properties of the billing details component
interface IBillingDetailsProps extends IWithRefreshProps, RouteComponentProps {
  organization_id?: string;
}

// The component to show the invoices as a list.
const BillingDetails = ({ error, clearError, refreshNow, organization_id, permissionCache, eventSubscriptionManager }: IBillingDetailsProps) => {
  const [config, setConfig] = useState<ApiBillingConfig>();
  const [paymentMethods, setPaymentMethods] = useState<ApiPaymentMethodList>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const reloadBillingConfig = async () => {
    const idOptions: ApiIDOptions = { id: organization_id };
    const config = await apiClients.idashboardClient.GetBillingConfig(idOptions);
    setConfig(config);
  };

  const refreshBillingConfig = () => {
    refreshNow && refreshNow(reloadBillingConfig);
  };

  const reloadPaymentMethods = async () => {
    const req: ApiListPaymentMethodsRequest = { organization_id };
    const paymentMethods = await apiClients.idashboardClient.ListPaymentMethods(req);
    setPaymentMethods(paymentMethods);
  };

  const refreshPaymentMethods = () => {
    refreshNow && refreshNow(reloadPaymentMethods);
  };
  const handleDismissError = () => {
    clearError && clearError();
  };

  useEffect(() => {
    refreshBillingConfig();
    refreshPaymentMethods();
  }, []);
  const vat_possible = isVatPossible(config || {});
  const { items: paymentMethodsItems = [] } = paymentMethods || {};
  const onEdit = () => {
    setShowEditModal(true);
  };
  return (
    <div>
      <ErrorMessage active={!!error} onDismiss={handleDismissError} message={error} />
      <Section>
        <SectionHead>
          <SectionHeader title="Configuration" />
          <SectionButtons>
            <ContentActionButtonEdit primary onClick={onEdit} />
          </SectionButtons>
        </SectionHead>
        <SectionContent>
          <DetailsView config={config || {}} is_vat_possible={vat_possible} />
        </SectionContent>
      </Section>
      <Section>
        <SectionHeader title="Payment methods" />
        <SectionContent>
          <PaymentMethodsView paymentMethods={paymentMethodsItems} />
        </SectionContent>
      </Section>
      {showEditModal && (
        <BillingAddressEditor
          organization_id={organization_id}
          eventSubscriptionManager={eventSubscriptionManager}
          permissionCache={permissionCache}
          address={(config && config.address) || {}}
          onCancel={() => setShowEditModal(false)}
          onSuccess={() => {
            reloadBillingConfig();
            setShowEditModal(false);
          }}
        />
      )}
    </div>
  );
};

export default withRefresh()(BillingDetails);
