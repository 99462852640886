//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { GetFeatureFlagsRequest as ApiGetFeatureFlagsRequest, UpdateFeatureFlagsRequest as ApiUpdateFeatureFlagsRequest } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Loading, Processing } from "../../ui/lib";
import _ from "lodash";
import { hasSupportPermission } from "../../util/PermissionCache";

interface IFeatureFlagsViewArgs {
  flags?: string[];
  edit: boolean;
  processing: boolean;
  canEdit: boolean;

  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  onChange: (flags: string[]) => void;
}

const FeatureFlagsView = ({ ...args }: IFeatureFlagsViewArgs) => {
  const loading = !args.flags;
  const flags = args.flags || [];
  const anyFlags = !_.isEmpty(flags);
  const edit = args.edit;
  const parseText = (input: string) => {
    return input.split("\n");
  };
  return (
    <div>
      {loading && <Loading />}
      {!edit && !loading && (
        <Segment>
          <div>
            {anyFlags && (
              <ul>
                {flags.map((f) => (
                  <li>
                    <code>{f}</code>
                  </li>
                ))}
              </ul>
            )}
            {!anyFlags && (
              <p>
                <i>None</i>
              </p>
            )}
          </div>
          {args.canEdit && <Button icon="pencil" content="Edit" onClick={args.onEdit} />}
        </Segment>
      )}
      {edit && !loading && (
        <Segment>
          <Form>
            <Form.TextArea label="One flags per line" value={flags.join("\n")} rows="10" onChange={(e, d) => args.onChange(parseText(d.value as string))} />

            <Button secondary icon="check" content="Save" disabled={args.processing} onClick={args.onSave} />
            <Button icon="cancel" content="Cancel" onClick={args.onCancel} />
          </Form>
        </Segment>
      )}
    </div>
  );
};

interface IFeatureFlagsProps extends IWithRefreshProps, RouteComponentProps {
  user_id?: string;
  organization_id?: string;
  tier_id?: string;
}

// Interface decribing the state of the email pattern edit component
interface IFeatureFlagsState {
  refreshNeeded: boolean;
  last_user_id?: string;
  last_organization_id?: string;
  last_tier_id?: string;

  flags?: string[];
  edit: boolean;

  errorMessage?: string;
  processing: boolean;
}

// The component to show / update feature flags.
class FeatureFlags extends Component<IFeatureFlagsProps, IFeatureFlagsState> {
  state: IFeatureFlagsState = {
    refreshNeeded: false,
    flags: undefined,
    edit: false,
    errorMessage: undefined,
    processing: false,
  };

  reloadFlags = async () => {
    const req: ApiGetFeatureFlagsRequest = {
      subject: {
        user_id: this.props.user_id,
        organization_id: this.props.organization_id,
        tier_id: this.props.tier_id,
      },
    };
    const flags = await apiClients.idashboardClient.GetFeatureFlags(req);
    this.setState({
      flags: flags.flags || [],
    });
  };

  refreshFlags = () => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadFlags());
  };

  static getDerivedStateFromProps(props: IFeatureFlagsProps, state: IFeatureFlagsState) {
    if (props.user_id !== state.last_user_id || props.organization_id !== state.last_organization_id || props.tier_id !== state.last_tier_id) {
      return {
        refreshNeeded: true,
        last_user_id: props.user_id,
        last_organization_id: props.organization_id,
        last_tier_id: props.tier_id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshFlags();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshFlags);
    }
  }

  onSave = async () => {
    this.setState({ processing: true });
    try {
      const flags = this.state.flags || [];
      const cleanFlags = _.filter(
        flags.map((x) => _.trim(x)),
        (x) => !_.isEmpty(x)
      );
      const req: ApiUpdateFeatureFlagsRequest = {
        subject: {
          user_id: this.props.user_id,
          organization_id: this.props.organization_id,
          tier_id: this.props.tier_id,
        },
        flags: cleanFlags,
      };
      await apiClients.idashboardClient.UpdateFeatureFlags(req);
      this.setState({ edit: false, flags: cleanFlags });
    } catch (e) {
      this.setState({ errorMessage: `Update feature flags failed: ${e}` });
    } finally {
      this.setState({ processing: false });
    }
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onEdit = () => {
    this.setState({ edit: true });
  };

  onCancel = () => {
    this.setState({ edit: false }, this.refreshFlags);
  };

  onChange = (flags: string[]) => {
    this.setState({ flags: flags });
  };

  render() {
    const canGet = hasSupportPermission("internal-dashboard.featureflag.get", this.props.hasPermissionByUrl);
    const canUpdate = hasSupportPermission("internal-dashboard.featureflag.update", this.props.hasPermissionByUrl);

    return (
      <div>
        {!canGet && <Message content="You are not allowed to inspect feature flags" />}
        {canGet && (
          <div>
            <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.handleDismissError} />
            <Processing active={this.state.processing} message="Updating feature flags, please wait..." />

            <FeatureFlagsView {...this.props} {...this.state} {...this} canEdit={canUpdate} />
          </div>
        )}
      </div>
    );
  }
}

export default withRefresh()(FeatureFlags);
