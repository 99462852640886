//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Provider as ApiProvider, ProviderList as ApiProviderList, ListProvidersRequest as ApiListProvidersRequest } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, ErrorMessage, Loading, SecondaryMenu, Section, SectionHead, SectionHeader, SectionContent } from "../../ui/lib";
import RegionList from "./RegionList";
import { SupportOrganizationID } from "../../constants";

interface IRowView extends IWithRefreshProps, RouteComponentProps {
  item: ApiProvider;
}

const RowView = ({ ...args }: IRowView) => {
  return (
    <Section>
      <SectionHead>
        <SectionHeader title={args.item.name || ""} />
      </SectionHead>
      <SectionContent>
        <RegionList {...args} provider_id={args.item.id || ""} />
      </SectionContent>
    </Section>
  );
};

// Interface describing the list
interface IListView extends IWithRefreshProps, RouteComponentProps {
  items: ApiProvider[];
}

const ListView = ({ ...args }: IListView) => (
  <div>
    {args.items.map((item) => {
      const id = item.id || "";
      return <RowView {...args} key={id} item={item} />;
    })}
  </div>
);

const EmptyView = () => <div>No regions</div>;

// Interface describing the list view arguments
export interface IProviderListViewArgs extends IWithRefreshProps, RouteComponentProps {
  loading: boolean;
  list?: ApiProviderList;
}

export const ProviderListView = ({ ...args }: IProviderListViewArgs) => {
  if (!args.list) {
    return <Loading />;
  }
  const isEmpty = !args.list.items || args.list.items.length === 0;
  const items = args.list ? args.list.items : undefined;
  return (
    <div>
      <ListView {...args} items={items || []} />
      {isEmpty && <EmptyView />}
    </div>
  );
};

interface IProviderListProps extends IWithRefreshProps, RouteComponentProps {}

interface IProviderListState {
  list?: ApiProviderList;
  errorMessage?: string;
}

// The component to show cloud providers as a list.
class ProviderList extends Component<IProviderListProps, IProviderListState> {
  state: IProviderListState = {
    list: undefined,
    errorMessage: undefined,
  };

  reloadProviders = async () => {
    var req: ApiListProvidersRequest = {
      organization_id: SupportOrganizationID,
    };
    const list = await apiClients.authenticationOnly.platformClient.ListProviders(req);
    this.setState({
      list: list,
    });
  };

  refreshProviders = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadProviders);
  };

  componentDidMount() {
    this.refreshProviders();
  }

  render() {
    const list = this.state.list;

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Providers</Menu.Item>
        </SecondaryMenu>
        <ProviderListView {...this.props} list={list} />
      </ContentSegment>
    );
  }
}

export default withRefresh()(ProviderList);
