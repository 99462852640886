//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import styled from "@emotion/styled";
import ReactJson from "react-json-view";
import { Button, IconProps, Modal, SemanticSIZES } from "semantic-ui-react";
import { SemanticShorthandItem } from "semantic-ui-react/dist/commonjs/generic";

const StyledObjectButton = styled.div`
    cursor: pointer;
    display: inline-block;
    width: 100%;
}`;

export interface IObjectJSONModalViewArgs {
  value: any;
  title: string;
  text: string;
  size?: SemanticSIZES;
  fluid?: boolean;
  icon?: SemanticShorthandItem<IconProps>;
}

export const ObjectJSONModalView = (args: IObjectJSONModalViewArgs) => {
  const [open, setVisibleState] = useState(false);

  return (
    <Modal
      open={open}
      centered
      trigger={
        <StyledObjectButton
          onClick={() => {
            setVisibleState(true);
          }}
        >
          <Button type="button" fluid={args.fluid} content={args.text} basic size={args.size || "mini"} icon={args.icon} />
        </StyledObjectButton>
      }
    >
      <Modal.Header>{args.title}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <ReactJson src={args.value} theme="chalk" displayObjectSize={false} displayDataTypes={false} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          content="Close"
          labelPosition="right"
          onClick={() => {
            setVisibleState(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};
