//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { Component, useState } from "react";
import { Button, Header, Icon, Message, Popup } from "semantic-ui-react";
import { User as ApiUser, MobilePhoneVerificationCode as ApiMobilePhoneVerificationCode } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import {
  Confirm,
  ConfirmInfo,
  Loading,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  FieldSet,
  ContentSegment,
  ErrorMessage,
  Processing,
} from "../../ui/lib";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import OrganizationList from "../organization/OrganizationList";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import FeatureFlags from "../feature/FeatureFlags";
import EmailMessageList from "../email/EmailMessageList";
import TabLayout from "../../components/Tab/Tab";
import { ITabConfig } from "../../components/Tab/TabTypes";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IGeneralViewArgs {
  user: ApiUser;
  onLoadVerificationCode?: () => void;
  onResendVerificationCode?: () => void;
  onVerifyCode?: () => void;
  onResetMobilePhone?: () => void;
  onSendVerificationEmail?: () => void;
  canResetMobilePhone: boolean;
  canSendVerificationEmail: boolean;
  resendingVerificationCode: boolean;
  loadingVerificationCode: boolean;
  verificationCode?: string;
  showId: boolean;
}

const GeneralView = ({ ...args }: IGeneralViewArgs) => {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const doesUserNeedVerification = (user: ApiUser) => {
    const { id = "" } = user;
    const nonVerifiableLoginTypes = ["google", "github"];
    return !nonVerifiableLoginTypes.find((type) => {
      return id.includes(type);
    });
  };

  const handleUserVerification = () => {
    const { user, onSendVerificationEmail } = args;
    const isVerificationRequired = doesUserNeedVerification(user);
    if (isVerificationRequired && onSendVerificationEmail) {
      onSendVerificationEmail();
    } else {
      setMessage("User doesn't need email verification since it does not use username and password explicitly");
    }
  };

  return (
    <>
      {!!message && <Message content={message} onDismiss={() => setMessage(undefined)} />}
      <FieldSet basic>
        <Field>
          <FL>Name</FL>
          <FC>{args.user.name || "-"}</FC>
        </Field>
        {args.showId && (
          <Field>
            <FL>ID</FL>
            <FC>{args.user.id || "-"}</FC>
          </Field>
        )}
        <Field>
          <FL>Email address</FL>
          <FC>{args.user.email || "-"}</FC>
        </Field>
        <Field>
          <FL>Given name</FL>
          <FC>{args.user.given_name || "-"}</FC>
        </Field>
        <Field>
          <FL>Family name</FL>
          <FC>{args.user.family_name || "-"}</FC>
        </Field>
        <Field>
          <FL>Company name</FL>
          <FC>{args.user.company_name || "-"}</FC>
        </Field>
        <Field>
          <FL>Slack name</FL>
          <FC>{args.user.slack_name || "-"}</FC>
        </Field>
        <Field>
          <FL>Mobile phone number</FL>
          <FC>
            <div>{args.user.mobile_phone ? "" : "-"}</div>
            {args.verificationCode && (
              <div>
                Verification code: <code>{args.verificationCode}</code>
              </div>
            )}
            {args.user.mobile_phone && (args.user.mobile_phone_verified ? "Verified" : "Not verified")}
            <div>
              {args.user.mobile_phone && args.onLoadVerificationCode && !args.verificationCode ? (
                <Button content="Show verification code" size="tiny" onClick={args.onLoadVerificationCode} disabled={args.loadingVerificationCode} />
              ) : undefined}
              {args.user.mobile_phone && !args.user.mobile_phone_verified && args.onResendVerificationCode ? (
                <Button content="Resend verification code" size="tiny" onClick={args.onResendVerificationCode} disabled={args.resendingVerificationCode} />
              ) : undefined}
              {args.user.mobile_phone && !args.user.mobile_phone_verified && args.onVerifyCode ? (
                <Button content="Verify code" size="tiny" onClick={args.onVerifyCode} />
              ) : undefined}
              {args.canResetMobilePhone && args.user.mobile_phone && args.onResetMobilePhone ? (
                <Button content="Reset mobile phone" size="tiny" onClick={args.onResetMobilePhone} />
              ) : undefined}
              {args.canSendVerificationEmail && args.user.id && args.onSendVerificationEmail ? (
                <Button content="Send verification email" size="tiny" onClick={handleUserVerification} />
              ) : undefined}
            </div>
          </FC>
        </Field>
        <Field>
          <FL>Created</FL>
          <FC>{args.user.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.user.created_at} label="Created at" /> : "-"}</FC>
        </Field>
        <Field>
          <FL>Last login</FL>
          <FC>
            {args.user.last_login_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.user.last_login_at} label="Last Login at" /> : "-"}
            {args.user.last_ip && <Popup trigger={<Icon name="at" />} content={`from ${args.user.last_ip}`} />}
          </FC>
        </Field>
      </FieldSet>
    </>
  );
};

interface IAuthenticationViewArgs {
  token: string;
  tokenCopied: boolean;
  onCopiedToken: () => void;
}

const AuthenticationView = ({ ...args }: IAuthenticationViewArgs) => (
  <FieldSet>
    <Header sub>Authorization</Header>
    <Field>
      <FL>Token</FL>
      <FC>
        <Popup
          trigger={
            <CopyToClipboard text={args.token} onCopy={args.onCopiedToken}>
              <Button icon="copy" />
            </CopyToClipboard>
          }
          content="Copy authentication token to clipboard"
        />
        {args.tokenCopied && "copied to clipboard"}
      </FC>
    </Field>
  </FieldSet>
);

interface IUserDetailsViewArgs extends IGeneralViewArgs, IAuthenticationViewArgs, IWithRefreshProps, RouteComponentProps {
  onOrganizationSelected: (organizationID: string) => void;
}

const UserDetailsView = ({ ...args }: IUserDetailsViewArgs) => {
  const tabConfig: ITabConfig[] = [
    {
      menuItem: { children: "User info", id: "userInfo" },
      content: () => (
        <div>
          <GeneralView {...args} />
          {args.token && <AuthenticationView {...args} />}
        </div>
      ),
    },
    {
      menuItem: { children: "Organizations", id: "organizations" },
      content: () => <OrganizationList {...args} inline member_of_user_id={args.user.id} />,
    },
    {
      menuItem: { children: "Email", id: "useEmail" },
      content: () => <EmailMessageList {...args} to={args.user.email} />,
    },
    {
      menuItem: { children: "Beta feature flags", id: "betaFlags" },
      content: () => <FeatureFlags {...args} user_id={args.user.id} />,
    },
  ];

  return <TabLayout tabConfig={tabConfig} id="userDetailsTab" />;
};

// Interface decribing the properties of the user component
interface IUserDetailsProps extends IWithRefreshProps, RouteComponentProps {
  user?: ApiUser;
  token?: string;
  showId: boolean;
  userFetchErrorMessage?: string;
  loadingUserDetail?: boolean;
  onClickVerifyCode?: () => void;
  onResendUserMobilePhoneVerification?: (user: ApiUser) => Promise<void>;
  onGetUserMobilePhoneVerification: (user: ApiUser) => Promise<ApiMobilePhoneVerificationCode>;
  onOrganizationSelected: (organizationID: string) => void;
  onResetMobilePhone?: (user: ApiUser) => Promise<void>;
  onSendVerificationEmail?: (user: ApiUser) => Promise<void>;
  onRefreshUser: () => void;
}

// Interface decribing the state of the user component
interface IUserDetailsState {
  processing: boolean;
  resendingVerificationCode: boolean;
  loadingVerificationCode: boolean;
  verificationCode?: string;
  errorMessage?: string;
  tokenCopied: boolean;
  prev_id?: string;
  resettingMobilePhone: boolean;
  sendingVerificationEmail: boolean;
  confirmResetMobilePhone?: ConfirmInfo;
  confirmSendVerificationEmail?: ConfirmInfo;
}

class UserDetails extends Component<IUserDetailsProps, IUserDetailsState> {
  state: IUserDetailsState = {
    processing: false,
    resendingVerificationCode: false,
    loadingVerificationCode: false,
    verificationCode: undefined,
    errorMessage: undefined,
    tokenCopied: false,
    resettingMobilePhone: false,
    sendingVerificationEmail: false,
    confirmResetMobilePhone: undefined,
    confirmSendVerificationEmail: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  onResendVerificationCode = async () => {
    const user = this.props.user;
    if (this.props.onResendUserMobilePhoneVerification && user) {
      try {
        this.setState({ resendingVerificationCode: true, errorMessage: undefined });
        await this.props.onResendUserMobilePhoneVerification(user);
      } catch (e) {
        this.setState({ errorMessage: `Resending verification code failed: ${e}` });
      }
      this.setState({ resendingVerificationCode: false });
    }
  };

  onLoadVerificationCode = async () => {
    const user = this.props.user;
    if (this.props.onGetUserMobilePhoneVerification && user) {
      try {
        this.setState({ loadingVerificationCode: true, errorMessage: undefined });
        const code = await this.props.onGetUserMobilePhoneVerification(user);
        this.setState({ verificationCode: code.code });
      } catch (e) {
        this.setState({ errorMessage: `Loading verification code failed: ${e}` });
      }
      this.setState({ loadingVerificationCode: false });
    }
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCopiedToken = () => {
    this.setState({ tokenCopied: true });
  };

  onResetMobilePhone = async () => {
    const user = this.props.user;
    const onResetMobilePhone = this.props.onResetMobilePhone;
    if (onResetMobilePhone && user) {
      const confirmInfo: ConfirmInfo = {
        header: "Reset mobile phone number for user",
        content: `Do you want to reset the mobile phone number for user ${user.email}?`,
        warning: "This will allow the user to create another account with this number",
        onConfirm: async () => {
          try {
            this.setState({ resettingMobilePhone: true, confirmResetMobilePhone: undefined, errorMessage: undefined });
            await onResetMobilePhone(user);
          } catch (e) {
            this.setState({ errorMessage: `Resetting mobile phone failed with: ${e}` });
          }
          this.setState({ resettingMobilePhone: false });
          this.props.onRefreshUser();
        },
        onDenied: () => {
          this.setState({ confirmResetMobilePhone: undefined });
        },
      };
      this.setState({
        confirmResetMobilePhone: confirmInfo,
      });
    }
  };

  onSendVerificationEmail = async () => {
    const user = this.props.user;
    const onSendVerificationEmail = this.props.onSendVerificationEmail;
    if (onSendVerificationEmail && user) {
      const confirmInfo: ConfirmInfo = {
        header: "Send verification email for user",
        content: `Do you want to send a verification email for user ${user.id}?`,
        onConfirm: async () => {
          try {
            this.setState({ sendingVerificationEmail: true, confirmSendVerificationEmail: undefined, errorMessage: undefined });
            await onSendVerificationEmail(user);
          } catch (e) {
            this.setState({ errorMessage: `Sending verification email failed with: ${e}` });
          }
          this.setState({ sendingVerificationEmail: false });
          this.props.onRefreshUser();
        },
        onDenied: () => {
          this.setState({ confirmSendVerificationEmail: undefined });
        },
      };
      this.setState({
        confirmSendVerificationEmail: confirmInfo,
      });
    }
  };

  static getDerivedStateFromProps(props: IUserDetailsProps, state: IUserDetailsState) {
    const userId = (props.user || {}).id;
    if (userId !== state.prev_id) {
      return {
        prev_id: userId,
        loadingVerificationCode: false,
        resendingVerificationCode: false,
        verificationCode: undefined,
      };
    }
    // No state update necessary
    return null;
  }

  render() {
    const token = this.props.token;
    const user = this.props.user;
    const can_reset_mobile_phone = this.hasPermission("internal-dashboard.user.reset-mobile-phone");
    const can_send_verification_email = this.hasPermission("internal-dashboard.user.send-verification-email");

    return (
      <ContentSegment>
        <Confirm confirmInfo={this.state.confirmResetMobilePhone} />
        <Confirm confirmInfo={this.state.confirmSendVerificationEmail} />
        <Processing active={this.state.resettingMobilePhone} message="Resetting mobile phone..." />
        <ErrorMessage
          active={!!this.state.errorMessage || !!this.props.userFetchErrorMessage}
          onDismiss={this.handleDismissError}
          message={this.state.errorMessage || this.props.userFetchErrorMessage}
        />
        {this.props.loadingUserDetail && <Loading />}
        {user && !this.props.loadingUserDetail && (
          <UserDetailsView
            {...this.props}
            user={user}
            onResendVerificationCode={this.props.onResendUserMobilePhoneVerification && this.onResendVerificationCode}
            onLoadVerificationCode={this.onLoadVerificationCode}
            onResetMobilePhone={this.props.onResetMobilePhone && this.onResetMobilePhone}
            canResetMobilePhone={can_reset_mobile_phone}
            canSendVerificationEmail={can_send_verification_email}
            onSendVerificationEmail={this.props.onSendVerificationEmail && this.onSendVerificationEmail}
            resendingVerificationCode={this.state.resendingVerificationCode}
            loadingVerificationCode={this.state.loadingVerificationCode}
            verificationCode={this.state.verificationCode}
            onVerifyCode={this.props.onClickVerifyCode}
            token={token || ""}
            tokenCopied={this.state.tokenCopied}
            onCopiedToken={this.onCopiedToken}
            showId={this.props.showId}
          />
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(UserDetails);
