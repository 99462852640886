//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany

import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Table, Button, Modal, Form } from "semantic-ui-react";
import { Routes } from "../../routes";
import apiClients from "../../api/apiclients";
import { map, isEmpty } from "lodash";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { Organization } from "../../api/lib";
import {
  Credit,
  CreditUsage,
  DiscardUncommitedCreditUsageRequest,
  ListCreditRequest,
  ListCreditUsageRequest,
  SetCreditExhaustedRequest,
} from "../../api/billing/v1/ibilling";
import {
  TextLink,
  Confirm,
  ConfirmInfo,
  CurrencyInput,
  ErrorMessage,
  FloatInput,
  ContentSegment,
  Loading,
  UserLink,
  FormActionButtonCreate,
  FormActionButtonCancel,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { IWithRefreshProps } from "../../util/WithRefresh";

export interface IOrganizationCreditsArgs extends IWithRefreshProps, RouteComponentProps {
  organization: Organization;
}

export const OrganizationCreditsView = (args: IOrganizationCreditsArgs) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [items, setItems] = useState<Credit[]>([]);
  const [processing, setProcessing] = useState(false);
  const [create, setCreate] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);

  const hasPermission = (permission: Permission) => hasSupportPermission(permission, args.hasPermissionByUrl);

  const reloadItems = async () => {
    setProcessing(true);
    const req: ListCreditRequest = { organization_id: args.organization.id };
    try {
      const list = await apiClients.idashboardClient.ListCredit(req);
      setItems(list.items || []);
    } catch (e) {
      setErrorMessage(e);
    } finally {
      setProcessing(false);
    }
  };

  const handleDismissError = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (!isEmpty(args.organization)) {
      reloadItems();
    }
  }, [args.organization.id]);

  const setExhausted = (credit: Credit) => {
    setConfirmInfo({
      header: "Are you sure you want to set credit to exhausted?",
      content: `Are you sure you want to set credit with ${credit.amount} ${credit.currency_id} to exhausted?`,
      onDenied: () => {
        setConfirmInfo(undefined);
      },
      onConfirm: async () => {
        setProcessing(true);
        try {
          await apiClients.idashboardClient.SetCreditExhausted({ credit_id: credit.id } as SetCreditExhaustedRequest);
          reloadItems();
        } catch (e) {
          setErrorMessage(e);
        } finally {
          setConfirmInfo(undefined);
          setProcessing(false);
        }
      },
    } as ConfirmInfo);
  };

  const canCreate = hasPermission("internal-dashboard.credit.create");
  const canSetExhausted = hasPermission("internal-dashboard.credit.set-exhausted");
  const canListCreditUsages = hasPermission("internal-dashboard.credit.list-usage");

  const CreditRow = (item: Credit) => {
    return (
      <Table.Row key={item.id} negative={item.is_exhausted}>
        <Table.Cell>
          <DateTimePopupWithUTCAndLocalTime dateTime={item.created_at} label="Created at" />
        </Table.Cell>
        <Table.Cell>
          {canListCreditUsages ? <CreditUsageAmount {...args} credit={item} onError={setErrorMessage} /> : "..."} / {item.amount} {item.currency_id}
        </Table.Cell>
        <Table.Cell>
          <UserLink id={item.created_by_id || ""} />
        </Table.Cell>
        <Table.Cell>
          {canSetExhausted && !item.is_exhausted && (
            <Button
              icon="trash"
              label="SetExhausted"
              onClick={() => {
                setExhausted(item);
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  const createCredit = () => {
    setCreate(true);
  };

  const onCreateClose = () => {
    setCreate(false);
    reloadItems();
  };

  if (processing) {
    return (
      <ContentSegment>
        <Loading message="loading vouchers" />
      </ContentSegment>
    );
  } else {
    return (
      <ContentSegment>
        <ErrorMessage active={!!errorMessage} onDismiss={handleDismissError} message={errorMessage} />
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Created at</Table.HeaderCell>
              <Table.HeaderCell>(Used / Amount) </Table.HeaderCell>
              <Table.HeaderCell>Created by id</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">{canCreate && <Button icon="plus" size="tiny" basic onClick={createCredit} />}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{map(items, CreditRow)}</Table.Body>
        </Table>
        <OrganizationCreateCreditModal {...args} organization={args.organization} open={create} onClose={onCreateClose} />
        <Confirm confirmInfo={confirmInfo} />
      </ContentSegment>
    );
  }
};

export interface IOrganizationCreateCreditsArgs extends IWithRefreshProps, RouteComponentProps {
  open: boolean;
  organization: Organization;
  onClose: () => void;
}

const OrganizationCreateCreditModal = (args: IOrganizationCreateCreditsArgs) => {
  const [amount, setAmount] = useState(0.0);
  const [currency, setCurrency] = useState("usd");
  const [errorMessage, setErrorMessage] = useState("");

  const createCredit = () => {
    const create = async () => {
      await apiClients.idashboardClient.CreateCredit({
        organization_id: args.organization.id,
        amount: amount,
        currency_id: currency,
      } as Credit);
      args.onClose();
    };
    create().catch(setErrorMessage);
  };

  return (
    <Modal open={args.open}>
      <Modal.Header>Create a voucher for {args.organization.name}</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!errorMessage} onDismiss={() => setErrorMessage("")} message={errorMessage} />
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Amount</label>
              <FloatInput value={amount} onChange={setAmount} />
            </Form.Field>
            <Form.Field required>
              <label>Currency</label>
              <CurrencyInput {...args} placeholder="currency" id={currency} onChange={setCurrency} allowDefault />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCreate onClick={createCredit} />
        <FormActionButtonCancel onClick={args.onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export interface CreditUsageAmountProps extends IWithRefreshProps, RouteComponentProps {
  credit: Credit;
  onError: (e: string) => void;
}

const CreditUsageAmount = (args: CreditUsageAmountProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [items, setItems] = useState<CreditUsage[]>([]);
  const [sum, setSum] = useState("...");
  const [showModal, setShowModal] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);

  const hasPermission = (permission: Permission) => hasSupportPermission(permission, args.hasPermissionByUrl);
  const canDiscardCreditUsage = hasPermission("internal-dashboard.credit.discard-usage");

  const reload = async () => {
    try {
      const usageList = await apiClients.idashboardClient.ListCreditUsage({
        credit_id: args.credit.id,
        organization_id: args.credit.organization_id,
      } as ListCreditUsageRequest);
      const usages = usageList.items || [];
      setItems(usages);
      let s = 0.0;
      for (const item of usages) {
        if (!item.commited_at) {
          // don't sum uncommited usages
          continue;
        }
        s += item.credit_currency_amount || 0;
      }
      setSum(s.toFixed(2));
    } catch (e) {
      args.onError(e);
    }
  };
  useEffect(() => {
    reload();
  }, [args.credit.id]);

  const discardUsage = (usage: CreditUsage) => {
    setConfirmInfo({
      header: "Are you sure you want to set discard voucher usage?",
      content: `Are you sure you want to set discard voucer usage of ${usage.amount} ${usage.currency_id}? This will set amount to 0 and mark it as commited`,
      onDenied: () => {
        setConfirmInfo(undefined);
      },
      onConfirm: async () => {
        try {
          await apiClients.idashboardClient.DiscardUncommitedCreditUsage({ credit_usage_id: usage.id } as DiscardUncommitedCreditUsageRequest);
          reload();
        } catch (e) {
          setErrorMessage(e);
        } finally {
          setConfirmInfo(undefined);
        }
      },
    } as ConfirmInfo);
  };

  const CreditUsageRow = (item: CreditUsage) => {
    const amount = item.amount || 0;
    const credit_currency_amount = item.credit_currency_amount || 0;
    return (
      <Table.Row>
        <Table.Cell>
          <DateTimePopupWithUTCAndLocalTime dateTime={item.created_at} />
        </Table.Cell>
        <Table.Cell>
          {amount.toFixed(2)} {item.currency_id}
        </Table.Cell>
        <Table.Cell>
          {credit_currency_amount.toFixed(2)} {args.credit.currency_id}
        </Table.Cell>
        <Table.Cell>
          <TextLink href={Routes.dashboard_sales_invoices_detailsWithId(item.invoice_id || "")} label={item.invoice_id || ""} />
        </Table.Cell>
        <Table.Cell>
          {!!item.commited_at && <DateTimePopupWithUTCAndLocalTime dateTime={item.commited_at} />}
          {canDiscardCreditUsage && !item.commited_at && (
            <Button
              icon="trash"
              label="Discard usage"
              onClick={() => {
                discardUsage(item);
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };
  return (
    <span>
      <TextLink
        label={sum}
        onClick={() => {
          setShowModal(true);
        }}
      />
      <Modal open={showModal}>
        <Modal.Header>
          Usages for credit {args.credit.id} {args.credit.amount} {args.credit.currency_id}
        </Modal.Header>
        <Modal.Content>
          <ErrorMessage
            active={!!errorMessage}
            onDismiss={() => {
              setErrorMessage("");
            }}
            message={errorMessage}
          />
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Created at</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Credit amount</Table.HeaderCell>
                <Table.HeaderCell>Invoice id</Table.HeaderCell>
                <Table.HeaderCell>Commited at</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{map(items, CreditUsageRow)}</Table.Body>
          </Table>
          <Confirm confirmInfo={confirmInfo} />
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Actions>
      </Modal>
    </span>
  );
};

export default OrganizationCreditsView;
