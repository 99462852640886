//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dimmer, Loader, Menu, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { DetectSilentOutOfSyncRequest as ApiDetectSilentOutOfSyncRequest } from "../../api/lib";
import { ContentActionButton } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

const DataContainer = styled("div")`
  min-height: 20vh;
`;

interface ISilentOutOfSyncModalViewArgs {
  open: boolean;
  loading: boolean;
  report?: string;
  onClose: () => void;
  onTestCount: (exclusiveLock: boolean) => void;
  onTestHashing: (exclusiveLock: boolean) => void;
}

const SilentOutOfSyncModalView = ({ ...args }: ISilentOutOfSyncModalViewArgs) => {
  const empty = _.isEmpty(args.report);

  return (
    <Modal open={args.open} onClose={args.onClose} size="fullscreen">
      <Modal.Content scrolling>
        <Menu borderless pointing stackable>
          <Menu.Item>
            <Button onClick={() => args.onTestCount(false)} disabled={args.loading}>
              Run count test
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button onClick={() => args.onTestCount(true)} disabled={args.loading} color="yellow">
              Run count test (exclusive)
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button onClick={() => args.onTestHashing(false)} disabled={args.loading} color="yellow">
              Run hashing test
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button onClick={() => args.onTestHashing(true)} disabled={args.loading} color="orange">
              Run hashing test (exclusive)
            </Button>
          </Menu.Item>
        </Menu>
        <DataContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Detecting silent out-of-sync bug, please wait
              </Loader>
            </Dimmer>
          )}
          {!args.loading && !empty && <pre>{args.report}</pre>}
          {!args.loading && empty && (
            <span>
              <i>No data</i>
            </span>
          )}
        </DataContainer>
      </Modal.Content>
    </Modal>
  );
};

interface ISilentOutOfSyncModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
}

interface ISilentOutOfSyncModalState {
  open: boolean;
  loading: boolean;
  report?: string;
  errorMessage?: string;
}

// Component to detect silent-out-of-sync condition
class SilentOutOfSyncModal extends Component<ISilentOutOfSyncModalProps, ISilentOutOfSyncModalState> {
  state: ISilentOutOfSyncModalState = {
    open: false,
    loading: false,
    report: undefined,
    errorMessage: undefined,
  };

  reloadSilentOutOfSync = async (exclusiveLock: boolean, hashingTest: boolean) => {
    this.setState({ loading: true, report: undefined, errorMessage: undefined });
    try {
      const req: ApiDetectSilentOutOfSyncRequest = {
        deployment_id: this.props.deploymentId,
        exclusive_lock: exclusiveLock,
        hashing_test: hashingTest,
      };
      const stream = await apiClients.idashboardClient.DetectSilentOutOfSync(req, (x) => {
        const err = x.error;
        const msg = x.message;
        if (!!err) {
          this.setState({ errorMessage: x.error });
        } else if (!!msg) {
          const chunk = msg.chunk;
          if (!!chunk) {
            const decoded = atob(chunk);
            this.setState((old) => {
              return { report: (old.report || "") + decoded };
            });
          }
        }
      });
      await stream.closed;
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, report: undefined, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  onTestCount = (exclusiveLock: boolean) => {
    this.reloadSilentOutOfSync(exclusiveLock, false);
  };
  onTestHashing = (exclusiveLock: boolean) => {
    this.reloadSilentOutOfSync(exclusiveLock, true);
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Detect silent out-of-sync" icon="stethoscope" />
        <SilentOutOfSyncModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(SilentOutOfSyncModal);
