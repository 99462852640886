//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";

interface IChildren {
  children?: any;
}

export const Section = ({ ...args }: IChildren) => <section className="section">{args.children}</section>;

export const SectionHead = ({ ...args }: IChildren) => <div className="section__head">{args.children}</div>;

interface ISectionHeader extends IChildren {
  title: string;
}

export const SectionHeader = ({ ...args }: ISectionHeader) => (
  <header className="section__header">
    <div className="section__title">{args.title}</div>
    {args.children}
  </header>
);

interface ISectionContent extends IChildren {
  topAttached?: boolean;
}

export const SectionContent = ({ ...args }: ISectionContent) => {
  let className = "section__content";
  if (args.topAttached) {
    className += " top-attached";
  }
  return <section className={className}>{args.children}</section>;
};

export const SectionAddons = ({ ...args }: IChildren) => (
  <ol className="section__addons">
    <li>{args.children}</li>
  </ol>
);

export const SectionButtons = ({ ...args }: IChildren) => <div className="section__buttons">{args.children}</div>;
