//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import _ from "lodash";
import { Component } from "react";
import { Form, Message, Modal, InputOnChangeData } from "semantic-ui-react";
import { ConversionRate as ApiConversionRate, Currency as ApiCurrency } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Processing, FormActionButtonCancel, FormActionButtonSave } from "../../ui/lib";

interface IRateArgs extends IWithRefreshProps, RouteComponentProps {
  currency_id: string;
  system_default_currency: ApiCurrency;
  rate: string;
  rate_is_valid: boolean;
  onRateChange: (e: any, data: InputOnChangeData) => void;
  oneBaseEqualsXCurrency: number;
  oneCurrencyEqualsXBase: number;
}

const RateView = ({ ...args }: IRateArgs) => {
  const is_default = args.currency_id == args.system_default_currency.id;
  return (
    <Form>
      <Form.Input required label="Rate" id="rate" value={_.toString(args.rate)} onChange={args.onRateChange} error={!args.rate_is_valid} />
      {!is_default && (
        <div>
          <p>
            amount-in-{args.currency_id} = amount-in-{args.system_default_currency.id} * rate.
          </p>
          <p>
            1 {args.system_default_currency.id} = {args.oneBaseEqualsXCurrency} {args.currency_id};
          </p>
          <p>
            1 {args.currency_id} = {args.oneCurrencyEqualsXBase} {args.system_default_currency.id};
          </p>
        </div>
      )}
    </Form>
  );
};

interface IAPI {
  SetConversionRate: (req: ApiConversionRate) => Promise<void>;
}

// Interface decribing the properties of the set conversion rate component
interface ISetConversionRateProps extends IWithRefreshProps, RouteComponentProps {
  api: IAPI;
  system_default_currency: ApiCurrency;
  currency_id: string;
  rate?: number;
  onClose: () => void;
}

// Interface decribing the state of the set conversion rate component
interface ISetConversionRateState {
  rate: string;
  saving: boolean;
  errorMessage?: string;
  oneBaseEqualsXCurrency: number;
  oneCurrencyEqualsXBase: number;
}

// The component to set conversion rate
class SetConversionRate extends Component<ISetConversionRateProps, ISetConversionRateState> {
  state: ISetConversionRateState = {
    rate: _.toString(this.props.rate || 1.0),
    saving: false,
    errorMessage: undefined,
    oneBaseEqualsXCurrency: 0,
    oneCurrencyEqualsXBase: 0,
  };

  componentDidMount() {
    this.refreshSamples();
  }

  onRateChange = (e: any, data: InputOnChangeData) => {
    const rate = data.value;
    this.setState({ rate: rate }, this.refreshSamples);
  };

  refreshSamples = async () => {
    if (!this.rateIsValid()) {
      this.setState({
        errorMessage: "Invalid rate",
        oneBaseEqualsXCurrency: 0,
        oneCurrencyEqualsXBase: 0,
      });
      return;
    }
    try {
      const rate = _.toNumber(this.state.rate);
      const oneCurrencyEqualsXBase = 1.0 / rate;
      const oneBaseEqualsXCurrency = 1.0 * rate;
      this.setState({
        errorMessage: undefined,
        oneBaseEqualsXCurrency: oneBaseEqualsXCurrency,
        oneCurrencyEqualsXBase: oneCurrencyEqualsXBase,
      });
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  onSave = async () => {
    try {
      this.setState({ saving: true, errorMessage: undefined });
      const req: ApiConversionRate = {
        currency_id: this.props.currency_id,
        rate: _.toNumber(this.state.rate),
      };
      await this.props.api.SetConversionRate(req);
      this.props.onClose();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ saving: false });
    }
  };

  rateIsValid = () => {
    try {
      const rate = _.toNumber(this.state.rate);
      if (this.props.system_default_currency.id == this.props.currency_id) {
        return rate == 1;
      }
      return rate > 0;
    } catch (e) {
      return false;
    }
  };

  render() {
    const all_valid = this.rateIsValid();
    return (
      <Modal open>
        <Modal.Header>Set conversion rate</Modal.Header>
        <Modal.Content>
          <Processing active={this.state.saving} message="Storing conversion rate..." />
          {this.state.errorMessage && <Message error content={`${this.state.errorMessage}`} />}
          <RateView {...this.props} {...this.state} onRateChange={this.onRateChange} rate_is_valid={all_valid} />
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel onClick={this.props.onClose} />
          <FormActionButtonSave onClick={this.onSave} disabled={!all_valid && !this.state.saving} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRefresh()(SetConversionRate);
