//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { OrganizationInfo as ApiOrganizationInfo, SetOrganizationUsesCreditBundlesRequest as ApiSetOrganizationUsesCreditBundlesRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IUsesCreditBundlesFieldViewArgs {
  organizationInfo: ApiOrganizationInfo;
  canSetUsesCreditBundles: boolean;
  onSetOrganizationUsesCreditBundles: (uses: boolean) => void;
}

const CustomDeploymentSettingsToggle = ({ ...args }: IUsesCreditBundlesFieldViewArgs) => {
  const uses_credit_bundles = !!args.organizationInfo.uses_credit_bundles;
  return (
    <>
      <Field>
        <FL>Using Credit Bundles</FL>
        <FC>
          {uses_credit_bundles ? "Allowed" : "Not Allowed"}
          {args.canSetUsesCreditBundles && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!uses_credit_bundles && <Dropdown.Item text="Allow" onClick={() => args.onSetOrganizationUsesCreditBundles(true)} />}
                {uses_credit_bundles && <Dropdown.Item text="Do not allow" onClick={() => args.onSetOrganizationUsesCreditBundles(false)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
    </>
  );
};

interface IUsesCreditBundlesFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface IUsesCreditBundlesFieldState extends IOrganizationDetailsFieldState {
  commentPrompt: ICommentPromptStateForIntegrations;
}

class UsesCreditBundlesField extends OrganizationDetailsField<IUsesCreditBundlesFieldProps, IUsesCreditBundlesFieldState> {
  state: IUsesCreditBundlesFieldState = {
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };
  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  onSetOrganizationUsesCreditBundles = async (uses: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set organization to ${uses ? "allow" : "disallow"} using credit bundles`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization credit bundle usage, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationUsesCreditBundlesRequest = {
            organization_id: this.props.organization.id,
            uses_credit_bundles: uses,
          };
          await apiClients.idashboardClient.SetOrganizationUsesCreditBundles(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const can_set_uses_credit_bundles = this.hasPermission("internal-dashboard.organization.set-uses-credit-bundles");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <CustomDeploymentSettingsToggle {...this} {...this.props} {...this.state} canSetUsesCreditBundles={can_set_uses_credit_bundles} />
      </div>
    );
  }
}

export default withRefresh()(UsesCreditBundlesField);
