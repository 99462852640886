//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Button, Form, Icon, InputOnChangeData, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { EmailPattern as ApiEmailPattern } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Processing } from "../../ui/lib";

interface ICreateEmailPatternViewArgs {
  visible: boolean;
  pattern: string;
  allow: boolean;
  deny: boolean;
  canCreate: boolean;
  creating: boolean;
  errorMessage?: string;
  onPatternChanged: (value: string) => void;
  onToggleAllow: () => void;
  onToggleDeny: () => void;
  onCreate: () => void;
  onCancel: () => void;
  handleDismissError: () => void;
}

const CreateEmailPatternView = ({ ...args }: ICreateEmailPatternViewArgs) => (
  <Modal open={args.visible}>
    <Modal.Header>Create new email pattern</Modal.Header>
    <Modal.Content>
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <Modal.Description>
        <Form>
          <Form.Field>
            <Form.Input
              name="pattern"
              placeholder="pattern"
              value={args.pattern}
              onChange={(e: any, data: InputOnChangeData) => args.onPatternChanged(data.value)}
              disabled={args.creating}
              autoFocus
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox name="allow" placeholder="allow" checked={args.allow} onClick={args.onToggleAllow} disabled={args.creating} label="Allow" />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox name="deny" placeholder="deny" checked={args.deny} onClick={args.onToggleDeny} disabled={args.creating} label="Deny" />
          </Form.Field>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={args.onCancel} disabled={args.creating}>
        <Icon name="remove" />
        Cancel
      </Button>
      <Button positive onClick={args.onCreate} disabled={!args.canCreate || args.creating}>
        <Icon name="checkmark" />
        Create
      </Button>
    </Modal.Actions>
  </Modal>
);

// Interface decribing the properties of the email pattern create component
interface ICreateEmailPatternProps extends IWithRefreshProps, RouteComponentProps {
  visible: boolean;
  onEmailPatternCreated: (id: string) => void;
  onClose: () => void;
}

// Interface decribing the state of the email pattern create component
interface ICreateEmailPatternState {
  pattern: string;
  allow: boolean;
  deny: boolean;

  errorMessage?: string;
  processing: boolean;
}

// The component to show email pattern creation modal
class CreateEmailPattern extends Component<ICreateEmailPatternProps, ICreateEmailPatternState> {
  state: ICreateEmailPatternState = {
    pattern: "",
    allow: true,
    deny: false,
    errorMessage: undefined,
    processing: false,
  };

  onCreateEmailPattern = async () => {
    this.setState({ processing: true });
    try {
      var req: ApiEmailPattern = {
        pattern: this.state.pattern,
        allow: this.state.allow,
        deny: this.state.deny,
      };
      const pattern = await apiClients.idashboardClient.CreateEmailPattern(req);
      this.props.onEmailPatternCreated(pattern.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Email pattern creation failed: ${e}` });
    }
    this.setState({ processing: false });
  };

  onPatternChanged = (x: string) => {
    this.setState({ pattern: x });
  };

  onToggleAllow = () => {
    this.setState((prev: ICreateEmailPatternState) => {
      return {
        allow: !prev.allow,
        deny: prev.allow,
      };
    });
  };

  onToggleDeny = () => {
    this.setState((prev: ICreateEmailPatternState) => {
      return {
        deny: !prev.deny,
        allow: prev.deny,
      };
    });
  };

  isValid = (): boolean => {
    return this.state.pattern != "" && (this.state.allow || this.state.deny) && !(this.state.allow && this.state.deny);
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <Processing active={this.state.processing} message="Creating email pattern, please wait..." />

        <CreateEmailPatternView
          {...this.props}
          {...this.state}
          canCreate={this.isValid()}
          creating={this.state.processing}
          errorMessage={this.state.errorMessage}
          onCreate={this.onCreateEmailPattern}
          onCancel={this.props.onClose}
          handleDismissError={this.handleDismissError}
          onPatternChanged={this.onPatternChanged}
          onToggleAllow={this.onToggleAllow}
          onToggleDeny={this.onToggleDeny}
        />
      </div>
    );
  }
}

export default withRefresh()(CreateEmailPattern);
