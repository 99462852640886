//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Table, Popup, Form, InputOnChangeData, Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  NodePool as ApiNodePool,
  NodePoolList as ApiNodePoolList,
  ListNodePoolsRequest as ApiListNodePoolsRequest,
  DataCluster as ApiDataCluster,
  ScaleNodePoolRequest as ApiScaleNodePoolRequest,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { Loading, ErrorMessage, PagingButtons, LoaderBox } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import _ from "lodash";
import ReactJson from "react-json-view";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IHeaderView {
  loading: boolean;
  page: number;
  count: number;
  pageSize: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const HeaderView = (args: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Provider server type</Table.HeaderCell>
      <Table.HeaderCell>Zone</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Instance price per hour</Table.HeaderCell>
      <Table.HeaderCell>Instance count</Table.HeaderCell>
      <Table.HeaderCell>Status: Requested instances</Table.HeaderCell>
      <Table.HeaderCell>Status: Running instances</Table.HeaderCell>
      <Table.HeaderCell>Last updated</Table.HeaderCell>
      <Table.HeaderCell>
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  active: boolean;
  item: ApiNodePool;
  canScale: boolean;
  onScaleNodePool: (nodepoolID: string, count: number) => void;
}

const RowView = ({ ...args }: IRowView) => {
  const [loading, setLoading] = React.useState(false);
  const item = args.item || {};
  const status = item.status || {};
  const scale = (evt: React.ChangeEvent, value: InputOnChangeData) => {
    setLoading(true);
    args.onScaleNodePool(item.name || "", parseInt(value.value));
    setLoading(false);
  };

  return (
    <Table.Row>
      <Table.Cell>
        {item.provider_server_type || "-"} (vCPU: {status.cpu} {status.arch}, RAM: {status.memory_mb} MB{status.gpu ? ", GPU: " + status.gpu : ""})
      </Table.Cell>
      <Table.Cell>{item.zone || "-"}</Table.Cell>
      <Table.Cell>
        <Popup trigger={<u>{item.name || ""}</u>} content={<ReactJson src={item} collapsed={1} />} on="click" pinned />
      </Table.Cell>
      <Table.Cell>{status.status}</Table.Cell>
      <Table.Cell>{status.instance_price_per_hour} USD</Table.Cell>
      <Table.Cell>
        <Form.Input defaultValue={item.instance_count || 0} type="number" loading={loading} onChange={scale} disabled={!args.canScale} />
      </Table.Cell>
      <Table.Cell>{status.requested_instances || 0}</Table.Cell>
      <Table.Cell>{status.running_instances || 0}</Table.Cell>
      <Table.Cell>{status.last_update ? <DateTimePopupWithUTCAndLocalTime dateTime={status.last_update} label="Last updated at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  active: boolean;
  items: ApiNodePool[];
  loading: boolean;
  page: number;
  pageSize: number;
  canScale: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onScaleNodePool: (nodepoolID: string, count: number) => void;
}

const ListView = (args: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.name} item={item} onScaleNodePool={args.onScaleNodePool} canScale={args.canScale} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No node pools</div>;

export interface INodePoolListViewArgs extends RouteComponentProps {
  active: boolean;
  loading: boolean;
  nodePools?: ApiNodePoolList;
  page: number;
  pageSize: number;
  canScale: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onScaleNodePool: (nodepoolID: string, count: number) => void;
}

export const NodePoolListView = (args: INodePoolListViewArgs) => {
  if (!args.nodePools) {
    return <Loading />;
  }
  console.log("NodePoolListView", args.page);
  const items = args.nodePools.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={items} />;
};

interface INodePoolListProps extends IWithRefreshProps, RouteComponentProps {
  datacluster?: ApiDataCluster;
  canScale: boolean;
}

interface INodePoolListState {
  errorMessage?: string;
  loading: boolean;
  nodePools?: ApiNodePoolList;
  page: number;
  pageSize: number;
}

// The component to show the data cluster node pool list.
class NodePoolList extends Component<INodePoolListProps, INodePoolListState> {
  state: INodePoolListState = {
    errorMessage: undefined,
    loading: true,
    nodePools: undefined,
    page: 0,
    pageSize: 50,
  };

  onNextPage = async () => {
    this.setState((state) => ({ page: state.page + 1 }));
    this.reloadNodePools();
  };

  onPrevPage = async () => {
    this.setState((state) => ({ page: state.page - 1 }));
    this.reloadNodePools();
  };

  reloadNodePools = async () => {
    this.setState({ loading: true });
    const listOptions: ApiListNodePoolsRequest = {
      datacluster_id: this.props.datacluster && this.props.datacluster.id,
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    const nodePools = await apiClients.idashboardClient.ListNodePools(listOptions);
    this.setState({ nodePools: nodePools, loading: false });
  };

  componentDidMount() {
    if (this.props.datacluster) {
      const dataclusterUrl = `/Organization/_system/DataCluster/${this.props.datacluster.id}`; // note: datacluster.url doesn't exist
      this.props.subscribeUrl && this.props.subscribeUrl(this.reloadNodePools, `${dataclusterUrl}/NodePool/*`);
    }
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onScaleNodePool = async (name: string, count: number) => {
    const req: ApiScaleNodePoolRequest = {
      datacluster_id: this.props.datacluster && this.props.datacluster.id,
      name: name,
      requested_instances: count,
    };
    try {
      await apiClients.idashboardClient.ScaleNodePool(req);
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  render() {
    return (
      <div>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <NodePoolListView
          {...this.props}
          {...this.state}
          active={!this.state.loading}
          onScaleNodePool={this.onScaleNodePool}
          onNextPage={this.onNextPage}
          onPreviousPage={this.onPrevPage}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default withRefresh()(NodePoolList);
