//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

export type HumanizeFileSizeOptions = {
  useIECSystemThreshold?: boolean;
  fractionDigits?: number;
  noSuffix?: boolean;
};

const FileSizeModule = () => {
  const getUnits = (useIECSystemThreshold: boolean = true): string[] => {
    return useIECSystemThreshold ? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"] : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  };

  const getThreshold = (useIECSystemThreshold: boolean = true): number => {
    return useIECSystemThreshold ? 1024 : 1000;
  };

  const getSizeAndUnitIndex = (size: number, threshold: number, unitArrayLength: number): { size: number; index: number } => {
    let index = -1;
    do {
      size /= threshold;
      ++index;
    } while (Math.abs(size) >= threshold && index < unitArrayLength);
    return { size, index };
  };

  const humanizeFileSize = (size: number = 0, options?: HumanizeFileSizeOptions): string => {
    const { useIECSystemThreshold = true, fractionDigits = 2, noSuffix } = options || {}; // This means IEC/Metric system is selected by default which indicates that the base is 2, threshold is 1024 and units in KiB, Gib etc..
    const UNITS = getUnits(useIECSystemThreshold);
    const threshold = getThreshold(useIECSystemThreshold);

    if (Math.abs(size) < threshold) {
      const abs = Math.abs(size);
      return `${abs.toFixed(0)}${noSuffix ? "" : " Bytes"}`;
    }
    const { size: convertedSize, index: unitIndex } = getSizeAndUnitIndex(size, threshold, UNITS.length - 1);
    return `${convertedSize.toFixed(fractionDigits)}${noSuffix ? "" : " " + UNITS[unitIndex]}`;
  };

  return {
    humanizeFileSize,
  };
};

const { humanizeFileSize } = FileSizeModule();
export { humanizeFileSize };
