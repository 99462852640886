//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Gergely Brautigam
// Author Ewout Prangsma
//

import _ from "lodash";
import { Component } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { PublicEmailProviderDomain as ApiPublicEmailProviderDomain } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Processing } from "../../ui/lib";

interface ICreatePublicEmailProviderDomainViewArgs {
  visible: boolean;
  domains: string[];
  count: number;
  canCreate: boolean;
  creating: boolean;
  errorMessage?: string;
  dismissErrorHandle: () => void;
  onDomainsChanged: (value: string[]) => void;
  onCreate: () => void;
  onCancel: () => void;
}

const CreatePublicEmailProviderDomainView = ({ ...args }: ICreatePublicEmailProviderDomainViewArgs) => {
  const splitLines = (input: string) => {
    return input.split("\n");
  };
  const createText = args.count > 0 ? `Create (${args.count})` : "Create";
  return (
    <Modal open={args.visible}>
      <Modal.Header>Create new public email provider domain</Modal.Header>
      <Modal.Content>
        <ErrorMessage
          active={!!args.errorMessage}
          onDismiss={args.dismissErrorHandle}
          message={args.errorMessage}
          header="Creating public email provider domain(s) failed"
        />
        <Modal.Description>
          <Form>
            <Form.Field>
              <Form.TextArea
                label="Domains (one per line)"
                name="domain"
                placeholder="domain"
                value={args.domains.join("\n")}
                onChange={(e, data) => args.onDomainsChanged(splitLines(data.value as string))}
                disabled={args.creating}
                autoFocus
                rows={10}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" icon="remove" negative onClick={args.onCancel} disabled={args.creating} />
        <Button content={createText} icon="checkmark" positive onClick={args.onCreate} disabled={!args.canCreate || args.creating} />
      </Modal.Actions>
    </Modal>
  );
};

// Interface decribing the properties of the public email provider domain create component
interface ICreatePublicEmailProviderDomainProps extends IWithRefreshProps, RouteComponentProps {
  visible: boolean;
  onPublicEmailProviderDomainCreated: (id: string) => void;
  onClose: () => void;
}

// Interface decribing the state of the public email provider domain create component
interface ICreatePublicEmailProviderDomainState {
  domains: string[];
  count: number;
  errorMessage?: string;
  processing: boolean;
}

// The component to show public email provider domain creation modal
class CreatePublicEmailProviderDomain extends Component<ICreatePublicEmailProviderDomainProps, ICreatePublicEmailProviderDomainState> {
  state: ICreatePublicEmailProviderDomainState = {
    domains: [],
    count: 0,
    errorMessage: undefined,
    processing: false,
  };

  onCreatePublicEmailProviderDomains = async () => {
    this.setState({ processing: true });
    try {
      const nonEmpty = _.filter(this.state.domains, (x) => !_.isEmpty(x));
      await Promise.all(nonEmpty.map((x) => this.createPublicEmailProviderDomain(x)));
    } catch (e) {
      this.setState({ errorMessage: `Public email provider domain creation failed: ${e}` });
    }
    this.setState({ processing: false });
  };

  createPublicEmailProviderDomain = async (domain: string) => {
    const req: ApiPublicEmailProviderDomain = {
      domain: domain,
    };
    const result = await apiClients.idashboardClient.CreatePublicEmailProviderDomain(req);
    this.props.onPublicEmailProviderDomainCreated(result.id || "");
  };

  onDomainsChanged = (domains: string[]) => {
    const nonEmpty = _.filter(domains, (x) => !_.isEmpty(x));
    this.setState({ domains: domains, count: nonEmpty.length });
  };

  isValid = (): boolean => {
    const nonEmpty = _.filter(this.state.domains, (x) => !_.isEmpty(x));
    return !_.isEmpty(nonEmpty);
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <Processing active={this.state.processing} message="Creating public email provider domain, please wait..." />

        <CreatePublicEmailProviderDomainView
          {...this.props}
          {...this.state}
          canCreate={this.isValid()}
          creating={this.state.processing}
          onCreate={this.onCreatePublicEmailProviderDomains}
          onCancel={this.props.onClose}
          onDomainsChanged={this.onDomainsChanged}
          errorMessage={this.state.errorMessage}
          dismissErrorHandle={this.handleDismissError}
        />
      </div>
    );
  }
}

export default withRefresh()(CreatePublicEmailProviderDomain);
