//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Button, Form, Icon, InputOnChangeData, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Plan as ApiPlan } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Processing } from "../../ui/lib";

interface ICreatePlanViewArgs {
  visible: boolean;
  name: string;
  description: string;
  canCreate: boolean;
  creating: boolean;
  onNameChanged: (value: string) => void;
  onDescriptionChanged: (value: string) => void;
  onCreate: () => void;
  onCancel: () => void;
}

const CreatePlanView = ({ ...args }: ICreatePlanViewArgs) => (
  <Modal open={args.visible}>
    <Modal.Header>Create new pricing plan</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form>
          <Form.Field>
            <Form.Input
              name="name"
              placeholder="name"
              value={args.name}
              onChange={(e: any, data: InputOnChangeData) => args.onNameChanged(data.value)}
              disabled={args.creating}
              autoFocus
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              name="description"
              placeholder="description"
              value={args.description}
              onChange={(e: any, data: InputOnChangeData) => args.onDescriptionChanged(data.value)}
              disabled={args.creating}
            />
          </Form.Field>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={args.onCancel} disabled={args.creating}>
        <Icon name="remove" />
        Cancel
      </Button>
      <Button positive onClick={args.onCreate} disabled={!args.canCreate || args.creating}>
        <Icon name="checkmark" />
        Create
      </Button>
    </Modal.Actions>
  </Modal>
);

// Interface decribing the properties of the pricing plan create component
interface ICreatePricingPlanProps extends IWithRefreshProps, RouteComponentProps {
  visible: boolean;
  onPlanCreated: (planID: string) => void;
  onClose: () => void;
}

// Interface decribing the state of the pricing plan create component
interface ICreatePricingPlanState {
  name: string;
  description: string;

  errorMessage?: string;
  processing: boolean;
}

// The component to show pricing plan creation modal
class CreatePricingPlan extends Component<ICreatePricingPlanProps, ICreatePricingPlanState> {
  state: ICreatePricingPlanState = {
    name: "",
    description: "",
    errorMessage: undefined,
    processing: false,
  };

  onCreatePlan = async () => {
    this.setState({ processing: true });
    try {
      var req: ApiPlan = {
        name: this.state.name,
        description: this.state.description,
      };
      const plan = await apiClients.idashboardClient.CreatePlan(req);
      this.props.onPlanCreated(plan.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Pricing plan creation failed: ${e}` });
    }
    this.setState({ processing: false });
  };

  onNameChanged = (name: string) => {
    this.setState({ name: name });
  };

  onDescriptionChanged = (description: string) => {
    this.setState({ description: description });
  };

  isValid = (): boolean => {
    return this.state.name != "";
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <Processing active={this.state.processing} message="Creating pricing plan, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />

        <CreatePlanView
          {...this.props}
          {...this.state}
          canCreate={this.isValid()}
          creating={this.state.processing}
          onNameChanged={this.onNameChanged}
          onDescriptionChanged={this.onDescriptionChanged}
          onCreate={this.onCreatePlan}
          onCancel={this.props.onClose}
        />
      </div>
    );
  }
}

export default withRefresh()(CreatePricingPlan);
