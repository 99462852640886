//
// DISCLAIMER
//
// Copyright 2021-2023 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import { Table, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  LoadBalancerAssignment as ApiLoadBalancerAssignment,
  LoadBalancerAssignmentList as ApiLoadBalancerAssignmentList,
  ListLoadBalancerAssignmentsRequest as ApiListLoadBalancerAssignmentsRequest,
  LoadBalancerAssignment_Status as ApiLoadBalancerAssignment_Status,
  LoadBalancer_Status as ApiLoadBalancer_Status,
  Deployment as ApiDeployment,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Finalizers, Loading, ErrorMessage } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import _ from "lodash";
import ReactJson from "react-json-view";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IStatusView {
  as: ApiLoadBalancerAssignment_Status;
  ls: ApiLoadBalancer_Status;
}

const StatusView = ({ ...args }: IStatusView) => {
  const as = args.as;
  const ls = args.ls;
  if (!!ls.is_deleted) {
    return (
      <span>
        Deleting...
        <DateTimePopupWithUTCAndLocalTime dateTime={ls.deleted_at} label="Deleted at" />
      </span>
    );
  }
  if (!ls.is_created) {
    return <span>Creating...</span>;
  }
  if (!ls.is_ready) {
    return (
      <span>
        Created
        <DateTimePopupWithUTCAndLocalTime dateTime={ls.created_at} label="Created at" />, LB not ready: {ls.message}
      </span>
    );
  }
  if (!as.route_is_ready) {
    return (
      <span>
        LB ready <DateTimePopupWithUTCAndLocalTime dateTime={ls.first_ready_at} label="First ready at" />; Route not ready: {as.message}
      </span>
    );
  }
  return (
    <span>
      LB ready <DateTimePopupWithUTCAndLocalTime dateTime={ls.first_ready_at} label="First ready at" />; Route ready (
      <DateTimePopupWithUTCAndLocalTime dateTime={as.last_route_is_ready_transitioned_at} label="Last Route ready at" />)
    </span>
  );
};

interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Is Default</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Finalizers</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  active: boolean;
  item: ApiLoadBalancerAssignment;
}

const RowView = ({ ...args }: IRowView) => {
  const item = args.item || {};
  const load_balancer = item.load_balancer || {};
  return (
    <Table.Row>
      <Table.Cell>
        <Popup trigger={<u>{item.id || ""}</u>} content={<ReactJson src={item} collapsed={1} />} on="click" pinned />
      </Table.Cell>
      <Table.Cell>{item.is_default ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{load_balancer.type}</Table.Cell>
      <Table.Cell>
        <StatusView as={item.status || {}} ls={load_balancer.status || {}} />
      </Table.Cell>
      <Table.Cell>
        <Table.Cell>{args.item.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" /> : "-"}</Table.Cell>
      </Table.Cell>
      <Table.Cell>
        <Finalizers finalizers={item.finalizers} />
      </Table.Cell>
      <Table.Cell>{args.item.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  active: boolean;
  items: ApiLoadBalancerAssignment[];
  loading: boolean;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No load balancer assignments</div>;

export interface ILoadBalancerAssignmentListViewArgs extends RouteComponentProps {
  active: boolean;
  loading: boolean;
  loadBalancerAssignments?: ApiLoadBalancerAssignmentList;
}

export const LoadBalancerAssignmentListView = ({ ...args }: ILoadBalancerAssignmentListViewArgs) => {
  if (!args.loadBalancerAssignments) {
    return <Loading />;
  }
  const items = args.loadBalancerAssignments.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={_.orderBy(items, (x) => -moment(x.created_at).unix())} loading={args.loading} />;
};

interface ILoadBalancerAssignmentListProps extends IWithRefreshProps, RouteComponentProps {
  deployment: ApiDeployment;
}

interface ILoadBalancerAssignmentListState {
  errorMessage?: string;
  processing: boolean;
  loadBalancerAssignments?: ApiLoadBalancerAssignmentList;
}

// The component to show the deployment load balancer assigment list.
class LoadBalancerAssignmentList extends Component<ILoadBalancerAssignmentListProps, ILoadBalancerAssignmentListState> {
  state: ILoadBalancerAssignmentListState = {
    errorMessage: undefined,
    processing: false,
    loadBalancerAssignments: undefined,
  };

  reloadLoadBalancerAssignments = async () => {
    const listOptions: ApiListLoadBalancerAssignmentsRequest = { deployment_id: this.props.deployment.id };
    const loadBalancerAssignments = await apiClients.idashboardClient.ListLoadBalancerAssignments(listOptions);
    this.setState({ loadBalancerAssignments: loadBalancerAssignments });
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadLoadBalancerAssignments, `${this.props.deployment.url}/LoadBalancerAssignment/*`);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <LoadBalancerAssignmentListView {...this.props} {...this.state} active={!this.state.processing} />
      </div>
    );
  }
}

export default withRefresh()(LoadBalancerAssignmentList);
