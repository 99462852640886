//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import moment from "moment";
import React from "react";
import semver from "semver";
import { RouteComponentProps } from "react-router-dom";
import { Icon, Label, Popup, Table } from "semantic-ui-react";
import {
  Deployment as ApiDeployment,
  BackupInfo as ApiBackupInfo,
  BackupInfoList as ApiBackupInfoList,
  Backup_Status as ApiBackup_Status,
  CopyBackupRequest as ApiCopyBackupRequest,
  Backup as ApiBackup,
} from "../../api/lib";
import { Finalizers, IconWithPopup, Loading, SubID, ListAction } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";
import { DateTimePopupWithUTCAndLocalTime, DateTimeTransformationTypes, ElapsedTime, FormattedDateWithLabel } from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";
import { CopyBackupModal } from "./CopyBackupModal";

interface ICardValueView {
  backupInfo: ApiBackupInfo;
  status: ApiBackup_Status;
}

const InCloudStorageValueView = ({ ...args }: ICardValueView) => {
  const backupInfo = args.backupInfo || {};
  const bu = backupInfo.backup || {};
  const status = args.status;

  const hasStatus = !!bu.status;
  const isFailed = status && status.is_failed;
  const uploadStatus = status.upload_status;
  const uploaded = uploadStatus && uploadStatus.uploaded;
  const needsUpload = bu.upload && !uploaded;
  const uploadedAt = uploadStatus && uploadStatus.uploaded_at;
  const uploadSize = (uploadStatus && uploadStatus.size_bytes) || 0;
  const uploadDeleting = uploaded && !bu.upload;
  const uploadVerificationStatus = backupInfo.upload_verification_status || "Pending";
  const uploadVerificationFailedReason = backupInfo.upload_verification_failed_reason || "";
  const numberOfFiles = backupInfo.number_of_files || 0;

  const downloadSpec = bu.download;
  const downloadStatus = status.download_status;
  const downloadSpecRev = (downloadSpec && downloadSpec.revision) || 0;
  const downloaded = downloadStatus && downloadStatus.downloaded;
  const needsDownload = downloadSpec && downloadSpecRev > 0 && !downloaded;
  const startDownload = bu.download && bu.download.last_updated_at;
  const downloadedAt = downloadStatus && downloadStatus.downloaded_at;

  const SUCCEEDED = "Succeeded";
  const FAILED = "Failed";

  // Do we have a status?
  if (!hasStatus || isFailed) {
    // Unknown
    return <div>-</div>;
  }

  // Are we uploading?
  if (needsUpload) {
    return <div>Upload: {status.progress || "pending..."}</div>;
  }
  //Is the upload being deleted?
  if (uploadDeleting) {
    return <div>Upload: deleting...</div>;
  }
  // Are we downloading?
  if (needsDownload) {
    return <div>Download: {status.progress || "pending..."}</div>;
  }
  // Did we ever upload, and ready?
  if (uploaded) {
    return (
      <div>
        <IconWithPopup
          name="toggle on"
          color={uploadVerificationStatus === SUCCEEDED ? "green" : uploadVerificationStatus === FAILED ? "red" : "yellow"}
          content={
            <div>
              <div>
                Uploaded: <DateTimePopupWithUTCAndLocalTime dateTime={uploadedAt} label="Uploaded at" />
              </div>
              <div>Duration: {moment.duration(moment(bu.upload_updated_at).diff(uploadedAt)).humanize()}</div>
              <div>Size: {humanizeFileSize(uploadSize)}</div>
              <div>Number of files: {numberOfFiles}</div>
              <br />
              <div>Downloaded: {downloadedAt ? <DateTimePopupWithUTCAndLocalTime dateTime={downloadedAt} label="Downloaded at" /> : "never"}</div>
              <div>Duration: {downloaded ? moment.duration(moment(startDownload).diff(downloadedAt)).humanize() : "-"}</div>
              <br />
              <div>Backup verification: {uploadVerificationStatus}</div>
              {uploadVerificationStatus === FAILED && uploadVerificationFailedReason !== "" && (
                <div>Backup verification fail reason: {uploadVerificationFailedReason}</div>
              )}
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div>
      <Icon name="toggle off"></Icon>
    </div>
  );
};

const StatusValueView = ({ ...args }: ICardValueView) => {
  const status = args.status;
  const state = status.state;
  if (status.upload_only) {
    return <div>Upload only</div>;
  }
  if (!state) {
    return <div>-</div>;
  }
  return (
    <div>
      <Popup trigger={<span>{status.state}</span>} content={<div>{status.message || "-"}</div>} />
    </div>
  );
};

const InternalValueView = ({ ...args }: ICardValueView) => {
  const backupInfo = args.backupInfo;
  const isInternal = backupInfo.is_internal;
  if (isInternal) {
    return <IconWithPopup name="eye slash" content="This backup is internal and cannot been seen by the customer" color="orange" />;
  }
  return <IconWithPopup name="eye" content="This backup is public and can been seen by the customer" />;
};

const InconsistentValueView = ({ ...args }: ICardValueView) => {
  const backupInfo = args.backupInfo;
  if (backupInfo.potentially_inconsistent) {
    return <IconWithPopup name="lock open" content="This backup is potentially inconsistent!" color="red" />;
  }
  if (backupInfo.allow_inconsistent) {
    return <IconWithPopup name="lock" content="This backup is consistent, however was allowed to be potentially inconsistent" color="yellow" />;
  }
  return <IconWithPopup name="lock" content="This backup consistent" color="green" />;
};

const CreatedValueView = ({ ...args }: ICardValueView) => {
  const backupInfo = args.backupInfo;
  const bu = backupInfo.backup || {};
  const status = args.status;
  return (
    <div>
      <Popup
        trigger={<span>{bu.created_at ? <ElapsedTime dateTime={bu.created_at} /> : "-"}</span>}
        content={
          <div>
            {status.created_at ? (
              <>
                <FormattedDateWithLabel dateTime={status.created_at} label="Created At" transformTo={DateTimeTransformationTypes.UTC} />
                <FormattedDateWithLabel dateTime={status.created_at} label="Created At" transformTo={DateTimeTransformationTypes.LOCAL} />
              </>
            ) : (
              "pending..."
            )}
          </div>
        }
      />
    </div>
  );
};

const AutoDeleteValueView = ({ ...args }: ICardValueView) => {
  const backupInfo = args.backupInfo;
  const bu = backupInfo.backup || {};
  return <div>{bu.auto_deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={bu.auto_deleted_at} label="Auto deletes on" /> : "never"}</div>;
};

const DbVersionValueView = ({ ...args }: ICardValueView) => {
  const backupInfo = args.backupInfo;
  const bu = backupInfo.backup || {};
  const deplInfo = bu.deployment_info;
  const servers = (deplInfo && deplInfo.servers) || {};
  return <div>{deplInfo ? <Popup trigger={<span>{deplInfo.version}</span>} content={<div>DB Servers: {servers.dbservers || "-"}</div>} /> : "-"}</div>;
};

const SizeValueView = ({ ...args }: ICardValueView) => {
  const backupInfo = args.backupInfo || {};
  const bu = backupInfo.backup || {};
  const status = bu.status || {};
  const uploadStatus = status.upload_status;
  const uploaded = uploadStatus && uploadStatus.uploaded;
  const uploadSize = (uploadStatus && uploadStatus.size_bytes) || 0;
  // If we have a fully uploaded backup, use that size
  if (uploaded) {
    return <div>{humanizeFileSize(uploadSize)}</div>;
  }
  return <div>{status.size_bytes ? humanizeFileSize(status.size_bytes) : "-"}</div>;
};

interface ICardRestorableValueView extends ICardValueView {
  isRestorePossible: boolean;
  sameMajorMinorVersion: boolean;
  sameNumberOfDbServers: boolean;
  isDownloadPossible: boolean;
}

const RestorableValueView = ({ ...args }: ICardRestorableValueView) => {
  const backupInfo = args.backupInfo;
  const bu = backupInfo.backup || {};
  const status = bu.status || {};
  // Do we have a status?
  if (!bu.status) {
    return <div>-</div>;
  }
  // Is it restorable?
  if (args.isRestorePossible) {
    return <div>yes</div>;
  }
  // Show the reason, more important reasons are lower in this list
  let reason = "Unknown";
  if (args.isDownloadPossible) {
    reason = "Backup should be downloaded first";
  }
  if (!args.sameNumberOfDbServers) {
    reason = "Number of servers in backup and currently running cluster not the same";
  }
  if (!args.sameMajorMinorVersion) {
    reason = "Major and minor version in backup and currently running cluster not the same";
  }
  if (status.is_failed) {
    reason = `Backup creation failed: ${status.message}`;
  }
  return (
    <div>
      <Popup trigger={<span>no</span>} content={<div>Reason: {reason}</div>} />
    </div>
  );
};

// Interface describing a backup
interface IRowView extends IWithRefreshProps {
  deployment: ApiDeployment;
  item: ApiBackupInfo;
  hasBackupPermission: (url: string | undefined, permission: Permission) => boolean;
  onClickRestore: () => void;
  onClickEdit: () => void;
  onClickDownload: () => void;
  onClickDelete: () => void;
  onClickCloneDeploymentFromBackup: () => void;
  onResumeDeployment: () => void;

  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (finalizer: string) => void;

  onCopyBackup: (data: ApiCopyBackupRequest) => Promise<ApiBackup>;
}

const RowView = ({ ...args }: IRowView) => {
  const backupInfo = args.item || {};
  const bu = backupInfo.backup || {};
  const isDeleted = !!bu.is_deleted;
  const isRestoreAllowed = !isDeleted && args.hasBackupPermission(bu.url, "internal-dashboard.backup.restore");
  const isUpdateAllowed = !isDeleted && args.hasBackupPermission(bu.url, "internal-dashboard.backup.update");
  const isDownloadAllowed = !isDeleted && args.hasBackupPermission(bu.url, "internal-dashboard.backup.download");
  const isDeleteAllowed = !isDeleted && args.hasBackupPermission(bu.url, "internal-dashboard.backup.delete");
  const isMultiRegionBackupAllowed = !isDeleted && args.hasBackupPermission(bu.url, "internal-dashboard.backup.copy");
  const isCloneDeploymentAllowed = !isDeleted && args.hasBackupPermission(bu.url, "internal-dashboard.deployment.clone-from-backup");
  const status = bu.status || {};
  const versionRunning = new semver.SemVer(args.deployment.version || "");
  const versionBackup = new semver.SemVer((bu.deployment_info && bu.deployment_info.version) || "");
  const sameMajorMinorVersion = versionRunning.major == versionBackup.major && versionRunning.minor == versionBackup.minor;
  const isRestorePossible = !!status.available && sameMajorMinorVersion;
  const uploadStatus = status.upload_status || {};
  const numberOfDbServersRunning = (args.deployment.servers && args.deployment.servers.dbservers) || 0;
  const numberOfDbServersBackup = (bu.deployment_info && bu.deployment_info.servers && bu.deployment_info.servers.dbservers) || 0;
  const sameNumberOfDbServers = numberOfDbServersRunning == numberOfDbServersBackup;
  const isDownloadPossible = !status.available /* no need to, it's available */ && !!uploadStatus.uploaded /* not uploaded */ && sameNumberOfDbServers;
  const isClonePossible = !!(status.upload_status && status.upload_status.uploaded);
  const isMultiRegionBackup = !!((backupInfo.backup || {}).source_backup_id || "");

  return (
    <Table.Row>
      <Table.Cell>
        <div>
          <Popup trigger={<span>{bu.name || "-"}</span>} content={<span>{bu.description || ""}</span>} />
        </div>
        <SubID>{bu.id || ""}</SubID>
        {isMultiRegionBackup && (
          <Popup
            trigger={<Label size="mini">Multi Region</Label>}
            content={
              <span>
                <b>Source Backup:</b>
                {(backupInfo.backup || {}).source_backup_id}
              </span>
            }
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <CreatedValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>
        <StatusValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>
        <InternalValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>
        <InconsistentValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>{bu.backup_policy_id ? "Scheduled" : "Manual"}</Table.Cell>
      <Table.Cell>
        <InCloudStorageValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>{bu.region_id || args.deployment.region_id}</Table.Cell>
      <Table.Cell>
        <SizeValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>
        <AutoDeleteValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>
        <RestorableValueView
          backupInfo={backupInfo}
          status={status}
          isRestorePossible={isRestorePossible}
          sameMajorMinorVersion={sameMajorMinorVersion}
          sameNumberOfDbServers={sameNumberOfDbServers}
          isDownloadPossible={isDownloadPossible}
        />
      </Table.Cell>
      <Table.Cell>
        <DbVersionValueView backupInfo={backupInfo} status={status} />
      </Table.Cell>
      <Table.Cell>
        <Finalizers finalizers={backupInfo.finalizers} remove={args.canRemoveFinalizer ? args.onRemoveFinalizer : undefined} />
      </Table.Cell>
      <Table.Cell>{bu.is_deleted ? <div>{moment(bu.deleted_at).fromNow()}</div> : <div>-</div>}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        {!isDeleted && (
          <div className="table-action-buttons">
            {isRestoreAllowed && isRestorePossible && (
              <DeploymentPausedModal
                {...args}
                isPaused={!!args.deployment.is_paused}
                onClick={args.onClickRestore}
                trigger={<ListAction icon="undo" tooltip="Restore backup. Note that this will overwrite ALL existing data in the deployment!" size="tiny" />}
              />
            )}
            {isCloneDeploymentAllowed && isClonePossible && (
              <ListAction icon="clone" tooltip="Clone backup to new deployment" onClick={args.onClickCloneDeploymentFromBackup} size="tiny" />
            )}
            {isUpdateAllowed && (
              <DeploymentPausedModal
                {...args}
                isPaused={!!args.deployment.is_paused}
                onClick={args.onClickEdit}
                trigger={<ListAction icon="edit" tooltip="Edit backup" size="tiny" />}
              />
            )}
            {isMultiRegionBackupAllowed && (
              <CopyBackupModal deploymentRegionId={args.deployment.region_id || ""} sourceBackupId={bu.id || ""} onCopyBackup={args.onCopyBackup} />
            )}
            {isDownloadAllowed && isDownloadPossible && (
              <DeploymentPausedModal
                {...args}
                isPaused={!!args.deployment.is_paused}
                onClick={args.onClickDownload}
                trigger={<ListAction icon="cloud download" tooltip="Download backup to deployment servers" size="tiny" />}
              />
            )}
            {isDeleteAllowed && (
              <DeploymentPausedModal
                {...args}
                isPaused={!!args.deployment.is_paused}
                onClick={args.onClickDelete}
                trigger={<ListAction icon="trash" tooltip="Delete backup" size="tiny" />}
              />
            )}
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the backup list
interface IListView extends IWithRefreshProps {
  deployment: ApiDeployment;
  items: ApiBackupInfo[];
  loading: boolean;
  hasBackupPermission: (url: string | undefined, permission: Permission) => boolean;
  onClickRestore: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDownload: (id: string) => void;
  onClickDelete: (id: string) => void;
  onClickCloneDeploymentFromBackup: (id: string) => void;
  onResumeDeployment: () => void;

  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (id: string, finalizer: string) => void;
  onCopyBackup: (data: ApiCopyBackupRequest) => Promise<ApiBackup>;
}

const ListView = ({ ...args }: IListView) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>
            <IconWithPopup name="eye" content="Status" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <IconWithPopup name="low vision" content="Internal" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <IconWithPopup name="heartbeat" content="Potentially inconsistent" />
          </Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>
            <IconWithPopup name="cloud" content="In cloud storage" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <IconWithPopup name="globe" content="Backup region" />
          </Table.HeaderCell>
          <Table.HeaderCell>Size</Table.HeaderCell>
          <Table.HeaderCell>
            <Popup
              trigger={
                <Icon.Group>
                  <Icon name="clock" />
                  <Icon corner name="trash" />
                </Icon.Group>
              }
              content="Auto-delete"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <IconWithPopup name="check" content="Restorable" color="green" />
          </Table.HeaderCell>
          <Table.HeaderCell>DB Version</Table.HeaderCell>
          <Table.HeaderCell>Finalizers</Table.HeaderCell>
          <Table.HeaderCell>
            <IconWithPopup name="trash" content="Deleted" />
          </Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={(item.backup || {}).id}
            item={item}
            onClickRestore={() => args.onClickRestore((item.backup || {}).id || "")}
            onClickEdit={() => args.onClickEdit((item.backup || {}).id || "")}
            onClickDownload={() => args.onClickDownload((item.backup || {}).id || "")}
            onClickDelete={() => args.onClickDelete((item.backup || {}).id || "")}
            onClickCloneDeploymentFromBackup={() => args.onClickCloneDeploymentFromBackup((item.backup || {}).id || "")}
            onRemoveFinalizer={(finalizer) => args.onRemoveFinalizer((item.backup || {}).id || "", finalizer)}
            onCopyBackup={args.onCopyBackup}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No backups inside this deployment</div>;

// Interface describing the backup list view arguments
export interface IBackupListViewArgs extends IWithRefreshProps, RouteComponentProps {
  loading: boolean;
  deployment: ApiDeployment;
  backups?: ApiBackupInfoList;
  onClickBackupRestore: (id: string) => void;
  onClickBackupEdit: (id: string) => void;
  onClickBackupDownload: (id: string) => void;
  onClickBackupDelete: (id: string) => void;
  onClickCloneDeploymentFromBackup: (id: string) => void;
  onResumeDeployment: () => void;

  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (id: string, finalizer: string) => void;
  onCopyBackup: (data: ApiCopyBackupRequest) => Promise<ApiBackup>;
}

export const BackupListView = ({ ...args }: IBackupListViewArgs) => {
  if (!args.backups) {
    return <Loading />;
  }
  if (_.isEmpty(args.backups.items)) {
    return <EmptyView />;
  }
  return (
    <ListView
      {...args}
      items={args.backups.items || []}
      loading={args.loading}
      onClickRestore={args.onClickBackupRestore}
      onClickEdit={args.onClickBackupEdit}
      onClickDownload={args.onClickBackupDownload}
      onClickDelete={args.onClickBackupDelete}
      onCopyBackup={args.onCopyBackup}
      hasBackupPermission={(url: string | undefined, permission: Permission) => {
        return args.hasPermissionByUrl ? args.hasPermissionByUrl(url || "", ResourceType.Backup, permission) : false;
      }}
    />
  );
};
