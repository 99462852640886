//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Loader, Button, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { CACertificate as ApiCACertificate } from "../../api/crypto/v1/crypto";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { RouteComponentProps } from "react-router-dom";
import { LoaderBox, Field, FieldContent as FC, FieldLabel as FL, ContentSegment, SecondaryMenu, MenuActionBack, Loading } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface ICACertificateDetailsViewArgs extends IWithRefreshProps, RouteComponentProps {
  caCertificate: ApiCACertificate;
}

const CACertificateDetailsView = ({ ...args }: ICACertificateDetailsViewArgs) => (
  <ContentSegment>
    <SecondaryMenu>
      <MenuActionBack />
      <Menu.Item header>CA Certificate Details</Menu.Item>
      <LoaderBox>
        <Loader size="mini" active={args.loading} inline />
      </LoaderBox>
    </SecondaryMenu>
    <Field>
      <FL>Name</FL>
      <FC>{args.caCertificate.name || "-"}</FC>
    </Field>
    <Field>
      <FL>Description</FL>
      <FC>{args.caCertificate.description || "-"}</FC>
    </Field>
    <Field>
      <FL>Type</FL>
      <FC>{!!args.caCertificate.use_well_known_certificate ? "Well known and Self-signed" : "Self-signed"}</FC>
    </Field>
    <Field>
      <FL>Created</FL>
      <FC>
        {args.caCertificate.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.caCertificate.created_at} label="CA Certificate created at" /> : "-"}
      </FC>
    </Field>
    <Field>
      <FL>Lifetime</FL>
      <FC>{args.caCertificate.lifetime ? args.caCertificate.lifetime : "?"}</FC>
    </Field>
    <Field>
      <FL>Expires</FL>
      <FC>
        {args.caCertificate.expires_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.caCertificate.expires_at} label="CA Certificate expires at" /> : "?"}
      </FC>
    </Field>
    <Field>
      <FL>Deleted</FL>
      <FC>
        {args.caCertificate.deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.caCertificate.deleted_at} label="CA Certificate deleted at" /> : "-"}
      </FC>
    </Field>
    <Field>
      <FL>Self-signed Certificate (PEM)</FL>
      <FC>
        <pre>{args.caCertificate.certificate_pem}</pre>
        <CopyToClipboard text={args.caCertificate.certificate_pem}>
          <Button positive icon="copy" labelPosition="right" content="Copy to clipboard" />
        </CopyToClipboard>
      </FC>
    </Field>
  </ContentSegment>
);

// Interface decribing the properties of the CACertificate details component
interface ICACertificateDetailsProps extends IWithRefreshProps, RouteComponentProps {}

// Interface decribing the state of the CACertificate details component
interface ICACertificateDetailsState {
  caCertificateId?: string;
  caCertificate?: ApiCACertificate;
}

class CACertificateDetails extends Component<ICACertificateDetailsProps, ICACertificateDetailsState> {
  state: ICACertificateDetailsState = {
    caCertificateId: undefined,
    caCertificate: undefined,
  };

  reloadCACertificateInfo = async () => {
    const idOptions: ApiIDOptions = { id: this.state.caCertificateId };
    const caCertificate = await apiClients.idashboardClient.GetCACertificate(idOptions);
    if (!this.state.caCertificate) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadCACertificateInfo, caCertificate.url);
    }
    this.setState({ caCertificate: caCertificate });
  };

  componentDidMount() {
    const caCertificateId = (this.props.match.params as any).caCertificateId;
    this.setState(
      {
        caCertificateId: caCertificateId,
      },
      () => {
        //notice inside the reloadRoleInfo we use the groupId
        this.props.refreshNow && this.props.refreshNow(this.reloadCACertificateInfo);
      }
    );
  }

  render() {
    const caCertificate = this.state.caCertificate as ApiCACertificate | undefined;

    if (caCertificate) {
      return <CACertificateDetailsView {...this.props} caCertificate={caCertificate} />;
    }

    return <Loading />;
  }
}

export default withRefresh()(CACertificateDetails);
