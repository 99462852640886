//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  Organization as ApiOrganization,
  OrganizationInfo as ApiOrganizationInfo,
  SetAllowOrganizationDelinquentPayerRequest as ApiSetAllowOrganizationDelinquentPayerRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL, Processing, Confirm, ConfirmInfo } from "../../ui/lib";

export interface IAllowDelinquentPayerToggle {
  canSetOrganizationAllowDelinquentPayer: boolean;
  organization: ApiOrganization;
  organizationInfo: ApiOrganizationInfo;
  onError: (message: string) => void;
}

const AllowDelinquentPayerToggle: React.FC<IAllowDelinquentPayerToggle> = ({
  canSetOrganizationAllowDelinquentPayer,
  organization,
  organizationInfo,
  onError,
}) => {
  const [processing, setIsProcessing] = useState(false);
  const [isDelinquentPayerAllowed, setIsDeliquentPayerAllowed] = useState(!!organizationInfo.allow_delinquent_payer);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);

  useEffect(() => {
    if (organizationInfo.allow_delinquent_payer !== isDelinquentPayerAllowed) {
      setIsDeliquentPayerAllowed(!!organizationInfo.allow_delinquent_payer);
    }
  }, [organizationInfo]);

  const onSetIsDelinquentPayerAllowed = async (isDelinquentPayerAllowed: boolean) => {
    setConfirmInfo({
      header: `${isDelinquentPayerAllowed ? "Enable" : "Disable"} Allow Delinquent Payer`,
      content: `Are you sure to update this configuration?`,
      warning: `This will ${isDelinquentPayerAllowed ? "verify" : "not verify"} allow delinquent payer moving forward.`,
      confirm: isDelinquentPayerAllowed ? "Enable!" : "Disable!",
      onConfirm: async () => {
        const req: ApiSetAllowOrganizationDelinquentPayerRequest = {
          organization_id: organization.id,
          allow_delinquent_payer: isDelinquentPayerAllowed,
        };
        try {
          setIsProcessing(true);
          await apiClients.idashboardClient.SetOrganizationAllowDelinquentPayer(req);
          setIsDeliquentPayerAllowed(isDelinquentPayerAllowed);
        } catch (e) {
          onError(`Failed updating allow delinquent payer verification setting: ${e}`);
        } finally {
          setIsProcessing(false);
          setConfirmInfo(undefined);
        }
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    });
  };

  return (
    <div>
      {confirmInfo && <Confirm confirmInfo={confirmInfo} />}
      <Processing active={processing} message="Changing allow delinquent payer setting, please wait..." />
      <Field>
        <FL>Allow delinquent payer</FL>
        <FC>
          {isDelinquentPayerAllowed ? "Enabled" : "Disabled"}
          {canSetOrganizationAllowDelinquentPayer && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!isDelinquentPayerAllowed && <Dropdown.Item text="Enable" onClick={() => onSetIsDelinquentPayerAllowed(true)} />}
                {isDelinquentPayerAllowed && <Dropdown.Item text="Disable" onClick={() => onSetIsDelinquentPayerAllowed(false)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
    </div>
  );
};

export default AllowDelinquentPayerToggle;
