//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import _ from "lodash";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ListOptions as ApiListOptions,
  Organization as ApiOrganization,
  SetOrganizationTierRequest as ApiSetOrganizationTierRequest,
  TierDetailsList as ApiTierDetailsList,
  TierDetails as ApiTierDetails,
} from "../../api/lib";
import { Confirm, ConfirmInfo, Field, FieldContent as FC, FieldLabelWide as FL, Processing } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface ITierFieldViewArgs {
  organization: ApiOrganization;
  tiers?: ApiTierDetails[];
  onSetTier: (tierId: string, tierName: string) => void;
}

const TierFieldView = ({ ...args }: ITierFieldViewArgs) => {
  const tier = args.organization.tier || {};
  const otherTiers = _.filter(args.tiers || [], (x: ApiTierDetails) => x.id != tier.id);
  return (
    <Field>
      <FL>Tier</FL>
      <FC>
        {tier.name || "-"}
        <Dropdown icon="pencil" inline className="icon tiny set-organization-tier">
          <Dropdown.Menu>
            <Dropdown.Header content="Change tier to" />
            {otherTiers.map((tier) => (
              <Dropdown.Item key={tier.id} text={tier.name} value={tier.id} onClick={() => args.onSetTier(tier.id || "", tier.name || "")} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </FC>
    </Field>
  );
};

interface ITierFieldProps extends IOrganizationDetailsFieldProps {}

interface ITierFieldState extends IOrganizationDetailsFieldState {
  tiers?: ApiTierDetailsList;
  confirm?: ConfirmInfo;
  processing: boolean;
}

class TierField extends OrganizationDetailsField<ITierFieldProps, ITierFieldState> {
  state: ITierFieldState = {
    tiers: undefined,
    confirm: undefined,
    processing: false,
    refreshNeeded: false,
  };

  reloadTiers = async () => {
    const listOptions: ApiListOptions = {};
    const tiers = await apiClients.idashboardClient.ListTiers(listOptions);
    this.setState({ tiers: tiers });
  };

  refreshTiers = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTiers);
  };

  setTierId = (tierId: string, tierName: string) => {
    const org = this.props.organization;
    const orgId = org.id;
    const orgName = org.name || "";
    const confirmInfo: ConfirmInfo = {
      header: "Change organization tier",
      content: `Do you want to change the tier of the '${orgName}' organization to ${tierName}?`,
      warning: "This can only be done with explicit consent of the customer!",
      onConfirm: async () => {
        const req: ApiSetOrganizationTierRequest = {
          organization_id: orgId,
          tier_id: tierId,
        };
        try {
          this.setState({ processing: true, confirm: undefined });
          await apiClients.idashboardClient.SetOrganizationTier(req);
          this.props.onRefreshOrganization();
        } catch (e) {
          this.props.onError(`Changing organization tier failed: ${e}`);
        } finally {
          this.setState({ processing: false });
        }
      },
      onDenied: () => {
        this.setState({ confirm: undefined });
      },
    };
    this.setState({
      confirm: confirmInfo,
    });
  };

  componentDidMount() {
    super.componentDidMount();
    this.refreshTiers();
  }

  render() {
    const organization = this.props.organization;
    const tierList = this.state.tiers || {};
    const tiers = tierList.items;

    return (
      <div>
        <Confirm confirmInfo={this.state.confirm} />
        <Processing active={this.state.processing} message="Changing organization tier, please wait..." />
        <TierFieldView {...this.props} {...this.state} organization={organization} tiers={tiers} onSetTier={this.setTierId} />
      </div>
    );
  }
}

export default withRefresh()(TierField);
