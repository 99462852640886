//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Loader, Table, Menu, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ListEmailMessagesRequest as ApiListEmailMessagesRequest,
  EmailMessageList as ApiEmailMessageList,
  EmailMessage as ApiEmailMessage,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, LoaderBoxForTable as LoaderBox, ContentSegment, SecondaryMenu, PagingButtons, ErrorMessage } from "../../ui/lib";
import ReactSrcDocIframe from "react-srcdoc-iframe";
import styled from "@emotion/styled";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

const StyledIFrameContainer = styled("div")`
  iframe {
    width: 100% !important;
    height: 120px !important;
  }
  iframe:hover {
    height: 450px !important;
  }
`;

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Context</Table.HeaderCell>
      <Table.HeaderCell>
        Message
        <PagingButtons {...args} size="small" />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiEmailMessage;
}

const RowView = ({ ...args }: IRowView) => {
  const from = args.item.from || "";
  const to = args.item.to || "";
  const getRecipients = (recipients: string): React.ReactNode => {
    const parts = recipients.split(",");
    const total = parts.length;
    return (
      <>
        {parts[0]}
        <Popup
          trigger={<b>{total > 1 && ` + ${total - 1} more`}</b>}
          content={parts
            .slice(0)
            .filter((email) => !!email)
            .join(",\n")}
        />
      </>
    );
  };
  return (
    <Table.Row>
      <Table.Cell width="4" verticalAlign="top">
        <h5>{args.item.subject || ""}</h5>
        <div>
          From: <a href={`mailto:${from}`}>{getRecipients(from)}</a>
        </div>
        <div>
          To: <a href={`mailto:${to}`}>{getRecipients(to)}</a>
        </div>
        <div>
          <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
        </div>
      </Table.Cell>
      <Table.Cell width="12" verticalAlign="top">
        <StyledIFrameContainer>
          <ReactSrcDocIframe srcDoc={args.item.html} />
        </StyledIFrameContainer>
      </Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  items: ApiEmailMessage[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} />
      ))}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more email messages" : "No email messages found"}</div>;

export interface IEmailMessagesListViewArgs extends RouteComponentProps {
  loading: boolean;
  messages?: ApiEmailMessageList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

export const EmailMessagesListView = ({ ...args }: IEmailMessagesListViewArgs) => {
  if (!args.messages) {
    return <Loading />;
  }
  const isEmpty = !args.messages.items || args.messages.items.length === 0;
  const patterns = args.messages ? args.messages.items : undefined;
  return (
    <div>
      <ListView {...args} items={patterns || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the email message list component
interface IEmailMessageListProps extends IWithRefreshProps, RouteComponentProps {
  // If set, return only email messages related to this organization ID.
  // string
  organization_id?: string;

  // If set, return only email messages related to this deployment ID.
  // string
  deployment_id?: string;

  // If set, show only email messages sent to this email address.
  to?: string;

  // If set, do now show additional header
  inline?: boolean;
}

// Interface decribing the state of the email message list component
interface IEmailMessageListState {
  messages?: ApiEmailMessageList;
  page: number;
  pageSize: number;
  errorMessage?: string;
  organization_id?: string;
  deployment_id?: string;
  to?: string;
  refreshNeeded: boolean;
}

// The component to show email messages as a list.
class EmailMessageList extends Component<IEmailMessageListProps, IEmailMessageListState> {
  state: IEmailMessageListState = {
    messages: undefined,
    page: 0,
    pageSize: 30,
    errorMessage: undefined,
    organization_id: this.props.organization_id,
    deployment_id: this.props.deployment_id,
    to: this.props.to,
    refreshNeeded: false,
  };

  reloadEmailMessages = async () => {
    var req: ApiListEmailMessagesRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
      organization_id: this.state.organization_id,
      deployment_id: this.state.deployment_id,
      to: this.state.to,
    };
    const messages = await apiClients.idashboardClient.ListEmailMessages(req);
    this.setState({ messages: messages });
  };

  refreshEmailMessages = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadEmailMessages);
  };

  static getDerivedStateFromProps(props: IEmailMessageListProps, state: IEmailMessageListState) {
    if (props.organization_id !== state.organization_id || props.deployment_id !== state.deployment_id || props.to !== state.to) {
      return {
        organization_id: props.organization_id,
        deployment_id: props.deployment_id,
        to: props.to,
        page: 0,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshEmailMessages();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshEmailMessages);
    }
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshEmailMessages
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshEmailMessages
    );
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const messages = this.state.messages;
    const page = this.state.page;

    const listView = (
      <EmailMessagesListView
        {...this.props}
        messages={messages}
        onNextPage={this.onNextPage}
        onPreviousPage={page > 0 ? this.onPreviousPage : undefined}
        page={page}
        pageSize={this.state.pageSize}
      />
    );

    if (!!this.props.inline) {
      return listView;
    }

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Email messages</Menu.Item>
        </SecondaryMenu>
        {listView}
      </ContentSegment>
    );
  }
}

export default withRefresh()(EmailMessageList);
