//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Tomasz Mielech
//

import _ from "lodash";
import React, { Component } from "react";
import { SupportRequestCustomer as ApiSupportRequestCustomer } from "../../api/support/v1/isupport";
import {
  Deployment as ApiDeployment,
  DeploymentList as ApiDeploymentList,
  ListOptions as ApiListOptions,
  Organization as ApiOrganization,
  Project as ApiProject,
  ProjectList as ApiProjectList,
} from "../../api/lib";
import { Button, ButtonGroup, DropdownProps, Form, Grid, Modal, Icon } from "semantic-ui-react";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import apiClients from "../../api/apiclients";
import { ErrorMessage, Processing, Section, SectionContent, SectionHeader } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import styled from "@emotion/styled";
import { CopyToClipboard } from "react-copy-to-clipboard";

const StyledForm = styled(Form)`
  .section {
    margin-bottom: 0 !important;
  }
`;

const StyledSection = styled(Section)`
  .section {
    background-color: yellow;
  }
  margin-bottom: 0 !important;
`;

interface ISupportRequestShowIDViewArgs {
  onClose: () => void;
  requestID: string;
  copiedRequestID: boolean;
  onCopiedRequestID: () => void;
}

export const SupportRequestShowIDView = ({ ...args }: ISupportRequestShowIDViewArgs) => {
  return (
    <Modal size="small" open>
      <Modal.Header>Your support request for organization has been submitted</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Your request for support has been successfully submitted to Organization.</p>
          <p>
            Support request ID: <strong>{args.requestID}</strong>&nbsp;
            <CopyToClipboard text={args.requestID} onCopy={args.onCopiedRequestID}>
              <Icon name={args.copiedRequestID ? "checkmark" : "copy"} className="secondary-text" />
            </CopyToClipboard>
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={args.onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

interface ISupportRequestCustomerModalViewArgs {
  onSubmit: () => void;
  onClose: () => void;
  subjectVisible?: boolean;
  organization?: ApiOrganization;
  projects?: ApiProjectList;
  deployments?: ApiDeploymentList;
  description: string;
  title: string;
  onDescriptionChanged: (value: string) => void;
  onSeverityChanged: (sev: string) => void;
  onTitleChanged: (value: string) => void;
  severity: string;
  processing: boolean;
  updateProjectDropdownChange: (e: any, args: DropdownProps) => void;
  updateDeploymentDropdownChange: (e: any, args: DropdownProps) => void;
  handleDismissError: () => void;
  errorMessage?: string;
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  selectedProject?: ApiProject;
}

export const SupportRequestCustomerModalView = ({ ...args }: ISupportRequestCustomerModalViewArgs) => {
  const projectList = args.projects || {};
  const projects = projectList.items || [];
  const projectItems = _.orderBy(projects, "name")
    .map((p) => {
      if (args.hasPermissionByUrl && p.url && args.hasPermissionByUrl(p.url, ResourceType.Project, "resourcemanager.project.get")) {
        return { key: p.id, text: p.name, value: p.id };
      }
      return {};
    })
    .filter((p) => {
      return p.key;
    });
  projectItems.unshift({
    key: "",
    text: "No project",
    value: "",
  });

  const deploymentList = args.deployments || {};
  const deployments = deploymentList.items || [];
  const deploymentItems = _.orderBy(deployments, "name")
    .map((d) => {
      if (args.hasPermissionByUrl && d.url && args.hasPermissionByUrl(d.url, ResourceType.Deployment, "data.deployment.get")) {
        return { key: d.id, text: d.name, value: d.id };
      }
      return {};
    })
    .filter((p) => {
      return p.key;
    });
  deploymentItems.unshift({
    key: "",
    text: "No deployment",
    value: "",
  });

  const [open, setOpen] = React.useState(false);
  function close() {
    setOpen(false);
    args.onClose();
  }
  return (
    <span>
      <Button content="Create JIRA issue" size="mini" basic icon="help circle" onClick={() => setOpen(true)} />
      <Modal open={open} onClose={() => close()}>
        <Modal.Header>Create issue for the organization</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} header="Failed to create the issue" message={args.errorMessage} />
            <Processing active={args.processing} message="Submitting organization request ..." />
            <div>
              <StyledForm>
                <Grid columns="1" stackable>
                  {args.subjectVisible && (
                    <Grid.Column>
                      <StyledSection>
                        <SectionHeader title="Subject" />
                        <SectionContent>
                          <Form.Dropdown
                            fluid
                            selection
                            label="Project"
                            name="project"
                            placeholder="Select Project"
                            options={projectItems}
                            onChange={args.updateProjectDropdownChange}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            disabled={!args.selectedProject}
                            label="Deployment"
                            name="deployment"
                            placeholder="Select Deployment"
                            options={deploymentItems}
                            onChange={args.updateDeploymentDropdownChange}
                          />
                        </SectionContent>
                      </StyledSection>
                    </Grid.Column>
                  )}
                  <Grid.Column>
                    <StyledSection>
                      <SectionHeader title="Severity" />
                      <SectionContent>
                        <ButtonGroup>
                          <Button type="button" content="Low" primary={args.severity == "Low"} onClick={() => args.onSeverityChanged("Low")} />
                          <Button type="button" content="Normal" primary={args.severity == "Normal"} onClick={() => args.onSeverityChanged("Normal")} />
                          <Button type="button" content="High" primary={args.severity == "High"} onClick={() => args.onSeverityChanged("High")} />
                          <Button type="button" content="Critical" primary={args.severity == "Critical"} onClick={() => args.onSeverityChanged("Critical")} />
                        </ButtonGroup>
                      </SectionContent>
                    </StyledSection>
                  </Grid.Column>
                  <Grid.Column>
                    <StyledSection>
                      <SectionHeader title="Description" />
                      <SectionContent>
                        <Form.TextArea
                          label="Title"
                          required
                          name="Title"
                          placeholder="Please enter a title for the issue"
                          value={args.title}
                          onInput={(e, d) => args.onTitleChanged(d.value as string)}
                          rows="1"
                        />
                        <Form.TextArea
                          label="Description"
                          required
                          name="Description"
                          placeholder="Please enter issue details"
                          value={args.description}
                          onInput={(e, d) => args.onDescriptionChanged(d.value as string)}
                          rows="5"
                        />
                      </SectionContent>
                    </StyledSection>
                  </Grid.Column>
                </Grid>
              </StyledForm>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={() => close()} />
          <Button primary icon="check" content="Submit" onClick={args.onSubmit} disabled={args.title == "" || args.description == ""} labelPosition="right" />
        </Modal.Actions>
      </Modal>
    </span>
  );
};

// Interface describing the properties of the SupportRequestCustomer component
export interface ISupportRequestCustomerProps extends IWithRefreshProps {
  organization: ApiOrganization;
  deployment?: ApiDeployment;
}

// Interface decribing the state of the SupportRequestCustomer component
export interface ISupportRequestCustomerState {
  deployments?: ApiDeploymentList;
  errorMessage?: string;
  processing: boolean;
  projects?: ApiProjectList;
  // form fields
  description: string;
  title: string;
  requestID: string;
  copiedRequestID: boolean;
  selectedDeployment?: ApiDeployment;
  selectedProject?: ApiProject;
  severity: string;
}

class SupportRequestCustomer extends Component<ISupportRequestCustomerProps, ISupportRequestCustomerState> {
  state: ISupportRequestCustomerState = {
    errorMessage: undefined,
    processing: false,
    projects: undefined,
    deployments: undefined,
    description: "",
    title: "",
    requestID: "",
    copiedRequestID: false,
    severity: "Low",
    selectedDeployment: undefined,
    selectedProject: undefined,
  };

  reloadProjects = async () => {
    if (this.props.organization) {
      console.log(this.props.organization);
      const req: ApiListOptions = {
        context_id: this.props.organization.id,
      };
      const list = await apiClients.idashboardClient.ListProjects(req);
      this.setState({ projects: list });
    }
  };

  reloadDeployments = async () => {
    if (this.state.selectedProject) {
      const req: ApiListOptions = {
        context_id: this.state.selectedProject.id,
      };
      const list = await apiClients.authenticationOnly.dataClient.ListDeployments(req);
      this.setState({ deployments: list });
    }
  };

  updateProjectDropdownChange = (e: any, args: DropdownProps) => {
    if (args.name === "project") {
      const projects = this.state.projects;
      const selectedProject = projects && projects.items && projects.items.find((p) => p.id == args.value);
      this.setState({ selectedProject: selectedProject }, () => {
        this.props.refreshNow && this.props.refreshNow(this.reloadDeployments);
      });
      // clear the previous value
      this.setState({ selectedDeployment: undefined });
    }
  };

  updateDeploymentDropdownChange = (e: any, args: DropdownProps) => {
    if (args.name === "deployment") {
      const deployments = this.state.deployments;
      const selectedDeployment = deployments && deployments.items && deployments.items.find((d) => d.id == args.value);
      this.setState({ selectedDeployment: selectedDeployment });
    }
  };

  componentDidMount() {
    this.reloadProjects();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCopiedRequestID = () => {
    this.setState(
      {
        copiedRequestID: true,
      },
      () => {
        const setTimeout = this.props.setTimeout;
        setTimeout &&
          setTimeout(() => {
            this.setState({ copiedRequestID: false });
          }, 3000);
      }
    );
  };

  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };

  onResetAndClose = () => {
    this.setState({
      errorMessage: undefined,
      description: "",
      requestID: "",
      copiedRequestID: false,
      selectedDeployment: undefined,
      selectedProject: undefined,
      severity: "Low",
      title: "",
    });
  };

  onSeverityChanged = (sev: string) => {
    this.setState({ severity: sev });
  };

  onSubmit = async () => {
    this.setState({ processing: true });
    const req: ApiSupportRequestCustomer = {
      description: this.state.description,
      severity: this.state.severity.toLowerCase(),
      title: this.state.title,
    };

    if (this.props.deployment) {
      req.project_id = this.props.deployment.project_id;
      req.deployment_id = this.props.deployment.id;
    } else {
      if (this.state.selectedProject) {
        req.project_id = this.state.selectedProject.id;
      }
      if (this.state.selectedDeployment) {
        req.deployment_id = this.state.selectedDeployment.id;
      }
    }
    if (this.props.organization) {
      req.organization_id = this.props.organization.id;
    }

    try {
      const resp = await apiClients.idashboardClient.CreateSupportRequestForCustomer(req);
      if (resp.id) {
        console.log(resp);
        this.setState({ requestID: resp.id });
      } else {
        this.setState({ errorMessage: "Unable to create organization request" });
      }
    } catch (e) {
      this.setState({ errorMessage: e });
    }
    this.setState({ processing: false });
  };

  onTitleChanged = (value: string) => {
    this.setState({ title: value });
  };

  render() {
    if (this.state.requestID) {
      return (
        <SupportRequestShowIDView
          onClose={this.onResetAndClose}
          requestID={this.state.requestID}
          copiedRequestID={this.state.copiedRequestID}
          onCopiedRequestID={this.onCopiedRequestID}
        />
      );
    }

    return (
      <SupportRequestCustomerModalView
        {...this.props}
        {...this.state}
        onSubmit={this.onSubmit}
        onClose={this.onResetAndClose}
        updateProjectDropdownChange={this.updateProjectDropdownChange}
        updateDeploymentDropdownChange={this.updateDeploymentDropdownChange}
        handleDismissError={this.handleDismissError}
        onDescriptionChanged={this.onDescriptionChanged}
        onSeverityChanged={this.onSeverityChanged}
        onTitleChanged={this.onTitleChanged}
        subjectVisible={this.props.deployment ? false : true}
      />
    );
  }
}

export default withRefresh()(SupportRequestCustomer);
