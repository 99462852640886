//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Button, Icon, Input, InputOnChangeData } from "semantic-ui-react";
import styled from "@emotion/styled";
import _ from "lodash";

interface INumberInputProps {
  min?: number;
  max?: number;
  value: number;
  ext?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
}

interface INumberInputState {
  value: number;
  valueString: string;
  errorMessage?: string;
  prevValue: number;
}

const StyledGroup = styled("div")`
  display: inline-flex;
  .ui.input input {
    text-align: center;
    width: 6em !important;
  }
  .ui.input.error input {
    background-color: red;
  }
`;

export class NumberInput extends Component<INumberInputProps, INumberInputState> {
  state: INumberInputState = {
    value: this.props.value,
    valueString: `${this.props.value}${this.props.ext || ""}`,
    errorMessage: undefined,
    prevValue: 0,
  };

  static getDerivedStateFromProps(props: INumberInputProps, state: INumberInputState) {
    if (props.value != state.prevValue) {
      return {
        value: props.value,
        valueString: `${props.value}${props.ext || ""}`,
        prevValue: props.value,
      };
    }
    return null;
  }

  onNumberChange = (e: any, data: InputOnChangeData) => {
    this.setState({ valueString: data.value });
  };

  onBlur = () => {
    this.validateValueString(this.props.onChange);
  };

  validateValueString = (callback: (newValue: number) => void) => {
    if (this.state.valueString == "") {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.valueString = `${this.props.value}`;
    }
    let numberString = this.state.valueString;
    if (this.props.ext) {
      numberString = _.replace(numberString, this.props.ext, "");
    }
    const x = parseInt(numberString);
    if (isNaN(x)) {
      this.setState({ errorMessage: "Not a valid number" });
    } else {
      if ((this.props.min != undefined && x < this.props.min) || (this.props.max != undefined && x > this.props.max)) {
        this.setState({ errorMessage: `Number must be in between ${this.props.min} and ${this.props.max}` });
      } else {
        this.setState(
          {
            value: x,
            valueString: `${x}${this.props.ext || ""}`,
            errorMessage: undefined,
          },
          () => {
            callback(x);
          }
        );
      }
    }
  };

  onMinus = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    this.validateValueString((x: number) => {
      this.setState(
        (old: INumberInputState) => {
          const newValue = this.props.min == undefined || old.value > this.props.min ? old.value - 1 : old.value;
          return { value: newValue, valueString: `${newValue}${this.props.ext || ""}` };
        },
        () => {
          this.props.onChange(this.state.value);
        }
      );
    });
  };

  onPlus = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    this.validateValueString((x: number) => {
      this.setState(
        (old: INumberInputState) => {
          const newValue = this.props.max == undefined || old.value < this.props.max ? old.value + 1 : old.value;
          return { value: newValue, valueString: `${newValue}${this.props.ext || ""}` };
        },
        () => {
          this.props.onChange(this.state.value);
        }
      );
    });
  };

  render() {
    return (
      <StyledGroup>
        <Button
          basic
          icon
          size="small"
          onClick={this.onMinus}
          disabled={this.props.disabled || (this.props.min != undefined && this.state.value <= this.props.min)}
        >
          <Icon name="minus" />
        </Button>
        <Input
          focus
          value={this.state.valueString}
          onChange={this.onNumberChange}
          onBlur={this.onBlur}
          error={!!this.state.errorMessage}
          placeholder={this.props.value}
          disabled={this.props.disabled}
        />
        <Button
          basic
          icon
          size="small"
          onClick={this.onPlus}
          disabled={this.props.disabled || (this.props.max != undefined && this.state.value >= this.props.max)}
        >
          <Icon name="plus" />
        </Button>
      </StyledGroup>
    );
  }
}
