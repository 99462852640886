//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import { concat } from "lodash";
import moment, { Moment } from "moment";

// Do not import from this file directly.
// Import from `lib.tsx` instead.

export interface CreationDateFilter {
  id: string;
  name: string;
  created_from_hours: number; // 0 means not relevant
  created_to_hours: number; // 0 means not relevant
  filterStart?: (input: Moment) => Moment;
  filterEnd?: (input: Moment) => Moment;
}

// Default formatting for moment(..).format(momentDefaultFormat)
export const momentDefaultFormat = "YYYY-MM-DD HH:mm:ss z";

// Start of 2019 in UTC
export const momentStartOfProject = () => moment.utc("2019-01-01");

// Current date/time in UTC
export const momentNow = () => moment().utc();

const identity = (input: Moment) => input;

const thisWeekStart = (input: Moment) => input.startOf("week");

const prevWeekStart = (input: Moment) => input.subtract(1, "week").startOf("week");
const prevWeekEnd = (input: Moment) => input.subtract(1, "week").endOf("week");

const weekCreationDateFilters = (fullPeriod: boolean) => {
  let result = [];
  let moment = momentNow().startOf("week");
  for (let i = 0; i < 12; i++) {
    const delta = i;
    result.push({
      id: `week${i}`,
      name: "Week " + moment.format("w YYYY"),
      created_from_hours: 0,
      created_to_hours: 0,
      filterStart: (input: Moment) => input.subtract(delta, "week").startOf("week"),
      filterEnd: (input: Moment) => (!fullPeriod && i === 0 ? momentNow() : input.subtract(delta, "week").endOf("week")),
    });
    moment = moment.subtract(1, "week");
  }
  return result;
};

const monthCreationDateFilters = (fullPeriod: boolean) => {
  let result = [];
  let moment = momentNow().startOf("month");
  for (let i = 0; i < 36; i++) {
    const delta = i;
    result.push({
      id: `month${i}`,
      name: moment.format("MMM YYYY"),
      created_from_hours: 0,
      created_to_hours: 0,
      filterStart: (input: Moment) => input.subtract(delta, "month").startOf("month"),
      filterEnd: (input: Moment) => (!fullPeriod && i === 0 ? momentNow() : input.subtract(delta, "month").endOf("month")),
    });
    moment = moment.subtract(1, "month");
  }
  return result;
};

const quarterCreationDateFilters = (fullPeriod: boolean) => {
  let result = [];
  let moment = momentNow().startOf("quarter");
  for (let i = 0; i < 12; i++) {
    const delta = i;
    result.push({
      id: `q${i}`,
      name: "Q" + moment.format("Q YYYY"),
      created_from_hours: 0,
      created_to_hours: 0,
      filterStart: (input: Moment) => input.subtract(delta, "quarter").startOf("quarter"),
      filterEnd: (input: Moment) => (!fullPeriod && i === 0 ? momentNow() : input.subtract(delta, "quarter").endOf("quarter")),
    });
    moment = moment.subtract(1, "quarter");
  }
  return result;
};

const yearCreationDateFilters = (fullPeriod: boolean) => {
  let result = [];
  let moment = momentNow().startOf("year");
  for (let i = 0; i < 3; i++) {
    const delta = i;
    result.push({
      id: `year${i}`,
      name: moment.format("YYYY"),
      created_from_hours: 0,
      created_to_hours: 0,
      filterStart: (input: Moment) => input.subtract(delta, "year").startOf("year"),
      filterEnd: (input: Moment) => (!fullPeriod && i === 0 ? momentNow() : input.subtract(delta, "year").endOf("year")),
    });
    moment = moment.subtract(1, "year");
  }
  return result;
};

export const CreationDateFilters: CreationDateFilter[] = concat(
  [
    { id: "all", name: "All", created_from_hours: 0, created_to_hours: 0, filterStart: undefined, filterEnd: undefined },
    { id: "last24h", name: "In last 24h", created_from_hours: 24, created_to_hours: 0, filterStart: identity, filterEnd: identity },
    { id: "last484", name: "In last 48h", created_from_hours: 48, created_to_hours: 0, filterStart: identity, filterEnd: identity },
    { id: "last7d", name: "In last 7 days", created_from_hours: 24 * 7, created_to_hours: 0, filterStart: identity, filterEnd: identity },
    { id: "blast7d", name: "Earlier than 7 days ago", created_from_hours: 0, created_to_hours: 24 * 7, filterStart: identity, filterEnd: identity },
    { id: "thisweek", name: "This week", created_from_hours: 0, created_to_hours: 0, filterStart: thisWeekStart, filterEnd: identity },
    { id: "prevweek", name: "Previous week", created_from_hours: 0, created_to_hours: 0, filterStart: prevWeekStart, filterEnd: prevWeekEnd },
  ],
  monthCreationDateFilters(false),
  yearCreationDateFilters(false)
);

export const MonthAndYearCreationDateFilters = (fullPeriod: boolean) => concat(monthCreationDateFilters(fullPeriod), yearCreationDateFilters(false));

export const MonthAndWeekAndYearCreationDateFilters = (fullPeriod: boolean) =>
  concat(monthCreationDateFilters(fullPeriod), weekCreationDateFilters(fullPeriod), yearCreationDateFilters(false));

export const QuarterMonthAndWeekAndYearCreationDateFilters = (fullPeriod: boolean) =>
  concat(yearCreationDateFilters(false), quarterCreationDateFilters(fullPeriod), monthCreationDateFilters(fullPeriod), weekCreationDateFilters(fullPeriod));
