//
// DISCLAIMER
//
// Copyright 2019-2024 ArangoDB GmbH, Cologne, Germany
//

import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Header, Popup, Icon, Button, Form, Divider } from "semantic-ui-react";
import { Duration, Timestamp } from "../../api/googleTypes";
import {
  Model,
  DeploymentSize as ApiDeploymentSize,
  Deployment_ModelSpec as ApiDeployment_ModelSpec,
  Deployment_ServersSpec as ApiDeployment_ServersSpec,
  Deployment_DiskAutoSizeSettings as APIDiskAutoSizeSettings,
  MemoryReserve,
  UpdateDeploymentMemoryReservesRequest as ApiUpdateDeploymentMemoryReservesRequest,
  UpdateDeploymentTopologyAwarenessRequest as ApiUpdateDeploymentTopologyAwarenessRequest,
  VolumeInfo as ApiVolumeInfo,
  DeploymentResources as ApiDeploymentResources,
  UpdateKubernetesResourcesPatchesRequest as ApiUpdateKubernetesResourcesPatchesRequest,
  JSONPatch,
  MemoryFactors as ApiMemoryFactors,
  CPUFactors as ApiCPUFactors,
  NodeSizeDetails as ApiNodeSizeDetails,
} from "../../api/lib";

import { EditBox, Field, FieldContent as FC, FieldLabelWide as FL, humanizeDuration, DiskPerformanceInput } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { AsyncResult } from "../../util/Types";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { CommentCreationArgs } from "../comments/CommentTypes";
import { MemoryReserveView } from "./MemoryReserveView";
import { DiskSizeView } from "./DiskSizeView";
import { TopologyAwarenessView } from "./TopologyAwarenessView";
import UpdateKubernetesResourcesPatchModal from "./UpdateKubernetesResourcesPatchModal";
import { VolumeInfoView } from "./VolumeInfosViews";
import { VSTSupportView } from "./ConfigurationView/VSTSupportView";
import EnvoyAccessLogView from "./ConfigurationView/EnvoyAccessLogView";
import IConfigurationViewItemProps from "./ConfigurationView/IConigurationViewItemProps";

export interface IConfigurationViewArgs extends IWithRefreshProps, RouteComponentProps, CommentCreationArgs, IConfigurationViewItemProps {
  organizationId: string;
  regionId: string;
  model?: ApiDeployment_ModelSpec;
  servers?: ApiDeployment_ServersSpec;
  size?: ApiDeploymentSize;
  nodeSizeDetails: ApiNodeSizeDetails;
  cpuCoordinatorPercent: number;
  cpuDbServerPercent: number;
  onUpdateCPURatio: (newCPURatio: string) => Promise<AsyncResult<void>>;
  can_edit_cpu_ratio: boolean;
  cpuResources?: ApiDeploymentResources;
  memoryCoordinatorPercent: number;
  memoryDbServerPercent: number;
  onUpdateMemoryRatio: (newMemoryRatio: string) => Promise<AsyncResult<void>>;
  can_edit_memory_ratio: boolean;
  memoryResources?: ApiDeploymentResources;
  agencyResourceFactor: number;
  can_edit_agency_resource_factor: boolean;
  onUpdateAgencyResourceFactor: (newFactor: string) => Promise<AsyncResult<void>>;
  communicationMethod: string;
  can_edit_communication_method: boolean;
  onUpdateCommunicationMethod: (newMethod: string) => void;
  agentsMemoryReserve?: MemoryReserve;
  onUpdateMemoryReserve: (request: ApiUpdateDeploymentMemoryReservesRequest) => any;
  diskAutoSizeSettings?: APIDiskAutoSizeSettings;
  coordinatorsMemoryReserve?: MemoryReserve;
  dbserversMemoryReserve?: MemoryReserve;
  canEditMemoryReserves: boolean;
  isTopologyAware: boolean;
  numberOfZonesOverride: number;
  canUpdateTopologyAwareness: boolean;
  onUpdateTopologyAwareness: (request: ApiUpdateDeploymentTopologyAwarenessRequest) => any;
  schedulingPoliciesEnabled: boolean;

  agentsVolumeInfo?: ApiVolumeInfo;
  dbserverVolumeInfo?: ApiVolumeInfo;
  canEditVolumeInfos: boolean;
  onSaveVolumeInfos: (newAgentVolumeInfo?: ApiVolumeInfo, newDbserverVolumeInfo?: ApiVolumeInfo) => Promise<{ errorMessage: string }>;
  onEditAgentsVolumeInfos: () => void;
  onCancelEditAgentsVolumeInfos: () => void;
  editAgentsVolumeInfos: boolean;
  onEditDBServersVolumeInfos: () => void;
  onCancelEditDBServersVolumeInfos: () => void;
  editDBServersVolumeInfos: boolean;
  processingUpdateVolumeInfos: boolean;
  customStorageClassName?: string;
  diskPerformanceId?: string;
  diskPerformanceLocked?: boolean;
  lastDiskPerformanceUpdatedAt?: Timestamp;
  lastDiskSizeUpdatedAt?: Timestamp;
  diskRateLimitPeriod?: Duration;
  diskRateLimitActive?: Duration;
  canEditDiskPerformanceLocked: boolean;
  onUpdateDiskPerformanceLocked: (locked: boolean, useRegularVolumeIinfo: boolean) => void;
  canEditDiskPerformance: boolean;
  editDiskPerformance: boolean;
  onEditDiskPerformance: () => void;
  onCancelEditDiskPerformance: () => void;
  onDiskPerformanceChange: (id: string) => void;
  useShardRebalancer: boolean;
  canUpdateUseShardRebalacner: boolean;
  onUpdateDeploymentUseShardRebalancer: (newValue: boolean) => void;
  canUpdateKubernetesResourcesPatches: boolean;
  arangoDeploymentPatches?: JSONPatch[];
  cloudDeploymentPatches?: JSONPatch[];
  onUpdateKubernetesResourcesPatches: (data: ApiUpdateKubernetesResourcesPatchesRequest) => Promise<AsyncResult<void>>;
  onSetSchedulingPolciiesEnabled: (enabled: boolean) => void;

  memoryFactors?: ApiMemoryFactors;
  cpuFactors?: ApiCPUFactors;
  onUpdateCPUFactors: (newFactor: string) => Promise<AsyncResult<void>>;
  onUpdateMemoryFactors: (newFactor: string) => Promise<AsyncResult<void>>;
  canUpdateMemoryFactors?: boolean;
  canUpdateCPUFactors?: boolean;
  canSetSchedulingPoliciesEnabled: boolean;
  onUpdateDiskSize: (newValue: number, ignoreMax: boolean) => Promise<AsyncResult<void>>;
  canEditDiskSize: boolean;
  canEditDiskSizeIgnoreMax: boolean;
}

export const ConfigurationView = ({ ...args }: IConfigurationViewArgs) => {
  const model = args.model || {};
  const hasAgency = Model.hasAgency(model.model || "");
  const isCluster = Model.isCluster(model.model || "");

  const agentVolumeInfo = args.agentsVolumeInfo || {};
  const dbserverVolumeInfo = args.dbserverVolumeInfo || {};

  const onSaveAgentVolumeInfo = (newVolumeType: string, newVolumeIOPS: number, newVolumeThroughput: number) => {
    const newAgentsVolumeInfo: ApiVolumeInfo = {
      type: newVolumeType,
      iops: newVolumeIOPS,
      throughput: newVolumeThroughput,
    };
    return args.onSaveVolumeInfos(newAgentsVolumeInfo, undefined);
  };

  const onSaveDBServerVolumeInfo = (newVolumeType: string, newVolumeIOPS: number, newVolumeThroughput: number) => {
    const newDBServerVolumeInfo: ApiVolumeInfo = {
      type: newVolumeType,
      iops: newVolumeIOPS,
      throughput: newVolumeThroughput,
    };
    return args.onSaveVolumeInfos(undefined, newDBServerVolumeInfo);
  };

  return (
    <div>
      <Header sub>Configuration</Header>
      <Field>
        <FL>Model</FL>
        <FC>{model.model || "?"}</FC>
      </Field>
      <Field>
        <FL>Size</FL>
        <FC>
          {!!model.node_size_id && (
            <span>
              {args.nodeSizeDetails.name} ({args.nodeSizeDetails.cpu_size})
            </span>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Communication method</FL>
        <FC>
          <span>{args.communicationMethod}</span>
          {args.can_edit_communication_method && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                <Dropdown.Item key="headless" text="Use headless service" onClick={() => args.onUpdateCommunicationMethod("headless")} />
                <Dropdown.Item key="headless-dns" text="Use headless DNS" onClick={() => args.onUpdateCommunicationMethod("headless-dns")} />
                <Dropdown.Item key="ip" text="Use IP" onClick={() => args.onUpdateCommunicationMethod("ip")} />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>

      <VSTSupportView requireComment={args.requireComment} requirePermission={args.requirePermission} />
      <EnvoyAccessLogView requireComment={args.requireComment} requirePermission={args.requirePermission} />

      <Field>
        <FL>Custom StorageClass name</FL>
        <FC>{args.customStorageClassName || "-"}</FC>
      </Field>
      {args.canUpdateKubernetesResourcesPatches && (
        <Field>
          <FL>Update ArangoDeployment kubernetes Patches</FL>
          <FC>
            <UpdateKubernetesResourcesPatchModal
              resourceType="ArangoDeployment"
              patches={args.arangoDeploymentPatches || []}
              onUpdateKubernetesResourcesPatches={args.onUpdateKubernetesResourcesPatches}
            />
          </FC>
        </Field>
      )}
      {args.canUpdateKubernetesResourcesPatches && (
        <Field>
          <FL>Update CloudDeployment kubernetes Patches</FL>
          <FC>
            <UpdateKubernetesResourcesPatchModal
              resourceType="CloudDeployment"
              patches={args.cloudDeploymentPatches || []}
              onUpdateKubernetesResourcesPatches={args.onUpdateKubernetesResourcesPatches}
            />
          </FC>
        </Field>
      )}
      <Field>
        <FL>Scheduling policies propgation from NodeSize enabled</FL>
        <FC>
          <span>{args.schedulingPoliciesEnabled ? "Yes" : "No"}</span>
          {args.canSetSchedulingPoliciesEnabled && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!!args.schedulingPoliciesEnabled && (
                  <Dropdown.Item key="remove-hm" text="Disable scheduling policies" onClick={() => args.onSetSchedulingPolciiesEnabled(false)} />
                )}
                {!args.schedulingPoliciesEnabled && (
                  <Dropdown.Item key="set-hm" text="Enable scheduling policies" onClick={() => args.onSetSchedulingPolciiesEnabled(true)} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      {isCluster && (
        <div>
          <Field>
            <FL>Topology aware</FL>
            <FC>
              <TopologyAwarenessView
                isTopologyAware={args.isTopologyAware}
                numberOfZonesOverride={args.numberOfZonesOverride}
                canUpdateTopologyAwareness={args.canUpdateTopologyAwareness}
                onUpdateTopologyAwareness={args.onUpdateTopologyAwareness}
                createComment={args.createComment}
              />
            </FC>
          </Field>
          <Field>
            <FL>Use Shard Re-balancer</FL>
            <FC>
              <span>{args.useShardRebalancer ? "Yes" : "No"}</span>
              {args.canUpdateUseShardRebalacner && (
                <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
                  <Dropdown.Menu>
                    {args.useShardRebalancer && (
                      <Dropdown.Item
                        key="remove-hm"
                        text="Turn off automatic shard re-balancing"
                        onClick={() => args.onUpdateDeploymentUseShardRebalancer(false)}
                      />
                    )}
                    {!args.useShardRebalancer && (
                      <Dropdown.Item key="set-hm" text="Turn on automatic shard re-balancing" onClick={() => args.onUpdateDeploymentUseShardRebalancer(true)} />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </FC>
          </Field>
        </div>
      )}
      <Divider hidden />

      <Header size="tiny">Resource Allocation</Header>
      {isCluster && (
        <Field>
          <FL>Memory ratio (Coordinator/DB server)</FL>
          <FC>
            <EditBox
              {...args}
              initialValue={`${args.memoryCoordinatorPercent}/${args.memoryDbServerPercent}`}
              update={args.onUpdateMemoryRatio}
              placeholder={"Memory ratio e.g. 25/75"}
              editable={args.can_edit_memory_ratio}
              label="Memory ratio (Coordinator/DB server)"
            />
          </FC>
        </Field>
      )}
      <Field>
        <FL>Memory factors (Coordinator/DBServer)</FL>
        <FC>
          <EditBox
            {...args}
            initialValue={`${(args.memoryFactors || {}).coordinator_factor || "-"}/${(args.memoryFactors || {}).dbserver_factor || "-"}`}
            update={args.onUpdateMemoryFactors}
            placeholder={"Memory Factor eg: 0.5/0.7 (use 0/0 for default)"}
            editable={args.canUpdateMemoryFactors}
            label="Memory factors (Coordinator/DBServer)"
          />
        </FC>
      </Field>
      {isCluster && (
        <Field>
          <FL>CPU ratio (Coordinator/DB server)</FL>
          <FC>
            <EditBox
              {...args}
              initialValue={`${args.cpuCoordinatorPercent}/${args.cpuDbServerPercent}`}
              update={args.onUpdateCPURatio}
              placeholder={"CPU ratio e.g. 25/75"}
              editable={args.can_edit_cpu_ratio}
              label="CPU ratio (Coordinator/DB server)"
            />
          </FC>
        </Field>
      )}
      <Field>
        <FL>CPU factors (Coordinator/DBServer)</FL>
        <FC>
          <EditBox
            {...args}
            initialValue={`${(args.cpuFactors || {}).coordinator_factor || "-"}/${(args.cpuFactors || {}).dbserver_factor || "-"}`}
            update={args.onUpdateCPUFactors}
            placeholder={"CPU Factor eg: 0.5/0.7 (use 0/0 for default)"}
            editable={args.canUpdateCPUFactors}
            label="CPU factors (Coordinator/DBServer)"
          />
        </FC>
      </Field>

      {hasAgency && (
        <Field>
          <FL>Agency resources factor</FL>
          <FC>
            <EditBox
              {...args}
              initialValue={`${args.agencyResourceFactor}`}
              update={args.onUpdateAgencyResourceFactor}
              placeholder={"Agency resources factor (e.g. 2.0)"}
              editable={args.can_edit_agency_resource_factor}
              label="Agency resources factor"
            />
          </FC>
        </Field>
      )}

      {isCluster && (
        <div>
          <Header size="tiny">Coordinators</Header>
          <Field>
            <FL>Memory</FL>
            <FC>
              <Popup
                position="right center"
                trigger={
                  <div>
                    {args.servers ? args.servers.coordinators : "?"}
                    <span> &times; </span>
                    {args.servers ? args.servers.coordinator_memory_size : "?"}
                    <span> GiB Memory</span>
                  </div>
                }
                content={
                  <div>
                    {args.memoryResources ? args.memoryResources.coordinator_requests : "?"}
                    <span>/</span>
                    {args.memoryResources ? args.memoryResources.coordinator_limits : "?"}
                    <span> bytes</span>
                  </div>
                }
              />
            </FC>
          </Field>
          <Field>
            <FL>Memory reserve</FL>
            <FC>
              <MemoryReserveView
                memoryReserve={args.coordinatorsMemoryReserve}
                canEditMemoryReserves={args.canEditMemoryReserves}
                onUpdateMemoryReserve={(reserve: MemoryReserve) => {
                  const req: ApiUpdateDeploymentMemoryReservesRequest = { coordinators_memory_reserve: reserve };
                  args.onUpdateMemoryReserve(req);
                }}
                createComment={args.createComment}
              />
            </FC>
          </Field>
          <Field>
            <FL>CPU</FL>
            <FC>
              <div>
                {args.cpuResources ? args.cpuResources.coordinator_requests : "?"}
                <span>/</span>
                {args.cpuResources ? args.cpuResources.coordinator_limits : "?"}
                <span> vCPU</span>
              </div>
            </FC>
          </Field>
        </div>
      )}
      <Header size="tiny">Database servers</Header>
      <Field>
        <FL>Minimum Servers</FL>
        <FC>{args.servers ? args.servers.minimum_dbservers_count : "-"}</FC>
      </Field>
      <Field>
        <FL>Memory</FL>
        <FC>
          <Popup
            position="right center"
            trigger={
              <div>
                {args.servers ? args.servers.dbservers : "?"}
                <span> &times; </span>
                {args.servers ? args.servers.dbserver_memory_size : "?"}
                <span> GiB Memory</span>
              </div>
            }
            content={
              <div>
                {args.memoryResources ? args.memoryResources.dbserver_requests : "?"}
                <span>/</span>
                {args.memoryResources ? args.memoryResources.dbserver_limits : "?"}
                <span> bytes</span>
              </div>
            }
          />
        </FC>
      </Field>
      <Field>
        <FL>Memory reserve</FL>
        <FC>
          <MemoryReserveView
            memoryReserve={args.dbserversMemoryReserve}
            canEditMemoryReserves={args.canEditMemoryReserves}
            onUpdateMemoryReserve={(reserve: MemoryReserve) => {
              const req: ApiUpdateDeploymentMemoryReservesRequest = { dbservers_memory_reserve: reserve };
              args.onUpdateMemoryReserve(req);
            }}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      <Field>
        <FL>CPU</FL>
        <FC>
          <div>
            {args.cpuResources ? args.cpuResources.dbserver_requests : "?"}
            <span>/</span>
            {args.cpuResources ? args.cpuResources.dbserver_limits : "?"}
            <span> vCPU</span>
          </div>
        </FC>
      </Field>
      <Field>
        <FL>Disk</FL>
        <FC>
          <DiskSizeView
            serverCount={args.servers && args.servers.dbservers}
            diskSize={args.servers && args.servers.dbserver_disk_size}
            diskAutoSizeSettings={args.diskAutoSizeSettings}
            canEditDiskSize={args.canEditDiskSize}
            canEditDiskSizeIgnoreMax={args.canEditDiskSizeIgnoreMax}
            onUpdateDiskSize={args.onUpdateDiskSize}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      <Field>
        <FL>Minimum Disk</FL>
        <FC>{args.servers ? (args.servers.minimum_dbserver_disk_size || "0") + " GiB" : "-"}</FC>
      </Field>
      <Field>
        <FL>Disk performance ID</FL>
        <FC>
          {!args.editDiskPerformance && (
            <span>
              {args.diskPerformanceId || "-"}{" "}
              {args.canEditDiskPerformance && <Icon name="pencil" onClick={args.onEditDiskPerformance} className="edit-pencil" />}
            </span>
          )}
          {args.editDiskPerformance && (
            <Form>
              <Form.Field>
                <DiskPerformanceInput
                  {...args}
                  placeholder="disk performance"
                  id={args.diskPerformanceId}
                  onChange={args.onDiskPerformanceChange}
                  notAll
                  regionId={args.regionId}
                  nodeSizeId={model.node_size_id}
                  nodeDiskSize={model.node_disk_size}
                />
                <Button.Group icon basic size="small">
                  <Button icon="cancel" onClick={args.onCancelEditDiskPerformance} />
                </Button.Group>
              </Form.Field>
            </Form>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Disk performance locked</FL>
        <FC>
          <span>{args.diskPerformanceLocked ? "Yes" : "No"}</span>
          {args.canEditDiskPerformanceLocked && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.diskPerformanceLocked && (
                  <Dropdown.Item
                    key="remove-dpl-n"
                    text="Turn off disk performance locked for the deployment (do not update VolumeInfo)"
                    onClick={() => args.onUpdateDiskPerformanceLocked(false, false)}
                  />
                )}
                {args.diskPerformanceLocked && (
                  <Dropdown.Item
                    key="remove-dpl-y"
                    text="Turn off disk performance locked for the deployment (use regular VolumeInfo)"
                    onClick={() => args.onUpdateDiskPerformanceLocked(false, true)}
                  />
                )}
                {!args.diskPerformanceLocked && (
                  <Dropdown.Item
                    key="set-pl"
                    text="Turn on disk performance locked  for the deployment"
                    onClick={() => args.onUpdateDiskPerformanceLocked(true, false)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Disk performance last updated</FL>
        <FC>
          {args.lastDiskPerformanceUpdatedAt ? <DateTimePopupWithUTCAndLocalTime dateTime={args.lastDiskPerformanceUpdatedAt} label="Last updated at" /> : "-"}
        </FC>
      </Field>
      <Field>
        <FL>Disk size last updated</FL>
        <FC>{args.lastDiskSizeUpdatedAt ? <DateTimePopupWithUTCAndLocalTime dateTime={args.lastDiskSizeUpdatedAt} label="Last updated at" /> : "-"}</FC>
      </Field>
      <Field>
        <FL>Disk Rate limit</FL>
        <FC>
          {args.diskRateLimitPeriod ? humanizeDuration(args.diskRateLimitPeriod) : "-"}
          {args.diskRateLimitActive && "(active-for=" + humanizeDuration(args.diskRateLimitActive) + ")"}
        </FC>
      </Field>
      <Field>
        <FL>Disk params</FL>
        <FC>
          <VolumeInfoView
            title="DBServer"
            volumeType={dbserverVolumeInfo.type}
            volumeIOPS={dbserverVolumeInfo.iops}
            volumeThroughput={dbserverVolumeInfo.throughput}
            canEdit={args.canEditVolumeInfos}
            onEdit={args.onEditDBServersVolumeInfos}
            editing={args.editDBServersVolumeInfos}
            onCancelEdit={args.onCancelEditDBServersVolumeInfos}
            onSaveEdit={onSaveDBServerVolumeInfo}
            processingUpdate={args.processingUpdateVolumeInfos}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      {hasAgency && (
        <div>
          <Header size="tiny">Agents</Header>
          <Field>
            <FL>Memory</FL>
            <FC>
              <Popup
                position="right center"
                trigger={
                  <div>
                    {args.size ? args.size.agents : "?"}
                    <span> &times; </span>
                    {args.size ? args.size.agent_memory_size : "?"}
                    <span> GiB Memory</span>
                  </div>
                }
                content={
                  <div>
                    {args.memoryResources ? args.memoryResources.agent_requests : "?"}
                    <span>/</span>
                    {args.memoryResources ? args.memoryResources.agent_limits : "?"}
                    <span> bytes</span>
                  </div>
                }
              />
            </FC>
          </Field>
          <Field>
            <FL>Memory reserve</FL>
            <FC>
              <MemoryReserveView
                memoryReserve={args.agentsMemoryReserve}
                canEditMemoryReserves={args.canEditMemoryReserves}
                onUpdateMemoryReserve={(reserve: MemoryReserve) => {
                  const req: ApiUpdateDeploymentMemoryReservesRequest = { agents_memory_reserve: reserve };
                  args.onUpdateMemoryReserve(req);
                }}
                createComment={args.createComment}
              />
            </FC>
          </Field>
          <Field>
            <FL>CPU</FL>
            <FC>
              <div>
                {args.cpuResources ? args.cpuResources.agent_requests : "?"}
                <span>/</span>
                {args.cpuResources ? args.cpuResources.agent_limits : "?"}
                <span> vCPU</span>
              </div>
            </FC>
          </Field>
          <Field>
            <FL>Disk</FL>
            <FC>
              <div>
                {args.size ? args.size.agents : "?"}
                <span> &times; </span>
                {args.size ? args.size.agent_disk_size : "?"}
                <span> GiB Disk</span>
              </div>
            </FC>
          </Field>
          <Field>
            <FL>Disk params</FL>
            <FC>
              <VolumeInfoView
                title="Agent"
                volumeType={agentVolumeInfo.type}
                volumeIOPS={agentVolumeInfo.iops}
                volumeThroughput={agentVolumeInfo.throughput}
                canEdit={args.canEditVolumeInfos}
                onEdit={args.onEditAgentsVolumeInfos}
                editing={args.editAgentsVolumeInfos}
                onCancelEdit={args.onCancelEditAgentsVolumeInfos}
                onSaveEdit={onSaveAgentVolumeInfo}
                processingUpdate={args.processingUpdateVolumeInfos}
                createComment={args.createComment}
              />
            </FC>
          </Field>
        </div>
      )}
    </div>
  );
};
