//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Form, Modal, Input, Grid, Dropdown } from "semantic-ui-react";
import {
  IDOptions as ApiIDOptions,
  ListOptions as ApiListOptions,
  PlanAssignment as ApiPlanAssignment,
  Subject as ApiSubject,
  Organization as ApiOrganization,
  Project as ApiProject,
  ProjectList as ApiProjectList,
  DeploymentInfo as ApiDeploymentInfo,
  DeploymentInfoList as ApiDeploymentInfoList,
  ListAllDeploymentsRequest as ApiListAllDeploymentsRequest,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/lib";
import { orderBy, toString } from "lodash";

interface ISelectionViewArgs extends IWithRefreshProps, RouteComponentProps {
  organization_id: string;
  organization?: ApiOrganization;
  onOrganizationIDChanged: (id: string) => void;
  projects?: ApiProject[];
  project_id: string;
  onProjectIDChanged: (id: string) => void;
  deployments?: ApiDeploymentInfo[];
  deployment_id: string;
  onDeploymentIDChanged: (id: string) => void;
}

const SelectionView = ({ ...args }: ISelectionViewArgs) => {
  const projectOptions = orderBy(args.projects || [], "name").map((x, i) => {
    return {
      key: `project-${x.id || ""}`,
      text: x.name || "",
      value: x.id || "",
    };
  });
  projectOptions.unshift({
    key: "project-all",
    text: "All",
    value: "all",
  });

  const deploymentOptions = orderBy(args.deployments || [], "name").map((x, i) => {
    const d = x.deployment || {};
    return {
      key: `deployment-${d.id || ""}`,
      text: d.name || "",
      value: d.id || "",
    };
  });
  deploymentOptions.unshift({
    key: "deployment-all",
    text: "All",
    value: "all",
  });

  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column width="8">
            <Form.Field required>
              <label>Organization ID</label>
              <Input autoFocus value={args.organization_id} onChange={(e, d) => args.onOrganizationIDChanged(d.value)} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="8">
            <Form.Field>
              <label>Organization</label>
              <Input value={(args.organization && args.organization.name) || "?"} disabled />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {!!args.projects && (
            <Grid.Column width="8">
              <Form.Field>
                <label>Project</label>
                <Dropdown selection value={args.project_id || "all"} options={projectOptions} onChange={(e, d) => args.onProjectIDChanged(toString(d.value))} />
              </Form.Field>
            </Grid.Column>
          )}
          {!!args.deployments && (
            <Grid.Column width="8">
              <Form.Field>
                <label>Deployment</label>
                <Dropdown
                  selection
                  value={args.deployment_id || "all"}
                  options={deploymentOptions}
                  onChange={(e, d) => args.onDeploymentIDChanged(toString(d.value))}
                />
              </Form.Field>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

interface IAPI {
  GetOrganization: (req: ApiIDOptions) => Promise<ApiOrganization>;
  CreatePlanAssignment: (req: ApiPlanAssignment) => Promise<ApiPlanAssignment>;
  ListProjects: (req: ApiListOptions) => Promise<ApiProjectList>;
  ListAllDeployments: (req: ApiListAllDeploymentsRequest) => Promise<ApiDeploymentInfoList>;
}

interface ISelectPricingPlanAssignmentSubjectProps extends IWithRefreshProps, RouteComponentProps {
  api: IAPI;
  onClose: () => void;
  onSelectedSubjects: (subjects: ApiSubject[]) => void;
}

interface ISelectPricingPlanAssignmentSubjectState {
  organization_id: string;
  project_id: string;
  deployment_id: string;
  organization?: ApiOrganization;
  projects?: ApiProject[];
  deployments?: ApiDeploymentInfo[];
}

// The component to select a subject for a pricing plan assignment
class SelectPricingPlanAssignmentSubject extends Component<ISelectPricingPlanAssignmentSubjectProps, ISelectPricingPlanAssignmentSubjectState> {
  state: ISelectPricingPlanAssignmentSubjectState = {
    organization_id: "",
    project_id: "",
    deployment_id: "",
    organization: undefined,
    projects: undefined,
    deployments: undefined,
  };

  reloadOrganizationInfo = async () => {
    try {
      const org = await this.props.api.GetOrganization({ id: this.state.organization_id });
      this.setState({
        organization: org,
        projects: undefined,
        deployments: undefined,
      });
      const list = await this.props.api.ListProjects({ context_id: this.state.organization_id });
      this.setState({
        projects: list.items,
      });
    } catch {
      this.setState({
        organization: undefined,
        project_id: "",
        deployment_id: "",
        projects: undefined,
        deployments: undefined,
      });
    }
  };

  reloadProjectInfo = async () => {
    if (this.state.project_id) {
      try {
        const list = await this.props.api.ListAllDeployments({ project_id: this.state.project_id });
        this.setState({
          deployments: list.items,
        });
      } catch {
        this.setState({
          deployments: undefined,
        });
      }
    } else {
      this.setState({
        deployments: undefined,
      });
    }
  };

  onOrganizationIDChanged = (id: string) => {
    this.setState(
      {
        organization_id: id,
        project_id: "",
        deployment_id: "",
        organization: undefined,
        projects: undefined,
        deployments: undefined,
      },
      this.reloadOrganizationInfo
    );
  };
  onProjectIDChanged = (id: string) => {
    this.setState({ project_id: id }, this.reloadProjectInfo);
  };
  onDeploymentIDChanged = (id: string) => {
    this.setState({ deployment_id: id });
  };

  onSave = () => {
    const subject: ApiSubject = {
      organization_id: this.state.organization_id,
    };
    if (this.state.project_id) {
      subject.project_id = this.state.project_id;
    }
    if (this.state.deployment_id) {
      subject.deployment_id = this.state.deployment_id;
    }
    this.props.onSelectedSubjects([subject]);
  };

  render() {
    const all_valid = !!this.state.organization;
    return (
      <Modal open>
        <Modal.Header>Select subject to assign pricing plan to</Modal.Header>
        <Modal.Content>
          <SelectionView
            {...this.props}
            {...this.state}
            onOrganizationIDChanged={this.onOrganizationIDChanged}
            onProjectIDChanged={this.onProjectIDChanged}
            onDeploymentIDChanged={this.onDeploymentIDChanged}
          />
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel onClick={this.props.onClose} />
          <FormActionButtonSave primary onClick={this.onSave} disabled={!all_valid} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRefresh()(SelectPricingPlanAssignmentSubject);
