//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  UploadedDocument as ApiUploadedDocument,
  UploadedDocumentList as ApiUploadedDocumentList,
  ListUploadedDocumentsRequest as ApiListUploadedDocumentsRequest,
  IDOptions as ApiIDOptions,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, ErrorMessage, Loading, PagingButtons, Processing, SecondaryMenu, TextLink } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { CopyToClipboard } from "react-copy-to-clipboard";

import copy from "copy-to-clipboard";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

// Arguments for header view
interface IHeaderView {
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Content Type</Table.HeaderCell>
      <Table.HeaderCell>Created at</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <PagingButtons {...args} />
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiUploadedDocument;
  canView: boolean;
  getDocumentURL: (id: string) => void;
}

const RowView = ({ ...args }: IRowView) => {
  const document = args.item || {};
  const { id = "" } = document;
  const { getDocumentURL } = args;
  return (
    <Table.Row>
      <Table.Cell>
        <TextLink href={Routes.dashboard_uploaded_document_detailsWithId(id)} disabled={!args.canView}>
          {document.id}
        </TextLink>
      </Table.Cell>
      <Table.Cell>{document.name}</Table.Cell>
      <Table.Cell>{document.content_type}</Table.Cell>
      <Table.Cell>{document.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={document.created_at} label="Created at" /> : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {args.canView && document.content && (
            <>
              <CopyToClipboard onCopy={getDocumentURL}>
                <Button size="medium" icon="copy" />
              </CopyToClipboard>
              <a href={`data:${document.content_type};base64,${document.content}`} download={document.name}>
                <Button icon="download" size="medium" />
              </a>
            </>
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the list
interface IListView {
  items: ApiUploadedDocument[];
  canView: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  getDocumentURL: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => {
        const uploaded_document = item || {};
        const { id = "", name = "" } = uploaded_document;
        return <RowView {...args} key={id} item={item} getDocumentURL={() => args.getDocumentURL(name)} />;
      })}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No uploaded documents" : "No uploaded documents selected"}</div>;

// Interface describing the list view arguments
export interface IUploadedDocumentsListViewArgs extends RouteComponentProps {
  loading: boolean;
  list?: ApiUploadedDocumentList;
  canView: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  getDocumentURL: (id: string) => void;
}

export const UploadedDocumentsListView = ({ ...args }: IUploadedDocumentsListViewArgs) => {
  if (!args.list) {
    return <Loading />;
  }
  const isEmpty = !args.list.items || args.list.items.length === 0;
  const items = args.list ? args.list.items : undefined;
  return (
    <div>
      <ListView {...args} items={items || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

interface IUploadedDocumentsListProps extends IWithRefreshProps, RouteComponentProps {
  onCreate: () => void;
}

interface IUploadedDocumentsListState {
  list?: ApiUploadedDocumentList;
  uploading: boolean;
  errorMessage?: string;
  page: number;
  pageSize: number;
}

// The component to show uploaded documents as a list.
class UploadedDocumentList extends Component<IUploadedDocumentsListProps, IUploadedDocumentsListState> {
  state: IUploadedDocumentsListState = {
    list: undefined,
    uploading: false,
    errorMessage: undefined,
    page: 0,
    pageSize: 10,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadUploadedDocuments = async () => {
    const req: ApiListUploadedDocumentsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    const list = await apiClients.idashboardClient.ListUploadedDocuments(req);
    this.setState({
      list: list,
    });
  };

  getDocumentURL = async (id: string) => {
    const req: ApiIDOptions = {
      id,
    };

    try {
      const { url = "" } = await apiClients.idashboardClient.GetUploadedDocumentURL(req);
      copy(url);
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  refreshUploadedDocuments = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadUploadedDocuments);
  };

  componentDidMount() {
    this.refreshUploadedDocuments();
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.reloadUploadedDocuments
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.reloadUploadedDocuments
    );
  };

  render() {
    const list = this.state.list;
    const has_create = this.hasPermission("internal-dashboard.uploaddocument.create");
    const has_get = this.hasPermission("internal-dashboard.uploaddocument.get");

    return (
      <ContentSegment>
        <Processing active={this.state.uploading} message="Uploading document... please wait." />
        <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Upload Document</Menu.Item>
          <Button basic size="tiny" disabled={!has_create} onClick={this.props.onCreate} icon="plus" />
        </SecondaryMenu>
        <UploadedDocumentsListView
          {...this.state}
          {...this.props}
          list={list}
          getDocumentURL={this.getDocumentURL}
          canView={has_get}
          onNextPage={this.onNextPage}
          onPreviousPage={this.state.page > 0 ? this.onPreviousPage : undefined}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(UploadedDocumentList);
