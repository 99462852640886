//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React from "react";
import styled from "@emotion/styled";
import { Grid, TextArea, Form, Segment, TextAreaProps } from "semantic-ui-react";
import Markdown from "react-markdown";
import { DataProcessingAddendum as ApiDataProcessingAddendum } from "../../api/lib";

const StyledTextArea = styled(TextArea)`
  display: block;
  width: 100%;
`;

interface IEditorViewArgs {
  dpa: ApiDataProcessingAddendum;
  onContentChange: (content: string) => void;
}

export const EditorView = ({ ...args }: IEditorViewArgs) => (
  <Grid width="16">
    <Grid.Column width="8">
      <Form.Field>
        <label>Content</label>
        <StyledTextArea value={args.dpa.content || ""} onChange={(e: any, d: TextAreaProps) => args.onContentChange(d.value as string)} rows="25" />
      </Form.Field>
    </Grid.Column>
    <Grid.Column width="8">
      <Form.Field>
        <label>Preview</label>
        <Segment>
          <Markdown source={args.dpa.content || ""} />
        </Segment>
      </Form.Field>
    </Grid.Column>
  </Grid>
);
