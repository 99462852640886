//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//
import React, { useState, SyntheticEvent, FC } from "react";
import styled from "@emotion/styled";
import { Button, InputOnChangeData, Modal, Icon, Form, Input, Select, Message, Divider } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { ConfirmInfo, Confirm, ErrorMessage, FlexBox, FormActionButtonCancel, FormActionButtonSave, FormActionButtonCreate } from "../../ui/lib";
import { JSONPatch, UpdateKubernetesResourcesPatchesRequest as ApiUpdateKubernetesResourcesPatchesRequest } from "../../api/lib";
import ReactJson from "react-json-view";
import { AsyncResult } from "../../util/Types";

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Modes = {
  VIEW: `view`,
  EDIT: `edit`,
};
interface UpdateKubernetesResourcesPatchModalProps {
  resourceType: string;
  patches: JSONPatch[];
  onUpdateKubernetesResourcesPatches: (req: ApiUpdateKubernetesResourcesPatchesRequest) => Promise<AsyncResult<void>>;
}
const UpdateKubernetesResourcesPatchModal: FC<UpdateKubernetesResourcesPatchModalProps> = ({
  patches: initialPatches,
  resourceType,
  onUpdateKubernetesResourcesPatches,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mode, setMode] = useState(Modes.VIEW);
  const [patches, setPatches] = useState(initialPatches);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    setMode(Modes.VIEW);
    setError("");
    setConfirmInfo(undefined);
    setPatches(initialPatches);
  };
  const addNewPatch = () => {
    setPatches([
      ...patches,
      {
        op: "",
        path: "",
        value: "",
        description: "",
      },
    ]);
  };
  const removePatch = (index: number) => {
    setPatches(patches.filter((patch, i) => i !== index));
  };
  const onValueChange = (index: number, field: string, value: string) => {
    setPatches(
      patches.map((patch, i) => {
        if (i === index) {
          return {
            ...patch,
            [field]: value,
          };
        }
        return patch;
      })
    );
  };
  const onSubmit = async () => {
    const validPatches: JSONPatch[] = [];
    let invalidPatchIndex = -1;

    patches.forEach((patch, index) => {
      if (!patch) {
        return;
      }

      if (isEmpty(patch.op) || isEmpty(patch.path) || (patch.op !== "remove" && isEmpty(patch.value)) || isEmpty(patch.description)) {
        if (patch.op || patch.path || patch.value || patch.description) {
          invalidPatchIndex = index;
        }
        return;
      }

      // valid patches
      let path = (patch.path || "").trim().replace(/\./g, "/");
      if (!path.startsWith("/")) {
        path = `/${path}`;
      }

      validPatches.push({ ...patch, path });
    });

    if (invalidPatchIndex >= 0) {
      setError(`Patch #${invalidPatchIndex + 1} is invalid`);
      return;
    }

    setLoading(true);
    setError("");
    try {
      setConfirmInfo({
        header: `Update Kubernetes Resource Patches`,
        content: `Are you sure to update this configuration?`,
        warning: "This can lead change in kubernetes resources.",
        confirm: "CONFIRM!",
        onConfirm: async () => {
          const { error } = await onUpdateKubernetesResourcesPatches({
            resource_type: resourceType,
            patches: validPatches,
          });
          if (error) {
            setError(error.message);
            setConfirmInfo(undefined);
          } else {
            setConfirmInfo(undefined);
            onClose();
          }
        },
        onDenied: () => {
          setConfirmInfo(undefined);
        },
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div>
        <span>
          {patches.length} {patches.length > 1 ? `patches` : `patch`}
        </span>
        <StyledIcon name="pencil" className="edit-pencil" onClick={onOpen} />
      </div>
      {confirmInfo && <Confirm confirmInfo={confirmInfo} />}
      {isOpen && (
        <Modal open={isOpen} onClose={onClose} size="large">
          <Modal.Header>
            {mode === Modes.VIEW ? (
              <FlexBox justify="space-between">
                <div>{resourceType} Kubernetes Patches</div>
                <FormActionButtonCreate
                  icon="plus"
                  primary
                  onClick={() => {
                    setMode(Modes.EDIT);
                    if (patches.length === 0) {
                      addNewPatch();
                    }
                  }}
                  title={`Add${!!patches.length ? "/ Edit" : ""} Patches`}
                />
              </FlexBox>
            ) : (
              <FlexBox justify="space-between">
                <div>Update {resourceType} Kubernetes Resource Patches</div>
                <FormActionButtonCreate icon="plus" title="Add another patch" primary onClick={addNewPatch} />
              </FlexBox>
            )}
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <ErrorMessage active={!!error} message={error} />
              {patches.length > 0 ? (
                <>
                  {mode === Modes.EDIT && (
                    <div>
                      <Message>
                        <b>Note:</b> You can set the path as JSON key string with <code>.(dot)</code> or <code>/(slash) </code>depending on the field depth you
                        are trying to patch. Eg: <code>spec.containers[0].image</code> or <code>spec/containers[0]/image</code>. For applying patch to a nested
                        path whose parent does not exist, please use json object in value, example for path:
                        <code>/spec/dbservers/probes/startupProbeSpec</code>, value can be <code>{`{"initialDelaySeconds": 20}`}</code>.<br />
                        For more information check{" "}
                        <a
                          href="https://github.com/evanphx/json-patch#create-and-apply-a-json-patch"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-link"
                        >
                          JSON Patch Docs
                        </a>
                      </Message>
                      <Divider hidden />
                      <Form>
                        {patches.map((patch, index) => (
                          <Form.Group key={index} widths="equal">
                            <Form.Field
                              control={Select}
                              label="Operation"
                              value={patch.op}
                              onChange={(event: SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) => onValueChange(index, "op", data.value)}
                              options={[
                                {
                                  key: "add",
                                  text: "Add",
                                  value: "add",
                                },
                                {
                                  key: "replace",
                                  text: "Replace",
                                  value: "replace",
                                },
                                {
                                  key: "remove",
                                  text: "Remove",
                                  value: "remove",
                                },
                              ]}
                              placeholder="Eg: add/replace/remove"
                            />
                            <Form.Field
                              control={Input}
                              label="Path"
                              placeholder="Eg: spec.containers[0].image"
                              value={patch.path}
                              onChange={(event: SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) => onValueChange(index, "path", data.value)}
                            />
                            <Form.Field
                              control={Input}
                              label="Value"
                              placeholder="Eg: 1Gi"
                              value={patch.value}
                              onChange={(event: SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) => onValueChange(index, "value", data.value)}
                              disabled={patch.op === "remove"}
                            />
                            <Form.Field
                              control={Input}
                              label="Description"
                              placeholder="Reason for applying patch"
                              value={patch.description}
                              onChange={(event: SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) =>
                                onValueChange(index, "description", data.value)
                              }
                            />
                            <Button type="button" icon="close" size="mini" className="icon tiny" onClick={() => removePatch(index)} />
                          </Form.Group>
                        ))}
                      </Form>
                    </div>
                  )}
                  {mode === Modes.VIEW && (
                    <div>
                      <ReactJson src={patches} displayObjectSize={false} displayDataTypes={false} />
                    </div>
                  )}
                </>
              ) : (
                <Message>There are no patches applied {resourceType}. Please click on the Add Patches to start adding</Message>
              )}
            </Modal.Description>
          </Modal.Content>
          {mode === Modes.EDIT ? (
            <Modal.Actions>
              <FormActionButtonCancel onClick={onClose} title="Close" />
              <FormActionButtonSave icon="save" primary onClick={onSubmit} title="Save" disabled={loading} />
            </Modal.Actions>
          ) : (
            <Modal.Actions>
              <FormActionButtonCancel onClick={onClose} title="Close" />
            </Modal.Actions>
          )}
        </Modal>
      )}
    </>
  );
};
export default UpdateKubernetesResourcesPatchModal;
