//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Segment, Icon, Popup, Loader } from "semantic-ui-react";
import { FlexBox } from "../../ui/_flex";
import moment from "moment";
import { Timestamp } from "../../api/googleTypes";
import { ICommentContentViewArgs } from "./CommentTypes";
import UserCache, { UserWithDefinedID } from "../../util/UserCache";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

const StyledSegment = styled(Segment)`
  padding: 10px 25px !important;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
`;

const StyledComment = styled.span`
  width: 72%;
`;

const StyledLoader = styled(Loader)`
  &::before {
    border-color: #e9e9e9;
  }
  &::after {
    border-color: #577138 transparent transparent !important;
  }
`;

const CommentContentView = ({ comment }: ICommentContentViewArgs) => {
  const [user, setUser] = useState<UserWithDefinedID>();
  const [loading, setLoading] = useState(true);

  const getUser = async () => {
    const { created_by_id: userID = "" } = comment;
    const cache = new UserCache();
    setLoading(true);
    const user = await cache.getUser(userID);
    setLoading(false);
    setUser(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      {!!loading && (
        <>
          <StyledLoader active inline size="mini" />
          {"  "} Fetching user
        </>
      )}
      {!!user && (
        <>
          Created by <b>{user.name}</b>
        </>
      )}
    </div>
  );
};

export const isRecent = (createdAt: Timestamp | undefined) => {
  if (!createdAt) {
    return false;
  }
  const NEW_COMMENTS_DATE_LIMIT_IN_DAYS = 7;
  return moment().diff(moment(createdAt), "days") < NEW_COMMENTS_DATE_LIMIT_IN_DAYS;
};

const CommentItem = ({ comment }: ICommentContentViewArgs) => {
  const { comment: text = "", created_at: createdAt } = comment;
  const isCreatedNow = moment().diff(moment(createdAt), "seconds") < 20;
  const MAX_LENGTH = 200;

  return (
    <StyledSegment attached basic={isCreatedNow}>
      <FlexBox justify="space-between">
        {text.length > MAX_LENGTH ? (
          <Popup trigger={<StyledComment>{text.substring(0, MAX_LENGTH)}...</StyledComment>} content={text} on="hover" hoverable inverted />
        ) : (
          <StyledComment>{text}</StyledComment>
        )}
        <span>
          <>
            <DateTimePopupWithUTCAndLocalTime dateTime={comment.created_at} label="Created at" />
          </>{" "}
          <Popup trigger={<Icon name="info circle" />} position="top left" content={<CommentContentView comment={comment} />} />
        </span>
      </FlexBox>
    </StyledSegment>
  );
};

export default CommentItem;
