//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import _ from "lodash";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";

interface IServerTypeInputProps extends IWithRefreshProps, RouteComponentProps {
  id?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowAny?: boolean;
}

interface IServerTypeInputState {
  id: string;
}

const serverTypes = [
  { id: "agent", name: "Agent" },
  { id: "coordinator", name: "Coordinator" },
  { id: "dbserver", name: "DBServer" },
];

class ServerTypeInputComp extends Component<IServerTypeInputProps, IServerTypeInputState> {
  state: IServerTypeInputState = {
    id: this.props.id || "",
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  render() {
    let list = _.clone(serverTypes);
    if (this.props.allowAny) {
      list.push({ id: "", name: "Any" });
    }
    return (
      <div>
        <Dropdown
          fluid
          selection
          placeholder={this.props.placeholder}
          options={_.map(list, (x) => {
            return {
              key: x.id,
              text: x.name,
              value: x.id,
            };
          })}
          onChange={this.onChange}
          value={this.state.id}
        />
      </div>
    );
  }
}

export const ServerTypeInput = withRefresh()(ServerTypeInputComp);
