//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import { Form, Modal } from "semantic-ui-react";
import { ErrorMessage, Loading, FormActionButtonCancel, FormActionButtonSave } from "../../ui/lib";
import CommentInputField from "./CommentInputField";
import { ICommentPromptArgs } from "./CommentTypes";

const CommentsPrompt = ({ commentPrompt }: { commentPrompt: ICommentPromptArgs }) => {
  const { defaultCommentOnEvent, onClose, onCancellingCommentAddition, loadingMessage, onCommentConfirmation, handleAddComment, onError } = commentPrompt;

  const [loadingMessageValue, setLoadingMessageValue] = useState(loadingMessage);
  const [commentString, setComment] = useState(defaultCommentOnEvent);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  const handleCommentChange = (newComment: string) => {
    setComment(newComment);
  };

  const addCommentOnEvent = async () => {
    setCommentCreationFailed(false);
    setLoadingMessageValue("Add comments for the event");
    setError("");
    const { error: commentError } = await handleAddComment(commentString);
    if (commentError) {
      setError(commentError);
      setCommentCreationFailed(true);
      setLoading(false);
      return;
    }
    await setLoading(false);
    await setLoadingMessageValue("");
    await setComment("");
    onClose();
  };

  const handleCommentAddition = async () => {
    let allowCommentAddition = true;
    setError("");
    if (!!commentString.trim().length) {
      setLoading(true);
      try {
        await onCommentConfirmation();
      } catch (err) {
        allowCommentAddition = false;
        setLoading(false);
        const errorMessage = commentCreationFailed ? "Comment creation failed. You can retry saving the comment." : err.message;
        setError(errorMessage);
        onError && onError(err);
      } finally {
        if (allowCommentAddition) {
          addCommentOnEvent();
        }
      }
    }
  };

  const handlePromptClose = () => {
    onCancellingCommentAddition();
  };

  return (
    <Modal centered open>
      <Modal.Header>Please provide the reason for this change</Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} message={error} onDismiss={() => setError("")} />
        <Form>
          {loading ? (
            <Loading message={`${loadingMessageValue} `} />
          ) : (
            <Form.Field required>
              <label>Comments </label>

              <CommentInputField
                handleAddComment={handleCommentAddition}
                handleCommentChange={handleCommentChange}
                commentCreationInProcess={loading}
                comment={commentString}
                showOnlyInputField
                autoFocus
              />
              <sub>The default comment is provided for ease of usage and can be changed if required</sub>
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={handlePromptClose} />
        {commentCreationFailed ? (
          <FormActionButtonSave primary disabled={loading || !commentString} icon="comment" onClick={addCommentOnEvent} title="Retry saving comments" />
        ) : (
          <FormActionButtonSave
            primary
            disabled={loading || !commentString}
            icon={!!error ? "refresh" : "save"}
            onClick={handleCommentAddition}
            title={!!error ? "Retry" : "Save Change"}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default CommentsPrompt;
