//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import history from "../history";
import auth0 from "auth0-js";
import { Routes } from "../routes";

export default class Auth {
  private static StorageKeyGoTo = "Auth.goTo";
  private static StorageKeyIsLoggedIn = "Auth.isLoggedIn";
  private static StorageValueIsLoggedIn = "true";

  accessToken = "";
  idToken = "";
  expiresAt = 0;

  tokenRenewalTimeout: NodeJS.Timeout | undefined;
  renewingSession = false;

  auth0 = new auth0.WebAuth({
    domain: window.AUTH0_DOMAIN,
    clientID: window.AUTH0_CLIENT_ID,
    redirectUri: window.AUTH0_CALLBACK,
    responseType: "token id_token",
    scope: "openid profile",
    audience: window.AUTH0_API_IDENTIFIER || "test-api-identifier",
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
  }

  setRedirectToRoute(pathname: string) {
    localStorage.setItem(Auth.StorageKeyGoTo, pathname);
  }

  login() {
    const req = {
      mode: "login",
    } as auth0.AuthorizeOptions;
    this.auth0.authorize(req);
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace(Routes.home);
        console.log(err);
        //alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  getExpiryDate() {
    return new Date(this.expiresAt);
  }

  setSession(authResult: any) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem(Auth.StorageKeyIsLoggedIn, Auth.StorageValueIsLoggedIn);

    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    // Schedule a token renewal
    this.scheduleRenewal();

    // If a goto has been inserted previous web-session (before the callback was called by Auth0), use it once
    let goTo = localStorage.getItem(Auth.StorageKeyGoTo);
    if (goTo) {
      localStorage.removeItem(Auth.StorageKeyGoTo);
    } else {
      goTo = Routes.dashboard;
    }

    // Navigate to the goTo route
    history.replace(goTo);
  }

  renewSessionIfLoggedIn() {
    if (localStorage.getItem(Auth.StorageKeyIsLoggedIn) === Auth.StorageValueIsLoggedIn) {
      this.renewSession();
    }
  }

  renewSession() {
    this.renewingSession = true;
    this.auth0.checkSession({}, (err, authResult) => {
      this.renewingSession = false;
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        console.log(err);
        //alert(`Could not get a new token (${err.error}: ${(<any>err).error_description}).`);
      }
    });
  }

  scheduleRenewal() {
    let expiresAt = this.expiresAt;
    const timeout = (expiresAt - Date.now()) / 2;

    // Clear token renewal
    this.clearTokenRenewalTimeout();

    // Create new token renewal (if needed)
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
  }

  clearTokenRenewalTimeout() {
    // Clear token renewal (if already set)
    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
      this.tokenRenewalTimeout = undefined;
    }
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = "";
    this.idToken = "";
    this.expiresAt = 0;
    this.auth0.logout({
      returnTo: `${window.location.protocol}//${window.location.host}${Routes.home}`,
    });

    // Clear token renewal
    this.clearTokenRenewalTimeout();

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem(Auth.StorageKeyIsLoggedIn);

    // Navigate to the home route
    history.replace(Routes.home);
  }

  isAuthenticating() {
    return this.renewingSession;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }
}
