//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Input, Menu } from "semantic-ui-react";
import { ContentSegment, SecondaryMenu } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import EmailMessageList from "./EmailMessageList";

interface IFilterViewArgs {
  onToChanged: (to: string) => void;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown item text="Search">
        <Dropdown.Menu>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="To"
              name="search"
              onChange={(e, d) => args.onToChanged(d.value)}
              onClick={(e: any) => e.stopPropagation()}
            />
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};

interface IEmailMessagesProps extends IWithRefreshProps, RouteComponentProps {}

interface IEmailMessagesState {
  toFilter: string;
}

// The component to show any email message.
class EmailMessages extends Component<IEmailMessagesProps, IEmailMessagesState> {
  state: IEmailMessagesState = {
    toFilter: "",
  };

  onToChanged = (value: string) => {
    this.setState({
      toFilter: value,
    });
  };

  render() {
    return (
      <ContentSegment>
        <SecondaryMenu>
          <Menu.Item header>Email messages</Menu.Item>
        </SecondaryMenu>
        <FilterView {...this} />
        <EmailMessageList {...this.props} to={this.state.toFilter} inline />
      </ContentSegment>
    );
  }
}

export default withRefresh()(EmailMessages);
