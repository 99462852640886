//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, User as ApiUser, Member as ApiMember } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { LoaderBoxForTable as LoaderBox, ListActionView, TextLink } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

// Interface describing a member list
interface IHeaderView {
  loading: boolean;
}

export const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Email</Table.HeaderCell>
      <Table.HeaderCell>Owner</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Last login</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a member
interface IRowView {
  active: boolean;
  member: ApiMember;
  user?: ApiUser;
  onClickView: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const user = args.user || {};
  const [copied, setCopied] = React.useState(false);
  const onCopied = () => {
    setCopied(true);
    setInterval(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Table.Row>
      <Table.Cell>
        <TextLink label={user.name} href={Routes.dashboard_sales_user_detailsWithId(user.id as string)} />
      </Table.Cell>
      <Table.Cell>
        {user.email || ""}
        <CopyToClipboard text={user.email || ""} onCopy={onCopied}>
          <Button icon={copied ? "check" : "copy"} floated="right" size="tiny" basic />
        </CopyToClipboard>
      </Table.Cell>
      <Table.Cell>{args.member.owner ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{user.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={user.created_at} label="Created at" /> : ""}</Table.Cell>
      <Table.Cell>{user.last_login_at ? <DateTimePopupWithUTCAndLocalTime dateTime={user.last_login_at} label="Last Login at" /> : ""}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          <ListActionView disabled={!args.active} onClick={args.onClickView} />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface decribing the properties of the member row component
interface IOrganizationMemberRowProps extends IWithRefreshProps, RouteComponentProps {
  member: ApiMember;
  onMemberSelected: (userId: string) => void;
}

// Interface decribing the state of the member row component
interface IOrganizationMemberRowState {
  errorMessage?: string;
  processing: boolean;
  user?: ApiUser;
}

// The component to show a member of an organization as a row.
class OrganizationMemberRow extends Component<IOrganizationMemberRowProps, IOrganizationMemberRowState> {
  state: IOrganizationMemberRowState = {
    errorMessage: undefined,
    processing: false,
    user: undefined,
  };

  reloadUser = async () => {
    const options: ApiIDOptions = { id: this.props.member.user_id };
    const user = await apiClients.idashboardClient.GetUser(options);
    this.setState({ user: user });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUser);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <RowView
        {...this.props}
        {...this.state}
        active={!this.state.processing}
        onClickView={() => this.props.onMemberSelected(this.props.member.user_id || "")}
      />
    );
  }
}

export default withRefresh()(OrganizationMemberRow);
