//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Button, Icon, Input, Modal, Form } from "semantic-ui-react";
import { Deployment_DiskAutoSizeSettings as ApiDiskAutoSizeSettings } from "../../api/lib";
import { ErrorMessage } from "../../ui/_errorMessage";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";
import { AsyncResult } from "../../util/Types";

interface IDiskSizeEditModalArgs extends CommentCreationArgs {
  diskSize?: number;
  canIgnoreMax: boolean;
  onUpdateDiskSize: (newValue: number, ignoreMax: boolean) => Promise<AsyncResult<void>>;
}

const DiskSizeEditModal = ({ ...args }: IDiskSizeEditModalArgs) => {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [diskSize, setDiskSize] = useState(args.diskSize || 0);
  const [ignoreMax, setIgnoreMax] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  useEffect(() => {
    let newComment = "";
    if ((args.diskSize || 0) !== diskSize) {
      newComment = `Changed disk size to ${diskSize} GiB`;
    }
    setComment(newComment);
  }, [diskSize]);

  const onSave = async () => {
    setError("");
    setLoading(true);

    const { error } = (!commentCreationFailed && (await args.onUpdateDiskSize(diskSize, ignoreMax))) || {};

    setError(error);
    setCommentCreationFailed(false);

    if (!error) {
      const { error: commentError } = await args.createComment(comment);

      if (commentError) {
        setError(commentError);
        setLoading(false);
        setCommentCreationFailed(true);
        return;
      }

      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      trigger={<Icon name="pencil" className="edit-pencil" />}
      open={open}
      onClose={async () => {
        await setComment("");
        await setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      size="tiny"
    >
      <Modal.Header>Edit disk size</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={commentCreationFailed ? "Comment creation failed. You can retry saving the comment." : error} />
        <Form onSubmit={onSave}>
          <Form.Group>
            <Form.Field disabled={commentCreationFailed}>
              <label>Disk Size (GiB)</label>
              <Input type="number" min={0} value={diskSize} onChange={(e, d) => setDiskSize(parseInt(d.value))} />
            </Form.Field>
            <Form.Field disabled={commentCreationFailed}>
              <label>Ignore Max</label>
              <Form.Checkbox name="ignoreMax" disabled={!args.canIgnoreMax} checked={ignoreMax} onChange={(e, d) => setIgnoreMax(!!d.checked)} toggle />
            </Form.Field>
          </Form.Group>
          <Form.Field required>
            <label>Please provide the reason for this change</label>
            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>Disk size setting</b>. Otherwise, a default comment will be provided which can be
              changed if required.
            </sub>{" "}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Dismiss" labelPosition="right" icon="cancel" onClick={() => setOpen(false)} />
        {commentCreationFailed ? (
          <Button
            content="Retry saving comments"
            labelPosition="right"
            icon="comment"
            loading={loading}
            disabled={loading || !comment}
            positive
            onClick={onSave}
          />
        ) : (
          <Button content="Update" labelPosition="right" icon="checkmark" loading={loading} disabled={loading || !comment} positive onClick={onSave} />
        )}
      </Modal.Actions>
    </Modal>
  );
};

interface IDiskSizeViewArgs extends CommentCreationArgs {
  serverCount?: number;
  diskSize?: number;
  diskAutoSizeSettings?: ApiDiskAutoSizeSettings;
  canEditDiskSize: boolean;
  canEditDiskSizeIgnoreMax: boolean;
  onUpdateDiskSize: (newValue: number, ignoreMax: boolean) => Promise<AsyncResult<void>>;
}

export const DiskSizeView = ({ ...args }: IDiskSizeViewArgs) => {
  return (
    <div>
      {args.serverCount || "?"}
      <span> &times; </span>
      {args.diskSize || "?"}
      {!!args.diskAutoSizeSettings && <span> (Max {args.diskAutoSizeSettings && args.diskAutoSizeSettings.maximum_node_disk_size}) </span>}
      <span> GiB Disk</span>
      {args.canEditDiskSize && (
        <DiskSizeEditModal
          diskSize={args.diskSize}
          canIgnoreMax={args.canEditDiskSizeIgnoreMax}
          onUpdateDiskSize={args.onUpdateDiskSize}
          createComment={args.createComment}
        />
      )}
    </div>
  );
};
