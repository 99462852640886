//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Icon, Popup } from "semantic-ui-react";

export interface ISupportPlanViewArgs {
  planId?: string;
}

export const SupportPlanView = ({ ...args }: ISupportPlanViewArgs) => {
  switch (args.planId) {
    case "default":
      return <Popup trigger={<Icon name="star outline" />} content="Basic" />;
    case "silver":
      return <Popup trigger={<Icon name="star" color="brown" />} content="Standard" />;
    case "gold":
      return <Popup trigger={<Icon name="star" color="teal" />} content="Premium Standard" />;
    case "platinum":
      return <Popup trigger={<Icon name="star" color="yellow" />} content="Premium Enhanced" />;
    case "ultra":
      return <Popup trigger={<Icon name="star" color="purple" />} content="Premium Ultra" />;
    default:
      return <span>{args.planId}</span>;
  }
};
