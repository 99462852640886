//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Button, Form, Icon, InputOnChangeData, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { EmailPattern as ApiEmailPattern, IDOptions as ApiIDOptions } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Processing } from "../../ui/lib";
import _ from "lodash";

interface IEditEmailPatternViewArgs {
  visible: boolean;
  pattern: string;
  allow: boolean;
  deny: boolean;
  canSave: boolean;
  saving: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  onPatternChanged: (value: string) => void;
  onToggleAllow: () => void;
  onToggleDeny: () => void;
  onSave: () => void;
  onCancel: () => void;
}

const EditEmailPatternView = ({ ...args }: IEditEmailPatternViewArgs) => (
  <Modal open={args.visible}>
    <Modal.Header>Edit email pattern</Modal.Header>
    <Modal.Content>
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <Modal.Description>
        <Form>
          <Form.Field>
            <Form.Input
              name="pattern"
              placeholder="pattern"
              value={args.pattern}
              onChange={(e: any, data: InputOnChangeData) => args.onPatternChanged(data.value)}
              disabled={args.saving}
              autoFocus
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox name="allow" placeholder="allow" checked={args.allow} onClick={args.onToggleAllow} disabled={args.saving} label="Allow" />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox name="deny" placeholder="deny" checked={args.deny} onClick={args.onToggleDeny} disabled={args.saving} label="Deny" />
          </Form.Field>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={args.onCancel} disabled={args.saving}>
        <Icon name="remove" />
        Cancel
      </Button>
      <Button positive onClick={args.onSave} disabled={!args.canSave || args.saving}>
        <Icon name="checkmark" />
        Save
      </Button>
    </Modal.Actions>
  </Modal>
);

// Interface decribing the properties of the email pattern edit component
interface IEditEmailPatternProps extends IWithRefreshProps, RouteComponentProps {
  id: string;
  visible: boolean;
  onEmailPatternSaved: (id: string) => void;
  onClose: () => void;
}

// Interface decribing the state of the email pattern edit component
interface IEditEmailPatternState {
  item?: ApiEmailPattern;
  pattern: string;
  allow: boolean;
  deny: boolean;

  errorMessage?: string;
  processing: boolean;

  prev_id: string;
  refreshNeeded: boolean;
}

// The component to show email pattern edit modal
class EditEmailPattern extends Component<IEditEmailPatternProps, IEditEmailPatternState> {
  state: IEditEmailPatternState = {
    item: undefined,
    pattern: "",
    allow: true,
    deny: false,
    errorMessage: undefined,
    processing: false,
    refreshNeeded: false,
    prev_id: "",
  };

  reloadEmailPattern = async (id: string) => {
    if (id) {
      const req: ApiIDOptions = {
        id: id,
      };
      const pattern = await apiClients.idashboardClient.GetEmailPattern(req);
      this.setState({
        item: pattern,
        pattern: pattern.pattern || "",
        allow: pattern.allow || false,
        deny: pattern.deny || false,
      });
    } else {
      this.setState({
        item: undefined,
        pattern: "",
        allow: false,
        deny: false,
      });
    }
  };

  refreshEmailPattern = (id: string) => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadEmailPattern(id));
  };

  static getDerivedStateFromProps(props: IEditEmailPatternProps, state: IEditEmailPatternState) {
    if (props.id !== state.prev_id) {
      return {
        refreshNeeded: true,
        prev_id: props.id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshEmailPattern(this.props.id);
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => this.refreshEmailPattern(this.props.id));
    }
  }

  onSaveEmailPattern = async () => {
    this.setState({ processing: true });
    try {
      const item = this.state.item;
      if (item) {
        const req = _.clone(item);
        req.pattern = this.state.pattern;
        req.allow = this.state.allow;
        req.deny = this.state.deny;
        await apiClients.idashboardClient.UpdateEmailPattern(req);
        this.props.onEmailPatternSaved(req.id || "");
      }
    } catch (e) {
      this.setState({ errorMessage: `Email pattern saving failed: ${e}` });
    }
    this.setState({ processing: false });
  };

  onPatternChanged = (x: string) => {
    this.setState({ pattern: x });
  };

  onToggleAllow = () => {
    this.setState((prev: IEditEmailPatternState) => {
      return {
        allow: !prev.allow,
        deny: prev.allow,
      };
    });
  };

  onToggleDeny = () => {
    this.setState((prev: IEditEmailPatternState) => {
      return {
        deny: !prev.deny,
        allow: prev.deny,
      };
    });
  };

  isValid = (): boolean => {
    return this.state.item != undefined && this.state.pattern != "" && (this.state.allow || this.state.deny) && !(this.state.allow && this.state.deny);
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <Processing active={this.state.processing} message="Saving email pattern, please wait..." />

        <EditEmailPatternView
          {...this.props}
          {...this.state}
          canSave={this.isValid()}
          saving={this.state.processing}
          errorMessage={this.state.errorMessage}
          onSave={this.onSaveEmailPattern}
          onCancel={this.props.onClose}
          onPatternChanged={this.onPatternChanged}
          onToggleAllow={this.onToggleAllow}
          onToggleDeny={this.onToggleDeny}
          handleDismissError={this.handleDismissError}
        />
      </div>
    );
  }
}

export default withRefresh()(EditEmailPattern);
