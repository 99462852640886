//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Tier as ApiTier, TierList as ApiTierList, ListOptions as ApiListOptions } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, ContentSegment, SecondaryMenu, ErrorMessage, TextLink } from "../../ui/lib";
import { Routes } from "../../routes";

const HeaderView = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>ID</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiTier;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <TextLink label={args.item.name} href={Routes.dashboard_sales_tier_detailsWithId(args.item.id as string)} />
    </Table.Cell>
    <Table.Cell>{args.item.id}</Table.Cell>
  </Table.Row>
);

interface IListView {
  items: ApiTier[];
  loading: boolean;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} />
      ))}
    </Table.Body>
  </Table>
);

// Interface describing the user list view arguments
export interface IUserListViewArgs extends RouteComponentProps {
  loading: boolean;
  tiers?: ApiTierList;
}

export const TierListView = ({ ...args }: IUserListViewArgs) => {
  if (!args.tiers) {
    return <Loading />;
  }
  const tiers = args.tiers ? args.tiers.items : undefined;
  return (
    <div>
      <ListView {...args} items={tiers || []} />
    </div>
  );
};

// Interface decribing the properties of the tier list component
interface ITierListProps extends IWithRefreshProps, RouteComponentProps {
  onTierSelected: (tierId: string) => void;
}

// Interface decribing the state of the tier list component
interface ITierListState {
  tiers?: ApiTierList;
}

// The component to show all tiers in the platform.
class TierList extends Component<ITierListProps, ITierListState> {
  state: ITierListState = {
    tiers: undefined,
  };

  reloadTiers = async () => {
    const req: ApiListOptions = {};
    const tiers = await apiClients.idashboardClient.ListTiers(req);
    this.setState({ tiers: tiers });
  };

  refreshTiers = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTiers);
  };

  componentDidMount() {
    this.refreshTiers();
  }

  render() {
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Tiers</Menu.Item>
        </SecondaryMenu>
        <TierListView {...this.props} {...this.state} />
      </ContentSegment>
    );
  }
}

export default withRefresh()(TierList);
