//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { ErrorMessage, FlexBox, StyledInlineLoader } from "../../ui/lib";
import { AsyncResult } from "../../util/Types";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";

export interface IConfigurationPrometheusMemoryViewArgs extends CommentCreationArgs {
  canEditPrometheusMemory: boolean;
  onClickEditPrometheusMemory: () => void;
  editPrometheusMemory: boolean;
  customPrometheusMemory: string;
  onChangePrometheusMemory: (newValue: string) => Promise<AsyncResult<void>>;
  onClickCancelEditPrometheusMemory: () => void;
  processingUpdatePrometheusMemory: boolean;
}

export const ConfigurationPrometheusMemoryView = ({ ...args }: IConfigurationPrometheusMemoryViewArgs) => {
  const [prometheusMemory, setPrometheusMemory] = useState<string>(args.customPrometheusMemory || "");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);
  const [showModal, setShowModal] = useState(args.editPrometheusMemory);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePrometheusMemoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrometheusMemory(e.target.value);
  };

  useEffect(() => {
    if (args.customPrometheusMemory !== prometheusMemory) {
      setComment(`Update Prometheus memory to ${prometheusMemory || "<empty-value>"} `);
    } else {
      setComment("");
    }
  }, [prometheusMemory]);

  const savePrometheusMemoryChange = async () => {
    setLoading(true);
    setErrorMessage("");

    if (!commentCreationFailed) {
      let { error } = await args.onChangePrometheusMemory(prometheusMemory);
      if (error) {
        setErrorMessage(error);
        setLoading(false);
        return;
      }
    }

    setCommentCreationFailed(false);

    const { error: commentError } = await args.createComment(comment);

    if (commentError) {
      setCommentCreationFailed(true);
      setErrorMessage("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }

    setLoading(false);
    setShowModal(false);
  };

  return (
    <span>
      {args.customPrometheusMemory || "-"}
      {args.canEditPrometheusMemory && (
        <span>
          <Icon
            name="pencil"
            onClick={() => {
              args.onClickEditPrometheusMemory();
              setShowModal(true);
            }}
            className="edit-pencil"
          />
        </span>
      )}

      <Modal open={showModal} size="tiny">
        <Modal.Header>
          <FlexBox justify="space-between">
            <span>Edit Prometheus memory value</span>
            {loading && <StyledInlineLoader inline />}
          </FlexBox>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ErrorMessage active={!!commentCreationFailed || !!errorMessage} message={errorMessage} />
            <Form>
              <Form.Field>
                <label>Custom Prometheus Memory</label>
                <Form.Input value={prometheusMemory} onChange={handlePrometheusMemoryChange} disabled={commentCreationFailed} />
              </Form.Field>
              <Form.Field required>
                <label>Provide the reason for this change</label>
                <CommentInputField
                  handleAddComment={() => {}}
                  commentCreationInProcess={false}
                  handleCommentChange={(value: string) => {
                    setComment(value);
                  }}
                  comment={comment}
                  showOnlyInputField
                />
                <sub>
                  Comment box will be empty if there is no change in the <b>Prometheus memory</b>. Otherwise, a default comment will be provided which can be
                  changed if required.
                </sub>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              setComment("");
              setCommentCreationFailed(false);
              setErrorMessage("");
              setLoading(false);
              args.onClickCancelEditPrometheusMemory();
            }}
            negative
            content="Cancel"
          />
          {commentCreationFailed ? (
            <Button
              onClick={savePrometheusMemoryChange}
              positive
              labelPosition="right"
              icon="comment"
              content="Retry saving comment"
              loading={args.processingUpdatePrometheusMemory || loading}
              disabled={!comment.trim()}
            />
          ) : (
            <Button
              onClick={savePrometheusMemoryChange}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Save"
              loading={args.processingUpdatePrometheusMemory || loading}
              disabled={args.customPrometheusMemory === prometheusMemory || !comment.trim()}
            />
          )}
        </Modal.Actions>
      </Modal>
    </span>
  );
};
