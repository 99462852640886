//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import styled from "@emotion/styled";

import isEmpty from "lodash/isEmpty";

import { Button, Container, Form, Grid, Header, Modal, TextAreaProps } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { InternalContact, SetOrganizationInternalContactsRequest as ApiSetInternalContactsRequest } from "../../api/lib";
import { ErrorMessage, Field, Processing } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";

const StyledContainer = styled(Container)({
  margin: "2rem auto !important",
});

interface IInternalContactsFieldState extends IOrganizationDetailsFieldState {
  internalContacts: string;
  processing: boolean;
  isVisibleModal: boolean;
  isDisabledSubmit: boolean;
  comment: string;
  error: string;
  commentCreationFailure: boolean;
}
interface IInternalContactFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

class InternalContactsField extends OrganizationDetailsField<IInternalContactFieldProps, IInternalContactsFieldState> {
  state: IInternalContactsFieldState = {
    internalContacts: "",
    refreshNeeded: false,
    processing: false,
    isVisibleModal: false,
    isDisabledSubmit: true,
    comment: "",
    error: "",
    commentCreationFailure: false,
  };

  onSubmit = async () => {
    try {
      this.setState({ processing: true, commentCreationFailure: false });

      const req: ApiSetInternalContactsRequest = {
        organization_id: this.props.organization.id,
        internal_contacts: this.transformedInternalContacts(),
      };

      !this.state.commentCreationFailure && (await apiClients.idashboardClient.SetOrganizationInternalContacts(req));

      const { error: commentError } = await this.props.createComment(this.state.comment);

      if (commentError) {
        this.setState({ error: commentError, processing: false, commentCreationFailure: true });
        return;
      }
      this.refreshOrganizationInfo();
      this.setState({ isVisibleModal: false, comment: "", error: "", commentCreationFailure: false, processing: false });
    } catch (err) {
      this.setState({ ...this.state, error: `Changing organization deployment models failed: ${err.message}` });
    }
  };

  onInternalContactsInputChanged = (internalContacts: string) => {
    this.setState({ internalContacts, isDisabledSubmit: false });
  };

  transformedInternalContacts = (): InternalContact[] => {
    return this.state.internalContacts
      .split(",") // split internal contacts by comma <internalContactText>, <internalContactText>
      .map((contactText) => {
        const internalContact: InternalContact = {};
        if (isEmpty(contactText)) {
          return internalContact;
        }
        // split <email> --role=<role> by flag and remove empty space for each element
        const [email, role] = contactText.split("--role=").map((text) => text.trim());

        // ignores other (incorrect) emails
        if (this.validateEmail(email)) {
          internalContact.email = email;
        }

        // construct each InternalContact to have <email> and <role>
        if (internalContact.email && role) {
          internalContact.role = role;
        }

        return internalContact;
      })
      .filter((internalContact) => !isEmpty(internalContact)); // filter to remove empty internalContact
  };

  onInternalContactsList = (internalContacts: InternalContact[] | undefined) =>
    internalContacts &&
    internalContacts
      .map((contact) => {
        if (contact.role) {
          return contact.email + " --role=" + contact.role;
        }
        return contact.email;
      })
      .join(", ");

  onSetIsVisibleModal = (isVisible: boolean) => {
    this.setState({ isVisibleModal: isVisible, isDisabledSubmit: isVisible ? true : false, comment: "", error: "", commentCreationFailure: false });
  };

  validateEmail = (email: string): boolean => {
    const regexp = /\S+@\S+\.\S+/;
    return regexp.test(email);
  };

  render() {
    const canSetInternalContacts = this.hasPermission("internal-dashboard.organization.set-internal-contacts");
    return (
      <div>
        <Processing active={this.state.processing} message="Setting organization internal contacts, please wait..." />
        <StyledContainer>
          {canSetInternalContacts && (
            <div>
              <Header sub>Internal Contacts</Header>
              <Field>
                <Button content="Set Internal Emails" size="mini" basic icon="address card" onClick={() => this.onSetIsVisibleModal(true)} />
              </Field>
            </div>
          )}

          {this.state.isVisibleModal && (
            <Modal centered open onClose={() => this.onSetIsVisibleModal(false)}>
              <Modal.Header>Set Internal Contact email addresses</Modal.Header>
              <Modal.Content>
                <ErrorMessage
                  active={!!this.state.error}
                  message={this.state.error}
                  onDismiss={() => {
                    this.setState({ ...this.state, error: "" });
                  }}
                />

                <Form>
                  <Grid columns="1">
                    <Grid.Column>
                      <Form.Field>
                        <Form.TextArea
                          label="Internal Emails (separated by a comma) and optionally roles (specified by a flag --role=<role>):"
                          required
                          disabled={this.state.commentCreationFailure}
                          name="Internal Emails"
                          placeholder="Write the emails, separated by a comma:"
                          defaultValue={this.onInternalContactsList(this.props.organizationInfo.internal_contacts)}
                          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) =>
                            this.onInternalContactsInputChanged(data.value as string)
                          }
                          rows="5"
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>Provide the reason for this change</label>
                        <CommentInputField
                          handleAddComment={() => {}}
                          commentCreationInProcess={false}
                          handleCommentChange={(comment: string) => {
                            this.setState({ ...this.state, comment });
                          }}
                          comment={this.state.comment}
                          showOnlyInputField
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button content="Close" onClick={() => this.onSetIsVisibleModal(false)} />
                {this.state.commentCreationFailure ? (
                  <Button
                    disabled={this.state.isDisabledSubmit || !this.state.comment}
                    primary
                    icon="refresh"
                    onClick={() => this.onSubmit()}
                    content="Retry saving comment"
                    labelPosition="right"
                  />
                ) : (
                  <Button
                    disabled={this.state.isDisabledSubmit || !this.state.comment}
                    primary
                    icon="check"
                    onClick={() => this.onSubmit()}
                    content="Submit"
                    labelPosition="right"
                  />
                )}
              </Modal.Actions>
            </Modal>
          )}
        </StyledContainer>
      </div>
    );
  }
}

export default withRefresh()(InternalContactsField);
