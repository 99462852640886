//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState, forwardRef } from "react";
import { filter, isEmpty, isNil, isUndefined } from "lodash";
import { StyledTabMenuGroup, StyledTabContent } from "./TabStyles";
import { ITabLayoutProps } from "./TabTypes";
import TabMenuItem from "./TabMenuItem";
import TabMenuItemGroup from "./TabMenuItemGroup";
import TabMenuItemsWithIntersectionObserver from "./TabMenuWithIntersectionObserver";

const TabLayout = forwardRef(
  ({ tabConfig, activeIndex = 0, onTabChange, allowOverflow, tabClassForObserving, id, ...rest }: ITabLayoutProps, ref: React.Ref<HTMLDivElement> | null) => {
    if (!id) {
      throw new Error("ID is required since it will help optimise callbacks for IntersectionObserver");
    }

    if (!tabConfig) {
      throw new Error("No tab configuration is provided.");
    }

    if (tabConfig.length && activeIndex > tabConfig.length - 1) {
      console.warn("Index provided is out of bounds. Setting it to 0");
      activeIndex = 0;
    }

    const [currentIndex, setCurrentTab] = useState(activeIndex || 0);

    useEffect(() => {
      setCurrentTab(activeIndex);
    }, [activeIndex]);

    const handleTabChange = (index: number) => {
      setCurrentTab(index);
      onTabChange && onTabChange(index);
    };

    if (tabConfig && !tabConfig.length) {
      return <></>;
    }
    // This is to handle cases where the tabConfig might contain any null or undefined elements inside it.
    if (!isEmpty(filter(tabConfig, isNil)) || !isEmpty(filter(tabConfig, isUndefined))) {
      throw new Error("Tab configuration is incorrect. Cannot have null or undefined in the configuration.");
    }

    return (
      <div className="tabs-group" data-testid="tabView" ref={ref}>
        <StyledTabMenuGroup data-testid="tabMenuSection">
          {allowOverflow ? (
            <TabMenuItemsWithIntersectionObserver
              tabClassForObserving={tabClassForObserving}
              tabConfig={tabConfig}
              activeIndex={currentIndex}
              onTabClick={handleTabChange}
              id={id}
            />
          ) : (
            <TabMenuItemGroup tabConfig={tabConfig} activeIndex={currentIndex} onTabClick={handleTabChange} id={id} />
          )}
        </StyledTabMenuGroup>
        <StyledTabContent data-testid="tabContentSection">{tabConfig[currentIndex].content(rest)}</StyledTabContent>
      </div>
    );
  }
);

export default TabLayout;
export { TabMenuItem };
