//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Version as ApiVersion, VersionList as ApiVersionList, ListVersionsRequest as ApiListVersionsRequest } from "../../api/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { formatVersion } from "../../ui/lib";

interface ISelectVersionViewArgs extends ISelectVersionState {
  onSelectedVersionChanged: (version: ApiVersion) => void;
}

const SelectVersionView = ({ ...args }: ISelectVersionViewArgs) =>
  args.versions && args.versions.items ? (
    <Form.Dropdown
      fluid
      selection
      placeholder="Select Version"
      name="version"
      value={args.selectedVersion && args.selectedVersion.version}
      options={args.versions.items.map((v) => {
        return {
          key: v.version,
          text: formatVersion(v),
          value: v.version,
          onClick: () => {
            args.onSelectedVersionChanged(v);
          },
        };
      })}
    />
  ) : (
    <Form.Dropdown fluid selection placeholder="Select Version" name="version" loading={true} />
  );

// Interface decribing the properties of the SelectVersion component
interface ISelectVersionProps extends IWithRefreshProps, RouteComponentProps {
  organizationId: string;
  version?: ApiVersion;
  onVersionUpdated: (version?: ApiVersion) => void;
}

// Interface decribing the state of the SelectVersion component
interface ISelectVersionState {
  versions?: ApiVersionList;
  defaultVersion?: ApiVersion;
  selectedVersion?: ApiVersion;
}

// Component to select a version
class SelectVersion extends Component<ISelectVersionProps, ISelectVersionState> {
  state: ISelectVersionState = {
    versions: undefined,
    defaultVersion: undefined,
    selectedVersion: this.props.version,
  };

  onSelectedVersionChanged = (version: ApiVersion) => {
    this.setState({ selectedVersion: version });
  };

  reloadVersions = async () => {
    let listOptions: ApiListVersionsRequest = {
      organization_id: this.props.organizationId,
    };
    if (this.props.version) {
      listOptions.current_version = this.props.version.version;
    }
    const versions = await apiClients.authenticationOnly.dataClient.ListVersions(listOptions);
    const defaultVersion = await apiClients.authenticationOnly.dataClient.GetDefaultVersion();
    let selectedVersion = this.state.selectedVersion;
    const version = selectedVersion;
    if (version) {
      if (versions.items && !versions.items.find((v) => v.version == version.version)) {
        //not available anymore
        selectedVersion = undefined;
      }
    }
    if (!selectedVersion) {
      selectedVersion = defaultVersion;
    }
    this.setState({
      versions: versions,
      defaultVersion: defaultVersion,
      selectedVersion: selectedVersion,
    });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadVersions);
  }

  render() {
    // Disable Save button when no version has been selected or the version isn't different
    const disabled = !this.state.selectedVersion || !this.props.version || this.props.version.version == this.state.selectedVersion.version;
    return (
      <Form.Field>
        <SelectVersionView {...this.state} onSelectedVersionChanged={this.onSelectedVersionChanged} />
        <Form.Button
          onClick={() => {
            this.props.onVersionUpdated(this.state.selectedVersion);
          }}
          disabled={disabled}
          content="Save"
          size="small"
        />
      </Form.Field>
    );
  }
}

export default withRefresh()(SelectVersion);
