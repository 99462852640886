//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//
import moment from "moment";
import React from "react";
import { Checkbox, CheckboxProps, Dropdown, Form, Input, InputOnChangeData, Menu } from "semantic-ui-react";
import { PagingButtons } from "../../ui/lib";
import { DeploymentFilterViewArgs } from "./types";

const DeploymentUpdaterFilterView = ({
  onRunningStateChange,
  onPausedStateChange,
  onStoppedStateChange,
  onJobIDChange,
  onFinishedOnlyStateChange,
  onPendingStateChange,
  onCreatedBeforeChange,
  onCreatedAfterChange,
  onNextPage,
  onPreviousPage,
  page,
  pageSize,
  count,
  filters,
}: DeploymentFilterViewArgs) => {
  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown item text="Search">
        <Dropdown.Menu className="zi-top">
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Job ID"
              name="search"
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => event.stopPropagation()}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                const { value } = data;
                onJobIDChange(value as string);
              }}
            />
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item fitted className="padding-20-right">
        <Checkbox
          fitted
          toggle
          label="Running"
          onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
            const { checked } = data;
            onRunningStateChange(!!checked);
          }}
        />
      </Menu.Item>
      <Menu.Item fitted className="padding-20-right">
        <Checkbox
          fitted
          toggle
          label="Paused"
          onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
            const { checked } = data;
            onPausedStateChange(!!checked);
          }}
        />
      </Menu.Item>
      <Menu.Item fitted className="padding-20-right">
        <Checkbox
          fitted
          toggle
          label="Stopped"
          onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
            const { checked } = data;
            onStoppedStateChange(!!checked);
          }}
        />
      </Menu.Item>
      <Menu.Item fitted className="padding-20-right">
        <Checkbox
          fitted
          toggle
          label="Pending"
          onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
            const { checked } = data;
            onPendingStateChange(!!checked);
          }}
        />
      </Menu.Item>
      <Menu.Item fitted className="padding-20-right">
        <Checkbox
          fitted
          toggle
          label="Finished"
          onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
            const { checked } = data;
            onFinishedOnlyStateChange(!!checked);
          }}
        />
      </Menu.Item>
      <Menu.Item fitted className="padding-10">
        <Form.Field>
          <label>Created After</label>
          <div className="ui input">
            <input
              type="date"
              onChange={(event) => {
                const { target } = event;
                const { value = "" } = target;
                const udpatedDate = moment.utc(value, "YYYY-MM-DD");
                onCreatedAfterChange(udpatedDate.toDate());
              }}
              value={moment.utc(filters.created_after).format("YYYY-MM-DD")}
            />
          </div>
        </Form.Field>
      </Menu.Item>
      <Menu.Item fitted className="padding-10">
        <Form.Field>
          <label>Created Before</label>
          <div className="ui input">
            <input
              type="date"
              onChange={(event) => {
                const { target } = event;
                const { value = "" } = target;
                const udpatedDate = moment.utc(value, "YYYY-MM-DD");
                onCreatedBeforeChange(udpatedDate.toDate());
              }}
              value={moment.utc(filters.created_before).format("YYYY-MM-DD")}
            />
          </div>
        </Form.Field>
      </Menu.Item>

      <Menu.Item>
        <PagingButtons size="tiny" page={page} pageSize={pageSize} onNextPage={onNextPage} onPreviousPage={onPreviousPage} count={count} />
      </Menu.Item>
    </Menu>
  );
};

export default DeploymentUpdaterFilterView;
