//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { numberFormat } from "humanize";
import ReactJson from "react-json-view";
import { Modal, Header } from "semantic-ui-react";
import { MetricsInfo as ApiMetricsInfo, VolumeInfo as ApiVolumeInfo, DataCluster as ApiDataCluster, MetricsInfo_PrometheusFiringAlert } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FLW } from "../../ui/lib";
import { PrometheusAlertStatusView } from "./PrometheusAlertStatus";
import { PrometheusAlertSummaryView } from "./PrometheusAlertSummary";
import { DataVolumeView } from "./DataVolumeView";
import _ from "lodash";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { VolumeInfoView } from "../deployment/VolumeInfosViews";
import { CommentCreationArgs } from "../comments/CommentTypes";
import { ConfigurationDiskSizeView } from "../datacluster/DiskSizeView";
import { ConfigurationPrometheusMemoryView, IConfigurationPrometheusMemoryViewArgs } from "../datacluster/PrometheusMemoryView";

const isControlPlane = (dataClusterID?: string): boolean => dataClusterID === `_cp`;
export interface IMetricsInfoViewArgs extends CommentCreationArgs, IConfigurationPrometheusMemoryViewArgs {
  datacluster: ApiDataCluster;
  info?: ApiMetricsInfo;
  showDataclusterID?: boolean;
  showDeploymentID?: boolean;
  gotoUrl: (url: string) => void;

  canEditVolumeInfos: boolean;
  onSavePrometheusVolumeInfo: (newPrometheusVolumeInfo?: ApiVolumeInfo) => Promise<{ errorMessage: string }>;

  onEditPrometheusVolumeInfos: () => void;
  onCancelEditPrometheusVolumeInfos: () => void;
  editPrometheusVolumeInfos: boolean;

  processingUpdateVolumeInfos: boolean;

  canEditDiskSize: boolean;

  onSavePrometheusDiskSize: (newValue: string) => Promise<{ errorMessage: string }>;
  onEditPrometheusDiskSize: () => void;
  onCancelEditPrometheusDiskSize: () => void;
  editPrometheusDiskSize: boolean;

  processingUpdateDiskSize: boolean;
}

const severityToColor = (by_severity: _.Dictionary<MetricsInfo_PrometheusFiringAlert[]>) => {
  if (_.isEmpty(by_severity)) {
    return "";
  }
  if (by_severity["critical"]) {
    return "red";
  }
  if (by_severity["error"]) {
    return "purple";
  }
  if (by_severity["warning"]) {
    return "orange";
  }
  return "blue";
};
export const MetricsInfoView = ({ ...args }: IMetricsInfoViewArgs) => {
  const info = args.info || {};
  const prometheus_alert_status = info.prometheus_alert_status || {};
  const firing_info = prometheus_alert_status.firing_info || [];
  const by_severity = _.groupBy(firing_info, "severity");
  const firing_color = severityToColor(by_severity);
  const has_prometheus_scrape_targets = !!info.prometheus_scrape_targets;
  const prometheus_scrape_targets = info.prometheus_scrape_targets || {};
  const targets_down_color = (prometheus_scrape_targets.active_down || 0) > 0 ? "red" : "";
  const onSavePrometheusVolumeInfo = (newVolumeType: string, newVolumeIOPS: number, newVolumeThroughput: number) => {
    const newPrometheusVolumeInfo: ApiVolumeInfo = {
      type: newVolumeType,
      iops: newVolumeIOPS,
      throughput: newVolumeThroughput,
    };
    return args.onSavePrometheusVolumeInfo(newPrometheusVolumeInfo);
  };
  const prometheusVolumeInfo = args.datacluster.prometheus_volume_info || {};
  const prometheusDiskSize = args.datacluster.prometheus_disk_size || "";

  return (
    <div>
      <Header sub>Prometheus</Header>
      <Field>
        <FLW>Scrape targets UP</FLW>
        <FC>{has_prometheus_scrape_targets ? prometheus_scrape_targets.active_up || 0 : "-"}</FC>
      </Field>
      <Field>
        <FLW>Scrape targets DOWN</FLW>
        <FC>{}</FC>
        <FC>
          <span style={{ color: targets_down_color }}>{has_prometheus_scrape_targets ? prometheus_scrape_targets.active_down || 0 : "-"}</span>
        </FC>
      </Field>
      <Field>
        <FLW>Recording rules defined</FLW>
        <FC>{info.prometheus_rules_status ? info.prometheus_rules_status.type_recording || 0 : "-"}</FC>
      </Field>
      <Field>
        <FLW>First sample</FLW>
        <FC>{info.first_sample_at ? <DateTimePopupWithUTCAndLocalTime dateTime={info.first_sample_at} label="First sample at" /> : "-"}</FC>
      </Field>
      <Field>
        <FLW>Last sample</FLW>
        <FC>{info.last_sample_at ? <DateTimePopupWithUTCAndLocalTime dateTime={info.last_sample_at} label="Last sample at" /> : "-"}</FC>
      </Field>
      <Field>
        <FLW>Total samples (since restart)</FLW>
        <FC>{info.total_number_of_samples ? numberFormat(info.total_number_of_samples || 0, 0) : "-"}</FC>
      </Field>
      {!isControlPlane(args.datacluster.id) && (
        <Field>
          <FLW>Custom Prometheus memory</FLW>
          <FC>
            <ConfigurationPrometheusMemoryView {...args} />
          </FC>
        </Field>
      )}
      <Field>
        <FLW>Data Volume</FLW>
        <FC>
          <DataVolumeView data_volume_info={info.prometheus_data_volume_info} />
        </FC>
      </Field>
      <Field>
        <FLW>Volume Size</FLW>
        <FC>
          <ConfigurationDiskSizeView
            title="Prometheus"
            currentDiskSize={prometheusDiskSize}
            canEdit={args.canEditDiskSize}
            onEdit={args.onEditPrometheusDiskSize}
            onCancelEdit={args.onCancelEditPrometheusDiskSize}
            onSaveEdit={args.onSavePrometheusDiskSize}
            editing={args.editPrometheusDiskSize}
            processingUpdate={args.processingUpdateDiskSize}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      <Field>
        <FLW>Volume Params</FLW>
        <FC>
          <VolumeInfoView
            title="Prometheus"
            volumeType={prometheusVolumeInfo.type}
            volumeIOPS={prometheusVolumeInfo.iops}
            volumeThroughput={prometheusVolumeInfo.throughput}
            canEdit={args.canEditVolumeInfos}
            onEdit={args.onEditPrometheusVolumeInfos}
            editing={args.editPrometheusVolumeInfos}
            onCancelEdit={args.onCancelEditPrometheusVolumeInfos}
            onSaveEdit={onSavePrometheusVolumeInfo}
            processingUpdate={args.processingUpdateVolumeInfos}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      <Header sub>Alert Status</Header>
      <Field>
        <FLW>Active Alertmanagers</FLW>
        <FC>{info.alertmanagers_active || 0}</FC>
      </Field>
      <Field>
        <FLW>Alerting rules defined</FLW>
        <FC>{info.prometheus_rules_status ? info.prometheus_rules_status.type_alerting || 0 : "-"}</FC>
      </Field>
      <Field>
        <FLW>Pending alerts</FLW>
        <FC>{info.prometheus_alert_status ? info.prometheus_alert_status.pending || 0 : "-"}</FC>
      </Field>
      <Field>
        <FLW>
          <Modal
            trigger={
              <span style={{ color: firing_color }}>
                <u>Firing alerts</u>
              </span>
            }
            centered
            size="fullscreen"
          >
            <Modal.Header>Firing alerts</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                <PrometheusAlertStatusView {...args} status={info.prometheus_alert_status} />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </FLW>
        <FC>
          <Modal
            size="large"
            trigger={
              <span style={{ color: firing_color }}>
                <u>
                  <PrometheusAlertSummaryView {...args} status={prometheus_alert_status} />
                </u>
              </span>
            }
          >
            <Modal.Header>Alert details</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                <ReactJson src={info.prometheus_alert_status || {}} collapsed={1} />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </FC>
      </Field>
    </div>
  );
};
