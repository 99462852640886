//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { Dropdown, DropdownProps, Input, InputOnChangeData, Menu } from "semantic-ui-react";

type UsageItemFilterViewArgs = {
  onDatesChange: (args: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => void;
  onKindChange: (kind: string) => void;
  onDeploymentIDChange: (deploymentID: string) => void;
  onInvoiceIDChange: (invoiceID: string) => void;
  selectedKind: string;
  deploymentID: string;
  invoiceID: string;
  fromDate: Date;
  toDate: Date;
};

const UsageItemFilterView = ({
  onDatesChange,
  onKindChange,
  onDeploymentIDChange,
  onInvoiceIDChange,
  selectedKind,
  deploymentID,
  fromDate,
  toDate,
  invoiceID,
}: UsageItemFilterViewArgs) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  const onDatesChanged = (args: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    if (args.startDate && args.endDate) {
      onDatesChange(args);
    }
  };

  const handleDeploymentIdChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value = "" } = data;
    onDeploymentIDChange(value);
  };

  const handleInvoiceIDChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value = "" } = data;
    onInvoiceIDChange(value);
  };

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown item text="Search">
        <Dropdown.Menu>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Deployment ID"
              name="search"
              value={deploymentID}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => event.stopPropagation()}
              onChange={handleDeploymentIdChange}
            />
          </Menu.Item>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Invoice ID"
              name="search"
              value={invoiceID}
              onClick={(event: any) => event.stopPropagation()}
              onChange={handleInvoiceIDChange}
            />
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item>
        <Menu.Item>Usage : </Menu.Item>
        <DateRangePicker
          noBorder
          small
          startDate={moment(fromDate)}
          startDateId="usageItemListStartDate"
          endDate={moment(toDate)}
          endDateId="usageItemListEndDate"
          onDatesChange={onDatesChanged}
          isOutsideRange={() => false}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        />
      </Menu.Item>
      <Dropdown
        item
        text={`Kind - ${selectedKind}`}
        value={selectedKind}
        options={[
          { key: "all", value: "all", text: "All" },
          { key: "deploymentSize", value: "DeploymentSize", text: "Deployment Size" },
          { key: "networkTransferSize", value: "NetworkTransferSize", text: "Network Transfer Size" },
          { key: "backupStorageSize", value: "BackupStorageSize", text: "Backup Storage Size" },
          { key: "auditLogSize", value: "AuditLogSize", text: "Audit Log Size" },
          { key: "auditLogStorageSize", value: "AuditLogStorageSize", text: "AuditLog Storage Size" },
          { key: "mlServicesSize", value: "MLServicesSize", text: "MLServices Size" },
          { key: "notebookSize", value: "NotebookSize", text: "Notebook Size" },
        ]}
        onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
          const { value = "" } = data;
          onKindChange(value as string);
        }}
      />
    </Menu>
  );
};

export default UsageItemFilterView;
