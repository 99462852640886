//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import { IAMService } from "./iam/v1/iam";
import { PlatformService } from "./platform/v1/platform";
import { ResourceManagerService } from "./resourcemanager/v1/resourcemanager";
import { InternalDashboardService } from "./dashboard/v1/idashboard";
import { SupportService } from "./support/v1/support";
import moize from "moize";
import { DataService } from "./data/v1/data";

const clients = {
  idashboardClient: new InternalDashboardService(),
  authenticationOnly: {
    // Only use calls of these clients that require authentication only.
    // If a call requires 1 or more permissions, use the InternalDashboardService.
    dataClient: new DataService(),
    iamClient: new IAMService(),
    platformClient: new PlatformService(),
    resourceManagerClient: new ResourceManagerService(),
    supportClient: new SupportService(),
  },
};

const stableCacheOpts = {
  isPromise: true,
  maxAge: 120000, // 2min
  isDeepEqual: true,
};

export const Cached = {
  authenticationOnly: {
    dataClient: {
      ListVersions: moize(clients.authenticationOnly.dataClient.ListVersions, stableCacheOpts),
      ListCPUSizes: moize(clients.authenticationOnly.dataClient.ListCPUSizes, stableCacheOpts),
    },
    platformClient: {
      GetProvider: moize(clients.authenticationOnly.platformClient.GetProvider, stableCacheOpts),
      GetRegion: moize(clients.authenticationOnly.platformClient.GetRegion, stableCacheOpts),
      ListProviders: moize(clients.authenticationOnly.platformClient.ListProviders, stableCacheOpts),
      ListRegions: moize(clients.authenticationOnly.platformClient.ListRegions, stableCacheOpts),
    },
  },
  idashboardClient: {
    GetDefaultSystemCurrency: moize(clients.idashboardClient.GetDefaultSystemCurrency, stableCacheOpts),
    GetCurrency: moize(clients.idashboardClient.GetCurrency, stableCacheOpts),
    ListCurrencies: moize(clients.idashboardClient.ListCurrencies, stableCacheOpts),
    ListAllDeploymentModels: moize(clients.idashboardClient.ListAllDeploymentModels, stableCacheOpts),
    ListAllNodeSizes: moize(clients.idashboardClient.ListAllNodeSizes, stableCacheOpts),
    ListAllVersions: moize(clients.idashboardClient.ListAllVersions, stableCacheOpts),
    ListSupportPlans: moize(clients.idashboardClient.ListSupportPlans, stableCacheOpts),
    ListAllDiskPerformances: moize(clients.idashboardClient.ListAllDiskPerformances, stableCacheOpts),
  },
};

export default clients;
