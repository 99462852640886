//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Icon, Input, InputOnChangeData, Loader, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Plan as ApiPlan, PlanList as ApiPlanList, ListPlansRequest as ApiListPlansRequest } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, LoaderBoxForTable as LoaderBox, ContentSegment, SecondaryMenu, PagingButtons, ErrorMessage, ListActionView, TextLink } from "../../ui/lib";

import CreatePricingPlan from "./CreatePricingPlan";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  onNameChanged: (name: string) => void;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>
        <Input
          label="Name"
          icon="search"
          size="mini"
          placeholder="Search ..."
          onChange={(event: React.SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) => {
            args.onNameChanged(data.value);
          }}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Lock state</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing an organization
interface IRowView {
  item: ApiPlan;
  onClickView: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <TextLink label={args.item.name || args.item.id} href={Routes.dashboard_sales_pricingPlan_detailsWithId(args.item.id as string)} />
    </Table.Cell>
    <Table.Cell>{args.item.description}</Table.Cell>
    <Table.Cell>
      <Icon name={args.item.locked ? "lock" : "lock open"} />
    </Table.Cell>
    <Table.Cell>
      <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
    </Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        <ListActionView onClick={args.onClickView} />
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the organization list
interface IListView {
  items: ApiPlan[];
  loading: boolean;
  onNameChanged: (name: string) => void;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickView: (planId: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} onClickView={() => args.onClickView(item.id || "")} />
      ))}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more pricing plans" : "No pricing plans selected"}</div>;

// Interface describing the pricing plan list view arguments
export interface IPricingPlanListViewArgs extends RouteComponentProps {
  loading: boolean;
  plans?: ApiPlanList;
  onNameChanged: (name: string) => void;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickView: (planId: string) => void;
}

export const PricingPlanListView = ({ ...args }: IPricingPlanListViewArgs) => {
  if (!args.plans) {
    return <Loading />;
  }
  const isEmpty = !args.plans.items || args.plans.items.length === 0;
  const plans = args.plans ? args.plans.items : undefined;
  return (
    <div>
      <ListView {...args} items={plans || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the pricing plan list component
interface IPricingPlanListProps extends IWithRefreshProps, RouteComponentProps {
  onViewPlan: (planID: string) => void;
}

// Interface decribing the state of the pricing plan list component
interface IPricingPlanListState {
  plans?: ApiPlanList;
  nameFilter: string;
  page: number;
  pageSize: number;
  createVisible: boolean;
}

// The component to show pricing plans as a list.
class PricingPlanList extends Component<IPricingPlanListProps, IPricingPlanListState> {
  state: IPricingPlanListState = {
    plans: undefined,
    nameFilter: "",
    page: 0,
    pageSize: 10,
    createVisible: false,
  };

  reloadPlans = async () => {
    var req: ApiListPlansRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    const plans = await apiClients.idashboardClient.ListPlans(req);
    this.setState({ plans: plans });
  };

  refreshPlans = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPlans);
  };

  componentDidMount() {
    this.refreshPlans();
  }

  onNameFilterChanged = (name: string) => {
    this.setState(
      {
        nameFilter: name,
        page: 0,
      },
      this.refreshPlans
    );
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshPlans
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshPlans
    );
  };

  onPlanCreated = (planId: string) => {
    this.setState({ createVisible: false });
    this.refreshPlans();
  };

  render() {
    const plans = this.state.plans;
    const page = this.state.page;

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Pricing plans</Menu.Item>
          <CreatePricingPlan
            {...this.props}
            visible={this.state.createVisible}
            onPlanCreated={this.onPlanCreated}
            onClose={() => {
              this.setState({ createVisible: false });
            }}
          />
          <Button icon="plus" size="tiny" basic onClick={() => this.setState({ createVisible: true })} />
        </SecondaryMenu>
        <PricingPlanListView
          plans={plans}
          onNameChanged={this.onNameFilterChanged}
          onNextPage={this.onNextPage}
          onPreviousPage={page > 0 ? this.onPreviousPage : undefined}
          page={page}
          pageSize={this.state.pageSize}
          onClickView={this.props.onViewPlan}
          {...this.props}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(PricingPlanList);
