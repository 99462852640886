//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Button, Loader, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, TermsAndConditions as ApiTermsAndConditions } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, LoaderBox, MenuActionBack, SecondaryMenu, Section, SectionContent, Processing, ErrorMessage } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { EditorView } from "./TermsAndConditionsEditor";

interface IUpdateTermsAndConditionsProps extends IWithRefreshProps, RouteComponentProps {
  termsAndConditionsId: string;
  onSaved: () => void;
  onCancel: () => void;
}

interface IUpdateTermsAndConditionsState {
  termsAndConditions?: ApiTermsAndConditions;
  saving: boolean;
  errorMessage?: string;
}

// The component to update T&C
class UpdateTermsAndConditions extends Component<IUpdateTermsAndConditionsProps, IUpdateTermsAndConditionsState> {
  state: IUpdateTermsAndConditionsState = {
    termsAndConditions: undefined,
    saving: false,
    errorMessage: undefined,
  };

  hasPermission = (p: Permission) => hasSupportPermission(p, this.props.hasPermissionByUrl);

  reloadTermsAndConditions = async () => {
    const idOptions: ApiIDOptions = { id: this.props.termsAndConditionsId };
    var termsAndConditions = await apiClients.idashboardClient.GetTermsAndConditions(idOptions);
    this.setState({
      termsAndConditions: termsAndConditions,
    });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadTermsAndConditions);
  }

  onContentChange = (content: string) => {
    this.setState({
      termsAndConditions: {
        content: content,
      },
    });
  };

  onSave = async () => {
    if (this.state.termsAndConditions) {
      try {
        this.setState({ saving: true, errorMessage: undefined });
        const req: ApiTermsAndConditions = {
          id: this.props.termsAndConditionsId || "",
          content: this.state.termsAndConditions.content || "",
        };
        await apiClients.idashboardClient.UpdateTermsAndConditions(req);
        this.props.onSaved();
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ saving: false });
      }
    }
  };

  render() {
    const has_termsAndConditions = !!this.state.termsAndConditions;
    const termsAndConditions = this.state.termsAndConditions || {};

    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Edit Terms &amp; Conditions</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={!has_termsAndConditions} inline />
          </LoaderBox>
          <Processing active={this.state.saving} message="Updating Terms &amp; Conditions..." />
          <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        </SecondaryMenu>
        {has_termsAndConditions && (
          <div>
            <Section>
              <SectionContent>
                <EditorView {...this.props} {...this.state} termsAndConditions={termsAndConditions} onContentChange={this.onContentChange} />
                <Button icon="check" content="Save" primary onClick={this.onSave} />
                <Button content="Cancel" onClick={this.props.onCancel} />
              </SectionContent>
            </Section>
          </div>
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(UpdateTermsAndConditions);
