//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import _ from "lodash";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";
import { Usage } from "../api/lib";

interface INetworkTransferDestinationInputProps extends IWithRefreshProps, RouteComponentProps {
  id?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowAny?: boolean;
}

interface INetworkTransferDestinationInputState {
  id: string;
}

const destinations = [
  { id: Usage.network_transfer_destination_internet, name: "Internet" },
  { id: Usage.network_transfer_destination_in_cluster, name: "In Cluster" },
  { id: Usage.network_transfer_destination_private_endpoint, name: "Private Endpoint" },
];

class NetworkTransferDestinationInputComp extends Component<INetworkTransferDestinationInputProps, INetworkTransferDestinationInputState> {
  state: INetworkTransferDestinationInputState = {
    id: this.props.id || "",
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  render() {
    let list = _.clone(destinations);
    if (this.props.allowAny) {
      list.push({ id: "", name: "Any" });
    }
    return (
      <div>
        <Dropdown
          fluid
          selection
          placeholder={this.props.placeholder}
          options={_.map(list, (x) => {
            return {
              key: x.id,
              text: x.name,
              value: x.id,
            };
          })}
          onChange={this.onChange}
          value={this.state.id}
        />
      </div>
    );
  }
}

export const NetworkTransferDestinationInput = withRefresh()(NetworkTransferDestinationInputComp);
