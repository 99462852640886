//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
// Author Tomasz Mielech
//

import React from "react";
import _ from "lodash";
import { Message, MessageProps } from "semantic-ui-react";
import styled from "@emotion/styled";

interface IErrorMessageViewArgs {
  active: boolean;
  onDismiss?: (event: React.MouseEvent<HTMLElement>, data: MessageProps) => void;
  message?: string;
  header?: string;
}

const StickyMessage = styled(Message)`
  position: sticky !important;
  top: 0 !important;
  z-index: 9999;
  background: #fff5f2 !important;
`;

export const ErrorMessage = ({ ...args }: IErrorMessageViewArgs) => {
  if (!args.active) return null;

  const message = _.isEmpty(args.message) ? "Unknown error" : _.toString(args.message);
  const header = args.header || "An error occurred! - please retry";

  return <StickyMessage error onDismiss={args.onDismiss} header={header} content={message} />;
};
