//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import apiclients from "../api/apiclients";
import { Icon, Popup } from "semantic-ui-react";
import { IDOptions as ApiIDOptions, Organization as ApiOrganization } from "../api/lib";
import Cache from "./Cache";
import Callbacks from "./Callbacks";
import { TextLink } from "../ui/_textlink";
import { Routes } from "../routes";

interface IOrganizationFieldProps {
  organizationId?: string;
  cache: OrganizationCache;
  onClick?: () => void;
}

interface IOrganizationFieldState {
  organization?: ApiOrganization;
}

export class OrganizationField extends Component<IOrganizationFieldProps, IOrganizationFieldState> {
  state: IOrganizationFieldState = {};

  refreshOrganization = async () => {
    if (this.props.organizationId) {
      try {
        const organization = await this.props.cache.getOrganizationById(this.props.organizationId);
        this.setState({ organization: organization });
      } catch (e) {
        this.setState({ organization: undefined });
      }
    } else {
      this.setState({ organization: undefined });
    }
  };

  componentDidMount() {
    this.props.cache.addCallback(this.refreshOrganization);
    this.refreshOrganization();
  }

  componentWillUnmount() {
    this.props.cache.removeCallback(this.refreshOrganization);
  }

  componentDidUpdate(prevProps: IOrganizationFieldProps) {
    if (prevProps.organizationId != this.props.organizationId) {
      this.refreshOrganization();
    }
  }

  getFieldValue = (organization?: ApiOrganization): string | undefined => {
    return organization ? organization.id : undefined;
  };

  onClick = () => {
    this.props.onClick && this.props.onClick();
  };

  getClassName = (): string => {
    return this.props.onClick ? "text-link" : "";
  };

  render() {
    return (
      <TextLink
        className={this.getClassName()}
        onClick={this.onClick}
        href={Routes.dashboard_sales_organization_detailsWithId(this.props.organizationId || "")}
      >
        {this.getFieldValue(this.state.organization)}
      </TextLink>
    );
  }
}

export class OrganizationName extends OrganizationField {
  getFieldValue = (organization?: ApiOrganization): string | undefined => {
    return organization ? organization.name : undefined;
  };
}

export class OrganizationTierName extends OrganizationField {
  getFieldValue = (organization?: ApiOrganization): string | undefined => {
    return organization ? (organization.tier || {}).name : undefined;
  };
}

interface ITierViewArgs {
  tierId?: string;
}

const TierView = ({ ...args }: ITierViewArgs) => {
  switch (args.tierId) {
    case "free":
      return <Popup trigger={<Icon name="lemon outline" />} content="Free to try" />;
    case "professional":
      return <Popup trigger={<Icon name="credit card outline" />} content="Professional" />;
    case "enterprise":
      return <Popup trigger={<Icon name="building outline" />} content="Enterprise" />;
    case "internal":
      return <Popup trigger={<Icon name="id badge outline" color="green" />} content="Internal" />;
    default:
      return <span>{args.tierId}</span>;
  }
};

export class OrganizationTierIcon extends OrganizationField {
  getFieldValue = (organization?: ApiOrganization): string | undefined => {
    return organization ? (organization.tier || {}).id : undefined;
  };

  render() {
    return (
      <span className={this.getClassName()} onClick={this.onClick}>
        <TierView tierId={this.getFieldValue(this.state.organization)} />
      </span>
    );
  }
}

export default class OrganizationCache extends Callbacks {
  private cache: Cache<ApiOrganization>;

  constructor() {
    super();
    this.cache = new Cache<ApiOrganization>();
  }

  // getOrganizationById returns an organization with given ID from cache or loads it first.
  getOrganizationById = async (id: string): Promise<ApiOrganization | undefined> => {
    const cached = this.cache.get(id);
    if (cached) {
      return cached;
    }
    if (this.cache.has(id)) {
      return undefined;
    }
    this.cache.set(id, undefined);
    const req: ApiIDOptions = {
      id: id,
    };
    const organization = await apiclients.idashboardClient.GetOrganization(req);
    this.cache.set(id, organization);
    this.invokeCallbacks();
    return organization;
  };
}
