//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Nikhil Varma
//

import React from "react";
import { Header } from "semantic-ui-react";
import { Deployment_NotificationSettings as ApiDeploymentNotificationSettings } from "../../api/lib";

interface iNotificationSettings {
  settings: ApiDeploymentNotificationSettings;
}

const NotificationSettingsView = ({ settings }: iNotificationSettings) => {
  const { email_addresses: emailAddresses = [] } = settings;

  return (
    <>
      <Header sub>Notification Email addresses</Header>
      {!emailAddresses.length ? (
        <Header disabled as="h5">
          No email addresses configured
        </Header>
      ) : (
        emailAddresses.map((emailAddress: string, index: number) => {
          return <div key={index}>{emailAddress}</div>;
        })
      )}
    </>
  );
};

export default NotificationSettingsView;
