//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import { Loader, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ListExampleDatasetInstallationsRequest as ApiListExampleDatasetInstallationsRequest,
  ExampleDatasetInstallation as ApiExampleDatasetInstallation,
  ExampleDatasetInstallationList as ApiExampleDatasetInstallationList,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, LoaderBoxForTable as LoaderBox, ContentSegment, SecondaryMenu, PagingButtons, ErrorMessage } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Example</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Database</Table.HeaderCell>
      <Table.HeaderCell>Created at</Table.HeaderCell>
      <Table.HeaderCell>
        Deleted at
        <PagingButtons {...args} size="small" />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiExampleDatasetInstallation;
}

const RowView = ({ ...args }: IRowView) => {
  const status = args.item.status || {};
  return (
    <Table.Row>
      <Table.Cell>{args.item.exampledataset_id || ""}</Table.Cell>
      <Table.Cell>{status.state || ""}</Table.Cell>
      <Table.Cell>{status.database_name || ""}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>{args.item.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.deleted_at} label="Deleted at" /> : ""}</Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  items: ApiExampleDatasetInstallation[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} />
      ))}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more example installations" : "No example installations found"}</div>;

export interface IExampleInstallationsListViewArgs extends RouteComponentProps {
  loading: boolean;
  installations?: ApiExampleDatasetInstallationList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

export const ExampleInstallationsListView = ({ ...args }: IExampleInstallationsListViewArgs) => {
  if (!args.installations) {
    return <Loading />;
  }
  const isEmpty = !args.installations.items || args.installations.items.length === 0;
  const patterns = args.installations ? args.installations.items : undefined;
  return (
    <div>
      <ListView {...args} items={patterns || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

interface IExampleInstallationListProps extends IWithRefreshProps, RouteComponentProps {
  deployment_id: string;
}

// Interface decribing the state of the example installation list component
interface IExampleInstallationListState {
  installations?: ApiExampleDatasetInstallationList;
  page: number;
  pageSize: number;
  errorMessage?: string;
  deployment_id: string;
  refreshNeeded: boolean;
}

// The component to show example installations as a list.
class ExampleInstallationList extends Component<IExampleInstallationListProps, IExampleInstallationListState> {
  state: IExampleInstallationListState = {
    installations: undefined,
    page: 0,
    pageSize: 30,
    errorMessage: undefined,
    deployment_id: this.props.deployment_id,
    refreshNeeded: false,
  };

  reloadExampleInstallations = async () => {
    var req: ApiListExampleDatasetInstallationsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
      deployment_id: this.state.deployment_id,
    };
    const installations = await apiClients.idashboardClient.ListExampleDatasetInstallations(req);
    this.setState({ installations: installations });
  };

  refreshExampleInstallations = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadExampleInstallations);
  };

  static getDerivedStateFromProps(props: IExampleInstallationListProps, state: IExampleInstallationListState) {
    if (props.deployment_id !== state.deployment_id) {
      return {
        deployment_id: props.deployment_id,
        page: 0,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshExampleInstallations();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshExampleInstallations);
    }
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshExampleInstallations
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshExampleInstallations
    );
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const installations = this.state.installations;
    const page = this.state.page;

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Example installations</Menu.Item>
        </SecondaryMenu>
        <ExampleInstallationsListView
          {...this.props}
          installations={installations}
          onNextPage={this.onNextPage}
          onPreviousPage={page > 0 ? this.onPreviousPage : undefined}
          page={page}
          pageSize={this.state.pageSize}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(ExampleInstallationList);
