//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, DropdownProps, Form, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { DeploymentInfo, MemberLogLevel } from "../../api/lib";
import { Deployment_ServerStatus as ApiDeployment_ServerStatus } from "../../api/lib";
import { ContentActionButton, ErrorMessage, FormActionButtonCancel, FormActionButtonSave, Processing, TextLink } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { LogLevelList } from "./LogLevelList";

interface IRole {
  id: string;
  title: string;
}

interface ISetLogLevelModalProps extends IWithRefreshProps, RouteComponentProps {
  deployment: DeploymentInfo;
  deploymentId: string;
  servers?: ApiDeployment_ServerStatus[];
  reloadDeploymentInfo: () => void;
}

const roles: IRole[] = [
  { id: "all", title: "All" },
  { id: "agents", title: "Agents" },
  { id: "coordinators", title: "Coordinators" },
  { id: "dbservers", title: "DBServers" },
  { id: "singles", title: "Single" },
];

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const SetLogLevelModal = ({ deploymentId, servers = [], deployment, reloadDeploymentInfo }: ISetLogLevelModalProps) => {
  const [isSettingComplete, updateSettingStatus] = useState(false);
  const [defaultLogLevels, setDefaultLogLevels] = useState<MemberLogLevel[]>([]);
  const [memberLogLevels, setMemberLogLevels] = useState<MemberLogLevel[]>(deployment?.member_log_levels || []);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("all");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const onSetLevels = async (): Promise<void> => {
    setProcessing(true);
    setErrorMessage(undefined);

    const invalidLevels = memberLogLevels.filter(
      (memberLogLevel) => (memberLogLevel.level === "DEBUG" || memberLogLevel.level === "TRACE") && !memberLogLevel.expire_at
    );

    if (invalidLevels.length) {
      setProcessing(false);
      setErrorMessage("Expiration date is mandatory for DEBUG and TRACE log levels.");
      return;
    }

    try {
      await apiClients.idashboardClient.SetDeploymentLogLevels({
        deployment_id: deploymentId,
        member_log_level: memberLogLevels,
      });
      reloadDeploymentInfo();
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      setErrorMessage(err);
    }
  };

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const onRoleFilterChanged = (id: string) => setSelectedRoleId(id);

  const levelKeys = Array.from(new Set(defaultLogLevels.map((x) => x.topic))).sort();

  const onComponentAdded = (component: string) => {
    const newLevels = [...memberLogLevels, { topic: component, level: "WARN", role: selectedRoleId }];
    setMemberLogLevels(newLevels);
  };

  const fetchDefaultLogLevels = async () => {
    setLoading(true);
    try {
      const defaultLogLevels = await apiClients.idashboardClient.GetDefaultLogLevels({ id: deploymentId });
      setDefaultLogLevels(defaultLogLevels?.items || []);
    } catch (err) {
      setErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const hasAgents = servers.some((server) => server.type === "Agent");
  const hasSingles = servers.some((server) => server.type === "Single");
  const hasCoordinators = servers.some((server) => server.type === "Coordinator");
  const hasDBServers = servers.some((server) => server.type === "Dbserver");

  const roleOptions = roles
    .filter((x, i) => {
      switch (x.id) {
        case "all":
          return true;
        case "agents":
          return hasAgents;
        case "coordinators":
          return hasCoordinators;
        case "dbservers":
          return hasDBServers;
        case "singles":
          return hasSingles;
      }
      return false;
    })
    .map((role, i) => {
      return {
        key: `role-${role.id || ""}`,
        text: role.title,
        value: role.id,
      };
    });

  const remainingLevelKeys = _.xor(
    levelKeys,
    memberLogLevels.map((memberLogLevel) => memberLogLevel.topic || "")
  );
  const addKeyOptions = remainingLevelKeys.map((key, i) => {
    return {
      key: `key-${key}`,
      text: key,
      value: key,
    };
  });

  useEffect(() => {
    if (open && !!deploymentId) {
      setLoading(true);
      fetchDefaultLogLevels();
    }
  }, [open, deploymentId]);

  return (
    <span>
      <ContentActionButton primary onClick={onOpen} content="Set log level" icon="settings" />
      {open && (
        <Modal open onClose={onClose}>
          {loading && <Processing active={loading} message="Loading..." />}
          <Modal.Header>Set log level</Modal.Header>
          <Modal.Content>
            <ErrorMessage active={!!errorMessage} message={errorMessage} />
            {!isSettingComplete && (
              <>
                <Processing active={processing} message="Setting log levels..." />
                <Form onSubmit={onSetLevels}>
                  <Form.Field>
                    <label>Role:</label>
                    <Dropdown
                      fluid
                      button
                      scrolling
                      value={selectedRoleId}
                      text={getOptionText(roleOptions, selectedRoleId) || "?"}
                      options={roleOptions}
                      onChange={(_, data: DropdownProps) => onRoleFilterChanged(data.value as string)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Add component:</label>
                    <Dropdown
                      button
                      fluid
                      scrolling
                      search
                      text="Select component"
                      options={addKeyOptions}
                      onChange={(_, data: DropdownProps) => onComponentAdded(data.value as string)}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Log levels:</label>
                    <LogLevelList memberLogLevels={memberLogLevels} setMemberLogLevels={setMemberLogLevels} setErrorMessage={setErrorMessage} />
                  </Form.Field>
                </Form>
              </>
            )}
            {isSettingComplete && (
              <>
                Log level setting completed. You can visit the
                <TextLink href="#jobs">
                  {" "}
                  <b> Jobs tab </b>{" "}
                </TextLink>
                page to see the changes.
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            {!isSettingComplete ? (
              <FormActionButtonSave primary onClick={onSetLevels} title="Set" icon="check" disabled={processing} />
            ) : (
              <FormActionButtonSave primary onClick={() => updateSettingStatus(false)} title="Set More" icon="check" disabled={processing} />
            )}
            <FormActionButtonCancel onClick={onClose} title={isSettingComplete ? "Close" : "Cancel"} />
          </Modal.Actions>
        </Modal>
      )}
    </span>
  );
};

export default withRefresh()(SetLogLevelModal);
