//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Icon, Message, Modal } from "semantic-ui-react";
import { ErrorMessage, FormActionButtonCancel } from "../../ui/lib";
import { FlexBox } from "../../ui/_flex";
import { PagingButtons } from "../../ui/_paging";
import { ICommentsModalViewArgs } from "./CommentTypes";
import styled from "@emotion/styled";
import CommentsListView from "./CommentsListView";
import CommentInputField from "./CommentInputField";

export const StyledIcon = styled(Icon)`
  cursor: pointer !important;
`;

const StyledMessage = styled(Message)`
  position: relative !important;
  overflow: auto !important;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 5px 5px !important;
`;

const StyledLinkBack = styled.span`
  position: relative;
  top: 6px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #8d8d7d;
  }
`;

const CommentsModalView = ({
  comments,
  onCommentsPageChange,
  error,
  createComment,
  canCreate,
  lastestCommentsTriggered,
  onClose,
  commentCreationInProcess,
}: ICommentsModalViewArgs) => {
  const [page, updatePage] = useState(0);
  const [comment, updateComment] = useState("");
  const [commentCreatedRecently, updateCommentCreationStatus] = useState(false);
  const [errorMessage, updateErrorMessage] = useState(error);

  const { items = [] } = comments;

  useEffect(() => {
    onCommentsPageChange(page, 10);
    updateCommentCreationStatus(false);
  }, [page]);

  const getNextPage = () => {
    updatePage(page + 1);
  };

  const getPreviousPage = () => {
    const previousPage = page - 1 || 0;
    updatePage(previousPage);
  };

  const createNewComment = async () => {
    if (canCreate) {
      await createComment(comment);
    }
  };

  const handleAddComment = async () => {
    if (!!comment.trim().length) {
      createNewComment();
      updateCommentCreationStatus(page > 0);
      updateComment("");
      return;
    }
    updateErrorMessage("Comment cannot be empty");
  };

  const handleCommentChange = (comment: string) => {
    updateComment(comment);
  };

  return (
    <Modal centered open closeOnEscape closeOnDimmerClick>
      <Modal.Header>
        <FlexBox justify="space-between">
          <span>All comments</span>
          <StyledIcon name="close" onClick={onClose} />
        </FlexBox>
      </Modal.Header>
      <Modal.Content scrolling>
        {!!errorMessage && (
          <ErrorMessage
            active
            message={errorMessage}
            onDismiss={() => {
              updateErrorMessage("");
            }}
          />
        )}
        {canCreate && (
          <>
            <CommentInputField
              handleCommentChange={(value: string) => {
                handleCommentChange(value);
              }}
              handleAddComment={handleAddComment}
              commentCreationInProcess={commentCreationInProcess}
              comment={comment}
              autoFocus
            />
          </>
        )}
        <StyledMessage basic>
          {commentCreatedRecently && !commentCreationInProcess && page > 0 && (
            <StyledLinkBack
              onClick={() => {
                updatePage(0);
              }}
            >
              You are on page: <b>{page + 1}</b>. To see your new comment, click here to go to the first page
            </StyledLinkBack>
          )}
          <PagingButtons size="tiny" page={page} pageSize={10} count={items.length} onNextPage={getNextPage} onPreviousPage={getPreviousPage} />
        </StyledMessage>
        <CommentsListView comments={comments} lastestCommentsTriggered={lastestCommentsTriggered} />
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onClose} title="Close" />
      </Modal.Actions>
    </Modal>
  );
};

export default CommentsModalView;
