//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Organization as ApiOrganization, SetOrganizationAllowedCustomImagesRequest as ApiSetOrganizationAllowedCustomImagesRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface ICustomImageAllowedFieldViewArgs {
  organization: ApiOrganization;
  canSetAllowCustomImages: boolean;
  onSetOrganizationAllowedCustomImages: (enabled: boolean) => void;
}

const CustomImageAllowedFieldView = ({ ...args }: ICustomImageAllowedFieldViewArgs) => {
  const is_custom_image_allowed = !!args.organization.is_allowed_to_use_custom_images;
  return (
    <Field>
      <FL>Using Custom Images</FL>
      <FC>
        {is_custom_image_allowed ? "Allowed" : "Not allowed"}
        {args.canSetAllowCustomImages && (
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!is_custom_image_allowed && <Dropdown.Item text="Allow" onClick={() => args.onSetOrganizationAllowedCustomImages(true)} />}
              {is_custom_image_allowed && <Dropdown.Item text="Do not allow" onClick={() => args.onSetOrganizationAllowedCustomImages(false)} />}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </FC>
    </Field>
  );
};

interface ICustomImageAllowedFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface ICustomImageAllowedFieldState extends IOrganizationDetailsFieldState {
  commentPrompt: ICommentPromptStateForIntegrations;
}

class CustomImageAllowedField extends OrganizationDetailsField<ICustomImageAllowedFieldProps, ICustomImageAllowedFieldState> {
  state: ICustomImageAllowedFieldState = {
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };
  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  onSetOrganizationAllowedCustomImages = async (enabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set organization to ${enabled ? "allow" : "disallow"} custom images`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization allowed to use custom images, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationAllowedCustomImagesRequest = {
            organization_id: this.props.organization.id,
            allowed: enabled,
          };
          await apiClients.idashboardClient.SetOrganizationAllowedCustomImages(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const can_set_allow_custom_images = this.hasPermission("internal-dashboard.organization.set-allow-custom-images");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <CustomImageAllowedFieldView {...this} {...this.props} {...this.state} canSetAllowCustomImages={can_set_allow_custom_images} />
      </div>
    );
  }
}

export default withRefresh()(CustomImageAllowedField);
