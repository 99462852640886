//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import styled from "@emotion/styled";

const StyledIcon = styled(Icon)`
  margin-left: 8px !important;
  color: rgb(138, 158, 86);
`;

export const Locked = () => {
  return (
    <Popup
      content="Locked"
      trigger={
        <span>
          <StyledIcon name="lock" />
        </span>
      }
    />
  );
};
