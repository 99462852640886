//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component, useEffect, useState } from "react";
import { Loader, Popup, Table, Menu, Button } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Event as ApiEvent, EventList as ApiEventList, ListEventOptions as ApiListEventOptions, DataClusterAssignment } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, ErrorMessage, Loading, LoaderBox, PagingButtons, SecondaryMenu, UserLink, FlexBox } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface IHeaderViewArgs {
  loading: boolean;
  page: number;
  count: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderViewArgs) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Organization</Table.HeaderCell>
      <Table.HeaderCell>Subject</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Created By</Table.HeaderCell>
      <Table.HeaderCell>Created With</Table.HeaderCell>
      <Table.HeaderCell>Reason</Table.HeaderCell>
      <Table.HeaderCell>
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiEvent;
}

const RowView = ({ ...args }: IRowView) => {
  const [hasCopiedDataClusterId, setHasCopiedDataClusterId] = React.useState(false);
  const [dataClusterId, setDataClusterId] = useState<DataClusterAssignment["datacluster_id"] | undefined>();
  const [isLoadingDataClusterId, setIsLoadingDataClusterId] = useState<boolean>(false);
  const evt = args.item;

  const onCopyDataClusterId = () => {
    setHasCopiedDataClusterId(true);
    setInterval(() => {
      setHasCopiedDataClusterId(false);
    }, 3000);
  };

  const { subject_id, subject_url } = evt;
  const isDeploymentRelated = String(subject_url).toLowerCase().includes("deployment");

  const getDataClusterId = async () => {
    setIsLoadingDataClusterId(true);
    try {
      const deploymentInfo = await apiClients.idashboardClient.GetDeployment({ id: subject_id });

      if (deploymentInfo.assignment?.datacluster_id) {
        setDataClusterId(deploymentInfo.assignment.datacluster_id);
      }
    } catch {
      setDataClusterId(undefined);
    } finally {
      setIsLoadingDataClusterId(false);
    }
  };

  useEffect(() => {
    if (isDeploymentRelated) {
      getDataClusterId();
    }
  }, []);

  return (
    <Table.Row>
      <Table.Cell>
        <div>{evt.id}</div>
      </Table.Cell>
      <Table.Cell>{evt.type || "-"}</Table.Cell>
      <Table.Cell>
        <div>{evt.organization_id || "-"}</div>
      </Table.Cell>
      <Table.Cell>
        <Popup
          hoverable
          trigger={<div>{evt.subject_id || "-"}</div>}
          content={
            <div>
              <p>{evt.subject_url || "-"}</p>
              {isDeploymentRelated &&
                (isLoadingDataClusterId ? (
                  <Loader active inline />
                ) : (
                  <FlexBox align="center">
                    <span>Data Cluster Id: {dataClusterId || "-"}</span>
                    <CopyToClipboard text={dataClusterId || "-"} onCopy={onCopyDataClusterId}>
                      <Button icon={hasCopiedDataClusterId ? "check" : "copy"} floated="right" size="tiny" basic />
                    </CopyToClipboard>
                  </FlexBox>
                ))}
            </div>
          }
        />
      </Table.Cell>
      <Table.Cell>{evt.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={evt.created_at} label="Created at" /> : "-"}</Table.Cell>
      <Table.Cell>{(evt.created_by_id || "").includes("oasis-system") ? evt.created_by_id : <UserLink id={evt.created_by_id || ""} />}</Table.Cell>
      <Table.Cell>{evt.created_with || "?"}</Table.Cell>
      <Table.Cell>{evt.reason || ""}</Table.Cell>
    </Table.Row>
  );
};

// Interface describing the list
interface IListView {
  items: ApiEvent[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => {
        const id = item.id || "";
        return <RowView {...args} key={id} item={item} />;
      })}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No events</div>;

// Interface describing the list view arguments
export interface IEventListViewArgs extends RouteComponentProps {
  loading: boolean;
  list?: ApiEventList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

export const EventListView = ({ ...args }: IEventListViewArgs) => {
  if (!args.list) {
    return <Loading />;
  }
  const isEmpty = !args.list.items || args.list.items.length === 0;
  const items = args.list ? args.list.items : undefined;
  return (
    <div>
      <ListView {...args} items={items || []} />
      {isEmpty && <EmptyView />}
    </div>
  );
};

interface IEventListProps extends IWithRefreshProps, RouteComponentProps {
  showHeader: boolean;
  organization_id?: string;
  subject_id?: string;
}

interface IEventListState {
  list?: ApiEventList;
  errorMessage?: string;
  page: number;
  pageSize: number;
}

// The component to show Events as a list.
class EventList extends Component<IEventListProps, IEventListState> {
  state: IEventListState = {
    list: undefined,
    errorMessage: undefined,
    page: 0,
    pageSize: 25,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadEvents = async () => {
    var req: ApiListEventOptions = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
      sort_descending: true,
    };
    if (!!this.props.organization_id && req.options) {
      req.options.context_id = this.props.organization_id;
    }
    if (!!this.props.organization_id && this.props.organization_id == this.props.subject_id) {
      // We are on organization level, do not filter on subject ID, however
      // filter on types (aka topics), and include the project and deployment created/deleted events as well
      req.types = [
        "resourcemanager.organization.created",
        "resourcemanager.organization.updated",
        "resourcemanager.organization.deleted",
        "resourcemanager.project.created",
        "resourcemanager.project.deleted",
        "iam.policy.updated",
        "data.deployment.created",
        "data.deployment.deleted",
        "prepaid.prepaiddeployment.created",
        "prepaid.prepaiddeployment.updated",
        "prepaid.prepaiddeployment.deleted",
        "prepaid.prepaiddeployment.deployment-attached",
        "prepaid.prepaiddeployment.deployment-detached",
      ];
    } else if (!!this.props.subject_id) {
      // Filter on subject ID
      req.subject_ids = [this.props.subject_id];
    }
    const list = await apiClients.idashboardClient.ListAllEvents(req);
    this.setState({
      list: list,
    });
  };

  refreshEvents = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadEvents);
  };

  componentDidMount() {
    this.refreshEvents();
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshEvents
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshEvents
    );
  };

  render() {
    const list = this.state.list;

    const eventList = <EventListView {...this.props} {...this.state} list={list} onNextPage={this.onNextPage} onPreviousPage={this.onPreviousPage} />;

    if (!this.props.showHeader) {
      return eventList;
    }

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Events</Menu.Item>
        </SecondaryMenu>
        {eventList}
      </ContentSegment>
    );
  }
}

export default withRefresh()(EventList);
