//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps, Loader } from "semantic-ui-react";
import { ListOptions as ApiListOptions, CurrencyList as ApiCurrencyList, Currency as ApiCurrency } from "../api/lib";
import { Cached as apiClients } from "../api/apiclients";
import _ from "lodash";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";

interface ICurrencyInputProps extends IWithRefreshProps, RouteComponentProps {
  id?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowDefault?: boolean;
}

interface ICurrencyInputState {
  id: string;
  errorMessage?: string;
  loading: boolean;
  currencies?: ApiCurrencyList;
  default_system_currency?: ApiCurrency;
  refreshNeeded: boolean;
}

class CurrencyInputComp extends Component<ICurrencyInputProps, ICurrencyInputState> {
  state: ICurrencyInputState = {
    id: this.props.id || "",
    errorMessage: undefined,
    loading: true,
    currencies: undefined,
    default_system_currency: undefined,
    refreshNeeded: false,
  };

  reloadCurrencies = async () => {
    this.setState({ loading: true });
    const req: ApiListOptions = {};
    const default_system_currency = await apiClients.idashboardClient.GetDefaultSystemCurrency();
    const currencies = await apiClients.idashboardClient.ListCurrencies(req);
    this.setState({
      default_system_currency: default_system_currency,
      currencies: currencies,
      loading: false,
    });
  };

  refreshCurrencies = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCurrencies);
  };

  static getDerivedStateFromProps(props: ICurrencyInputProps, state: ICurrencyInputState) {
    const id = props.id || "";
    if (id != state.id) {
      return {
        refreshNeeded: true,
        id: id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshCurrencies();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshCurrencies);
    }
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  render() {
    const currencies = this.state.currencies;
    const default_system_currency = this.state.default_system_currency || {};
    let list = _.clone((currencies || {}).items || []);
    if (this.props.allowDefault) {
      list.push({ id: "", name: `Default (${default_system_currency.id})` });
    }
    return (
      <div>
        {this.state.loading && <Loader active size="tiny" />}
        {currencies && currencies.items && (
          <Dropdown
            fluid
            selection
            placeholder={this.props.placeholder}
            options={_.map(list, (x: ApiCurrency) => {
              return {
                key: x.id,
                text: x.name,
                value: x.id,
              };
            })}
            onChange={this.onChange}
            value={this.state.id}
          />
        )}
      </div>
    );
  }
}

export const CurrencyInput = withRefresh()(CurrencyInputComp);
