//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import { Divider } from "semantic-ui-react";
import { ErrorMessage } from "../../ui/lib";
import { IBaseCommentViewArgs } from "./CommentTypes";
import CommentsListView from "./CommentsListView";
import CommentInputField from "./CommentInputField";
import styled from "@emotion/styled";

export const StyledCommentsHolder = styled.div`
  height: 390px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 20px;
`;

const CommentsListWithInput = ({ comments, error, createComment, canCreate, commentCreationInProcess, lastestCommentsTriggered }: IBaseCommentViewArgs) => {
  const [comment, updateComment] = useState("");

  const handleCommentAddition = () => {
    if (!!comment.trim().length) {
      createComment(comment);
      updateComment("");
    }
  };

  return (
    <>
      {canCreate && (
        <CommentInputField
          handleCommentChange={(value: string) => updateComment(value)}
          handleAddComment={handleCommentAddition}
          commentCreationInProcess={commentCreationInProcess}
          comment={comment}
        />
      )}
      <Divider hidden />
      {!!error && <ErrorMessage message={error} active />}
      <CommentsListView comments={comments} lastestCommentsTriggered={lastestCommentsTriggered} />
    </>
  );
};

export default CommentsListWithInput;
