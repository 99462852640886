//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import {
  Button,
  Icon,
  Menu,
  Popup,
  PopupContentProps,
  PopupProps,
  SemanticCOLORS,
  SemanticFLOATS,
  SemanticICONS,
  SemanticShorthandItem,
  SemanticSIZES,
} from "semantic-ui-react";
import history from "../history";

interface IButtonView {
  disabled?: boolean;
  tooltip?: string;
  onClick: () => void;
  icon?: SemanticICONS;
  type?: "submit" | "reset" | "button";
  size?: SemanticSIZES;
  color?: SemanticCOLORS;
}

interface IOptionalButtonView {
  disabled?: boolean;
  tooltip?: string;
  onClick?: () => void;
  icon?: SemanticICONS;
  type?: "submit" | "reset" | "button";
  size?: SemanticSIZES;
  loading?: boolean;
}

interface IOptionalActionButton extends IOptionalButtonView {
  primary?: boolean;
  big?: boolean;
  title?: string;
}
interface IActionButton extends IButtonView {
  primary?: boolean;
  big?: boolean;
  title?: string;
}

interface IDropdownButton extends IOptionalButtonView {
  primary?: boolean;
  big?: boolean;
  title?: string;
}

interface IActionButtonWithContent extends IActionButton {
  content: string;
  icon?: SemanticICONS;
}

export const ContentActionButton = ({ ...args }: IActionButtonWithContent) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.content}
    icon={args.icon || "plus"}
    labelPosition="right"
  />
);

export const ContentActionButtonNew = ({ ...args }: IActionButtonWithContent) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.content}
    icon={args.icon || "plus"}
    labelPosition="right"
  />
);

// MenuActionBack renders a button for a Back action is a menu.
export const MenuActionBack = () => (
  <Menu.Item onClick={() => history.goBack()}>
    <Icon name="arrow left" />
  </Menu.Item>
);

// ListAction renders a generic button for a View action is a listview.
export const ListAction = ({ ...args }: IOptionalButtonView) => {
  const hasIcon = !!args.icon;
  const button = (
    <Button icon disabled={args.disabled} onClick={args.onClick} type={args.type || "button"} size={args.size}>
      {hasIcon && <Icon name={args.icon} />}
    </Button>
  );
  if (!!args.tooltip) {
    return <Popup position="top right" content={args.tooltip} trigger={button} />;
  }
  return button;
};

// ListActionView renders a button for a View action is a listview.
export const ListActionView = ({ ...args }: IButtonView) => (
  <Button icon size={args.size || "small"} primary disabled={args.disabled} onClick={() => args.onClick()} color={args.color}>
    view
  </Button>
);

// ListActionEdit renders a button for a Edit action is a listview.
export const ListActionEdit = ({ ...args }: IButtonView) => (
  <Button circular icon disabled={args.disabled} onClick={() => args.onClick()} color={args.color} size={args.size}>
    <Icon name={args.icon || "edit"} />
  </Button>
);

// ListActionDelete renders a button for a Delete action is a listview.
export const ListActionDelete = ({ ...args }: IButtonView) => (
  <Button circular icon disabled={args.disabled} onClick={() => args.onClick()} color={args.color} size={args.size}>
    <Icon name={args.icon || "trash"} />
  </Button>
);

// ListActionRotate renders a button for a Rotate action is a listview.
export const ListActionRotate = ({ ...args }: IButtonView) => (
  <Popup
    trigger={
      <Button circular icon disabled={args.disabled} onClick={() => args.onClick()} color={args.color} size={args.size}>
        <Icon name={args.icon || "recycle"} />
      </Button>
    }
    content={args.tooltip || "Rotate the server"}
  />
);

// ListActionMoveUp renders a button for a MoveUp action is a listview.
export const ListActionMoveUp = ({ ...args }: IButtonView) => (
  <Button circular icon disabled={args.disabled} onClick={() => args.onClick()} color={args.color} size={args.size}>
    <Icon name={args.icon || "arrow up"} />
  </Button>
);

// ListActionMoveDown renders a button for a MoveUp action is a listview.
export const ListActionMoveDown = ({ ...args }: IButtonView) => (
  <Button circular icon disabled={args.disabled} onClick={() => args.onClick()} color={args.color} size={args.size}>
    <Icon name={args.icon || "arrow down"} />
  </Button>
);

// ListActionAccept renders a button for an Accept action is a listview.
export const ListActionAccept = ({ ...args }: IButtonView) => (
  <Button circular icon disabled={args.disabled} onClick={() => args.onClick()} color={args.color} size={args.size}>
    <Icon name={args.icon || "check"} />
  </Button>
);

// ListActionReject renders a button for a Reject action is a listview.
export const ListActionReject = ({ ...args }: IButtonView) => (
  <Button circular icon disabled={args.disabled} onClick={() => args.onClick()} color={args.color} size={args.size}>
    <Icon name={args.icon || "cancel"} />
  </Button>
);

// ListActionSetUnassignable renders a button for a "Set unassignable" action is a listview.
export const ListActionSetUnassignable = ({ ...args }: IButtonView) => (
  <Popup
    trigger={
      <Button icon size={args.size || "small"} primary disabled={args.disabled} onClick={() => args.onClick()} color={args.color}>
        <Icon name={args.icon || "lock"} />
      </Button>
    }
    content="Prevent new deployments on datacluster"
  />
);

// ListActionSetAssignable renders a button for a "Set assignable" action is a listview.
export const ListActionSetAssignable = ({ ...args }: IButtonView) => (
  <Popup
    trigger={
      <Button icon size={args.size || "small"} primary disabled={args.disabled} onClick={() => args.onClick()} color={args.color}>
        <Icon name={args.icon || "unlock"} />
      </Button>
    }
    content="Allow new deployments on datacluster"
  />
);

// ListActionSetCurrent renders a button for a "Mark as current" action is a listview.
export const ListActionSetCurrent = ({ ...args }: IButtonView) => (
  <Popup
    trigger={
      <Button icon size={args.size || "small"} primary disabled={args.disabled} onClick={() => args.onClick()} color={args.color}>
        <Icon name={args.icon || "tag"} />
      </Button>
    }
    content="Mark as current"
  />
);

// ListActionDownload renders a button for a "Download" action is a listview.
export const ListActionDownload = ({ ...args }: IButtonView) => (
  <Popup
    position="top right"
    content={args.tooltip || "Download"}
    trigger={
      <Button icon disabled={args.disabled} onClick={args.onClick} color={args.color} size={args.size}>
        <Icon name={args.icon || "download"} />
      </Button>
    }
  />
);

export const ListActionOpenInNewTab = ({
  ...args
}: Omit<IButtonView, "onClick" | "tooltip"> & {
  link: string;
  className?: string;
  position: PopupProps["position"];
  tooltip?: SemanticShorthandItem<PopupContentProps>;
  circular?: boolean;
  floated?: SemanticFLOATS;
}) => (
  <Popup
    trigger={
      <a href={args.link} target="_blank" rel="noopener noreferrer">
        <Button className={args.className} icon={args.icon || "external alternate"} size={args.size} circular={args.circular} floated={args.floated} />
      </a>
    }
    content={args.tooltip || "Open in new tab"}
    position={args.position}
  />
);

export const FormActionButtonCreate = ({ ...args }: IOptionalActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Create"}
    icon={args.icon || "save"}
    labelPosition="right"
  />
);

export const FormActionButtonSave = ({ ...args }: IOptionalActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Save"}
    icon={args.icon || "check"}
    labelPosition="right"
  />
);

export const FormActionButtonCancel = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Cancel"}
    icon={args.icon || "cancel"}
    labelPosition="right"
  />
);

export const FormActionButtonDropdown = ({ ...args }: IDropdownButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "..."}
    icon={args.icon || "ellipsis horizontal"}
    labelPosition="right"
  />
);

export const ContentActionButtonEdit = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Edit"}
    icon={args.icon || "edit"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonBackup = ({ ...args }: IOptionalActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content="Back up now"
    icon={args.icon || "window restore"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const FormActionButtonResume = ({ ...args }: IOptionalActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Resume"}
    icon={args.icon || "play"}
    labelPosition="right"
  />
);
