//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import Downloader from "js-file-downloader";
import Auth from "../../auth/Auth";

export const DownloadInvoice = async (invoiceID: string, auth: Auth) => {
  const fileURL = `/downloads/invoices/${invoiceID}.pdf`;
  const downloader = new Downloader({
    url: fileURL,
    headers: [{ name: "Authorization", value: `bearer ${auth.getAccessToken()}` }],
  });
  await downloader;
};
