//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Button, Loader, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, DataProcessingAddendum as ApiDataProcessingAddendum } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, LoaderBox, MenuActionBack, SecondaryMenu, Section, SectionContent, Processing, ErrorMessage } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { EditorView } from "./DataProcessingAddendumEditor";

interface IUpdateDataProcessingAddendumProps extends IWithRefreshProps, RouteComponentProps {
  dpaId: string;
  onSaved: () => void;
  onCancel: () => void;
}

interface IUpdateDataProcessingAddendumState {
  dpa?: ApiDataProcessingAddendum;
  saving: boolean;
  errorMessage?: string;
}

// The component to update DPA
class UpdateDataProcessingAddendum extends Component<IUpdateDataProcessingAddendumProps, IUpdateDataProcessingAddendumState> {
  state: IUpdateDataProcessingAddendumState = {
    dpa: undefined,
    saving: false,
    errorMessage: undefined,
  };

  hasPermission = (p: Permission) => hasSupportPermission(p, this.props.hasPermissionByUrl);

  reloadTermsAndConditions = async () => {
    const idOptions: ApiIDOptions = { id: this.props.dpaId };
    var dpa = await apiClients.idashboardClient.GetDataProcessingAddendum(idOptions);
    this.setState({
      dpa: dpa,
    });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadTermsAndConditions);
  }

  onContentChange = (content: string) => {
    this.setState({
      dpa: {
        content: content,
      },
    });
  };

  onSave = async () => {
    if (this.state.dpa) {
      try {
        this.setState({ saving: true, errorMessage: undefined });
        const req: ApiDataProcessingAddendum = {
          id: this.props.dpaId || "",
          content: this.state.dpa.content || "",
        };
        await apiClients.idashboardClient.UpdateDataProcessingAddendum(req);
        this.props.onSaved();
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ saving: false });
      }
    }
  };

  render() {
    const has_dpa = !!this.state.dpa;
    const dpa = this.state.dpa || {};

    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Edit Data Processing Addendum</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={!has_dpa} inline />
          </LoaderBox>
          <Processing active={this.state.saving} message="Updating Data Processing Addendum..." />
          <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        </SecondaryMenu>
        {has_dpa && (
          <div>
            <Section>
              <SectionContent>
                <EditorView {...this.props} {...this.state} dpa={dpa} onContentChange={this.onContentChange} />
                <Button icon="check" content="Save" primary onClick={this.onSave} />
                <Button content="Cancel" onClick={this.props.onCancel} />
              </SectionContent>
            </Section>
          </div>
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(UpdateDataProcessingAddendum);
