//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import _ from "lodash";
import { Icon, Statistic } from "semantic-ui-react";
import { BackupInfoList as ApiBackupInfoList, BackupPolicyInfoList as ApiBackupPolicyInfoList } from "../../api/lib";
import { StatisticsContentGroup, StyledStatsSegment } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";

interface BackupSummaryViewArgs {
  isDeploymentPaused: boolean;
  totalBackupSizeBytes: number;
  backupUploadInProgress: boolean;
  backups?: ApiBackupInfoList;
  lastGoodBackups?: ApiBackupInfoList;
  backupPolicies?: ApiBackupPolicyInfoList;
}

export const BackupSummaryTableView = ({ ...args }: BackupSummaryViewArgs) => {
  const lastGoodBackups = (args.lastGoodBackups || {}).items || [];
  const lastSafeBackup = _.first(lastGoodBackups.map((x) => (x.backup || {}).created_at));
  const nextBackup = BackupSummary.getNextBackup(args.backupPolicies);
  const totalCount = args.backups && args.backups.budget ? args.backups.budget.used : 0;
  const recentErrorCount = BackupSummary.getRecentErrorCount(args.backups);
  const formatNumber = (x: number | undefined, defaultString: string) => {
    if (!x) {
      return defaultString;
    }
    return _.toString(x);
  };
  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths="5">
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="calendar check" className="primary-text" /> Last safe backup
            </Statistic.Label>
            <Statistic.Value>
              {lastSafeBackup ? <DateTimePopupWithUTCAndLocalTime dateTime={lastSafeBackup} label="Last safe Backup at" /> : "None"}
            </Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="calendar check" className="secondary-text" /> Next backup
            </Statistic.Label>
            <Statistic.Value>
              {" "}
              {nextBackup ? args.isDeploymentPaused ? "Paused" : <DateTimePopupWithUTCAndLocalTime dateTime={nextBackup} label="Next Backup at" /> : "None"}
            </Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="hashtag" className="secondary-text" /> Total count
            </Statistic.Label>
            <Statistic.Value>{formatNumber(totalCount, "None")}</Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="circle" className="secondary-text" /> Total size
            </Statistic.Label>
            <Statistic.Value>
              {humanizeFileSize(args.totalBackupSizeBytes)}
              {args.backupUploadInProgress && <sup>*</sup>}
            </Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="exclamation triangle" className="orange-text" /> Recent Error
            </Statistic.Label>
            <Statistic.Value className={recentErrorCount > 0 ? "orange-text" : ""}>{formatNumber(recentErrorCount, "None")}</Statistic.Value>
          </Statistic>
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

class BackupSummary {
  static getLastSafeBackup = (backups?: ApiBackupInfoList): Date | undefined => {
    if (backups && backups.items) {
      const succeededItems = backups.items.filter((b) => ((b.backup || {}).status || {}).available);
      if (succeededItems.length > 0) {
        const sortedItems = succeededItems.sort((bi1, bi2) => {
          const b1 = bi1.backup || {};
          const b2 = bi2.backup || {};
          if (b1.created_at && b2.created_at) {
            return b1.created_at > b2.created_at ? -1 : 1;
          }
          return 0;
        });
        const lastBackup = sortedItems[0].backup || {};
        return lastBackup.created_at;
      }
    }
    return undefined;
  };

  static getTotalSize = (backups?: ApiBackupInfoList): number => {
    let size = 0;
    if (backups && backups.items) {
      backups.items.forEach((bi) => {
        const b = bi.backup || {};
        if (b.status && b.status.size_bytes) {
          // Make sure we are adding numbers, not strings
          size += +b.status.size_bytes;
        }
      });
    }
    return size;
  };

  static getRecentErrorCount = (backups?: ApiBackupInfoList): number => {
    let errorCount = 0;
    if (backups && backups.items) {
      const sortedItems = backups.items.sort((bi1, bi2) => {
        const b1 = bi1.backup || {};
        const b2 = bi2.backup || {};
        if (b1.created_at && b2.created_at) {
          return b1.created_at > b2.created_at ? -1 : 1;
        }
        return 0;
      });
      for (let i = 0; i < sortedItems.length; i++) {
        const b = sortedItems[0].backup || {};
        if (b.status) {
          if (b.status.is_failed) {
            errorCount++;
          } else {
            break;
          }
        }
      }
    }
    return errorCount;
  };

  static getNextBackup = (backupPolicies?: ApiBackupPolicyInfoList): Date | undefined => {
    if (backupPolicies && backupPolicies.items) {
      const nonPausedItems = backupPolicies.items.filter((b) => !(b.backup_policy || {}).is_paused);
      if (!_.isEmpty(nonPausedItems)) {
        const sortedItems = nonPausedItems.sort((bpi1, bpi2) => {
          const bp1 = bpi1.backup_policy || {};
          const bp2 = bpi2.backup_policy || {};
          if (bp1.status && bp1.status.next_backup && bp2.status && bp2.status.next_backup) {
            return bp1.status.next_backup > bp2.status.next_backup ? 1 : -1;
          }
          if (bp1.status && bp1.status.next_backup) {
            return 1;
          }
          if (bp2.status && bp2.status.next_backup) {
            return -1;
          }
          return 0;
        });
        const firstItem = sortedItems[0].backup_policy || {};
        if (firstItem.status) {
          return firstItem.status.next_backup;
        }
      }
    }
    return undefined;
  };
}
