//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { isEmpty, split } from "lodash";
import { Form, Header, Modal, TextArea, TextAreaProps } from "semantic-ui-react";
import { EditIcon, ErrorMessage, FlexBox, FormActionButtonCancel, FormActionButtonSave, StyledInlineLoader } from "../../ui/lib";
import styled from "@emotion/styled";
import { CommentCreationArgs } from "../comments/CommentTypes";
import CommentInputField from "../comments/CommentInputField";
import { AsyncResult } from "../../util/Types";

export interface ICustomCommandLineArgumentsViewArgs extends CommentCreationArgs {
  canSetKubeArangodbAdditionalCommandLineArgs: boolean;
  onSaveKubeArangodbAdditionalCommandLineArguments: (kubeArangodbArgs: string[]) => Promise<AsyncResult<void>>;
  kubeArangodbAdditionalArgs?: string[];
}

const CmdlineArgument = styled("pre")`
  font-style: italic;
`;

export const KubeArangodbAdditionalCommandLineArgumentsView = ({ ...args }: ICustomCommandLineArgumentsViewArgs) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editKubeArangodbArgs, setKubeArangodbArgs] = useState(args.kubeArangodbAdditionalArgs || []);

  const [comment, setComment] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  const kubeArangodbArgs = args.kubeArangodbAdditionalArgs || [];
  const hasKubeArangodbArgs = !isEmpty(kubeArangodbArgs);

  useEffect(() => {
    let newComment = "";
    if (args.kubeArangodbAdditionalArgs !== editKubeArangodbArgs) {
      newComment = `Setting kube arangodb additional command line arguments to ${editKubeArangodbArgs.join(", ") || "none"}\n`;
    }

    setComment(newComment);
  }, [editKubeArangodbArgs]);

  const textToSplitedLines = (v: string) => {
    return isEmpty(v) ? [] : split(v, "\n");
  };

  const startEditing = () => {
    setKubeArangodbArgs(args.kubeArangodbAdditionalArgs || []);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setLoading(false);
    setError(undefined);
    setCommentCreationFailed(false);
  };

  const saveEdits = async () => {
    setLoading(true);
    const { error: apiError } = await args.onSaveKubeArangodbAdditionalCommandLineArguments(editKubeArangodbArgs);
    setCommentCreationFailed(false);

    if (!!apiError) {
      setError(apiError);
      setLoading(false);
      return;
    }

    const { error: commentsError } = await args.createComment(comment);
    if (!!commentsError) {
      setCommentCreationFailed(true);
      setError("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }
    setLoading(false);
    setIsEditing(false);
  };

  return (
    <Modal
      centered
      open={isEditing}
      trigger={
        <span>
          <br />
          <Header sub>
            Additional Kube Arangodb <br />
            Command Line Arguments
          </Header>
          <FlexBox>
            {!isEditing && (
              <div>
                <div>
                  {!hasKubeArangodbArgs && <span>No additional kube arangodb arguments</span>}
                  {hasKubeArangodbArgs && (
                    <div>
                      {kubeArangodbArgs.map((x, i) => (
                        <CmdlineArgument key={`acmdl${i}`}>{x}</CmdlineArgument>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            {!isEditing && args.canSetKubeArangodbAdditionalCommandLineArgs && <EditIcon onClick={startEditing} />}
          </FlexBox>
        </span>
      }
    >
      <Modal.Header>
        <FlexBox justify="space-between">
          <span>Additional Kube Arangodb Command Line Arguments </span>
          {loading && <StyledInlineLoader inline />}
        </FlexBox>
      </Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} message={error} />
        <Form>
          <Form.Field disabled={commentCreationFailed}>
            <TextArea
              key="kube-arangodb"
              rows="10"
              value={editKubeArangodbArgs.join("\n")}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => setKubeArangodbArgs(textToSplitedLines(data.value as string))}
            />
          </Form.Field>
          <Form.Field required>
            <label>Please provide the reason for this change </label>
            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>custom command line arguments</b>. Otherwise, a default comment will be provided which
              can be changed if required.
            </sub>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={cancelEditing} />
        {commentCreationFailed ? (
          <FormActionButtonSave primary onClick={saveEdits} icon="comment" disabled={!comment.trim() || loading} title="Retry saving comment" />
        ) : (
          <FormActionButtonSave
            primary
            onClick={saveEdits}
            icon={!!error ? "refresh" : "check"}
            disabled={!comment.trim() || loading}
            title={!!error ? "Retry" : "Save"}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};
