//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Label, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Region as ApiRegion, RegionList as ApiRegionList, ListRegionsRequest as ApiListRegionsRequest } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Loading, Processing } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import RegionLimits from "./RegionLimits";
import RegionStats from "./RegionStats";
import { SupportOrganizationID } from "../../constants";

const HeaderView = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Location</Table.HeaderCell>
      <Table.HeaderCell>Stock</Table.HeaderCell>
      <Table.HeaderCell>Deployments</Table.HeaderCell>
      <Table.HeaderCell>Limits</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView extends IWithRefreshProps, RouteComponentProps {
  item: ApiRegion;
}

const RowView = ({ ...args }: IRowView) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div>
          {args.item.id}
          {!!args.item.prerelease && <Label pointing="left">Preview</Label>}
        </div>
      </Table.Cell>
      <Table.Cell>{args.item.location}</Table.Cell>
      <Table.Cell>{!!args.item.out_of_stock ? "Out of stock" : !!args.item.low_stock ? "Low on stock" : "Normal"}</Table.Cell>
      <Table.Cell>
        <RegionStats {...args} region_id={args.item.id || ""} />
      </Table.Cell>
      <Table.Cell>
        <RegionLimits {...args} region_id={args.item.id || ""} />
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the list
interface IListView extends IWithRefreshProps, RouteComponentProps {
  items: ApiRegion[];
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView />
    <Table.Body>
      {args.items.map((item) => {
        const id = item.id || "";
        return <RowView {...args} key={id} item={item} />;
      })}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No regions</div>;

// Interface describing the list view arguments
export interface IRegionListViewArgs extends IWithRefreshProps, RouteComponentProps {
  loading: boolean;
  list?: ApiRegionList;
}

export const RegionListView = ({ ...args }: IRegionListViewArgs) => {
  if (!args.list) {
    return <Loading />;
  }
  const isEmpty = !args.list.items || args.list.items.length === 0;
  const items = args.list ? args.list.items : undefined;
  return (
    <div>
      <ListView {...args} items={items || []} />
      {isEmpty && <EmptyView />}
    </div>
  );
};

interface IRegionListProps extends IWithRefreshProps, RouteComponentProps {
  provider_id: string;
}

interface IRegionListState {
  prev_provider_id: string;
  refreshNeeded: boolean;
  list?: ApiRegionList;
  errorMessage?: string;
}

// The component to show regions of a provider as a list.
class RegionList extends Component<IRegionListProps, IRegionListState> {
  state: IRegionListState = {
    prev_provider_id: "",
    refreshNeeded: false,
    list: undefined,
    errorMessage: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadRegions = async () => {
    var req: ApiListRegionsRequest = {
      provider_id: this.props.provider_id,
      organization_id: SupportOrganizationID,
    };
    const list = await apiClients.authenticationOnly.platformClient.ListRegions(req);
    this.setState({
      list: list,
    });
  };

  refreshRegions = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadRegions);
  };

  static getDerivedStateFromProps(props: IRegionListProps, state: IRegionListState) {
    if (props.provider_id !== state.prev_provider_id) {
      return {
        refreshNeeded: true,
        prev_provider_id: props.provider_id || "",
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshRegions();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshRegions);
    }
  }

  render() {
    const list = this.state.list;

    return (
      <div>
        <Processing active={false} message="Changing region limits..." />
        <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <RegionListView {...this.props} list={list} />
      </div>
    );
  }
}

export default withRefresh()(RegionList);
