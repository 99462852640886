//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import moment from "moment";
import apiClients from "../../api/apiclients";
import {
  CalculateDeploymentSizeRequest as ApiCalculateDeploymentSizeRequest,
  ListProvidersRequest as ApiListProvidersRequest,
  ListRegionsRequest as ApiListRegionsRequest,
  PriceList as ApiPriceList,
  PriceRequest as ApiPriceRequest,
  PriceRequest_Item as ApiPriceRequest_Item,
} from "../../api/lib";
import { SupportOrganizationID } from "../../constants";
import { momentNow } from "../../ui/lib";

export interface IPriceListWithRegion {
  provider: string;
  region: string;
  model: string;
  node_size_id: string;
  node_disk_size: number;
  total_disk_size: number;
  total_memory_size: number;
  priceList: ApiPriceList;
}

interface ICalculatePriceArgs {
  show_usage_vars: boolean;
  show_fixed_vars: boolean;
  honor_min_node_disk_size: boolean;
  period: string;

  plan_id: string;
  currency_id: string;
  provider_id: string;
  region_id: string;
  support_plan_id: string;
  model: string;
  disk_size: number;
  disk_performance_id: string;
  memory_size: number;
  node_count: number;
  node_size_id: string;
  backup_object_storage_size: number;
  network_egress_internet: number;
  network_ingress_internet: number;
  network_egress_in_cluster: number;
  network_ingress_in_cluster: number;
  network_egress_private_endpoint: number;
  network_ingress_private_endpoint: number;
  auditlog_object_storage_size: number;
  auditlog_https_post_invocations: number;
  auditlog_https_post_body_size: number;

  addon_iamprovider: boolean;
  addon_auditlog: boolean;
  addon_privateendpointservice: boolean;
}

export const CalculatePrice = async (args: ICalculatePriceArgs): Promise<IPriceListWithRegion> => {
  const priceLists = await calculatePrice(args);
  return priceLists[0];
};

const getStart = (period: string) => {
  switch (period) {
    case "year":
      return momentNow().clone().subtract(365, "days").toDate();
    case "month":
      return momentNow().clone().subtract(730, "hours").toDate(); // 24 * (365/12) == 730
    default:
      return momentNow()
        .clone()
        .subtract(1, period as moment.DurationInputArg2)
        .toDate();
  }
};

const calculatePrice = async (args: ICalculatePriceArgs): Promise<IPriceListWithRegion[]> => {
  const start = getStart(args.period);
  const end = momentNow().clone().toDate();
  const gb = 1024 * 1024 * 1024;
  const show_fixed_vars = args.show_fixed_vars;
  const show_usage_vars = args.show_usage_vars;
  let total_agent_disk_size = 0;
  let total_agent_disk_count = 0;
  let total_agent_memory_size = 0;
  let total_coordinator_memory_size = 0;
  let total_dbserver_memory_size = 0;
  let total_dbserver_disk_size = 0;
  let total_dbserver_disk_count = 0;

  if (args.model == "sharded" || args.model == "oneshard" || args.model == "developer") {
    const sizeReq: ApiCalculateDeploymentSizeRequest = {
      model: args.model,
      node_size_id: args.node_size_id,
      node_count: args.node_count,
      node_disk_size: args.disk_size,
      region_id: args.region_id,
    };
    const deplSize = await apiClients.authenticationOnly.dataClient.CalculateDeploymentSize(sizeReq);
    total_agent_disk_size = (deplSize.agent_disk_size || 0) * (deplSize.agents || 0);
    total_agent_disk_count = deplSize.agents || 0;
    total_agent_memory_size = (deplSize.agent_memory_size || 0) * (deplSize.agents || 0);
    total_coordinator_memory_size = (deplSize.coordinator_memory_size || 0) * (deplSize.coordinators || 0);
    total_dbserver_memory_size = (deplSize.dbserver_memory_size || 0) * (deplSize.dbservers || 0);
    total_dbserver_disk_size = (deplSize.dbserver_disk_size || 0) * (deplSize.dbservers || 0);
    total_dbserver_disk_count = deplSize.dbservers || 0;
  }

  let disk_size = args.disk_size;
  if (args.honor_min_node_disk_size) {
    const region = await apiClients.idashboardClient.GetRegionDetails({ id: args.region_id });
    const minNodeDiskSize = region.minNodeDiskSize || 0;
    if (minNodeDiskSize > disk_size) {
      disk_size = minNodeDiskSize;
    }
  }
  let addon_ids = [];
  if (args.addon_iamprovider) {
    addon_ids.push("iamprovider");
  }
  if (args.addon_auditlog) {
    addon_ids.push("auditlog");
  }
  if (args.addon_privateendpointservice) {
    addon_ids.push("privateendpointservice");
  }
  const item: ApiPriceRequest_Item = {
    range: {
      start: start,
      end: end,
    },
    addon_ids: addon_ids,
    agents_memory_gb: show_fixed_vars ? total_agent_memory_size : undefined,
    coordinators_memory_gb: show_fixed_vars ? total_coordinator_memory_size : undefined,
    dbservers_memory_gb: show_fixed_vars ? total_dbserver_memory_size : undefined,
    agents_disk_gb: show_fixed_vars ? total_agent_disk_size : undefined,
    agents_disk_count: show_fixed_vars ? total_agent_disk_count : undefined,
    dbservers_disk_gb: show_fixed_vars ? total_dbserver_disk_size : undefined,
    dbservers_disk_count: show_fixed_vars ? total_dbserver_disk_count : undefined,
    disk_performance_id: show_fixed_vars ? args.disk_performance_id : undefined,
    node_size_id: show_fixed_vars ? args.node_size_id : undefined,
    backup_object_storage_b: show_usage_vars ? args.backup_object_storage_size * gb : undefined,
    network_egress_traffic_b: show_usage_vars ? args.network_egress_internet * gb : undefined,
    network_ingress_traffic_b: show_usage_vars ? args.network_ingress_internet * gb : undefined,
    network_egress_cluster_traffic_b: show_usage_vars ? args.network_egress_in_cluster * gb : undefined,
    network_ingress_cluster_traffic_b: show_usage_vars ? args.network_ingress_in_cluster * gb : undefined,
    network_egress_private_endpoint_traffic_b: show_usage_vars ? args.network_egress_private_endpoint * gb : undefined,
    network_ingress_private_endpoint_traffic_b: show_usage_vars ? args.network_ingress_private_endpoint * gb : undefined,
    auditlog_object_storage_b: show_usage_vars ? args.auditlog_object_storage_size * gb : undefined,
    https_post_invocations: show_usage_vars ? args.auditlog_https_post_invocations * 1000 : undefined,
    https_post_body_b: show_usage_vars ? args.auditlog_https_post_body_size * gb : undefined,
  };
  const opts: ApiPriceRequest = {
    plan_id: args.plan_id,
    separate_components: true,
    exclude_humanize: true,
    exclude_free_limit: true,
    support_plan_id: args.support_plan_id,
    cloud_provider_id: args.provider_id,
    cloud_region_id: args.region_id,
    currency_id: args.currency_id,
    model: args.model,
    items: [item],
  };
  const priceList = await apiClients.idashboardClient.CalculatePrice(opts);
  return [
    {
      provider: args.provider_id,
      region: args.region_id,
      model: opts.model || "",
      node_size_id: item.node_size_id || "",
      node_disk_size: disk_size || 0,
      total_disk_size: (item.agents_disk_gb || 0) + (item.dbservers_disk_gb || 0),
      total_memory_size: (item.agents_memory_gb || 0) + (item.coordinators_memory_gb || 0) + (item.dbservers_memory_gb || 0),
      priceList: priceList,
    },
  ];
};

const calculatePriceListsForAllRegions = async (
  args: ICalculatePriceArgs,
  calculator: (args: ICalculatePriceArgs) => Promise<IPriceListWithRegion[]>
): Promise<IPriceListWithRegion[]> => {
  const regionReq: ApiListRegionsRequest = {
    provider_id: args.provider_id,
    organization_id: SupportOrganizationID,
  };
  const regions = await apiClients.authenticationOnly.platformClient.ListRegions(regionReq);
  const priceLists = await Promise.all(
    _.map(regions.items || [], async (r) => {
      const regionID = r.id || "";
      const req = _.clone(args);
      req.region_id = regionID;
      return await calculator(req);
    })
  );
  return _.flatten(priceLists);
};

const calculatePriceListsForAllProviders = async (
  args: ICalculatePriceArgs,
  calculator: (args: ICalculatePriceArgs) => Promise<IPriceListWithRegion[]>
): Promise<IPriceListWithRegion[]> => {
  const provReq: ApiListProvidersRequest = {
    organization_id: SupportOrganizationID,
  };
  const providers = await apiClients.authenticationOnly.platformClient.ListProviders(provReq);
  const priceLists = await Promise.all(
    _.map(providers.items || [], async (r) => {
      const providerID = r.id || "";
      const req = _.clone(args);
      req.provider_id = providerID;
      return await calculatePriceListsForAllRegions(req, calculator);
    })
  );
  return _.flatten(priceLists);
};

export const CalculatePriceListsForAllProviders = async (args: ICalculatePriceArgs): Promise<IPriceListWithRegion[]> => {
  return calculatePriceListsForAllProviders(args, calculatePrice);
};

const calculatePriceListsForAllNodeSizes = async (
  args: ICalculatePriceArgs,
  calculator: (args: ICalculatePriceArgs) => Promise<IPriceListWithRegion[]>
): Promise<IPriceListWithRegion[]> => {
  const nodeSizes = await apiClients.idashboardClient.ListAllNodeSizes();
  const priceLists = await Promise.all(
    _.map(nodeSizes.items || [], async (ns) => {
      const req = _.clone(args);
      req.node_size_id = ns.id || "";
      req.memory_size = ns.memory_size || 0;
      req.disk_size = ns.min_disk_size || 0;
      return await calculator(req);
    })
  );
  return _.flatten(priceLists);
};

export const CalculatePriceListsForAllNodeSizes = async (args: ICalculatePriceArgs): Promise<IPriceListWithRegion[]> => {
  return calculatePriceListsForAllNodeSizes(args, calculatePrice);
};

export const CalculatePriceListsForAll = async (args: ICalculatePriceArgs): Promise<IPriceListWithRegion[]> => {
  const priceLists = await calculatePriceListsForAllNodeSizes(args, (x) => calculatePriceListsForAllProviders(x, calculatePrice));
  const result = _.flatten(priceLists);
  console.log(result);
  return result;
};
