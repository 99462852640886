//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import _ from "lodash";
import { PriceList as ApiPriceList } from "../../api/lib";
import { IPriceListWithRegion } from "./Calculator";

interface IFormatPriceListArgs {
  model: string;
  node_size_id: string;
  node_count: number;
  region_id: string;
  disk_size: number;
  disk_performance_id: string;
  support_plan_id: string;
  addon_iamprovider: boolean;
  addon_auditlog: boolean;
  addon_privateendpointservice: boolean;
}

const formatCsv = (rows: any[], includeHeader: boolean) => {
  const separator = ",";
  const keys = Object.keys(rows[0]);
  let prefix = "";
  if (includeHeader) {
    prefix = keys.join(",") + "\n";
  }
  const csvContent = rows
    .map((row) => {
      return keys
        .map((k) => {
          let cell = row[k] === null || row[k] === undefined ? "" : row[k];
          cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator);
    })
    .join("\n");
  return prefix + csvContent;
};

export const calculateFixedPrice = (priceList: ApiPriceList) => {
  const priceListItems = priceList.items || [];
  return _.sum(_.map(priceListItems, (x) => (!x.is_usage ? x.price || 0 : 0)));
};

export const calculateUsagePrice = (priceList: ApiPriceList) => {
  const priceListItems = priceList.items || [];
  return _.sum(_.map(priceListItems, (x) => (x.is_usage ? x.price || 0 : 0)));
};

interface ICSVRow {
  description: string;
  value: number | string;
  currency?: string;
}

export const FormatPriceListAsCSV = (args: IFormatPriceListArgs, priceList: ApiPriceList, currencySign: string) => {
  const priceListItems = priceList.items || [];
  // Ensure required items
  if (!_.find(priceListItems, (x) => x.description == "support-plan")) {
    priceListItems.push({
      description: "support-plan",
      price: 0,
    });
  }
  const priceRows = priceListItems.map((x, i) => {
    const result: ICSVRow = {
      description: x.description || "-",
      value: x.price || 0,
      currency: currencySign,
    };
    return result;
  });
  const hdrRows = [
    {
      description: "description",
      value: "value",
      currency: "currency",
    },
    {
      description: "region",
      value: args.region_id,
    },
    {
      description: "model",
      value: args.model,
    },
    {
      description: "node-size-id",
      value: args.node_size_id,
    },
    {
      description: "node-disk-size",
      value: args.disk_size,
    },
    {
      description: "node-disk-performance-id",
      value: args.disk_performance_id,
    },
    {
      description: "node-count",
      value: args.node_count,
    },
    {
      description: "support-plan-id",
      value: args.support_plan_id,
    },
    {
      description: "addon-auditlog",
      value: args.addon_auditlog,
    },
    {
      description: "addon-iamprovider",
      value: args.addon_iamprovider,
    },
    {
      description: "addon-privateendpointservice",
      value: args.addon_privateendpointservice,
    },
  ] as ICSVRow[];
  const totalRows = [
    {
      description: "sub-total-fixed",
      value: calculateFixedPrice(priceList),
      currency: currencySign,
    },
    {
      description: "sub-total-usage",
      value: calculateUsagePrice(priceList),
      currency: currencySign,
    },
    {
      description: "total",
      value: priceList.total_price || 0,
      currency: currencySign,
    },
  ] as ICSVRow[];
  const rows = _.concat(hdrRows, priceRows, totalRows);
  return formatCsv(rows, false);
};

export const FormatAllRegionsPriceListAsCSV = (priceLists: IPriceListWithRegion[]) => {
  const priceListRows = _.flatten(
    _.map(priceLists, (entry) => {
      const priceListItems = entry.priceList.items || [];
      const result = {
        provider: entry.provider,
        region: entry.region,
        mode: entry.model,
        "node-size-id": entry.node_size_id,
        "node-disk-size": entry.node_disk_size,
        "total-disk-size": entry.total_disk_size,
        "total-memory-size": entry.total_memory_size,
        "total-price": entry.priceList.total_price || 0,
        "sub-total-fixed": calculateFixedPrice(entry.priceList),
        "sub-total-usage": calculateUsagePrice(entry.priceList),
      } as any;
      priceListItems.forEach((x) => {
        result[x.description || ""] = x.price || 0;
      });
      return result;
    })
  );
  return formatCsv(priceListRows, true);
};
