//
// DISCLAIMER
//
// Copyright 2022-2024 ArangoDB GmbH, Cologne, Germany
//

import { useEffect, useState } from "react";
import { Icon, Loader, Popup, Table } from "semantic-ui-react";
import { INotebookTableViewArgs } from "./types";
import { CPUUsageView } from "../../status/CpuUsageView";
import { GPUUsageView } from "../../status/GpuUsageView";
import { DataVolumeView } from "../../status/DataVolumeView";
import { MemoryUsageView } from "../../status/MemoryUsageView";
import { DateTimePopupWithUTCAndLocalTime } from "../../../util/dateAndTimeUtils/DateTime";
import UserCache, { UserWithDefinedID } from "../../../util/UserCache";
import { NotebookInfo } from "../../../api/notebook/v1/inotebook";
import { Finalizers } from "../../../ui/_finalizers";
import { TextLink } from "../../../ui/_textlink";
import { Routes } from "../../../routes";
import { CustomNotebookImage } from "../CustomNotebookImage";
import { NotebookMaintenance } from "../NotebookMaintenance";

const RowView = ({
  notebookInfo,
  hideDeploymentID,
  canUpdateNotebookImage,
  canUpdateMaintenanceMode,
  onSaveNotebookMaintenanceMode,
}: {
  notebookInfo: NotebookInfo;
  hideDeploymentID?: boolean;
  canUpdateNotebookImage?: boolean;
  canUpdateMaintenanceMode?: boolean;
  onSaveNotebookMaintenanceMode: (notebookID: string | undefined, maintenanceMode: boolean) => void;
}) => {
  const { notebook = {}, custom_notebook_image: notebookImage } = notebookInfo;
  const [user, setUser] = useState<UserWithDefinedID>();
  const [loading, setLoading] = useState(false);

  const getUser = async () => {
    setLoading(true);
    const cache = new UserCache();
    try {
      const user = await cache.getUser(notebook.created_by_id || "");
      setUser(user);
      setLoading(false);
    } catch (err) {
      setUser({
        name: "-",
      } as UserWithDefinedID);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, [notebookInfo]);

  return (
    <Table.Row key={notebook.id}>
      <Table.Cell>{notebook.id}</Table.Cell>
      <Table.Cell>
        <Popup trigger={<span>{notebook.name}</span>} content={<span>{notebook.description}</span>} />
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime label="Created At" dateTime={notebook.created_at} />
      </Table.Cell>
      <Table.Cell>{notebook.model ? notebook.model.notebook_model_id || "?" : "-"}</Table.Cell>
      <Table.Cell>{notebook.ml_enabled ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>
        {notebook && notebook.status && notebook.status.endpoint ? (
          <>
            <TextLink onClick={() => window.open((notebook.status || {}).endpoint)} label="Open Notebook" /> <Icon name="external" />
          </>
        ) : (
          "-"
        )}
      </Table.Cell>
      <Table.Cell>{notebook.is_paused ? <DateTimePopupWithUTCAndLocalTime label="Created At" dateTime={notebook.last_paused_at} /> : "No"}</Table.Cell>
      <Table.Cell>{(notebook.status || {}).phase || "-"}</Table.Cell>
      <Table.Cell>
        <MemoryUsageView
          limit={((notebook.status || {}).usage || {}).last_memory_limit || 0}
          used={((notebook.status || {}).usage || {}).last_memory_usage || 0}
        />
      </Table.Cell>
      <Table.Cell>
        <CPUUsageView limit={((notebook.status || {}).usage || {}).last_cpu_limit || 0} used={((notebook.status || {}).usage || {}).last_cpu_usage || 0} />
      </Table.Cell>
      <Table.Cell>
        <GPUUsageView limit={((notebook.status || {}).usage || {}).last_gpu_limit || 0} used={((notebook.status || {}).usage || {}).last_gpu_usage || 0} />
      </Table.Cell>
      <Table.Cell>
        <DataVolumeView data_volume_info={((notebook.status || {}).usage || {}).data_volume_info} />
      </Table.Cell>
      <Table.Cell>{loading ? <Loader inline active size="mini" /> : (user || {}).name}</Table.Cell>
      <Table.Cell>
        <Finalizers finalizers={notebookInfo.finalizers || []} />
      </Table.Cell>
      {canUpdateNotebookImage && (
        <Table.Cell>
          <CustomNotebookImage notebookID={notebook.id} customImageName={notebookImage} />
        </Table.Cell>
      )}
      {canUpdateMaintenanceMode && (
        <Table.Cell>
          <NotebookMaintenance
            notebookID={notebook.id}
            onSaveMaintenanceMode={onSaveNotebookMaintenanceMode}
            currentMaintenanceMode={notebookInfo.maintenance_mode}
          />
        </Table.Cell>
      )}
      {!hideDeploymentID && (
        <Table.Cell>
          <TextLink label={notebook.deployment_id} href={Routes.dashboard_support_deployment_detailsWithId(notebook.deployment_id || "")} />
        </Table.Cell>
      )}
      <Table.Cell>
        <Table.Cell>{notebook.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={notebook.deleted_at} label="Deleted at" /> : ""}</Table.Cell>
      </Table.Cell>
    </Table.Row>
  );
};

const NotebookTableView = ({
  notebookInfo,
  hideDeploymentID,
  canUpdateNotebookImage,
  canUpdateNotebookMaintenanceMode,
  onSaveNotebookMaintenanceMode,
}: INotebookTableViewArgs) => {
  return (
    <>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Model</Table.HeaderCell>
            <Table.HeaderCell>ML enabled</Table.HeaderCell>
            <Table.HeaderCell>Endpoint</Table.HeaderCell>
            <Table.HeaderCell>Paused</Table.HeaderCell>
            <Table.HeaderCell>Phase</Table.HeaderCell>
            <Table.HeaderCell>Memory</Table.HeaderCell>
            <Table.HeaderCell>CPU</Table.HeaderCell>
            <Table.HeaderCell>GPU</Table.HeaderCell>
            <Table.HeaderCell>Disk Used</Table.HeaderCell>
            <Table.HeaderCell>Created By</Table.HeaderCell>
            <Table.HeaderCell>Finalizers</Table.HeaderCell>
            {canUpdateNotebookImage && <Table.HeaderCell>Notebook image</Table.HeaderCell>}
            {canUpdateNotebookMaintenanceMode && <Table.HeaderCell>Maintenance Mode</Table.HeaderCell>}
            {!hideDeploymentID && <Table.HeaderCell>Deployment ID</Table.HeaderCell>}
            <Table.HeaderCell>Deleted</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {notebookInfo.map((notebook) => {
            return (
              <RowView
                onSaveNotebookMaintenanceMode={onSaveNotebookMaintenanceMode}
                canUpdateNotebookImage={canUpdateNotebookImage}
                canUpdateMaintenanceMode={canUpdateNotebookMaintenanceMode}
                notebookInfo={notebook}
                hideDeploymentID={hideDeploymentID}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default NotebookTableView;
