//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React from "react";
import { Button, Form, Icon, TextArea } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  SetOrganizationCustomImageFilterRequest as ApiSetOrganizationCustomImageFilterRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface ICustomImageFiltersFieldViewArgs {
  organization: ApiOrganization;
  canSetCustomImageFilters: boolean;
  editingCustomImageFilters: boolean;
  onSaveOrganizationCustomImageFilter: () => void;
  onEditCustomImageFilters: () => void;
  onCancelEditCustomImageFilters: () => void;
  onChangeCustomImageFilters: (filter: string[]) => void;
  customImageFilters?: string[];
}

const CustomImageFiltersFieldView = ({ ...args }: ICustomImageFiltersFieldViewArgs) => {
  const has_custom_image_filter = !!args.customImageFilters;
  const is_custom_image_allowed = !!args.organization.is_allowed_to_use_custom_images;
  const customImageFilters = args.customImageFilters || [];
  const textToSplitedLines = (v: string) => {
    return _.isEmpty(v) ? [] : _.split(v, "\n");
  };
  return (
    <div>
      {is_custom_image_allowed && (
        <Field>
          <FL>Custom Image Filter</FL>
          <FC>
            {args.editingCustomImageFilters && (
              <div>
                <Form.Field>
                  <label>One filter per row</label>
                  <TextArea
                    key="agents"
                    rows="10"
                    value={customImageFilters.join("\n")}
                    onChange={(e, d) => args.onChangeCustomImageFilters(textToSplitedLines(d.value as string))}
                  />
                  <Button.Group icon basic size="small">
                    <Button icon="check" onClick={args.onSaveOrganizationCustomImageFilter} />
                    <Button icon="cancel" onClick={args.onCancelEditCustomImageFilters} />
                  </Button.Group>
                </Form.Field>
              </div>
            )}
            {!args.editingCustomImageFilters && (
              <div>
                {has_custom_image_filter && <span>{customImageFilters || "-"}</span>}
                {!has_custom_image_filter && <span>-</span>}
                {args.canSetCustomImageFilters && <Icon name="pencil" onClick={args.onEditCustomImageFilters} className="edit-pencil" />}
              </div>
            )}
          </FC>
        </Field>
      )}
    </div>
  );
};

interface ICustomImageFiltersFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface ICustomImageFiltersFieldState extends IOrganizationDetailsFieldState {
  editingCustomImageFilters: boolean;
  customImageFilters?: string[];
  commentPrompt: ICommentPromptStateForIntegrations;
}

class CustomImageFiltersField extends OrganizationDetailsField<ICustomImageFiltersFieldProps, ICustomImageFiltersFieldState> {
  state: ICustomImageFiltersFieldState = {
    editingCustomImageFilters: false,
    customImageFilters: undefined,
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      const organizationInfo = await apiClients.idashboardClient.GetOrganizationInfo(idOptions);
      this.setState({
        customImageFilters: organizationInfo.custom_image_filters,
      });
    }
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  onSaveOrganizationCustomImageFilter = async () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Updated custom image filters`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization custom image filters, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationCustomImageFilterRequest = {
            organization_id: this.props.organization.id,
            filters: this.state.customImageFilters,
          };
          await apiClients.idashboardClient.SetOrganizationCustomImageFilter(req);
          this.refreshOrganizationInfo();
          this.setState({ editingCustomImageFilters: false });
        },
      },
    });
  };

  onChangeCustomImageFilters = (filters: string[]) => {
    this.setState({ customImageFilters: filters });
  };

  render() {
    const can_set_custom_image_filters = this.hasPermission("internal-dashboard.organization.set-custom-image-filter");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <CustomImageFiltersFieldView
          {...this}
          {...this.props}
          {...this.state}
          canSetCustomImageFilters={can_set_custom_image_filters}
          onCancelEditCustomImageFilters={() => this.setState({ editingCustomImageFilters: false })}
          onEditCustomImageFilters={() => this.setState({ editingCustomImageFilters: true })}
        />
      </div>
    );
  }
}

export default withRefresh()(CustomImageFiltersField);
