//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _, { orderBy } from "lodash";
import { Moment } from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Accordion, Button, Dropdown, Loader, Icon, Menu, Message, Placeholder, Segment, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { CustomerChanges as ApiCustomerChanges, GetCustomerChangesRequest as ApiGetCustomerChangesRequest } from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  CreationDateFilter,
  ErrorMessage,
  MonthAndWeekAndYearCreationDateFilters,
  SecondaryMenu,
  momentDefaultFormat,
  momentNow,
  momentStartOfProject,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import OrganizationCache, { OrganizationName } from "../../util/OrganizationCache";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CreateReportButtonView from "./CreateReportButton";

const dateFilters = MonthAndWeekAndYearCreationDateFilters(false);

interface IOrganizationListViewArgs extends IWithRefreshProps {
  organization_ids: string[];
  organizationCache: OrganizationCache;
  onOrganizationSelected: (id: string) => void;
}

const OrganizationListView = ({ ...args }: IOrganizationListViewArgs) => {
  const ids = args.organization_ids.join(", ");
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Organization ID</Table.HeaderCell>
        <Table.HeaderCell>Organization Name</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {args.organization_ids.map((id) => (
          <Table.Row key={`row-${id}`}>
            <Table.Cell>{id}</Table.Cell>
            <Table.Cell>
              <OrganizationName cache={args.organizationCache} organizationId={id} onClick={() => args.onOrganizationSelected(id)} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <CopyToClipboard text={ids}>
          <Button positive icon="copy" labelPosition="right" content="Copy to clipboard" />
        </CopyToClipboard>
      </Table.Footer>
    </Table>
  );
};

interface IOrganizationListModalViewArgs extends IOrganizationListViewArgs {
  count: number;
}

const OrganizationListModalView = ({ ...args }: IOrganizationListModalViewArgs) => {
  const [open, setOpen] = React.useState(false);
  if (!args.count) {
    return <span>0</span>;
  }
  return (
    <Accordion>
      <Accordion.Title active={open} onClick={() => setOpen(!open)}>
        <span>{args.count}</span>
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={open}>
        <OrganizationListView {...args} />
      </Accordion.Content>
    </Accordion>
  );
};

interface IFilterViewArgs {
  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Date: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
      <Menu.Item position="right">
        <CreateReportButtonView include_customer_changes />
      </Menu.Item>
    </Menu>
  );
};

interface IStatisticsGroupViewArgs extends IWithRefreshProps {
  report: IChangesReport;
  organizationCache: OrganizationCache;
  onOrganizationSelected: (id: string) => void;
}

const StatisticsGroupsView = ({ ...args }: IStatisticsGroupViewArgs) => {
  const st = args.report.changes;
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>{args.report.tier_id}</Table.Cell>
      <Table.Cell>
        <OrganizationListModalView {...args} count={st.new_count || 0} organization_ids={st.new_organization_ids || []} />
      </Table.Cell>
      <Table.Cell>
        <OrganizationListModalView {...args} count={st.churned_count || 0} organization_ids={st.churned_organization_ids || []} />
      </Table.Cell>
      <Table.Cell>
        <OrganizationListModalView {...args} count={st.upgraded_count || 0} organization_ids={st.upgraded_organization_ids || []} />
      </Table.Cell>
      <Table.Cell>
        <OrganizationListModalView {...args} count={st.active_count || 0} organization_ids={st.active_organization_ids || []} />
      </Table.Cell>
    </Table.Row>
  );
};

interface IStatisticsViewArgs extends IWithRefreshProps {
  expectedReports: number;
  reports: IChangesReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
  organizationCache: OrganizationCache;
  onOrganizationSelected: (id: string) => void;
}

const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";
  const loading = args.expectedReports > args.reports.length;
  return (
    <div>
      <Message>
        Customer changes {timeFilter}.{loading && <Loader inline active size="mini" />}
      </Message>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tier</Table.HeaderCell>
            <Table.HeaderCell>New customer</Table.HeaderCell>
            <Table.HeaderCell>Churned customer</Table.HeaderCell>
            <Table.HeaderCell>Upgraded customer</Table.HeaderCell>
            <Table.HeaderCell>Active customer</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {args.reports.map((x, i) => (
            <StatisticsGroupsView {...args} key={`group-${i}`} report={x} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const PlaceholderView = () => (
  <Segment>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

// Interface describing the invoice list view arguments
export interface IReportingViewArgs extends IWithRefreshProps, IFilterViewArgs, RouteComponentProps {
  expectedReports: number;
  reports?: IChangesReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
  organizationCache: OrganizationCache;
  onOrganizationSelected: (id: string) => void;
}

export const ReportingView = ({ ...args }: IReportingViewArgs) => {
  const reports = args.reports || [];
  const has_reports = !_.isEmpty(reports);
  return (
    <div>
      <FilterView {...args} />
      {has_reports && <StatisticsView {...args} reports={reports} />}
      {!has_reports && <PlaceholderView />}
    </div>
  );
};

interface ICustomerChangesProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  onOrganizationSelected: (id: string) => void;
}

interface IChangesReport {
  tier_id: string;
  changes: ApiCustomerChanges;
}

interface ICustomerChangesState {
  sequence: number;
  errorMessage?: string;
  reports: Map<string, IChangesReport>;
  tierCount: number;
  filterFrom?: Moment;
  filterTo?: Moment;
  selectedCreationDateFilterID: string;
  organizationCache: OrganizationCache;
}

// The component to show revenue reporting.
class CustomerChanges extends Component<ICustomerChangesProps, ICustomerChangesState> {
  state: ICustomerChangesState = {
    sequence: 0,
    errorMessage: undefined,
    filterFrom: undefined,
    filterTo: undefined,
    tierCount: 0,
    reports: new Map<string, IChangesReport>(),
    selectedCreationDateFilterID: dateFilters[0].id,
    organizationCache: new OrganizationCache(),
  };

  reloadChanges = async () => {
    this.setState(
      (old) => {
        return {
          sequence: old.sequence + 1,
          reports: new Map<string, IChangesReport>(),
        };
      },
      async () => {
        const self = this;
        const sequence = this.state.sequence;
        let filterFrom = momentStartOfProject();
        let filterTo = momentNow();

        const f = dateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
        if (f) {
          if (f.filterStart) {
            filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          }
          if (f.filterEnd) {
            filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          }
        }

        // List all tiers
        const tiersList = await apiClients.idashboardClient.ListTiers({});
        const tiers = tiersList.items || [];

        // Load report per tier
        tiers.forEach(async (x) => {
          const id = x.id || "";
          const report = await self.loadChanges(id, filterFrom, filterTo);
          self.setState((old) => {
            if (old.sequence != sequence) return { reports: old.reports };
            const newReports = new Map(old.reports);
            newReports.set(id, report);
            return { reports: newReports };
          });
        });

        this.setState({
          filterFrom: filterFrom,
          filterTo: filterTo,
          tierCount: tiers.length,
        });
      }
    );
  };

  loadChanges = async (tier_id: string, filterFrom: Moment, filterTo: Moment) => {
    const req = {
      tier_id: tier_id,
      from: filterFrom.toDate(),
      to: filterTo.toDate(),
    } as ApiGetCustomerChangesRequest;

    const changes = await new Promise<ApiCustomerChanges>(async (resolve, reject) => {
      try {
        await apiClients.idashboardClient.GetCustomerChanges(req, (x) => {
          if (!!x.message) resolve(x.message);
          else if (!!x.error) {
            reject(x.error);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
    const result: IChangesReport = {
      tier_id: tier_id,
      changes: changes,
    };
    return result;
  };

  refreshChanges = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadChanges);
  };

  componentDidMount() {
    this.refreshChanges();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState(
      {
        selectedCreationDateFilterID: id,
        reports: new Map<string, IChangesReport>(),
      },
      this.refreshChanges
    );
  };

  render() {
    const reports = orderBy(Array.from(this.state.reports.values()), (report) => report.tier_id);
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Customer changes</Menu.Item>
        </SecondaryMenu>
        <ReportingView
          {...this.props}
          {...this.state}
          expectedReports={this.state.tierCount}
          reports={_.isEmpty(reports) ? undefined : reports}
          creationDateFilters={dateFilters}
          onCreationDateFilterChanged={this.onCreationDateFilterChanged}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(CustomerChanges);
