//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import styled from "@emotion/styled";
import _ from "lodash";
import { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RouteComponentProps } from "react-router-dom";
import { Button, Checkbox, Dropdown, Form, Grid, Icon, Loader, Message, Modal, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  CurrencyList as ApiCurrencyList,
  IDOptions as ApiIDOptions,
  ListOptions as ApiListOptions,
  NodeSizeList as ApiNodeSizeList,
  NodeSizesRequest as ApiNodeSizesRequest,
  Plan as ApiPlan,
  PriceList as ApiPriceList,
} from "../../api/lib";
import {
  CurrencyInput,
  DiskPerformanceInput,
  ModelInput,
  NodeSizeInput,
  NumberInput,
  ProviderInput,
  RegionInput,
  Section,
  SectionAddons,
  SectionContent,
  SectionHead,
  SectionHeader,
  SupportPlanInput,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { CalculatePrice, CalculatePriceListsForAll, CalculatePriceListsForAllNodeSizes, CalculatePriceListsForAllProviders } from "./Calculator";
import { calculateFixedPrice, calculateUsagePrice, FormatAllRegionsPriceListAsCSV, FormatPriceListAsCSV } from "./PriceExport";

const StyledForm = styled(Form)`
  .section {
    margin-bottom: 0 !important;
  }
`;

const FloatedIcon = styled(Icon)`
  float: right;
`;

interface ICalculatorViewArgs extends IWithRefreshProps, RouteComponentProps {
  loading: boolean;
  model: string;
  onModelChange: (value: string) => void;
  node_size_id: string;
  onNodeSizeChange: (id: string) => void;
  node_count: number;
  onNodeCountChange: (value: number) => void;
  currency_id: string;
  onCurrencyChange: (id: string) => void;
  provider_id: string;
  onCloudProviderChange: (id: string) => void;
  region_id: string;
  onCloudRegionChange: (id: string) => void;
  memory_size: number;
  onMemorySizeChange: (value: number) => void;
  disk_size: number;
  min_disk_size?: number;
  max_disk_size?: number;
  disk_sizes?: number[];
  onDiskSizeChange: (value: number) => void;
  disk_performance_id: string;
  onDiskPerformanceChange: (id: string) => void;
  backup_object_storage_size: number;
  onBackupObjectStorageSizeChange: (value: number) => void;
  network_egress_internet: number;
  onNetworkEgressInternetChange: (value: number) => void;
  network_ingress_internet: number;
  onNetworkIngressInternetChange: (value: number) => void;
  network_egress_in_cluster: number;
  onNetworkEgressInClusterChange: (value: number) => void;
  network_ingress_in_cluster: number;
  onNetworkIngressInClusterChange: (value: number) => void;
  network_egress_private_endpoint: number;
  onNetworkEgressPrivateEndpointChange: (value: number) => void;
  network_ingress_private_endpoint: number;
  onNetworkIngressPrivateEndpointChange: (value: number) => void;
  auditlog_object_storage_size: number;
  onAuditLogObjectStorageSizeChange: (value: number) => void;
  auditlog_https_post_invocations: number;
  onAuditlogHttpsPostInvocationsChange: (value: number) => void;
  auditlog_https_post_body_size: number;
  onAuditlogHttpsPostBodySizeChange: (value: number) => void;

  addon_iamprovider: boolean;
  onAddonIAMProviderChange: (value: boolean) => void;
  addon_auditlog: boolean;
  onAddonAuditlogChange: (value: boolean) => void;
  addon_privateendpointservice: boolean;
  onAddonPrivateEndpointServiceChange: (value: boolean) => void;

  period: string;
  onPeriodChange: (value: string) => void;
  support_plan_id: string;
  onSupportPlanChange: (value: string) => void;

  show_fixed_vars: boolean;
  onShowFixedVarsChange: (show: boolean) => void;
  show_usage_vars: boolean;
  onShowUsageVarsChange: (show: boolean) => void;

  currencies?: ApiCurrencyList;
  priceList?: ApiPriceList;

  copiedToClipboard: boolean;
  onCopiedToClipboard: () => void;

  generatingCSV: boolean;
  csvText?: string;
  onCopyAllProvidersCSV: () => void;
  onCopyAllNodeSizesCSV: () => void;
  onCopyAllCSV: () => void;

  honor_min_node_disk_size: boolean;
  onHonorMinNodeDiskSizeChange: (value: boolean) => void;

  pastedInput: string;
  onPastedInputChange: (value: string) => void;
  fillFromPasted: () => void;
}

interface ICSVButtonArgs {
  text: string;
  has_currency: boolean;
  generatingCSV: boolean;
  onClick: () => void;
}
const CSVButton = ({ ...args }: ICSVButtonArgs) => {
  if (args.has_currency) {
    return <Button secondary disabled={args.generatingCSV} icon={"table"} labelPosition="right" content={args.text} onClick={args.onClick} />;
  }
  return <span />;
};

const CalculatorView = ({ ...args }: ICalculatorViewArgs) => {
  const is_flexible = args.model == "flexible";
  const is_oneshard = args.model == "oneshard";
  const is_developer = args.model == "developer";
  const currencies = (args.currencies || {}).items || [];
  const currency = _.find(currencies, (x) => x.id == args.currency_id);
  const has_location = !!args.provider_id && !!args.region_id;
  const has_priceList = args.priceList && args.priceList.items;
  const has_currency = !!currency;
  const priceList = args.priceList || {};
  const priceListItems = priceList.items || [];
  const currencySign = (currency || {}).sign;
  const min_node_count = is_developer ? 1 : 3;
  const max_node_count = is_developer ? 1 : is_oneshard ? 3 : 20;
  const fixed_disk_sizes = !_.isEmpty(args.disk_sizes);

  return (
    <StyledForm>
      <Section>
        <SectionHead>
          <SectionHeader title="Autofill form" />
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row>
              <Grid.Column width="16">
                <Form.TextArea rows="9" onChange={(e, d) => args.onPastedInputChange(d.value as string)} />
                <Button onClick={args.fillFromPasted} primary>
                  Fill Details
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Location" />
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>Provider</label>
                  <ProviderInput {...args} placeholder="cloud provider" id={args.provider_id} onChange={args.onCloudProviderChange} />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Region</label>
                  <RegionInput {...args} placeholder="cloud region" id={args.region_id} providerId={args.provider_id} onChange={args.onCloudRegionChange} />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Fixed price variables">
            <SectionAddons>
              <Checkbox label="Enable" checked={args.show_fixed_vars} toggle onClick={() => args.onShowFixedVarsChange(!args.show_fixed_vars)} />
            </SectionAddons>
          </SectionHeader>
        </SectionHead>
        <SectionContent>
          {args.show_fixed_vars && (
            <Grid>
              <Grid.Row>
                <Grid.Column width="8">
                  <Form.Field required>
                    <label>Model</label>
                    <ModelInput {...args} placeholder="model" id={args.model} onChange={args.onModelChange} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="8">
                  <Form.Field required>
                    <label>Support plan</label>
                    <SupportPlanInput {...args} placeholder="support plan" id={args.support_plan_id} onChange={args.onSupportPlanChange} />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {!is_flexible && (
                  <Grid.Column width="4">
                    <Form.Field>
                      <label>Node size</label>
                      <NodeSizeInput {...args} placeholder="node size" id={args.node_size_id} onChange={args.onNodeSizeChange} />
                    </Form.Field>
                  </Grid.Column>
                )}
                {!is_flexible && (
                  <Grid.Column width="4">
                    <Form.Field>
                      <label>Node count</label>
                      <NumberInput
                        min={min_node_count}
                        max={max_node_count}
                        disabled={is_oneshard || is_developer}
                        value={args.node_count}
                        onChange={args.onNodeCountChange}
                      />
                    </Form.Field>
                  </Grid.Column>
                )}
                <Grid.Column width="3">
                  <Form.Field>
                    {is_flexible && <label>Total memory size</label>}
                    {!is_flexible && <label>Node memory size</label>}
                    <NumberInput
                      min={is_flexible ? 8 : args.memory_size}
                      max={is_flexible ? 8192 : args.memory_size}
                      disabled={is_oneshard}
                      value={args.memory_size}
                      onChange={args.onMemorySizeChange}
                      ext=" GiB"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="3">
                  <Form.Field>
                    {is_flexible && <label>Total disk size</label>}
                    {!is_flexible && <label>Node disk size</label>}
                    {fixed_disk_sizes && (
                      <Dropdown
                        fluid
                        selection
                        options={_.map(args.disk_sizes, (x: number) => {
                          return {
                            key: `sz${x}`,
                            text: `${x} GiB`,
                            value: x,
                          };
                        })}
                        onChange={(e, d) => args.onDiskSizeChange(d.value as number)}
                        value={args.disk_size}
                      />
                    )}
                    {!fixed_disk_sizes && (
                      <NumberInput
                        min={args.min_disk_size || 8}
                        max={args.max_disk_size || 4194304}
                        value={args.disk_size}
                        onChange={args.onDiskSizeChange}
                        ext=" GiB"
                      />
                    )}
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="2">
                  <Form.Field>
                    <label>Honor min node disk size</label>
                    <Checkbox checked={args.honor_min_node_disk_size} onChange={(e, d) => args.onHonorMinNodeDiskSizeChange(!!d.checked)} toggle />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="4">
                  <Checkbox label="Auditlog" checked={args.addon_auditlog} toggle onClick={() => args.onAddonAuditlogChange(!args.addon_auditlog)} />
                </Grid.Column>
                <Grid.Column width="4">
                  <Checkbox
                    label="IAM Provider"
                    checked={args.addon_iamprovider}
                    toggle
                    onClick={() => args.onAddonIAMProviderChange(!args.addon_iamprovider)}
                  />
                </Grid.Column>
                <Grid.Column width="3">
                  <Checkbox
                    label="Private Endpoint"
                    checked={args.addon_privateendpointservice}
                    toggle
                    onClick={() => args.onAddonPrivateEndpointServiceChange(!args.addon_privateendpointservice)}
                  />
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Disk Performance</label>
                    <DiskPerformanceInput
                      {...args}
                      placeholder="disk performance"
                      id={args.disk_performance_id}
                      onChange={args.onDiskPerformanceChange}
                      notAll
                      regionId={args.region_id}
                      nodeSizeId={args.node_size_id}
                      nodeDiskSize={args.disk_size}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Usage price variables">
            <SectionAddons>
              <Checkbox label="Enable" checked={args.show_usage_vars} toggle onClick={() => args.onShowUsageVarsChange(!args.show_usage_vars)} />
            </SectionAddons>
          </SectionHeader>
        </SectionHead>
        <SectionContent>
          {args.show_usage_vars && (
            <Grid>
              <Grid.Row>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Network Traffic Egress (Internet)</label>
                    <NumberInput min={0} max={4194304} value={args.network_egress_internet} onChange={args.onNetworkEgressInternetChange} ext=" GiB" />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Network Traffic Ingress (Internet)</label>
                    <NumberInput min={0} max={4194304} value={args.network_ingress_internet} onChange={args.onNetworkIngressInternetChange} ext=" GiB" />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Total object storage (backup)</label>
                    <NumberInput min={0} max={4194304} value={args.backup_object_storage_size} onChange={args.onBackupObjectStorageSizeChange} ext=" GiB" />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Total HTTPS post invocations (audit-log)</label>
                    <NumberInput
                      min={0}
                      max={4194304}
                      value={args.auditlog_https_post_invocations}
                      onChange={args.onAuditlogHttpsPostInvocationsChange}
                      ext=" k"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Network Traffic Egress (InCluster)</label>
                    <NumberInput min={0} max={4194304} value={args.network_egress_in_cluster} onChange={args.onNetworkEgressInClusterChange} ext=" GiB" />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Network Traffic Ingress (InCluster)</label>
                    <NumberInput min={0} max={4194304} value={args.network_ingress_in_cluster} onChange={args.onNetworkIngressInClusterChange} ext=" GiB" />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Total object storage (audit-log)</label>
                    <NumberInput min={0} max={4194304} value={args.auditlog_object_storage_size} onChange={args.onAuditLogObjectStorageSizeChange} ext=" GiB" />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Total HTTPS post body size (audit-log)</label>
                    <NumberInput
                      min={0}
                      max={4194304}
                      value={args.auditlog_https_post_body_size}
                      onChange={args.onAuditlogHttpsPostBodySizeChange}
                      ext=" GiB"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Network Traffic Egress (PrivateEndpoint)</label>
                    <NumberInput
                      min={0}
                      max={4194304}
                      value={args.network_egress_private_endpoint}
                      onChange={args.onNetworkEgressPrivateEndpointChange}
                      ext=" GiB"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Field>
                    <label>Network Traffic Ingress (PrivateEndpoint)</label>
                    <NumberInput
                      min={0}
                      max={4194304}
                      value={args.network_ingress_private_endpoint}
                      onChange={args.onNetworkIngressPrivateEndpointChange}
                      ext=" GiB"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="8">Network traffic, HTTPS post invocations and HTTPS post body size are not time based.</Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Result" />
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row>
              <Grid.Column width="8">
                <Form.Field>
                  <label>Period</label>
                  <Button.Group>
                    <Button type="button" size="small" active={args.period == "hour"} onClick={() => args.onPeriodChange("hour")}>
                      per hour
                    </Button>
                    <Button type="button" size="small" active={args.period == "day"} onClick={() => args.onPeriodChange("day")}>
                      per day
                    </Button>
                    <Button type="button" size="small" active={args.period == "month"} onClick={() => args.onPeriodChange("month")}>
                      per month
                    </Button>
                    <Button type="button" size="small" active={args.period == "year"} onClick={() => args.onPeriodChange("year")}>
                      per year
                    </Button>
                  </Button.Group>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="8">
                <Form.Field>
                  <label>Currency</label>
                  <CurrencyInput {...args} placeholder="currency" id={args.currency_id} onChange={args.onCurrencyChange} />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {has_location && has_currency && has_priceList && (
              <Grid.Row>
                <Grid.Column width="16">
                  <Form.Field>
                    <label>Price</label>
                    <Table compact="very">
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Grid columns="2">
                              <Grid.Column>
                                {args.show_fixed_vars && (
                                  <Table compact="very">
                                    <Table.Body
                                      children={_.filter(priceListItems, (x) => !x.is_usage).map((x, i) => {
                                        return (
                                          <Table.Row key={`row${i}`} positive={!!x.is_usage}>
                                            <Table.Cell>{x.description || "-"}</Table.Cell>
                                            <Table.Cell collapsing>
                                              {currencySign || ""} {x.price || 0}
                                            </Table.Cell>
                                          </Table.Row>
                                        );
                                      })}
                                    />
                                    <Table.Footer>
                                      <Table.Row>
                                        <Table.HeaderCell>Sub total fixed:</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>
                                          {currencySign || ""} {calculateFixedPrice(priceList)}
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Footer>
                                  </Table>
                                )}
                              </Grid.Column>
                              <Grid.Column>
                                {args.show_usage_vars && (
                                  <Table compact="very">
                                    <Table.Body
                                      children={_.filter(priceListItems, (x) => !!x.is_usage).map((x, i) => {
                                        return (
                                          <Table.Row key={`row${i}`} positive={!!x.is_usage}>
                                            <Table.Cell>{x.description || "-"}</Table.Cell>
                                            <Table.Cell collapsing>
                                              {currencySign || ""} {x.price || 0}
                                            </Table.Cell>
                                          </Table.Row>
                                        );
                                      })}
                                    />
                                    <Table.Footer>
                                      <Table.Row>
                                        <Table.HeaderCell>Sub total usage:</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>
                                          {currencySign || ""} {calculateUsagePrice(priceList)}
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Footer>
                                  </Table>
                                )}
                              </Grid.Column>
                            </Grid>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell textAlign="right">
                            Total:{" "}
                            <b>
                              {currencySign || ""} {priceList.total_price || 0}
                            </b>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column width="16">
                {has_location && has_currency && has_priceList && (
                  <CopyToClipboard
                    text={FormatPriceListAsCSV(args, priceList, currencySign || "")}
                    options={{ format: "text/plain" }}
                    onCopy={args.onCopiedToClipboard}
                  >
                    <Button positive icon={args.copiedToClipboard ? "check" : "copy"} labelPosition="right" content="Copy to clipboard" />
                  </CopyToClipboard>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Bulk">{args.generatingCSV && <Loader active inline size="mini" inverted />}</SectionHeader>
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row>
              <Grid.Column width="16">
                <CSVButton has_currency={has_currency} generatingCSV={args.generatingCSV} text="All regions/providers" onClick={args.onCopyAllProvidersCSV} />
                {has_location && (
                  <CSVButton has_currency={has_currency} generatingCSV={args.generatingCSV} text="All node sizes" onClick={args.onCopyAllNodeSizesCSV} />
                )}
                <CSVButton has_currency={has_currency} generatingCSV={args.generatingCSV} text="ALL node sizes/regions/providers" onClick={args.onCopyAllCSV} />

                {!!args.csvText && <Form.TextArea rows={20}>{args.csvText}</Form.TextArea>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
    </StyledForm>
  );
};

// Interface decribing the properties of the price calculator component
interface IPriceCalculatorProps extends IWithRefreshProps, RouteComponentProps {
  planId: string;
}

// Interface decribing the state of the price calculator component
interface IPriceCalculatorState {
  loading: boolean;
  plan_id: string;
  plan?: ApiPlan;
  priceList?: ApiPriceList;
  nodeSizeList?: ApiNodeSizeList;
  errorMessage?: string;
  provider_id: string;
  region_id: string;
  memory_size: number;
  disk_size: number;
  disk_performance_id: string;
  backup_object_storage_size: number;
  network_egress_internet: number;
  network_ingress_internet: number;
  network_egress_in_cluster: number;
  network_ingress_in_cluster: number;
  network_egress_private_endpoint: number;
  network_ingress_private_endpoint: number;
  auditlog_object_storage_size: number;
  auditlog_https_post_invocations: number;
  auditlog_https_post_body_size: number;
  addon_iamprovider: boolean;
  addon_auditlog: boolean;
  addon_privateendpointservice: boolean;
  period: string;
  support_plan_id: string;
  currency_id: string;
  model: string;
  node_size_id: string;
  node_count: number;
  min_disk_size?: number;
  max_disk_size?: number;
  disk_sizes?: number[];
  currencies?: ApiCurrencyList;
  show_fixed_vars: boolean;
  show_usage_vars: boolean;
  copiedToClipboard: boolean;
  honor_min_node_disk_size: boolean;
  generatingCSV: boolean;
  csvText?: string;
  pastedInput: string;
}

// The component to calculate price for imaginary deployment
class PriceCalculator extends Component<IPriceCalculatorProps, IPriceCalculatorState> {
  state: IPriceCalculatorState = {
    loading: false,
    plan_id: this.props.planId,
    plan: undefined,
    nodeSizeList: undefined,
    priceList: undefined,
    model: "flexible",
    node_size_id: "c4-a4",
    node_count: 3,
    provider_id: "",
    region_id: "",
    memory_size: 96,
    disk_size: 1024,
    disk_performance_id: "",
    backup_object_storage_size: 1,
    network_egress_internet: 1,
    network_ingress_internet: 1,
    network_egress_in_cluster: 1,
    network_ingress_in_cluster: 1,
    network_egress_private_endpoint: 1,
    network_ingress_private_endpoint: 1,
    auditlog_object_storage_size: 1,
    auditlog_https_post_invocations: 100,
    auditlog_https_post_body_size: 1,
    addon_iamprovider: false,
    addon_auditlog: false,
    addon_privateendpointservice: false,
    period: "year",
    support_plan_id: "default",
    currency_id: "",
    min_disk_size: undefined,
    max_disk_size: undefined,
    disk_sizes: undefined,
    currencies: undefined,
    show_fixed_vars: true,
    show_usage_vars: true,
    copiedToClipboard: false,
    honor_min_node_disk_size: true,
    generatingCSV: false,
    csvText: undefined,
    pastedInput: "",
  };

  static createUpdate(model: string, node_size_id: string, state: IPriceCalculatorState, update: IPriceCalculatorState) {
    if (model != "flexible") {
      const node_sizes = (state.nodeSizeList || {}).items || [];
      const node_size = _.find(node_sizes, (x) => x.id == node_size_id);
      if (node_size) {
        update.memory_size = node_size.memory_size || state.memory_size;
        update.min_disk_size = node_size.min_disk_size;
        update.max_disk_size = node_size.max_disk_size;
        update.disk_sizes = node_size.disk_sizes;
        const disk_sizes = node_size.disk_sizes || [];
        if (_.isEmpty(disk_sizes)) {
          if (!!node_size.min_disk_size && state.disk_size < node_size.min_disk_size) {
            update.disk_size = node_size.min_disk_size;
          }
          if (!!node_size.max_disk_size && state.disk_size > node_size.max_disk_size) {
            update.disk_size = node_size.max_disk_size;
          }
        } else {
          update.disk_size = _.find(disk_sizes, (x) => x >= state.disk_size) || disk_sizes[0];
        }
      }
    } else {
      update.min_disk_size = undefined;
      update.max_disk_size = undefined;
      update.disk_sizes = undefined;
    }
    if (model == "oneshard") {
      update.node_count = 3;
    } else if (model == "sharded") {
      if (state.node_count < 3) {
        update.node_count = 3;
      }
    } else if (model == "developer") {
      update.node_count = 1;
    }
    return update;
  }

  reloadCurrencies = async () => {
    const req: ApiListOptions = {};
    const currencies = await apiClients.idashboardClient.ListCurrencies(req);
    this.setState({ currencies: currencies });
  };

  reloadNodeSizes = async () => {
    if (_.isEmpty(this.state.region_id)) {
      const list = await apiClients.idashboardClient.ListAllNodeSizes();
      this.setState({ nodeSizeList: list }, () => {
        const update = PriceCalculator.createUpdate(this.state.model, this.state.node_size_id, this.state, {} as IPriceCalculatorState);
        this.setState(update, this.refreshPrice);
      });
    } else {
      const req: ApiNodeSizesRequest = {
        project_id: "all",
        region_id: this.state.region_id,
        model: this.state.model,
      };
      const list = await apiClients.authenticationOnly.dataClient.ListNodeSizes(req);
      this.setState({ nodeSizeList: list }, () => {
        const update = PriceCalculator.createUpdate(this.state.model, this.state.node_size_id, this.state, {} as IPriceCalculatorState);
        this.setState(update, this.refreshPrice);
      });
    }
  };
  refreshNodeSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadNodeSizes);
  };

  reloadPlan = async () => {
    const idOptions: ApiIDOptions = { id: this.props.planId };
    var plan = await apiClients.idashboardClient.GetPlan(idOptions);
    const default_system_currency = await apiClients.idashboardClient.GetDefaultSystemCurrency();
    this.setState(
      {
        plan: plan,
        currency_id: default_system_currency.id || "",
      },
      this.refreshPrice
    );
  };

  calculateAllRegionCSV = async () => {
    const priceLists = await CalculatePriceListsForAllProviders(this.state);
    return FormatAllRegionsPriceListAsCSV(priceLists);
  };

  calculateAllNodeSizeCSV = async () => {
    const priceLists = await CalculatePriceListsForAllNodeSizes(this.state);
    return FormatAllRegionsPriceListAsCSV(priceLists);
  };

  calculateAllNodeSizeAllRegionsCSV = async () => {
    const priceLists = await CalculatePriceListsForAll(this.state);
    return FormatAllRegionsPriceListAsCSV(priceLists);
  };

  reloadPrice = async () => {
    const regionID = this.state.region_id;
    try {
      this.setState({
        loading: true,
        errorMessage: undefined,
      });
      if (_.isEmpty(regionID)) {
        // No selection
      } else {
        var result = await CalculatePrice(this.state);
        this.setState({
          loading: false,
          priceList: result.priceList,
          errorMessage: undefined,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        priceList: undefined,
        errorMessage: e,
      });
    }
  };

  refreshPrice = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPrice);
  };

  componentDidMount() {
    this.props.refreshNow &&
      this.props.refreshNow(async () => {
        await this.reloadPlan();
        await this.reloadNodeSizes();
        await this.reloadCurrencies();
        this.onModelChange("oneshard");
      });
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadPlan, `/Organization/_system/PricingPlan/${this.props.planId}`);
  }

  onMemorySizeChange = (value: number) => {
    this.setState({ memory_size: value }, this.refreshPrice);
  };

  onDiskSizeChange = (value: number) => {
    this.setState({ disk_size: value }, this.refreshPrice);
  };

  onDiskPerformanceChange = (id: string) => {
    this.setState({ disk_performance_id: id }, this.refreshPrice);
  };

  onBackupObjectStorageSizeChange = (value: number) => {
    this.setState({ backup_object_storage_size: value }, this.refreshPrice);
  };

  onNetworkEgressInternetChange = (value: number) => {
    this.setState({ network_egress_internet: value }, this.refreshPrice);
  };

  onNetworkIngressInternetChange = (value: number) => {
    this.setState({ network_ingress_internet: value }, this.refreshPrice);
  };

  onNetworkEgressInClusterChange = (value: number) => {
    this.setState({ network_egress_in_cluster: value }, this.refreshPrice);
  };

  onNetworkIngressInClusterChange = (value: number) => {
    this.setState({ network_ingress_in_cluster: value }, this.refreshPrice);
  };

  onNetworkEgressPrivateEndpointChange = (value: number) => {
    this.setState({ network_egress_private_endpoint: value }, this.refreshPrice);
  };

  onNetworkIngressPrivateEndpointChange = (value: number) => {
    this.setState({ network_ingress_private_endpoint: value }, this.refreshPrice);
  };
  onAuditLogObjectStorageSizeChange = (value: number) => {
    this.setState({ auditlog_object_storage_size: value }, this.refreshPrice);
  };

  onAuditlogHttpsPostInvocationsChange = (value: number) => {
    this.setState({ auditlog_https_post_invocations: value }, this.refreshPrice);
  };

  onAuditlogHttpsPostBodySizeChange = (value: number) => {
    this.setState({ auditlog_https_post_body_size: value }, this.refreshPrice);
  };

  onPeriodChange = (value: string) => {
    this.setState({ period: value }, this.refreshPrice);
  };

  onCloudProviderChange = (value: string) => {
    this.setState({ provider_id: value }, this.refreshNodeSizes);
  };

  onCloudRegionChange = (value: string) => {
    this.setState({ region_id: value }, this.refreshNodeSizes);
  };

  onSupportPlanChange = (value: string) => {
    this.setState({ support_plan_id: value }, this.refreshPrice);
  };

  onCurrencyChange = (id: string) => {
    this.setState({ currency_id: id }, this.refreshPrice);
  };

  onModelChange = (value: string) => {
    const update = PriceCalculator.createUpdate(value, this.state.node_size_id, this.state, { model: value } as IPriceCalculatorState);
    this.setState(update, this.refreshPrice);
  };

  onNodeSizeChange = (id: string) => {
    const update = PriceCalculator.createUpdate(this.state.model, id, this.state, { node_size_id: id } as IPriceCalculatorState);
    console.log(update);
    this.setState(update, this.refreshPrice);
  };

  onNodeCountChange = (value: number) => {
    const update = PriceCalculator.createUpdate(this.state.model, this.state.node_size_id, this.state, { node_count: value } as IPriceCalculatorState);
    this.setState(update, this.refreshPrice);
  };

  onShowFixedVarsChange = (show: boolean) => {
    this.setState({ show_fixed_vars: show }, this.refreshPrice);
  };
  onShowUsageVarsChange = (show: boolean) => {
    this.setState({ show_usage_vars: show }, this.refreshPrice);
  };

  onHonorMinNodeDiskSizeChange = (value: boolean) => {
    this.setState({ honor_min_node_disk_size: value }, this.refreshPrice);
  };

  onAddonIAMProviderChange = (value: boolean) => {
    this.setState({ addon_iamprovider: value }, this.refreshPrice);
  };
  onAddonAuditlogChange = (value: boolean) => {
    this.setState({ addon_auditlog: value }, this.refreshPrice);
  };
  onAddonPrivateEndpointServiceChange = (value: boolean) => {
    this.setState({ addon_privateendpointservice: value }, this.refreshPrice);
  };

  onPastedInputChange = (value: string) => {
    this.setState({ pastedInput: value });
  };

  convertRawRegionToId = (region: string, provider: string) => {
    if (provider === "aks") {
      return `${provider}-${_.toLower(region).replace(/\s/g, "")}`;
    }

    const id = `${provider}-${_.toLower(region).replace(/\s/g, "-")}`;
    if (provider === "aws") {
      return id;
    }

    // GCP doesn't follow the same pattern so extra munging required
    const i = id.lastIndexOf("-");
    return `${id.slice(0, i)}${id.slice(i + 1)}`;
  };

  convertRawPlanToId = (plan: string) => {
    switch (plan.trimEnd()) {
      case "Standard":
        return "silver";
      case "Premium Standard":
        return "gold";
      case "Premium Enhanced":
        return "platinum";
      default:
        return "default";
    }
  };

  parsePastedInput = () => {
    const input = this.state.pastedInput;
    if (!input) {
      return;
    }

    // This looks more complex than it is because our react scripts
    // is so old that we cannot do optional chaining, so many null checks
    let m = input.match(/Cloud Provider & region\t(AWS|Azure|GCP) ([^(]*) \(/);
    if (!m) {
      return;
    }
    const provider = m[1] === "Azure" ? "aks" : _.toLower(m[1]);
    const region = this.convertRawRegionToId(m[2], provider);

    m = input.match(/Deployment model\t(\w+)/);
    if (!m) {
      return;
    }
    const model = _.toLower(m[1]);

    m = input.match(/Node Size.*\t(A\d+)\n/);
    if (!m) {
      return;
    }
    const nodeSize = `c4-${_.toLower(m[1])}`;

    m = input.match(/Number of nodes\t(\d+)/);
    const nodes = m ? Number(m[1]) : 3;

    m = input.match(/Disk size.*\t(\d+)(GB|TB)/);
    if (!m) {
      return;
    }
    const disk = m[2] === "TB" ? Number(m[1]) * 1024 : Number(m[1]);

    m = input.match(/Support plan\t([^(\n]*)\s?\(?/);
    if (!m) {
      return;
    }
    const plan = this.convertRawPlanToId(m[1]);

    let auditLog = false;
    let privateNetwork = false;
    m = input.match(/Addons\t(Audit log)?(\n)?(Private network)/);
    if (m) {
      auditLog = !!m[1];
      privateNetwork = !!m[3];
    }

    // Need to call onNodeSize change after state has updated so that
    // validation like min disk size are applied
    this.setState(
      {
        provider_id: provider,
        region_id: region,
        model: model,
        support_plan_id: plan,
        node_count: nodes,
        disk_size: disk,
        addon_auditlog: auditLog,
        addon_privateendpointservice: privateNetwork,
      },
      () => {
        this.onNodeSizeChange(nodeSize);
      }
    );
  };

  onCopiedToClipboard = () => {
    this.setState({ copiedToClipboard: true }, () => {
      setInterval(() => {
        this.setState({ copiedToClipboard: false });
      }, 3000);
    });
  };

  onCopyAllProvidersCSV = () => {
    this.setState(
      {
        generatingCSV: true,
        errorMessage: undefined,
      },
      async () => {
        try {
          const csv = await this.calculateAllRegionCSV();
          this.setState({
            csvText: csv,
            generatingCSV: false,
          });
        } catch (e) {
          this.setState({
            generatingCSV: false,
            errorMessage: e,
          });
        }
      }
    );
  };

  onCopyAllNodeSizesCSV = () => {
    this.setState(
      {
        generatingCSV: true,
        errorMessage: undefined,
      },
      async () => {
        try {
          const csv = await this.calculateAllNodeSizeCSV();
          this.setState({
            csvText: csv,
            generatingCSV: false,
          });
        } catch (e) {
          this.setState({
            generatingCSV: false,
            errorMessage: e,
          });
        }
      }
    );
  };

  onCopyAllCSV = () => {
    this.setState(
      {
        generatingCSV: true,
        errorMessage: undefined,
      },
      async () => {
        try {
          const csv = await this.calculateAllNodeSizeAllRegionsCSV();
          this.setState({
            csvText: csv,
            generatingCSV: false,
          });
        } catch (e) {
          this.setState({
            generatingCSV: false,
            errorMessage: e,
          });
        }
      }
    );
  };

  render() {
    return (
      <Modal
        size="large"
        trigger={
          <Button icon labelPosition="right">
            <Icon name="calculator" /> Calculator
          </Button>
        }
      >
        <Modal.Header>
          {this.state.loading && <FloatedIcon loading={true} name="spinner" />}
          Price calculator
        </Modal.Header>
        <Modal.Content scrolling>
          {this.state.errorMessage && <Message error content={`${this.state.errorMessage}`} />}
          <CalculatorView
            {...this.props}
            {...this.state}
            onModelChange={this.onModelChange}
            onNodeSizeChange={this.onNodeSizeChange}
            onNodeCountChange={this.onNodeCountChange}
            onCurrencyChange={this.onCurrencyChange}
            onCloudProviderChange={this.onCloudProviderChange}
            onCloudRegionChange={this.onCloudRegionChange}
            onMemorySizeChange={this.onMemorySizeChange}
            onDiskSizeChange={this.onDiskSizeChange}
            onDiskPerformanceChange={this.onDiskPerformanceChange}
            onBackupObjectStorageSizeChange={this.onBackupObjectStorageSizeChange}
            onNetworkEgressInternetChange={this.onNetworkEgressInternetChange}
            onNetworkIngressInternetChange={this.onNetworkIngressInternetChange}
            onNetworkEgressInClusterChange={this.onNetworkEgressInClusterChange}
            onNetworkIngressInClusterChange={this.onNetworkIngressInClusterChange}
            onNetworkEgressPrivateEndpointChange={this.onNetworkEgressPrivateEndpointChange}
            onNetworkIngressPrivateEndpointChange={this.onNetworkIngressPrivateEndpointChange}
            onAuditLogObjectStorageSizeChange={this.onAuditLogObjectStorageSizeChange}
            onAuditlogHttpsPostInvocationsChange={this.onAuditlogHttpsPostInvocationsChange}
            onAuditlogHttpsPostBodySizeChange={this.onAuditlogHttpsPostBodySizeChange}
            onSupportPlanChange={this.onSupportPlanChange}
            onPeriodChange={this.onPeriodChange}
            onShowFixedVarsChange={this.onShowFixedVarsChange}
            onShowUsageVarsChange={this.onShowUsageVarsChange}
            onCopiedToClipboard={this.onCopiedToClipboard}
            onHonorMinNodeDiskSizeChange={this.onHonorMinNodeDiskSizeChange}
            onAddonAuditlogChange={this.onAddonAuditlogChange}
            onAddonIAMProviderChange={this.onAddonIAMProviderChange}
            onAddonPrivateEndpointServiceChange={this.onAddonPrivateEndpointServiceChange}
            onCopyAllProvidersCSV={this.onCopyAllProvidersCSV}
            onCopyAllNodeSizesCSV={this.onCopyAllNodeSizesCSV}
            onCopyAllCSV={this.onCopyAllCSV}
            onPastedInputChange={this.onPastedInputChange}
            fillFromPasted={this.parsePastedInput}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRefresh()(PriceCalculator);
