//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Input, InputOnChangeData } from "semantic-ui-react";
import styled from "@emotion/styled";
import _ from "lodash";

const rangePattern = new RegExp("^(?<lower>[0-9]*)-(?<upper>[0-9]*)$");

interface IIntegerRangeInputProps {
  min?: number;
  max?: number;
  value: string;
  ext?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

interface IIntegerRangeInputState {
  value: string;
  valueString: string;
  errorMessage?: string;
}

const StyledGroup = styled("div")`
  display: inline-flex;
  .ui.input input {
    text-align: center;
    width: 10em !important;
  }
  .ui.input.error input {
    background-color: red;
  }
`;

export class IntegerRangeInput extends Component<IIntegerRangeInputProps, IIntegerRangeInputState> {
  state: IIntegerRangeInputState = {
    value: this.props.value,
    valueString: `${this.props.value}${this.props.ext || ""}`,
    errorMessage: undefined,
  };

  componentDidMount() {
    this.setState({ valueString: `${this.props.value || ""}${this.props.ext || ""}` });
  }

  onTextChange = (e: any, data: InputOnChangeData) => {
    if (data) {
      this.setState({ valueString: data.value });
    }
  };

  onBlur = () => {
    this.validateValueString(this.props.onChange);
  };

  validateValueString = (callback: (newValue: string) => void) => {
    let patternString = this.state.valueString;
    if (this.props.ext) {
      patternString = _.replace(patternString, this.props.ext, "");
    }
    const m = rangePattern.exec(patternString);
    if (!m || !m.groups) {
      this.setState({ errorMessage: "Not a valid range" });
    } else {
      const lowerString = m.groups.lower || "";
      const upperString = m.groups.upper || "";
      const lower = lowerString ? parseInt(lowerString) : undefined;
      const upper = upperString ? parseInt(upperString) : undefined;
      if (
        (this.props.min != undefined && lower != undefined && lower < this.props.min) ||
        (this.props.max != undefined && upper != undefined && upper > this.props.max)
      ) {
        this.setState({ errorMessage: `Range must be in between ${this.props.min} and ${this.props.max}` });
      } else if (!lowerString && !upperString) {
        this.setState({ errorMessage: `Range must have at least a lower or upper value` });
      } else {
        const value = `${lowerString}-${upperString}`;
        this.setState(
          {
            value: value,
            valueString: `${value}${this.props.ext || ""}`,
            errorMessage: undefined,
          },
          () => {
            callback(value);
          }
        );
      }
    }
  };

  render() {
    return (
      <StyledGroup>
        <Input
          focus
          value={this.state.valueString}
          onChange={this.onTextChange}
          onBlur={this.onBlur}
          error={!!this.state.errorMessage}
          placeholder={this.props.placeholder}
        />
      </StyledGroup>
    );
  }
}
