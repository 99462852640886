//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Icon, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  DeploymentInfoList as ApiDeploymentInfoList,
  ListAllDeploymentsRequest as ApiListAllDeploymentsRequest,
  DataCluster as ApiDataCluster,
} from "../../api/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { IconWithPopup } from "../../ui/lib";
import _ from "lodash";
import { DataClusterHelper } from "./DataClusterHelper";

interface IProvisionInfoCellViewArgs {
  datacluster: ApiDataCluster;
  outdated_deployments: number;
  loading: boolean;
}

const ProvisionInfoCellView = ({ ...args }: IProvisionInfoCellViewArgs) => {
  const status = DataClusterHelper.getProvisionInfoStatus(args.datacluster);
  const outdatedView = (
    <span>
      {args.loading && <Icon name="circle" loading />}
      {args.outdated_deployments > 0 && (
        <IconWithPopup name="shield" color="purple" content={`${args.outdated_deployments} deployments in implicit maintenance mode`} />
      )}
    </span>
  );

  if (!!status.allGood) {
    return (
      <Table.Cell>
        <IconWithPopup name="check" color="green" content={status.message} /> {outdatedView}
      </Table.Cell>
    );
  }
  return (
    <Table.Cell negative={status.negative} warning={status.warning}>
      {status.message} {outdatedView}
    </Table.Cell>
  );
};

interface IProvisionInfoCellProps extends IWithRefreshProps {
  datacluster: ApiDataCluster;
}

interface IProvisionInfoCellState {
  deployments?: ApiDeploymentInfoList;
}

// The component to show the dataclusters as a list.
class ProvisionInfoCell extends Component<IProvisionInfoCellProps, IProvisionInfoCellState> {
  state: IProvisionInfoCellState = {
    deployments: undefined,
  };

  reloadDeployments = async () => {
    const req: ApiListAllDeploymentsRequest = {
      datacluster_id: this.props.datacluster.id,
    };
    const list = await apiClients.idashboardClient.ListAllDeployments(req);
    this.setState({ deployments: list });
  };

  refreshDeployments = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDeployments);
  };

  componentDidMount() {
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadDeployments, 30000);
    this.refreshDeployments();
  }

  render() {
    const deploymentList = this.state.deployments || {};
    const deployments = deploymentList.items || [];
    const outdated_deployments = _.filter(deployments, (x) => !!(x.assignment && x.assignment.provision_hash_outdated)).length;

    return <ProvisionInfoCellView {...this.props} {...this.state} outdated_deployments={outdated_deployments} loading={!this.state.deployments} />;
  }
}

export default withRefresh()(ProvisionInfoCell);
