//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import create from "zustand";
import { persist } from "zustand/middleware";
import { Organization } from "../../api/lib";
import { DateTypePreference, DATE_TYPE_PREFERENCE, GlobalStoreRequiredProps, PersistentStoreRequiredProps } from "./types";

export const useGlobalStore = create<GlobalStoreRequiredProps>((set) => ({
  visibleTabElement: {},
  featureFlags: [],
  setVisibleTabElement: (visibleTabElement: { [key: string]: boolean }) => set((state) => ({ ...state, visibleTabElement })),
  setFeatureFlags: (featureFlags: string[]) => set((state) => ({ ...state, featureFlags })),
}));

export const usePersistentStorage = create<PersistentStoreRequiredProps>()(
  persist(
    (set) => ({
      organization: {},
      setOrganization: (organization: Organization) => set((state) => ({ ...state, organization })),
      dateTypePreference: DATE_TYPE_PREFERENCE.DURATION as DateTypePreference,
      setDateTypePreference: (dateTypePreference: DateTypePreference) => set((state) => ({ ...state, dateTypePreference })),
    }),
    {
      name: "appState",
      getStorage: () => localStorage,
    }
  )
);
