//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import apiClients from "../../api/apiclients";
import { IDOptions } from "../../api/lib";
import { ErrorMessage, Confirm, ConfirmInfo, DangerZoneItem } from "../../ui/lib";
import { Timestamp } from "../../api/googleTypes";
import { Message } from "semantic-ui-react";

interface IDeploymentDangerZoneArgs {
  id: string;
  name: string;
  deletedAt?: Timestamp;
  canDeleteExistingDeployment: boolean;
}

const DeploymentDangerZone = ({ id, name, deletedAt, canDeleteExistingDeployment }: IDeploymentDangerZoneArgs) => {
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isDeleteActionTriggered, setIsDeleteActionTriggered] = useState<boolean>(false);
  const handleDeploymentDeletion = async () => {
    if (!canDeleteExistingDeployment) {
      setError("You are not allowed to delete this deployment");
      return;
    }
    if (!!deletedAt) {
      setError("Deployment is already deleted");
      return;
    }
    const confirmInfo: ConfirmInfo = {
      header: "Are you sure you want to delete this deployment?",
      content: `This will delete the deployment ${name} and all its resources.`,
      warning: "This action cannot be undone.",
      onConfirm: async () => {
        setIsDeleteActionTriggered(true);
        const req: IDOptions = {
          id,
        };
        try {
          await apiClients.idashboardClient.DeleteDeployment(req);
          setConfirmInfo(undefined);
        } catch (err) {
          setError(err.message);
          setIsDeleteActionTriggered(false);
        }
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    };

    setConfirmInfo(confirmInfo);
  };

  if (!canDeleteExistingDeployment) {
    return <Message>No actions are allowed for this deployment.</Message>;
  }
  return (
    <div>
      <Confirm confirmInfo={confirmInfo} />
      <ErrorMessage active={!!error} onDismiss={() => setError(undefined)} message={error} />
      {canDeleteExistingDeployment && (
        <DangerZoneItem
          buttonIcon="trash"
          buttonColor="orange"
          buttonText="Delete deployment..."
          onClick={handleDeploymentDeletion}
          disabled={!!deletedAt || isDeleteActionTriggered}
        >
          <strong>Delete</strong> this deployment and all data in it.
        </DangerZoneItem>
      )}
    </div>
  );
};

export default DeploymentDangerZone;
