//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

export const ModelOneshard = "oneshard";

export const FreeTierID = "free";

export const SupportOrganizationID = "_support";
