//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Gergely Brautigam
//

import React, { Component, CSSProperties } from "react";
import { TextArea, TextAreaProps, Form, Button, Icon, Loader, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import styled from "@emotion/styled";
import { UploadedDocument as ApiUploadedDocument } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, LoaderBox, MenuActionBack, SecondaryMenu, Processing, Section, SectionContent, ErrorMessage } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import Dropzone from "react-dropzone";

const StyledTextArea = styled(TextArea)`
  display: block;
  width: 100%;
`;

const StyledDiv = styled("div")`
  margin-bottom: 1em;
`;

const DragAndDropArea = styled("div")`
  border: dotted;
`;

interface ICreateUploadedDocumentsProps extends IWithRefreshProps, RouteComponentProps {
  onCreated: () => void;
  onCancel: () => void;
}

interface ICreateUploadedDocumentsState {
  content: string;
  content_type: string;
  name: string;
  saving: boolean;
  errorMessage?: string;
  description: string;
}

// The component to upload a new document.
class CreateUploadedDocument extends Component<ICreateUploadedDocumentsProps, ICreateUploadedDocumentsState> {
  state: ICreateUploadedDocumentsState = {
    content: "",
    content_type: "",
    name: "",
    saving: false,
    description: "",
  };

  hasPermission = (p: Permission) => hasSupportPermission(p, this.props.hasPermissionByUrl);

  onSave = async () => {
    try {
      this.setState({ saving: true, errorMessage: undefined });
      const req: ApiUploadedDocument = {
        content: this.state.content,
        content_type: this.state.content_type,
        description: this.state.description,
        name: this.state.name,
      };
      await apiClients.idashboardClient.UploadDocument(req);
      this.props.onCreated();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ saving: false });
    }
  };

  onDrop = (acceptedFiles: File[]) => {
    const reader = new FileReader();
    acceptedFiles.forEach((file: File) => {
      reader.onabort = () => this.setState({ errorMessage: "Reading was aborted" });
      reader.onerror = () => this.setState({ errorMessage: "Failed to upload file" });
      reader.onload = () => {
        // Parse the data from readAsDataURL. This looks like this:
        // "data:text/plain;base64,VGVzdCBjb250ZW50IGhlaC4K"
        const content = reader.result as string;
        const split = content.split(";");
        if (split.length != 2) {
          this.setState({ errorMessage: "Invalid content type received" });
          return;
        }
        const data = split[0];
        const base64 = split[1];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_x, content_type] = data.split(":");
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_y, base64_content] = base64.split(",");
        this.setState({
          content_type: content_type,
          content: base64_content,
          name: file.name,
        });
      };
      reader.readAsDataURL(file);
    });
  };

  onDismiss = () => {
    this.setState({ errorMessage: undefined });
  };

  onContentChange = (content: string) => {
    this.setState({
      description: content,
    });
  };

  render() {
    const baseStyle: CSSProperties = {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    };
    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Upload Document</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
          <Processing active={this.state.saving} message="Uploading document..." />
          <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} onDismiss={this.onDismiss} />
        </SecondaryMenu>
        <div>
          <Section>
            <SectionContent>
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section style={baseStyle}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <DragAndDropArea>
                        <Icon name="upload" />
                        <p>Drag and drop here, or click to select.</p>
                      </DragAndDropArea>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div>
                <aside>
                  <h4>Selected file</h4>
                  <ul>{this.state.name}</ul>
                </aside>
              </div>
              <StyledDiv>
                <Form.Field>
                  <label>Description</label>
                  <StyledTextArea value={this.state.description} onChange={(e: any, d: TextAreaProps) => this.onContentChange(d.value as string)} rows="5" />
                </Form.Field>
              </StyledDiv>
              <Button icon="check" content="Upload" primary onClick={this.onSave} />
              <Button content="Cancel" onClick={this.props.onCancel} />
            </SectionContent>
          </Section>
        </div>
      </ContentSegment>
    );
  }
}

export default withRefresh()(CreateUploadedDocument);
