//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component, useEffect, useState } from "react";
import { Divider, Form, Icon, Input, InputOnChangeData, Modal } from "semantic-ui-react";
import CommentInputField from "../dashboard/comments/CommentInputField";
import { CommentCreationArgs } from "../dashboard/comments/CommentTypes";
import { AsyncResult } from "../util/Types";
import { ErrorMessage } from "./lib";
import { FormActionButtonCancel, FormActionButtonSave } from "./_buttons";
import { FlexBox } from "./_flex";
import { StyledInlineLoader } from "./_style";
interface EditBoxViewArgs extends CommentCreationArgs {
  editMode: boolean;
  editable: boolean;
  placeholder?: string;
  value: string;
  label: string;
  error?: string;
  commentCreationFailed?: boolean;
  onCancelEditMode: () => void;
  onChangeValue: (value: string) => void;
  onStartEditMode: () => void;
  onConfirmValue: (value: string, comment: string) => void;
}

const EditBoxFieldsView = ({ ...args }: EditBoxViewArgs) => {
  const [comment, setComment] = useState("");
  const [value, setValue] = useState(args.value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (args.value !== value) {
      setComment(`Setting ${args.label} to ${value}`);
    } else {
      setComment("");
    }
  }, [value]);

  const handleConfirmation = async () => {
    setLoading(true);
    await args.onConfirmValue(value, comment);
    setLoading(false);
  };

  return (
    <Modal open>
      <Modal.Header>
        <FlexBox justify="space-between">
          <span> Edit {args.label}</span> {loading && <StyledInlineLoader inline />}
        </FlexBox>
      </Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!args.error} message={args.commentCreationFailed ? "Comment creation failed. You can retry saving the comment." : args.error} />
        {args.error && <Divider hidden />}
        <Form>
          <Form.Field>
            <label>{args.label}</label>
            <Input
              value={value || ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                setValue(data.value.toString());
              }}
              placeholder={args.placeholder ? args.placeholder : ""}
              disabled={args.commentCreationFailed}
            />
          </Form.Field>
          <Form.Field required>
            <label>Please provide the reason for this change</label>

            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>{args.label}</b>. Otherwise, a default comment will be provided which can be changed if
              required.
            </sub>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel icon="cancel" onClick={args.onCancelEditMode} title="Cancel" />
        {args.commentCreationFailed ? (
          <FormActionButtonSave primary disabled={loading || !comment} icon="comment" onClick={handleConfirmation} title="Retry saving comments" />
        ) : (
          <FormActionButtonSave
            icon={!!args.error ? "refresh" : "check"}
            primary
            onClick={handleConfirmation}
            disabled={!comment || args.value === value || loading}
            title={!!args.error ? "Retry" : "Confirm"}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

interface EditBoxFieldsProps extends CommentCreationArgs {
  initialValue: string;
  update: (value: string) => Promise<AsyncResult<void>>;
  placeholder?: string;
  editable?: boolean;
  label: string;
}

interface EditBoxFieldsState {
  editMode: boolean;
  value: string;
  error?: string;
  commentCreationFailed: boolean;
}

export class EditBox extends Component<EditBoxFieldsProps, EditBoxFieldsState> {
  state: EditBoxFieldsState = {
    value: this.props.initialValue,
    editMode: false,
    commentCreationFailed: false,
  };

  onChangeValue = (newValue: string) => {
    this.setState({ value: newValue });
  };

  onConfirmValue = async (value: string, comment: string) => {
    if (!comment.trim()) {
      this.setState({ error: "Comment cannot be empty" });
      return;
    }
    this.setState({ error: "", commentCreationFailed: false });
    const { error } = (!this.state.commentCreationFailed && (await this.props.update(value))) || {};
    if (!!error) {
      this.setState({ error });
      return;
    }
    const { error: commentError } = await this.props.createComment(comment);
    if (commentError) {
      this.setState({ error: commentError, commentCreationFailed: true });
      return;
    }
    await this.onChangeValue(value);
    this.setState({ editMode: false });
  };

  render() {
    return (
      <>
        <div>
          <span>{this.state.value ? this.state.value : "-"}</span>
          {!this.state.editMode && this.props.editable && (
            <Icon name="pencil" onClick={() => this.setState({ editMode: true, value: this.props.initialValue })} className="edit-pencil" />
          )}
        </div>
        {this.state.editMode && this.props.editable && (
          <EditBoxFieldsView
            label={this.props.label}
            editMode={this.state.editMode}
            placeholder={this.props.placeholder}
            editable={this.props.editable ? this.props.editable : false}
            onStartEditMode={() => this.setState({ editMode: true, value: this.props.initialValue })}
            onCancelEditMode={() => this.setState({ editMode: false, value: this.props.initialValue })}
            onChangeValue={this.onChangeValue}
            onConfirmValue={this.onConfirmValue}
            value={this.state.value}
            createComment={this.props.createComment}
            error={this.state.error}
            commentCreationFailed={this.state.commentCreationFailed}
          />
        )}
      </>
    );
  }
}
