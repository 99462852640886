//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React from "react";
import styled from "@emotion/styled";
import { Grid, TextArea, Form, Segment, TextAreaProps } from "semantic-ui-react";
import Markdown from "react-markdown";
import { TermsAndConditions as ApiTermsAndConditions } from "../../api/lib";

const StyledTextArea = styled(TextArea)`
  display: block;
  width: 100%;
`;

interface IEditorViewArgs {
  termsAndConditions: ApiTermsAndConditions;
  onContentChange: (content: string) => void;
}

export const EditorView = ({ ...args }: IEditorViewArgs) => (
  <Grid width="16">
    <Grid.Column width="8">
      <Form.Field>
        <label>Content</label>
        <StyledTextArea
          value={args.termsAndConditions.content || ""}
          onChange={(e: any, d: TextAreaProps) => args.onContentChange(d.value as string)}
          rows="25"
        />
      </Form.Field>
    </Grid.Column>
    <Grid.Column width="8">
      <Form.Field>
        <label>Preview</label>
        <Segment>
          <Markdown source={args.termsAndConditions.content || ""} />
        </Segment>
      </Form.Field>
    </Grid.Column>
  </Grid>
);
