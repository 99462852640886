//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
// Author Ewout Prangsma
//

import { Component } from "react";
import { Link, NavLink, Redirect, Route, RouteComponentProps } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import apiclients from "../api/apiclients";
import { MobilePhoneVerificationCode as ApiMobilePhoneVerificationCode, Tier as ApiTier, User as ApiUser } from "../api/lib";
import Auth from "../auth/Auth";
import { Routes } from "../routes";
import arangographLogo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo.svg";
import { Loading, SidebarContent, SidebarMenu, SidebarPage } from "../ui/lib";
import { EventSubscriptionManager } from "../util/EventSubscriptionManager";
import { hasSupportPermission, Permission } from "../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import InvoiceDetails from "./billing/InvoiceDetails";
import InvoiceList from "./billing/InvoiceList";
import CACertificateDetails from "./cacertificate/CACertificateDetails";
import CurrencyList from "./currency/CurrencyList";
import CoreDumpList from "./coredump/CoreDumpList";
import DataClusterDetails from "./datacluster/DataClusterDetails";
import DataClusterList from "./datacluster/DataClusterList";
import DCUpdate from "./dcupdate/DCUpdate";
import DeploymentChangesReporting from "./reports/DeploymentChangesReporting";
import DeploymentDetails from "./deployment/DeploymentDetails";
import DeploymentList from "./deployment/DeploymentList";
import DeploymentsCostReporting from "./reports/DeploymentsCostReporting";
import CreateDataProcessingAddendum from "./dpa/CreateDataProcessingAddendum";
import DataProcessingAddendumDetails from "./dpa/DataProcessingAddendumDetails";
import DataProcessingAddendumList from "./dpa/DataProcessingAddendumList";
import UpdateDataProcessingAddendum from "./dpa/UpdateDataProcessingAddendum";
import EmailPatternList from "./emailpattern/EmailPatternList";
import IntegrationTests from "./integration-tests/IntegrationTests";
import OrganizationDetails from "./organization/OrganizationDetails";
import OrganizationList from "./organization/OrganizationList";
import ProviderList from "./platform/ProviderList";
import PricingPlanDetails from "./pricing/PricingPlanDetails";
import PricingPlanList from "./pricing/PricingPlanList";
import ProjectDetails from "./project/ProjectDetails";
import PublicEmailProviderDomainList from "./publicemailproviderdomain/PublicEmailProviderDomainList";
import DailyEssentials from "./reports/DailyEssentials";
import RevenueReporting from "./revenue/RevenueReporting";
import Statistics from "./stats/Statistics";
import CreateTermsAndConditions from "./termsandconditions/CreateTermsAndConditions";
import TermsAndConditionsDetails from "./termsandconditions/TermsAndConditionsDetails";
import TermsAndConditionsList from "./termsandconditions/TermsAndConditionsList";
import UpdateTermsAndConditions from "./termsandconditions/UpdateTermsAndConditions";
import CreateUploadedDocument from "./uploaddocuments/CreateUploadedDocument";
import UploadedDocumentDetails from "./uploaddocuments/UploadedDocumentDetails";
import UploadedDocumentList from "./uploaddocuments/UploadedDocumentList";
import UserDetailsWithId from "./user/UserDetailsWithId";
import UserList from "./user/UserList";
import ExampleInstallationsReporting from "./reports/ExampleInstallationsReporting";
import CustomerChanges from "./reports/CustomerChanges";
import AuditLogList from "./auditlog/AuditLogList";
import AuditLogDetails from "./auditlog/AuditLogDetails";
import AuditLogArchiveDetails from "./auditlog/AuditLogArchiveDetails";
import TierList from "./tier/TierList";
import TierDetailsWithId from "./tier/TierDetailsWithId";
import EmailMessages from "./email/EmailMessages";
import _ from "lodash";
import CommentsRoute from "./comments/CommentsRoute";
import DeploymentUpdaterView from "./deployment-updater/DeploymentUpdater";
import DeploymentUpdaterDetailsView from "./deployment-updater/DeploymentUpdaterDetailsView";
import NotebookView from "./deployment/notebook/NotebookView";
import SchedulingPoliciesView from "./schedulingpolicy/SchedulingPoliciesView";

const ReportsMenu = (args: IMenuViewArgs) => {
  const has_revenue_get = args.hasPermission("internal-dashboard.revenue.get");
  const has_customer_changes_get = args.hasPermission("internal-dashboard.customer-changes.get");
  const has_deployment_changes_get = args.hasPermission("internal-dashboard.deployment-changes.get");
  const has_deployments_cost_get = args.hasPermission("internal-dashboard.deployments-cost.get");
  const has_example_installations_get = args.hasPermission("internal-dashboard.example-installations.get");
  return (
    <Menu.Item>
      <Menu.Header>Reports</Menu.Header>
      <Menu.Menu>
        <Menu.Item as={NavLink} to={Routes.dashboard_sales_reports_daily}>
          Daily essentials
        </Menu.Item>
        {has_customer_changes_get && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_customer_changes}>
            Customer changes
          </Menu.Item>
        )}
        {has_deployment_changes_get && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_deployment_changes}>
            Deployment changes
          </Menu.Item>
        )}
        {has_revenue_get && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_revenue}>
            Revenue
          </Menu.Item>
        )}
        {has_deployments_cost_get && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_deployment_cost}>
            Deployment cost
          </Menu.Item>
        )}
        {has_example_installations_get && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_example_installations}>
            Example Installations
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu.Item>
  );
};

const UserMenu = (args: IMenuViewArgs) => {
  const has_organization_list = args.hasPermission("internal-dashboard.organization.list");
  const has_user_list = args.hasPermission("internal-dashboard.user.list");
  const has_email_list = args.hasPermission("internal-dashboard.emailmessage.list");
  const has_deployment_list = args.hasPermission("internal-dashboard.deployment.list");
  const canListNotebook = args.hasPermission("internal-dashboard.notebook.list");

  return (
    <Menu.Item>
      <Menu.Header>User Support</Menu.Header>
      <Menu.Menu>
        {has_deployment_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_deployments}>
            Deployments
          </Menu.Item>
        )}
        {has_user_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_users}>
            Users
          </Menu.Item>
        )}
        {has_email_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_emails}>
            Emails
          </Menu.Item>
        )}
        {canListNotebook && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_notebooks}>
            Notebooks
          </Menu.Item>
        )}
        {has_organization_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_organizations}>
            Organizations
          </Menu.Item>
        )}
        <Menu.Item as={NavLink} to={Routes.dashboard_sales_tiers}>
          Tiers
        </Menu.Item>
      </Menu.Menu>
    </Menu.Item>
  );
};

const BillingMenu = (args: IMenuViewArgs) => {
  const has_invoice_list = args.hasPermission("internal-dashboard.invoice.list");
  return (
    <Menu.Item>
      <Menu.Header>Billing</Menu.Header>
      <Menu.Menu>
        {has_invoice_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_invoices}>
            Invoices
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu.Item>
  );
};

const TechnicalSupportMenu = (args: IMenuViewArgs) => {
  const has_apicompat_get = args.hasPermission("internal-dashboard.apicompat.get");
  const has_controlplane_status = args.hasPermission("internal-dashboard.controlplanestatus.get");
  const has_datacluster_list = args.hasPermission("internal-dashboard.datacluster.list");
  const has_regionlimits_get = args.hasPermission("internal-dashboard.regionlimits.get");
  const has_get_dcupdate_status = args.hasPermission("internal-dashboard.dcupdate.get-status");
  const has_run_tests = args.hasPermission("internal-dashboard.tests.run");
  const has_get_test_status = args.hasPermission("internal-dashboard.tests.get-status");
  const has_get_comments_permission = args.hasPermission("internal-dashboard.comment.list");
  const has_updatejob_get = args.hasPermission("internal-dashboard.deploymentupdatejob.get");
  const has_scheduling_policy_list = args.hasPermission("internal-dashboard.schedulingpolicy.list");
  const has_coredump_list = args.hasPermission("internal-dashboard.coredump.list");

  return (
    <Menu.Item>
      <Menu.Header>Technical Support</Menu.Header>
      <Menu.Menu>
        {has_datacluster_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_dataclusters}>
            Data Clusters
          </Menu.Item>
        )}
        {has_datacluster_list && has_apicompat_get && has_controlplane_status && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_datacluster_detailsWithId(`_cp`)}>
            Controlplane
          </Menu.Item>
        )}
        {has_updatejob_get && (
          <Menu.Item as={NavLink} to={Routes.dashboard_deployment_update_job}>
            Deployment Updater
          </Menu.Item>
        )}
        {has_get_dcupdate_status && (
          <Menu.Item as={NavLink} to={Routes.dashboard_dcupdate}>
            DC Update
          </Menu.Item>
        )}
        {has_regionlimits_get && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_providers}>
            Cloud providers
          </Menu.Item>
        )}
        {(has_run_tests || has_get_test_status) && (
          <Menu.Item as={NavLink} to={Routes.dashboard_integration_tests}>
            Integration tests
          </Menu.Item>
        )}
        {has_get_comments_permission && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_comments}>
            Comments
          </Menu.Item>
        )}
        {has_scheduling_policy_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_schedulingpolicy_list}>
            Scheduling policies
          </Menu.Item>
        )}
        {has_coredump_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_coredumps}>
            Core Dumps
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu.Item>
  );
};

const ConfigurationMenu = (args: IMenuViewArgs) => {
  const has_email_pattern_list = args.hasPermission("internal-dashboard.emailpattern.list");
  const has_public_email_provider_domain_list = args.hasPermission("internal-dashboard.publicemailproviderdomain.list");
  const has_pricingplan_list = args.hasPermission("internal-dashboard.pricingplan.list");
  const has_currency_list = args.hasPermission("internal-dashboard.currency.list");
  const has_tandc_list = args.hasPermission("internal-dashboard.termsandconditions.list");
  const has_dpa_list = args.hasPermission("internal-dashboard.dataprocessingaddendum.list");
  const has_uploaded_documents_list = args.hasPermission("internal-dashboard.uploaddocument.list");

  return (
    <Menu.Item>
      <Menu.Header>Configuration</Menu.Header>
      <Menu.Menu>
        {has_email_pattern_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_emailpatterns}>
            Email patterns
          </Menu.Item>
        )}
        {has_public_email_provider_domain_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_support_publicemailproviderdomains}>
            Public Email Provider Domains
          </Menu.Item>
        )}
        {has_pricingplan_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_pricingPlans}>
            Pricing plans
          </Menu.Item>
        )}
        {has_currency_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_currencies}>
            Currencies
          </Menu.Item>
        )}
        {has_tandc_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_terms_and_conditions}>
            Terms &amp; Conditions
          </Menu.Item>
        )}
        {has_uploaded_documents_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_uploaded_documents}>
            Uploaded Documents
          </Menu.Item>
        )}
        {has_dpa_list && (
          <Menu.Item as={NavLink} to={Routes.dashboard_sales_data_processing_addendums}>
            Data Processing Addendums
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu.Item>
  );
};

interface IMenuViewArgs {
  hasUserInfo: boolean;
  hasPermission: (permission: Permission) => boolean;
}

const MenuView = (args: IMenuViewArgs) => (
  <SidebarMenu vertical>
    <Menu.Item>
      <Menu.Header>
        <Link to={Routes.dashboard}>
          <img src={arangographLogo} alt="home" width="100%" />
        </Link>
      </Menu.Header>
    </Menu.Item>
    {!args.hasUserInfo && (
      <Menu.Item>
        <Menu.Header>Home</Menu.Header>
        <Menu.Menu>
          <Loading />
        </Menu.Menu>
      </Menu.Item>
    )}
    <ReportsMenu {...args} />
    {args.hasUserInfo && <UserMenu {...args} />}
    {args.hasUserInfo && <BillingMenu {...args} />}
    {args.hasUserInfo && <TechnicalSupportMenu {...args} />}
    {args.hasUserInfo && <ConfigurationMenu {...args} />}
  </SidebarMenu>
);

// API used by DashboardHomeView
interface IDashboardHomeViewAPI {
  GetTier: (tierId: string) => Promise<ApiTier>;
  GetUser: (userId: string) => Promise<ApiUser>;
  onGetUserMobilePhoneVerification: (user: ApiUser) => Promise<ApiMobilePhoneVerificationCode>;
  onResetMobilePhone: (user: ApiUser) => Promise<void>;
  onSendVerificationEmail: (user: ApiUser) => Promise<void>;
}

// Interface describing the dashboard home view arguments
interface IDashboardHomeViewArgs extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  api: IDashboardHomeViewAPI;
  hasUserInfo: boolean;
  eventSubscriptionManager: EventSubscriptionManager;
  user?: ApiUser;
  hasPermission: (permission: Permission) => boolean;
}

const DashboardHomeView = ({ ...args }: IDashboardHomeViewArgs) => {
  const handlers = {
    onDataClusterSelected: (id: string) => {
      args.history.push(Routes.dashboard_support_datacluster_detailsWithId(id));
    },
    onDeploymentSelected: (id: string) => {
      args.history.push(Routes.dashboard_support_deployment_detailsWithId(id));
    },
    onOrganizationSelected: (id: string) => {
      args.history.push(Routes.dashboard_sales_organization_detailsWithId(id));
    },
    onProjectSelected: (id: string) => {
      args.history.push(Routes.dashboard_sales_project_detailsWithId(id));
    },
    onUserSelected: (id: string) => {
      args.history.push(Routes.dashboard_sales_user_detailsWithId(id));
    },
    onViewPlan: (id: string) => {
      args.history.push(Routes.dashboard_sales_pricingPlan_detailsWithId(id));
    },
    onInvoiceSelected: (id: string) => {
      args.history.push(Routes.dashboard_sales_invoices_detailsWithId(id));
    },
    onCreateTermsAndConditions: () => {
      args.history.push(Routes.dashboard_sales_terms_and_conditions_create);
    },
    onTermsAndConditionsList: () => {
      args.history.push(Routes.dashboard_sales_terms_and_conditions);
    },
    onTermsAndConditionsCreated: () => {
      args.history.push(Routes.dashboard_sales_terms_and_conditions);
    },
    onEditTermsAndConditions: (id: string) => {
      args.history.push(Routes.dashboard_sales_terms_and_conditions_editWithId(id));
    },
    onTermsAndConditionsSelected: (id: string) => {
      args.history.push(Routes.dashboard_sales_terms_and_conditions_detailsWithId(id));
    },
    onUploadDocumentsList: () => {
      args.history.push(Routes.dashboard_uploaded_documents);
    },
    onCreateUploadedDocument: () => {
      args.history.push(Routes.dashboard_create_uploaded_document);
    },
    onViewUploadedDocument: (id: string) => {
      args.history.push(Routes.dashboard_uploaded_document_detailsWithId(id));
    },
    onCreateDataProcessingAddendum: () => {
      args.history.push(Routes.dashboard_sales_data_processing_addendum_create);
    },
    onDataProcessingAddendumList: () => {
      args.history.push(Routes.dashboard_sales_data_processing_addendums);
    },
    onDataProcessingAddendumCreated: () => {
      args.history.push(Routes.dashboard_sales_data_processing_addendums);
    },
    onEditDataProcessingAddendum: (id: string) => {
      args.history.push(Routes.dashboard_sales_data_processing_addendum_editWithId(id));
    },
    onDataProcessingAddendumSelected: (id: string) => {
      args.history.push(Routes.dashboard_sales_data_processing_addendum_detailsWithId(id));
    },
    onAuditLogSelected: (id: string) => {
      args.history.push(Routes.dashboard_support_auditlog_detailsWithId(id));
    },
    onAuditLogArchiveSelected: (id: string) => {
      args.history.push(Routes.dashboard_support_auditlogarchive_detailsWithId(id));
    },
    onTierSelected: (id: string) => {
      args.history.push(Routes.dashboard_sales_tier_detailsWithId(id));
    },
  };

  const can_view_datacluster_alerts = args.hasPermission("internal-dashboard.datacluster.list");

  return (
    <SidebarPage>
      <MenuView {...args} />
      <SidebarContent>
        <Route exact path={Routes.dashboard} render={(props) => <Statistics {...args} />} />

        <Route path={Routes.dashboard_sales_reports_daily} render={(props) => <DailyEssentials {...args} />} />

        <Route exact path={Routes.dashboard_sales} render={(props) => <Redirect to={Routes.dashboard_sales_users} />} />
        <Route exact path={Routes.dashboard_sales_organizations} render={(props) => <OrganizationList {...args} {...props} {...handlers} />} />
        <Route
          path={Routes.dashboard_sales_organization_details}
          render={(props) => <OrganizationDetails {...args} {...props} {...handlers} organizationId={props.match.params.organizationId} />}
        />
        <Route
          path={Routes.dashboard_sales_project_details}
          render={(props) => <ProjectDetails {...args} {...props} {...handlers} projectId={props.match.params.projectId} />}
        />
        <Route path={Routes.dashboard_sales_tiers} render={(props) => <TierList {...args} {...props} {...handlers} />} />
        <Route
          path={Routes.dashboard_sales_tier_details}
          render={(props) => <TierDetailsWithId {...args} {...props} {...handlers} tierId={props.match.params.tierId} />}
        />
        <Route path={Routes.dashboard_sales_users} render={(props) => <UserList {...args} {...props} {...handlers} />} />
        <Route
          path={Routes.dashboard_sales_user_details}
          render={(props) => <UserDetailsWithId {...args} {...props} {...handlers} userId={props.match.params.userId} />}
        />
        <Route path={Routes.dashboard_sales_emails} render={(props) => <EmailMessages {...args} {...props} {...handlers} />} />
        <Route
          path={Routes.dashboard_support_notebooks}
          render={(props) => <NotebookView {...args} {...props} {...handlers} canListNotebook={args.hasPermission("internal-dashboard.notebook.list")} />}
        />
        <Route exact path={Routes.dashboard_sales_pricingPlans} render={(props) => <PricingPlanList {...args} {...props} {...handlers} loading={false} />} />
        <Route
          path={Routes.dashboard_sales_pricingPlan_details}
          render={(props) => <PricingPlanDetails {...args} {...props} {...handlers} planId={props.match.params.planId || ""} loading={false} />}
        />

        <Route path={Routes.dashboard_sales_currencies} render={(props) => <CurrencyList {...args} {...props} {...handlers} />} />

        <Route
          exact
          path={Routes.dashboard_sales_terms_and_conditions}
          render={(props) => (
            <TermsAndConditionsList
              {...args}
              {...props}
              onCreate={handlers.onCreateTermsAndConditions}
              onView={handlers.onTermsAndConditionsSelected}
              onEdit={handlers.onEditTermsAndConditions}
            />
          )}
        />
        <Route
          path={Routes.dashboard_sales_terms_and_conditions_create}
          render={(props) => (
            <CreateTermsAndConditions {...args} {...props} onCreated={handlers.onTermsAndConditionsCreated} onCancel={handlers.onTermsAndConditionsList} />
          )}
        />
        <Route
          path={Routes.dashboard_sales_terms_and_conditions_details}
          render={(props) => <TermsAndConditionsDetails {...args} {...props} termsAndConditionsId={props.match.params.termsAndConditionsId || ""} />}
        />
        <Route
          path={Routes.dashboard_sales_terms_and_conditions_edit}
          render={(props) => (
            <UpdateTermsAndConditions
              {...args}
              {...props}
              termsAndConditionsId={props.match.params.termsAndConditionsId || ""}
              onSaved={handlers.onTermsAndConditionsList}
              onCancel={handlers.onTermsAndConditionsList}
            />
          )}
        />
        <Route
          exact
          path={Routes.dashboard_uploaded_documents}
          render={(props) => <UploadedDocumentList {...args} {...props} onCreate={handlers.onCreateUploadedDocument} />}
        />
        <Route
          exact
          path={Routes.dashboard_create_uploaded_document}
          render={(props) => (
            <CreateUploadedDocument {...args} {...props} onCreated={handlers.onUploadDocumentsList} onCancel={handlers.onUploadDocumentsList} />
          )}
        />
        <Route
          exact
          path={Routes.dashboard_uploaded_document_details}
          render={(props) => <UploadedDocumentDetails {...args} {...props} uploadedDocumentId={props.match.params.uploadedDocumentId || ""} />}
        />

        <Route
          exact
          path={Routes.dashboard_sales_data_processing_addendums}
          render={(props) => (
            <DataProcessingAddendumList
              {...args}
              {...props}
              onCreate={handlers.onCreateDataProcessingAddendum}
              onView={handlers.onDataProcessingAddendumSelected}
              onEdit={handlers.onEditDataProcessingAddendum}
            />
          )}
        />
        <Route
          path={Routes.dashboard_sales_data_processing_addendum_create}
          render={(props) => (
            <CreateDataProcessingAddendum
              {...args}
              {...props}
              onCreated={handlers.onDataProcessingAddendumCreated}
              onCancel={handlers.onDataProcessingAddendumList}
            />
          )}
        />
        <Route
          path={Routes.dashboard_sales_data_processing_addendum_details}
          render={(props) => <DataProcessingAddendumDetails {...args} {...props} dpaId={props.match.params.dpaId || ""} />}
        />
        <Route
          path={Routes.dashboard_sales_data_processing_addendum_edit}
          render={(props) => (
            <UpdateDataProcessingAddendum
              {...args}
              {...props}
              dpaId={props.match.params.dpaId || ""}
              onSaved={handlers.onDataProcessingAddendumList}
              onCancel={handlers.onDataProcessingAddendumList}
            />
          )}
        />

        <Route exact path={Routes.dashboard_sales_invoices} render={(props) => <InvoiceList {...args} {...props} {...handlers} showHeader />} />
        <Route
          path={Routes.dashboard_sales_invoices_details}
          render={(props) => <InvoiceDetails {...args} {...props} {...handlers} api={apiclients.idashboardClient} />}
        />
        <Route path={Routes.dashboard_sales_revenue} render={(props) => <RevenueReporting {...args} {...props} {...handlers} />} />

        <Route exact path={Routes.dashboard_support} render={(props) => <Redirect to={Routes.dashboard_support_dataclusters} />} />
        <Route exact path={Routes.dashboard_support_comments} render={(props) => <CommentsRoute currentUserId={(args.user || {}).id || ``} />} />
        <Route path={Routes.dashboard_support_dataclusters} render={(props) => <DataClusterList {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_support_coredumps} render={(props) => <CoreDumpList {...args} {...props} {...handlers} showHeader />} />
        <Route
          path={Routes.dashboard_support_datacluster_details}
          render={(props) => (
            <DataClusterDetails
              {...args}
              {...props}
              {...handlers}
              datacluster_id={props.match.params["dcId"] || ""}
              canViewAlerts={can_view_datacluster_alerts}
            />
          )}
        />
        <Route path={Routes.dashboard_support_deployments} render={(props) => <DeploymentList {...args} {...props} {...handlers} />} />
        <Route
          path={Routes.dashboard_support_deployment_details}
          render={(props) => <DeploymentDetails {...args} {...props} {...handlers} api={apiclients.idashboardClient} />}
        />
        <Route path={Routes.dashboard_support_auditlog} render={(props) => <AuditLogList {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_support_auditlog_details} render={(props) => <AuditLogDetails {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_support_auditlogarchive_details} render={(props) => <AuditLogArchiveDetails {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_sales_customer_changes} render={(props) => <CustomerChanges {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_support_deployment_changes} render={(props) => <DeploymentChangesReporting {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_sales_deployment_cost} render={(props) => <DeploymentsCostReporting {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_sales_example_installations} render={(props) => <ExampleInstallationsReporting {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_support_cacertificate_details} render={(props) => <CACertificateDetails {...args} {...props} />} />
        <Route path={Routes.dashboard_support_emailpatterns} render={(props) => <EmailPatternList {...args} {...props} />} />
        <Route path={Routes.dashboard_support_publicemailproviderdomains} render={(props) => <PublicEmailProviderDomainList {...args} {...props} />} />

        <Route path={Routes.dashboard_support_providers} render={(props) => <ProviderList {...args} {...props} />} />

        <Route path={Routes.dashboard_integration_tests} render={(props) => <IntegrationTests {...args} {...props} />} />

        <Route path={Routes.dashboard_deployment_update_job} exact render={() => <DeploymentUpdaterView {...args} />} />
        <Route path={Routes.dashboard_deployment_update_job_details} exact render={() => <DeploymentUpdaterDetailsView {...args} />} />
        <Route path={Routes.dashboard_dcupdate} render={(props) => <DCUpdate {...args} {...props} {...handlers} />} />
        <Route path={Routes.dashboard_schedulingpolicy_list} render={(props) => <SchedulingPoliciesView {...args} {...props} {...handlers} />} />
      </SidebarContent>
    </SidebarPage>
  );
};

// Interface decribing the properties of the DashboardHome component
interface IDashboardHomeProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  hasUserInfo: boolean;
  eventSubscriptionManager: EventSubscriptionManager;
}

// Interface decribing the state of the DashboardHome component
interface IDashboardHomeState {}

// The DashboardHome component
class DashboardHome extends Component<IDashboardHomeProps, IDashboardHomeState> {
  GetTier = async (tierId: string): Promise<ApiTier> => {
    const tiers = await apiclients.idashboardClient.ListTiers({});
    return _.find(tiers.items || [], (x) => x.id == tierId) || {};
  };
  GetUser = async (userId: string): Promise<ApiUser> => {
    return apiclients.idashboardClient.GetUser({ id: userId });
  };
  onGetUserMobilePhoneVerification = async (user: ApiUser) => {
    return apiclients.idashboardClient.GetUserMobilePhoneVerificationCode({ id: user.id });
  };
  onResetMobilePhone = async (user: ApiUser) => {
    return apiclients.idashboardClient.ResetMobilePhone({ id: user.id });
  };
  onSendVerificationEmail = async (user: ApiUser) => {
    return apiclients.idashboardClient.SendVerificationEmail({ user_id: user.id });
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  render() {
    return <DashboardHomeView {...this.props} api={this} hasPermission={this.hasPermission} />;
  }
}

export default withRefresh()(DashboardHome);
