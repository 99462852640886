//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState, useEffect } from "react";
import { Modal, Form, DropdownProps } from "semantic-ui-react";
import { ErrorMessage, FormActionButtonCreate, FormActionButtonCancel, ListAction, Loading } from "../../ui/lib";
import { Cached as apiClients } from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { ListRegionsRequest as ApiListRegionRequest, Region as ApiRegion } from "../../api/platform/v1/platform";
import { CopyBackupRequest as ApiCopyBackupRequest, Backup as ApiBackup } from "../../api/lib";

interface CopyBackupArgs {
  deploymentRegionId: string;
  sourceBackupId: string;
  onCopyBackup: (data: ApiCopyBackupRequest) => Promise<ApiBackup>;
}

interface RegionLoader {
  loading: boolean;
  regions: ApiRegion[];
  provider: string;
}

export const CopyBackupModal = ({ ...args }: CopyBackupArgs) => {
  const [isOpen, setIsOpen] = useState(false);
  const [regionDataLoader, setRegionDataLoader] = useState<RegionLoader>({
    loading: false,
    regions: [],
    provider: "",
  });
  const [selectedRegion, setSelectedRegion] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const getRegions = async () => {
    try {
      setRegionDataLoader({
        ...regionDataLoader,
        loading: true,
      });
      const idOptions: ApiIDOptions = { id: args.deploymentRegionId };
      const regionDetails = await apiClients.authenticationOnly.platformClient.GetRegion(idOptions);

      const listRegionRequest: ApiListRegionRequest = {
        provider_id: regionDetails.provider_id,
      };
      const regionsList = await apiClients.authenticationOnly.platformClient.ListRegions(listRegionRequest);

      const regions = (regionsList.items || []).filter((r) => r.id !== args.deploymentRegionId);

      setRegionDataLoader({
        ...regionDataLoader,
        loading: false,
        regions: regions,
      });

      if (regions.length) {
        setSelectedRegion((regions[0] || {}).id || "");
      }
    } catch (err) {
      setRegionDataLoader({
        ...regionDataLoader,
        loading: false,
      });

      setError(err.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getRegions();
    }
  }, [isOpen]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    // clear state
    setRegionDataLoader({
      loading: false,
      regions: [],
      provider: "",
    });
    setSelectedRegion("");
    setIsOpen(false);
  };

  const onRegionChange = (e: React.SyntheticEvent, args: DropdownProps) => {
    setSelectedRegion(args.value as string);
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);

      await args.onCopyBackup({
        source_backup_id: args.sourceBackupId,
        region_id: selectedRegion,
      });

      closeModal();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ListAction icon="boxes" tooltip="Clone Backup to another region" size="tiny" onClick={openModal} />
      <Modal open={isOpen} closeOnDimmerClick={false} closeOnEscape={false}>
        <Modal.Header>Copy Backup To Different Region</Modal.Header>
        <Modal.Content>
          {regionDataLoader.loading ? (
            <Loading message="Getting Region Details, please wait" />
          ) : (
            <>
              <ErrorMessage active={!!error} message={error} onDismiss={() => setError("")} />
              <p>
                Create Multi Region Backup for <b>{args.sourceBackupId}</b>
              </p>
              <br />
              <Form.Dropdown
                compact
                fluid
                selection
                label="Select Region"
                placeholder="Select Region"
                name="region"
                loading={regionDataLoader.loading}
                disabled={isSubmitting}
                value={selectedRegion}
                onChange={onRegionChange}
                options={regionDataLoader.regions.map((r) => ({
                  key: r.id,
                  text: r.location,
                  value: r.id,
                }))}
              />
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel icon="cancel" onClick={closeModal} disabled={isSubmitting || regionDataLoader.loading} />
          <FormActionButtonCreate primary icon="clone" onClick={onSubmit} title="Copy" disabled={isSubmitting || regionDataLoader.loading} />
        </Modal.Actions>
      </Modal>
    </>
  );
};
