//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
// Author Ewout Prangsma
//

import _ from "lodash";
import React from "react";
import { Dropdown, Header } from "semantic-ui-react";
import { Deployment as ApiDeployment, Organization as ApiOrganization, PlanList as ApiSupportPlanList, Project as ApiProject } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { FlexBox } from "../../ui/_flex";
import { IWithRefreshProps } from "../../util/WithRefresh";
import SupportRequestCustomer from "../support/RequestCustomer";

interface ISupportViewArgs extends IWithRefreshProps {
  deployment: ApiDeployment;
  supportPlans?: ApiSupportPlanList;
  project: ApiProject;
  organization: ApiOrganization;
  can_edit_support_plan: boolean;
  onClickUpdateSupportPlan: (newSupportPlanID: string, newSupportPlanName: string) => void;
}

export const SupportView = ({ ...args }: ISupportViewArgs) => {
  const supportPlanList = args.supportPlans || {};
  const supportPlans = supportPlanList.items || [];
  const supportPlan = _.find(supportPlans, (x) => x.id == args.deployment.support_plan_id);
  return (
    <div>
      <Header sub>
        <FlexBox justify="space-between">
          Support
          <Field>{args.organization.name && args.project && args.deployment && <SupportRequestCustomer {...args} />}</Field>
        </FlexBox>
      </Header>
      <Field>
        <FL>Support plan</FL>
        <FC>
          {!!supportPlan ? supportPlan.name : args.deployment.support_plan_id || "?"}
          {args.can_edit_support_plan && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {supportPlans.map((x) => (
                  <Dropdown.Item key={`support-plan-${x.id}`} text={x.name} onClick={() => args.onClickUpdateSupportPlan(x.id || "", x.name || "")} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
    </div>
  );
};
