//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import apiClients from "../../api/apiclients";
import { SchedulingPolicy } from "../../api/lib";
import { ObjectJSONModalView, EditIcon, FormActionButtonCancel, FormActionButtonSave, ErrorMessage } from "../../ui/lib";
import { Form, Header, Modal, DropdownItemProps, Button, DropdownProps, Grid, Loader, Container } from "semantic-ui-react";
import styled from "@emotion/styled";

const StyledDropdown = styled(Form.Select)`
  width: 100%;

  & > .ui.selection.dropdown {
    width: 100%;
  }
`;
export interface ISchedulingPoliciesEditViewArgs {
  schedulingPolicies: SchedulingPolicy[];
  selected: string[];
  onSave: (ids: string[]) => void;
  header?: string;
  readonly?: boolean;
}

export const SchedulingPoliciesEditView = (args: ISchedulingPoliciesEditViewArgs) => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [schedulingPolicies, setSchedulingPolicies] = useState(args.schedulingPolicies);
  const [allSchedulingPolicies, setAllSchedulingPolicies] = useState<SchedulingPolicy[]>([]);
  const [availableOptions, setAvailableOptions] = useState<DropdownItemProps[]>([{ value: "", text: "empty" }]);
  const [currentPolicy, setCurrentPolicy] = useState<SchedulingPolicy>();
  const isEmpty = !args.schedulingPolicies.length;

  useEffect(() => {
    async function load() {
      try {
        const list = await apiClients.idashboardClient.ListAllSchedulingPolicies({});
        const allSchedulingPolicies = list.items || [];
        setAllSchedulingPolicies(allSchedulingPolicies);
        if (args.selected.length > 0 && args.schedulingPolicies.length == 0) {
          setSchedulingPolicies(schedulingPoliciesFromIds(allSchedulingPolicies, args.selected));
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    load();
  }, []);

  const schedulingPoliciesFromIds = (all: SchedulingPolicy[], ids: string[]): SchedulingPolicy[] => {
    return ids.map(
      (v): SchedulingPolicy => {
        return (
          all.find((p) => {
            return p.id == v;
          }) || { id: v, spec: { name: undefined } }
        );
      }
    );
  };

  useEffect(() => {
    setAvailableOptions(
      allSchedulingPolicies
        .filter((v) => {
          return !schedulingPolicies.some((x) => {
            return x.id == v.id;
          });
        })
        .map(
          (x): DropdownItemProps => {
            return { value: x.id || "", text: (x.spec && x.spec.name) || "undefined" };
          }
        )
    );
  }, [allSchedulingPolicies, schedulingPolicies]);

  const save = () => {
    try {
      args.onSave(schedulingPolicies.map((x) => x.id || ""));
      setIsEditing(false);
    } catch (e) {
      setError(e);
    }
  };

  const trigger = (
    <span>
      <Header sub>Scheduling policies</Header>
      <div>
        {isEmpty && "No scheduling policies defined"}
        {schedulingPolicies.map((policy, i) => (
          <ObjectJSONModalView
            key={policy.id + "-" + i}
            value={policy}
            title={policy.id || ""}
            text={(policy.spec && policy.spec.name) || ""}
            icon={policy.spec == undefined || policy.spec.name == undefined ? "bug" : undefined}
          />
        ))}
      </div>
      {!args.readonly && <EditIcon onClick={() => setIsEditing(true)} />}
    </span>
  );

  const onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setCurrentPolicy(
      allSchedulingPolicies.find((item) => {
        return item.id === data.value;
      })
    );
  };

  const onAdd = () => {
    if (currentPolicy) {
      setSchedulingPolicies(schedulingPolicies.concat([currentPolicy]));
    }
  };

  const onRemove = (id: string) => {
    setSchedulingPolicies(schedulingPolicies.filter((policy) => policy.id != id));
  };

  return (
    <Modal centered open={isEditing} trigger={trigger}>
      <Modal.Header>{args.header || "Scheduling policies"}</Modal.Header>
      <Modal.Content>
        <Loader active={loading}></Loader>
        <ErrorMessage active={!!error} message={error} />
        <Grid width="16">
          <Grid.Row>
            <Grid.Column width={12}>
              <StyledDropdown action="Add" options={availableOptions} onChange={onChange} />
            </Grid.Column>
            <Grid.Column width={4} floated="right">
              <FormActionButtonSave icon="add" primary onClick={onAdd} title="Add" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Container>
                If you clear all scheduling policies the default scheduling policies from NodeSize will be used. If you don't want any scheduling policies set
                them to contain only <b>Empty</b> scheduling policy
              </Container>
            </Grid.Column>
          </Grid.Row>
          {schedulingPolicies.map((policy, idx) => (
            <Grid.Row>
              <Grid.Column width={12}>
                <ObjectJSONModalView fluid value={policy} title={policy.id || ""} text={(policy.spec && policy.spec.name) || ""} size="medium" />
              </Grid.Column>
              <Grid.Column width={4}>
                <Button negative icon="trash" basic title="Remove" onClick={() => onRemove(policy.id || "")} />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel
          onClick={() => {
            // reset to selected state
            setSchedulingPolicies(schedulingPoliciesFromIds(allSchedulingPolicies, args.selected));
            setIsEditing(false);
          }}
        />
        <FormActionButtonSave primary onClick={save} icon={"check"} title="Save" />
      </Modal.Actions>
    </Modal>
  );
};
