//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import styled from "@emotion/styled";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Menu, Statistic, Segment, SemanticWIDTHS, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  AuthenticationStatistics as ApiAuthenticationStatistics,
  DeploymentStatistics as ApiDeploymentStatistics,
  OrganizationStatistics as ApiOrganizationStatistics,
} from "../../api/lib";
import { ContentSegment, Loading, SecondaryMenu, Section, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import _ from "lodash";

const Tiny = styled("div")`
  font-size: 40%;
`;

interface IStatisticsViewArgs {
  widths: SemanticWIDTHS;
  auth?: ApiAuthenticationStatistics;
  deployments?: ApiDeploymentStatistics;
  organizations?: ApiOrganizationStatistics;
}

const AuthStatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const has_auth = !!args.auth;
  const auth = args.auth || {};
  const today = auth.today || {};
  const last_7days = auth.last_7days || {};
  return (
    <div>
      <Section>
        <SectionHead>
          <SectionHeader title="Authentication" />
        </SectionHead>
        <SectionContent>
          {!has_auth && <Loading />}
          {has_auth && (
            <Statistic.Group widths={args.widths}>
              <Statistic label="Logins today" value={today.logins || 0} />
              <Statistic label="Signups today" value={today.signups || 0} />
              <Statistic label="Logins last 7 days" value={last_7days.logins || 0} />
              <Statistic label="Signups last 7 days" value={last_7days.signups || 0} />
            </Statistic.Group>
          )}
        </SectionContent>
      </Section>
    </div>
  );
};

const OrganizationStatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const has_orgs = !!args.organizations;
  const orgs = args.organizations || {};
  const by_tier = orgs.by_tier || [];
  return (
    <div>
      <Section>
        <SectionHead>
          <SectionHeader title="Organizations" />
        </SectionHead>
        <SectionContent>
          {!has_orgs && <Loading />}
          {has_orgs && (
            <Statistic.Group widths={args.widths}>
              <Statistic label="Created today" value={orgs.today || 0} />
              <Statistic label="Created yesterday" value={orgs.yesterday || 0} />
              <Statistic label="Created last 7 days" value={orgs.last_7days || 0} />
            </Statistic.Group>
          )}
          {has_orgs && (
            <Statistic.Group widths={args.widths}>
              <Statistic label="Total" value={orgs.total || 0} />
              {by_tier.map((x) => (
                <Statistic key={x.tier_id || ""} label={x.tier_name || ""} value={x.count || 0} />
              ))}
            </Statistic.Group>
          )}
        </SectionContent>
      </Section>
    </div>
  );
};

const DeploymentStatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const has_deployments = !!args.deployments;
  const deployments = args.deployments || {};
  const by_tier = deployments.by_tier || [];
  const by_model = deployments.by_model || [];
  const by_support_plan = deployments.by_support_plan || [];
  const by_node_size = _.filter(deployments.by_node_size || [], (x) => (x.count || 0) > 0 || (x.paused || 0) > 0);
  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Deployments" />
      </SectionHead>
      <SectionContent>
        {!has_deployments && <Loading />}
        {has_deployments && (
          <Statistic.Group widths={args.widths}>
            <Statistic>
              <Statistic.Value>
                {deployments.total || 0}
                <Tiny>({deployments.total_paused || 0} paused)</Tiny>
              </Statistic.Value>
              <Statistic.Label>Total active</Statistic.Label>
            </Statistic>
            <Statistic label="Created today" value={deployments.today || 0} />
            <Statistic label="Created yesterday" value={deployments.yesterday || 0} />
            <Statistic label="Created last 7 days" value={deployments.last_7days || 0} />
            <Statistic label="Created forever" value={deployments.forever || 0} />
          </Statistic.Group>
        )}
        {has_deployments && (
          <Segment>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.HeaderCell>By tier</Table.HeaderCell>
                  <Table.Cell>
                    <p>
                      {by_tier.map((x, i) => (
                        <span>
                          {x.tier_name || ""}: {x.count || 0}
                          {(x.paused || 0) > 0 && <span> ({x.paused || 0} paused)</span>}
                          {i + 1 < by_tier.length && <span>, </span>}
                        </span>
                      ))}
                    </p>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>By model</Table.HeaderCell>
                  <Table.Cell>
                    <p>
                      {by_model.map((x, i) => (
                        <span>
                          {x.model_name || ""}: {x.count || 0}
                          {(x.paused || 0) > 0 && <span> ({x.paused || 0} paused)</span>}
                          {i + 1 < by_model.length && <span>, </span>}
                        </span>
                      ))}
                    </p>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>By support plan</Table.HeaderCell>
                  <Table.Cell>
                    {by_support_plan.map((x, i) => (
                      <span>
                        {x.support_plan_name || ""}: {x.count || 0}
                        {(x.paused || 0) > 0 && <span> ({x.paused || 0} paused)</span>}
                        {i + 1 < by_support_plan.length && <span>, </span>}
                      </span>
                    ))}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>By node size</Table.HeaderCell>
                  <Table.Cell>
                    <p>
                      {by_node_size.map((x, i) => (
                        <span>
                          {x.node_size_name || ""}: {x.count || 0}
                          {(x.paused || 0) > 0 && <span> ({x.paused || 0} paused)</span>}
                          {i + 1 < by_node_size.length && <span>, </span>}
                        </span>
                      ))}
                    </p>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        )}
      </SectionContent>
    </Section>
  );
};

interface IStatisticsProps extends IWithRefreshProps, RouteComponentProps {}

interface IStatisticsState {
  auth?: ApiAuthenticationStatistics;
  deployments?: ApiDeploymentStatistics;
  organizations?: ApiOrganizationStatistics;
}

class Statistics extends Component<IStatisticsProps, IStatisticsState> {
  state: IStatisticsState = {
    auth: undefined,
    deployments: undefined,
    organizations: undefined,
  };

  reloadAuthenticationStats = async () => {
    const auth = await apiClients.idashboardClient.GetAuthenticationStatistics();
    this.setState({ auth: auth });
  };

  reloadOrganizationStats = async () => {
    const orgs = await apiClients.idashboardClient.GetOrganizationStatistics();
    this.setState({ organizations: orgs });
  };

  reloadDeploymentStats = async () => {
    const count = await apiClients.idashboardClient.GetDeploymentStatistics({});
    this.setState({ deployments: count });
  };

  refreshStats = () => {
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadAuthenticationStats, 120000);
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadOrganizationStats, 120000);
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadDeploymentStats, 120000);
  };

  componentDidMount() {
    this.refreshStats();
  }

  render() {
    return (
      <ContentSegment>
        <SecondaryMenu>
          <Menu.Item header>Statistics</Menu.Item>
        </SecondaryMenu>
        <div>
          <AuthStatisticsView {...this.state} widths="5" />
          <OrganizationStatisticsView {...this.state} widths="5" />
          <DeploymentStatisticsView {...this.state} widths="5" />
        </div>
      </ContentSegment>
    );
  }
}

export default withRefresh()(Statistics);
