// 
// DISCLAIMER
// 
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
// 
// Author Robert Stam
// 

import createHistory from 'history/createBrowserHistory'

export default createHistory()
