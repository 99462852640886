//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Grid, Loader, Menu, Segment } from "semantic-ui-react";
import Markdown from "react-markdown";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, DataProcessingAddendum as ApiDataProcessingAddendum } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import {
  ContentSegment,
  Field,
  FieldContent as FC,
  FieldLabel as FL,
  LoaderBox,
  MenuActionBack,
  SecondaryMenu,
  Section,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IDataProcessingAddendumViewArgs {
  dpa: ApiDataProcessingAddendum;
}

const DataProcessingAddendumView = ({ ...args }: IDataProcessingAddendumViewArgs) => (
  <div>
    <Segment>
      <Grid width="16">
        <Grid.Column width="8">
          <Field>
            <FL>ID</FL>
            <FC>
              <div>{args.dpa.id || "-"}</div>
            </FC>
          </Field>
        </Grid.Column>
        <Grid.Column width="8">
          <Field>
            <FL>Created</FL>
            <FC>{args.dpa.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.dpa.created_at} label="Created at" /> : "-"}</FC>
          </Field>
        </Grid.Column>
      </Grid>
    </Segment>
    <Segment>
      <Markdown source={args.dpa.content || ""} />
    </Segment>
  </div>
);

interface IDataProcessingAddendumDetailsProps extends IWithRefreshProps, RouteComponentProps {
  dpaId: string;
}

interface IDataProcessingAddendumDetailsState {
  dpa?: ApiDataProcessingAddendum;
}

// The component to show DPA
class DataProcessingAddendumDetails extends Component<IDataProcessingAddendumDetailsProps, IDataProcessingAddendumDetailsState> {
  state: IDataProcessingAddendumDetailsState = {
    dpa: undefined,
  };

  reloadDataProcessingAddendum = async () => {
    const idOptions: ApiIDOptions = { id: this.props.dpaId };
    var dpa = await apiClients.idashboardClient.GetDataProcessingAddendum(idOptions);
    this.setState({
      dpa: dpa,
    });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadDataProcessingAddendum);
  }

  render() {
    const has_dpa = !!this.state.dpa;
    const dpa = this.state.dpa || {};

    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Data Processing Addendum Details</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        {has_dpa && (
          <div>
            <Section>
              <SectionHead>
                <SectionHeader title="Details" />
              </SectionHead>
              <SectionContent>
                <DataProcessingAddendumView {...this.props} {...this.state} dpa={dpa} />
              </SectionContent>
            </Section>
          </div>
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(DataProcessingAddendumDetails);
