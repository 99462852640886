//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import { startCase } from "lodash";
import React from "react";
import { Modal, Segment } from "semantic-ui-react";
import { Routes } from "../../routes";
import { TextLink, FormActionButtonCancel } from "../../ui/lib";
import { Field, FieldContent as FC, FieldLabel as FL } from "../../ui/_style";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { AssignedFiltersForJobViewArgs } from "./types";

function AssignedFiltersForJobView({ deploymentFilters = {}, onClose }: AssignedFiltersForJobViewArgs) {
  const ignoreFilters = ["options"];
  const timestampFields = ["expires_after", "expires_before", "from", "to", "last_password_rotated_at_before"];

  const getValue = (filterKey: string) => {
    const value = deploymentFilters[filterKey];
    if (filterKey === "deployment_id") {
      return (
        <TextLink href={Routes.dashboard_support_deployment_detailsWithId(deploymentFilters[filterKey])} label={deploymentFilters[filterKey]} target="_blank" />
      );
    }

    if (timestampFields.includes(filterKey)) {
      return <DateTimePopupWithUTCAndLocalTime label={startCase(filterKey)} dateTime={value} />;
    }

    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }

    return deploymentFilters[filterKey];
  };

  return (
    <Modal open>
      <Modal.Header>Deployment filters</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Segment>
            {Object.keys(deploymentFilters).map((filterKey) => {
              if (ignoreFilters.includes(filterKey)) return null;
              return (
                <Field>
                  <FL>{startCase(filterKey)}</FL>
                  <FC>{getValue(filterKey)}</FC>
                </Field>
              );
            })}
          </Segment>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
}

export default AssignedFiltersForJobView;
