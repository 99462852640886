//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import styled from "@emotion/styled";
import { MetricsInfo_PrometheusAlertStatus as ApiMetricsInfo_PrometheusAlertStatus } from "../../api/lib";

const PreventWrappingSpan = styled("span")`
  white-space: nowrap;
`;
export interface IPrometheusAlertSummaryViewArgs {
  status?: ApiMetricsInfo_PrometheusAlertStatus;
}

export const PrometheusAlertSummaryView = ({ ...args }: IPrometheusAlertSummaryViewArgs) => {
  const status = args.status || {};
  const firing_info = (status.firing_info || []).filter((alert) => !alert.is_silenced);
  const by_severity = _.groupBy(firing_info, "severity");
  const critical = (by_severity["critical"] || []).length;
  const error = (by_severity["error"] || []).length;
  const warning = (by_severity["warning"] || []).length;
  const informational = (by_severity["informational"] || []).length;

  const format = (value: number, color: string) => {
    if (value == 0) {
      return <span> - </span>;
    }
    return <span style={{ color: color }}> {value} </span>;
  };

  return (
    <PreventWrappingSpan>
      {format(critical, "red")} /{format(error, "purple")} /{format(warning, "orange")} /{format(informational, "blue")}
    </PreventWrappingSpan>
  );
};
