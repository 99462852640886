//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import { isEmpty, isEqual, split } from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Header, Modal, TextArea } from "semantic-ui-react";
import { EditIcon, FormActionButtonCancel, FormActionButtonSave, FlexBox, ErrorMessage, StyledInlineLoader } from "../../ui/lib";
import { AsyncResult } from "../../util/Types";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";

export interface IEndpointAllowlistsViewArgs extends CommentCreationArgs {
  allowed_endpoints: string[];
  can_edit_allowlisted_endpoints: boolean;
  onSaveAllowlistedEndpoints: (endpoints: string[]) => Promise<AsyncResult<void>>;
}

export const EndpointAllowlistsView = ({ ...args }: IEndpointAllowlistsViewArgs) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editAllowlistedEndpoints, setEndpoints] = useState(args.allowed_endpoints);
  const [comment, setComment] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  const allowlisted_endpoints = args.allowed_endpoints;
  const has_allowlisted_endpoints = !isEmpty(allowlisted_endpoints);
  const can_edit_allowlisted_endpoints = args.can_edit_allowlisted_endpoints;

  useEffect(() => {
    if (!isEqual(args.allowed_endpoints, editAllowlistedEndpoints)) {
      setComment(`Setting allowlisted endpoints to ${editAllowlistedEndpoints.join(", ") || "none"}`);
    } else {
      setComment("");
    }
  }, [editAllowlistedEndpoints]);

  const textToEndpoints = (v: string) => {
    return isEmpty(v) ? [] : split(v, "\n");
  };

  const startEditing = () => {
    setEndpoints(args.allowed_endpoints);
    setIsEditing(true);
  };
  const cancelEditing = () => {
    setIsEditing(false);
    setLoading(false);
    setError(undefined);
    setCommentCreationFailed(false);
  };
  const saveEdits = async () => {
    setLoading(true);
    const { error } = (!commentCreationFailed && (await args.onSaveAllowlistedEndpoints(editAllowlistedEndpoints))) || {};
    setCommentCreationFailed(false);
    if (!!error) {
      setError(error);
      setLoading(false);
      return;
    }

    const { error: commentsError } = await args.createComment(comment);
    if (!!commentsError) {
      setCommentCreationFailed(true);
      setError("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }
    setLoading(false);
    setIsEditing(false);
  };

  return (
    <Modal
      centered
      open={isEditing}
      trigger={
        <span>
          <Header sub>Endpoint allowlists</Header>
          <FlexBox>
            {!has_allowlisted_endpoints && <span>None</span>}
            {has_allowlisted_endpoints && (
              <span>
                {allowlisted_endpoints.map((x) => (
                  <div>{x}</div>
                ))}
              </span>
            )}
            {!isEditing && <div>{can_edit_allowlisted_endpoints && <EditIcon onClick={startEditing} />}</div>}
          </FlexBox>
        </span>
      }
    >
      <Modal.Header>
        <FlexBox justify="space-between">
          <span>Endpoint allowlists </span>
          {loading && <StyledInlineLoader inline />}
        </FlexBox>
      </Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} message={error} />
        <Form>
          <Form.Field disabled={commentCreationFailed}>
            <TextArea rows="10" value={editAllowlistedEndpoints.join("\n")} onChange={(e, d) => setEndpoints(textToEndpoints(d.value as string))} />
          </Form.Field>
          <Form.Field required>
            <label>Please provide the reason for this change </label>
            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>Endpoint allowlist</b>. Otherwise, a default comment will be provided which can be
              changed if required.
            </sub>
          </Form.Field>
        </Form>
        <div>{!isEditing && <div>{can_edit_allowlisted_endpoints && <EditIcon onClick={startEditing} />}</div>}</div>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={cancelEditing} />
        {commentCreationFailed ? (
          <FormActionButtonSave primary onClick={saveEdits} icon="comment" disabled={!comment.trim() || loading} title="Retry saving comment" />
        ) : (
          <FormActionButtonSave
            primary
            onClick={saveEdits}
            icon={!!error ? "refresh" : "check"}
            disabled={!comment.trim() || loading}
            title={!!error ? "Retry" : "Save"}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};
