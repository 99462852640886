//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Icon, Loader, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IPAllowlist as ApiIPAllowlist } from "../../api/security/v1/security";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { SubID } from "../../ui/lib";
import _ from "lodash";

interface IIPAllowlistViewArgs extends IIPAllowlistState {}

const IPAllowlistView = ({ ...args }: IIPAllowlistViewArgs) => {
  if (args.ipAllowlist) {
    const cidr_ranges = args.ipAllowlist.cidr_ranges || [];
    const remote_inspection_allowed = !!args.ipAllowlist.remote_inspection_allowed;
    const warnings = args.ipAllowlist.warnings || [];
    const hasWarnings = !_.isEmpty(warnings);
    return (
      <div>
        <div>
          {args.ipAllowlist.name}{" "}
          <SubID>
            <Popup
              trigger={
                <Icon.Group size="large">
                  <Icon name="sign-in" />
                  <Icon name={remote_inspection_allowed ? "check" : "cancel"} color={remote_inspection_allowed ? "green" : "orange"} corner="top right" />
                </Icon.Group>
              }
              content={<p>{remote_inspection_allowed ? "Remote inspection allowed" : "Remote inspection not allowed"}</p>}
            />{" "}
            {hasWarnings && (
              <Popup
                trigger={
                  <Icon.Group size="large">
                    <Icon name="exclamation triangle" color="red" />
                  </Icon.Group>
                }
                content={
                  <p>
                    {warnings.map((w) => (
                      <div>{w}</div>
                    ))}
                  </p>
                }
              />
            )}
            ({cidr_ranges.join(", ")})
          </SubID>
        </div>
      </div>
    );
  }

  return <span>retrieving...</span>;
};

interface IIPAllowlistProps extends IWithRefreshProps {
  ipAllowlistId?: string;
}

interface IIPAllowlistState {
  ipAllowlist?: ApiIPAllowlist;
}

class IPAllowlist extends Component<IIPAllowlistProps, IIPAllowlistState> {
  state: IIPAllowlistState = {
    ipAllowlist: undefined,
  };

  reloadIPAllowlist = async () => {
    const idOptions: ApiIDOptions = { id: this.props.ipAllowlistId };
    const ipAllowlist = await apiClients.idashboardClient.GetIPAllowlist(idOptions);
    this.setState({ ipAllowlist: ipAllowlist });
  };

  componentDidMount() {
    if (this.props.ipAllowlistId) {
      this.props.refreshNow && this.props.refreshNow(this.reloadIPAllowlist);
    }
  }

  render() {
    if (!this.props.ipAllowlistId) {
      return <div>-</div>;
    }

    return (
      <div>
        <IPAllowlistView {...this.state} />
        <Loader size="mini" active={this.props.loading} inline />
      </div>
    );
  }
}

export default withRefresh()(IPAllowlist);
