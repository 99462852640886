//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { isEmpty, map } from "lodash";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid, Icon, Loader, Menu, Segment, Table } from "semantic-ui-react";
import apiClients, { Cached as cacheApiClients } from "../../api/apiclients";
import {
  AddonPrice as ApiAddonPrice,
  Currency as ApiCurrency,
  IDOptions as ApiIDOptions,
  Plan as ApiPlan,
  PriceRow as ApiPriceRow,
  PriceTable as ApiPriceTable,
  Subject as ApiSubject,
  SupportPlanPrice as ApiSupportPlanPrice,
  Variable as ApiVariable,
} from "../../api/lib";
import TabLayout from "../../components/Tab/Tab";
import { ITabConfig } from "../../components/Tab/TabTypes";
import TabLayoutWithRouter from "../../components/Tab/TabWithRouter";
import {
  ContentSegment,
  Field,
  FieldContent as FC,
  FieldLabel as FL,
  LoaderBox,
  MenuActionBack,
  SecondaryMenu,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CreatePricingPlanAssignment from "./CreatePricingPlanAssignment";
import PriceCalculator from "./PriceCalculator";
import PricingPlanAssignmentList from "./PricingPlanAssignmentList";
import SelectPricingPlanAssignmentSubject from "./SelectPricingPlanAssignmentSubject";
import SelectPricingPlanAssignmentSubjects from "./SelectPricingPlanAssignmentSubjects";

interface IPriceRowViewArgs {
  item: ApiPriceRow;
  default_system_currency: ApiCurrency;
}

const PriceRowView = ({ ...args }: IPriceRowViewArgs) => (
  <Table.Row>
    <Table.Cell>{args.item.model || "any"}</Table.Cell>
    <Table.Cell>{args.item.notebook_model || "any"}</Table.Cell>
    <Table.Cell>{args.item.node_size_id || "any"}</Table.Cell>
    <Table.Cell>{args.item.cloud_provider || "any"}</Table.Cell>
    <Table.Cell>{args.item.cloud_region || "any"}</Table.Cell>
    <Table.Cell>{args.item.memory_size_range || "any"}</Table.Cell>
    <Table.Cell>{args.item.disk_size_range || "any"}</Table.Cell>
    <Table.Cell>{args.item.disk_performance_id || "any"}</Table.Cell>
    <Table.Cell>{args.item.network_transfer_destination || "any"}</Table.Cell>
    <Table.Cell>{args.item.server_type || "any"}</Table.Cell>
    <Table.Cell>{args.item.price || 0}</Table.Cell>
    <Table.Cell>{args.item.currency_id || `default (${args.default_system_currency.id})`}</Table.Cell>
    <Table.Cell>{args.item.credits || 0}</Table.Cell>
  </Table.Row>
);

interface IPriceTableViewArgs {
  table: ApiPriceTable;
  default_system_currency: ApiCurrency;
}

const PriceTableView = ({ ...args }: IPriceTableViewArgs) => {
  if (isEmpty(args.table.rows)) {
    return <span>No rows defined</span>;
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Deployment Model</Table.HeaderCell>
          <Table.HeaderCell>Notebook Model</Table.HeaderCell>
          <Table.HeaderCell>Node size</Table.HeaderCell>
          <Table.HeaderCell>Cloud provider</Table.HeaderCell>
          <Table.HeaderCell>Region</Table.HeaderCell>
          <Table.HeaderCell>Memory size range</Table.HeaderCell>
          <Table.HeaderCell>Disk size range</Table.HeaderCell>
          <Table.HeaderCell>Disk performance</Table.HeaderCell>
          <Table.HeaderCell>Network transfer destination</Table.HeaderCell>
          <Table.HeaderCell>Server type</Table.HeaderCell>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <Table.HeaderCell>Currency</Table.HeaderCell>
          <Table.HeaderCell>Credits</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body
        children={map(args.table.rows || [], (x: ApiPriceRow, index: any) => (
          <PriceRowView {...args} key={index} item={x} />
        ))}
      />
    </Table>
  );
};

interface IVariableViewArgs {
  name: string;
  item: ApiVariable;
  default_system_currency: ApiCurrency;
}

const VariableView = ({ ...args }: IVariableViewArgs) => (
  <div>
    <SecondaryMenu>
      <Menu.Item header>{args.name}</Menu.Item>
    </SecondaryMenu>
    <PriceTableView {...args} table={args.item.prices || {}} />
  </div>
);

interface ISupportPlanPriceRowArgs {
  item: ApiSupportPlanPrice;
}

const SupportPlanPriceRow = ({ ...args }: ISupportPlanPriceRowArgs) => (
  <Table.Row>
    <Table.Cell>{args.item.id}</Table.Cell>
    <Table.Cell>{args.item.factor}</Table.Cell>
    <Table.Cell>{args.item.min_monthly_price}</Table.Cell>
  </Table.Row>
);

interface ISupportPlanPricesTableArgs {
  list: ApiSupportPlanPrice[];
}

const SupportPlanPricesTable = ({ ...args }: ISupportPlanPricesTableArgs) => {
  if (isEmpty(args.list)) {
    return <span>No support plan prices defined</span>;
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Plan</Table.HeaderCell>
          <Table.HeaderCell>Factor</Table.HeaderCell>
          <Table.HeaderCell>Min. monthly price</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body
        children={map(args.list || [], (x: ApiSupportPlanPrice, index: any) => (
          <SupportPlanPriceRow key={index} item={x} />
        ))}
      />
    </Table>
  );
};

interface IAddonPriceRowArgs {
  item: ApiAddonPrice;
}

const AddonPriceRow = ({ ...args }: IAddonPriceRowArgs) => (
  <Table.Row>
    <Table.Cell>{args.item.id}</Table.Cell>
    <Table.Cell>{args.item.factor}</Table.Cell>
    <Table.Cell>{args.item.fixed_monthly_price}</Table.Cell>
  </Table.Row>
);

interface IAddonPricesTableArgs {
  list: ApiAddonPrice[];
}

const AddonPricesTable = ({ ...args }: IAddonPricesTableArgs) => {
  if (isEmpty(args.list)) {
    return <span>No addon prices defined</span>;
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Addon</Table.HeaderCell>
          <Table.HeaderCell>Factor</Table.HeaderCell>
          <Table.HeaderCell>Fixed monthly price</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body
        children={map(args.list || [], (x: ApiSupportPlanPrice, index: any) => (
          <AddonPriceRow key={index} item={x} />
        ))}
      />
    </Table>
  );
};

interface IPlanDetailsViewArgs {
  plan: ApiPlan;
  default_system_currency: ApiCurrency;
}

const PlanDetailsView = ({ ...args }: IPlanDetailsViewArgs) => (
  <Grid width="16">
    <Grid.Column width="8">
      <Field>
        <FL>Name</FL>
        <FC>{args.plan.name || "-"}</FC>
      </Field>
      <Field>
        <FL>Description</FL>
        <FC>{args.plan.description || "-"}</FC>
      </Field>
    </Grid.Column>
    <Grid.Column width="8">
      <Field>
        <FL>Created</FL>
        <FC>{args.plan.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.plan.created_at} label="Created at" /> : "-"}</FC>
      </Field>
      <Field>
        <FL>Lock state</FL>
        <FC>{args.plan.locked ? "Locked" : "Unlocked"}</FC>
      </Field>
    </Grid.Column>
  </Grid>
);

const PlanVariablesView = ({ ...args }: IPlanDetailsViewArgs) => (
  <div>
    <TabLayout
      allowOverflow
      id="planVariablesView"
      tabClassForObserving="variablesTabView"
      tabConfig={[
        {
          menuItem: { id: "licenseMemoryTab", children: "License memory" },
          content: () => (
            <VariableView {...args} name="Deployment License price for each GiB or memory per hour" item={args.plan.license_memory_per_gb_hour || {}} />
          ),
        },
        {
          menuItem: { id: "licenseDiskTab", children: "License disk" },
          content: () => (
            <VariableView {...args} name="Deployment License price for each GiB of storage per hour" item={args.plan.license_disk_per_gb_hour || {}} />
          ),
        },
        {
          menuItem: { id: "operationsMemoryTab", children: "Operations memory" },
          content: () => (
            <VariableView {...args} name="Deployment Operations price for each GiB or memory per hour" item={args.plan.operations_memory_per_gb_hour || {}} />
          ),
        },
        {
          menuItem: { id: "operationsDiskTab", children: "Operations disk" },
          content: () => (
            <VariableView {...args} name="Deployment Operations price for each GiB of storage per hour" item={args.plan.operations_disk_per_gb_hour || {}} />
          ),
        },
        {
          menuItem: { id: "notebookLicenseMemoryTab", children: "Notebook license memory" },
          content: () => (
            <VariableView {...args} name="Notebook License price for each GiB or memory per hour" item={args.plan.notebook_license_memory_per_gb_hour || {}} />
          ),
        },
        {
          menuItem: { id: "notebookLicenseDiskTab", children: "Notebook license disk" },
          content: () => (
            <VariableView {...args} name="Notebook License price for each GiB of storage per hour" item={args.plan.notebook_license_disk_per_gb_hour || {}} />
          ),
        },
        {
          menuItem: { id: "notebookOperationsMemoryTab", children: "Notebook Operations memory" },
          content: () => (
            <VariableView
              {...args}
              name="Notebook Operations price for each GiB or memory per hour"
              item={args.plan.notebook_operations_memory_per_gb_hour || {}}
            />
          ),
        },
        {
          menuItem: { id: "notebookOperationsDiskTab", children: "Notebook Operations disk" },
          content: () => (
            <VariableView
              {...args}
              name="Notebook Operations price for each GiB of storage per hour"
              item={args.plan.notebook_operations_disk_per_gb_hour || {}}
            />
          ),
        },
        {
          menuItem: { id: "cloudMemoryTab", children: "Cloud memory" },
          content: () => <VariableView {...args} name="Cloud resource price for each GiB of memory per hour" item={args.plan.cloud_memory_per_gb_hour || {}} />,
        },
        {
          menuItem: { id: "cloudDiskTab", children: "Cloud disk (size)" },
          content: () => <VariableView {...args} name="Cloud resource price for each GiB of storage per hour" item={args.plan.cloud_disk_per_gb_hour || {}} />,
        },
        {
          menuItem: { id: "cloudDiskCountTab", children: "Cloud disk (count)" },
          content: () => <VariableView {...args} name="Cloud resource price for number of disks per hour" item={args.plan.cloud_disk_per_hour || {}} />,
        },
        {
          menuItem: { id: "cloudObjectStorageTab", children: "Cloud object storage" },
          content: () => (
            <VariableView
              {...args}
              name="Cloud resource price for each GB of object storage (backup and audit-log) per hour"
              item={args.plan.cloud_object_storage_per_gb_hour || {}}
            />
          ),
        },
        {
          menuItem: { id: "networkEgressTab", children: "Network egress" },
          content: () => (
            <VariableView {...args} name="Network price for traffic leaving the region per GiB" item={args.plan.network_egress_traffic_per_gb || {}} />
          ),
        },
        {
          menuItem: { id: "networkIngressTab", children: "Network ingress" },
          content: () => (
            <VariableView {...args} name="Network price for traffic entering the region per GiB" item={args.plan.network_ingress_traffic_per_gb || {}} />
          ),
        },
        {
          menuItem: { id: "postInvocationsTab", children: "HTTPS Post invocations" },
          content: () => (
            <VariableView {...args} name="Price for each HTTPS post invocation done (audit-log)" item={args.plan.https_post_per_invocation || {}} />
          ),
        },
        {
          menuItem: { id: "postBodySizeTab", children: "HTTPS Post body size" },
          content: () => (
            <VariableView {...args} name="Price for HTTPS post body size (audit-log) per GiB" item={args.plan.https_post_body_size_per_gb || {}} />
          ),
        },
        {
          menuItem: { id: "supportPlansTab", children: "Support plans" },
          content: () => <SupportPlanPricesTable list={args.plan.support_plan_prices || []} />,
        },
        {
          menuItem: { id: "AddonsTab", children: "Addons" },
          content: () => <AddonPricesTable list={args.plan.addon_prices || []} />,
        },
      ]}
    />
  </div>
);

// Interface decribing the properties of the pricing plan view component
interface IPricingPlanDetailsProps extends IWithRefreshProps, RouteComponentProps {
  planId: string;
}

// Interface decribing the state of the pricing plan view component
interface IPricingPlanDetailsState {
  plan?: ApiPlan;
  default_system_currency?: ApiCurrency;
  creatingAssignment: boolean;
  selectingSubject: boolean;
  selectingSubjects: boolean;
  selectedSubjects?: ApiSubject[];
  assignmentsVersion: number;
}

// The component to show pricing plan view
class PricingPlanDetails extends Component<IPricingPlanDetailsProps, IPricingPlanDetailsState> {
  state: IPricingPlanDetailsState = {
    plan: undefined,
    creatingAssignment: false,
    selectingSubject: false,
    selectingSubjects: false,
    selectedSubjects: undefined,
    assignmentsVersion: 0,
  };

  hasPermission = (p: Permission) => hasSupportPermission(p, this.props.hasPermissionByUrl);

  reloadPlan = async () => {
    const idOptions: ApiIDOptions = { id: this.props.planId };
    const default_system_currency = await cacheApiClients.idashboardClient.GetDefaultSystemCurrency();
    var plan = await apiClients.idashboardClient.GetPlan(idOptions);
    this.setState({
      default_system_currency: default_system_currency,
      plan: plan,
    });
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadPlan, `/Organization/_system/PricingPlan/${this.props.planId}`);
  }

  onCreateAssignment = () => {
    this.setState({
      selectedSubjects: [
        {
          all: true,
        },
      ],
      creatingAssignment: true,
    });
  };

  onCreateAssignmentClose = () => {
    this.setState((old) => {
      return {
        creatingAssignment: false,
        assignmentsVersion: old.assignmentsVersion + 1,
      };
    });
  };

  onSelectSubject = () => {
    this.setState({ selectingSubject: true });
  };

  onSelectSubjectClose = () => {
    this.setState({ selectingSubject: false });
  };

  onSelectSubjects = () => {
    this.setState({ selectingSubjects: true });
  };

  onSelectSubjectsClose = () => {
    this.setState({ selectingSubjects: false });
  };

  onSelectSubjectsFinished = (subjects: ApiSubject[]) => {
    this.setState({
      selectingSubject: false,
      selectingSubjects: false,
      selectedSubjects: subjects,
      creatingAssignment: true,
    });
  };

  render() {
    const has_assignment_create = this.hasPermission("internal-dashboard.pricingplanassignment.create");
    const has_assignment_list = this.hasPermission("internal-dashboard.pricingplanassignment.list");
    const default_system_currency = this.state.default_system_currency;
    const plan = this.state.plan;

    const getTabConfig = (): ITabConfig[] => {
      const panes: ITabConfig[] = [];

      if (this.state.default_system_currency && !!this.state.plan) {
        if (has_assignment_list) {
          panes.push({
            menuItem: { children: "Plan assignment", id: "planAssignmentTab", route: "planAssignment" },
            content: () => (
              <Section>
                <SectionHead>
                  <SectionHeader title="Plan assignments"></SectionHeader>
                  <SectionButtons>
                    <Button disabled={!has_assignment_create} onClick={this.onSelectSubject} icon labelPosition="right">
                      <Icon name="linkify" /> Assign to ...
                    </Button>
                    <Button disabled={!has_assignment_create} onClick={this.onSelectSubjects} icon labelPosition="right">
                      <Icon name="linkify" /> Assign to multiple ...
                    </Button>
                    <Button disabled={!has_assignment_create} onClick={this.onCreateAssignment} icon labelPosition="right" primary>
                      <Icon name="linkify" /> Assign as default
                    </Button>
                    {this.state.creatingAssignment && (
                      <CreatePricingPlanAssignment
                        {...this.props}
                        api={apiClients.idashboardClient}
                        plan_id={this.props.planId}
                        subjects={this.state.selectedSubjects}
                        onClose={this.onCreateAssignmentClose}
                      />
                    )}
                    {this.state.selectingSubject && (
                      <SelectPricingPlanAssignmentSubject
                        {...this.props}
                        api={apiClients.idashboardClient}
                        onClose={this.onSelectSubjectClose}
                        onSelectedSubjects={this.onSelectSubjectsFinished}
                      />
                    )}
                    {this.state.selectingSubjects && (
                      <SelectPricingPlanAssignmentSubjects
                        {...this.props}
                        api={apiClients.idashboardClient}
                        onClose={this.onSelectSubjectsClose}
                        onSelectedSubjects={this.onSelectSubjectsFinished}
                      />
                    )}
                  </SectionButtons>
                </SectionHead>
                <SectionContent>
                  <PricingPlanAssignmentList {...this.props} plan_id={(this.state.plan || {}).id} version={this.state.assignmentsVersion} />
                </SectionContent>
              </Section>
            ),
          });
        }
        panes.push({
          menuItem: { children: "Variables", id: "variablesTab", route: "variables" },
          content: () => (
            <PlanVariablesView
              {...this.props}
              {...this.state}
              default_system_currency={this.state.default_system_currency || {}}
              plan={this.state.plan || {}}
            />
          ),
        });
      }

      return panes;
    };

    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Pricing Plan Details</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        {default_system_currency && plan && (
          <div>
            <Section>
              <SectionHead>
                <SectionHeader title="Details" />
                <SectionButtons>
                  <PriceCalculator {...this.props} />
                </SectionButtons>
              </SectionHead>
              <SectionContent>
                <Segment>
                  <PlanDetailsView {...this.props} {...this.state} default_system_currency={default_system_currency} plan={plan} />
                </Segment>
              </SectionContent>
            </Section>
            <TabLayoutWithRouter id="pricingPlansTabsView" tabConfig={getTabConfig()} />
          </div>
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(PricingPlanDetails);
