//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import Cache from "./Cache";
import apiClients from "../api/apiclients";
import { IDOptions, User as ApiUser } from "../api/lib";
import { isEmpty } from "lodash";

export type UserWithDefinedID = Omit<ApiUser, "id"> & Required<Pick<ApiUser, "id">>;
class UserCache {
  static cachedUsers = new Cache<UserWithDefinedID>();
  fetchingInProgress = false;

  getUser = async (userID: string) => {
    const usersFromCache = UserCache.cachedUsers.get(userID);
    if (!isEmpty(usersFromCache) && !!usersFromCache) {
      return usersFromCache;
    }

    if (this.fetchingInProgress) {
      return;
    }

    const req: IDOptions = {
      id: userID,
    };

    this.fetchingInProgress = true;
    const user = (await apiClients.idashboardClient.GetUser(req)) as UserWithDefinedID;
    this.fetchingInProgress = false;
    UserCache.cachedUsers.set(userID, user);
    return user;
  };
}

export default UserCache;
