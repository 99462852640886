//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { OrganizationInfo as ApiOrganizationInfo, SetOrganizationCustomSettingsRequest as ApiSetOrganizationCustomSettingsRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface ISCIMAllowedFieldViewArgs {
  organizationInfo: ApiOrganizationInfo;
  canSetDisableCustomCPUSettings: boolean;
  canSetDisableCustomDiskSettings: boolean;
  onSetOrganizationDisableCustomCPUSettings: (disabled: boolean) => void;
  onSetOrganizationDisableCustomDiskSettings: (disabled: boolean) => void;
}

const CustomDeploymentSettingsToggle = ({ ...args }: ISCIMAllowedFieldViewArgs) => {
  const custom_cpu_settings_disabled = !!args.organizationInfo.custom_cpu_settings_disabled;
  const custom_disk_settings_disabled = !!args.organizationInfo.custom_disk_settings_disabled;
  return (
    <>
      <Field>
        <FL>Custom CPU Settings</FL>
        <FC>
          {custom_cpu_settings_disabled ? "Not Allowed" : "Allowed"}
          {args.canSetDisableCustomCPUSettings && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!custom_cpu_settings_disabled && <Dropdown.Item text="Do not allow" onClick={() => args.onSetOrganizationDisableCustomCPUSettings(true)} />}
                {custom_cpu_settings_disabled && <Dropdown.Item text="Allow" onClick={() => args.onSetOrganizationDisableCustomCPUSettings(false)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Custom Disk Settings</FL>
        <FC>
          {custom_disk_settings_disabled ? "Not Allowed" : "Allowed"}
          {args.canSetDisableCustomDiskSettings && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!custom_disk_settings_disabled && <Dropdown.Item text="Do not allow" onClick={() => args.onSetOrganizationDisableCustomDiskSettings(true)} />}
                {custom_disk_settings_disabled && <Dropdown.Item text="Allow" onClick={() => args.onSetOrganizationDisableCustomDiskSettings(false)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
    </>
  );
};

interface ICustomDeploymentSettingsFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface ICustomDeploymentSettingsFieldState extends IOrganizationDetailsFieldState {
  commentPrompt: ICommentPromptStateForIntegrations;
}

class SCIMAllowedField extends OrganizationDetailsField<ICustomDeploymentSettingsFieldProps, ICustomDeploymentSettingsFieldState> {
  state: ICustomDeploymentSettingsFieldState = {
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };
  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  onSetOrganizationDisableCustomCPUSettings = async (disabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set organization to ${disabled ? "disallow" : "allow"} custom CPU settings`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization allow custom CPU settings, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationCustomSettingsRequest = {
            organization_id: this.props.organization.id,
            disabled: disabled,
          };
          await apiClients.idashboardClient.SetOrganizationDisableCustomCPU(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  onSetOrganizationDisableCustomDiskSettings = async (disabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set organization to ${disabled ? "disallow" : "allow"} custom Disk settings`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization allow custom Disk settings, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationCustomSettingsRequest = {
            organization_id: this.props.organization.id,
            disabled: disabled,
          };
          await apiClients.idashboardClient.SetOrganizationDisableCustomDisk(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const can_set_disable_custom_cpu = this.hasPermission("internal-dashboard.organization.set-disable-custom-cpu");
    const can_set_disable_custom_disk = this.hasPermission("internal-dashboard.organization.set-disable-custom-disk");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <CustomDeploymentSettingsToggle
          {...this}
          {...this.props}
          {...this.state}
          canSetDisableCustomCPUSettings={can_set_disable_custom_cpu}
          canSetDisableCustomDiskSettings={can_set_disable_custom_disk}
        />
      </div>
    );
  }
}

export default withRefresh()(SCIMAllowedField);
