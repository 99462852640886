//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import _ from "lodash";
import React, { Component } from "react";
import { Dropdown, DropdownProps, Loader } from "semantic-ui-react";
import { Cached as apiClients } from "../api/apiclients";
import { ListRegionsRequest as ApiListRegionsRequest, Region as ApiRegion, RegionList as ApiRegionList } from "../api/lib";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import { SupportOrganizationID } from "../constants";

interface IRegionInputProps extends IWithRefreshProps {
  id?: string;
  providerId?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowAny?: boolean;
  excludePrerelease?: boolean;
  disabled?: boolean;
}

interface IRegionInputState {
  id: string;
  errorMessage?: string;
  loading: boolean;
  regions?: ApiRegionList;
  providerId: string;
  refreshNeeded: boolean;
}

class RegionInputComp extends Component<IRegionInputProps, IRegionInputState> {
  state: IRegionInputState = {
    id: this.props.id || "",
    errorMessage: undefined,
    loading: true,
    providerId: "",
    refreshNeeded: false,
  };

  reloadRegions = async (providerId?: string) => {
    this.setState({ loading: true });
    if (providerId) {
      const req: ApiListRegionsRequest = {
        provider_id: providerId,
        organization_id: SupportOrganizationID,
      };
      const regions = await apiClients.authenticationOnly.platformClient.ListRegions(req);
      this.setState({ regions: regions, loading: false });
    } else {
      this.setState({ regions: undefined, loading: false });
    }
  };

  refreshRegions = (providerId?: string) => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadRegions(providerId));
  };

  static getDerivedStateFromProps(props: IRegionInputProps, state: IRegionInputState) {
    const regionId = props.id || "";
    if (regionId != state.id) {
      return {
        refreshNeeded: true,
        id: regionId,
      };
    }

    const providerId = props.providerId || "";
    if (providerId != state.providerId) {
      return {
        refreshNeeded: true,
        providerId: providerId,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshRegions(this.props.providerId);
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => this.refreshRegions(this.props.providerId));
    }
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    if (!this.props.disabled) {
      this.setState({ id: newId }, () => {
        this.props.onChange(newId);
      });
    }
  };

  render() {
    const regions = this.state.regions;
    let list = _.clone((regions || {}).items || []);
    if (!!this.props.excludePrerelease) {
      list = _.filter(list, (x) => !x.prerelease);
    }
    if (this.props.allowAny) {
      list.push({ id: "", location: "Any" });
    }
    return (
      <div>
        {this.state.loading && <Loader active size="tiny" />}
        {list && (
          <Dropdown
            fluid
            selection
            placeholder={this.props.placeholder}
            options={_.map(list, (x: ApiRegion) => {
              const preview = x.prerelease ? " [PREVIEW]" : "";
              return {
                key: x.id,
                text: x.location + " " + x.id + preview,
                value: x.id,
              };
            })}
            onChange={this.onChange}
            value={this.state.id}
            disabled={this.props.disabled}
          />
        )}
      </div>
    );
  }
}

export const RegionInput = withRefresh()(RegionInputComp);
