//
// DISCLAIMER
//
// Copyright 2022-2023 ArangoDB GmbH, Cologne, Germany
//

import { isEmpty, isEqual, truncate } from "lodash";
import { useEffect, useState } from "react";
import { Popup, Progress, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions } from "../../api/lib";
import { DeploymentReplication as ApiDeploymentReplication } from "../../api/replication/v1/replication";
import { ErrorMessage, FlexBox, TextLink } from "../../ui/lib";

type MigrationDeploymentReplicationProps = {
  id: string;
};
const MigrationDeploymentReplication = ({ id }: MigrationDeploymentReplicationProps) => {
  const initialData: Required<ApiDeploymentReplication> = {
    started: false,
    certificate_pem: "",
    tls_keyfile: "",
    deployment_id: id,
    started_by_id: "",
    status: {},
    cancelation_options: {},
  };
  const [replicationData, setReplicationData] = useState<Required<ApiDeploymentReplication>>(initialData);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchDeploymentReplications = async () => {
    const req: IDOptions = {
      id,
    };
    try {
      const response = (await apiClients.idashboardClient.GetMigrationDeploymentReplication(req)) as Required<ApiDeploymentReplication>;
      if (isEmpty(response)) {
        setReplicationData(initialData);
      } else {
        setReplicationData(response);
      }
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchDeploymentReplications();
  }, []);

  const getProgress = (data: ApiDeploymentReplication) => {
    const { status = {} } = data;
    const { progress } = status;
    if (!progress) return "-";

    return progress * 100;
  };

  return (
    <>
      <ErrorMessage active={!!error} message={error} />

      {(!replicationData || isEmpty(replicationData) || isEqual(replicationData, initialData)) && (
        <FlexBox justify="space-between">
          <div>No data</div>
        </FlexBox>
      )}
      {!!replicationData && !isEqual(replicationData, initialData) && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Started</Table.HeaderCell>
              <Table.HeaderCell>Phase</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>Progress</Table.HeaderCell>
              <Table.HeaderCell>Forwarder endpoint</Table.HeaderCell>
              <Table.HeaderCell>Sync endpoint</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{replicationData.started ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>{replicationData.status ? replicationData.status.phase || "-" : "-"}</Table.Cell>
              <Table.Cell>
                {replicationData.status && replicationData.status.message ? (
                  <Popup trigger={<span>{truncate(replicationData.status.message, { length: 30 })}...</span>} content={replicationData.status.message} />
                ) : (
                  "-"
                )}
              </Table.Cell>
              <Table.Cell>
                {getProgress(replicationData) !== "-" ? (
                  <Progress className="margin-0" percent={getProgress(replicationData)} precision={2} success indicating size="small" />
                ) : (
                  "-"
                )}
              </Table.Cell>
              <Table.Cell singleLine width={2}>
                {replicationData.status
                  ? (
                      <TextLink
                        onClick={() => window.open(replicationData.status.forwarder_endpoint, "forwarder_endpoint")}
                        label={replicationData.status.forwarder_endpoint}
                      />
                    ) || "-"
                  : "-"}
              </Table.Cell>

              <Table.Cell>
                {replicationData.status
                  ? (
                      <TextLink
                        onClick={() => window.open(replicationData.status.sync_endpoint, "replication_endpoint")}
                        label={replicationData.status.sync_endpoint}
                      />
                    ) || "-"
                  : "-"}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export default MigrationDeploymentReplication;
