//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import _ from "lodash";

export default class Callbacks {
  private callbacks: Array<() => void>;

  constructor() {
    this.callbacks = new Array<() => void>();
  }

  invokeCallbacks = () => {
    this.callbacks.forEach((callback) => {
      callback();
    });
  };

  addCallback = (callback: () => void) => {
    this.callbacks.push(callback);
  };

  removeCallback = (callback: () => void) => {
    _.pull(this.callbacks, callback);
  };
}
