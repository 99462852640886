//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DropdownProps, Form } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import { Version as ApiVersion, ListVersionsRequest as ApiListVersionsRequest } from "../../api/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ISelectVersionViewArgs extends ISelectVersionState {
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

const SelectVersionView = ({ ...args }: ISelectVersionViewArgs) => {
  const versions = args.versions || [];
  const options = versions.map((x) => {
    return {
      key: x.version,
      text: x.version,
      value: x.version,
    };
  });
  options.unshift({
    key: "",
    value: "",
    text: "System default",
  });
  return (
    <Form.Dropdown
      compact
      fluid
      selection
      label="Version"
      placeholder="Select Version"
      name="version"
      value={args.selectedVersion && args.selectedVersion.version}
      onChange={args.updateFormDropdownChange}
      options={options}
    />
  );
};

interface ISelectVersionProps extends IWithRefreshProps, RouteComponentProps {
  organizationId: string;
  onVersionUpdated: (version?: ApiVersion) => void;
}

interface ISelectVersionState {
  versions?: ApiVersion[];
  selectedVersion?: ApiVersion;
}

// Component to select a version
class SelectVersion extends Component<ISelectVersionProps, ISelectVersionState> {
  state: ISelectVersionState = {
    versions: undefined,
    selectedVersion: undefined,
  };

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "version":
        const versions = this.state.versions;
        const selectedVersion = versions && versions.find((r) => r.version == args.value);
        this.setState({ selectedVersion: selectedVersion });
        this.props.onVersionUpdated(selectedVersion);
        break;
    }
  };

  reload = () => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadVersions());
  };

  reloadVersions = async () => {
    this.setState({
      versions: undefined,
      selectedVersion: undefined,
    });

    const listReq: ApiListVersionsRequest = { organization_id: this.props.organizationId };
    const versions = await apiClients.authenticationOnly.dataClient.ListVersions(listReq);
    this.setState({ versions: versions.items || [] });
  };

  componentDidMount() {
    this.reload();
  }

  render() {
    return <SelectVersionView {...this.state} updateFormDropdownChange={this.updateFormDropdownChange} />;
  }
}

export default withRefresh()(SelectVersion);
