//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import { isEmpty } from "lodash";
import React, { forwardRef } from "react";
import { RouteComponentProps, HashRouter, withRouter } from "react-router-dom";
import TabLayout from "./Tab";
import { ITabLayoutProps } from "./TabTypes";

const TabWithRouter = forwardRef((props: ITabLayoutProps & RouteComponentProps, ref: React.Ref<HTMLDivElement> | null) => {
  const { location, tabConfig } = props;
  const { hash } = location;

  const tabsWithMissingRoutes = tabConfig.find(({ menuItem }) => !menuItem.route);

  if (tabsWithMissingRoutes && !isEmpty(tabsWithMissingRoutes)) {
    throw new ReferenceError(`Missing route for tab menu item: ${tabsWithMissingRoutes.menuItem.id}`);
  }

  if (tabConfig && !tabConfig.length) {
    return <></>;
  }

  const getTabIndexFromHash = (hash: string) => {
    const tabIndex = tabConfig.findIndex((tab) => tab.menuItem.route === hash);
    return tabIndex >= 0 ? tabIndex : 0;
  };

  const handleTabChange = (index: number) => {
    const { onTabChange } = props;
    onTabChange && onTabChange(index);
  };

  return (
    <HashRouter basename="" hashType="noslash">
      <TabLayout {...props} ref={ref} activeIndex={getTabIndexFromHash(hash.substring(1))} onTabChange={handleTabChange} />
    </HashRouter>
  );
});

const TabLayoutWithRouter = withRouter(TabWithRouter);
export default TabLayoutWithRouter;
