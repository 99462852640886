//
// DISCLAIMER
//
// Copyright 2020-2021 ArangoDB GmbH, Cologne, Germany
//

import React, { ReactNode } from "react";
import { Modal, Portal } from "semantic-ui-react";
import { FormActionButtonResume } from "../../ui/lib";

interface IDeploymentPausedModalArgs {
  trigger: ReactNode;
  isPaused: boolean;
  onResumeDeployment: () => void;
  onClick: () => void;
}

export const DeploymentPausedModal = ({ ...args }: IDeploymentPausedModalArgs) => {
  if (args.isPaused) {
    return (
      <Modal trigger={args.trigger}>
        <Modal.Header>Deployment paused</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Your deployment is currently paused.</p>
            <p>Before you can continue with this action, you must first resume your deployment.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonResume primary onClick={args.onResumeDeployment} />
        </Modal.Actions>
      </Modal>
    );
  }
  return (
    <Portal trigger={args.trigger} onOpen={args.onClick}>
      <span />
    </Portal>
  );
};
