//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ConfirmInfo, Confirm } from "./_confirm";

interface IFinalizers {
  finalizers?: string[];
  remove?: (finalizer: string) => void;
}

export const Finalizers = ({ ...args }: IFinalizers) => {
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [confirmInfo, setConfirmInfo] = React.useState<ConfirmInfo>({});
  const [confirm, setConfirm] = React.useState(false);

  const finalizers = args.finalizers || [];
  const has_finalizers = !_.isEmpty(finalizers);
  const canRemove = !!args.remove;
  if (!has_finalizers) {
    return <span>None</span>;
  }

  const onRemoveFinalizer = (finalizer: string) => {
    setConfirmInfo({
      header: "Remove finalizer",
      content: `Are you sure to remove finalizer "${finalizer}"?`,
      warning: "This should only be done when a finalizer is stuck!",
      confirm: "REMOVE!",
      onConfirm: () => {
        args.remove && args.remove(finalizer);
        setConfirm(false);
        setConfirmInfo({});
      },
      onDenied: () => {
        setConfirm(false);
        setConfirmInfo({});
      },
    });
    setConfirm(true);
  };

  return (
    <span>
      {confirm && <Confirm confirmInfo={confirmInfo} />}
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
          <span>
            {`${finalizers.length} item(s)`}
            &nbsp;
            <Icon name="eye" />
          </span>
        }
      >
        <Modal.Header>Finalizers</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div>
              {finalizers.map((x) => (
                <div>
                  <code>{x}</code> {canRemove && <Icon name="trash" onClick={() => onRemoveFinalizer(x)} />}
                </div>
              ))}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CopyToClipboard text={finalizers.join("\n")} onCopy={() => setCopied(true)}>
            <Button positive icon={copied ? "check" : "copy"} labelPosition="right" content="Copy to clipboard" />
          </CopyToClipboard>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
    </span>
  );
};
