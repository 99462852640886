//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import _ from "lodash";
import { KeyValuePair as ApiKeyValuePair } from "../api/lib";

interface IAnnotations {
  annotations?: ApiKeyValuePair[];
}

export const Annotations = ({ ...args }: IAnnotations) => {
  const [open, setOpen] = React.useState(false);
  const annotations = args.annotations || [];
  const has_annotations = !_.isEmpty(annotations);
  if (!has_annotations) {
    return <span>None</span>;
  }
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <span>
          {`${annotations.length} item(s)`}
          &nbsp;
          <Icon name="eye" />
        </span>
      }
    >
      <Modal.Header>Annotations</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div>
            {annotations.map((x) => (
              <div>
                <code>
                  {x.key}={x.value}
                </code>
              </div>
            ))}
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};
