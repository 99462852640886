//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import { Loader, Popup, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { CoreDump as ApiCoreDump, CoreDumpList as ApiCoreDumpList, ListCoreDumpsRequest as ApiListCoreDumpsRequest } from "../../api/core-dump/v1/icoredump";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, ErrorMessage, Loading, LoaderBox, PagingButtons, SecondaryMenu } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import ReactJson from "react-json-view";

interface IHeaderViewArgs {
  loading: boolean;
  page: number;
  count: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderViewArgs) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Datacluster ID</Table.HeaderCell>
      <Table.HeaderCell>Region ID</Table.HeaderCell>
      <Table.HeaderCell>Deployment ID</Table.HeaderCell>
      <Table.HeaderCell>Path</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiCoreDump;
}

const RowView = ({ ...args }: IRowView) => {
  const cd = args.item;
  const status = cd.status || {};
  return (
    <Table.Row>
      <Table.Cell>
        <div>{cd.id}</div>
      </Table.Cell>
      <Table.Cell>{cd.datacluster_id || "-"}</Table.Cell>
      <Table.Cell>{cd.region_id || "-"}</Table.Cell>
      <Table.Cell>
        <div>{cd.deployment_id || "-"}</div>
      </Table.Cell>
      <Table.Cell>
        <Popup trigger={<div>{cd.bucket_path || "-"}</div>} content={<ReactJson src={cd.object_info || {}} collapsed={1} />} on="click" pinned />
      </Table.Cell>
      <Table.Cell>{cd.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={cd.created_at} label="Created at" /> : "-"}</Table.Cell>
      <Table.Cell>{status.is_inspected ? "Inspected" : "Not inspected"}</Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

// Interface describing the list
interface IListView {
  items: ApiCoreDump[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => {
        const id = item.id || "";
        return <RowView {...args} key={id} item={item} />;
      })}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No core dumps</div>;

// Interface describing the list view arguments
export interface ICoreDumpListViewArgs extends RouteComponentProps {
  loading: boolean;
  list?: ApiCoreDumpList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

export const CoreDumpListView = ({ ...args }: ICoreDumpListViewArgs) => {
  if (!args.list) {
    return <Loading />;
  }
  const isEmpty = !args.list.items || args.list.items.length === 0;
  const items = args.list ? args.list.items : undefined;
  return (
    <div>
      <ListView {...args} items={items || []} />
      {isEmpty && <EmptyView />}
    </div>
  );
};

interface ICoreDumpListProps extends IWithRefreshProps, RouteComponentProps {
  showHeader: boolean;
  datacluster_id?: string;
  deployment_id?: string;
}

interface ICoreDumpListState {
  list?: ApiCoreDumpList;
  errorMessage?: string;
  page: number;
  pageSize: number;
}

// The component to show CoreDumps as a list.
class CoreDumpList extends Component<ICoreDumpListProps, ICoreDumpListState> {
  state: ICoreDumpListState = {
    list: undefined,
    errorMessage: undefined,
    page: 0,
    pageSize: 25,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadCoreDumps = async () => {
    var req: ApiListCoreDumpsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    if (!!this.props.datacluster_id) {
      req.datacluster_id = this.props.datacluster_id;
    }
    if (!!this.props.deployment_id) {
      req.deployment_id = this.props.deployment_id;
    }

    const list = await apiClients.idashboardClient.ListCoreDumps(req);
    this.setState({
      list: list,
    });
  };

  refreshCoreDumps = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCoreDumps);
  };

  componentDidMount() {
    this.refreshCoreDumps();
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshCoreDumps
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshCoreDumps
    );
  };

  render() {
    const list = this.state.list;

    const coreDumpList = <CoreDumpListView {...this.props} {...this.state} list={list} onNextPage={this.onNextPage} onPreviousPage={this.onPreviousPage} />;

    if (!this.props.showHeader) {
      return coreDumpList;
    }

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Core Dumps</Menu.Item>
        </SecondaryMenu>
        {coreDumpList}
      </ContentSegment>
    );
  }
}

export default withRefresh()(CoreDumpList);
