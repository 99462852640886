//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Button, Icon, Input, Modal, Form } from "semantic-ui-react";
import { MemoryReserve as ApiMemoryReserve } from "../../api/lib";
import { ErrorMessage } from "../../ui/_errorMessage";
import { humanizeFileSize } from "../../util/FileSize";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";

interface IMemoryReserveEditModalArgs extends CommentCreationArgs {
  memoryReserve?: ApiMemoryReserve;
  onUpdateMemoryReserve: (reserve: ApiMemoryReserve) => any;
}

const MemoryReserveEditModal = ({ ...args }: IMemoryReserveEditModalArgs) => {
  const MB = 1024 * 1024;
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [absolute, setAbsolute] = useState(((args.memoryReserve ? args.memoryReserve.absolute : 0) || 0) / MB);
  const [relative, setRelative] = useState(args.memoryReserve ? args.memoryReserve.relative : 0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  useEffect(() => {
    let newComment = "";
    if (args.memoryReserve) {
      if ((args.memoryReserve.absolute || 0) !== absolute) {
        newComment = `Changed absolute memory reserve to ${absolute} MB`;
      }
      if ((args.memoryReserve.relative || 0) !== relative) {
        newComment = `${newComment ? `${newComment} and` : "Changed"} relative memory reserve to ${relative} %`;
      }
    }
    setComment(newComment);
  }, [absolute, relative]);

  const onSave = async () => {
    setError("");
    setLoading(true);

    const req: ApiMemoryReserve = { absolute: absolute * MB, relative };
    const { error } = (!commentCreationFailed && (await args.onUpdateMemoryReserve(req))) || {};

    setError(error);
    setCommentCreationFailed(false);

    if (!error) {
      const { error: commentError } = await args.createComment(comment);

      if (commentError) {
        setError(commentError);
        setLoading(false);
        setCommentCreationFailed(true);
        return;
      }

      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      trigger={<Icon name="pencil" className="edit-pencil" />}
      open={open}
      onClose={async () => {
        await setComment("");
        await setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      size="tiny"
    >
      <Modal.Header>Edit memory reserve</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={commentCreationFailed ? "Comment creation failed. You can retry saving the comment." : error} />
        <Form onSubmit={onSave}>
          <Form.Group>
            <Form.Field disabled={commentCreationFailed}>
              <label>Absolute (MB)</label>
              <Input type="number" min={0} value={absolute} onChange={(e, d) => setAbsolute(parseInt(d.value))} />
            </Form.Field>
            <Form.Field disabled={commentCreationFailed}>
              <label>Relative (%)</label>
              <Input type="number" min={0} max={100} value={relative} onChange={(e, d) => setRelative(parseInt(d.value))} />
            </Form.Field>
          </Form.Group>
          <Form.Field required>
            <label>Please provide the reason for this change</label>
            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>Memory reserve setting</b>. Otherwise, a default comment will be provided which can be
              changed if required.
            </sub>{" "}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Dismiss" labelPosition="right" icon="cancel" onClick={() => setOpen(false)} />
        {commentCreationFailed ? (
          <Button
            content="Retry saving comments"
            labelPosition="right"
            icon="comment"
            loading={loading}
            disabled={loading || !comment}
            positive
            onClick={onSave}
          />
        ) : (
          <Button content="Update" labelPosition="right" icon="checkmark" loading={loading} disabled={loading || !comment} positive onClick={onSave} />
        )}
      </Modal.Actions>
    </Modal>
  );
};

interface IMemoryReserveViewArgs extends CommentCreationArgs {
  memoryReserve?: ApiMemoryReserve;
  canEditMemoryReserves: boolean;
  onUpdateMemoryReserve: (reserve: ApiMemoryReserve) => void;
}

export const MemoryReserveView = ({ ...args }: IMemoryReserveViewArgs) => {
  return (
    <div>
      {humanizeFileSize(args.memoryReserve ? args.memoryReserve.absolute : 0)} + {args.memoryReserve ? args.memoryReserve.relative : 0} %
      {args.canEditMemoryReserves && (
        <MemoryReserveEditModal memoryReserve={args.memoryReserve} onUpdateMemoryReserve={args.onUpdateMemoryReserve} createComment={args.createComment} />
      )}
    </div>
  );
};
