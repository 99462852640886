//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";
import ReactJson from "react-json-view";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  TestResults as ApiTestResults,
  Test as ApiTest,
  TestStep as ApiTestStep,
  TestRun as ApiTestRun,
  AbortRequest as ApiAbortRequest,
  GetStatusRequest as ApiGetStatusRequest,
} from "../../api/lib";
import { Button, Grid, Header, Segment, Icon, Table, Popup, Modal } from "semantic-ui-react";
import { ErrorMessage, Loading, humanizeDuration, Processing, TextLink, ListActionOpenInNewTab, FlexBox } from "../../ui/lib";
import _ from "lodash";
import CopyToClipboardButton from "../../ui/_copybutton";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IIntegrationTestsStatusViewArgs extends RouteComponentProps {
  loading: boolean;
  testResults?: ApiTestResults;
  onRefresh: () => void;
}

export const IntegrationTestsStatusView = ({ ...args }: IIntegrationTestsStatusViewArgs) => {
  if (!args.testResults) {
    return <Loading />;
  }
  const isEmpty = !args.testResults.tests || args.testResults.tests.length === 0;
  return (
    <div>
      {!!args.testResults.tests && <ListView {...args} items={args.testResults} />}
      {isEmpty && <EmptyView />}
    </div>
  );
};

interface IEmptyView {}

const EmptyView = ({ ...args }: IEmptyView) => <div>No test results</div>;

// Interface describing the integration tests list
interface IListView {
  loading: boolean;
  items: ApiTestResults;
  onRefresh: () => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped columns={4}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Steps</Table.HeaderCell>
        <Table.HeaderCell>Duration (deadline)</Table.HeaderCell>
        <Table.HeaderCell textAlign="right">Status</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {args.items.tests!.map((item) => (
        <TestRowView {...args} item={item} key={item.name} />
      ))}
    </Table.Body>
  </Table>
);

// Interface describing an integration tests
interface ITestRowView {
  item: ApiTest;
  onRefresh: () => void;
  loading: boolean;
}

const TestRowView = ({ ...args }: ITestRowView) => {
  const steps = args.item.steps || [];
  const hasSteps = !_.isEmpty(steps);
  const isFailed = args.item.status == "FAILED";
  const isPassed = args.item.status == "PASSED";
  const stepsStates = _.groupBy(steps, (x) => x.status || "?");
  const stepsStatesText = _.map(stepsStates, (v, k) => `${k}: ${v.length}`).join(", ");
  const result = args.item.result || {};
  const hasError = !_.isEmpty(result.error_message);
  const hasExecutionTime = !!result.execution_time;
  const executionTime = hasExecutionTime ? humanizeDuration(result.execution_time || 0) : "-";
  const hasDeadline = !!result.must_finish_at;
  const deadline = hasDeadline ? <DateTimePopupWithUTCAndLocalTime dateTime={result.must_finish_at} label="Deadline" /> : "-";
  const [open, setOpen] = React.useState(false);

  return (
    <Table.Row verticalAlign="top" negative={isFailed} positive={isPassed}>
      <Table.Cell>
        <Popup
          trigger={<span style={{ cursor: "pointer" }}>{args.item.name}</span>}
          content={<ReactJson src={args.item.extra_params || []} name="extra_params_info" collapsed />}
          on="click"
          pinned
          position="bottom left"
        />
      </Table.Cell>
      <Table.Cell>
        {!hasSteps && <span>-</span>}
        {hasSteps && (
          <div>
            <Modal size="large" open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
              <Modal.Header>
                <Button primary onClick={args.onRefresh} disabled={args.loading} floated="right">
                  <Icon name="recycle" loading={args.loading} />
                  Refresh
                </Button>
                {args.item.name}
              </Modal.Header>
              <Modal.Content scrolling>
                <TestStepsTableView steps={steps} />
              </Modal.Content>
            </Modal>
            <span onClick={() => setOpen(true)} className="text-link">
              {stepsStatesText}
            </span>
          </div>
        )}
      </Table.Cell>
      <Table.Cell>
        {executionTime} {hasDeadline && <span> ({deadline})</span>}
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        {hasError && <Popup trigger={<span className="text-link">{args.item.status}</span>} content={<div>Error: {result.error_message}</div>} />}
        {!hasError && <span>{args.item.status}</span>}
      </Table.Cell>
    </Table.Row>
  );
};

interface ITestStepsTableView {
  steps: ApiTestStep[];
}

const TestStepsTableView = ({ ...args }: ITestStepsTableView) => {
  return (
    <Table compact basic="very" size="small" striped columns={3}>
      <Table.Header>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell collapsing>Group</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell collapsing>Duration (Deadline)</Table.HeaderCell>
        <Table.HeaderCell>Results</Table.HeaderCell>
        <Table.HeaderCell textAlign="right">Status</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {args.steps.map((x, i) => (
          <TestStepRowView key={`step${i}`} item={x} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface ITestStepStatusView {
  status?: string;
}

const TestStepStatusView = ({ ...args }: ITestStepStatusView) => {
  const status = args.status || "";
  switch (status) {
    case "FAILED":
      return <Icon name="exclamation" />;
    case "RUNNING":
      return <Icon name="spinner" loading />;
    case "PASSED":
      return <Icon name="check" />;
    case "PENDING":
      return <Icon name="clock outline" />;
    case "SKIPPED":
      return <Icon name="long arrow alternate right" />;
    default:
      return <span>{status}</span>;
  }
};

// Interface describing an integration test step
interface ITestStepRowView {
  item: ApiTestStep;
}

const TestStepRowView = ({ ...args }: ITestStepRowView) => {
  const result = args.item.result || {};
  const errorMessage = result.error_message;
  const warnings = result.warnings || [];
  const hasErrorOrWarnings = !_.isEmpty(errorMessage) || !_.isEmpty(warnings);
  const hasExecutionTime = !!result.execution_time;
  const executionTime = hasExecutionTime ? humanizeDuration(result.execution_time || 0) : "-";
  const results = result.results || [];
  const hasDeadline = !!result.must_finish_at;
  const deadline = hasDeadline ? <DateTimePopupWithUTCAndLocalTime dateTime={result.must_finish_at} label="Deadline" /> : "-";

  return (
    <Table.Row negative={args.item.status == "FAILED"} positive={args.item.status == "PASSED"}>
      <Table.Cell>{args.item.name}</Table.Cell>
      <Table.Cell collapsing>{args.item.group}</Table.Cell>
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell collapsing>
        {executionTime} {hasDeadline && <span> ({deadline})</span>}
      </Table.Cell>
      <Table.Cell>
        {results.map((x) => (
          <div>{x}</div>
        ))}
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        {hasErrorOrWarnings && (
          <Popup
            position="right center"
            trigger={
              <span className="text-link">
                <TestStepStatusView status={args.item.status} />
              </span>
            }
            content={
              <div>
                <div>Error: {result.error_message}</div>
                <div>Warnings: {warnings.join(", ")}</div>
              </div>
            }
          />
        )}
        {!hasErrorOrWarnings && <TestStepStatusView status={args.item.status} />}
      </Table.Cell>
    </Table.Row>
  );
};

// Interface decribing the properties of the integration tests component
interface IIntegrationTestsStatusProps extends IWithRefreshProps, RouteComponentProps {
  run: ApiTestRun;
  onDeletedTestRun: () => void;
}

// Interface decribing the state of the integration tests component
interface IIntegrationTestsStatusState {
  errorMessage?: string;
  processingAbortTest: boolean;
  processingDeleteTestRun: boolean;
  testResults?: ApiTestResults;
  refreshNeeded: boolean;
  previousId?: string;
  loading: boolean;
}

class IntegrationTestsStatus extends Component<IIntegrationTestsStatusProps, IIntegrationTestsStatusState> {
  state: IIntegrationTestsStatusState = {
    errorMessage: undefined,
    processingAbortTest: false,
    processingDeleteTestRun: false,
    testResults: undefined,
    refreshNeeded: true,
    previousId: undefined,
    loading: false,
  };

  static getDerivedStateFromProps(props: IIntegrationTestsStatusProps, state: IIntegrationTestsStatusState) {
    const id = props.run.id || "";
    if (id !== state.previousId) {
      return {
        previousId: id,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadTestStatusList, "/Organization/_system/Test");
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshTestStatusList);
    }
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  reloadTestStatusList = async () => {
    this.setState({ loading: true }, async () => {
      try {
        const req: ApiGetStatusRequest = {
          id: this.props.run.id,
        };
        const testResults = await apiClients.idashboardClient.GetTestStatus(req);
        this.setState({ testResults: testResults });
      } catch (e) {
        this.setState({ errorMessage: `Failed to load test results: ${e}` });
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  refreshTestStatusList = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTestStatusList);
  };

  onAbortTestRun = async () => {
    this.setState({ processingAbortTest: true });
    try {
      const req: ApiAbortRequest = {
        id: this.props.run.id,
      };
      await apiClients.idashboardClient.AbortTests(req);
    } catch (e) {
      this.setState({ errorMessage: `Failed to abort integration tests: ${e}` });
    }
    this.setState({ processingAbortTest: false }, this.refreshTestStatusList);
  };

  onDeleteTestRun = async () => {
    this.setState({ processingDeleteTestRun: true });
    try {
      const req: ApiIDOptions = {
        id: this.props.run.id,
      };
      await apiClients.idashboardClient.DeleteTestRun(req);
      this.setState({ processingDeleteTestRun: false });
      this.props.onDeletedTestRun();
    } catch (e) {
      this.setState({ errorMessage: `Failed to delete integration test run: ${e}`, processingDeleteTestRun: false });
    }
  };

  render() {
    const run = this.props.run;
    const filter = run.filter || {};
    const has_default_version = !!filter.default_version;
    const has_custom_arangodb_image = !!filter.custom_arangodb_image;
    const has_custom_kube_arangodb_image = !!filter.custom_kube_arangodb_image;
    const testResults = this.state.testResults || {};
    const startedAt = testResults.started_at;
    const hasStartedAt = !!startedAt;
    const finishedAt = testResults.finished_at;
    const hasFinishedAt = !!finishedAt;
    const listView = <IntegrationTestsStatusView {...this.props} {...this.state} onRefresh={this.refreshTestStatusList} />;
    /** @ts-ignore */
    const grafanaLink = new URL("/explore", window.GRAFANA_URL || "");
    const expr = [
      "now-24h",
      "now",
      "Loki",
      { refId: "A", expr: `{namespace="controlplane", app="integration-tests", mode="runner", instance="${testResults.instance_name}"}` },
    ];
    grafanaLink.searchParams.set("orgId", "1");
    grafanaLink.searchParams.set("left", JSON.stringify(expr));
    return (
      <div>
        <Segment>
          <Grid>
            <Grid.Row columns="16">
              <Grid.Column width="2" verticalAlign="middle">
                <Header>
                  <div>{testResults.id || "?"}</div>
                </Header>
              </Grid.Column>
              <Grid.Column width="2" verticalAlign="middle">
                <p>
                  Started {hasStartedAt ? <DateTimePopupWithUTCAndLocalTime dateTime={startedAt} label="Started at" /> : "n/a"}
                  <br />
                  by {testResults.started_by_name || "?"}
                </p>
                {hasFinishedAt && (
                  <p>
                    Finished <DateTimePopupWithUTCAndLocalTime dateTime={finishedAt} label="Finished at" />
                  </p>
                )}
              </Grid.Column>
              <Grid.Column width="8">
                <Table basic="very" compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Provider</Table.Cell>
                      <Table.Cell>
                        <CopyToClipboardButton content={filter.provider || ""} floated="right" />
                        <div>{filter.provider || ""}</div>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Region</Table.Cell>
                      <Table.Cell>
                        <CopyToClipboardButton content={filter.region || ""} floated="right" />
                        <div>{filter.region || ""}</div>
                      </Table.Cell>
                    </Table.Row>
                    {has_default_version && (
                      <Table.Row>
                        <Table.Cell>Default version</Table.Cell>
                        <Table.Cell>
                          <CopyToClipboardButton content={filter.default_version || ""} floated="right" />
                          <div>{filter.default_version || ""}</div>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {has_custom_arangodb_image && (
                      <Table.Row>
                        <Table.Cell>Custom image</Table.Cell>
                        <Table.Cell>
                          <CopyToClipboardButton content={filter.custom_arangodb_image || ""} floated="right" />
                          <div>{filter.custom_arangodb_image || ""}</div>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {has_custom_kube_arangodb_image && (
                      <Table.Row>
                        <Table.Cell>Custom kube-arangodb</Table.Cell>
                        <Table.Cell>
                          <CopyToClipboardButton content={filter.custom_kube_arangodb_image || ""} floated="right" />
                          <div>{filter.custom_kube_arangodb_image || ""}</div>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row>
                      <Table.Cell>Instance name</Table.Cell>
                      <Table.Cell>
                        <FlexBox justify="space-between">
                          <span>{testResults.instance_name}</span>
                          <ListActionOpenInNewTab
                            link={grafanaLink.toString()}
                            position="top right"
                            size="tiny"
                            circular
                            className="margin-right-0"
                            tooltip={
                              <>
                                Open{" "}
                                <b>
                                  <TextLink label={testResults.instance_name} />
                                </b>{" "}
                                in a new tab
                              </>
                            }
                          />
                        </FlexBox>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width="4" textAlign="right" verticalAlign="middle">
                <Button primary onClick={this.refreshTestStatusList} disabled={this.state.loading}>
                  <Icon name="recycle" loading={this.state.loading} />
                  Refresh
                </Button>
                {testResults.isRunning && (
                  <Button primary onClick={this.onAbortTestRun} disabled={this.state.processingAbortTest}>
                    <Icon name="check" loading={this.state.processingAbortTest} />
                    Abort Tests
                  </Button>
                )}
                {!testResults.isRunning && (
                  <Button primary onClick={this.onDeleteTestRun} disabled={this.state.processingDeleteTestRun}>
                    <Icon name="trash" loading={this.state.processingDeleteTestRun} />
                    Delete!
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Processing active={this.state.processingAbortTest} message="Aborting test run, please wait..." />
        <Processing active={this.state.processingDeleteTestRun} message="Deleting test run, please wait..." />
        {listView}
      </div>
    );
  }
}

export default withRefresh()(IntegrationTestsStatus);
