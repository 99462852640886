//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DropdownProps, Form } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import { Provider as ApiProvider, Region as ApiRegion, ListRegionsRequest as ApiListRegionsRequest } from "../../api/platform/v1/platform";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ISelectRegionViewArgs extends ISelectRegionState {
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

export const createRegionName = (p: ApiRegion) => {
  if (!!p.prerelease) {
    return `${p.location} (Preview)`;
  }
  return p.location;
};

const SelectRegionView = ({ ...args }: ISelectRegionViewArgs) => {
  const hasProvider = !!args.selectedProvider;
  const regions = args.regions || [];
  const hasRegions = hasProvider && !_.isEmpty(regions);
  const options = !hasRegions
    ? [{ key: "", value: "", text: "" }]
    : regions.map((r) => {
        return {
          key: r.id,
          text: createRegionName(r),
          value: r.location,
          icon: !!r.out_of_stock ? "warning sign" : !!r.low_stock ? "warning" : "",
          className: !!r.out_of_stock ? "orange-text" : "",
          description: !!r.out_of_stock ? "Out of stock" : !!r.low_stock ? "Low on stock" : "",
        };
      });
  return (
    <Form.Dropdown
      compact
      fluid
      selection
      label="Region"
      placeholder="Select Region"
      name="region"
      loading={hasProvider && !hasRegions}
      disabled={!hasProvider}
      value={args.selectedRegion && args.selectedRegion.location}
      onChange={args.updateFormDropdownChange}
      options={options}
    />
  );
};

// Interface decribing the properties of the SelectRegion component
interface ISelectRegionProps extends IWithRefreshProps, RouteComponentProps {
  organizationId: string;
  provider?: ApiProvider;
  region?: ApiRegion;
  select_first?: boolean;
  onRegionUpdated: (region?: ApiRegion) => void;
}

// Interface decribing the state of the SelectRegion component
interface ISelectRegionState {
  selectedProvider?: ApiProvider;
  regions?: ApiRegion[];
  selectedRegion?: ApiRegion;
  refreshNeeded: boolean;
}

// Component to select a region
class SelectRegion extends Component<ISelectRegionProps, ISelectRegionState> {
  state: ISelectRegionState = {
    selectedProvider: undefined,
    regions: undefined,
    selectedRegion: undefined,
    refreshNeeded: false,
  };

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "region":
        const regions = this.state.regions;
        const selectedRegion = regions && regions.find((r) => r.location == args.value);
        this.setState({ selectedRegion: selectedRegion });
        this.props.onRegionUpdated(selectedRegion);
        break;
    }
  };

  reload = (provider?: ApiProvider) => {
    if (provider) {
      this.props.refreshNow && this.props.refreshNow(() => this.reloadRegions(provider));
    }
  };

  reloadRegions = async (provider: ApiProvider) => {
    this.setState({
      regions: undefined,
      selectedRegion: undefined,
    });

    const listReq: ApiListRegionsRequest = { provider_id: provider.id, organization_id: this.props.organizationId };
    const regions = await apiClients.authenticationOnly.platformClient.ListRegions(listReq);
    // Filter out unavailable regions
    const availableRegions = regions && regions.items ? regions.items.filter((x) => x.available) : undefined;
    this.setState(
      (old) => {
        const update = { regions: availableRegions } as ISelectRegionState;
        if (!old.selectedRegion && !!this.props.select_first) {
          update.selectedRegion = _.first(availableRegions || []);
        }
        return update;
      },
      () => {
        if (this.props.select_first) {
          this.props.onRegionUpdated(this.state.selectedRegion);
        }
      }
    );
  };

  static getDerivedStateFromProps(props: ISelectRegionProps, state: ISelectRegionState) {
    if (props.provider != state.selectedProvider) {
      return {
        refreshNeeded: true,
        selectedProvider: props.provider,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.reload(this.props.provider);
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => this.reload(this.props.provider));
    }
  }

  render() {
    return <SelectRegionView {...this.state} updateFormDropdownChange={this.updateFormDropdownChange} />;
  }
}

export default withRefresh()(SelectRegion);
