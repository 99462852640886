//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import _ from "lodash";
import moment from "moment";
import humanize from "humanize";
import styled from "@emotion/styled";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";
import apiClients from "../../api/apiclients";
import {
  StopRequest as ApiStopRequest,
  StartRequest as ApiStartRequest,
  UpdateStatus as ApiUpdateStatus,
  DataClusterStatus as ApiDataClusterStatus,
  DeploymentStatus as ApiDeploymentStatus,
} from "../../api/lib";
import { Button, Grid, Menu, Segment, Header, Table, Modal, Popup, Icon } from "semantic-ui-react";
import {
  SecondaryMenu,
  Loading,
  ContentSegment,
  ErrorMessage,
  Field,
  FieldContent as FC,
  FieldLabelWide as FLW,
  Processing,
  IconWithPopup,
  SubID,
  TextLink,
  humanizeDuration,
  momentNow,
} from "../../ui/lib";
import Location from "../deployment/Location";
import ProvisionInfoCell from "../datacluster/ProvisionInfoCell";
import { PrometheusAlertStatusView } from "../status/PrometheusAlertStatus";
import { PrometheusAlertSummaryView } from "../status/PrometheusAlertSummary";
import { StatusCellView as DataClusterStatusCellView } from "../datacluster/DataClusterList";
import {
  StatusCellView as DeploymentStatusCellView,
  HibernationCellView as DeploymentHibernationCellView,
  MaintenanceView as DeploymentMaintenanceView,
} from "../deployment/DeploymentList";
import ProjectCache, { ProjectOrganizationTierIcon } from "../../util/ProjectCache";
import OrganizationCache from "../../util/OrganizationCache";
import { SupportPlanView } from "../deployment/SupportPlanView";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

const Red = styled("span")`
  color: red;
`;

const Gray = styled("span")`
  color: gray;
`;

interface IDCUpdateViewArgs extends IWithRefreshProps {
  projectCache: ProjectCache;
  organizationCache: OrganizationCache;
  updateStatus?: ApiUpdateStatus;
  gotoUrl: (url: string) => void;
  onDataClusterClick: (id: string) => void;
  onDeploymentClick: (id: string) => void;
}

export const DCUpdateView = ({ ...args }: IDCUpdateViewArgs) => {
  if (!args.updateStatus) {
    return <Loading />;
  }
  const formatPercentage = (total?: number, part?: number) => {
    const amount = (part || 0) / (total || 1);
    return <span>{humanize.numberFormat(100 * amount)}%</span>;
  };
  const formatNonZero = (value: number, color: string) => {
    if (value == 0) {
      return <span>{value}</span>;
    }
    return <span style={{ color: color }}>{value}</span>;
  };

  const updateStatus = args.updateStatus;
  const dataclusters = args.updateStatus.dataclusters || {};
  const deployments = args.updateStatus.deployments || {};
  return (
    <div>
      <Segment>
        <Grid columns="3" stackable>
          <Grid.Column>
            <Header sub>General</Header>
            <Field>
              <FLW>Is Running</FLW>
              <FC>{updateStatus.is_running ? "Yes" : "No"}</FC>
            </Field>
            <Field>
              <FLW>Started</FLW>
              <FC>{updateStatus.started_at ? <DateTimePopupWithUTCAndLocalTime dateTime={updateStatus.started_at} label="Started at" /> : "-"}</FC>
            </Field>
            <Field>
              <FLW>Stopped</FLW>
              <FC>{updateStatus.stopped_at ? <DateTimePopupWithUTCAndLocalTime dateTime={updateStatus.stopped_at} label="Stopped at" /> : "-"}</FC>
            </Field>
            <Field>
              <FLW>Total Update Duration</FLW>
              <FC>{updateStatus.stopped_at ? moment.duration(moment(updateStatus.stopped_at).diff(updateStatus.started_at)).humanize() : "-"}</FC>
            </Field>
            <Field>
              <FLW>Estimated Time Remaining</FLW>
              <FC>{updateStatus.estimate_time_remaining ? humanizeDuration(updateStatus.estimate_time_remaining) : "-"}</FC>
            </Field>
            <Field>
              <FLW>Is Manually Stopped</FLW>
              <FC>{updateStatus.is_manually_stopped ? "Yes" : "No"}</FC>
            </Field>
            <Field>
              <FLW>Has Failures</FLW>
              <FC>{updateStatus.has_failures ? <Red>Yes</Red> : "No"}</FC>
            </Field>
            <Field>
              <FLW>Controlplane</FLW>
              <FC>{updateStatus.unhealthy_controlplane ? <Red>Unhealty</Red> : "Health"}</FC>
            </Field>
            <Field>
              <FLW>
                <Gray>Reconcile Loop</Gray>
              </FLW>
              <FC>
                <Gray>{updateStatus.reconcile_duration ? updateStatus.reconcile_duration : "-"}</Gray>
              </FC>
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Header sub>Data Clusters</Header>
            <Field>
              <FLW>Total</FLW>
              <FC>{dataclusters.total || 0}</FC>
            </Field>
            <Field>
              <FLW>Up to date</FLW>
              <FC>
                {dataclusters.up_to_date || 0} ({formatPercentage(dataclusters.total, dataclusters.up_to_date)})
              </FC>
            </Field>
            <Field>
              <FLW>Excluded</FLW>
              <FC>
                {dataclusters.excluded || 0} ({formatPercentage(dataclusters.total, dataclusters.excluded)})
              </FC>
            </Field>
            <Field>
              <FLW>Needs update</FLW>
              <FC>
                {dataclusters.needs_update || 0} ({formatPercentage(dataclusters.total, dataclusters.needs_update)})
              </FC>
            </Field>
            <Field>
              <FLW>Cannot be updated</FLW>
              <FC>
                {formatNonZero(dataclusters.cannot_be_updated || 0, "red")} ({formatPercentage(dataclusters.total, dataclusters.cannot_be_updated)})
              </FC>
            </Field>
            <Field>
              <FLW>Updating</FLW>
              <FC>
                {dataclusters.updating || 0} ({formatPercentage(dataclusters.total, dataclusters.updating)}){" "}
              </FC>
            </Field>
            <Field>
              <FLW>Min update duration</FLW>
              <FC>{dataclusters.minimal_update_duration ? humanizeDuration(dataclusters.minimal_update_duration) : "-"}</FC>
            </Field>
            <Field>
              <FLW>Avg update duration</FLW>
              <FC>{dataclusters.average_update_duration ? humanizeDuration(dataclusters.average_update_duration) : "-"}</FC>
            </Field>
            <Field>
              <FLW>Max update duration</FLW>
              <FC>{dataclusters.maximum_update_duration ? humanizeDuration(dataclusters.maximum_update_duration) : "-"}</FC>
            </Field>
            <Field>
              <FLW>Total update duration</FLW>
              <FC>{dataclusters.total_update_duration ? humanizeDuration(dataclusters.total_update_duration) : "-"}</FC>
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Header sub>Deployments</Header>
            <Field>
              <FLW>Total</FLW>
              <FC>{deployments.total || 0}</FC>
            </Field>
            <Field>
              <FLW>Up to date</FLW>
              <FC>
                {deployments.up_to_date || 0} ({formatPercentage(deployments.total, deployments.up_to_date)})
              </FC>
            </Field>
            <Field>
              <FLW>Excluded</FLW>
              <FC>
                {deployments.excluded || 0} ({formatPercentage(deployments.total, deployments.excluded)})
              </FC>
            </Field>
            <Field>
              <FLW>Needs update</FLW>
              <FC>
                {deployments.needs_update || 0} ({formatPercentage(deployments.total, deployments.needs_update)})
              </FC>
            </Field>
            <Field>
              <FLW>Cannot be updated</FLW>
              <FC>
                {formatNonZero(deployments.cannot_be_updated || 0, "red")} ({formatPercentage(deployments.total, deployments.cannot_be_updated)})
              </FC>
            </Field>
            <Field>
              <FLW>Updating</FLW>
              <FC>
                {deployments.updating || 0} ({formatPercentage(deployments.total, deployments.updating)})
              </FC>
            </Field>
            <Field>
              <FLW>Min update duration</FLW>
              <FC>{deployments.minimal_update_duration ? humanizeDuration(deployments.minimal_update_duration) : "-"}</FC>
            </Field>
            <Field>
              <FLW>Avg update duration</FLW>
              <FC>{deployments.average_update_duration ? humanizeDuration(deployments.average_update_duration) : "-"}</FC>
            </Field>
            <Field>
              <FLW>Max update duration</FLW>
              <FC>{deployments.maximum_update_duration ? humanizeDuration(deployments.maximum_update_duration) : "-"}</FC>
            </Field>
            <Field>
              <FLW>Total update duration</FLW>
              <FC>{deployments.total_update_duration ? humanizeDuration(deployments.total_update_duration) : "-"}</FC>
            </Field>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment>
        <Header sub>Controlplane Message</Header>
        {updateStatus.controlplane_message}
        <Header sub>Overall messages</Header>
        {updateStatus.message}
      </Segment>
      <Header sub>Data Cluster in update</Header>
      <DataClusterListView {...args} items={dataclusters.in_update || []} onClickView={args.onDataClusterClick} />
      <Header sub>Deployment in update</Header>
      <DeploymentListView {...args} items={deployments.in_update || []} onClickView={args.onDeploymentClick} onClickDataCluster={args.onDataClusterClick} />
      <Header sub>Data Cluster needs update but cannot</Header>
      <DataClusterListView {...args} items={dataclusters.needs_update_but_cannot || []} onClickView={args.onDataClusterClick} />
      <Header sub>Deployment needs update but cannot</Header>
      <DeploymentListView
        {...args}
        items={deployments.needs_update_but_cannot || []}
        onClickView={args.onDeploymentClick}
        onClickDataCluster={args.onDataClusterClick}
      />
    </div>
  );
};

// Arguments for header view
interface IDataClusterHeaderView {}

const DataClusterHeaderView = ({ ...args }: IDataClusterHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Has Failures</Table.HeaderCell>
      <Table.HeaderCell>Message</Table.HeaderCell>
      <Table.HeaderCell>Region</Table.HeaderCell>
      <Table.HeaderCell>Last ping</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Provision Info</Table.HeaderCell>
      <Table.HeaderCell>Nodes</Table.HeaderCell>
      <Table.HeaderCell>Kubernetes</Table.HeaderCell>
      <Table.HeaderCell>Firing Alerts</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a datacluster
interface IDataClusterRowView extends IWithRefreshProps {
  item: ApiDataClusterStatus;
  onClickView: () => void;
  gotoUrl: (url: string) => void;
}

const DataClusterRowView = ({ ...args }: IDataClusterRowView) => {
  const has_failures = !!args.item.has_failures;
  const message = args.item.message || "-";
  const datacluster = args.item.datacluster || {};
  const status = datacluster.status || {};
  const nodes = (status.nodes || {}).items || [];
  const k8sVersions = _.orderBy(_.uniq(nodes.map((n) => (n.info || {}).kubeletVersion || "")));
  const metrics_info = status.metrics_info || {};
  const prometheus_alert_status = metrics_info.prometheus_alert_status || {};
  const firing_info = prometheus_alert_status.firing_info || [];
  const has_firing_alerts = firing_info.length > 0;
  const now = momentNow();
  const last_ping_diff = now.diff(moment(status.last_ping_at), "seconds");
  const last_ping_ok = !!status.last_ping_at && last_ping_diff >= 0 && last_ping_diff < 180;
  const in_maintenance_mode = datacluster.in_maintenance_mode || false;

  return (
    <Table.Row warning={has_failures}>
      <Table.Cell>
        <TextLink label={datacluster.id} onClick={args.onClickView} />
      </Table.Cell>
      <Table.Cell>{has_failures ? <Red>Yes</Red> : "No"}</Table.Cell>
      <Table.Cell>{message}</Table.Cell>
      <Table.Cell>
        <Location {...args} showProvider={false} showRegion regionId={datacluster.region_id} />
        <SubID>{datacluster.region_id || "-"}</SubID>
      </Table.Cell>
      <Table.Cell>
        <IconWithPopup
          name={last_ping_ok ? "check" : "warning sign"}
          color={last_ping_ok ? "green" : "orange"}
          content={status.last_ping_at ? <DateTimePopupWithUTCAndLocalTime dateTime={status.last_ping_at} label="Last ping at" /> : "-"}
        />
      </Table.Cell>
      <DataClusterStatusCellView status={status} in_maintenance_mode={in_maintenance_mode} />
      <ProvisionInfoCell {...args} datacluster={datacluster} />
      <Table.Cell>{nodes.length}</Table.Cell>
      <Table.Cell>{k8sVersions.join(", ")}</Table.Cell>
      <Table.Cell>
        {has_firing_alerts && (
          <Modal
            trigger={
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                <PrometheusAlertSummaryView {...args} status={prometheus_alert_status} />
              </span>
            }
            centered
            size="fullscreen"
          >
            <Modal.Header>Firing alerts</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                <PrometheusAlertStatusView {...args} status={prometheus_alert_status} showDeploymentID />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {!has_firing_alerts && <PrometheusAlertSummaryView {...args} status={prometheus_alert_status} />}
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={datacluster.created_at} label="Created at" />
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the datacluster list
interface IDataClusterListView extends IWithRefreshProps {
  items: ApiDataClusterStatus[];
  onClickView: (id: string) => void;
  gotoUrl: (url: string) => void;
}

const DataClusterListView = ({ ...args }: IDataClusterListView) => (
  <Table striped>
    <DataClusterHeaderView {...args} />
    <Table.Body>
      {args.items.map((item) => (
        <DataClusterRowView
          {...args}
          key={item.datacluster && item.datacluster.id}
          item={item}
          onClickView={() => args.onClickView((item.datacluster && item.datacluster.id) || "")}
        />
      ))}
    </Table.Body>
  </Table>
);

// Arguments for header view
interface IDeploymentHeaderView {}

const DeploymentHeaderView = ({ ...args }: IDeploymentHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>
          <Popup trigger={<Icon name="paw" />} content="Tier" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Popup trigger={<Icon name="help circle" />} content="Support plan" />
        </Table.HeaderCell>
        <Table.HeaderCell>Has Failures</Table.HeaderCell>
        <Table.HeaderCell>Message</Table.HeaderCell>
        <Table.HeaderCell>Version</Table.HeaderCell>
        <Table.HeaderCell>Model</Table.HeaderCell>
        <Table.HeaderCell>Size</Table.HeaderCell>
        <Table.HeaderCell>Region</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Hibernation</Table.HeaderCell>
        <Table.HeaderCell>Maintenance</Table.HeaderCell>
        <Table.HeaderCell>Created</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

// Interface describing a deployment
interface IDeploymentRowView extends IWithRefreshProps {
  item: ApiDeploymentStatus;
  projectCache: ProjectCache;
  organizationCache: OrganizationCache;
  onClickView: () => void;
  onClickDataCluster: (datacluster_id: string) => void;
}

const DeploymentRowView = ({ ...args }: IDeploymentRowView) => {
  const has_failures = !!args.item.has_failures;
  const message = args.item.message || "-";
  const depl_info = args.item.deployment || {};
  const depl = depl_info.deployment || {};
  const model = depl.model || {};
  const has_assignment = !!depl_info.assignment;
  const assignment = depl_info.assignment || {};
  const is_updating = assignment.provision_hash != assignment.last_reported_provision_hash;
  return (
    <Table.Row verticalAlign="top" warning={has_failures}>
      <Table.Cell>
        <TextLink label={depl.name} onClick={args.onClickView} />
        {depl.description && (
          <Popup trigger={<Icon name="paperclip" />} position="right center">
            {depl.description}
          </Popup>
        )}
        <SubID>{depl.id || ""}</SubID>
      </Table.Cell>
      <Table.Cell>
        <ProjectOrganizationTierIcon cache={args.projectCache} organizationCache={args.organizationCache} projectId={depl.project_id} />
      </Table.Cell>
      <Table.Cell>
        <SupportPlanView planId={depl.support_plan_id} />
      </Table.Cell>
      <Table.Cell>{has_failures ? <Red>Yes</Red> : "No"}</Table.Cell>
      <Table.Cell>{message}</Table.Cell>
      <Table.Cell collapsing>
        {depl.version}
        {depl.custom_image && (
          <Popup trigger={<Icon name="paperclip" />} position="right center">
            {depl.custom_image}
          </Popup>
        )}
      </Table.Cell>
      <Table.Cell collapsing>{model.model || "?"}</Table.Cell>
      <Table.Cell collapsing>{model.node_size_id || "-"}</Table.Cell>
      <Table.Cell>
        <Location {...args} regionId={depl.region_id} showProvider={false} showRegion multiLine />
        <SubID>
          {!has_assignment && <span>{depl.region_id}</span>}
          {has_assignment && <TextLink label={depl.region_id} onClick={() => args.onClickDataCluster(assignment.datacluster_id || "")} />}
        </SubID>
      </Table.Cell>
      <DeploymentStatusCellView
        status={depl.status}
        isUpToDate={!!depl_info.is_up_to_date}
        isUpdating={is_updating}
        isPaused={!!depl.is_paused}
        inHibernationMode={!!depl_info.in_hibernation_mode}
      />
      <DeploymentHibernationCellView item={depl_info} />
      <DeploymentMaintenanceView item={depl_info} canUpdateProvisionHash={false} onClickUpdateProvisionHash={() => {}} />
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={depl.created_at} label="Created at" />
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the deployment list
interface IDeploymentListView extends IWithRefreshProps {
  items?: ApiDeploymentStatus[];
  projectCache: ProjectCache;
  organizationCache: OrganizationCache;
  onClickView: (id: string) => void;
  onClickDataCluster: (datacluster_id: string) => void;
}

const DeploymentListView = ({ ...args }: IDeploymentListView) => {
  const items = args.items || [];
  return (
    <Table striped>
      <DeploymentHeaderView />
      <Table.Body>
        {items.map((item) => (
          <DeploymentRowView
            {...args}
            key={item.deployment && item.deployment.deployment && item.deployment.deployment.id}
            item={item}
            onClickView={() => args.onClickView((item.deployment && item.deployment.deployment && item.deployment.deployment.id) || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

// Interface decribing the properties of the dcupdate component
interface IDCUpdateProps extends IWithRefreshProps, RouteComponentProps {
  onDataClusterSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
}

// Interface decribing the state of the dcupdate component
interface IDCUpdateState {
  errorMessage?: string;
  updateStatus?: ApiUpdateStatus;
  processingStart: boolean;
  processingStop: boolean;
  projectCache: ProjectCache;
  organizationCache: OrganizationCache;
}

class DCUpdate extends Component<IDCUpdateProps, IDCUpdateState> {
  state: IDCUpdateState = {
    errorMessage: undefined,
    updateStatus: undefined,
    processingStart: false,
    processingStop: false,
    projectCache: new ProjectCache(),
    organizationCache: new OrganizationCache(),
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  refreshStatus = async () => {
    try {
      const updateStatus = await apiClients.idashboardClient.GetDCUpdateStatus();
      this.setState({ updateStatus: updateStatus });
    } catch (e) {
      this.setState({ errorMessage: `Failed to get DCUpdate status: ${e}` }, () => {
        const setTimeout = this.props.setTimeout;
        setTimeout &&
          setTimeout(() => {
            this.handleDismissError();
          }, 2000);
      });
    }
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onStart = async (ignoreAlertsOnCP: boolean) => {
    this.setState({ processingStart: true });
    try {
      const req: ApiStartRequest = { ignore_alerts_on_cp: ignoreAlertsOnCP };
      await apiClients.idashboardClient.StartDCUpdate(req);
    } catch (e) {
      this.setState({ errorMessage: `Failed to start DCUpdate service: ${e}` });
    }
    this.setState({ processingStart: false });
  };

  onStop = async () => {
    this.setState({ processingStop: true });
    try {
      const req: ApiStopRequest = {};
      await apiClients.idashboardClient.StopDCUpdate(req);
    } catch (e) {
      this.setState({ errorMessage: `Failed to stop DCUpdate service: ${e}` });
    }
    this.setState({ processingStop: false });
  };

  onDataClusterClick = (id: string) => {
    this.props.onDataClusterSelected(id);
  };

  onDeploymentClick = (id: string) => {
    this.props.onDeploymentSelected(id);
  };

  gotoUrl = (url: string) => {
    window.open(url);
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.refreshStatus, "/Organization/_system/DCUpdate");
  }

  render() {
    const hasStartPermission = this.hasPermission("internal-dashboard.dcupdate.start");
    const hasStopPermission = this.hasPermission("internal-dashboard.dcupdate.stop");
    const canStart = this.state.updateStatus && !this.state.updateStatus.is_running;
    const canStop = this.state.updateStatus && !!this.state.updateStatus.is_running;
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Processing active={this.state.processingStart} message="Starting DCUpdate process, please wait..." />
        <Processing active={this.state.processingStop} message="Stopping DCUpdate process, please wait..." />
        <SecondaryMenu>
          <Menu.Item header>DC Update process status</Menu.Item>
        </SecondaryMenu>
        <Segment>
          <Grid columns="2">
            <Grid.Column>
              {hasStartPermission && (
                <Button
                  icon="check"
                  content="Start Updates"
                  primary
                  onClick={() => {
                    this.onStart(false);
                  }}
                  disabled={!canStart}
                />
              )}
              {hasStartPermission && (
                <Button
                  icon="check"
                  content="Start Updates (ignore alerts on CP)"
                  onClick={() => {
                    this.onStart(true);
                  }}
                  disabled={!canStart}
                />
              )}
              {hasStopPermission && <Button icon="cross" content="Stop Updates" primary onClick={this.onStop} disabled={!canStop} />}
            </Grid.Column>
          </Grid>
        </Segment>
        <DCUpdateView
          {...this.props}
          {...this.state}
          gotoUrl={this.gotoUrl}
          onDataClusterClick={this.onDataClusterClick}
          onDeploymentClick={this.onDeploymentClick}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(DCUpdate);
