//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
// Author Ewout Prangsma
//

import { Header } from "semantic-ui-react";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import Location from "./Location";

interface ILocationViewArgs extends IWithRefreshProps {
  regionId?: string;
}

export const LocationView = ({ ...args }: ILocationViewArgs) => {
  return (
    <div>
      <Header sub>Location</Header>
      <Field>
        <FL>Provider</FL>
        <FC>
          <Location {...args} showProvider showRegion={false} />
        </FC>
      </Field>
      <Field>
        <FL>Region</FL>
        <FC>
          <Location {...args} showProvider={false} showRegion />
        </FC>
      </Field>
    </div>
  );
};
