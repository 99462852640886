//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { StyledTabMenuItem, StyledLinkTabMenuItem } from "./TabStyles";
import { ITabMenuItem } from "./TabTypes";

const TabMenuItem = ({ id, children, isActive, onTabClick, align, route }: ITabMenuItem) => {
  if (!children) {
    return null;
  }

  if (route) {
    return (
      <StyledLinkTabMenuItem
        key={id}
        id={id}
        to={route}
        className="tab-menu-item"
        isActive={isActive}
        onClick={onTabClick}
        align={align}
        data-observerid={id}
        data-testid="tabMenuItem"
      >
        {children}
      </StyledLinkTabMenuItem>
    );
  }

  return (
    <StyledTabMenuItem
      key={id}
      id={id}
      className="tab-menu-item"
      isActive={isActive}
      onClick={onTabClick}
      align={align}
      data-observerid={id}
      data-testid="tabMenuItem"
    >
      {children}
    </StyledTabMenuItem>
  );
};
export default TabMenuItem;
