//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import { isEmpty } from "lodash";
import React from "react";
import { Table } from "semantic-ui-react";
import { Token as ApiToken } from "../../api/metrics/v1/metrics";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IMetricTokenArgs {
  tokens?: ApiToken[];
}

const MetricsTokens = ({ tokens }: IMetricTokenArgs) => {
  if (!tokens && isEmpty(tokens)) {
    return <div>No tokens present</div>;
  }

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Lifetime</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>Expiring soon</Table.HeaderCell>
          <Table.HeaderCell>Expired</Table.HeaderCell>
          <Table.HeaderCell>Revoked</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!!tokens &&
          tokens.map((token) => (
            <Table.Row>
              <Table.Cell>{token.name}</Table.Cell>
              <Table.Cell>{token.lifetime}</Table.Cell>
              <Table.Cell>
                <DateTimePopupWithUTCAndLocalTime dateTime={token.created_at} label="Created at" />
              </Table.Cell>
              <Table.Cell>{token.will_expire_soon ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>{token.is_expired ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>{token.is_revoked ? "Yes" : "No"}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

export default MetricsTokens;
