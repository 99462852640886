//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import { Button, Divider, Header } from "semantic-ui-react";
import { IBaseCommentViewArgs } from "./CommentTypes";
import { FlexBox } from "../../ui/lib";
import CommentsModalView from "./CommentsModal";

const CommentViewAccessor = ({
  comments,
  error,
  canCreate,
  commentCreationInProcess,
  lastestCommentsTriggered,
  onCommentsPageChange,
  createComment,
}: IBaseCommentViewArgs) => {
  const [showModal, updateModalVisibility] = useState(false);

  return (
    <>
      <FlexBox justify="space-between" padding="0 20px">
        <Header sub>Comments</Header>
        {canCreate && <Button content="View all comments" size="mini" basic icon="comment" onClick={() => updateModalVisibility(true)} />}
      </FlexBox>
      <Divider hidden />

      {showModal && (
        <CommentsModalView
          comments={comments}
          onCommentsPageChange={onCommentsPageChange}
          error={error}
          createComment={createComment}
          onClose={() => {
            updateModalVisibility(false);
          }}
          canCreate={canCreate}
          commentCreationInProcess={commentCreationInProcess}
          lastestCommentsTriggered={lastestCommentsTriggered}
        />
      )}
    </>
  );
};

export { CommentViewAccessor };
