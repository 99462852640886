//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import { useEffect, useState } from "react";
import { Section, SectionContent } from "../../../ui/_section";
import apiClients from "../../../api/apiclients";
import { IDOptions, isNotFound } from "../../../api/lib";
import { ErrorMessage, SecondaryMenu } from "../../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../../util/WithRefresh";
import MLSummaryTableView from "./MLSummaryTableView";
import { Dropdown, Loader, Menu, Segment } from "semantic-ui-react";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../../ui/lib";
import { MLServicesInfo } from "../../../api/ml/v1/iml";
interface MLStatusViewArgs extends IWithRefreshProps {
  deploymentID: string;
  canSetAllowGPUWorkloads: boolean;
  canSetJobsResourceLimits: boolean;
  canEnableMLService: boolean;
}

const MLStatusView = ({ deploymentID, canSetJobsResourceLimits, canSetAllowGPUWorkloads, canEnableMLService, ...rest }: MLStatusViewArgs) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [mlServicesInfo, setMLServicesInfo] = useState<MLServicesInfo>({});

  const { ml_services = {} } = mlServicesInfo;
  const { enabled } = ml_services;

  const loadMLServiceData = async () => {
    setLoading(true);
    try {
      const req: IDOptions = {
        id: deploymentID,
      };
      const mlServicesInfo = (await apiClients.idashboardClient.GetMLServicesInfo(req)) || {};
      setMLServicesInfo(mlServicesInfo);
    } catch (err) {
      if (isNotFound(err)) setMLServicesInfo({});
      else setErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const handleServiceStateChange = async (enabled: boolean) => {
    setErrorMessage(undefined);
    setLoading(true);
    try {
      const req = {
        deployment_id: deploymentID,
        enabled,
      };
      await apiClients.idashboardClient.SetMLServiceEnabled(req);
    } catch (err) {
      setErrorMessage(err);
    } finally {
      loadMLServiceData();
    }
  };

  useEffect(() => {
    const { subscribeUrl } = rest;
    setLoading(true);
    subscribeUrl && subscribeUrl(loadMLServiceData);
  }, []);

  return (
    <>
      <Section>
        <ErrorMessage active={!!errorMessage} onDismiss={() => setErrorMessage(undefined)} message={errorMessage} />
        <SectionContent>
          <SecondaryMenu>
            <Menu.Item header>ML settings {loading ? <Loader size="mini" inline active /> : ""}</Menu.Item>
          </SecondaryMenu>
          <Field>
            <FL>ML Service enabled</FL>
            <FC>
              <span>{enabled ? "Yes" : "No"}</span>
              {canEnableMLService && (
                <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
                  <Dropdown.Menu>
                    {!enabled && <Dropdown.Item key="enable-ml-service" text="Enable ML Service" onClick={() => handleServiceStateChange(true)} />}
                    {enabled && <Dropdown.Item key="disable-ml-service" text="Disable ML Service" onClick={() => handleServiceStateChange(false)} />}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </FC>
          </Field>
          {enabled ? (
            <MLSummaryTableView
              mlServicesInfo={mlServicesInfo}
              canSetJobsResourceLimits={canSetJobsResourceLimits}
              canSetAllowGPUWorkloads={canSetAllowGPUWorkloads}
            />
          ) : (
            <Segment>No MLServices data found.</Segment>
          )}
        </SectionContent>
      </Section>
    </>
  );
};

export default withRefresh()(MLStatusView);
