//
// DISCLAIMER
//
// Copyright 2022-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  Organization as ApiOrganization,
  OrganizationInfo as ApiOrganizationInfo,
  SetOrganizationHasPrivateDataClustersRequest as ApiSetOrganizationHasPrivateDataClustersRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL, Processing, Confirm, ConfirmInfo } from "../../ui/lib";

export interface IPrivateDataClusterFieldToggleArgs {
  canSetHasPrivateDataCluster: boolean;
  organization: ApiOrganization;
  organizationInfo: ApiOrganizationInfo;
  onError: (message: string) => void;
}

const PrivateDataClusterFieldToggle: React.FC<IPrivateDataClusterFieldToggleArgs> = ({
  canSetHasPrivateDataCluster,
  organization,
  organizationInfo,
  onError,
}) => {
  const [processing, setIsProcessing] = useState(false);
  const [hasPrivateDataClusters, setHasPrivateDataClusters] = useState(!!organizationInfo.has_private_data_clusters);
  const [hasPrivateDataClusterPerDeployment, setHasPrivateDataClusterPerDeployment] = useState(!!organizationInfo.has_private_data_cluster_per_deployment);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);

  useEffect(() => {
    if (organizationInfo.has_private_data_clusters !== hasPrivateDataClusters) {
      setHasPrivateDataClusters(!!organizationInfo.has_private_data_clusters);
    }
  }, [organizationInfo]);

  const onSetHasPrivateDataCluster = async (hasPrivateDataCluster: boolean, hasPrivateDataClusterPerDeployment: boolean) => {
    setConfirmInfo({
      header: `${hasPrivateDataCluster ? "Disable" : "Enable"} Private Data Clusters`,
      content: `Are you sure to update this configuration?`,
      warning: "This can lead to manual migration of deployments.",
      confirm: "Update!",
      onConfirm: async () => {
        const req: ApiSetOrganizationHasPrivateDataClustersRequest = {
          organization_id: organization.id,
          has_private_data_clusters: hasPrivateDataCluster,
          has_private_data_cluster_per_deployment: hasPrivateDataClusterPerDeployment,
        };
        try {
          setIsProcessing(true);
          await apiClients.idashboardClient.SetOrganizationHasPrivateDataClusters(req);
          setHasPrivateDataClusters(hasPrivateDataCluster);
        } catch (e) {
          onError(`Failed updating has private data cluster: ${e}`);
        } finally {
          setIsProcessing(false);
          setConfirmInfo(undefined);
        }
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    });
  };

  const onSetHasPrivateDataClusterPerDeployment = async (hasPrivateDataCluster: boolean, hasPrivateDataClusterPerDeployment: boolean) => {
    setConfirmInfo({
      header: `${hasPrivateDataClusterPerDeployment ? "Disable" : "Enable"} Private Data Cluster per Deployment`,
      content: `Are you sure to update this configuration?`,
      confirm: "Update!",
      onConfirm: async () => {
        const req: ApiSetOrganizationHasPrivateDataClustersRequest = {
          organization_id: organization.id,
          has_private_data_clusters: hasPrivateDataCluster,
          has_private_data_cluster_per_deployment: hasPrivateDataClusterPerDeployment,
        };
        try {
          setIsProcessing(true);
          await apiClients.idashboardClient.SetOrganizationHasPrivateDataClusters(req);
          setHasPrivateDataClusterPerDeployment(hasPrivateDataClusterPerDeployment);
        } catch (e) {
          onError(`Failed updating has private data cluster per deployment: ${e}`);
        } finally {
          setIsProcessing(false);
          setConfirmInfo(undefined);
        }
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    });
  };

  return (
    <div>
      {confirmInfo && <Confirm confirmInfo={confirmInfo} />}
      <Processing active={processing} message="Changing organization private datacluster mode, please wait..." />
      <Field>
        <FL>Has Private Data Clusters</FL>
        <FC>
          {hasPrivateDataClusters ? "Enabled" : "Disabled"}
          {canSetHasPrivateDataCluster && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!hasPrivateDataClusters && (
                  <Dropdown.Item text="Enable" onClick={() => onSetHasPrivateDataCluster(true, hasPrivateDataClusterPerDeployment)} />
                )}
                {hasPrivateDataClusters && (
                  <Dropdown.Item text="Disable" onClick={() => onSetHasPrivateDataCluster(false, hasPrivateDataClusterPerDeployment)} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Has Private Data Cluster per deployment</FL>
        <FC>
          {hasPrivateDataClusters ? (hasPrivateDataClusterPerDeployment ? "Enabled" : "Disabled") : "No Private DC"}
          {hasPrivateDataClusters && canSetHasPrivateDataCluster && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!hasPrivateDataClusterPerDeployment && (
                  <Dropdown.Item text="Enable" onClick={() => onSetHasPrivateDataClusterPerDeployment(hasPrivateDataClusters, true)} />
                )}
                {hasPrivateDataClusterPerDeployment && (
                  <Dropdown.Item text="Disable" onClick={() => onSetHasPrivateDataClusterPerDeployment(hasPrivateDataClusters, false)} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
    </div>
  );
};

export default PrivateDataClusterFieldToggle;
