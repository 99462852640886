//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import styled from "@emotion/styled";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Modal, Header, Table } from "semantic-ui-react";
import { APICompatibility as ApiAPICompatibility, IncompatibleServiceDescriptor as ApiIncompatibleServiceDescriptor } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FLW } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import ServiceDescriptorModal from "./ServiceDescriptorsModal";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

const Red = styled("span")`
  color: red;
`;

const Gray = styled("span")`
  color: gray;
`;

const NoWrapCell = styled(Table.Cell)`
  white-space: nowrap;
`;

interface IIncompatibleServiceDescriptorsViewArgs {
  incompatibleDescriptors: ApiIncompatibleServiceDescriptor[];
}

export const IncompatibleServiceDescriptorView = ({ ...args }: IIncompatibleServiceDescriptorsViewArgs) => {
  const sortedList = _.orderBy(args.incompatibleDescriptors, ["source.name", "target.name"]);
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Dependency</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedList.map((x, i) => {
          const source = x.source || {};
          const target = x.target || {};
          return (
            <Table.Row key={`row${i}`}>
              <NoWrapCell>
                {source.name || ""} &rarr; {target.name || ""} ({x.service_api_id || ""})
              </NoWrapCell>
              <Table.Cell>{x.message || ""}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

interface IControlplaneAPICompatViewArgs extends IWithRefreshProps, RouteComponentProps {
  apiCompatibility: ApiAPICompatibility;
}

export const ControlplaneAPICompatView = ({ ...args }: IControlplaneAPICompatViewArgs) => {
  const is_compatible = !!args.apiCompatibility.is_compatible;
  const last_inspected_at = args.apiCompatibility.last_inspected_at;
  const reconcile_duration = args.apiCompatibility.reconcile_duration;
  const incompatible_service_descriptors = args.apiCompatibility.incompatible_service_descriptors || [];
  return (
    <div>
      <Header sub>API Compatibility</Header>
      <Field>
        <FLW>Is Compatible</FLW>
        <FC>
          {last_inspected_at ? (
            is_compatible ? (
              "Yes"
            ) : (
              <Modal
                size="large"
                trigger={
                  <Red>
                    <u>No</u>
                  </Red>
                }
              >
                <Modal.Header>API Compatibility details</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
                    <IncompatibleServiceDescriptorView incompatibleDescriptors={incompatible_service_descriptors} />
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            )
          ) : (
            "-"
          )}
        </FC>
      </Field>
      <Field>
        <FLW>Last Inspected</FLW>
        <FC>{last_inspected_at ? <DateTimePopupWithUTCAndLocalTime dateTime={last_inspected_at} label="Last inspected at" /> : "-"}</FC>
      </Field>
      <Field>
        <FLW>
          <Gray>Reconcile Loop</Gray>
        </FLW>
        <FC>
          <Gray>{reconcile_duration ? reconcile_duration : "-"}</Gray>
        </FC>
      </Field>
      <ServiceDescriptorModal {...args} />
    </div>
  );
};
