//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Button, Icon, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ConversionRate as ApiConversionRate,
  ConversionRateList as ApiConversionRateList,
  Currency as ApiCurrency,
  CurrencyList as ApiCurrencyList,
  ListOptions as ApiListOptions,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, ContentSegment, SecondaryMenu, ErrorMessage } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import SetConversionRate from "./SetConversionRate";
import ConversionSample from "./ConversionSample";

const HeaderView = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Sign</Table.HeaderCell>
      <Table.HeaderCell>ISO code</Table.HeaderCell>
      <Table.HeaderCell>Is default</Table.HeaderCell>
      <Table.HeaderCell>Rate</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  is_system_default: boolean;
  item: ApiCurrency;
  rate?: ApiConversionRate;
  canSetConversionRate: boolean;
  onEditConversionRate: (rate?: number) => void;
  system_default_currency: ApiCurrency;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>{args.item.id}</Table.Cell>
    <Table.Cell>{args.item.name}</Table.Cell>
    <Table.Cell>{args.item.sign}</Table.Cell>
    <Table.Cell>{args.item.iso4217_code}</Table.Cell>
    <Table.Cell>{args.is_system_default ? "Yes" : "No"}</Table.Cell>
    <Table.Cell>
      <div>
        {args.rate ? args.rate.rate : "-"}
        {args.canSetConversionRate && (
          <span>
            &nbsp;
            <Button icon basic size="mini" floated="right" onClick={() => args.onEditConversionRate(args.rate ? args.rate.rate : undefined)}>
              <Icon name="pencil" />
            </Button>
          </span>
        )}
      </div>
      {!args.is_system_default && (
        <ConversionSample {...args} api={apiClients.idashboardClient} currency_id={args.item.id || ""} rate={args.rate ? args.rate.rate || 1 : 1} />
      )}
    </Table.Cell>
  </Table.Row>
);

// Interface describing the organization list
interface IListView {
  system_default_currency: ApiCurrency;
  currencies: ApiCurrency[];
  rates: ApiConversionRate[];
  canSetConversionRate: boolean;
  onEditConversionRate: (id: string, rate?: number) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView />
    <Table.Body>
      {args.currencies.map((item) => (
        <RowView
          {...args}
          key={item.id}
          is_system_default={item.id == args.system_default_currency.id}
          item={item}
          rate={args.rates.find((x) => x.currency_id == item.id)}
          onEditConversionRate={(rate) => args.onEditConversionRate(item.id || "", rate)}
        />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No currencies</div>;

// Interface describing the currency list view arguments
export interface ICurrencyListViewArgs extends RouteComponentProps {
  loading: boolean;
  system_default_currency: ApiCurrency;
  currencies?: ApiCurrencyList;
  rates?: ApiConversionRateList;
  canSetConversionRate: boolean;
  onEditConversionRate: (id: string, rate?: number) => void;
}

export const CurrencyListView = ({ ...args }: ICurrencyListViewArgs) => {
  if (!args.currencies || !args.rates) {
    return <Loading />;
  }
  const isEmpty = !args.currencies.items || args.currencies.items.length === 0;
  const currencies = args.currencies ? args.currencies.items : undefined;
  const rates = args.rates ? args.rates.items : undefined;
  return (
    <div>
      <ListView {...args} currencies={currencies || []} rates={rates || []} />
      {isEmpty && <EmptyView />}
    </div>
  );
};

// Interface decribing the properties of the currency list component
interface ICurrencyListProps extends IWithRefreshProps, RouteComponentProps {}

// Interface decribing the state of the conversion rate list component
interface ICurrencyListState {
  system_default_currency?: ApiCurrency;
  currencies?: ApiCurrencyList;
  rates?: ApiConversionRateList;
  editing_currency_id?: string;
  editing_conversion_rate?: number;
}

// The component to show currency as a list.
class CurrencyList extends Component<ICurrencyListProps, ICurrencyListState> {
  state: ICurrencyListState = {
    system_default_currency: undefined,
    currencies: undefined,
    rates: undefined,
    editing_currency_id: undefined,
    editing_conversion_rate: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadCurrenciesAndRates = async () => {
    const has_conversionrate_list = this.hasPermission("internal-dashboard.conversionrate.list");

    var req: ApiListOptions = {};
    const system_default_currency = await apiClients.idashboardClient.GetDefaultSystemCurrency();
    const currencies = await apiClients.idashboardClient.ListCurrencies(req);
    const rates = has_conversionrate_list ? await apiClients.idashboardClient.ListConversionRates(req) : undefined;
    this.setState({
      system_default_currency: system_default_currency,
      currencies: currencies,
      rates: rates,
    });
  };

  refreshCurrenciesAndRates = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCurrenciesAndRates);
  };

  componentDidMount() {
    this.refreshCurrenciesAndRates();
  }

  onEditConversionRate = (id: string, rate?: number) => {
    this.setState({
      editing_currency_id: id,
      editing_conversion_rate: rate,
    });
  };

  onEditConversionRateClosed = () => {
    this.setState({
      editing_currency_id: undefined,
      editing_conversion_rate: undefined,
    });
    this.refreshCurrenciesAndRates();
  };

  render() {
    const system_default_currency = this.state.system_default_currency || {};
    const currencies = this.state.currencies;
    const rates = this.state.rates;
    const has_conversionrate_set = this.hasPermission("internal-dashboard.conversionrate.set");

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Currencies</Menu.Item>
        </SecondaryMenu>
        {this.state.editing_currency_id && (
          <SetConversionRate
            {...this.props}
            system_default_currency={this.state.system_default_currency || {}}
            currency_id={this.state.editing_currency_id || ""}
            rate={this.state.editing_conversion_rate}
            api={apiClients.idashboardClient}
            onClose={this.onEditConversionRateClosed}
          />
        )}
        <CurrencyListView
          {...this.props}
          system_default_currency={system_default_currency}
          currencies={currencies}
          rates={rates}
          canSetConversionRate={has_conversionrate_set}
          onEditConversionRate={this.onEditConversionRate}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(CurrencyList);
