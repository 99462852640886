//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//
//

import styled from "@emotion/styled";
import Downloader from "js-file-downloader";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Container, Dimmer, Header, Loader, Modal, Form, Grid, ButtonGroup, TextAreaProps } from "semantic-ui-react";
import Auth from "../../auth/Auth";
import { Field, ErrorMessage } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { Permission, hasSupportPermission } from "../../util/PermissionCache";

const DataContainer = styled("div")`
  min-height: 20vh;
`;

enum CustomResourceType {
  ArangoDeployment = "ArangoDeployment",
  CloudDeployment = "CloudDeployment",
}

// getFileURL gets the URL to fetch the Kubernetes Custom Resource from
const getFileURL = (deploymentId: string, deploymentName: string, crType: CustomResourceType) =>
  `/downloads/v1/deployments/${deploymentId}/fetch-k8s-resource?name=${deploymentName}&type=${crType.toLowerCase()}`;

// getFileName gets the name of the file in which the contents of the CR will be displayed
const getFileName = (deploymentId: string) => `k8s-resource-${deploymentId}.json`;

interface IKubernetesResourceModalViewArgs {
  loading: boolean;
  isVisibleMainModal: boolean;
  crType: string;
  deploymentName: string;
  canGetKubernetesCustomResource: boolean;
  errorMessage: string | undefined;
  onSetIsVisibleModal: (isVisible: boolean) => void;
  onDeploymentInputChanged: (input: string) => void;
  onSubmit: () => void;
  onCustomResourceChanged: (crType: string) => void;
}

const KubernetesResourceModalView = ({ ...args }: IKubernetesResourceModalViewArgs) => {
  return (
    <Container>
      {args.canGetKubernetesCustomResource && (
        <div>
          <Header sub>Kubernetes Resource</Header>
          <Field>
            <Button content="Get Kubernetes Custom Resource" size="mini" basic icon="cogs" onClick={() => args.onSetIsVisibleModal(true)} />
          </Field>
        </div>
      )}
      {args.isVisibleMainModal && (
        <Modal centered open onClose={() => args.onSetIsVisibleModal(false)}>
          <Modal.Header>Get Kubernetes Custom Resource</Modal.Header>
          <Modal.Content>
            <ErrorMessage active={!!args.errorMessage} message={args.errorMessage} />
            {!args.loading && (
              <Form>
                <Grid columns="1">
                  <Grid.Column>
                    <Form.TextArea
                      label="Deployment name"
                      name="Deployment name"
                      value={args.deploymentName}
                      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => args.onDeploymentInputChanged(data.value as string)}
                      rows="1"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <ButtonGroup>
                      <Button
                        type="button"
                        content={CustomResourceType.ArangoDeployment}
                        primary={args.crType === CustomResourceType.ArangoDeployment}
                        onClick={() => args.onCustomResourceChanged(CustomResourceType.ArangoDeployment)}
                      />
                      <Button
                        type="button"
                        content={CustomResourceType.CloudDeployment}
                        primary={args.crType === CustomResourceType.CloudDeployment}
                        onClick={() => args.onCustomResourceChanged(CustomResourceType.CloudDeployment)}
                      />
                    </ButtonGroup>
                  </Grid.Column>
                </Grid>
              </Form>
            )}
            {args.loading && (
              <DataContainer>
                {args.loading && (
                  <Dimmer inverted active>
                    <Loader active inverted>
                      Fetching k8s resource, please wait
                    </Loader>
                  </Dimmer>
                )}
              </DataContainer>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button content="Close" onClick={() => args.onSetIsVisibleModal(false)} />
            <Button primary icon="check" disabled={!args.deploymentName} onClick={args.onSubmit} content="Submit" labelPosition="right" />
          </Modal.Actions>
        </Modal>
      )}
    </Container>
  );
};

interface IKubernetesResourceModalProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  deploymentId: string;
}

interface IKubernetesResourceModalState {
  loading: boolean;
  isVisibleMainModal: boolean;
  deploymentName: string;
  crType: string;
  errorMessage?: string;
}

// Component to view kubernetes resource
class KubernetesResourceModal extends Component<IKubernetesResourceModalProps, IKubernetesResourceModalState> {
  state: IKubernetesResourceModalState = {
    loading: false,
    errorMessage: undefined,
    isVisibleMainModal: false,
    deploymentName: "deployment",
    crType: CustomResourceType.ArangoDeployment,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadKubernetesResource = async () => {
    this.setState({ loading: true, errorMessage: undefined });
    try {
      const downloader = new Downloader({
        url: getFileURL(this.props.deploymentId, this.state.deploymentName, this.state.crType as CustomResourceType),
        filename: getFileName(this.props.deploymentId),
        headers: [{ name: "Authorization", value: `bearer ${this.props.auth.getAccessToken()} ` }],
      });
      await downloader;

      this.setState({ loading: false, isVisibleMainModal: false });
    } catch (e) {
      this.setState({ loading: false, errorMessage: e });
    }
  };

  onSetIsVisibleModal = (isVisible: boolean) => {
    this.setState({ isVisibleMainModal: isVisible });
  };

  onDeploymentInputChanged = (input: string) => {
    this.setState({ deploymentName: input });
  };

  onCustomResourceChanged = (crType: string) => {
    this.setState({ crType });
  };

  render() {
    const canGetKubernetesCustomResource = this.hasPermission("internal-dashboard.deployment.get-kubernetes-resource");
    return (
      <KubernetesResourceModalView
        canGetKubernetesCustomResource={canGetKubernetesCustomResource}
        loading={this.state.loading}
        isVisibleMainModal={this.state.isVisibleMainModal}
        crType={this.state.crType}
        onSetIsVisibleModal={this.onSetIsVisibleModal}
        onDeploymentInputChanged={this.onDeploymentInputChanged}
        onSubmit={this.reloadKubernetesResource}
        onCustomResourceChanged={this.onCustomResourceChanged}
        deploymentName={this.state.deploymentName}
        errorMessage={this.state.errorMessage}
      />
    );
  }
}

export default KubernetesResourceModal;
