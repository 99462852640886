//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany

import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Table, Button, Modal, Form, Segment, Header } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { map, isEmpty, upperCase } from "lodash";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IDOptions, Organization } from "../../api/lib";
import { CreditBundleInfo, CreditDebt, ListAllCreditBundlesRequest } from "../../api/credits/v1/icredits";
import { CreditBundle } from "../../api/credits/v1/credits";
import {
  Confirm,
  ConfirmInfo,
  DateInput,
  CurrencyInput,
  ErrorMessage,
  FloatInput,
  ContentSegment,
  Loading,
  UserLink,
  FormActionButtonCreate,
  FormActionButtonCancel,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { IWithRefreshProps } from "../../util/WithRefresh";

export interface IOrganizationCreditBundlesArgs extends IWithRefreshProps, RouteComponentProps {
  organization: Organization;
}

export const OrganizationCreditBundlesView = (args: IOrganizationCreditBundlesArgs) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<CreditBundleInfo[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [create, setCreate] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);
  const [creditDebt, setCreditDebt] = useState<CreditDebt | undefined>(undefined);

  const hasPermission = (permission: Permission) => hasSupportPermission(permission, args.hasPermissionByUrl);

  const reloadItems = async () => {
    setProcessing(true);
    const req: ListAllCreditBundlesRequest = { organization_id: args.organization.id };
    try {
      const list = await apiClients.idashboardClient.ListAllCreditBundleInfos(req);
      setItems(list.items || []);
    } catch (e) {
      setErrorMessage(e);
    } finally {
      setProcessing(false);
    }

    try {
      const debt = await apiClients.idashboardClient.GetCreditDebt({ id: args.organization.id });
      setCreditDebt(debt);
    } catch (e) {
      setErrorMessage(e);
    } finally {
      setProcessing(false);
    }
  };

  const handleDismissError = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => {
    if (!isEmpty(args.organization)) {
      reloadItems();
    }
  }, [args.organization.id]);

  const deleteCreditBundle = async (creditBundle: CreditBundle | undefined) => {
    if (creditBundle === undefined) {
      return;
    }

    setConfirmInfo({
      header: "Are you sure you want to delete this credit bundle?",
      content: `Are you sure you want to delete this credit bundle with ${creditBundle.credits_purchased} credits?`,
      onDenied: () => {
        setConfirmInfo(undefined);
      },
      onConfirm: async () => {
        setProcessing(true);

        try {
          let req: IDOptions = { id: creditBundle.id };
          await apiClients.idashboardClient.DeleteCreditBundle(req);
          reloadItems();
        } catch (e) {
          setErrorMessage(e);
        } finally {
          setConfirmInfo(undefined);
          setProcessing(false);
        }
      },
    });
  };

  const setCreditBundleLock = async (bundleID: string | undefined, lock: boolean) => {
    setConfirmInfo({
      header: `Are you sure you want to  ${lock ? "lock" : "unlock"} this credit bundle?`,
      content: `Are you sure you want to  ${lock ? "lock" : "unlock"} this credit bundle?`,
      onDenied: () => {
        setConfirmInfo(undefined);
      },
      onConfirm: async () => {
        setProcessing(true);

        try {
          if (lock) {
            await apiClients.idashboardClient.LockCreditBundle({ id: bundleID });
          } else {
            await apiClients.idashboardClient.UnlockCreditBundle({ id: bundleID });
          }
          reloadItems();
        } catch (e) {
          setErrorMessage(e);
        } finally {
          setConfirmInfo(undefined);
          setProcessing(false);
        }
      },
    });
  };

  const canCreate = hasPermission("internal-dashboard.creditbundle.create");
  const canUpdate = hasPermission("internal-dashboard.creditbundle.update");
  const canDelete = hasPermission("internal-dashboard.creditbundle.delete");
  const canSetLock = hasPermission("internal-dashboard.creditbundle.set-lock");
  const canListCreditBundles = hasPermission("internal-dashboard.creditbundle.list");

  const CreditBundleRow = (item: CreditBundleInfo) => {
    const credit_bundle = item.credit_bundle;

    const id = credit_bundle ? credit_bundle.id : "";
    const purchasedAt = credit_bundle ? credit_bundle.purchased_at : undefined;
    const createdBy = item.created_by_id || "";
    const creditsRemaining = credit_bundle ? credit_bundle.credits_remaining : null;
    const creditsPurchased = credit_bundle ? credit_bundle.credits_purchased : null;
    const totalPrice = credit_bundle ? credit_bundle.total_price : null;
    const currency = credit_bundle ? credit_bundle.currency : null;
    const validFrom = credit_bundle ? credit_bundle.valid_from : undefined;
    const validUntil = credit_bundle ? credit_bundle.valid_until : undefined;

    return (
      canListCreditBundles && (
        <Table.Row key={id}>
          <Table.Cell>{id}</Table.Cell>
          <Table.Cell>
            <DateTimePopupWithUTCAndLocalTime dateTime={purchasedAt} label="Purchased at" />
          </Table.Cell>
          <Table.Cell>
            <UserLink id={createdBy} />
          </Table.Cell>
          <Table.Cell>
            {creditsRemaining || 0} / {creditsPurchased}
          </Table.Cell>
          <Table.Cell>
            {totalPrice} {upperCase(currency || "")}
          </Table.Cell>
          <Table.Cell>
            <DateTimePopupWithUTCAndLocalTime dateTime={validFrom} label="Valid From" />
          </Table.Cell>
          <Table.Cell>
            <DateTimePopupWithUTCAndLocalTime dateTime={validUntil} label="Valid Until" />
          </Table.Cell>
          <Table.Cell>
            {canDelete && (
              <Button
                icon="trash"
                onClick={() => {
                  deleteCreditBundle(credit_bundle);
                }}
              />
            )}
            {canUpdate && (
              <Button
                icon="edit"
                disabled={creditsRemaining != creditsPurchased}
                onClick={() => {
                  setUpdate(true);
                }}
              />
            )}
            {canSetLock && (
              <Button
                icon={item.locked ? "unlock" : "lock"}
                onClick={() => {
                  setCreditBundleLock(id, !item.locked);
                }}
              />
            )}
            <OrganizationUpdateCreditBundleModal
              {...args}
              creditBundleInfo={item}
              creditBundle={credit_bundle || ({} as CreditBundle)}
              organization={args.organization}
              open={update}
              onClose={onCreateClose}
            />
          </Table.Cell>
        </Table.Row>
      )
    );
  };

  const createCredit = () => {
    setCreate(true);
  };

  const onCreateClose = () => {
    setCreate(false);
    setUpdate(false);
    reloadItems();
  };

  const creditDebtAmount = creditDebt ? creditDebt.amount : 0;
  const creditDebtCreatedAt = creditDebt ? creditDebt.created_at : undefined;

  if (processing) {
    return (
      <ContentSegment>
        <Loading message="loading credit bundles" />
      </ContentSegment>
    );
  } else {
    return (
      <ContentSegment>
        {creditDebtAmount ? (
          <Segment>
            <Header sub>Credit Debt Info</Header>
            <Field>
              <FL>Amount</FL>
              <FC>{creditDebtAmount}</FC>
            </Field>
            <Field>
              <FL>Created at</FL>
              <FC>
                <DateTimePopupWithUTCAndLocalTime dateTime={creditDebtCreatedAt} label="Created at" />
              </FC>
            </Field>
          </Segment>
        ) : (
          <></>
        )}
        <ErrorMessage active={!!errorMessage} onDismiss={handleDismissError} message={errorMessage} />
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Purchased At</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>(Remaining / Amount) </Table.HeaderCell>
              <Table.HeaderCell>Total Price</Table.HeaderCell>
              <Table.HeaderCell>Valid From</Table.HeaderCell>
              <Table.HeaderCell>Valid Until</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">{canCreate && <Button icon="plus" size="tiny" basic onClick={createCredit} />}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{map(items, CreditBundleRow)}</Table.Body>
        </Table>
        <Confirm confirmInfo={confirmInfo} />
        <OrganizationCreateCreditBundleModal {...args} organization={args.organization} open={create} onClose={onCreateClose} />
      </ContentSegment>
    );
  }
};

export interface IOrganizationCreateCreditBundleArgs extends IWithRefreshProps, RouteComponentProps {
  open: boolean;
  organization: Organization;
  onClose: () => void;
}

const OrganizationCreateCreditBundleModal = (args: IOrganizationCreateCreditBundleArgs) => {
  const [credits, setCredits] = useState<number>(0.0);
  const [price, setPrice] = useState<number>(0.0);
  const [currency, setCurrency] = useState<string | undefined>("");
  const [validFrom, setValidFrom] = useState<Date | undefined>(undefined);
  const [validUntil, setValidUntil] = useState<Date | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loadingCreditBundle, setLoadingCreditBundle] = useState<boolean>(false);

  const createCreditBundle = async () => {
    setLoadingCreditBundle(true);

    const bundle: CreditBundle = {
      organization_id: args.organization.id,
      valid_from: validFrom,
      valid_until: validUntil,
      total_price: price,
      currency: currency,
      credits_purchased: credits,
    };

    try {
      const req: CreditBundleInfo = {
        credit_bundle: bundle,
      };

      await apiClients.idashboardClient.CreateCreditBundleInfo(req);
      args.onClose();
    } catch (e) {
      setErrorMessage(e);
    } finally {
      setLoadingCreditBundle(false);
    }
  };

  const onValidFromChange = (date: Date) => {
    setValidFrom(date);
  };
  const onValidUntilChange = (date: Date) => {
    setValidUntil(date);
  };

  return (
    <Modal open={args.open}>
      {loadingCreditBundle && <p>Creating credit bundle</p>}
      <Modal.Header>Create a credit bundle for {args.organization.name}</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!errorMessage} onDismiss={() => setErrorMessage(undefined)} message={errorMessage} />
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Credits</label>
              <FloatInput value={credits} onChange={setCredits} />
            </Form.Field>
            <Form.Field required>
              <label>Price</label>
              <FloatInput value={price} onChange={setPrice} />
            </Form.Field>
            <Form.Field required>
              <label>Currency</label>
              <CurrencyInput {...args} placeholder="currency" id={currency} onChange={setCurrency} allowDefault />
            </Form.Field>
            <Form.Field required>
              <DateInput value={validFrom} name="valid_from" label="Valid From" onChange={onValidFromChange} />
            </Form.Field>
            <Form.Field required>
              <DateInput value={validUntil} name="valid_until" label="Valid Until" onChange={onValidUntilChange} />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCreate onClick={createCreditBundle} title={loadingCreditBundle ? "Creating..." : "Create"} />
        <FormActionButtonCancel onClick={args.onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export interface IOrganizationUpdateCreditBundleArgs extends IWithRefreshProps, RouteComponentProps {
  open: boolean;
  onClose: () => void;
  creditBundleInfo: CreditBundleInfo;
  creditBundle: CreditBundle;
  organization: Organization;
}

const OrganizationUpdateCreditBundleModal = (args: IOrganizationUpdateCreditBundleArgs) => {
  const [credits, setCredits] = useState(args.creditBundle.credits_purchased || 0.0);
  const [price, setPrice] = useState(args.creditBundle.total_price || 0.0);
  const [currency, setCurrency] = useState(args.creditBundle.currency || "");
  const [validFrom, setValidFrom] = useState<Date | undefined>(args.creditBundle.valid_from || undefined);
  const [validUntil, setValidUntil] = useState<Date | undefined>(args.creditBundle.valid_until || undefined);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loadingCreditBundle, setLoadingCreditBundle] = useState<boolean>(false);

  const updateCreditBundle = async () => {
    setLoadingCreditBundle(true);

    const req: CreditBundle = {
      id: args.creditBundle.id || "",
      valid_from: validFrom,
      valid_until: validUntil,
      total_price: price,
      currency: currency,
      credits_purchased: credits,
    };

    try {
      await apiClients.idashboardClient.UpdateCreditBundle(req);
      args.onClose();
    } catch (e) {
      setErrorMessage(e);
    } finally {
      setLoadingCreditBundle(false);
    }
  };

  const onValidFromChange = (date: Date) => {
    setValidFrom(date);
  };
  const onValidUntilChange = (date: Date) => {
    setValidUntil(date);
  };

  return (
    <Modal open={args.open}>
      <Modal.Header>Update credit bundle for {args.organization.name}</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!errorMessage} onDismiss={() => setErrorMessage(undefined)} message={errorMessage} />
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Credits</label>
              <FloatInput value={credits} onChange={setCredits} />
            </Form.Field>
            <Form.Field required>
              <label>Price</label>
              <FloatInput value={price} onChange={setPrice} />
            </Form.Field>
            <Form.Field required>
              <label>Currency</label>
              <CurrencyInput {...args} placeholder="currency" id={currency} onChange={setCurrency} allowDefault />
            </Form.Field>
            <Form.Field required>
              <DateInput value={validFrom} name="valid_from" label="Valid From" onChange={onValidFromChange} />
            </Form.Field>
            <Form.Field required>
              <DateInput value={validUntil} name="valid_until" label="Valid Until" onChange={onValidUntilChange} />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCreate onClick={updateCreditBundle} title={loadingCreditBundle ? "Updating..." : "Update"} />
        <FormActionButtonCancel onClick={args.onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default OrganizationCreditBundlesView;
