//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Icon, Message, Table } from "semantic-ui-react";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { isEmpty } from "lodash";
import { ConditionItemArgs, ConditionsViewArgs } from "./types";
import { UserLink } from "../../ui/lib";

const ConditionItem = ({ condition = {} }: ConditionItemArgs) => {
  const [isController, setIsController] = useState(false);

  useEffect(() => {
    if (!isEmpty(condition)) {
      if (condition.triggered_by === "controller") {
        setIsController(true);
      }
    }
  }, [condition]);

  return (
    <>
      <Table.Row>
        <Table.Cell>{condition.type || "-"}</Table.Cell>
        <Table.Cell>{condition.status ? <Icon name="check" color="green" /> : <Icon name="close" color="red" />}</Table.Cell>
        <Table.Cell>{condition.reason || "-"}</Table.Cell>
        <Table.Cell>
          <DateTimePopupWithUTCAndLocalTime dateTime={condition.last_transition_time} label="Last transition time" />
        </Table.Cell>
        <Table.Cell>{isController ? "Controller" : <UserLink id={condition.triggered_by || ""} /> || "-"}</Table.Cell>
      </Table.Row>
    </>
  );
};

const ConditionsView = ({ conditions = [] }: ConditionsViewArgs) => {
  const filteredConditions = conditions.filter((condition) => condition.status);
  if (!filteredConditions.length) {
    return <Message>No conditions found</Message>;
  }
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>type</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Reason</Table.HeaderCell>
          <Table.HeaderCell>Last transition time</Table.HeaderCell>
          <Table.HeaderCell>Triggered by</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {filteredConditions.map((condition) => {
          return <ConditionItem condition={condition} key={`${condition.type}-${condition.last_transition_time}-${condition.status}`} />;
        })}
      </Table.Body>
    </Table>
  );
};
export default ConditionsView;
