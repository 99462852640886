//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Organization as ApiOrganization, SetOrganizationAllowedSCIMRequest as ApiSetOrganizationAllowedSCIMRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface ISCIMAllowedFieldViewArgs {
  organization: ApiOrganization;
  canSetAllowSCIM: boolean;
  onSetOrganizationAllowedSCIM: (enabled: boolean) => void;
}

const SCIMAllowedFieldView = ({ ...args }: ISCIMAllowedFieldViewArgs) => {
  const is_scim_allowed = !!args.organization.is_allowed_to_use_scim;
  return (
    <Field>
      <FL>Using SCIM</FL>
      <FC>
        {is_scim_allowed ? "Allowed" : "Not allowed"}
        {args.canSetAllowSCIM && (
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!is_scim_allowed && <Dropdown.Item text="Allow" onClick={() => args.onSetOrganizationAllowedSCIM(true)} />}
              {is_scim_allowed && <Dropdown.Item text="Do not allow" onClick={() => args.onSetOrganizationAllowedSCIM(false)} />}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </FC>
    </Field>
  );
};

interface ISCIMAllowedFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface ISCIMAllowedFieldState extends IOrganizationDetailsFieldState {
  commentPrompt: ICommentPromptStateForIntegrations;
}

class SCIMAllowedField extends OrganizationDetailsField<ISCIMAllowedFieldProps, ISCIMAllowedFieldState> {
  state: ISCIMAllowedFieldState = {
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };
  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  onSetOrganizationAllowedSCIM = async (enabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set organization to ${enabled ? "allow" : "disallow"} SCIM`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization allowed to use SCIM, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationAllowedSCIMRequest = {
            organization_id: this.props.organization.id,
            allowed: enabled,
          };
          await apiClients.idashboardClient.SetOrganizationAllowedSCIM(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const can_set_allow_scim = this.hasPermission("internal-dashboard.organization.set-allow-scim");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <SCIMAllowedFieldView {...this} {...this.props} {...this.state} canSetAllowSCIM={can_set_allow_scim} />
      </div>
    );
  }
}

export default withRefresh()(SCIMAllowedField);
