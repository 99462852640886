//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import styled from "@emotion/styled";
import humanize from "humanize";
import _, { orderBy } from "lodash";
import { Moment } from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Loader, Menu, Message, Placeholder, Segment, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { DeploymentsCost as ApiDeploymentsCost, GetDeploymentsCostRequest as ApiGetDeploymentsCostRequest, Revenue as ApiRevenue } from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  CreationDateFilter,
  ErrorMessage,
  MonthAndWeekAndYearCreationDateFilters,
  SecondaryMenu,
  Section,
  SectionContent,
  SectionHeader,
  momentDefaultFormat,
  momentNow,
  momentStartOfProject,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

const CombinedRow = styled(Table.Row)`
  font-weight: bold;
`;

const dateFilters = MonthAndWeekAndYearCreationDateFilters(false);

interface IFilterViewArgs {
  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Date: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
    </Menu>
  );
};

interface IStatisticsGroupViewArgs {
  report: ICostReport;
}

const StatisticsGroupsView = ({ ...args }: IStatisticsGroupViewArgs) => {
  const st = args.report.cost;
  const per_entity = st.per_entity || [];
  const total = st.total || {};
  const total_currency = total.currency || {};
  const totCurSign = total_currency.sign || "?";
  return (
    <div>
      <Section>
        <SectionHeader title={args.report.tier_id} />
        <SectionContent>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Entity</Table.HeaderCell>
                <Table.HeaderCell>Total cost</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {per_entity.map((x) => {
                const currency = x.currency || {};
                const curSign = currency.sign || "?";
                return (
                  <Table.Row>
                    <Table.Cell>{x.entity_id || ""}</Table.Cell>
                    <Table.Cell>{`${curSign} ${humanize.numberFormat(x.total_amount || 0)}`}</Table.Cell>
                  </Table.Row>
                );
              })}
              <CombinedRow>
                <Table.Cell>Combined</Table.Cell>
                <Table.Cell>{`${totCurSign} ${humanize.numberFormat(total.total_amount || 0)}`}</Table.Cell>
              </CombinedRow>
            </Table.Body>
          </Table>
        </SectionContent>
      </Section>
    </div>
  );
};

interface IStatisticsViewArgs {
  expectedReports: number;
  reports: ICostReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
}

const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";
  const loading = args.expectedReports > args.reports.length;
  return (
    <div>
      <Message>
        Deployments cost {timeFilter}.{loading && <Loader inline active size="mini" />}
      </Message>
      {args.reports.map((x) => (
        <StatisticsGroupsView {...args} report={x} />
      ))}
    </div>
  );
};

const PlaceholderView = () => (
  <Segment>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

export interface IDeploymentsCostReportingViewArgs extends IFilterViewArgs, RouteComponentProps {
  expectedReports: number;
  reports?: ICostReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
}

export const DeploymentsCostReportingView = ({ ...args }: IDeploymentsCostReportingViewArgs) => {
  const reports = args.reports || [];
  const has_reports = !_.isEmpty(reports);
  return (
    <div>
      <FilterView {...args} />
      {has_reports && <StatisticsView {...args} reports={reports} />}
      {!has_reports && <PlaceholderView />}
    </div>
  );
};

interface IDeploymentsCostReportingProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
}

interface ICostReport {
  tier_id: string;
  cost: ApiDeploymentsCost;
}

interface IDeploymentsCostReportingState {
  sequence: number;
  errorMessage?: string;
  reports: Map<string, ICostReport>;
  tierCount: number;
  filterFrom?: Moment;
  filterTo?: Moment;
  selectedCreationDateFilterID: string;
}

// The component to show deployment cost reporting.
class DeploymentsCostReporting extends Component<IDeploymentsCostReportingProps, IDeploymentsCostReportingState> {
  state: IDeploymentsCostReportingState = {
    sequence: 0,
    errorMessage: undefined,
    filterFrom: undefined,
    filterTo: undefined,
    tierCount: 0,
    reports: new Map<string, ICostReport>(),
    selectedCreationDateFilterID: dateFilters[0].id,
  };

  reloadDeploymentsCost = async () => {
    this.setState(
      (old) => {
        return {
          sequence: old.sequence + 1,
          reports: new Map<string, ICostReport>(),
        };
      },
      async () => {
        const self = this;
        const sequence = this.state.sequence;
        let filterFrom = momentStartOfProject();
        let filterTo = momentNow();

        const f = dateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
        if (f) {
          if (f.filterStart) {
            filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          }
          if (f.filterEnd) {
            filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          }
        }

        // List all tiers
        const tiersList = await apiClients.idashboardClient.ListTiers({});
        const tiers = tiersList.items || [];

        // Load report per tier
        tiers.forEach(async (x) => {
          const id = x.id || "";
          const report = await self.loadDeploymentsCost(id, filterFrom, filterTo);
          self.setState((old) => {
            if (old.sequence != sequence) return { reports: old.reports };
            const newReports = new Map(old.reports);
            newReports.set(id, report);
            return { reports: newReports };
          });
        });

        this.setState({
          filterFrom: filterFrom,
          filterTo: filterTo,
          tierCount: tiers.length,
        });
      }
    );
  };

  loadDeploymentsCost = async (tier_id: string, filterFrom: Moment, filterTo: Moment) => {
    const req: ApiGetDeploymentsCostRequest = {
      tier_id: tier_id,
      from: filterFrom.toDate(),
      to: filterTo.toDate(),
    };

    const cost = await new Promise<ApiRevenue>(async (resolve, reject) => {
      try {
        await apiClients.idashboardClient.GetDeploymentsCost(req, (x) => {
          if (!!x.message) resolve(x.message);
          else if (!!x.error) {
            reject(x.error);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
    const result: ICostReport = {
      tier_id: tier_id,
      cost: cost,
    };
    return result;
  };

  refreshDeploymentCost = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDeploymentsCost);
  };

  componentDidMount() {
    this.refreshDeploymentCost();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState(
      {
        selectedCreationDateFilterID: id,
        reports: new Map<string, ICostReport>(),
      },
      this.refreshDeploymentCost
    );
  };

  render() {
    const reports = orderBy(Array.from(this.state.reports.values()), (report) => report.tier_id);
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Deployments cost</Menu.Item>
        </SecondaryMenu>
        <DeploymentsCostReportingView
          {...this.props}
          {...this.state}
          expectedReports={this.state.tierCount}
          reports={_.isEmpty(reports) ? undefined : reports}
          creationDateFilters={dateFilters}
          onCreationDateFilterChanged={this.onCreationDateFilterChanged}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(DeploymentsCostReporting);
