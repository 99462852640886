//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Icon, IconProps } from "semantic-ui-react";

interface IEditIconArgs extends IconProps {}

export const EditIcon = ({ ...args }: IEditIconArgs) => {
  return <Icon {...args} name="pencil" className="edit-pencil" />;
};
