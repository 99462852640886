//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  ListOptions as ApiListOptions,
  DataProcessingAddendumInfo as ApiDataProcessingAddendumInfo,
  DataProcessingAddendumInfoList as ApiDataProcessingAddendumInfoList,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ContentSegment, ErrorMessage, ListActionEdit, ListActionSetCurrent, ListActionView, Loading, Processing, SecondaryMenu, TextLink } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

const HeaderView = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Locked</Table.HeaderCell>
      <Table.HeaderCell>Current</Table.HeaderCell>
      <Table.HeaderCell>Created at</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiDataProcessingAddendumInfo;
  canUpdate: boolean;
  canSetCurrent: boolean;
  onView: () => void;
  onEdit: () => void;
  onSetCurrent: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const dpa = args.item.dpa || {};
  return (
    <Table.Row>
      <Table.Cell>
        <TextLink href={Routes.dashboard_sales_data_processing_addendum_detailsWithId(dpa.id as string)}>{dpa.id}</TextLink>
      </Table.Cell>
      <Table.Cell>{args.item.locked ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{args.item.current ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{dpa.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={dpa.created_at} label="Created at" /> : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          <ListActionView onClick={args.onView} />
          {args.canUpdate && !args.item.locked && <ListActionEdit onClick={args.onEdit} />}
          {args.canSetCurrent && !args.item.current && <ListActionSetCurrent onClick={args.onSetCurrent} />}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the list
interface IListView {
  items: ApiDataProcessingAddendumInfo[];
  canUpdate: boolean;
  canSetCurrent: boolean;
  onView: (id: string) => void;
  onEdit: (id: string) => void;
  onSetCurrent: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView />
    <Table.Body>
      {args.items.map((item) => {
        const dpa = item.dpa || {};
        const id = dpa.id || "";
        return (
          <RowView {...args} key={id} item={item} onView={() => args.onView(id)} onEdit={() => args.onEdit(id)} onSetCurrent={() => args.onSetCurrent(id)} />
        );
      })}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No data processing addendums</div>;

// Interface describing the list view arguments
export interface IDataProcessingAddendumListViewArgs extends RouteComponentProps {
  loading: boolean;
  list?: ApiDataProcessingAddendumInfoList;
  canUpdate: boolean;
  canSetCurrent: boolean;
  onView: (id: string) => void;
  onEdit: (id: string) => void;
  onSetCurrent: (id: string) => void;
}

export const DataProcessingAddendumListView = ({ ...args }: IDataProcessingAddendumListViewArgs) => {
  if (!args.list) {
    return <Loading />;
  }
  const isEmpty = !args.list.items || args.list.items.length === 0;
  const items = args.list ? args.list.items : undefined;
  return (
    <div>
      <ListView {...args} items={items || []} />
      {isEmpty && <EmptyView />}
    </div>
  );
};

interface IDataProcessingAddendumListProps extends IWithRefreshProps, RouteComponentProps {
  onCreate: () => void;
  onView: (id: string) => void;
  onEdit: (id: string) => void;
}

interface IDataProcessingAddendumListState {
  list?: ApiDataProcessingAddendumInfoList;
  settingCurrent: boolean;
  errorMessage?: string;
}

// The component to show DPAs as a list.
class DataProcessingAddendumList extends Component<IDataProcessingAddendumListProps, IDataProcessingAddendumListState> {
  state: IDataProcessingAddendumListState = {
    list: undefined,
    settingCurrent: false,
    errorMessage: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadDataProcessingAddendums = async () => {
    var req: ApiListOptions = {};
    const list = await apiClients.idashboardClient.ListDataProcessingAddendums(req);
    this.setState({
      list: list,
    });
  };

  refreshDataProcessingAddendums = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDataProcessingAddendums);
  };

  componentDidMount() {
    this.refreshDataProcessingAddendums();
  }

  onSetCurrent = async (id: string) => {
    try {
      this.setState({ settingCurrent: true, errorMessage: undefined });
      const req: ApiIDOptions = {
        id: id,
      };
      await apiClients.idashboardClient.SetCurrentDataProcessingAddendum(req);
      this.refreshDataProcessingAddendums();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ settingCurrent: false });
    }
  };

  render() {
    const list = this.state.list;
    const has_create = this.hasPermission("internal-dashboard.dataprocessingaddendum.create");
    const has_set_current = this.hasPermission("internal-dashboard.dataprocessingaddendum.set-current");
    const has_update = this.hasPermission("internal-dashboard.dataprocessingaddendum.update");

    return (
      <ContentSegment>
        <Processing active={this.state.settingCurrent} message="Changing current Data Processing Addendum..." />
        <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Data Processing Addendums</Menu.Item>
          <Button basic size="tiny" disabled={!has_create} onClick={this.props.onCreate} icon="plus" />
        </SecondaryMenu>
        <DataProcessingAddendumListView {...this.props} list={list} canUpdate={has_update} canSetCurrent={has_set_current} onSetCurrent={this.onSetCurrent} />
      </ContentSegment>
    );
  }
}

export default withRefresh()(DataProcessingAddendumList);
