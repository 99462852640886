//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { numberFormat } from "humanize";
import moment from "moment";
import React from "react";
import { Popup } from "semantic-ui-react";
import { DataVolumeInfo as ApiDataVolumeInfo } from "../../api/lib";
import { humanizeFileSize } from "../../util/FileSize";

export interface IDataVolumeViewArgs {
  data_volume_info?: ApiDataVolumeInfo;
}

export const DataVolumeView = ({ ...args }: IDataVolumeViewArgs) => {
  const dvi = args.data_volume_info || {};
  const sizePercentage = (((dvi.total_bytes || 0) - (dvi.available_bytes || 0)) * 100) / (dvi.total_bytes || 0);
  const inodePercentage = (((dvi.total_inodes || 0) - (dvi.available_inodes || 0)) * 100) / (dvi.total_inodes || 0);
  return (
    <span>
      {args.data_volume_info ? (
        <Popup
          trigger={
            <span>
              <i>
                {numberFormat(sizePercentage, 1)}% ({numberFormat(inodePercentage, 1)} %)
              </i>
            </span>
          }
          content={
            <span>
              <p>
                <b>Size:</b> <br />
                Total: {humanizeFileSize(dvi.total_bytes || 0)} <br />
                Used: {humanizeFileSize(dvi.used_bytes || 0)} <br />
                Available: {humanizeFileSize(dvi.available_bytes || 0)}
              </p>
              <p>
                <b>Inodes:</b> <br />
                Total: {numberFormat(dvi.total_inodes || 0, 0)} <br />
                Used: {numberFormat(dvi.used_inodes || 0, 0)} <br />
                Available: {numberFormat(dvi.available_inodes || 0, 0)}
              </p>
              <p>Measured: {moment(dvi.measured_at).fromNow()}</p>
            </span>
          }
        />
      ) : (
        "-"
      )}
    </span>
  );
};
