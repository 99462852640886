//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Button, Header, Icon, Table } from "semantic-ui-react";
import { TextLink, UserLink } from "../../ui/lib";
import { Routes } from "../../routes";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { UpdateJobListViewArgs } from "./types";
import { find } from "lodash";
import { UpdateJob } from "../../api/deployment-updater/v1/ideploymentupdater";

const UpdateJobListView = ({ jobList, runningJob, startJob, pauseJob, stopJob, deleteJob, resumeJob }: UpdateJobListViewArgs) => {
  const getPausedStatus = (job: UpdateJob) => {
    const { paused, stopped, status = {} } = job;
    const { conditions = [] } = status;

    if (stopped) return "-";

    if (paused) {
      const isActuallyPaused = find(conditions, (condition) => condition.type === "Paused" && condition.status);
      return isActuallyPaused ? (
        <Header as="h5" color="green">
          Yes
        </Header>
      ) : (
        "Queued"
      );
    }

    return "No";
  };

  const getStoppedStatus = (job: UpdateJob) => {
    const { stopped, status = {} } = job;
    const { conditions = [] } = status;
    if (stopped) {
      const isActuallyStopped = find(conditions, (condition) => condition.type === "Stopped" && condition.status);
      return isActuallyStopped ? (
        <Header as="h5" color="green">
          Yes
        </Header>
      ) : (
        "Queued"
      );
    }

    return "No";
  };
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Started</Table.HeaderCell>
          <Table.HeaderCell>Created By</Table.HeaderCell>
          <Table.HeaderCell>Running</Table.HeaderCell>
          <Table.HeaderCell>Paused</Table.HeaderCell>
          <Table.HeaderCell>Stopped</Table.HeaderCell>
          <Table.HeaderCell>Job finished</Table.HeaderCell>
          <Table.HeaderCell>Finished At</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {jobList.map((job) => {
          const { started, paused, stopped, id = "", status = {} } = job;
          const { finished_at: finishedAt, is_running: isRunning } = status;
          return (
            <Table.Row positive={isRunning}>
              <Table.Cell>
                <TextLink label={job.id} href={Routes.dashboard_deployment_update_job_details_with_id(job.id || "")} className={isRunning ? "font-bold" : ""} />
              </Table.Cell>
              <Table.Cell>
                {started ? (
                  <Header as="h5" color="green">
                    Yes
                  </Header>
                ) : (
                  "No"
                )}
              </Table.Cell>
              <Table.Cell>
                <UserLink id={job.created_by || ""} />
              </Table.Cell>
              <Table.Cell>
                {isRunning ? (
                  <Header as="h5" color="green">
                    Yes
                  </Header>
                ) : (
                  "No"
                )}
              </Table.Cell>
              <Table.Cell>{getPausedStatus(job)}</Table.Cell>
              <Table.Cell>{getStoppedStatus(job)}</Table.Cell>
              <Table.Cell>{!!finishedAt ? <Icon name="check" color="green" /> : "-"}</Table.Cell>
              <Table.Cell>
                <DateTimePopupWithUTCAndLocalTime dateTime={finishedAt} label="Finished" />
              </Table.Cell>
              <Table.Cell>
                {paused && started && isRunning && !stopped ? (
                  <Button
                    icon
                    size="tiny"
                    circular
                    onClick={() => {
                      resumeJob(id);
                    }}
                    disabled={!!finishedAt}
                  >
                    <Icon className="rotate-90" name="eject" />
                  </Button>
                ) : (
                  <Button
                    icon
                    size="tiny"
                    circular
                    primary
                    onClick={() => {
                      startJob(id);
                    }}
                    disabled={!!finishedAt || stopped || isRunning}
                  >
                    <Icon name="play" />
                  </Button>
                )}
                <Button icon size="tiny" circular disabled={!!finishedAt || paused || stopped || !started} onClick={() => pauseJob(id)}>
                  <Icon name="pause" />
                </Button>
                <Button icon size="tiny" circular negative disabled={!!finishedAt || !started || stopped} onClick={() => stopJob(id)}>
                  <Icon name="stop" />
                </Button>
                <Button icon size="tiny" circular color="red" disabled={!finishedAt && started && !stopped} onClick={() => deleteJob(id)}>
                  <Icon name="trash" />
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default UpdateJobListView;
