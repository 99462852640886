//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dimmer, Dropdown, Loader, Menu, Modal } from "semantic-ui-react";
import api from "../../api/api";
import { Deployment_ServerStatus as ApiDeployment_ServerStatus } from "../../api/lib";
import { ContentActionButton } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { CopyToClipboard } from "react-copy-to-clipboard";

const LogsContainer = styled("div")`
  min-height: 20vh;
`;

interface IPeriod {
  id: string;
  startAt: string;
  endAt: string;
  title: string;
}

const periods = [
  { id: "default", startAt: "1h", endAt: "", title: "Last hour" },
  { id: "2h", startAt: "2h", endAt: "", title: "Last 2 hours" },
  { id: "3h", startAt: "3h", endAt: "", title: "Last 3 hours" },
  { id: "6h", startAt: "6h", endAt: "", title: "Last 6 hours" },
  { id: "12h", startAt: "12h", endAt: "", title: "Last 12 hours" },
  { id: "24h", startAt: "24h", endAt: "", title: "Last 24 hours" },
] as IPeriod[];

interface IRole {
  id: string;
  title: string;
}

const roles = [
  { id: "all", title: "All" },
  { id: "agents", title: "Agents" },
  { id: "coordinators", title: "Coordinators" },
  { id: "dbservers", title: "DBServers" },
  { id: "singles", title: "Single" },
] as IRole[];

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

interface ILogsModalViewArgs {
  open: boolean;
  loading: boolean;
  logs?: string;
  onClose: () => void;

  selectedPeriodId: string;
  onPeriodFilterChanged: (id: string) => void;

  selectedRoleId: string;
  onRoleFilterChanged: (id: string) => void;

  selectedServerId: string;
  servers: ApiDeployment_ServerStatus[];
  onServerFilterChanged: (id: string) => void;

  copiedToClipboard: boolean;
  onCopied: () => void;
}

const LogsModalView = ({ ...args }: ILogsModalViewArgs) => {
  const empty = _.isEmpty(args.logs);
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const periodOptions = periods.map((x, i) => {
    return {
      key: `period-${x.id || ""}`,
      text: x.title,
      value: x.id,
    };
  });

  const hasAgents = args.servers.some((x) => x.type == "Agent");
  const hasSingles = args.servers.some((x) => x.type == "Single");
  const hasCoordinators = args.servers.some((x) => x.type == "Coordinator");
  const hasDBServers = args.servers.some((x) => x.type == "Dbserver");

  const roleOptions = roles
    .filter((x, i) => {
      switch (x.id) {
        case "all":
          return true;
        case "agents":
          return hasAgents;
        case "coordinators":
          return hasCoordinators;
        case "dbservers":
          return hasDBServers;
        case "singles":
          return hasSingles;
      }
      return false;
    })
    .map((x, i) => {
      return {
        key: `role-${x.id || ""}`,
        text: x.title,
        value: x.id,
      };
    });

  const serverOptions = args.servers.map((x, i) => {
    return {
      key: `server-${x.id || ""}`,
      text: x.id || "",
      value: x.id || "",
    };
  });
  serverOptions.unshift({ key: "server-all", text: "All", value: "all" });

  return (
    <Modal open={args.open} onClose={args.onClose} size="fullscreen">
      <Modal.Header>
        <Menu borderless pointing stackable>
          <Menu.Item header>Filter</Menu.Item>
          <Dropdown
            item
            value={args.selectedPeriodId}
            text={`Period: ${getOptionText(periodOptions, args.selectedPeriodId) || "?"}`}
            options={periodOptions}
            onChange={(e, d) => args.onPeriodFilterChanged(d.value as string)}
          />
          <Dropdown
            item
            value={args.selectedRoleId}
            text={`Server role: ${getOptionText(roleOptions, args.selectedRoleId) || "?"}`}
            options={roleOptions}
            onChange={(e, d) => args.onRoleFilterChanged(d.value as string)}
          />
          <Dropdown
            item
            value={args.selectedServerId}
            text={`Server: ${getOptionText(serverOptions, args.selectedServerId) || "?"}`}
            options={serverOptions}
            onChange={(e, d) => args.onServerFilterChanged(d.value as string)}
          />
          {!empty && (
            <Menu.Item position="right">
              <CopyToClipboard text={args.logs} onCopy={args.onCopied}>
                <Button icon={args.copiedToClipboard ? "check" : "copy"} labelPosition="right" content="Copy to clipboard" />
              </CopyToClipboard>
            </Menu.Item>
          )}
        </Menu>
      </Modal.Header>
      <Modal.Content scrolling>
        <LogsContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Fetching logs, please wait
              </Loader>
            </Dimmer>
          )}
          {!args.loading && !empty && <pre>{args.logs}</pre>}
          {!args.loading && empty && (
            <span>
              <i>No data</i>
            </span>
          )}
        </LogsContainer>
      </Modal.Content>
    </Modal>
  );
};

interface ILogsModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
  servers: ApiDeployment_ServerStatus[];
}

interface ILogsModalState {
  open: boolean;
  loading: boolean;
  logs?: string;
  selectedPeriodId: string;
  selectedRoleId: string;
  selectedServerId: string;
  errorMessage?: string;
  copiedToClipboard: boolean;
}

// Component to show deployment logs
class LogsModal extends Component<ILogsModalProps, ILogsModalState> {
  state: ILogsModalState = {
    open: false,
    loading: false,
    logs: undefined,
    selectedPeriodId: "default",
    selectedRoleId: "all",
    selectedServerId: "all",
    errorMessage: undefined,
    copiedToClipboard: false,
  };

  reloadLogs = async () => {
    this.setState({ loading: true, errorMessage: undefined });
    try {
      const period = _.find(periods, (p) => p.id == this.state.selectedPeriodId);
      const role = _.find(roles, (r) => r.id == this.state.selectedRoleId);
      const query = {} as any;
      if (!!period) {
        query["start_at"] = period.startAt;
        query["end_at"] = period.endAt;
      }
      if (!!role && role.id != "all") {
        query["role"] = role.id;
      }
      if (this.state.selectedServerId != "all") {
        query["server_id"] = this.state.selectedServerId;
      }
      const qs = api.queryString(query, []);
      const logs = await api.getText(`/downloads/v1/deployments/${this.props.deploymentId}/logs${qs}`);
      this.setState({ loading: false, logs: logs });
    } catch (e) {
      this.setState({ loading: false, logs: undefined, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true }, this.reloadLogs);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  onPeriodFilterChanged = (id: string) => {
    this.setState({ selectedPeriodId: id }, this.reloadLogs);
  };

  onRoleFilterChanged = (id: string) => {
    this.setState({ selectedRoleId: id }, this.reloadLogs);
  };

  onServerFilterChanged = (id: string) => {
    this.setState({ selectedServerId: id }, this.reloadLogs);
  };

  onCopied = () => {
    this.setState({ copiedToClipboard: true }, () => {
      const setTimeout = this.props.setTimeout;
      setTimeout &&
        setTimeout(() => {
          this.setState({ copiedToClipboard: false });
        }, 3000);
    });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="View logs" icon="history" />
        <LogsModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(LogsModal);
