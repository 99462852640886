//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany

import React, { useState, useEffect } from "react";
import UserCache from "../util/UserCache";
import { Routes } from "../routes";
import { TextLink } from "./lib";
import { User } from "../api/lib";

export interface IUserLinkArgs {
  id: string;
}

export const UserLink = (args: IUserLinkArgs) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [error, setError] = useState("");

  const cache = new UserCache();
  useEffect(() => {
    const getUser = async () => {
      const user = await cache.getUser(args.id);
      setUser(user);
    };
    getUser().catch(setError);
  }, [args.id]);

  return <TextLink disabled={!!error} label={user ? user.name : "...loading"} href={Routes.dashboard_sales_user_detailsWithId(args.id)} />;
};
