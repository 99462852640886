//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Grid, Loader, Menu, Segment } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { UploadedDocument as ApiUploadedDocument, GetUploadedDocumentRequest as ApiGetUploadedDocumentRequest } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import Markdown from "react-markdown";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import {
  ContentSegment,
  Field,
  FieldContent as FC,
  FieldLabel as FL,
  LoaderBox,
  MenuActionBack,
  SecondaryMenu,
  Section,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IUploadedDocumentViewArgs {
  uploadedDocument: ApiUploadedDocument;
}

const UploadedDocumentView = ({ ...args }: IUploadedDocumentViewArgs) => {
  return (
    <div>
      <Segment>
        <Grid width="16">
          <Grid.Column width="8">
            <Field>
              <FL>ID</FL>
              <FC>
                <div>{args.uploadedDocument.id || "-"}</div>
              </FC>
            </Field>
            <Field>
              <FL>Name</FL>
              <FC>
                <div>{args.uploadedDocument.name || "-"}</div>
              </FC>
            </Field>
          </Grid.Column>
          <Grid.Column width="8">
            <Field>
              <FL>Created</FL>
              <FC>
                {args.uploadedDocument.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.uploadedDocument.created_at} label="Created at" /> : "-"}
              </FC>
            </Field>
            {args.uploadedDocument.content && (
              <a href={`data:${args.uploadedDocument.content_type};base64,${args.uploadedDocument.content}`} download={args.uploadedDocument.name}>
                <Button content="Download file" size="small" />
              </a>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment>
        <Markdown source={args.uploadedDocument.description || ""} />
      </Segment>
    </div>
  );
};

interface IUploadedDocumentDetailsProps extends IWithRefreshProps, RouteComponentProps {
  uploadedDocumentId: string;
}

interface IUploadedDocumentDetailsState {
  uploadedDocument?: ApiUploadedDocument;
}

// The component to show uploaded document details.
class UploadedDocumentDetails extends Component<IUploadedDocumentDetailsProps, IUploadedDocumentDetailsState> {
  state: IUploadedDocumentDetailsState = {
    uploadedDocument: undefined,
  };

  reloadUploadedDocument = async () => {
    const findById: ApiGetUploadedDocumentRequest = { id: this.props.uploadedDocumentId };
    var uploadedDocument = await apiClients.idashboardClient.GetUploadedDocument(findById);
    this.setState({
      uploadedDocument: uploadedDocument,
    });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUploadedDocument);
  }

  render() {
    const has_uploadedDocument = !!this.state.uploadedDocument;
    const uploadedDocument = this.state.uploadedDocument || {};

    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Uploaded Document Details</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        {has_uploadedDocument && (
          <div>
            <Section>
              <SectionHead>
                <SectionHeader title="Details" />
              </SectionHead>
              <SectionContent>
                <UploadedDocumentView {...this.props} {...this.state} uploadedDocument={uploadedDocument} />
              </SectionContent>
            </Section>
          </div>
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(UploadedDocumentDetails);
