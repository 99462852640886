//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ContentSegment, ReportContainer } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface IDailyEssentialsProps extends IWithRefreshProps, RouteComponentProps {}

interface IDailyEssentialsState {}

class DailyEssentials extends Component<IDailyEssentialsProps, IDailyEssentialsState> {
  state: IDailyEssentialsState = {};

  render() {
    return (
      <ContentSegment>
        <ReportContainer>
          <iframe
            title="Daily Essentials Report"
            width="100%"
            height="100%"
            src="https://datastudio.google.com/embed/reporting/1rYj0zYcRc-2DoGNCAKA1J6KDnuOgxjTb/page/1Zf8"
            style={{ border: 0 }}
          />
        </ReportContainer>
      </ContentSegment>
    );
  }
}

export default withRefresh()(DailyEssentials);
