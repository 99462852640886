//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps, Loader } from "semantic-ui-react";
import { ProviderList as ApiProviderList, Provider as ApiProvider, ListProvidersRequest as ApiListProvidersRequest } from "../api/lib";
import { Cached as apiClients } from "../api/apiclients";
import _ from "lodash";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";

interface IProviderInputProps extends IWithRefreshProps {
  id?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowAny?: boolean;
  disabled?: boolean;
}

interface IProviderInputState {
  id: string;
  errorMessage?: string;
  loading: boolean;
  providers?: ApiProviderList;
  refreshNeeded: boolean;
}

class ProviderInputComp extends Component<IProviderInputProps, IProviderInputState> {
  state: IProviderInputState = {
    id: this.props.id || "",
    errorMessage: undefined,
    loading: true,
    refreshNeeded: false,
  };

  reloadProviders = async () => {
    this.setState({ loading: true });
    const req: ApiListProvidersRequest = {};
    const providers = await apiClients.authenticationOnly.platformClient.ListProviders(req);
    this.setState({ providers: providers, loading: false });
  };

  refreshProviders = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadProviders);
  };

  static getDerivedStateFromProps(props: IProviderInputProps, state: IProviderInputState) {
    const id = props.id || "";
    if (id != state.id) {
      return {
        refreshNeeded: true,
        id: id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshProviders();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshProviders);
    }
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  render() {
    const providers = this.state.providers;
    let list = _.clone((providers || {}).items || []);
    if (this.props.allowAny) {
      list.push({ id: "", name: "Any" });
    }
    return (
      <div>
        {this.state.loading && <Loader active size="tiny" />}
        {providers && providers.items && (
          <Dropdown
            fluid
            selection
            placeholder={this.props.placeholder}
            options={_.map(list, (x: ApiProvider) => {
              return {
                key: x.id,
                text: x.name,
                value: x.id,
              };
            })}
            onChange={this.onChange}
            value={this.state.id}
            disabled={this.props.disabled}
          />
        )}
      </div>
    );
  }
}

export const ProviderInput = withRefresh()(ProviderInputComp);
