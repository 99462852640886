import { CacheItem } from "./PermissionCache";

//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

export class PersistentState {
  private static selectedOrganizationId = "selectedOrganizationId";
  private static collapsedTreeViewItems = "collapsedTreeViewItems";
  private static permissionCache = "permissionCache";

  public static saveSelectedOrganizationId = (id: string): void => {
    localStorage.setItem(PersistentState.selectedOrganizationId, id);
  };

  public static removeSelectedOrganizationId = (): void => {
    localStorage.removeItem(PersistentState.selectedOrganizationId);
  };

  public static retrieveSelectedOrganizationId = (): string | null => {
    return localStorage.getItem(PersistentState.selectedOrganizationId);
  };

  public static saveCollapsedTreeViewItems = (state: Array<string>): void => {
    const collapsedTreeViewItems = JSON.stringify(state);
    localStorage.setItem(PersistentState.collapsedTreeViewItems, collapsedTreeViewItems);
  };

  public static retrieveCollapsedTreeViewItems = (): Array<string> => {
    const collapsedTreeViewItems = localStorage.getItem(PersistentState.collapsedTreeViewItems);
    if (collapsedTreeViewItems) {
      try {
        return JSON.parse(collapsedTreeViewItems) as Array<string>;
      } catch {
        console.warn("Cannot deserialize collapsedTreeViewItems state: " + collapsedTreeViewItems);
        // return a new array
      }
    }
    return new Array<string>();
  };

  public static savePermissionCache = (cache: Map<string, CacheItem>): number => {
    let obj = Object.create(null);
    cache.forEach((cacheItem: CacheItem, url) => {
      if (!cacheItem.isOutdated()) {
        obj[url] = JSON.stringify(cacheItem);
      }
    });
    const json = JSON.stringify(obj);
    localStorage.setItem(PersistentState.permissionCache, json);

    return json.length;
  };

  public static retrievePermissionCache = (id: number): Map<string, CacheItem> => {
    let map = new Map<string, CacheItem>();
    const permissionCache = localStorage.getItem(PersistentState.permissionCache);
    if (permissionCache) {
      try {
        const obj = JSON.parse(permissionCache);
        for (let key of Object.keys(obj)) {
          const val = obj[key];
          const parsedVal = JSON.parse(val);
          const permissions = parsedVal["permissions"] as string[];
          const createDate = parsedVal["createDate"] as number;
          if (permissions && createDate) {
            map.set(key, new CacheItem(id, permissions, createDate));
          }
        }
      } catch {
        console.warn("Cannot deserialize permissionCache state: " + permissionCache);
        // return a new array
      }
    }
    return map;
  };
}
