//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
// Author Robert Stam
//

import React, { Component } from "react";
import { Form, Icon, Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/lib";
import { RegionLimits as ApiRegionLimits } from "../../api/platform/v1/iplatform";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Processing } from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import _ from "lodash";

// Interface describing the list view arguments
export interface IRegionLimitsViewArgs extends RouteComponentProps {
  loading: boolean;
  limits?: ApiRegionLimits;
  can_set_limits: boolean;
  edit_limits: boolean;
  deployment_limit: string;
  onChangeDeploymentLimit: (value: string) => void;
  onEdit: () => void;
  onCancelEdit: () => void;
  onSaveEdit: () => void;
}

export const RegionLimitsView = ({ ...args }: IRegionLimitsViewArgs) => {
  if (!args.limits) {
    return <Loader size="mini">Loading</Loader>;
  }
  if (args.edit_limits) {
    return (
      <div>
        <Form.Input
          value={args.deployment_limit}
          onChange={(e, d) => args.onChangeDeploymentLimit(d.value)}
          action={[<Icon name="check" onClick={args.onSaveEdit} />, <Icon name="cancel" onClick={args.onCancelEdit} />]}
        />
      </div>
    );
  }
  return (
    <div>
      {args.limits.deployment_limit || "0"}
      <span> </span>
      {args.can_set_limits && <Icon name="pencil" onClick={args.onEdit} />}
    </div>
  );
};

interface IRegionLimitsProps extends IWithRefreshProps, RouteComponentProps {
  region_id: string;
}

interface IRegionLimitsState {
  prev_region_id: string;
  refreshNeeded: boolean;
  limits?: ApiRegionLimits;
  errorMessage?: string;
  edit_limits: boolean;
  deployment_limit: string;
  saving: boolean;
}

// The component to show limits of a region.
class RegionLimits extends Component<IRegionLimitsProps, IRegionLimitsState> {
  state: IRegionLimitsState = {
    prev_region_id: "",
    refreshNeeded: false,
    limits: undefined,
    errorMessage: undefined,
    edit_limits: false,
    deployment_limit: "",
    saving: false,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadLimits = async () => {
    var req: ApiIDOptions = {
      id: this.props.region_id,
    };
    const limits = await apiClients.idashboardClient.GetRegionLimits(req);
    this.setState({
      limits: limits,
      deployment_limit: _.toString(limits.deployment_limit || 0),
    });
  };

  refreshLimits = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadLimits);
  };

  static getDerivedStateFromProps(props: IRegionLimitsProps, state: IRegionLimitsState) {
    if (props.region_id !== state.prev_region_id) {
      return {
        refreshNeeded: true,
        prev_region_id: props.region_id || "",
        edit_limits: false,
        saving: false,
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshLimits();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshLimits);
    }
  }

  onEdit = () => {
    this.setState({ edit_limits: true });
  };

  onCancelEdit = () => {
    this.setState({ edit_limits: false });
    this.refreshLimits();
  };

  onChangeDeploymentLimit = (value: string) => {
    this.setState({ deployment_limit: value });
  };

  onSaveEdit = async () => {
    try {
      this.setState({ saving: true, errorMessage: undefined });
      const req: ApiRegionLimits = {
        id: this.props.region_id,
        deployment_limit: _.toNumber(this.state.deployment_limit),
      };
      await apiClients.idashboardClient.SetRegionLimits(req);
      this.refreshLimits();
      this.setState({ edit_limits: false });
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const has_set_limits = this.hasPermission("internal-dashboard.regionlimits.set");

    return (
      <div>
        <Processing active={this.state.saving} message="Saving limits..." />
        <ErrorMessage active={!!this.state.errorMessage} message={this.state.errorMessage} />
        <RegionLimitsView
          {...this.props}
          {...this.state}
          can_set_limits={has_set_limits}
          onChangeDeploymentLimit={this.onChangeDeploymentLimit}
          onEdit={this.onEdit}
          onCancelEdit={this.onCancelEdit}
          onSaveEdit={this.onSaveEdit}
        />
      </div>
    );
  }
}

export default withRefresh()(RegionLimits);
