//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps, Loader } from "semantic-ui-react";
import {
  ListOptions as ApiListOptions,
  NodeSizeList as ApiNodeSizeList,
  NodeSizesRequest as ApiNodeSizesRequest,
  NodeSize as ApiNodeSize,
  CPUSizeList as ApiCPUSizeList,
  ListCPUSizesRequest as ApiListCPUSizesRequest,
} from "../api/lib";
import apiClients from "../api/apiclients";
import { Cached as cachedApiClients } from "../api/apiclients";
import _ from "lodash";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";

interface INodeSizeInputProps extends IWithRefreshProps {
  organizationID?: string;
  id?: string;
  region_id?: string;
  model?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowAny?: boolean;
}

interface INodeSizeInputState {
  organizationID?: string;
  id: string;
  region_id: string;
  model?: string;
  errorMessage?: string;
  loading: boolean;
  node_sizes?: ApiNodeSizeList;
  cpu_sizes?: ApiCPUSizeList;
  refreshNeeded: boolean;
}

class NodeSizeInputComp extends Component<INodeSizeInputProps, INodeSizeInputState> {
  state: INodeSizeInputState = {
    organizationID: this.props.organizationID,
    id: this.props.id || "",
    region_id: this.props.region_id || "",
    model: this.props.model,
    errorMessage: undefined,
    loading: true,
    node_sizes: undefined,
    cpu_sizes: undefined,
    refreshNeeded: false,
  };

  reloadCPUSizes = async () => {
    const req: ApiListCPUSizesRequest = { project_id: "all" };
    const list = await cachedApiClients.authenticationOnly.dataClient.ListCPUSizes(req);
    this.setState({ cpu_sizes: list });
  };

  reloadNodeSizes = async () => {
    this.setState({ loading: true });
    if (_.isEmpty(this.state.region_id)) {
      const req: ApiListOptions = {};
      const node_sizes = await cachedApiClients.idashboardClient.ListAllNodeSizes(req);
      this.setState({
        node_sizes: node_sizes,
        loading: false,
      });
    } else {
      const req: ApiNodeSizesRequest = {
        organization_id: this.state.organizationID,
        project_id: "all",
        region_id: this.state.region_id,
        model: this.state.model,
      };
      const list = await apiClients.idashboardClient.ListNodeSizes(req);
      this.setState({
        node_sizes: list,
        loading: false,
      });
    }
  };

  refreshCPUSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCPUSizes);
  };

  refreshNodeSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadNodeSizes);
  };

  static getDerivedStateFromProps(props: INodeSizeInputProps, state: INodeSizeInputState) {
    const orgID = props.organizationID || "";
    const id = props.id || "";
    const region_id = props.region_id || "";
    const model = props.model;
    if (orgID != state.organizationID || id != state.id || region_id != state.region_id || model != state.model) {
      return {
        refreshNeeded: true,
        organizationID: orgID,
        id: id,
        region_id: region_id,
        model: model,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshCPUSizes();
    this.refreshNodeSizes();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => {
        this.refreshCPUSizes();
        this.refreshNodeSizes();
      });
    }
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  render() {
    const node_sizes = this.state.node_sizes;
    let list = _.clone((node_sizes || {}).items || []);
    const cpu_sizes = (this.state.cpu_sizes || {}).items || [];
    const getCPUSizeName = (cpu_size_id?: string) => {
      const cpuSize = _.find(cpu_sizes, (x) => x.id == cpu_size_id);
      return !!cpuSize ? cpuSize.name : cpu_size_id;
    };

    if (this.props.allowAny) {
      list.push({ id: "", name: "Any" });
    }
    return (
      <div>
        {this.state.loading && <Loader active size="tiny" />}
        {node_sizes && node_sizes.items && (
          <Dropdown
            fluid
            selection
            placeholder={this.props.placeholder}
            options={_.map(list, (x: ApiNodeSize) => {
              return {
                key: x.id,
                text: `${x.name} (${getCPUSizeName(x.cpu_size)})`,
                value: x.id,
              };
            })}
            onChange={this.onChange}
            value={this.state.id}
          />
        )}
      </div>
    );
  }
}

export const NodeSizeInput = withRefresh()(NodeSizeInputComp);
