//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Form, Grid, Icon, Message, Modal } from "semantic-ui-react";
import { ErrorMessage, FormActionButtonCancel, FormActionButtonCreate, momentNow, QuarterMonthAndWeekAndYearCreationDateFilters } from "../../ui/lib";
import { FlexBox } from "../../ui/_flex";
import apiClients from "../../api/apiclients";
import { CreateReportRequest as ApiCreateReportRequest, CreateReportRequest_SalesReport as ApiSalesReport } from "../../api/lib";
import { find, map } from "lodash";

const dateFilters = QuarterMonthAndWeekAndYearCreationDateFilters(false);
const creationDateOptions = map(dateFilters, (filter) => {
  return {
    key: `crd-${filter.id}`,
    text: filter.name,
    value: filter.id,
  };
});

// Interval of report periods
export type Interval = "byWeek" | "byMonth";

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

export interface ICreateReportModalViewArgs {
  onClose: () => void;
  options: ApiSalesReport;
}

const CreateReportModalView = ({ onClose, options }: ICreateReportModalViewArgs) => {
  const [errorMessage, updateErrorMessage] = useState("");
  const [creating, setCreating] = useState(false);
  const [email, setEmail] = useState("");
  const [dateFilterID, setDateFilterID] = useState(dateFilters[0].id);
  const [interval, setIntervalValue] = useState<Interval>("byMonth");
  const [includeTotalRevenue, setIncludeTotalRevenue] = useState(options.include_total_revenue || false);
  const [includeRevenueByOrganization, setIncludeRevenueByOrganization] = useState(options.include_revenue_by_organization || false);
  const [includeTotalRevenueByOrganization, setIncludeTotalRevenueByOrganization] = useState(options.include_total_revenue_by_organization || false);
  const [includeInvoiceStatistics, setIncludeInvoiceStatistics] = useState(options.include_invoice_statistics || false);
  const [includePendingInvoices, setIncludePendingInvoices] = useState(options.include_pending_invoices || false);
  const [includeRejectedInvoices, setIncludeRejectedInvoices] = useState(options.include_rejected_invoices || false);
  const [includeCustomerChanges, setIncludeCustomerChanges] = useState(options.include_customer_changes || false);
  const [includeDeploymentChanges, setIncludeDeploymentChanges] = useState(options.include_deployment_changes || false);
  const [includeDeploymentCosts, setIncludeDeploymentCosts] = useState(options.include_deployment_changes || false);
  const [includeTotalCredits, setIncludeTotalCredits] = useState(options.include_total_credits || false);
  const [includeCreditsByOrganization, setIncludeCreditsByOrganization] = useState(options.include_credits_by_organization || false);
  const [reportID, setReportID] = useState("");

  // Load current user
  useEffect(() => {
    initUser();
  }, []);

  const initUser = async () => {
    const user = await apiClients.authenticationOnly.iamClient.GetThisUser();
    setEmail(user.email || "");
  };

  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = find(options, (option) => option.value === value);
    return item && item.text;
  };

  const doCreate = async () => {
    try {
      updateErrorMessage("");
      setCreating(true);
      const req: ApiCreateReportRequest = {
        email_to: email,
        period_splitter: {
          by_month: interval === "byMonth",
          by_week: interval === "byWeek",
        },
        sales_report: {
          include_total_revenue: includeTotalRevenue,
          include_revenue_by_organization: includeRevenueByOrganization,
          include_invoice_statistics: includeInvoiceStatistics,
          include_pending_invoices: includePendingInvoices,
          include_rejected_invoices: includeRejectedInvoices,
          include_customer_changes: includeCustomerChanges,
          include_deployment_changes: includeDeploymentChanges,
          include_deployment_costs: includeDeploymentCosts,
          include_total_revenue_by_organization: includeTotalRevenueByOrganization,
          include_total_credits: includeTotalCredits,
          include_credits_by_organization: includeCreditsByOrganization,
        },
      };
      const filter = dateFilters.find((filter) => filter.id === dateFilterID);
      if (filter) {
        if (filter.filterStart) {
          req.from = filter.filterStart(momentNow().add(-filter.created_from_hours, "hours")).toDate();
        }
        if (filter.filterEnd) {
          req.to = filter.filterEnd(momentNow().add(-filter.created_to_hours, "hours")).toDate();
        }
      }

      const resp = await apiClients.idashboardClient.CreateReport(req);
      setReportID(resp.report_id || "");
    } catch (e) {
      updateErrorMessage(`Creating report failed: ${e}`);
    } finally {
      setCreating(false);
    }
  };

  const hasEmail = !!email;
  const canCreate = !creating && hasEmail;

  return (
    <Modal centered open closeOnEscape closeOnDimmerClick>
      <Modal.Header>
        <FlexBox justify="space-between">
          <span>Create report [BETA]</span>
          <Icon className="cursor-pointer" name="close" onClick={onClose} />
        </FlexBox>
      </Modal.Header>
      <Modal.Content>
        {!!errorMessage && (
          <ErrorMessage
            active
            message={errorMessage}
            onDismiss={() => {
              updateErrorMessage("");
            }}
          />
        )}
        {!!reportID && (
          <Message>
            A report with ID <b>{reportID}</b> is being created.
            <br />
            Once the report is ready, it will be sent by email.
          </Message>
        )}
        <Form>
          <Form.Input value={email} onChange={(e, d) => setEmail(d.value)} label="Send report to?" type="email" placeholder="Email address" />

          <Grid columns={3}>
            <Grid.Column>
              <Form.Group grouped>
                <label>Time range</label>
                <Form.Dropdown
                  item
                  scrolling
                  search
                  value={dateFilterID}
                  text={getOptionText(creationDateOptions, dateFilterID) || "?"}
                  options={creationDateOptions}
                  onChange={(e, d) => setDateFilterID(d.value as string)}
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Group grouped>
                <label>Split periods</label>
                <Form.Radio checked={interval === "byMonth"} label="By month" onClick={() => setIntervalValue("byMonth")} />
                <Form.Radio checked={interval === "byWeek"} label="By week" onClick={() => setIntervalValue("byWeek")} />
              </Form.Group>
            </Grid.Column>
          </Grid>
          <Grid columns={4}>
            <Grid.Column>
              <Form.Group grouped>
                <label>Revenue</label>
                <Form.Checkbox label="Total" checked={includeTotalRevenue} onChange={(e, d) => setIncludeTotalRevenue(!!d.checked)} />
                <Form.Checkbox
                  label="Recurring by organization"
                  checked={includeRevenueByOrganization}
                  onChange={(e, d) => setIncludeRevenueByOrganization(!!d.checked)}
                />
                <Form.Checkbox
                  label="Total by organization"
                  checked={includeTotalRevenueByOrganization}
                  onChange={(e, d) => setIncludeTotalRevenueByOrganization(!!d.checked)}
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Group grouped>
                <label>Invoices</label>
                <Form.Checkbox label="Statistics" checked={includeInvoiceStatistics} onChange={(e, d) => setIncludeInvoiceStatistics(!!d.checked)} />
                <Form.Checkbox label="Pending" checked={includePendingInvoices} onChange={(e, d) => setIncludePendingInvoices(!!d.checked)} />
                <Form.Checkbox label="Rejected" checked={includeRejectedInvoices} onChange={(e, d) => setIncludeRejectedInvoices(!!d.checked)} />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Group grouped>
                <label>Customers / deployments</label>
                <Form.Checkbox label="Customer changes" checked={includeCustomerChanges} onChange={(e, d) => setIncludeCustomerChanges(!!d.checked)} />
                <Form.Checkbox label="Deployment changes" checked={includeDeploymentChanges} onChange={(e, d) => setIncludeDeploymentChanges(!!d.checked)} />
                <Form.Checkbox label="Deployment costs" checked={includeDeploymentCosts} onChange={(e, d) => setIncludeDeploymentCosts(!!d.checked)} />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Group grouped>
                <label>Credits</label>
                <Form.Checkbox label="Total" checked={includeTotalCredits} onChange={(e, d) => setIncludeTotalCredits(!!d.checked)} />
                <Form.Checkbox
                  label="By organization"
                  checked={includeCreditsByOrganization}
                  onChange={(e, d) => setIncludeCreditsByOrganization(!!d.checked)}
                />
              </Form.Group>
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCreate onClick={doCreate} disabled={!canCreate} title="Create report" primary />
        <FormActionButtonCancel onClick={onClose} title="Close" />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateReportModalView;
