//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Gergely Brautigam
//

import styled from "@emotion/styled";
import Downloader from "js-file-downloader";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import Auth from "../../auth/Auth";
import { ContentActionButton } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

const DataContainer = styled("div")`
  min-height: 20vh;
`;

interface IAgencyDumpModalViewArgs {
  open: boolean;
  loading: boolean;
  onClose: () => void;
}

const AgencyDumpModalView = ({ ...args }: IAgencyDumpModalViewArgs) => {
  return (
    <Modal open={args.open} onClose={args.onClose}>
      <Modal.Content scrolling>
        <DataContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Fetching agency dump, please wait
              </Loader>
            </Dimmer>
          )}
        </DataContainer>
      </Modal.Content>
    </Modal>
  );
};

interface IAgencyDumpModalProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  deploymentId: string;
}

interface IAgencyDumpModalState {
  open: boolean;
  loading: boolean;
  errorMessage?: string;
}

// Component to view agency dump
class AgencyDumpModal extends Component<IAgencyDumpModalProps, IAgencyDumpModalState> {
  state: IAgencyDumpModalState = {
    open: false,
    loading: false,
    errorMessage: undefined,
  };

  reloadAgencyDump = async () => {
    this.setState({ loading: true, errorMessage: undefined });
    try {
      const fileURL = `/downloads/v1/deployments/${this.props.deploymentId}/agency-dump`;
      const downloader = new Downloader({
        url: fileURL,
        filename: `agency-history-${this.props.deploymentId}.json`,
        headers: [{ name: "Authorization", value: `bearer ${this.props.auth.getAccessToken()} ` }],
      });
      await downloader;
      this.setState({ loading: false, open: false });
    } catch (e) {
      this.setState({ loading: false, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true }, this.reloadAgencyDump);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Agency History" icon="history" />
        <AgencyDumpModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(AgencyDumpModal);
