//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import { Version as ApiVersion } from "../api/lib";

export const formatVersion = (version: ApiVersion) => {
  if (!!version.is_end_of_life) {
    return `${version.version} (EOL)`;
  }
  return version.version || "?";
};
