//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Icon, Popup } from "semantic-ui-react";

interface ITextWithHelpArgs {
  text: any;
  help: any;
}

export const TextWithHelp = ({ ...args }: ITextWithHelpArgs) => {
  return (
    <span>
      {args.text} <Popup content={args.help} trigger={<Icon name="info circle" />} />
    </span>
  );
};
