//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
// Author Ewout Prangsma
//

import apiclients from "../api/apiclients";
import { URLOptions as ApiURLOptions } from "../api/lib";
import { PersistentState } from "./PersistentState";
import _ from "lodash";
import { Permission } from "./Permission";

// Ensure that an import of this file is sufficient to import Permission & ResourceType as well.
export * from "./Permission";
export * from "./ResourceType";

export class PermissionCache {
  private debug: boolean;
  private cache: Map<string, CacheItem>;
  private pendingRequests: Map<string, RequestHandler>;
  private lastId: number;

  constructor(debug?: boolean) {
    this.debug = !!debug;
    this.lastId = 2;
    this.cache = PersistentState.retrievePermissionCache(1);
    if (this.debug) {
      console.info(`Cache entries at startup: ${this.cache.size}`);
    }
    this.pendingRequests = new Map<string, RequestHandler>();
  }

  private uptodate = (url: string) => {
    const cacheItem = this.cache.get(url);
    return cacheItem && !cacheItem.isOutdated();
  };

  private upsert = (url: string, permissions: string[]) => {
    const sortedPermissions = _.sortBy(permissions);
    const previousItem = this.cache.get(url);
    if (!!previousItem && previousItem.permissionsEqual(sortedPermissions)) {
      // No change
      previousItem.resetDate();
      return;
    }

    this.lastId++;
    const id = this.lastId;
    this.cache.set(url, new CacheItem(id, sortedPermissions));
    const size = PersistentState.savePermissionCache(this.cache);
    if (this.debug) {
      console.info(`Cache entries: ${this.cache.size} json size: ${size}`);
    }
  };

  // Add a permission to cache and returns an boolean if an entry has been added
  private updatePermission = async (url: string): Promise<boolean> => {
    // Check again to prevent additional traffic
    if (this.uptodate(url)) {
      // Already added and up-to-date
      return false;
    }

    //check pending requests
    let requestHandler = this.pendingRequests.get(url);
    if (!requestHandler) {
      if (this.debug) {
        console.log(`Not in cache (or out-dated) - Create request handler for: ${url}`);
      }
      requestHandler = new RequestHandler(url, this.upsert, this.debug);
      this.pendingRequests.set(url, requestHandler);
      const result = await requestHandler.getWaiter();
      if (this.debug) {
        console.log(`Delete request handler found for: ${url}`);
      }
      this.pendingRequests.delete(url);
      return result;
    }
    if (this.debug) {
      console.log(`Not in cache (or out-dated) - Existing request handler found for: ${url}`);
    }
    return await requestHandler.getWaiter();
  };

  // getId returns an identifier of the state of the permission cache.
  getId = () => {
    let maxId = 0;
    this.cache.forEach((v) => {
      maxId = Math.max(maxId, v.getId());
    });
    return maxId;
  };

  clear = () => {
    this.cache.clear();
  };

  checkPermission = (url: string, permission: Permission): CheckCacheResult => {
    const permissions = this.cache.get(url);
    if (permissions) {
      const found = permissions.getPermissions().includes(permission);
      return new CheckCacheResult(true, found);
    }

    return new CheckCacheResult(false, false);
  };

  updatePermissions = async (urls: string[]): Promise<AddCacheResult> => {
    if (this.debug) {
      console.log(`Active permission urls: ${urls.length}`);
    }
    let updated = false;
    if (!_.isEmpty(urls)) {
      const allUpdates = await Promise.all(urls.map((url) => this.updatePermission(url)));
      updated = _.some(allUpdates);
    }
    return new AddCacheResult(updated, this.getId());
  };
}

class RequestHandler {
  private debug: boolean;
  private running = true;
  private result = false;
  private waiters: Array<(result: boolean) => void> = new Array<(result: boolean) => void>();

  constructor(url: string, upsert: (url: string, permissions: string[]) => void, debug: boolean) {
    this.debug = debug;
    this.getEffectivePerms(url, upsert);
  }

  getWaiter = async (): Promise<boolean> => {
    if (!this.running) {
      return this.result;
    }

    return new Promise<boolean>((resolve) => {
      this.waiters.push(resolve);
    });
  };

  getEffectivePerms = async (url: string, upsert: (url: string, permissions: string[]) => void) => {
    try {
      const urlOptions = { url: url } as ApiURLOptions;
      const permissionList = await apiclients.idashboardClient.GetEffectivePermissions(urlOptions);
      upsert(url, permissionList.items || []);
      if (this.debug) {
        console.log(`Inserted or updated cache: ${url}`);
      }
      this.result = true;
    } catch (e) {
      if (this.debug) {
        console.log(`Error while updating permission: ${url} - ${e}`);
      }
      const obj = e as Object;
      if (obj && Object.keys(obj).includes("status")) {
        const val = e["status"] as number;
        if (val && val == 403) {
          //NotAllowed, add empty list, will retry after 2 minutes
          upsert(url, []);
          this.result = true;
        }
      }
    }
    this.running = false;
    if (this.debug) {
      console.log(`Releasing ${this.waiters.length} waiters for ${url}`);
    }
    this.waiters.forEach((waiter) => {
      waiter(this.result);
    });
  };
}

export class CacheItem {
  // Cache is 2 minutes valid
  static expirationTimeMs = 2000 * 60;

  private createDate: number;
  private permissions: string[];
  private id: number;

  constructor(id: number, permissions: string[], createDate?: number) {
    this.createDate = createDate || Date.now();
    this.permissions = permissions;
    this.id = id;
  }

  isOutdated = () => {
    const now = Date.now();
    const diffMs = now - this.createDate;
    return diffMs > CacheItem.expirationTimeMs;
  };

  getPermissions = () => {
    return this.permissions;
  };

  getId = () => {
    return this.id;
  };

  permissionsEqual = (otherPermissions: string[]) => {
    return _.isEqual(this.permissions, otherPermissions);
  };

  resetDate = () => {
    this.createDate = Date.now();
  };
}

export class CheckCacheResult {
  private inCache: boolean;
  private result: boolean;

  constructor(inCache: boolean, result: boolean) {
    this.inCache = inCache;
    this.result = result;
  }

  getInCache() {
    return this.inCache;
  }

  getResult() {
    return this.result;
  }
}

export class AddCacheResult {
  private updated: boolean;
  private id: number;

  constructor(updated: boolean, id: number) {
    this.updated = updated;
    this.id = id;
  }

  getUpdated = () => {
    return this.updated;
  };

  getId = () => {
    return this.id;
  };
}
