//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import React, { Component } from "react";
import { Loader, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions } from "../../api/common/v1/common";
import {
  Project as ApiProject,
  Organization as ApiOrganization,
  Quota as ApiQuota,
  ListQuotasRequest as ApiListQuotasRequest,
  QuotaDescriptionList as ApiQuotaDescriptionList,
  QuotaDescription as ApiQuotaDescription,
  QuotaList as ApiQuotaList,
} from "../../api/resourcemanager/v1/resourcemanager";
import { RouteComponentProps } from "react-router-dom";
import { LoaderBoxForTable as LoaderBox, ContentSegment, SecondaryMenu, ListActionEdit, Loading, ErrorMessage, TextLink, NumberInput } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import _ from "lodash";
import { Button, Modal } from "semantic-ui-react";
import { SetQuotaRequest as ApiSetQuotaRequest } from "../../api/lib";

// Interface describing a project
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Limit</Table.HeaderCell>
      <Table.HeaderCell>Decription</Table.HeaderCell>
      <Table.HeaderCell>Kind</Table.HeaderCell>
      <Table.HeaderCell>Relevant At</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRelevantAtCellView {
  item: ApiQuotaDescription;
}

const RelevantAtCellView = ({ ...args }: IRelevantAtCellView) => (
  <Table.Cell>
    {args.item.for_organizations && args.item.for_projects
      ? "organization & project"
      : args.item.for_organizations
      ? "organization"
      : args.item.for_projects
      ? "project"
      : "none"}
  </Table.Cell>
);

// Interface describing a quota
interface IRowView {
  active: boolean;
  item: ApiQuotaDescription;
  value?: ApiQuota;
  hasEditPermission: boolean;
  onClickEdit: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <TextLink label={args.value ? `${args.value.limit || "unlimited"}` : "?"} onClick={args.onClickEdit} disabled={!args.hasEditPermission} />
    </Table.Cell>
    <Table.Cell>{args.item.description}</Table.Cell>
    <Table.Cell>{args.item.kind}</Table.Cell>
    <RelevantAtCellView item={args.item} />
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">{args.hasEditPermission && <ListActionEdit disabled={!args.active} onClick={args.onClickEdit} />}</div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the quota
interface IListView {
  active: boolean;
  items: ApiQuotaDescription[];
  quotas: ApiQuota[];
  loading: boolean;
  hasEditPermission: boolean;
  onClickEdit: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView
          {...args}
          key={item.kind}
          item={item}
          value={args.quotas.find((q) => q.kind == item.kind)}
          onClickEdit={() => args.onClickEdit(item.kind || "")}
        />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No quota specified inside this system</div>;

// Interface describing the quota list view arguments
export interface IQuotaListViewArgs extends IQuotaListProps, IQuotaListState {
  active: boolean;
  loading: boolean;
  hasEditPermission: boolean;
  onClickEdit: (id: string) => void;
}

export const QuotaListView = ({ ...args }: IQuotaListViewArgs) => {
  if (!args.quotaDescriptions || !args.quotas) {
    return <Loading />;
  }
  if (!args.quotaDescriptions.items || args.quotaDescriptions.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.quotaDescriptions.items} quotas={args.quotas.items || ({} as ApiQuota[])} loading={args.loading} />;
};

// Interface describing the quota list view arguments
export interface IQuotaEditViewArgs extends IQuotaListProps, IQuotaListState {
  onValueChange: (limit: number) => void;
  onClickCancel: () => void;
  onClickSave: () => void;
}
export const QuotaEditView = ({ ...args }: IQuotaEditViewArgs) => (
  <Modal size="mini" open={!!args.editQuota} onClose={args.onClickCancel}>
    <Modal.Header>Edit limit for: {`${args.editQuota && args.editQuota.description}`}</Modal.Header>
    <Modal.Content>
      <p>Enter new value (0 = unlimited): </p>
      {args.editLimit != undefined && <NumberInput value={args.editLimit} min={0} onChange={args.onValueChange} />}
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={args.onClickCancel}>
        Cancel
      </Button>
      <Button positive icon="checkmark" labelPosition="right" content="Save" onClick={args.onClickSave} />
    </Modal.Actions>
  </Modal>
);

// Interface decribing the properties of the quota list component
interface IQuotaListProps extends IWithRefreshProps, RouteComponentProps {
  organization?: ApiOrganization;
  project?: ApiProject;
  showHeader: boolean;
}

// Interface decribing the state of the quota list component
interface IQuotaListState {
  errorMessage?: string;
  processing: boolean;
  quotaDescriptions?: ApiQuotaDescriptionList;
  quotas?: ApiQuotaList;
  //edit related
  editQuota?: ApiQuota;
  editLimit?: number;
}

// The component to show the quota inside an organization/project as a list.
class QuotaList extends Component<IQuotaListProps, IQuotaListState> {
  state: IQuotaListState = {
    errorMessage: undefined,
    processing: false,
    quotaDescriptions: undefined,
    quotas: undefined,
    editQuota: undefined,
    editLimit: undefined,
  };

  refreshQuotas = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadQuotas);
  };

  reloadQuotas = async () => {
    const listOptions: ApiListOptions = {};
    const quotaDescriptions = await apiClients.authenticationOnly.resourceManagerClient.ListQuotaDescriptions(listOptions);

    let quotas: ApiQuotaList | undefined = undefined;
    if (this.props.organization) {
      if (quotaDescriptions.items) {
        quotaDescriptions.items = _.orderBy(
          quotaDescriptions.items.filter((qd) => qd.for_organizations || qd.for_projects),
          "kind"
        );
      }

      const quotasRequest: ApiListQuotasRequest = { options: { context_id: this.props.organization.id } };
      quotas = await apiClients.idashboardClient.ListOrganizationQuotas(quotasRequest);
    }
    if (this.props.project) {
      if (quotaDescriptions.items) {
        quotaDescriptions.items = _.orderBy(
          quotaDescriptions.items.filter((qd) => qd.for_projects),
          "kind"
        );
      }

      const quotasRequest: ApiListQuotasRequest = { options: { context_id: this.props.project.id } };
      quotas = await apiClients.idashboardClient.ListProjectQuotas(quotasRequest);
    }
    this.setState({ quotaDescriptions: quotaDescriptions, quotas: quotas });
  };

  onClickEdit = (id: string) => {
    let editQuota = this.state.quotas && this.state.quotas.items && this.state.quotas.items.find((q) => q.kind == id);
    this.setState({ editQuota: editQuota, editLimit: (editQuota && editQuota.limit) || 0 });
  };

  onClickEditCancel = () => {
    this.setState({ editQuota: undefined, editLimit: undefined });
  };

  onClickEditSave = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const quotaRequest: ApiSetQuotaRequest = { kind: this.state.editQuota && this.state.editQuota.kind, limit: this.state.editLimit };
      if (this.props.organization) {
        quotaRequest.id = this.props.organization.id;
        await apiClients.idashboardClient.SetOrganizationQuota(quotaRequest);
      }
      if (this.props.project) {
        quotaRequest.id = this.props.project.id;
        await apiClients.idashboardClient.SetProjectQuota(quotaRequest);
      }
      this.refreshQuotas();
    } catch (e) {
      this.setState({ errorMessage: `Setting limit failed: ${e}` });
    } finally {
      this.setState({ processing: false, editQuota: undefined, editLimit: undefined });
    }
  };

  onEditValueChange = (limit: number) => {
    this.setState({ editLimit: limit });
  };

  componentDidMount() {
    if (this.props.organization) {
      this.props.subscribeUrl && this.props.subscribeUrl(this.reloadQuotas, `${this.props.organization.url}`);
    }
    if (this.props.project) {
      this.props.subscribeUrl && this.props.subscribeUrl(this.reloadQuotas, `${this.props.project.url}`);
    }
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        {this.props.showHeader && (
          <SecondaryMenu>
            <Menu.Item header>Quotas</Menu.Item>
          </SecondaryMenu>
        )}
        <QuotaListView {...this.props} {...this.state} hasEditPermission={true} active={!this.state.processing} onClickEdit={this.onClickEdit} />
        <QuotaEditView
          {...this.props}
          {...this.state}
          onClickCancel={this.onClickEditCancel}
          onClickSave={this.onClickEditSave}
          onValueChange={this.onEditValueChange}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(QuotaList);
