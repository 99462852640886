//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import _ from "lodash";
import React from "react";
import { Header, Icon, Popup } from "semantic-ui-react";
import { Deployment as ApiDeployment } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import PrepaidDeploymentLink from "../prepaid/PrepaidDeploymentLink";

interface IPrepaidViewArgs extends IWithRefreshProps {
  organizationID: string;
  deployment: ApiDeployment;
}

export const PrepaidView = ({ ...args }: IPrepaidViewArgs) => {
  const isPrepaid = !_.isEmpty(args.deployment.prepaid_deployment_id);
  const hasUpdate = !!args.deployment.is_prepaid_deployment_update_available;
  return (
    <div>
      <Header sub>Prepaid</Header>
      <Field>
        <FL>Is prepaid?</FL>
        <FC>
          <div>{isPrepaid ? "Yes" : "No"}</div>
          {isPrepaid && <PrepaidDeploymentLink {...args} prepaidDeploymentID={args.deployment.prepaid_deployment_id} />}
        </FC>
      </Field>
      {isPrepaid && (
        <Field>
          <FL>Is up to date?</FL>
          <FC>
            <div>
              {hasUpdate && <Popup trigger={<Icon name="warning sign" color="orange" />} content={<p>Deployment needs to be updated!</p>} />}{" "}
              {!hasUpdate ? "Yes" : "No"}
            </div>
          </FC>
        </Field>
      )}
    </div>
  );
};
