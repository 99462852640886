//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Icon, Statistic } from "semantic-ui-react";
import { AuditLog as ApiAuditLog } from "../../api/lib";
import { StatisticsContentGroup, StyledStatsSegment } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface AuditLogSummaryViewArgs {
  auditLog?: ApiAuditLog;
}

export const AuditLogSummaryTableView = ({ ...args }: AuditLogSummaryViewArgs) => {
  const auditLog = args.auditLog || {};
  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths="5">
          <Statistic size="tiny">
            <Statistic.Label>Name</Statistic.Label>
            <Statistic.Value>{auditLog.name ? auditLog.name : "None"}</Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>Description</Statistic.Label>
            <Statistic.Value>{auditLog.description ? auditLog.description : "None"}</Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="hashtag" className="secondary-text" /> destinations
            </Statistic.Label>
            <Statistic.Value>{(auditLog.destinations || []).length}</Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Statistic.Label>Created at</Statistic.Label>
            </Statistic.Label>
            <Statistic.Value>
              {auditLog.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={auditLog.created_at} label="Created at" /> : "-"}
            </Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Statistic.Label>Deleted at</Statistic.Label>
            </Statistic.Label>
            <Statistic.Value>
              {auditLog.deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={auditLog.deleted_at} label="Deleted at" /> : "-"}
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};
