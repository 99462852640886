//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, SetOrganizationKubernetesAccessRequest as ApiSetOrganizationKubernetesAccessRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IKubernetesAccessAllowedFieldViewArgs {
  canSetKubernetesAccess: boolean;
  onSetOrganizationKubernetesAccess: (enabled: boolean) => void;
  kubernetesAccessAllowed?: boolean;
}

const KubernetesAccessAllowedFieldView = ({ ...args }: IKubernetesAccessAllowedFieldViewArgs) => {
  const kubernetesAccessAllowed = !!args.kubernetesAccessAllowed;
  return (
    <Field>
      <FL>Allow Kubernetes Access</FL>
      <FC>
        {kubernetesAccessAllowed ? "Allowed" : "Not allowed"}
        {args.canSetKubernetesAccess && (
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!kubernetesAccessAllowed && <Dropdown.Item text="Allow" onClick={() => args.onSetOrganizationKubernetesAccess(true)} />}
              {kubernetesAccessAllowed && <Dropdown.Item text="Do not allow" onClick={() => args.onSetOrganizationKubernetesAccess(false)} />}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </FC>
    </Field>
  );
};

interface IKubernetesAccessAllowedFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface IKubernetesAccessAllowedFieldState extends IOrganizationDetailsFieldState {
  kubernetesAccessAllowed?: boolean;
  commentPrompt: ICommentPromptStateForIntegrations;
}

class KubernetesAccessAllowedField extends OrganizationDetailsField<IKubernetesAccessAllowedFieldProps, IKubernetesAccessAllowedFieldState> {
  state: IKubernetesAccessAllowedFieldState = {
    kubernetesAccessAllowed: false,
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      const organizationInfo = await apiClients.idashboardClient.GetOrganizationInfo(idOptions);
      this.setState({
        kubernetesAccessAllowed: organizationInfo.kubernetes_access_allowed,
      });
    }
  };

  onSetOrganizationKubernetesAccess = async (enabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set Kubernetes access to ${enabled ? "allowed" : "not allowed"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization members allowed to request kubernetes access, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationKubernetesAccessRequest = {
            organization_id: this.props.organization.id,
            kubernetes_access_allowed: enabled,
          };
          await apiClients.idashboardClient.SetOrganizationKubernetesAccess(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const can_set_kubernetes_access = this.hasPermission("internal-dashboard.organization.set-kubernetes-access");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}

        <KubernetesAccessAllowedFieldView {...this} {...this.props} {...this.state} canSetKubernetesAccess={can_set_kubernetes_access} />
      </div>
    );
  }
}

export default withRefresh()(KubernetesAccessAllowedField);
