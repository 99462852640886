//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Loader, Menu, Segment } from "semantic-ui-react";
import { ListNotebooksRequest as ApiListNotebooksRequest } from "../../../api/notebook/v1/notebook";
import { Section, ErrorMessage, PagingButtons, SecondaryMenu, Loading } from "../../../ui/lib";
import { ListOptions as ApiListOptions } from "../../../api/lib";
import NotebookTableView from "./NotebookList";
import { INotebookViewArgs } from "./types";
import apiClients from "../../../api/apiclients";
import { NotebookInfo as ApiNotebookInfo, SetNotebookMaintenanceModeRequest } from "../../../api/notebook/v1/inotebook";

const NotebookView = ({
  canListNotebook,
  canUpdateNotebookMaintenanceMode,
  canUpdateNotebookImage,
  deploymentID = "",
  noHeader,
  hideDeploymentID,
  ...rest
}: INotebookViewArgs) => {
  const [notebooks, updateNotebooks] = useState<ApiNotebookInfo[]>([]);
  const [pageOptions, updatePageOptions] = useState<Required<Omit<ApiListOptions, "context_id">>>({
    page: 0,
    page_size: 10,
  });
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const getNotebooks = async () => {
    setLoading(true);
    try {
      const req: ApiListNotebooksRequest = {
        deployment_id: deploymentID,
        options: pageOptions,
      };
      const response = await apiClients.idashboardClient.ListNotebookInfos(req);
      const { items = [] } = response;
      updateNotebooks(items);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    const { subscribeUrl } = rest;
    setInitialLoading(true);
    subscribeUrl && subscribeUrl(getNotebooks);
  }, []);

  useEffect(() => {
    getNotebooks();
  }, [pageOptions]);

  const onNextPage = () => {
    updatePageOptions({
      ...pageOptions,
      page: pageOptions.page + 1,
    });
  };

  const onPreviousPage = () => {
    updatePageOptions({
      ...pageOptions,
      page: pageOptions.page - 1,
    });
  };

  const onSaveMaintenanceMode = async (notebookID: string | undefined, maintenanceMode: boolean) => {
    setLoading(true);
    try {
      const req: SetNotebookMaintenanceModeRequest = {
        notebook_id: notebookID,
        maintenance_mode: maintenanceMode,
      };
      await apiClients.idashboardClient.SetNotebookMaintenanceMode(req);
      setError(undefined);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  if (initialLoading) return <Loading />;

  return (
    <Section>
      <ErrorMessage active={!!error} onDismiss={() => setError(undefined)} message={error} />
      {!noHeader && (
        <SecondaryMenu>
          <Menu.Item header>Notebooks {loading ? <Loader size="mini" inline active /> : ""} </Menu.Item>
        </SecondaryMenu>
      )}

      {!!notebooks.length ? (
        <>
          <Menu borderless pointing stackable>
            <Menu.Item header></Menu.Item>
            <Menu.Item position="right" fitted="vertically">
              <PagingButtons
                page={pageOptions.page}
                count={notebooks.length}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                size="mini"
                pageSize={pageOptions.page_size}
              />
            </Menu.Item>
          </Menu>
          <NotebookTableView
            onSaveNotebookMaintenanceMode={onSaveMaintenanceMode}
            canUpdateNotebookImage={canUpdateNotebookImage}
            canUpdateNotebookMaintenanceMode={canUpdateNotebookMaintenanceMode}
            notebookInfo={notebooks}
            hideDeploymentID={hideDeploymentID}
          />
        </>
      ) : (
        <Segment>No notebooks Found.</Segment>
      )}
    </Section>
  );
};

export default NotebookView;
