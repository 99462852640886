//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Button, SemanticSIZES } from "semantic-ui-react";

interface IPagingButtons {
  // Page number; 0..
  page: number;
  // Number of items per page
  pageSize: number;
  // Size of button
  size?: SemanticSIZES;
  // Number of items on current page
  count: number;
  // Next page handler
  onNextPage?: () => void;
  // Previous page handler
  onPreviousPage?: () => void;
}

export const PagingButtons = ({ ...args }: IPagingButtons) => {
  const hasNext = args.onNextPage && args.count == args.pageSize;
  const hasPrev = args.onPreviousPage && args.page > 0;
  if (!hasNext && !hasPrev) {
    return <span></span>;
  }
  return (
    <Button.Group size={args.size || "mini"} floated="right">
      <Button icon="angle left" disabled={!hasPrev} onClick={args.onPreviousPage} size={args.size} />
      <Button.Or text={`${args.page + 1}`} />
      <Button icon="angle right" disabled={!hasNext} onClick={args.onNextPage} size={args.size} />
    </Button.Group>
  );
};
