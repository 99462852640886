//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import DashboardMenu, { IDashboardMenuArgs } from "./DashboardMenu";

export interface IDashboardTemplateArgs extends IDashboardMenuArgs {
  children: any;
  canViewDCAlerts: boolean;
  canViewCPAlerts: boolean;
}

export const DashboardTemplate = ({ children, ...args }: IDashboardTemplateArgs) => (
  <div>
    <DashboardMenu {...args} />
    {children}
  </div>
);
