//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { forwardRef } from "react";
import { Dropdown } from "semantic-ui-react";
import { FlexBox } from "../../ui/_flex";
import { TabMenuItem } from "./Tab";
import { StyledOverflowTabMenuItem } from "./TabStyles";
import { ITabMenuItemsViewArgs } from "./TabTypes";

const TabMenuItemGroup = forwardRef(
  ({ tabConfig, tabClassForObserving, activeIndex, onTabClick, testId = "" }: ITabMenuItemsViewArgs, ref: React.Ref<HTMLDivElement> | null) => {
    const hiddenTabMenuItems = tabConfig.filter((tab) => tab.menuItem.isHidden);

    let overflowIndex: Array<number> = [];

    return (
      <FlexBox className={`tab-menu-group ${tabClassForObserving} `} position="relative" data-testid={testId || "tabMenuGroup"}>
        <FlexBox overflow="hidden" ref={ref} width="99.5%" data-testid="visibleTabs">
          {tabConfig.map((tab, index) => {
            const { children, id, isHidden, ...rest } = tab.menuItem;
            if (isHidden) {
              overflowIndex.push(index);
            }
            return (
              <TabMenuItem key={`${id}-${index}`} isActive={index === activeIndex} onTabClick={() => onTabClick(index)} id={id} {...rest}>
                {children}
              </TabMenuItem>
            );
          })}
        </FlexBox>

        {!!hiddenTabMenuItems.length && (
          <StyledOverflowTabMenuItem icon="ellipsis vertical" direction="left" data-testid="tabOverflowMenu">
            <Dropdown.Menu>
              {hiddenTabMenuItems.map((tab, index) => {
                const { children, id, ...rest } = tab.menuItem;
                const oldIndex = overflowIndex[index];
                return (
                  <TabMenuItem key={`${id}-${index}`} isActive={oldIndex === activeIndex} onTabClick={() => onTabClick(oldIndex)} id={id} {...rest}>
                    {children}
                  </TabMenuItem>
                );
              })}
            </Dropdown.Menu>
          </StyledOverflowTabMenuItem>
        )}
      </FlexBox>
    );
  }
);

export default TabMenuItemGroup;
