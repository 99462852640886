//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import styled from "@emotion/styled";
import Downloader from "js-file-downloader";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import Auth from "../../auth/Auth";
import { ContentActionButton } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

const DataContainer = styled("div")`
  min-height: 20vh;
`;

interface IAgencyStateModalViewArgs {
  open: boolean;
  loading: boolean;
  onClose: () => void;
}

const AgencyStateModalView = ({ ...args }: IAgencyStateModalViewArgs) => {
  return (
    <Modal open={args.open} onClose={args.onClose}>
      <Modal.Content scrolling>
        <DataContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Fetching agency state, please wait
              </Loader>
            </Dimmer>
          )}
        </DataContainer>
      </Modal.Content>
    </Modal>
  );
};

interface IAgencyStateModalProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  deploymentId: string;
}

interface IAgencyStateModalState {
  open: boolean;
  loading: boolean;
  errorMessage?: string;
}

// Component to view agency state
class AgencyStateModal extends Component<IAgencyStateModalProps, IAgencyStateModalState> {
  state: IAgencyStateModalState = {
    open: false,
    loading: false,
    errorMessage: undefined,
  };

  reloadAgencyState = async () => {
    this.setState({ loading: true, errorMessage: undefined });
    try {
      const fileURL = `/downloads/v1/deployments/${this.props.deploymentId}/agency-state`;
      const downloader = new Downloader({
        url: fileURL,
        filename: `agency-state-${this.props.deploymentId}.json`,
        headers: [{ name: "Authorization", value: `bearer ${this.props.auth.getAccessToken()} ` }],
      });
      await downloader;
      this.setState({ loading: false, open: false });
    } catch (e) {
      this.setState({ loading: false, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true }, this.reloadAgencyState);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Agency State" icon="file" />
        <AgencyStateModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(AgencyStateModal);
