//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import { Form, Input, InputOnChangeData, Modal } from "semantic-ui-react";
import { EditIcon, ErrorMessage, FlexBox, FormActionButtonCancel, FormActionButtonSave, TextLink } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import { SetCustomNotebookImageRequest } from "../../api/notebook/v1/inotebook";

export const CustomNotebookImage = ({ notebookID, onCustomImageSave, customImageName = "" }: any) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(customImageName);
  const [open, toggleVisibility] = useState(false);

  const onImageSave = async () => {
    setLoading(true);
    try {
      const req: SetCustomNotebookImageRequest = {
        notebook_id: notebookID,
        custom_notebook_image: image,
      };
      await apiClients.idashboardClient.SetCustomNotebookImage(req);
      onCustomImageSave && onCustomImageSave({ notebookID, image });
      toggleVisibility(false);
      setError(undefined);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      open={open}
      trigger={
        <span>
          {customImageName ? <TextLink label={customImageName} /> : "Set custom image"}
          <EditIcon
            onClick={() => {
              toggleVisibility(true);
            }}
          />
        </span>
      }
    >
      <Modal.Header>
        <FlexBox justify="space-between">Set custom image</FlexBox>
      </Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} message={error} />
        <Form>
          <Form.Field>
            <label>Notebook image</label>
            <Input
              value={image}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                const { value } = data;
                setImage(value);
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel
          onClick={() => {
            toggleVisibility(false);
            setImage(customImageName || "");
            setError(undefined);
          }}
          disabled={loading}
        />
        <FormActionButtonSave primary onClick={onImageSave} disabled={loading} />
      </Modal.Actions>
    </Modal>
  );
};
