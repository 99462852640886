//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import apiClients from "../../api/apiclients";
import { ListUpdateJobsRequest, UpdateJob, UpdateJobStateChangeRequest } from "../../api/deployment-updater/v1/ideploymentupdater";
import { Button, Loader, Menu, Message } from "semantic-ui-react";
import { ContentSegment, SecondaryMenu, ErrorMessage, ConfirmInfo, Confirm } from "../../ui/lib";
import DeploymentUpdaterFilterView from "./DeploymentUpdaterFilterView";
import { IDOptions as ApiIDOptions, isNotFound as ApiisNotFound } from "../../api/lib";
import moment from "moment";
import { withRefresh } from "../../util/WithRefresh";
import UpdateJobListView from "./UpdateJobsListView";
import { DeploymentUpdaterViewArgs } from "./types";
import CreateDeploymentUpdaterView from "./CreateDeploymentUpdaterJobView";

const DeploymentUpdaterView = (props: DeploymentUpdaterViewArgs) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [jobList, updateJobList] = useState<UpdateJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [runningJob, updateRunningJob] = useState<string>("");
  const [openCreateModal, toggleCreateModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState<ListUpdateJobsRequest>({
    created_before: moment().add(1, "days").toDate(),
    created_after: moment().subtract(1, "year").toDate(),
  });
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);

  const [page, setPage] = useState(0);
  const PAGE_SIZE = 10;

  useEffect(() => {
    const { subscribeUrl } = props;
    subscribeUrl && subscribeUrl(() => fetchAllUpdateJobs({}));
  }, []);

  const handleDelete = (jobId: string) => {
    const confirmInfo: ConfirmInfo = {
      header: `Delete ${jobId}?`,
      warning: "The job will be permanently deleted and this action cannot be undone",
      onConfirm: async () => {
        deleteJob(jobId);
        setConfirmInfo(undefined);
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    };
    setConfirmInfo(confirmInfo);
  };

  const handleStop = (jobId: string) => {
    const confirmInfo: ConfirmInfo = {
      header: `Stop ${jobId}?`,
      warning: "This will stop all pending updates for the deployments in this job. This action is not reversible.",
      showCommentBox: true,
      onConfirm: async (reason?: string) => {
        setConfirmInfo(undefined);
        stopJob(jobId, reason);
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    };
    setConfirmInfo(confirmInfo);
  };

  const handlePause = (jobId: string) => {
    const confirmInfo: ConfirmInfo = {
      header: `Pause ${jobId}?`,
      warning: "This will pause the current job!",
      showCommentBox: true,
      onConfirm: async (reason?: string) => {
        setConfirmInfo(undefined);
        pauseJob(jobId, reason);
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    };
    setConfirmInfo(confirmInfo);
  };

  const fetchAllUpdateJobs = async (req: ListUpdateJobsRequest) => {
    setError(undefined);
    setLoading(true);
    try {
      const finalReq: ListUpdateJobsRequest = {
        ...req,
        options: {
          page,
          page_size: PAGE_SIZE,
        },
      };
      const response = await apiClients.idashboardClient.ListDeploymentUpdateJobs(finalReq);
      const { items = [] } = response;
      updateJobList(items);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const startJob = async (jobID: string) => {
    const req: ApiIDOptions = {
      id: jobID,
    };
    try {
      await apiClients.idashboardClient.StartDeploymentUpdateJob(req);
      updateRunningJob(jobID);
      fetchAllUpdateJobs({});
    } catch (err) {
      setError(err);
    }
  };

  const resumeJob = async (jobID: string) => {
    const req: ApiIDOptions = {
      id: jobID,
    };
    try {
      await apiClients.idashboardClient.ResumeDeploymentUpdateJob(req);
      updateRunningJob(jobID);
      fetchAllUpdateJobs({});
    } catch (err) {
      setError(err);
    }
  };

  const stopJob = async (jobID: string, reason?: string) => {
    const req: UpdateJobStateChangeRequest = {
      id: jobID,
      reason,
    };
    try {
      await apiClients.idashboardClient.StopDeploymentUpdateJob(req);
      fetchAllUpdateJobs({});
    } catch (err) {
      setError(err);
    }
  };

  const pauseJob = async (jobID: string, reason?: string) => {
    const req: UpdateJobStateChangeRequest = {
      id: jobID,
      reason,
    };
    try {
      await apiClients.idashboardClient.PauseDeploymentUpdateJob(req);
      fetchAllUpdateJobs({});
    } catch (err) {
      setError(err);
    } finally {
    }
  };

  const deleteJob = async (jobID: string) => {
    const req: ApiIDOptions = {
      id: jobID,
    };
    try {
      await apiClients.idashboardClient.DeleteDeploymentUpdateJob(req);
      fetchAllUpdateJobs({});
    } catch (err) {
      setError(err);
    } finally {
    }
  };

  useEffect(() => {
    fetchAllUpdateJobs(filterOptions);
  }, [page]);

  const onNextPage = () => {
    setPage(page + 1);
  };

  const onPreviousPage = () => {
    setPage(page - 1);
  };

  const onRunningStateChange = (active: boolean) => {
    const filters = {
      ...filterOptions,
      running_only: active,
    };
    applyFilterChange(filters);
  };

  const onPausedStateChange = (active: boolean) => {
    const filters = {
      ...filterOptions,
      paused_only: active,
    };
    applyFilterChange(filters);
  };

  const onStoppedStateChange = (active: boolean) => {
    const filters = {
      ...filterOptions,
      stopped_only: active,
    };
    applyFilterChange(filters);
  };

  const onFinishedOnlyStateChange = (active: boolean) => {
    const filters = {
      ...filterOptions,
      finished_only: active,
    };
    applyFilterChange(filters);
  };

  const onPendingStateChange = (active: boolean) => {
    const filters = {
      ...filterOptions,
      pending_only: active,
    };
    applyFilterChange(filters);
  };

  const onCreatedBeforeChange = (val: Date) => {
    const filters = {
      ...filterOptions,
      created_before: val,
    };
    applyFilterChange(filters);
  };

  const onCreatedAfterChange = (val: Date) => {
    const filters = {
      ...filterOptions,
      created_after: val,
    };
    applyFilterChange(filters);
  };

  const applyFilterChange = (filters: ListUpdateJobsRequest) => {
    setPage(0);
    setFilterOptions(filters);
    fetchAllUpdateJobs(filters);
  };

  const onJobIDChange = async (jobID: string) => {
    if (!jobID) {
      fetchAllUpdateJobs({});
      return;
    }

    try {
      setLoading(true);
      setError(undefined);

      const req: ApiIDOptions = {
        id: jobID,
      };

      const response = await apiClients.idashboardClient.GetDeploymentUpdateJob(req);
      updateJobList([response]);
    } catch (err) {
      if (ApiisNotFound(err)) {
        updateJobList([]);
        return;
      }
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentSegment>
      <SecondaryMenu>
        <Menu.Item header>
          <span>
            Deployment update jobs {"  "} <Button basic size="tiny" disabled={openCreateModal} onClick={() => toggleCreateModal(true)} icon="plus" />
          </span>
          <Loader size="mini" active={loading} inline />
        </Menu.Item>
      </SecondaryMenu>
      <CreateDeploymentUpdaterView
        onClose={() => {
          fetchAllUpdateJobs({});
          toggleCreateModal(false);
        }}
        open={openCreateModal}
        {...props}
      />
      <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} />
      <DeploymentUpdaterFilterView
        onJobIDChange={onJobIDChange}
        onRunningStateChange={onRunningStateChange}
        onPausedStateChange={onPausedStateChange}
        onStoppedStateChange={onStoppedStateChange}
        onFinishedOnlyStateChange={onFinishedOnlyStateChange}
        onPendingStateChange={onPendingStateChange}
        onCreatedBeforeChange={onCreatedBeforeChange}
        onCreatedAfterChange={onCreatedAfterChange}
        filters={filterOptions}
        page={page}
        pageSize={PAGE_SIZE}
        count={jobList.length}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
      {!jobList.length && !loading && <Message>No jobs found</Message>}
      {!!jobList.length && (
        <>
          <Confirm confirmInfo={confirmInfo} />
          <UpdateJobListView
            jobList={jobList}
            runningJob={runningJob}
            startJob={startJob}
            pauseJob={handlePause}
            stopJob={handleStop}
            deleteJob={handleDelete}
            resumeJob={resumeJob}
          />
        </>
      )}
    </ContentSegment>
  );
};

export default withRefresh()(DeploymentUpdaterView);
