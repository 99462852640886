//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import _ from "lodash";
import { Cached as cachedApiClients } from "../api/apiclients";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import { ListOptions as ApiListOptions, DeploymentModel as ApiDeploymentModel } from "../api/lib";
import { Loading } from "../ui/lib";

interface IModelInputProps extends IWithRefreshProps {
  id?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowAny?: boolean;
}

interface IModelInputState {
  id: string;
  models?: ApiDeploymentModel[];
}

class ModelInputComp extends Component<IModelInputProps, IModelInputState> {
  state: IModelInputState = {
    id: this.props.id || "",
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  static getDerivedStateFromProps(props: IModelInputProps, state: IModelInputState) {
    const id = props.id || "";
    if (id != state.id) {
      return {
        refreshNeeded: true,
        id: id,
      };
    }
    // No state update necessary
    return null;
  }

  reloadModels = async () => {
    const req: ApiListOptions = {};
    const list = await cachedApiClients.idashboardClient.ListAllDeploymentModels(req);
    this.setState({ models: list.items });
  };

  componentDidMount() {
    this.reloadModels();
  }

  render() {
    if (!this.state.models) {
      return <Loading />;
    }
    const modelOptions = _.orderBy(this.state.models, "name").map((x, i) => {
      return {
        key: `model-${x.id || ""}`,
        text: x.name || "",
        value: x.id || "",
      };
    });
    if (this.props.allowAny) {
      modelOptions.unshift({
        key: "model-all",
        text: "All",
        value: "all",
      });
    }
    return (
      <div>
        <Dropdown fluid selection placeholder={this.props.placeholder} options={modelOptions} onChange={this.onChange} value={this.state.id} />
      </div>
    );
  }
}

export const ModelInput = withRefresh()(ModelInputComp);
