//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import React from "react";

import { Dropdown } from "semantic-ui-react";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../../ui/lib";

import { useDeploymentStore } from "../../../util/storage/DeploymentStore";

import apiclients from "../../../api/apiclients";
import { UpdateDeploymentEnvoyAccessLogRequest } from "../../../api/lib";
import IConfigurationViewItemProps from "./IConigurationViewItemProps";
import { RenderGuard } from "../../../util/RenderGuard";

const EnvoyAccessLogView = ({ ...args }: IConfigurationViewItemProps) => {
  const { deploymentInfo, deployment } = useDeploymentStore();
  const { requireComment, requirePermission } = args;
  const { idashboardClient } = apiclients;

  const envoyAccessLogEnabled = !!deploymentInfo.enable_envoy_access_log;

  const onUpdateEnvoyAccessLog = async (enable: boolean) => {
    let enabled = enable ? "Enable" : "Disable";
    requireComment({
      defaultComment: `${enabled} envoy access log`,
      loadingMessage: "Updating envoy access log",
      onCommentConfirmation: async () => {
        const req: UpdateDeploymentEnvoyAccessLogRequest = {
          deployment_id: deployment.id,
          enable: enable,
        };
        await idashboardClient.UpdateDeploymentEnvoyAccessLog(req);
      },
    });
  };

  return (
    <Field>
      <FL>Envoy access log</FL>
      <FC>
        <span>{envoyAccessLogEnabled ? "Enabled" : "Disabled"}</span>
        <RenderGuard renderIf={requirePermission("internal-dashboard.deployment.update-envoy-access-log")}>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!envoyAccessLogEnabled ? (
                <Dropdown.Item key="set-hm" text="Enable" onClick={() => onUpdateEnvoyAccessLog(true)} />
              ) : (
                <Dropdown.Item key="remove-hm" text="Disable" onClick={() => onUpdateEnvoyAccessLog(false)} />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </RenderGuard>
      </FC>
    </Field>
  );
};

export default EnvoyAccessLogView;
