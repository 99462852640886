//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Message } from "semantic-ui-react";
import { Comment as ApiComment } from "../../api/comment/v1/icomment";
import { ICommentsViewArgs } from "./CommentTypes";
import CommentItem from "./CommentItem";
import { Loading } from "../../ui/_loading";

const CommentsListView = ({ comments, lastestCommentsTriggered }: ICommentsViewArgs) => {
  const { items = [] } = comments;

  if (!lastestCommentsTriggered) {
    return <Loading message="Loading comments..." />;
  }

  if (!items.length) {
    return <Message>No comments found</Message>;
  }
  return (
    <div>
      {items.map((comment: ApiComment) => (
        <CommentItem key={`${comment.created_at}`} comment={comment} />
      ))}
    </div>
  );
};

export default CommentsListView;
