//
// DISCLAIMER
//
// Copyright 2021-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component, useEffect, useState } from "react";
import { Button, Table, Popup, Dropdown, Modal, Form, Icon, Input } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  LoadBalancer as ApiLoadBalancer,
  LoadBalancerList as ApiLoadBalancerList,
  ListLoadBalancersRequest as ApiListLoadBalancersRequest,
  SetLoadBalancerMemoryOverrideRequest as ApiSetLoadBalancerMemoryOverrideRequest,
  SetLoadBalancerReplicasOverrideRequest as ApiSetLoadBalancerReplicasOverrideRequest,
  LoadBalancer_Status as ApiLoadBalancer_Status,
  DataCluster as ApiDataCluster,
  Deployment as ApiDeployment,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Finalizers, Loading, ErrorMessage, Processing } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import _ from "lodash";
import ReactJson from "react-json-view";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import CommentInputField from "../comments/CommentInputField";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { AsyncResult } from "../../util/Types";
import { CommentCreationArgs } from "../comments/CommentTypes";

interface IStatusView {
  ls: ApiLoadBalancer_Status;
}

const StatusView = ({ ...args }: IStatusView) => {
  const ls = args.ls;
  if (!!ls.is_deleted) {
    return (
      <span>
        Deleting... (<DateTimePopupWithUTCAndLocalTime dateTime={ls.deleted_at} label="Deleted at" />)
      </span>
    );
  }
  if (!ls.is_created) {
    return <span>Creating...</span>;
  }
  if (!ls.is_ready) {
    return (
      <span>
        Created (<DateTimePopupWithUTCAndLocalTime dateTime={ls.created_at} label="Created at" />) , not ready: {ls.message}
      </span>
    );
  }
  return (
    <span>
      Ready (<DateTimePopupWithUTCAndLocalTime dateTime={ls.first_ready_at} label="First ready at" />)
    </span>
  );
};

interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Cross-AZ</Table.HeaderCell>
      <Table.HeaderCell>Debug Passthrough</Table.HeaderCell>
      <Table.HeaderCell>Memory override</Table.HeaderCell>
      <Table.HeaderCell>Replicas override</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Finalizers</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface ILoadBalancerMemoryOverriderModalArgs extends CommentCreationArgs {
  memory_override?: string;
  onSetLoadBalancerMemoryOverride: (val: string) => Promise<AsyncResult<void>>;
}

const LoadBalancerMemoryOverrideModal = ({ ...args }: ILoadBalancerMemoryOverriderModalArgs) => {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [memoryOverride, setMemoryOverride] = useState(args.memory_override || "");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  useEffect(() => {
    let newComment = "";
    if (args.memory_override !== memoryOverride) {
      if (!!memoryOverride) {
        newComment = `Changed memory_override to ${memoryOverride}`;
      } else {
        newComment = `Remove memory_override`;
      }
    }
    setComment(newComment);
  }, [memoryOverride]);

  const onSave = async () => {
    setError("");
    setLoading(true);

    const { error } = (!commentCreationFailed && (await args.onSetLoadBalancerMemoryOverride(memoryOverride))) || {};

    setError(error);
    setCommentCreationFailed(false);

    if (!error) {
      const { error: commentError } = await args.createComment(comment);

      if (commentError) {
        setError(commentError);
        setLoading(false);
        setCommentCreationFailed(true);
        return;
      }

      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      trigger={<Icon name="pencil" className="editPencil" />}
      open={open}
      onClose={async () => {
        await setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      size="tiny"
    >
      <Modal.Header>Change loadbalancer memory override</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={commentCreationFailed ? "Comment creation failed. You can retry saving the comment." : error} />
        <Form onSubmit={onSave}>
          <Form.Group>
            <Form.Field disabled={commentCreationFailed}>
              <label>Memory override value in kubernetes quantity format Eg: 256Mi, 1Gi</label>
              <Input type="text" value={memoryOverride} onChange={(e, d) => setMemoryOverride(d.value)} />
            </Form.Field>
          </Form.Group>
          <Form.Field required>
            <label>Please provide the reason for this change</label>
            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>Disk size setting</b>. Otherwise, a default comment will be provided which can be
              changed if required.
            </sub>{" "}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Dismiss" labelPosition="right" icon="cancel" onClick={() => setOpen(false)} />
        {commentCreationFailed ? (
          <Button
            content="Retry saving comments"
            labelPosition="right"
            icon="comment"
            loading={loading}
            disabled={loading || !comment}
            positive
            onClick={onSave}
          />
        ) : (
          <Button content="Update" labelPosition="right" icon="checkmark" loading={loading} disabled={loading || !comment} positive onClick={onSave} />
        )}
      </Modal.Actions>
    </Modal>
  );
};

interface ILoadBalancerReplicasOverriderModalArgs extends CommentCreationArgs {
  replicas_override?: number;
  onSetLoadBalancerReplicasOverride: (val: number) => Promise<AsyncResult<void>>;
}

const LoadBalancerReplicasOverrideModal = ({ ...args }: ILoadBalancerReplicasOverriderModalArgs) => {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [replicasOverride, setReplicasOverride] = useState(args.replicas_override || 0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  useEffect(() => {
    let newComment = "";
    if (args.replicas_override !== replicasOverride) {
      if (!!replicasOverride) {
        newComment = `Changed replicas_override to ${replicasOverride}`;
      } else {
        newComment = `Remove replicas_override`;
      }
    }
    setComment(newComment);
  }, [replicasOverride]);

  const onSave = async () => {
    setError("");
    setLoading(true);

    const { error } = (!commentCreationFailed && (await args.onSetLoadBalancerReplicasOverride(replicasOverride))) || {};

    setError(error);
    setCommentCreationFailed(false);

    if (!error) {
      const { error: commentError } = await args.createComment(comment);

      if (commentError) {
        setError(commentError);
        setLoading(false);
        setCommentCreationFailed(true);
        return;
      }

      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      trigger={<Icon name="pencil" className="editPencil" />}
      open={open}
      onClose={async () => {
        await setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      size="tiny"
    >
      <Modal.Header>Change loadbalancer replicas override</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={commentCreationFailed ? "Comment creation failed. You can retry saving the comment." : error} />
        <Form onSubmit={onSave}>
          <Form.Group>
            <Form.Field disabled={commentCreationFailed}>
              <label>Memory override value in kubernetes quantity format Eg: 256Mi, 1Gi</label>
              <Input type="number" value={replicasOverride} onChange={(e, d) => setReplicasOverride(parseInt(d.value))} />
            </Form.Field>
          </Form.Group>
          <Form.Field required>
            <label>Please provide the reason for this change</label>
            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>replicas override setting</b>. Otherwise, a default comment will be provided which can
              be changed if required.
            </sub>{" "}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Dismiss" labelPosition="right" icon="cancel" onClick={() => setOpen(false)} />
        {commentCreationFailed ? (
          <Button
            content="Retry saving comments"
            labelPosition="right"
            icon="comment"
            loading={loading}
            disabled={loading || !comment}
            positive
            onClick={onSave}
          />
        ) : (
          <Button content="Update" labelPosition="right" icon="checkmark" loading={loading} disabled={loading || !comment} positive onClick={onSave} />
        )}
      </Modal.Actions>
    </Modal>
  );
};

interface IRowView extends CommentCreationArgs {
  active: boolean;
  canUpdateLoadbalancer: boolean;
  item: ApiLoadBalancer;
  onUpdateLoadBalancerCrossAvailability: (req: ApiLoadBalancer, newValue: boolean) => Promise<void>;
  onUpdateLoadBalancerDebugPassthrough: (req: ApiLoadBalancer, newValue: boolean) => Promise<void>;
}

const RowView = ({ ...args }: IRowView) => {
  const item = args.item || {};

  const onSetLoadBalancerMemoryOverride = async (value: string): Promise<AsyncResult<void>> => {
    let error = "";
    const req: ApiSetLoadBalancerMemoryOverrideRequest = {
      id: item.id,
      memory_override: value,
    };
    try {
      await apiClients.idashboardClient.SetLoadBalancerMemoryOverride(req);
    } catch (e) {
      error = e;
    }
    return { error: error };
  };
  const onSetLoadBalancerReplicasOverride = async (value: number): Promise<AsyncResult<void>> => {
    let error = "";
    const req: ApiSetLoadBalancerReplicasOverrideRequest = {
      id: item.id,
      replicas_override: value,
    };
    try {
      await apiClients.idashboardClient.SetLoadBalancerReplicasOverride(req);
    } catch (e) {
      error = e;
    }
    return { error: error };
  };

  return (
    <Table.Row>
      <Table.Cell>
        <Popup trigger={<u>{item.id || ""}</u>} content={<ReactJson src={item} collapsed={1} />} on="click" pinned />
      </Table.Cell>
      <Table.Cell>{item.type}</Table.Cell>
      <Table.Cell>
        {item.type === "internal-dedicated-1" ? (item.cross_availability_zone ? "yes" : "no") : "n/a"}
        {item.type === "internal-dedicated-1" && args.canUpdateLoadbalancer && (
          <Dropdown icon="pencil" className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {item.cross_availability_zone && (
                <Dropdown.Item
                  key="remove-caz"
                  text="Disable the cross availability zone traffic"
                  onClick={() => args.onUpdateLoadBalancerCrossAvailability(item, false)}
                />
              )}
              {!item.cross_availability_zone && (
                <Dropdown.Item
                  key="set-caz"
                  text="Enable the cross availability zone traffic (this can lead to additional costs)"
                  onClick={() => args.onUpdateLoadBalancerCrossAvailability(item, true)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.Cell>
      <Table.Cell>
        {!item.is_shared ? (item.debug_passthrough ? "yes" : "no") : "n/a"}
        {!item.is_shared && args.canUpdateLoadbalancer && (
          <Dropdown icon="pencil" className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {item.debug_passthrough && (
                <Dropdown.Item
                  key="remove-caz"
                  text="Disable the debug passthrough port"
                  onClick={() => args.onUpdateLoadBalancerDebugPassthrough(item, false)}
                />
              )}
              {!item.debug_passthrough && (
                <Dropdown.Item
                  key="set-caz"
                  text="Enable the debug passthrough (port 58529; NOT for production workloads)"
                  onClick={() => args.onUpdateLoadBalancerDebugPassthrough(item, true)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.Cell>
      <Table.Cell>
        {!item.memory_override ? "None" : item.memory_override}{" "}
        {!item.is_shared && args.canUpdateLoadbalancer && (
          <LoadBalancerMemoryOverrideModal memory_override={item.memory_override} onSetLoadBalancerMemoryOverride={onSetLoadBalancerMemoryOverride} {...args} />
        )}
      </Table.Cell>
      <Table.Cell>
        {!item.replicas_override ? "0" : item.replicas_override}{" "}
        {item.is_shared && args.canUpdateLoadbalancer && (
          <LoadBalancerReplicasOverrideModal
            replicas_override={item.replicas_override}
            onSetLoadBalancerReplicasOverride={onSetLoadBalancerReplicasOverride}
            {...args}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <StatusView ls={item.status || {}} />
      </Table.Cell>
      <Table.Cell>{args.item.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created on" /> : "-"}</Table.Cell>
      <Table.Cell>
        <Finalizers finalizers={item.finalizers} />
      </Table.Cell>
      <Table.Cell>{args.item.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

interface IListView extends CommentCreationArgs {
  active: boolean;
  canUpdateLoadbalancer: boolean;
  items: ApiLoadBalancer[];
  loading: boolean;
  onUpdateLoadBalancerCrossAvailability: (req: ApiLoadBalancer, newValue: boolean) => Promise<void>;
  onUpdateLoadBalancerDebugPassthrough: (req: ApiLoadBalancer, newValue: boolean) => Promise<void>;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} {...args} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No load balancers</div>;

export interface ILoadBalancerListViewArgs extends RouteComponentProps, CommentCreationArgs {
  active: boolean;
  loading: boolean;
  canUpdateLoadbalancer: boolean;
  loadBalancers?: ApiLoadBalancerList;
  onUpdateLoadBalancerCrossAvailability: (req: ApiLoadBalancer, newValue: boolean) => Promise<void>;
  onUpdateLoadBalancerDebugPassthrough: (req: ApiLoadBalancer, newValue: boolean) => Promise<void>;
}

export const LoadBalancerListView = ({ ...args }: ILoadBalancerListViewArgs) => {
  if (!args.loadBalancers) {
    return <Loading />;
  }
  const items = args.loadBalancers.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={_.orderBy(items, (x) => -moment(x.created_at).unix())} loading={args.loading} />;
};

interface ILoadBalancerListProps extends IWithRefreshProps, RouteComponentProps, CommentCreationArgs {
  datacluster?: ApiDataCluster;
  deployment?: ApiDeployment;
}

interface ILoadBalancerListState {
  errorMessage?: string;
  processing: boolean;
  loadBalancers?: ApiLoadBalancerList;
}

// The component to show the data cluster load balancer list.
class LoadBalancerList extends Component<ILoadBalancerListProps, ILoadBalancerListState> {
  state: ILoadBalancerListState = {
    errorMessage: undefined,
    processing: false,
    loadBalancers: undefined,
  };

  reloadLoadBalancers = async () => {
    const listOptions: ApiListLoadBalancersRequest = {
      datacluster_id: this.props.datacluster && this.props.datacluster.id,
      deployment_id: this.props.deployment && this.props.deployment.id,
    };
    const loadBalancers = await apiClients.idashboardClient.ListLoadBalancers(listOptions);
    this.setState({ loadBalancers: loadBalancers });
  };

  onUpdateLoadBalancerCrossAvailability = async (req: ApiLoadBalancer, newValue: boolean) => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      req.cross_availability_zone = newValue;
      await apiClients.idashboardClient.UpdateLoadBalancer(req);
      this.reloadLoadBalancers();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processing: false });
    }
  };

  onUpdateLoadBalancerDebugPassthrough = async (req: ApiLoadBalancer, newValue: boolean) => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      req.debug_passthrough = newValue;
      await apiClients.idashboardClient.UpdateLoadBalancer(req);
      this.reloadLoadBalancers();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processing: false });
    }
  };

  componentDidMount() {
    if (this.props.datacluster) {
      const dataclusterUrl = `/Organization/_system/DataCluster/${this.props.datacluster.id}`; // note: datacluster.url doesn't exist
      this.props.subscribeUrl && this.props.subscribeUrl(this.reloadLoadBalancers, `${dataclusterUrl}/LoadBalancer/*`);
    }
    if (this.props.deployment) {
      this.props.subscribeUrl && this.props.subscribeUrl(this.reloadLoadBalancers, `${this.props.deployment.url}/LoadBalancer/*`);
    }
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  hasPermission = (p: Permission) => {
    const deployment = this.props.deployment;
    const url = (deployment && deployment.url) || "";
    return !!(this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(url, ResourceType.Deployment, p));
  };

  render() {
    const canUpdateLoadbalancer = this.hasPermission("internal-dashboard.loadbalancer.update");
    return (
      <div>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Processing active={this.state.processing} message="Changing loadbalancer, please wait..." />{" "}
        <LoadBalancerListView
          {...this.props}
          {...this.state}
          active={!this.state.processing}
          canUpdateLoadbalancer={canUpdateLoadbalancer}
          onUpdateLoadBalancerCrossAvailability={this.onUpdateLoadBalancerCrossAvailability}
          onUpdateLoadBalancerDebugPassthrough={this.onUpdateLoadBalancerDebugPassthrough}
        />
      </div>
    );
  }
}

export default withRefresh()(LoadBalancerList);
