//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Checkbox, Loader, Menu, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  AuditLog as ApiAuditLog,
  AuditLogArchiveInfoList as ApiAuditLogArchiveInfoList,
  AuditLogArchiveInfo as ApiAuditLogArchiveInfo,
  ListAuditLogArchiveInfosRequest as ApiListAuditLogArchiveInfosRequest,
} from "../../api/lib";
import { ContentSegment, ErrorMessage, LoaderBoxForTable as LoaderBox, Loading, SecondaryMenu, TextLink } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";

// Arguments for header view
interface IHeaderView {}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Datacluster</Table.HeaderCell>
        <Table.HeaderCell>Region</Table.HeaderCell>
        <Table.HeaderCell>AuditLog</Table.HeaderCell>
        <Table.HeaderCell>Deployment</Table.HeaderCell>
        <Table.HeaderCell>Size</Table.HeaderCell>
        <Table.HeaderCell>Size Changed At</Table.HeaderCell>
        <Table.HeaderCell>Created</Table.HeaderCell>
        <Table.HeaderCell>Deleted</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

// Interface describing a AuditLog
interface IRowView extends IWithRefreshProps {
  onAuditLogSelected: () => void;
  onDataClusterSelected: () => void;
  onDeploymentSelected: () => void;
  onAuditLogArchiveSelected: () => void;
  item: ApiAuditLogArchiveInfo;
}

const RowView = ({ ...args }: IRowView) => {
  const auditLogArchiveInfo = args.item || {};
  const auditLogArchive = auditLogArchiveInfo.audit_log_archive || {};
  const id = auditLogArchive.id || "-";
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>
        <TextLink label={id} onClick={args.onAuditLogArchiveSelected} />
      </Table.Cell>
      <Table.Cell>
        <TextLink label={args.item.datacluster_id} onClick={args.onDataClusterSelected} />
      </Table.Cell>
      <Table.Cell>{auditLogArchiveInfo.region_id}</Table.Cell>
      <Table.Cell>
        <TextLink label={auditLogArchive.auditlog_id} onClick={args.onAuditLogSelected} />
      </Table.Cell>
      <Table.Cell>
        <TextLink label={auditLogArchive.deployment_id} onClick={args.onDeploymentSelected} />
      </Table.Cell>
      <Table.Cell>{humanizeFileSize(auditLogArchive.size_in_bytes)}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={auditLogArchive.size_in_bytes_changed_at} label="Size changed at" />
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={auditLogArchive.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>
        {auditLogArchive.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={auditLogArchive.deleted_at} label="Deleted at" /> : "-"}
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the AuditLog list
interface IListView extends IWithRefreshProps {
  items?: ApiAuditLogArchiveInfo[];
  onAuditLogSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const items = args.items || [];
  return (
    <Table striped>
      <HeaderView {...args} />
      <Table.Body>
        {items.map((item) => (
          <RowView
            {...args}
            key={item && item.audit_log_archive && item.audit_log_archive.id}
            item={item}
            onAuditLogSelected={() => args.onAuditLogSelected((item.audit_log_archive && item.audit_log_archive.auditlog_id) || "")}
            onDataClusterSelected={() => args.onDataClusterSelected(item.datacluster_id || "")}
            onDeploymentSelected={() => args.onDeploymentSelected((item.audit_log_archive && item.audit_log_archive.deployment_id) || "")}
            onAuditLogArchiveSelected={() => args.onAuditLogArchiveSelected((item.audit_log_archive && item.audit_log_archive.id) || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IEmptyViewArgs {}

const EmptyView = ({ ...args }: IEmptyViewArgs) => {
  return <div>No Audit Archives</div>;
};

// Interface describing the AuditLog list view arguments
export interface IAuditLogArchiveInfoListViewArgs extends IListView {
  auditLogArchiveInfos?: ApiAuditLogArchiveInfoList;
}

const AuditLogArchiveInfoListViewContent = ({ ...args }: IAuditLogArchiveInfoListViewArgs) => {
  const auditLogArchivesInfos = args.auditLogArchiveInfos || {};
  const items = auditLogArchivesInfos.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView {...args} />;
  }
  return <ListView {...args} items={items} />;
};

export const AuditLogArchiveInfoListView = ({ ...args }: IAuditLogArchiveInfoListViewArgs) => {
  if (!args.auditLogArchiveInfos) {
    return <Loading />;
  }
  return (
    <div>
      <AuditLogArchiveInfoListViewContent {...args} />
    </div>
  );
};

// Interface describing the properties of the AuditLog list component
interface IAuditLogArchiveInfoListProps extends IWithRefreshProps, RouteComponentProps {
  project_id?: string;
  auditLog: ApiAuditLog;
  deployment_id?: string;
  with_deployment_id_flipper?: boolean;
  onAuditLogSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
}

// Interface describing the state of the AuditLog list component
interface IAuditLogArchiveInfoListState {
  auditLogArchiveInfos?: ApiAuditLogArchiveInfoList;
  auditlog_id: string;
  reloadListNeeded: boolean;
  withoutDeploymentId: boolean;
  subscriptionId?: string;
}

// The component to show the auditlog archive infos.
class AuditLogArchiveInfoList extends Component<IAuditLogArchiveInfoListProps, IAuditLogArchiveInfoListState> {
  state: IAuditLogArchiveInfoListState = {
    auditlog_id: this.props.auditLog.id || "",
    reloadListNeeded: false,
    auditLogArchiveInfos: undefined,
    subscriptionId: undefined,
    withoutDeploymentId: false,
  };

  reloadAuditLogArchiveInfos = async () => {
    if (this.props.auditLog) {
      const req: ApiListAuditLogArchiveInfosRequest = {
        auditlog_id: this.props.auditLog.id,
        project_id: this.props.project_id,
        deployment_id: this.props.deployment_id,
        without_deployment_id: this.state.withoutDeploymentId,
        include_deleted: true,
      };
      const auditLogArchiveInfoList = await apiClients.idashboardClient.ListAuditLogArchiveInfos(req);
      this.setState({
        auditLogArchiveInfos: auditLogArchiveInfoList,
      });
    }
  };

  refreshAuditLogArchiveInfos = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchiveInfos);
  };

  onFlipWithoutDeploymentId = () => {
    this.setState({ withoutDeploymentId: !this.state.withoutDeploymentId }, this.refreshAuditLogArchiveInfos);
  };

  async componentDidMount() {
    const subscribeUrl = this.props.subscribeUrl;
    const unsubscribe = this.props.unsubscribe;
    if (subscribeUrl && unsubscribe) {
      if (this.state.subscriptionId) {
        unsubscribe(this.state.subscriptionId);
      }
      const subscriptionId = await subscribeUrl(this.reloadAuditLogArchiveInfos, `${this.props.auditLog.url}/*`);
      this.setState({ subscriptionId: subscriptionId });
    }
  }

  componentDidUpdate() {
    if (this.state.reloadListNeeded) {
      this.componentDidMount();
      this.setState({ reloadListNeeded: false }, this.refreshAuditLogArchiveInfos);
    }
  }

  static getDerivedStateFromProps(props: IAuditLogArchiveInfoListProps, state: IAuditLogArchiveInfoListState) {
    // Store prevId in state so we can compare when props change.
    if (props.auditLog.id !== state.auditlog_id) {
      return {
        auditlog_id: props.auditLog.id,
        reloadListNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  render() {
    const infos = this.state.auditLogArchiveInfos;
    const view = (
      <div>
        {this.props.with_deployment_id_flipper && (
          <Menu borderless pointing stackable>
            <Menu.Item header>No deployment ID</Menu.Item>
            <Menu.Item>
              <Checkbox fitted slider onChange={this.onFlipWithoutDeploymentId} toggle />
            </Menu.Item>
          </Menu>
        )}
        <AuditLogArchiveInfoListView {...this.props} {...this.state} auditLogArchiveInfos={infos} />
      </div>
    );

    if (!!this.props.project_id) {
      return view;
    }
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>AuditLog Archive Infos</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        {view}
      </ContentSegment>
    );
  }
}

export default withRefresh()(AuditLogArchiveInfoList);
