//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dropdown, Form, Header, Icon, Modal, Popup } from "semantic-ui-react";
import { ReplaceVersionBy as ApiReplaceVersionBy, Version as ApiVersion } from "../../api/lib";
import { ErrorMessage, Field, FieldContent as FC, FieldLabelWide as FL, FlexBox, StyledInlineLoader } from "../../ui/lib";
import { AsyncResult } from "../../util/Types";
import { IWithRefreshProps } from "../../util/WithRefresh";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";
import SelectVersion from "./SelectVersion";

interface IConfigurationVersionViewArgs extends IWithRefreshProps, RouteComponentProps {
  organizationId: string;
  version?: string;
  replace_version_by?: ApiReplaceVersionBy;
  can_edit_version: boolean;
  versions: ApiVersion[];
  onUpdateVersion: (newVersion: string) => void;
}

const ConfigurationVersionView = ({ ...args }: IConfigurationVersionViewArgs) => {
  const has_replacement = !!args.replace_version_by;
  const version: ApiVersion = { version: args.version };
  const newVersion = (args.replace_version_by && args.replace_version_by.version) || "";
  const reason = (args.replace_version_by && args.replace_version_by.reason) || "no reason specified";
  const versionInfo = find(args.versions, (x) => x.version === args.version) || {};
  return (
    <span>
      {args.version}
      {versionInfo.is_end_of_life && <Popup trigger={<Icon name="bell slash" />} content="End of life" />}
      {args.can_edit_version && (
        <span>
          {has_replacement && <span style={{ color: "orange" }}> Update available!</span>}
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {has_replacement && (
                <Dropdown.Item
                  key="update-version"
                  text={`Update version to: ${newVersion}, because ${reason}`}
                  onClick={() => args.onUpdateVersion(newVersion)}
                />
              )}
              <SelectVersion
                {...args}
                version={version}
                onVersionUpdated={(v) => {
                  v && args.onUpdateVersion(v.version || "");
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </span>
      )}
    </span>
  );
};

interface IConfigurationKubeArangodbImageViewArgs extends IWithRefreshProps, RouteComponentProps, CommentCreationArgs {
  custom_kube_arangodb_image?: string;
  can_edit_kube_arangodb_image: boolean;
  onClickEditKubeArangodbImage: () => void;
  editKubeArangodbImage: boolean;
  customKubeArangodbImage: string;
  onChangeKubeArangodbImage: (newImage: string) => Promise<AsyncResult<void>>;
  onClickCancelEditKubeArangodbImage: () => void;
  onClickSaveEditKubeArangodbImage: () => void;
  processingUpdateKubeArangodbImage: boolean;
}

const ConfigurationKubeArangodbImageView = ({ ...args }: IConfigurationKubeArangodbImageViewArgs) => {
  const [kubeArangoImageName, setKubeArangoImageName] = useState<string>(args.customKubeArangodbImage || "");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);
  const [showModal, setShowModal] = useState(args.editKubeArangodbImage);
  const [errorMessage, setErrorMessage] = useState("");

  const handleKubeArangoImageNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKubeArangoImageName(e.target.value);
  };

  useEffect(() => {
    if (args.customKubeArangodbImage !== kubeArangoImageName) {
      setComment(`Update kube arangodb image to ${kubeArangoImageName || "none"} `);
    } else {
      setComment("");
    }
  }, [kubeArangoImageName]);

  const saveImageNameChange = async () => {
    setLoading(true);
    setErrorMessage("");

    if (!commentCreationFailed) {
      let { error } = await args.onChangeKubeArangodbImage(kubeArangoImageName);
      if (error) {
        setErrorMessage(error);
        setLoading(false);
        return;
      }
    }

    setCommentCreationFailed(false);

    const { error: commentError } = await args.createComment(comment);

    if (commentError) {
      setCommentCreationFailed(true);
      setErrorMessage("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }

    setLoading(false);
    setShowModal(false);
  };

  return (
    <span>
      {args.customKubeArangodbImage || "-"}
      {args.can_edit_kube_arangodb_image && (
        <span>
          <Icon
            name="pencil"
            onClick={() => {
              args.onClickEditKubeArangodbImage();
              setShowModal(true);
            }}
            className="edit-pencil"
          />
        </span>
      )}

      <Modal open={showModal} size="tiny">
        <Modal.Header>
          <FlexBox justify="space-between">
            <span>Edit Kube-arangodb image</span>
            {loading && <StyledInlineLoader inline />}
          </FlexBox>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ErrorMessage active={!!commentCreationFailed || !!errorMessage} message={errorMessage} />
            <Form>
              <Form.Field>
                <label>Kube-Arangodb Image</label>
                <Form.Input value={kubeArangoImageName} onChange={handleKubeArangoImageNameChange} disabled={commentCreationFailed} />
              </Form.Field>
              <Form.Field required>
                <label>Provide the reason for this change</label>
                <CommentInputField
                  handleAddComment={() => {}}
                  commentCreationInProcess={false}
                  handleCommentChange={(value: string) => {
                    setComment(value);
                  }}
                  comment={comment}
                  showOnlyInputField
                />
                <sub>
                  Comment box will be empty if there is no change in the <b>Image</b>. Otherwise, a default comment will be provided which can be changed if
                  required.
                </sub>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              setComment("");
              setCommentCreationFailed(false);
              setErrorMessage("");
              setLoading(false);
              args.onClickCancelEditKubeArangodbImage();
            }}
            negative
            content="Cancel"
          />
          {commentCreationFailed ? (
            <Button
              onClick={saveImageNameChange}
              positive
              labelPosition="right"
              icon="comment"
              content="Retry saving comment"
              loading={args.processingUpdateKubeArangodbImage || loading}
              disabled={!comment.trim()}
            />
          ) : (
            <Button
              onClick={saveImageNameChange}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Save"
              loading={args.processingUpdateKubeArangodbImage || loading}
              disabled={args.customKubeArangodbImage === kubeArangoImageName || !comment.trim()}
            />
          )}
        </Modal.Actions>
      </Modal>
    </span>
  );
};

interface IConfigurationArangodbImageViewArgs extends IWithRefreshProps, RouteComponentProps, CommentCreationArgs {
  customArangodbImage?: string;
  canEditArangodbImage: boolean;
  onEditArangodbImage: () => void;
  editArangodbImage: boolean;
  onCancelEditArangodbImage: () => void;
  onSaveEditArangodbImage: (newImage: string) => Promise<AsyncResult<void>>;
  processingUpdateArangodbImage: boolean;
}

const ConfigurationArangodbImageView = ({ ...args }: IConfigurationArangodbImageViewArgs) => {
  const [arangoImageName, setArangoImageName] = React.useState<string>(args.customArangodbImage || "");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);
  const [showModal, setShowModal] = useState(args.editArangodbImage);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (args.customArangodbImage !== arangoImageName && !!arangoImageName) {
      setComment(`Update arangodb image to ${arangoImageName}`);
    } else {
      setComment("");
    }
  }, [arangoImageName]);
  const handleArangoImageNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArangoImageName(e.target.value);
  };

  const saveImageNameChange = async () => {
    setLoading(true);
    const { error } = await args.onSaveEditArangodbImage(arangoImageName);
    if (error) {
      setErrorMessage(error);
      setLoading(false);
      return;
    }
    setCommentCreationFailed(false);

    const { error: commentError } = await args.createComment(comment);

    if (commentError) {
      setCommentCreationFailed(true);
      setErrorMessage("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }

    setShowModal(false);
    setLoading(false);
  };

  return (
    <span>
      {args.customArangodbImage || "-"}
      {args.canEditArangodbImage && (
        <span>
          <Icon
            name="pencil"
            onClick={() => {
              args.onEditArangodbImage();
              setShowModal(true);
            }}
            className="edit-pencil"
          />
        </span>
      )}
      <Modal open={showModal} size="tiny">
        <Modal.Header>
          <FlexBox justify="space-between">
            <span>Edit Arangodb image</span>
            {loading && <StyledInlineLoader inline />}
          </FlexBox>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ErrorMessage active={!!commentCreationFailed || !!errorMessage} message={errorMessage} />
            <Form>
              <Form.Field>
                <label>Arangodb Image</label>
                <Form.Input value={arangoImageName} onChange={handleArangoImageNameChange} disabled={commentCreationFailed} />
              </Form.Field>
              <Form.Field required>
                <label>Provide the reason for this change</label>
                <CommentInputField
                  handleAddComment={() => {}}
                  commentCreationInProcess={false}
                  handleCommentChange={(value: string) => {
                    setComment(value);
                  }}
                  comment={comment}
                  showOnlyInputField
                />
                <sub>
                  Comment box will be empty if there is no change in the <b>Image</b>. Otherwise, a default comment will be provided which can be changed if
                  required.
                </sub>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              setComment("");
              setCommentCreationFailed(false);
              setErrorMessage("");
              setLoading(false);
              args.onCancelEditArangodbImage();
            }}
            negative
            content="Cancel"
          />
          {commentCreationFailed ? (
            <Button
              onClick={saveImageNameChange}
              positive
              labelPosition="right"
              icon="comment"
              content="Retry saving comment"
              loading={args.processingUpdateArangodbImage || loading}
              disabled={!comment.trim()}
            />
          ) : (
            <Button
              onClick={saveImageNameChange}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Save"
              loading={args.processingUpdateArangodbImage || loading}
              disabled={args.customArangodbImage === arangoImageName || !comment.trim()}
            />
          )}
        </Modal.Actions>
      </Modal>
    </span>
  );
};

interface IConfigurationNotebookImageViewArgs extends IWithRefreshProps, RouteComponentProps, CommentCreationArgs {
  custom_notebook_image?: string;
  can_edit_notebook_image: boolean;
  onClickEditNotebookImage: () => void;
  editNotebookImage: boolean;
  customNotebookImage: string;
  onChangeNotebookImage: (newImage: string) => Promise<AsyncResult<void>>;
  onClickCancelEditNotebookImage: () => void;
  onClickSaveEditNotebookImage: () => void;
  processingUpdateNotebookImage: boolean;
}

const ConfigurationNotebookImageView = ({ ...args }: IConfigurationNotebookImageViewArgs) => {
  const [notebookImageName, setNotebookImageName] = useState<string>(args.customNotebookImage || "");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);
  const [showModal, setShowModal] = useState(args.editNotebookImage);
  const [errorMessage, setErrorMessage] = useState("");

  const handleNotebookImageNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotebookImageName(e.target.value);
  };

  useEffect(() => {
    if (args.customNotebookImage !== notebookImageName) {
      setComment(`Update notebook image to ${notebookImageName || "none"} `);
    } else {
      setComment("");
    }
  }, [notebookImageName]);

  const saveImageNameChange = async () => {
    setLoading(true);
    setErrorMessage("");

    if (!commentCreationFailed) {
      let { error } = await args.onChangeNotebookImage(notebookImageName);
      if (error) {
        setErrorMessage(error);
        setLoading(false);
        return;
      }
    }

    setCommentCreationFailed(false);

    const { error: commentError } = await args.createComment(comment);

    if (commentError) {
      setCommentCreationFailed(true);
      setErrorMessage("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }

    setLoading(false);
    setShowModal(false);
  };

  return (
    <span>
      {args.customNotebookImage || "-"}
      {args.can_edit_notebook_image && (
        <span>
          <Icon
            name="pencil"
            onClick={() => {
              args.onClickEditNotebookImage();
              setShowModal(true);
            }}
            className="edit-pencil"
          />
        </span>
      )}

      <Modal open={showModal} size="tiny">
        <Modal.Header>
          <FlexBox justify="space-between">
            <span>Edit Notebook image</span>
            {loading && <StyledInlineLoader inline />}
          </FlexBox>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ErrorMessage active={!!commentCreationFailed || !!errorMessage} message={errorMessage} />
            <Form>
              <Form.Field>
                <label>Notebook Image</label>
                <Form.Input value={notebookImageName} onChange={handleNotebookImageNameChange} disabled={commentCreationFailed} />
              </Form.Field>
              <Form.Field required>
                <label>Provide the reason for this change</label>
                <CommentInputField
                  handleAddComment={() => {}}
                  commentCreationInProcess={false}
                  handleCommentChange={(value: string) => {
                    setComment(value);
                  }}
                  comment={comment}
                  showOnlyInputField
                />
                <sub>
                  Comment box will be empty if there is no change in the <b>Image</b>. Otherwise, a default comment will be provided which can be changed if
                  required.
                </sub>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              setComment("");
              setCommentCreationFailed(false);
              setErrorMessage("");
              setLoading(false);
              args.onClickCancelEditNotebookImage();
            }}
            negative
            content="Cancel"
          />
          {commentCreationFailed ? (
            <Button
              onClick={saveImageNameChange}
              positive
              labelPosition="right"
              icon="comment"
              content="Retry saving comment"
              loading={args.processingUpdateNotebookImage || loading}
              disabled={!comment.trim()}
            />
          ) : (
            <Button
              onClick={saveImageNameChange}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Save"
              loading={args.processingUpdateNotebookImage || loading}
              disabled={args.customNotebookImage === notebookImageName || !comment.trim()}
            />
          )}
        </Modal.Actions>
      </Modal>
    </span>
  );
};

export interface IVersionsViewArgs extends IConfigurationArangodbImageViewArgs, IWithRefreshProps, RouteComponentProps, CommentCreationArgs {
  organizationId: string;
  version?: string;
  versions: ApiVersion[];
  replace_version_by?: ApiReplaceVersionBy;
  can_edit_version: boolean;
  onUpdateVersion: (newVersion: string) => void;
  custom_kube_arangodb_image?: string;
  custom_notebook_image?: string;
  can_edit_kube_arangodb_image: boolean;
  can_edit_notebook_image: boolean;
  onClickEditKubeArangodbImage: () => void;
  onClickEditNotebookImage: () => void;
  editKubeArangodbImage: boolean;
  editNotebookImage: boolean;
  onChangeKubeArangodbImage: (newImage: string) => Promise<AsyncResult<void>>;
  onChangeNotebookImage: (newImage: string) => Promise<AsyncResult<void>>;
  onClickCancelEditKubeArangodbImage: () => void;
  onClickCancelEditNotebookImage: () => void;
  onClickSaveEditKubeArangodbImage: () => void;
  onClickSaveEditNotebookImage: () => void;
  processingUpdateKubeArangodbImage: boolean;
  processingUpdateNotebookImage: boolean;
  customKubeArangodbImage: string;
  customNotebookImage: string;
}

export const VersionsView = ({ ...args }: IVersionsViewArgs) => {
  return (
    <div>
      <Header sub>Versions</Header>
      <Field>
        <FL>Version</FL>
        <FC>
          <ConfigurationVersionView {...args} />
        </FC>
      </Field>
      <Field>
        <FL>Custom Arangodb image</FL>
        <FC>
          <ConfigurationArangodbImageView {...args} />
        </FC>
      </Field>
      <Field>
        <FL>Custom kube-arangodb image</FL>
        <FC>
          <ConfigurationKubeArangodbImageView {...args} />
        </FC>
      </Field>
      <Field>
        <FL>Custom notebook image</FL>
        <FC>
          <ConfigurationNotebookImageView {...args} />
        </FC>
      </Field>
    </div>
  );
};
