//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _, { orderBy } from "lodash";
import { Moment } from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Loader, Menu, Message, Placeholder, Segment, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { DeploymentChanges as ApiDeploymentChanges, GetDeploymentChangesRequest as ApiGetDeploymentChangesRequest } from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  CreationDateFilter,
  ErrorMessage,
  TextWithHelp,
  MonthAndWeekAndYearCreationDateFilters,
  SecondaryMenu,
  momentDefaultFormat,
  momentNow,
  momentStartOfProject,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CreateReportButtonView from "./CreateReportButton";

const dateFilters = MonthAndWeekAndYearCreationDateFilters(false);

interface IFilterViewArgs {
  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Date: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
      <Menu.Item position="right">
        <CreateReportButtonView include_deployment_changes />
      </Menu.Item>
    </Menu>
  );
};

interface IStatisticsGroupViewArgs {
  report: IChangesReport;
}

const StatisticsGroupsView = ({ ...args }: IStatisticsGroupViewArgs) => {
  const st = args.report.changes;
  return (
    <Table.Row>
      <Table.Cell>{args.report.tier_id}</Table.Cell>
      <Table.Cell>{st.distinct || 0}</Table.Cell>
      <Table.Cell>{st.created || 0}</Table.Cell>
      <Table.Cell>{st.created_still_running || 0}</Table.Cell>
      <Table.Cell>{st.deleted || 0}</Table.Cell>
      <Table.Cell>{st.deleted_was_running || 0}</Table.Cell>
      <Table.Cell>{st.running || 0}</Table.Cell>
    </Table.Row>
  );
};

interface IStatisticsViewArgs {
  expectedReports: number;
  reports: IChangesReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
}

const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";
  const loading = args.expectedReports > args.reports.length;
  return (
    <div>
      <Message>
        Deployment changes {timeFilter}.{loading && <Loader inline active size="mini" />}
      </Message>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tier</Table.HeaderCell>
            <Table.HeaderCell>Distinct deployment</Table.HeaderCell>
            <Table.HeaderCell>
              <TextWithHelp text="Created" help="Created in the period" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TextWithHelp text="Created and still running" help="Created in the period and still running at the end of the period" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TextWithHelp text="Deleted" help="Deleted in the period" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TextWithHelp text="Deleted from previous period" help="Deleted in the period created before the beginning of the period" />
            </Table.HeaderCell>
            <Table.HeaderCell>Running entire period</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {args.reports.map((x) => (
            <StatisticsGroupsView {...args} report={x} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const PlaceholderView = () => (
  <Segment>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

// Interface describing the invoice list view arguments
export interface IRevenueReportingViewArgs extends IFilterViewArgs, RouteComponentProps {
  expectedReports: number;
  reports?: IChangesReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
}

export const RevenueReportingView = ({ ...args }: IRevenueReportingViewArgs) => {
  const reports = args.reports || [];
  const has_reports = !_.isEmpty(reports);
  return (
    <div>
      <FilterView {...args} />
      {has_reports && <StatisticsView {...args} reports={reports} />}
      {!has_reports && <PlaceholderView />}
    </div>
  );
};

interface IDeploymentChangesReportingProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
}

interface IChangesReport {
  tier_id: string;
  changes: ApiDeploymentChanges;
}

interface IDeploymentChangesReportingState {
  sequence: number;
  errorMessage?: string;
  reports: Map<string, IChangesReport>;
  tierCount: number;
  filterFrom?: Moment;
  filterTo?: Moment;
  selectedCreationDateFilterID: string;
}

// The component to show revenue reporting.
class DeploymentChangesReporting extends Component<IDeploymentChangesReportingProps, IDeploymentChangesReportingState> {
  state: IDeploymentChangesReportingState = {
    sequence: 0,
    errorMessage: undefined,
    filterFrom: undefined,
    filterTo: undefined,
    tierCount: 0,
    reports: new Map<string, IChangesReport>(),
    selectedCreationDateFilterID: dateFilters[0].id,
  };

  reloadChanges = async () => {
    this.setState(
      (old) => {
        return {
          sequence: old.sequence + 1,
          reports: new Map<string, IChangesReport>(),
        };
      },
      async () => {
        const self = this;
        const sequence = this.state.sequence;
        let filterFrom = momentStartOfProject();
        let filterTo = momentNow();

        const f = dateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
        if (f) {
          if (f.filterStart) {
            filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          }
          if (f.filterEnd) {
            filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          }
        }

        // List all tiers
        const tiersList = await apiClients.idashboardClient.ListTiers({});
        const tiers = tiersList.items || [];

        // Load report per tier
        tiers.forEach(async (x) => {
          const id = x.id || "";
          const report = await self.loadChanges(id, filterFrom, filterTo);
          self.setState((old) => {
            if (old.sequence != sequence) return { reports: old.reports };
            const newReports = new Map(old.reports);
            newReports.set(id, report);
            return { reports: newReports };
          });
        });

        this.setState({
          filterFrom: filterFrom,
          filterTo: filterTo,
          tierCount: tiers.length,
        });
      }
    );
  };

  loadChanges = async (tier_id: string, filterFrom: Moment, filterTo: Moment) => {
    const req: ApiGetDeploymentChangesRequest = {
      tier_id: tier_id,
      from: filterFrom.toDate(),
      to: filterTo.toDate(),
    };

    const changes = await new Promise<ApiDeploymentChanges>(async (resolve, reject) => {
      try {
        await apiClients.idashboardClient.GetDeploymentChanges(req, (x) => {
          if (!!x.message) resolve(x.message);
          else if (!!x.error) {
            reject(x.error);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
    const result: IChangesReport = {
      tier_id: tier_id,
      changes: changes,
    };
    return result;
  };

  refreshChanges = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadChanges);
  };

  componentDidMount() {
    this.refreshChanges();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState(
      {
        selectedCreationDateFilterID: id,
        reports: new Map<string, IChangesReport>(),
      },
      this.refreshChanges
    );
  };

  render() {
    const reports = orderBy(Array.from(this.state.reports.values()), (report) => report.tier_id);
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Deployment changes</Menu.Item>
        </SecondaryMenu>
        <RevenueReportingView
          {...this.props}
          {...this.state}
          expectedReports={this.state.tierCount}
          reports={_.isEmpty(reports) ? undefined : reports}
          creationDateFilters={dateFilters}
          onCreationDateFilterChanged={this.onCreationDateFilterChanged}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(DeploymentChangesReporting);
