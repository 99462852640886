//
// DISCLAIMER
//
// Copyright 2019-2024 ArangoDB GmbH, Cologne, Germany
//

import { numberFormat } from "humanize";
import _, { isEmpty, slice } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import copy from "copy-to-clipboard";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import ReactJson from "react-json-view";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dropdown, Grid, Header, Loader, Menu, Modal, Segment, Table, Divider, Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  Common,
  UpdateDataClusterAnnotationsRequest as ApiUpdateDataClusterAnnotationsRequest,
  KeyValuePair as ApiKeyValuePair,
  DataCluster as ApiDataCluster,
  DataClusterProvisionInfo as ApiDataClusterProvisionInfo,
  DataCluster_Status as ApiDataCluster_Status,
  IDOptions as ApiIDOptions,
  MetricsInfo as ApiMetricsInfo,
  SetDataClusterLabelsRequest as ApiSetDataClusterLabelsRequest,
  SetDataClusterUnassignableRequest as ApiSetDataClusterUnassignableRequest,
  UpdateDataClusterRebootNodesAllowedRequest as ApiUpdateDataClusterRebootNodesAllowedRequest,
  UpdateDataClusterUpgradeNodepoolsAllowedRequest as ApiUpdateDataClusterUpgradeNodepoolsAllowedRequest,
  UpdateDataClusterMaintenanceModeRequest as ApiUpdateDataClusterMaintenanceModeRequest,
  UpdateDataClusterCiliumHubbleRequest as ApiUpdateDataClusterCiliumHubbleRequest,
  UpdateDataClusterCoreDumpRequest as ApiUpdateDataClusterCoreDumpRequest,
  KubernetesAccessRequest as ApiKubernetesAccessRequest,
  FinalizerRequest as ApiFinalizerRequest,
  ListOptions as ApiListOptions,
  ControlplaneStatus as ApiControlplaneStatus,
  APICompatibility as ApiAPICompatibility,
  CompatibilityRequest as ApiCompatibilityRequest,
  UpdateDataClusterPrometheusMemoryRequest as ApiUpdateDataClusterPrometheusMemoryRequest,
  VolumeInfo as ApiVolumeInfo,
  UpdateDataClusterVolumeInfosRequest as ApiUpdateDataClusterVolumeInfosRequest,
  UpdateDataClusterDiskSizesRequest as ApiUpdateDataClusterDiskSizesRequest,
  UpdateDataClusterNodeUpdateConcurrencyLevelRequest as ApiUpdateDataClusterNodeUpdateConcurrencyLevelRequest,
} from "../../api/lib";
import { Routes } from "../../routes";
import {
  Confirm,
  ConfirmInfo,
  ContentSegment,
  ErrorMessage,
  Field,
  FieldContent as FC,
  FieldLabelWide as FLW,
  LoaderBox,
  MenuActionBack,
  Processing,
  SecondaryMenu,
  Finalizers,
  Annotations,
  TextLink,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import DeploymentList from "../deployment/DeploymentList";
import Location from "../deployment/Location";
import { DataVolumeView } from "../status/DataVolumeView";
import { MetricsInfoView } from "../status/MetricsInfo";
import { NodesView } from "../status/Nodes";
import { PodStatisticsView } from "../status/PodStatistics";
import { DataClusterHelper } from "./DataClusterHelper";
import { hasSupportPermission } from "../../util/PermissionCache";
import LoadBalancerList from "../network/LoadBalancerList";
import { CommentCreationArgs, ICommentPromptStateForIntegrations, ICommentsStateForIntegration } from "../comments/CommentTypes";
import {
  CommentList as ApiCommentList,
  CreateCommentRequest as ApiCreateCommentRequest,
  ListCommentsRequest as ApiListCommentsRequest,
} from "../../api/comment/v1/icomment";
import CommentsSectionInline from "../comments/CommentSectionInline";
import { AsyncResult } from "../../util/Types";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import CommentsPrompt from "../comments/CommentsPrompt";
import DCLabelsEditModalView from "./DataClusterLabelsModelView";
import { ControlplaneAPICompatView } from "./ControlplaneAPICompatView";
import NodePoolList from "./NodePoolList";
import TabLayoutWithRouter from "../../components/Tab/TabWithRouter";
import { ITabConfig } from "../../components/Tab/TabTypes";
import { VolumeInfoView } from "../deployment/VolumeInfosViews";
import { ConfigurationDiskSizeView } from "./DiskSizeView";
import CoreDumpList from "../coredump/CoreDumpList";
import { DataClusterOptimizationStatus } from "./DataClusterOptimizationStatus";

const isControlPlane = (dataClusterID?: string): boolean => dataClusterID === `_cp`;

interface IDataClusterDetailsViewArgs extends IWithRefreshProps, RouteComponentProps, CommentCreationArgs {
  datacluster: ApiDataCluster;
  status: ApiDataCluster_Status;
  canEditLabels: boolean;
  editLabels: boolean;
  labels: string[];
  canGetKubeConfig: boolean;
  copiedKubeConfig: boolean;
  onCopyKubeConfig: () => void;
  onClickSetAssignable: () => void;
  onClickSetUnassignable: () => void;
  onClickEditLabels: () => void;
  onCancelEditLabels: () => void;
  onSaveEditLabels: () => Promise<AsyncResult<void>>;
  onChangeLabels: (newValue: string[]) => void;
  onClickRebootNodesAllowed: (newValue: boolean) => void;
  onClickUpgradeNodepoolsAllowed: (newValue: boolean) => void;
  onNodePoolConcurrencyLevelChange: (newValue: number) => void;
  onClickDCUpdateMode: (newValue: string) => void;
  copiedConnectionKubeConfig: boolean;
  onClickGenerateConnectionKubeConfig: () => void;
  onCloseConnectionKubeConfigView: () => void;
  onClickMaintenanceMode: (newValue: boolean) => void;
  onClickCiliumHubble: (newValue: boolean) => void;
  canEditCoreDump: boolean;
  onClickCoreDump: (newEnabledValue: boolean) => void;

  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (finalizer: string) => void;
  processingRemoveFinalizer: boolean;
  controlplaneStatus?: ApiControlplaneStatus;
  apiCompatibility?: ApiAPICompatibility;
}

const DataClusterDetailsView = ({ ...args }: IDataClusterDetailsViewArgs) => {
  const finalizers = args.datacluster.finalizers || [];
  const annotations = args.datacluster.annotations || [];
  const annots = args.datacluster.annotations || [];
  const dcUpdateMode = annots.find((v: ApiKeyValuePair) => {
    return v.key == Common.dcupdate_mode_annotation_key;
  });
  const dcUpdateModeValue = (dcUpdateMode && dcUpdateMode.value) || "";
  const privateToOrganizations = args.datacluster.private_to_organizations || [];
  let dcUpdateModeDisplay = "";
  switch (dcUpdateModeValue) {
    case Common.dcupdate_mode_exclude_annotation_value:
      dcUpdateModeDisplay = "Excluded";
      break;
    case "":
      dcUpdateModeDisplay = "Healthy only";
      break;
    case Common.dcupdate_mode_force_annotation_value:
      dcUpdateModeDisplay = "Always (even Unhealthy)";
      break;
    case Common.dcupdate_mode_skip_version_update_annotation_value:
      dcUpdateModeDisplay = "Excluded from db version updates";
      break;
  }

  return (
    <div>
      {isControlPlane(args.datacluster.id) && (
        <>
          <Header sub>Control Plane Details</Header>
          <Field>
            <FLW>Environment</FLW>
            <FC>
              <b>{(args.controlplaneStatus || {}).environment || "-"}</b>
            </FC>
          </Field>
          <Field>
            <FLW>Git Branch</FLW>
            <FC>{(args.controlplaneStatus || {}).git_branch || "-"}</FC>
          </Field>
          <Divider hidden />
          <ControlplaneAPICompatView {...args} apiCompatibility={args.apiCompatibility || {}} />
          <Divider />
        </>
      )}
      <Header sub>General</Header>
      <Field>
        <FLW>Name</FLW>
        <FC>
          <div>{args.datacluster.id || "-"}</div>
        </FC>
      </Field>
      <Field>
        <FLW>Provider</FLW>
        <FC>
          {" "}
          <Location {...args} showProvider showRegion={false} regionId={args.datacluster.region_id} /> ({args.datacluster.provider_id || "-"})
        </FC>
      </Field>
      <Field>
        <FLW>Region</FLW>
        <FC>
          <Location {...args} showProvider={false} showRegion regionId={args.datacluster.region_id} /> ({args.datacluster.region_id || "-"})
        </FC>
      </Field>
      <Field>
        <FLW>Created</FLW>
        <FC>{args.datacluster.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.datacluster.created_at} label="Created at" /> : "-"}</FC>
      </Field>
      {privateToOrganizations.length > 0 && (
        <Field>
          <FLW>Private to Organization</FLW>
          <FC>
            {privateToOrganizations.map((orgID, i) => (
              <span key={`private_dc_${orgID}`}>
                <TextLink label={orgID} href={Routes.dashboard_sales_organization_detailsWithId(orgID)} />
                {i < privateToOrganizations.length - 1 && ", "}
              </span>
            ))}
          </FC>
        </Field>
      )}
      <Field>
        <FLW>Labels</FLW>
        <FC>
          <span>{args.datacluster.labels && !_.isEmpty(args.datacluster.labels) ? args.datacluster.labels.join(", ") : "-"} </span>
          {!args.editLabels && (
            <span>
              <Icon name="pencil" onClick={args.onClickEditLabels} className="edit-pencil" />
            </span>
          )}
          <DCLabelsEditModalView
            editLabels={args.editLabels}
            labels={args.labels}
            onCancelEditLabels={args.onCancelEditLabels}
            onSaveEditLabels={args.onSaveEditLabels}
            createComment={args.createComment}
            onChangeLabels={args.onChangeLabels}
          />
        </FC>
      </Field>
      <Field>
        <FLW>Finalizers</FLW>
        <FC>
          <Finalizers finalizers={finalizers} remove={args.canRemoveFinalizer ? args.onRemoveFinalizer : undefined} />
        </FC>
      </Field>
      <Field>
        <FLW>Annotations</FLW>
        <FC>
          <Annotations annotations={annotations} />
        </FC>
      </Field>
      <Field>
        <FLW>Connect to datacluster</FLW>
        <Button size="mini" icon={args.copiedConnectionKubeConfig ? "check" : "unlock"} onClick={args.onClickGenerateConnectionKubeConfig} />
        {args.copiedConnectionKubeConfig && (
          <Modal size="small" open>
            <Modal.Header>Your connection config is ready</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Segment>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <p>A kubeconfig file has been copied to your clipboard.</p>
                          <p>
                            Write it to a file and then run <strong>export KUBECONFIG=filename</strong>.
                          </p>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Segment>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={args.onCloseConnectionKubeConfigView}>Close</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Field>
      <Field>
        <FLW>Kube-config</FLW>
        <FC>
          {args.canGetKubeConfig ? <Button icon={args.copiedKubeConfig ? "check" : "copy"} size="mini" onClick={args.onCopyKubeConfig} /> : <span>-</span>}
        </FC>
      </Field>

      <Divider hidden />

      <Header sub>Special modes</Header>
      <Field>
        <FLW>Assignable</FLW>
        <FC>
          <span>{args.datacluster.is_unassignable ? "No" : "Yes"}</span>
          <Dropdown icon="pencil" inline className="icon tiny set-datacluster-unassignable">
            <Dropdown.Menu>
              {args.datacluster.is_unassignable && (
                <Dropdown.Item key="allow" text="Allow assignment of new deployments" onClick={() => args.onClickSetAssignable()} />
              )}
              {!args.datacluster.is_unassignable && (
                <Dropdown.Item key="prevent" text="Prevent assignment of new deployments" onClick={() => args.onClickSetUnassignable()} />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </FC>
      </Field>
      <Field>
        <FLW>Upgrade Nodepools allowed</FLW>
        <FC>
          <span>{args.datacluster.upgrade_nodepools_allowed ? "Yes" : "No"}</span>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {args.datacluster.upgrade_nodepools_allowed && (
                <Dropdown.Item key="prevent" text="Prevent upgrade of k8s nodepools" onClick={() => args.onClickUpgradeNodepoolsAllowed(false)} />
              )}
              {!args.datacluster.upgrade_nodepools_allowed && (
                <Dropdown.Item
                  key="allow"
                  text="Allow upgrade of k8s nodepools (by the data-cluster-operator-<provider>)"
                  onClick={() => args.onClickUpgradeNodepoolsAllowed(true)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </FC>
      </Field>
      <Field>
        <FLW>Nodepool update concurrency level</FLW>
        <FC>
          <span>{(args.datacluster.node_update_concurrency_level || 0) > 1 ? args.datacluster.node_update_concurrency_level : "Disabled"}</span>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              <Dropdown.Item key="0" text="Disable" onClick={() => args.onNodePoolConcurrencyLevelChange(0)} />
              <Dropdown.Item key="2" text="2" onClick={() => args.onNodePoolConcurrencyLevelChange(2)} />
              <Dropdown.Item key="3" text="3" onClick={() => args.onNodePoolConcurrencyLevelChange(3)} />
              <Dropdown.Item key="4" text="4" onClick={() => args.onNodePoolConcurrencyLevelChange(4)} />
              <Dropdown.Item key="5" text="5" onClick={() => args.onNodePoolConcurrencyLevelChange(5)} />
            </Dropdown.Menu>
          </Dropdown>
        </FC>
      </Field>
      <Field>
        <FLW>Node Reboot allowed</FLW>
        <FC>
          <span>{args.datacluster.reboot_nodes_allowed ? "Yes" : "No"}</span>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {args.datacluster.reboot_nodes_allowed && (
                <Dropdown.Item key="prevent" text="Prevent reboot of k8s nodes" onClick={() => args.onClickRebootNodesAllowed(false)} />
              )}
              {!args.datacluster.reboot_nodes_allowed && (
                <Dropdown.Item
                  key="allow"
                  text="Allow reboot of k8s nodes (by the data-cluster-provisioner)"
                  onClick={() => args.onClickRebootNodesAllowed(true)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </FC>
      </Field>
      <Field>
        <FLW>Cilium Hubble</FLW>
        <FC>
          <span>{args.datacluster.enable_cilium_hubble ? "Enabled" : "Disabled"}</span>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {args.datacluster.enable_cilium_hubble && (
                <Dropdown.Item key="disable" text="Disable Cilium Hubble" onClick={() => args.onClickCiliumHubble(false)} />
              )}
              {!args.datacluster.enable_cilium_hubble && (
                <Dropdown.Item key="enable" text="Enable Cilium Hubble" onClick={() => args.onClickCiliumHubble(true)} />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </FC>
      </Field>
      <Field>
        <FLW>Auto update mode</FLW>
        <FC>
          <span>{dcUpdateModeDisplay}</span>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {dcUpdateModeValue != Common.dcupdate_mode_exclude_annotation_value && (
                <Dropdown.Item
                  key="exclude"
                  text="Exclude this data cluster from automatic updates"
                  onClick={() => args.onClickDCUpdateMode(Common.dcupdate_mode_exclude_annotation_value)}
                />
              )}
              {dcUpdateModeValue != "" && (
                <Dropdown.Item
                  key="include"
                  text="Include this data cluster for automatic updates (when healty)"
                  onClick={() => args.onClickDCUpdateMode("")}
                />
              )}
              {dcUpdateModeValue != Common.dcupdate_mode_force_annotation_value && (
                <Dropdown.Item
                  key="force"
                  text="Include this data cluster for automatic updates (even when unhealty)"
                  onClick={() => args.onClickDCUpdateMode(Common.dcupdate_mode_force_annotation_value)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </FC>
      </Field>
      <Field>
        <FLW>In maintenance mode</FLW>
        <FC>
          <span>{args.datacluster.in_maintenance_mode ? "Yes" : "No"}</span>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {args.datacluster.in_maintenance_mode && (
                <Dropdown.Item key="off" text="Turn off maintance mode" onClick={() => args.onClickMaintenanceMode(false)} />
              )}
              {!args.datacluster.in_maintenance_mode && (
                <Dropdown.Item
                  key="on"
                  text="Switch on maintenance mode (so the data-cluster-operator-<provider> and data-cluster-provisioner will not update cluster)"
                  onClick={() => args.onClickMaintenanceMode(true)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </FC>
      </Field>
      <Field>
        <FLW>Uses Local SSDs</FLW>
        <FC>
          <span>{args.datacluster.uses_local_ssd ? "Yes" : "No"}</span>
        </FC>
      </Field>
      <Field>
        <FLW>Core Dump</FLW>
        <FC>
          <span>{args.datacluster.is_core_dump_enabled ? "Enabled" : "Disabled"}</span>
          {args.canEditCoreDump && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.datacluster.is_core_dump_enabled && <Dropdown.Item key="disable" text="Disable Core Dump" onClick={() => args.onClickCoreDump(false)} />}
                {!args.datacluster.is_core_dump_enabled && <Dropdown.Item key="enable" text="Enable Core Dump" onClick={() => args.onClickCoreDump(true)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
    </div>
  );
};

interface IDataClusterStatusViewArgs {
  datacluster: ApiDataCluster;
  status: ApiDataCluster_Status;
}

const DataClusterStatusView = ({ ...args }: IDataClusterStatusViewArgs) => (
  <div>
    <Header sub>Data Cluster Status</Header>
    <Field>
      <FLW>Last data-cluster-provisioner ping</FLW>
      <FC>
        {args.status.data_cluster_provision_last_ping_at ? (
          <DateTimePopupWithUTCAndLocalTime dateTime={args.status.data_cluster_provision_last_ping_at} label="Last data-cluster-provisioner ping at" />
        ) : (
          "-"
        )}
        <br />
        Dataclusterd: {args.status.data_cluster_provisioner_found_compatible_dataclusterd ? "Compatible" : "Incompatible"}
      </FC>
    </Field>
    <Field>
      <FLW>Last data-manager ping</FLW>
      <FC>
        {args.status.data_manager_last_ping_at ? (
          <DateTimePopupWithUTCAndLocalTime dateTime={args.status.data_manager_last_ping_at} label="Last data-manager ping at" />
        ) : (
          "-"
        )}
        <br />
        Dataclusterd: {args.status.data_manager_found_compatible_dataclusterd ? "Compatible" : "Incompatible"}
      </FC>
    </Field>
    <Field>
      <FLW>Phase</FLW>
      <FC>{args.status.phase}</FC>
    </Field>
    <Field>
      <FLW>Message</FLW>
      <FC>{args.status.message}</FC>
    </Field>
    <Field>
      <FLW>Running</FLW>
      <FC>{args.status.is_running ? "Yes" : "No"}</FC>
    </Field>
    <Field>
      <FLW>Upgrading Master</FLW>
      <FC>{args.status.is_upgrading ? "Yes" : "No"}</FC>
    </Field>
    <Field>
      <FLW>Upgrading Nodepool</FLW>
      <FC>{args.status.is_nodepool_upgrading ? "Yes" : "No"}</FC>
    </Field>
    <Field>
      <FLW>Failing</FLW>
      <FC>{args.status.is_failing ? "Yes" : "No"}</FC>
    </Field>
    <Field>
      <FLW>Unavailable</FLW>
      <FC>{args.status.is_unavailable ? "Yes" : "No"}</FC>
    </Field>
    <Field>
      <FLW>Is Node Reboot Running</FLW>
      <FC>{args.status.is_reboot_nodes_running ? "Yes" : "No"}</FC>
    </Field>
    <Field>
      <FLW>Deleted</FLW>
      <FC>{args.datacluster.deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.datacluster.deleted_at} label="Deleted at" /> : "-"}</FC>
    </Field>
  </div>
);

interface IProvisionInfoViewArgs {
  provisionInfo: ApiDataClusterProvisionInfo;
  oldProvisionInfo?: ApiDataClusterProvisionInfo;
  onClick?: () => void;
  canEdit?: boolean;
  title: string;
}

const ProvisionInfoView = ({ ...args }: IProvisionInfoViewArgs) => {
  if (!args.provisionInfo.provision_info_hash) {
    return <span>-</span>;
  }
  const canEdit = !!args.canEdit;
  const canDiff = !!args.oldProvisionInfo && !!args.oldProvisionInfo.provision_info_hash;
  const oldValue = canDiff && JSON.stringify(args.oldProvisionInfo, null, 4);
  const newValue = canDiff && JSON.stringify(args.provisionInfo, null, 4);
  const shouldDiff = canDiff && oldValue != newValue;

  return (
    <span>
      <Modal size={canDiff ? "fullscreen" : "large"} trigger={<u>{args.provisionInfo.provision_info_hash || "-"}</u>}>
        <Modal.Header>{args.title}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {shouldDiff && <ReactDiffViewer splitView={false} oldValue={oldValue || ""} newValue={newValue || ""} compareMethod={DiffMethod.WORDS} />}
            {!shouldDiff && <ReactJson src={args.provisionInfo} collapsed={1} />}
          </Modal.Description>
        </Modal.Content>
      </Modal>
      {args.onClick && canEdit && args.provisionInfo.provision_info_hash && (
        <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
          <Dropdown.Menu>
            <Dropdown.Item text="Make Target" onClick={() => args.onClick && args.onClick()} />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </span>
  );
};

interface IProvisionInfoStatusViewArgs {
  datacluster: ApiDataCluster;
}

const ProvisionInfoStatusView = ({ datacluster }: IProvisionInfoStatusViewArgs) => {
  const status = DataClusterHelper.getProvisionInfoStatus(datacluster);
  return <span>{status.message}</span>;
};

interface IDataClusterProvisionInfoViewArgs {
  datacluster: ApiDataCluster;
  onClickUpdateTargetProvisionInfo: (latest: boolean) => void;
}

const DataClusterProvisionInfoView = ({ ...args }: IDataClusterProvisionInfoViewArgs) => {
  const target_provision_info = args.datacluster.target_provision_info || {};
  const latest_provision_info = args.datacluster.latest_provision_info || {};
  const previous_target_provision_info = args.datacluster.previous_target_provision_info || {};
  const last_target_provision_info_changed_at = args.datacluster.last_target_provision_info_changed_at;
  const last_provision_info_hash_changed_at = args.datacluster.status && args.datacluster.status.last_provision_info_hash_changed_at;
  const duration =
    last_target_provision_info_changed_at && last_provision_info_hash_changed_at && last_target_provision_info_changed_at <= last_provision_info_hash_changed_at
      ? moment.duration(moment(last_provision_info_hash_changed_at).diff(last_target_provision_info_changed_at)).humanize()
      : "-";
  return (
    <div>
      <Header sub>Provision Info</Header>
      <Field>
        <FLW>Status</FLW>
        <FC>
          <ProvisionInfoStatusView {...args} />
        </FC>
      </Field>
      <Field>
        <FLW>Actual</FLW>
        <FC>
          {(args.datacluster.status && args.datacluster.status.provision_info_hash) || "-"}
          <br />
          Updated:{" "}
          {last_provision_info_hash_changed_at ? <DateTimePopupWithUTCAndLocalTime dateTime={last_provision_info_hash_changed_at} label="Updated at" /> : "-"}
          <br />
          Duration: {duration}
        </FC>
      </Field>
      <Field>
        <FLW>Target</FLW>
        <FC>
          <ProvisionInfoView provisionInfo={target_provision_info} title="Target provision info" />
          <br />
          Updated:{" "}
          {last_target_provision_info_changed_at ? (
            <DateTimePopupWithUTCAndLocalTime dateTime={last_target_provision_info_changed_at} label="Updated at" />
          ) : (
            "-"
          )}
        </FC>
      </Field>
      <Field>
        <FLW>Latest</FLW>
        <FC>
          <ProvisionInfoView
            provisionInfo={latest_provision_info}
            oldProvisionInfo={target_provision_info}
            onClick={() => args.onClickUpdateTargetProvisionInfo(true)}
            canEdit={latest_provision_info.provision_info_hash != target_provision_info.provision_info_hash}
            title="Latest available provision info"
          />
        </FC>
      </Field>
      <Field>
        <FLW>Previous target</FLW>
        <FC>
          <ProvisionInfoView
            provisionInfo={previous_target_provision_info}
            oldProvisionInfo={target_provision_info}
            onClick={() => args.onClickUpdateTargetProvisionInfo(false)}
            canEdit={previous_target_provision_info.provision_info_hash != target_provision_info.provision_info_hash}
            title="Previous target provision info"
          />
        </FC>
      </Field>
    </div>
  );
};

interface IDataClusterVersionsViewArgs {
  dataclusterID: string;
  controlplaneStatus?: ApiControlplaneStatus;
  status: ApiDataCluster_Status;
}

const DataClusterVersionsView = ({ ...args }: IDataClusterVersionsViewArgs) => (
  <div>
    <Header sub>Component versions</Header>
    <Field>
      <FLW>data-cluster-provisioner</FLW>
      <FC>{args.status.data_cluster_provision_version || "-"}</FC>
    </Field>
    <Field>
      <FLW>data-manager</FLW>
      <FC>{args.status.data_manager_version || "-"}</FC>
    </Field>
    <Field>
      <FLW>route-manager</FLW>
      <FC>{args.status.route_manager_version || "-"}</FC>
    </Field>
    <Field>
      <FLW>health-manager</FLW>
      <FC>{args.status.health_manager_version || "-"}</FC>
    </Field>
    <Field>
      <FLW>bucket-manager</FLW>
      <FC>{args.status.bucket_manager_version || "-"}</FC>
    </Field>
    <Field>
      <FLW>metrics-server</FLW>
      <FC>{args.status.metrics_server_version || "-"}</FC>
    </Field>
    <Field>
      <FLW>prometheus</FLW>
      <FC>{(args.status.metrics_info && args.status.metrics_info.prometheus_version) || "-"}</FC>
    </Field>
    <Field>
      <FLW>alertmanager</FLW>
      <FC>{(args.status.metrics_info && args.status.metrics_info.alertmanager_version) || "-"}</FC>
    </Field>
    <Field>
      <FLW>loki</FLW>
      <FC>{(args.status.metrics_info && args.status.metrics_info.loki_version) || "-"}</FC>
    </Field>
    <Field>
      <FLW>promtail</FLW>
      <FC>{(args.status.metrics_info && args.status.metrics_info.promtail_version) || "-"}</FC>
    </Field>
    {isControlPlane(args.dataclusterID) && (
      <Field>
        <FLW>Grafana</FLW>
        <FC>
          {(args.controlplaneStatus &&
            args.controlplaneStatus.metrics_info &&
            args.controlplaneStatus.metrics_info.grafana_info &&
            args.controlplaneStatus.metrics_info.grafana_info.version) ||
            "-"}
        </FC>
      </Field>
    )}
  </div>
);

interface IDataClusterInfoViewArgs extends CommentCreationArgs {
  datacluster: ApiDataCluster;
  controlplaneStatus?: ApiControlplaneStatus;
  info?: ApiMetricsInfo;

  canEditVolumeInfos: boolean;
  onSaveVolumeInfos: (
    newNatsStreamingVolumeInfo?: ApiVolumeInfo,
    newNatsJetStreamVolumeInfo?: ApiVolumeInfo,
    newLokiVolumeInfo?: ApiVolumeInfo
  ) => Promise<{ errorMessage: string }>;

  onEditNatsStreamingVolumeInfos: () => void;
  onCancelEditNatsStreamingVolumeInfos: () => void;
  editNatsStreamingVolumeInfos: boolean;

  onEditNatsJetstreamVolumeInfos: () => void;
  onCancelEditNatsJetstreamVolumeInfos: () => void;
  editNatsJetstreamVolumeInfos: boolean;

  onEditLokiVolumeInfos: () => void;
  onCancelEditLokiVolumeInfos: () => void;
  editLokiVolumeInfos: boolean;

  processingUpdateVolumeInfos: boolean;

  canEditDiskSize: boolean;

  onSaveNatsStreamingDiskSize: (newValue: string) => Promise<{ errorMessage: string }>;
  onEditNatsStreamingDiskSize: () => void;
  onCancelEditNatsStreamingDiskSize: () => void;
  editNatsStreamingDiskSize: boolean;

  onSaveNatsJetstreamDiskSize: (newValue: string) => Promise<{ errorMessage: string }>;
  onEditNatsJetstreamDiskSize: () => void;
  onCancelEditNatsJetstreamDiskSize: () => void;
  editNatsJetstreamDiskSize: boolean;

  onSaveLokiDiskSize: (newValue: string) => Promise<{ errorMessage: string }>;
  onEditLokiDiskSize: () => void;
  onCancelEditLokiDiskSize: () => void;
  editLokiDiskSize: boolean;

  processingUpdateDiskSize: boolean;
  canScaleNodePool: boolean;
}

const DataClusterInfoView = ({ ...args }: IDataClusterInfoViewArgs) => {
  const dataclusterID = args.datacluster.id;
  const natsStreamingVolumeInfo = args.datacluster.nats_streaming_volume_info || {};
  const natsStreamingDiskSize = args.datacluster.nats_streaming_disk_size || "";
  const natsJetstreamVolumeInfo = args.datacluster.nats_jetstream_volume_info || {};
  const natsJetstreamDiskSize = args.datacluster.nats_jetstream_disk_size || "";
  const lokiVolumeInfo = args.datacluster.loki_volume_info || {};
  const lokiDiskSize = args.datacluster.loki_disk_size || "";
  const onSaveNatsStreamingVolumeInfo = (newVolumeType: string, newVolumeIOPS: number, newVolumeThroughput: number) => {
    const newNatsStreamingVolumeInfo: ApiVolumeInfo = {
      type: newVolumeType,
      iops: newVolumeIOPS,
      throughput: newVolumeThroughput,
    };
    return args.onSaveVolumeInfos(newNatsStreamingVolumeInfo, undefined, undefined);
  };
  const onSaveNatsJetstreamVolumeInfo = (newVolumeType: string, newVolumeIOPS: number, newVolumeThroughput: number) => {
    const newNatsJetstreamVolumeInfo: ApiVolumeInfo = {
      type: newVolumeType,
      iops: newVolumeIOPS,
      throughput: newVolumeThroughput,
    };
    return args.onSaveVolumeInfos(undefined, newNatsJetstreamVolumeInfo, undefined);
  };
  const onSaveLokiVolumeInfo = (newVolumeType: string, newVolumeIOPS: number, newVolumeThroughput: number) => {
    const newLokiVolumeInfo: ApiVolumeInfo = {
      type: newVolumeType,
      iops: newVolumeIOPS,
      throughput: newVolumeThroughput,
    };
    return args.onSaveVolumeInfos(undefined, undefined, newLokiVolumeInfo);
  };
  return (
    <div>
      <Header sub>Loki info</Header>
      <Field>
        <FLW>Log lines received (since restart)</FLW>
        <FC>{args.info ? numberFormat(args.info.log_lines_total || 0, 0) : "-"}</FC>
      </Field>
      <Field>
        <FLW>Data Volume</FLW>
        <FC>
          <DataVolumeView data_volume_info={args.info && args.info.loki_data_volume_info} />
        </FC>
      </Field>
      <Field>
        <FLW>Volume Size</FLW>
        <FC>
          <ConfigurationDiskSizeView
            title="Loki"
            currentDiskSize={lokiDiskSize}
            canEdit={args.canEditDiskSize}
            onEdit={args.onEditLokiDiskSize}
            onCancelEdit={args.onCancelEditLokiDiskSize}
            onSaveEdit={args.onSaveLokiDiskSize}
            editing={args.editLokiDiskSize}
            processingUpdate={args.processingUpdateDiskSize}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      <Field>
        <FLW>Volume Params</FLW>
        <FC>
          <VolumeInfoView
            title="Loki"
            volumeType={lokiVolumeInfo.type}
            volumeIOPS={lokiVolumeInfo.iops}
            volumeThroughput={lokiVolumeInfo.throughput}
            canEdit={args.canEditVolumeInfos}
            onEdit={args.onEditLokiVolumeInfos}
            editing={args.editLokiVolumeInfos}
            onCancelEdit={args.onCancelEditLokiVolumeInfos}
            onSaveEdit={onSaveLokiVolumeInfo}
            processingUpdate={args.processingUpdateVolumeInfos}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      {isControlPlane(dataclusterID) && (
        <>
          <Header sub>Grafana info</Header>
          <Field>
            <FLW>Number of dashboards</FLW>
            <FC>
              {(args.controlplaneStatus &&
                args.controlplaneStatus.metrics_info &&
                args.controlplaneStatus.metrics_info.grafana_info &&
                args.controlplaneStatus.metrics_info.grafana_info.dashboards_installed) ||
                "-"}
            </FC>
          </Field>
          <Field>
            <FLW>Data Volume</FLW>
            <FC>
              <DataVolumeView
                data_volume_info={
                  args.controlplaneStatus &&
                  args.controlplaneStatus.metrics_info &&
                  args.controlplaneStatus.metrics_info.grafana_info &&
                  args.controlplaneStatus.metrics_info.grafana_info.data_volume_info
                }
              />
            </FC>
          </Field>
          <Field>
            <FLW>Logs Volume</FLW>
            <FC>
              <DataVolumeView
                data_volume_info={
                  args.controlplaneStatus &&
                  args.controlplaneStatus.metrics_info &&
                  args.controlplaneStatus.metrics_info.grafana_info &&
                  args.controlplaneStatus.metrics_info.grafana_info.logs_volume_info
                }
              />
            </FC>
          </Field>
        </>
      )}
      <Header sub>Nats info</Header>
      <Field>
        <FLW>Nats Streaming Volume</FLW>
        <FC>
          <DataVolumeView data_volume_info={args.info && args.info.nats_data_volume_info} />
        </FC>
      </Field>
      <Field>
        <FLW>Nats Streaming Volume Size</FLW>
        <FC>
          <ConfigurationDiskSizeView
            title="NATS Streaming"
            currentDiskSize={natsStreamingDiskSize}
            canEdit={args.canEditDiskSize}
            onEdit={args.onEditNatsStreamingDiskSize}
            onCancelEdit={args.onCancelEditNatsStreamingDiskSize}
            onSaveEdit={args.onSaveNatsStreamingDiskSize}
            editing={args.editNatsStreamingDiskSize}
            processingUpdate={args.processingUpdateDiskSize}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      <Field>
        <FLW>Nats Streaming Volume Params</FLW>
        <FC>
          <VolumeInfoView
            title="NATS Streaming"
            volumeType={natsStreamingVolumeInfo.type}
            volumeIOPS={natsStreamingVolumeInfo.iops}
            volumeThroughput={natsStreamingVolumeInfo.throughput}
            canEdit={args.canEditVolumeInfos}
            onEdit={args.onEditNatsStreamingVolumeInfos}
            editing={args.editNatsStreamingVolumeInfos}
            onCancelEdit={args.onCancelEditNatsStreamingVolumeInfos}
            onSaveEdit={onSaveNatsStreamingVolumeInfo}
            processingUpdate={args.processingUpdateVolumeInfos}
            createComment={args.createComment}
          />
        </FC>
      </Field>
      {args.info && args.info.nats_jetstream_data_volume_info && (
        <div>
          <Field>
            <FLW>Jet Stream Volumes</FLW>
            <FC>
              {args.info.nats_jetstream_data_volume_info.map((volume) => (
                <div key={volume.deployment_id}>
                  <div>
                    <b>{volume.deployment_id}:</b>
                  </div>
                  <DataVolumeView data_volume_info={volume.volume_info} />
                </div>
              ))}
            </FC>
          </Field>
          <Field>
            <FLW>Jet Stream Volume Size</FLW>
            <FC>
              <ConfigurationDiskSizeView
                title="NATS JetSteam"
                currentDiskSize={natsJetstreamDiskSize}
                canEdit={args.canEditDiskSize}
                onEdit={args.onEditNatsJetstreamDiskSize}
                onCancelEdit={args.onCancelEditNatsJetstreamDiskSize}
                onSaveEdit={args.onSaveNatsJetstreamDiskSize}
                editing={args.editNatsJetstreamDiskSize}
                processingUpdate={args.processingUpdateDiskSize}
                createComment={args.createComment}
              />
            </FC>
          </Field>
          <Field>
            <FLW>Jet Stream Volumes Params</FLW>
            <FC>
              <VolumeInfoView
                title="NATS JetStream"
                volumeType={natsJetstreamVolumeInfo.type}
                volumeIOPS={natsJetstreamVolumeInfo.iops}
                volumeThroughput={natsJetstreamVolumeInfo.throughput}
                canEdit={args.canEditVolumeInfos}
                onEdit={args.onEditNatsJetstreamVolumeInfos}
                editing={args.editNatsJetstreamVolumeInfos}
                onCancelEdit={args.onCancelEditNatsJetstreamVolumeInfos}
                onSaveEdit={onSaveNatsJetstreamVolumeInfo}
                processingUpdate={args.processingUpdateVolumeInfos}
                createComment={args.createComment}
              />
            </FC>
          </Field>
        </div>
      )}
    </div>
  );
};
// Interface decribing the properties of the datacluster details component
interface IDataClusterDetailsProps extends IWithRefreshProps, RouteComponentProps {
  datacluster_id: string;
  canViewAlerts: boolean;
  onDataClusterSelected: (id: string) => void;
}

// Interface decribing the state of the datacluster details component
interface IDataClusterDetailsState extends ICommentsStateForIntegration {
  errorMessage?: string;
  processing: boolean;
  datacluster?: ApiDataCluster;
  copiedKubeConfig: boolean;
  copiedDataClusterID: boolean;
  processingUpdateTargetProvisionInfo: boolean;
  processingGenerateConnectionKubeConfig: boolean;
  editLabels: boolean;
  labels?: string[];
  deploymentListVersion: number;
  updateDataClusterConfirm?: ConfirmInfo;
  copiedConnectionKubeConfig: boolean;
  connectKubeConfig?: string;
  processingRemoveFinalizer: boolean;
  commentPrompt: ICommentPromptStateForIntegrations;
  controlplaneStatus?: ApiControlplaneStatus;
  apiCompatibility?: ApiAPICompatibility;
  prometheusMemory: string;
  editPrometheusMemory: boolean;
  processingUpdatePrometheusMemory: boolean;
  processingUpdateVolumeInfos: boolean;
  editNatsStreamingVolumeInfos: boolean;
  editNatsJetstreamVolumeInfos: boolean;
  editLokiVolumeInfos: boolean;
  editPrometheusVolumeInfos: boolean;
  processingUpdateDiskSize: boolean;
  editNatsStreamingDiskSize: boolean;
  editNatsJetstreamDiskSize: boolean;
  editLokiDiskSize: boolean;
  editPrometheusDiskSize: boolean;
}

class DataClusterDetails extends Component<IDataClusterDetailsProps, IDataClusterDetailsState> {
  state: IDataClusterDetailsState = {
    errorMessage: undefined,
    processing: false,
    datacluster: undefined,
    copiedKubeConfig: false,
    editLabels: false,
    deploymentListVersion: 0,
    copiedConnectionKubeConfig: false,
    connectKubeConfig: undefined,
    processingUpdateTargetProvisionInfo: false,
    processingGenerateConnectionKubeConfig: false,
    copiedDataClusterID: false,
    processingRemoveFinalizer: false,
    editPrometheusMemory: false,
    prometheusMemory: "",
    processingUpdatePrometheusMemory: false,
    processingUpdateVolumeInfos: false,
    editNatsStreamingVolumeInfos: false,
    editNatsJetstreamVolumeInfos: false,
    editLokiVolumeInfos: false,
    editPrometheusVolumeInfos: false,
    processingUpdateDiskSize: false,
    editNatsStreamingDiskSize: false,
    editNatsJetstreamDiskSize: false,
    editLokiDiskSize: false,
    editPrometheusDiskSize: false,

    commentList: {},
    commentsLoading: false,
    commentsPageOptions: {
      page: 0,
      pageSize: 10,
    },
    commentsFetchError: undefined,
    commentCreationInProcess: false,
    latestComments: {},
    lastestCommentsTriggered: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  getTabConfig = (): ITabConfig[] => {
    const canEditVolumeInfos = hasSupportPermission("internal-dashboard.datacluster.update-volume-infos", this.props.hasPermissionByUrl);
    const canEditDiskSizes = hasSupportPermission("internal-dashboard.datacluster.update-disk-sizes", this.props.hasPermissionByUrl);
    const canEditPrometheusMemory = hasSupportPermission("internal-dashboard.datacluster.manage", this.props.hasPermissionByUrl);
    const canScaleNodePools = hasSupportPermission("internal-dashboard.nodepool.scale", this.props.hasPermissionByUrl);
    const canStartOptimization = hasSupportPermission("internal-dashboard.datacluster.start-optimization", this.props.hasPermissionByUrl);
    const canStopOptimization = hasSupportPermission("internal-dashboard.datacluster.stop-optimization", this.props.hasPermissionByUrl);
    const canGetOptimizationStatus = hasSupportPermission("internal-dashboard.datacluster.get-optimization-status", this.props.hasPermissionByUrl);
    const datacluster = this.state.datacluster || {};
    const panes: ITabConfig[] = [
      {
        menuItem: { children: "Details", id: "detailsTab", route: "dataclusterDetails" },
        content: () => (
          <Segment>
            {" "}
            <Grid columns="3" stackable>
              <Grid.Column>
                <DataClusterStatusView {...this.state} datacluster={datacluster} status={datacluster.status || {}} />
              </Grid.Column>
              <Grid.Column>
                <PodStatisticsView {...this.state} stats={(datacluster.status || {}).pod_statistics} />
              </Grid.Column>
              <Grid.Column>
                {this.props.canViewAlerts && (
                  <MetricsInfoView
                    {...this.state}
                    gotoUrl={this.gotoUrl}
                    info={(datacluster.status || {}).metrics_info}
                    showDeploymentID
                    datacluster={datacluster}
                    canEditVolumeInfos={canEditVolumeInfos}
                    onSavePrometheusVolumeInfo={this.onSavePrometheusVolumeInfo}
                    onEditPrometheusVolumeInfos={this.onEditPrometheusVolumeInfos}
                    onCancelEditPrometheusVolumeInfos={this.onCancelEditPrometheusVolumeInfos}
                    canEditDiskSize={canEditDiskSizes}
                    onSavePrometheusDiskSize={this.onSavePrometheusDiskSize}
                    onEditPrometheusDiskSize={this.onEditPrometheusDiskSize}
                    onCancelEditPrometheusDiskSize={this.onCancelEditPrometheusDiskSize}
                    canEditPrometheusMemory={canEditPrometheusMemory}
                    customPrometheusMemory={datacluster.prometheus_memory_size_limit || ""}
                    onClickEditPrometheusMemory={this.onClickEditPrometheusMemory}
                    onClickCancelEditPrometheusMemory={this.onClickCancelEditPrometheusMemory}
                    onChangePrometheusMemory={this.onChangePrometheusMemory}
                    createComment={this.createComment}
                  />
                )}
                <br />
                <DataClusterInfoView
                  {...this.state}
                  datacluster={this.state.datacluster || {}}
                  controlplaneStatus={this.state.controlplaneStatus}
                  info={(datacluster.status || {}).metrics_info}
                  canEditVolumeInfos={canEditVolumeInfos}
                  onSaveVolumeInfos={this.onSaveVolumeInfos}
                  onEditNatsStreamingVolumeInfos={this.onEditNatsStreamingVolumeInfos}
                  onEditNatsJetstreamVolumeInfos={this.onEditNatsJetstreamVolumeInfos}
                  onEditLokiVolumeInfos={this.onEditLokiVolumeInfos}
                  onCancelEditNatsStreamingVolumeInfos={this.onCancelEditNatsStreamingVolumeInfos}
                  onCancelEditNatsJetstreamVolumeInfos={this.onCancelEditNatsJetstreamVolumeInfos}
                  onCancelEditLokiVolumeInfos={this.onCancelEditLokiVolumeInfos}
                  canEditDiskSize={canEditDiskSizes}
                  onSaveNatsStreamingDiskSize={(newValue: string) => this.onSaveDiskSize(newValue, undefined, undefined)}
                  onEditNatsStreamingDiskSize={this.onEditNatsStreamingDiskSize}
                  onCancelEditNatsStreamingDiskSize={this.onCancelEditNatsStreamingDiskSize}
                  onSaveNatsJetstreamDiskSize={(newValue: string) => this.onSaveDiskSize(undefined, newValue, undefined)}
                  onEditNatsJetstreamDiskSize={this.onEditNatsJetstreamDiskSize}
                  onCancelEditNatsJetstreamDiskSize={this.onCancelEditNatsJetstreamDiskSize}
                  onSaveLokiDiskSize={(newValue: string) => this.onSaveDiskSize(undefined, undefined, newValue)}
                  onEditLokiDiskSize={this.onEditLokiDiskSize}
                  onCancelEditLokiDiskSize={this.onCancelEditLokiDiskSize}
                  createComment={this.createComment}
                  canScaleNodePool={canScaleNodePools}
                />
              </Grid.Column>
            </Grid>
          </Segment>
        ),
      },
      {
        menuItem: { children: "Nodes", id: "nodesTab", route: "dataclusterNodes" },
        content: () => <NodesView {...this.props} nodes={(datacluster.status || {}).nodes} />,
      },
      {
        menuItem: { children: "Node pools", id: "nodePoolsTab", route: "dataclusterNodePools" },
        content: () => <NodePoolList {...this.props} datacluster={datacluster} canScale={canScaleNodePools} />,
      },
      {
        menuItem: { children: "Load balancers", id: "nodeLoadBalancersTab", route: "dataclusterLoadBalancers" },
        content: () => <LoadBalancerList {...this.props} datacluster={datacluster} createComment={this.createComment} />,
      },
      {
        menuItem: { children: "Deployments", id: "nodeDeploymentsTab", route: "dataclusterDeployments" },
        content: () => (
          <DeploymentList
            {...this.props}
            dataClusterId={this.props.datacluster_id}
            onDeploymentSelected={(id: string) => {
              this.props.history.push(Routes.dashboard_support_deployment_detailsWithId(id));
            }}
            onOrganizationSelected={(id: string) => {
              this.props.history.push(Routes.dashboard_sales_organization_detailsWithId(id));
            }}
            onProjectSelected={(id: string) => {
              this.props.history.push(Routes.dashboard_sales_project_detailsWithId(id));
            }}
          />
        ),
      },
      {
        menuItem: { children: "Core dumps", id: "coreDumpsTab", route: "coreDumps" },
        content: () => <CoreDumpList {...this.props} datacluster_id={datacluster.id} showHeader={false} />,
      },
    ];
    if (canGetOptimizationStatus) {
      panes.push({
        menuItem: { children: "Optimization status", id: "optimizationStatus", route: "optimizationStatus" },
        content: () => (
          <DataClusterOptimizationStatus datacluster={datacluster} can_start_optimization={canStartOptimization} can_stop_optimization={canStopOptimization} />
        ),
      });
    }
    return panes;
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  getComments = async (options: ApiListOptions) => {
    const canListComments = hasSupportPermission("internal-dashboard.comment.list", this.props.hasPermissionByUrl);
    if (!canListComments) return;

    if (this.state.commentCreationInProcess) {
      return;
    }

    const { datacluster = {} } = this.state;
    const { url = "" } = datacluster;

    if (!url) {
      return;
    }

    const req: ApiListCommentsRequest = {
      resource_url: url,
      options,
    };
    try {
      const commentList = (await apiClients.idashboardClient.ListComments(req)) || {};
      this.setState({ commentsLoading: false, commentsFetchError: undefined });
      return commentList;
    } catch (err) {
      this.setState({ commentsFetchError: err, commentsLoading: false });
    }
  };

  reloadLatestComments = async () => {
    const latestComments = await this.getComments({ page: 0, page_size: 10 });
    if (!!latestComments) {
      if (!this.state.lastestCommentsTriggered) {
        this.setState({ commentList: latestComments });
      }
      this.setState({ latestComments, lastestCommentsTriggered: true });
    }
  };

  reloadComments = async () => {
    const { page, pageSize } = this.state.commentsPageOptions;

    const commentList = await this.getComments({ page, page_size: pageSize });
    if (!!commentList) {
      this.setState({ commentList });
    }
  };

  createComment = async (comment: string): Promise<AsyncResult<void>> => {
    let error = "";
    this.setState({ commentCreationInProcess: true });
    const { page, pageSize } = this.state.commentsPageOptions;
    const { datacluster = {} } = this.state;
    const req: ApiCreateCommentRequest = {
      resource_url: datacluster.url,
      comment,
    };
    try {
      const comment = await apiClients.idashboardClient.CreateComment(req);
      const { items = [] } = this.state.commentList;
      const newCommentList: ApiCommentList = {
        items: [comment, ...slice(items, 0, pageSize - 1)],
      };
      this.setState(
        {
          commentsFetchError: undefined,
          commentCreationInProcess: false,
          commentList: page > 0 ? this.state.commentList : newCommentList,
          latestComments: {
            items: [comment, ...slice(this.state.latestComments.items, 0, pageSize - 1)],
          },
        },
        this.reloadComments
      );
    } catch (err) {
      this.setState({ commentsFetchError: err, commentCreationInProcess: false });
      error = err;
    }
    return { error };
  };

  onCommentsPageChange = (page: number, pageSize: number) => {
    this.setState({ commentsPageOptions: { page, pageSize } }, this.reloadComments);
  };

  reloadDataClusterInfo = async () => {
    const idOptions: ApiIDOptions = { id: this.props.datacluster_id };
    var datacluster = await apiClients.idashboardClient.GetDataCluster(idOptions);
    if (isEmpty(this.state.datacluster)) {
      // Subscribe for the first time
      this.props.subscribeUrl &&
        this.props.subscribeUrl(async () => {
          this.reloadDataClusterInfo();
          this.reloadLatestComments();
        }, datacluster.url);
    }
    this.setState((old) => {
      return {
        datacluster: datacluster,
        deploymentListVersion: old.deploymentListVersion + 1,
      };
    });
  };

  refreshDataClusterInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDataClusterInfo);
  };

  reloadControlplaneStatus = async () => {
    const controlplaneStatus = await apiClients.idashboardClient.GetControlplaneStatus();
    this.setState({ controlplaneStatus: controlplaneStatus });
  };

  reloadApiCompatibility = async () => {
    const req: ApiCompatibilityRequest = {};
    const apiCompatibility = await apiClients.idashboardClient.GetAPICompatibility(req);
    this.setState({ apiCompatibility: apiCompatibility });
  };

  componentDidMount() {
    this.reloadDataClusterInfo();
    if (isControlPlane(this.props.datacluster_id)) {
      this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadControlplaneStatus, 10000); // Every 10 sec
      this.props.subscribeUrl && this.props.subscribeUrl(this.reloadApiCompatibility, "/Organization/_system/APICompat");
    }
  }

  onClickChangeUnassignable = async (value: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set unassignable to ${value ? "true" : "false"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing assignable state, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetDataClusterUnassignableRequest = {
            datacluster_id: this.props.datacluster_id,
            is_unassignable: value,
          };
          await apiClients.idashboardClient.SetDataClusterUnassignable(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onClickEditLabels = () => {
    this.setState({ editLabels: true, labels: this.state.datacluster && this.state.datacluster.labels });
  };

  onChangeLabels = (newValue: string[]) => {
    this.setState({ labels: newValue });
  };

  onClickCancelEditLabels = () => {
    this.setState({ editLabels: false, labels: undefined });
  };

  onClickSaveEditLabels = async (): Promise<AsyncResult<void>> => {
    let error = "";
    try {
      const req: ApiSetDataClusterLabelsRequest = {
        datacluster_id: this.props.datacluster_id,
        labels: this.state.labels,
      };
      await apiClients.idashboardClient.SetDataClusterLabels(req);
      this.refreshDataClusterInfo();
    } catch (err) {
      error = err;
    }
    return { error };
  };

  onClickUpdateTargetProvisionInfo = (latest: boolean) => {
    const datacluster = this.state.datacluster || {};
    const labels = datacluster.labels || [];
    if (_.find(labels, (x) => x.indexOf("LTS") >= 0)) {
      // Confirm first
      const confirmInfo: ConfirmInfo = {
        header: "Update datacluster",
        content: `Do you want to update this LTS datacluster?`,
        warning: "This is only acceptable during QA testing!",
        onConfirm: async () => {
          this.setState({
            updateDataClusterConfirm: undefined,
          });
          this.onUpdateTargetProvisionInfo(latest);
        },
        onDenied: () => {
          this.setState({ updateDataClusterConfirm: undefined });
        },
      };
      this.setState({
        updateDataClusterConfirm: confirmInfo,
      });
    } else {
      // Call update directly
      this.onUpdateTargetProvisionInfo(latest);
    }
  };

  onUpdateTargetProvisionInfo = async (latest: boolean) => {
    try {
      this.setState({ processingUpdateTargetProvisionInfo: true, errorMessage: undefined });
      const req = _.cloneDeep(this.state.datacluster);
      if (!req) {
        throw "DataCluster not loaded";
      }
      if (latest) {
        req.target_provision_info = req.latest_provision_info;
      } else {
        req.target_provision_info = req.previous_target_provision_info;
      }
      await apiClients.idashboardClient.UpdateDataClusterTargetProvisionInfo(req);
      const result = await apiClients.idashboardClient.GetDataCluster({ id: this.props.datacluster_id });
      this.setState({ datacluster: result });
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingUpdateTargetProvisionInfo: false });
    }
  };

  onClickUpdateRebootNodesAllowed = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set reboot nodes allowed to ${newMode ? "true" : "false"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating reboot nodes allowed, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiUpdateDataClusterRebootNodesAllowedRequest = {
            datacluster_id: this.props.datacluster_id,
            reboot_nodes_allowed: newMode,
          };

          await apiClients.idashboardClient.UpdateDataClusterRebootNodesAllowed(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onClickUpdateUpgradeNodepoolsAllowed = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set upgrade nodepools allowed to ${newMode ? "true" : "false"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating upgrade nodepools allowed, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiUpdateDataClusterUpgradeNodepoolsAllowedRequest = {
            datacluster_id: this.props.datacluster_id,
            upgrade_nodepools_allowed: newMode,
          };

          await apiClients.idashboardClient.UpdateDataClusterUpgradeNodepoolsAllowed(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onNodePoolConcurrencyLevelChange = async (value: number) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set node pool update concurrency level to ${value}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating node pool update concurrency level, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiUpdateDataClusterNodeUpdateConcurrencyLevelRequest = {
            datacluster_id: this.props.datacluster_id,
            node_update_concurrency_level: value,
          };

          await apiClients.idashboardClient.UpdateDataClusterNodeUpdateConcurrencyLevel(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onClickUpdateMaintenanceMode = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set maintenance mode to ${newMode ? "true" : "false"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating DC update mode, please wait...",
        onCommentConfirmation: async () => {
          this.setState({ errorMessage: undefined });
          const req: ApiUpdateDataClusterMaintenanceModeRequest = {
            datacluster_id: this.props.datacluster_id,
            in_maintenance_mode: newMode,
          };

          await apiClients.idashboardClient.UpdateDataClusterMaintenanceMode(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onClickUpdateCoreDump = async (newEnbledMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set core dump enabled to ${newEnbledMode ? "true" : "false"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating DC core dump mode, please wait...",
        onCommentConfirmation: async () => {
          this.setState({ errorMessage: undefined });
          const req: ApiUpdateDataClusterCoreDumpRequest = {
            datacluster_id: this.props.datacluster_id,
            is_core_dump_enabled: newEnbledMode,
          };
          await apiClients.idashboardClient.UpdateDataClusterCoreDump(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onClickEditPrometheusMemory = () => {
    this.setState({
      editPrometheusMemory: true,
      prometheusMemory: this.state.prometheusMemory,
    });
  };

  onClickCancelEditPrometheusMemory = () => {
    this.setState({ editPrometheusMemory: false, prometheusMemory: "" });
  };

  onChangePrometheusMemory = async (newValue: string): Promise<AsyncResult<void>> => {
    let error = "";
    const req: ApiUpdateDataClusterPrometheusMemoryRequest = {
      datacluster_id: (this.state.datacluster || {}).id,
      prometheus_memory_size_limit: newValue,
    };
    this.setState({ processingUpdatePrometheusMemory: true });
    try {
      await apiClients.idashboardClient.UpdateDataClusterPrometheusMemory(req);
      this.setState({ prometheusMemory: newValue });
    } catch (err) {
      error = err;
    }
    this.setState({ processingUpdatePrometheusMemory: false });
    return { error };
  };

  onClickUpdateCiliumHubble = async (newMode: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set Cilium Hubble mode to ${newMode ? "true" : "false"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating DC update mode, please wait...",
        onCommentConfirmation: async () => {
          this.setState({ errorMessage: undefined });
          const req: ApiUpdateDataClusterCiliumHubbleRequest = {
            datacluster_id: this.props.datacluster_id,
            enable_hubble: newMode,
          };

          await apiClients.idashboardClient.UpdateDataClusterCiliumHubble(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onClickDCUpdateMode = async (newValue: string) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set DC update mode to ${newValue}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Updating DC update mode, please wait...",
        onCommentConfirmation: async () => {
          const annotations = (this.state.datacluster && this.state.datacluster.annotations) || [];
          const tmpList = annotations.filter((v: ApiKeyValuePair) => {
            return v.key != Common.dcupdate_mode_annotation_key;
          });
          const newAnnotations = _.concat(tmpList, {
            key: Common.dcupdate_mode_annotation_key,
            value: newValue,
          });

          const req: ApiUpdateDataClusterAnnotationsRequest = {
            datacluster_id: this.props.datacluster_id,
            annotations: newAnnotations,
          };

          await apiClients.idashboardClient.UpdateDataClusterAnnotations(req);
          this.refreshDataClusterInfo();
        },
      },
    });
  };

  onEditNatsStreamingVolumeInfos = () => {
    this.setState({ editNatsStreamingVolumeInfos: true });
  };
  onCancelEditNatsStreamingVolumeInfos = () => {
    this.setState({ editNatsStreamingVolumeInfos: false });
  };
  onEditNatsJetstreamVolumeInfos = () => {
    this.setState({ editNatsJetstreamVolumeInfos: true });
  };
  onCancelEditNatsJetstreamVolumeInfos = () => {
    this.setState({ editNatsJetstreamVolumeInfos: false });
  };
  onEditLokiVolumeInfos = () => {
    this.setState({ editLokiVolumeInfos: true });
  };
  onCancelEditLokiVolumeInfos = () => {
    this.setState({ editLokiVolumeInfos: false });
  };
  onEditPrometheusVolumeInfos = () => {
    this.setState({ editPrometheusVolumeInfos: true });
  };
  onCancelEditPrometheusVolumeInfos = () => {
    this.setState({ editPrometheusVolumeInfos: false });
  };

  onSaveVolumeInfos = async (
    newNatsStreamingVolumeInfo?: ApiVolumeInfo,
    newNatsJetstreamVolumeInfo?: ApiVolumeInfo,
    newLokiVolumeInfo?: ApiVolumeInfo
  ): Promise<{ errorMessage: string }> => {
    let errorMessage = "";
    try {
      this.setState({ processingUpdateVolumeInfos: true, errorMessage: undefined });
      const req: ApiUpdateDataClusterVolumeInfosRequest = {
        datacluster_id: this.props.datacluster_id,
        nats_streaming_volume_info: newNatsStreamingVolumeInfo,
        nats_jetstream_volume_info: newNatsJetstreamVolumeInfo,
        loki_volume_info: newLokiVolumeInfo,
      };
      await apiClients.idashboardClient.UpdateDataClusterVolumeInfos(req);
      this.setState({ editNatsStreamingVolumeInfos: false, editNatsJetstreamVolumeInfos: false, editLokiVolumeInfos: false });
      this.refreshDataClusterInfo();
    } catch (e) {
      errorMessage = e;
      return { errorMessage };
    } finally {
      this.setState({ processingUpdateVolumeInfos: false });
    }

    return { errorMessage };
  };

  onSavePrometheusVolumeInfo = async (newVolumeInfo?: ApiVolumeInfo): Promise<{ errorMessage: string }> => {
    let errorMessage = "";
    try {
      this.setState({ processingUpdateVolumeInfos: true, errorMessage: undefined });
      const req: ApiUpdateDataClusterVolumeInfosRequest = {
        datacluster_id: this.props.datacluster_id,
        prometheus_volume_info: newVolumeInfo,
      };
      await apiClients.idashboardClient.UpdateDataClusterVolumeInfos(req);
      this.setState({ editPrometheusVolumeInfos: false });
      this.refreshDataClusterInfo();
    } catch (e) {
      errorMessage = e;
      return { errorMessage };
    } finally {
      this.setState({ processingUpdateVolumeInfos: false });
    }

    return { errorMessage };
  };

  onEditNatsStreamingDiskSize = () => {
    this.setState({ editNatsStreamingDiskSize: true });
  };
  onCancelEditNatsStreamingDiskSize = () => {
    this.setState({ editNatsStreamingDiskSize: false });
  };
  onEditNatsJetstreamDiskSize = () => {
    this.setState({ editNatsJetstreamDiskSize: true });
  };
  onCancelEditNatsJetstreamDiskSize = () => {
    this.setState({ editNatsJetstreamDiskSize: false });
  };
  onEditLokiDiskSize = () => {
    this.setState({ editLokiDiskSize: true });
  };
  onCancelEditLokiDiskSize = () => {
    this.setState({ editLokiDiskSize: false });
  };
  onEditPrometheusDiskSize = () => {
    this.setState({ editPrometheusDiskSize: true });
  };
  onCancelEditPrometheusDiskSize = () => {
    this.setState({ editPrometheusDiskSize: false });
  };

  onSaveDiskSize = async (
    newNatsStreamingVolumeSize?: string,
    newNatsJetstreamVolumeSize?: string,
    newLokiVolumeSize?: string
  ): Promise<{ errorMessage: string }> => {
    let errorMessage = "";
    try {
      this.setState({ processingUpdateDiskSize: true, errorMessage: undefined });
      const req: ApiUpdateDataClusterDiskSizesRequest = {
        datacluster_id: this.props.datacluster_id,
        nats_streaming_disk_size: newNatsStreamingVolumeSize,
        nats_jetstream_disk_size: newNatsJetstreamVolumeSize,
        loki_disk_size: newLokiVolumeSize,
      };
      await apiClients.idashboardClient.UpdateDataClusterDiskSizes(req);
      this.setState({ editNatsStreamingVolumeInfos: false, editNatsJetstreamVolumeInfos: false, editLokiVolumeInfos: false });
      this.refreshDataClusterInfo();
    } catch (e) {
      errorMessage = e;
      return { errorMessage };
    } finally {
      this.setState({ processingUpdateDiskSize: false });
    }

    return { errorMessage };
  };

  onSavePrometheusDiskSize = async (newSize: string): Promise<{ errorMessage: string }> => {
    let errorMessage = "";
    try {
      this.setState({ processingUpdateDiskSize: true, errorMessage: undefined });
      const req: ApiUpdateDataClusterDiskSizesRequest = {
        datacluster_id: this.props.datacluster_id,
        prometheus_disk_size: newSize,
      };
      await apiClients.idashboardClient.UpdateDataClusterDiskSizes(req);
      this.setState({ editPrometheusDiskSize: false });
      this.refreshDataClusterInfo();
    } catch (e) {
      errorMessage = e;
      return { errorMessage };
    } finally {
      this.setState({ processingUpdateDiskSize: false });
    }

    return { errorMessage };
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCopyKubeConfig = async () => {
    const idOptions: ApiIDOptions = { id: this.props.datacluster_id };
    const kubeConfig = await apiClients.idashboardClient.GetDataClusterKubeConfig(idOptions);
    copy(kubeConfig.kube_config || "");
    this.setState({ copiedKubeConfig: true }, () => {
      const setTimeout = this.props.setTimeout;
      setTimeout &&
        setTimeout(() => {
          this.setState({ copiedKubeConfig: false });
        }, 3000);
    });
  };

  onClickGenerateConnectionKubeConfig = async () => {
    try {
      this.setState({ processingGenerateConnectionKubeConfig: true, errorMessage: undefined });
      const req: ApiKubernetesAccessRequest = {
        datacluster_id: this.props.datacluster_id,
      };

      const resp = await apiClients.idashboardClient.RequestKubernetesAccess(req);
      if (resp && resp.kubeconfig) {
        copy(resp.kubeconfig);
        this.setState({ copiedConnectionKubeConfig: true }, () => {
          const setTimeout = this.props.setTimeout;
          setTimeout &&
            setTimeout(() => {
              this.setState({ copiedConnectionKubeConfig: false });
            }, 8000);
        });
      }
      this.createComment("Generated Connection Kube Config file and copied to clipboard");
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingGenerateConnectionKubeConfig: false });
    }
  };

  onCloseConnectionKubeConfigView = () => {
    this.setState({ copiedConnectionKubeConfig: false });
  };

  gotoUrl = (url: string) => {
    window.open(url);
  };

  onRemoveFinalizer = async (finalizer: string) => {
    try {
      this.setState({ processingRemoveFinalizer: true, errorMessage: undefined });
      const req: ApiFinalizerRequest = {
        context_id: this.props.datacluster_id,
        finalizers: [finalizer],
      };
      await apiClients.idashboardClient.RemoveDataClusterFinalizers(req);
      this.refreshDataClusterInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingRemoveFinalizer: false });
    }
  };

  render() {
    const canGetKubeConfig = hasSupportPermission("internal-dashboard.datacluster.get-credentials", this.props.hasPermissionByUrl);
    const canRemoveFinalizer = hasSupportPermission("internal-dashboard.datacluster.manage", this.props.hasPermissionByUrl);
    const canUpdateCoreDump = hasSupportPermission("internal-dashboard.datacluster.update-core-dump", this.props.hasPermissionByUrl);
    const canViewComments = hasSupportPermission("internal-dashboard.comment.list", this.props.hasPermissionByUrl);
    const canCreateComment = hasSupportPermission("internal-dashboard.comment.create", this.props.hasPermissionByUrl);
    return (
      <ContentSegment>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <Confirm confirmInfo={this.state.updateDataClusterConfirm} />
        <Processing active={this.state.processingUpdateTargetProvisionInfo} message="Updating target provision info, please wait..." />
        <Processing active={this.state.processingGenerateConnectionKubeConfig} message="Generating kube-config file for datacluster, please wait..." />
        <Processing active={this.state.processingUpdateVolumeInfos} message="Updating volume infos for datacluster, please wait..." />
        <Processing active={this.state.processingUpdateDiskSize} message="Updating disk size for datacluster, please wait..." />
        <Processing active={this.state.processingRemoveFinalizer} message="Removing finalizer, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Data Cluster Details</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        {this.state.datacluster && (
          <div>
            <Segment>
              <Grid columns="3" stackable>
                <Grid.Column>
                  <DataClusterDetailsView
                    {...this.props}
                    {...this.state}
                    datacluster={this.state.datacluster}
                    status={this.state.datacluster.status || {}}
                    canEditLabels={true} // manage cluster permissions?
                    onClickSetAssignable={() => this.onClickChangeUnassignable(false)}
                    onClickSetUnassignable={() => this.onClickChangeUnassignable(true)}
                    onClickEditLabels={this.onClickEditLabels}
                    labels={this.state.labels || []}
                    onCancelEditLabels={this.onClickCancelEditLabels}
                    onSaveEditLabels={this.onClickSaveEditLabels}
                    onChangeLabels={this.onChangeLabels}
                    onClickRebootNodesAllowed={this.onClickUpdateRebootNodesAllowed}
                    onClickUpgradeNodepoolsAllowed={this.onClickUpdateUpgradeNodepoolsAllowed}
                    onClickCiliumHubble={this.onClickUpdateCiliumHubble}
                    onClickDCUpdateMode={this.onClickDCUpdateMode}
                    canGetKubeConfig={!!canGetKubeConfig}
                    onCopyKubeConfig={this.onCopyKubeConfig}
                    copiedConnectionKubeConfig={this.state.copiedConnectionKubeConfig}
                    onClickGenerateConnectionKubeConfig={this.onClickGenerateConnectionKubeConfig}
                    onCloseConnectionKubeConfigView={this.onCloseConnectionKubeConfigView}
                    onClickMaintenanceMode={this.onClickUpdateMaintenanceMode}
                    onNodePoolConcurrencyLevelChange={this.onNodePoolConcurrencyLevelChange}
                    canRemoveFinalizer={canRemoveFinalizer}
                    onRemoveFinalizer={this.onRemoveFinalizer}
                    createComment={this.createComment}
                    canEditCoreDump={canUpdateCoreDump}
                    onClickCoreDump={this.onClickUpdateCoreDump}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DataClusterProvisionInfoView datacluster={this.state.datacluster} onClickUpdateTargetProvisionInfo={this.onClickUpdateTargetProvisionInfo} />
                  <Divider hidden />
                  <DataClusterVersionsView
                    dataclusterID={this.props.datacluster_id}
                    controlplaneStatus={this.state.controlplaneStatus}
                    status={this.state.datacluster.status || {}}
                  />
                </Grid.Column>
                <Grid.Column>
                  <CommentsSectionInline
                    onCommentsPageChange={this.onCommentsPageChange}
                    canCreate={canCreateComment}
                    canViewComments={canViewComments}
                    canCreateComment={canCreateComment}
                    createComment={this.createComment}
                    commentList={this.state.commentList}
                    latestComments={this.state.latestComments}
                    commentsLoading={this.state.commentsLoading}
                    commentCreationInProcess={this.state.commentCreationInProcess}
                    lastestCommentsTriggered={this.state.lastestCommentsTriggered}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <TabLayoutWithRouter tabConfig={this.getTabConfig()} allowOverflow id="dataclusterTabs" />
          </div>
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(DataClusterDetails);
