//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import { Grid, Loader, Menu, Segment } from "semantic-ui-react";
import Markdown from "react-markdown";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, TermsAndConditions as ApiTermsAndConditions } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import {
  ContentSegment,
  Field,
  FieldContent as FC,
  FieldLabel as FL,
  LoaderBox,
  MenuActionBack,
  SecondaryMenu,
  Section,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface ITermsAndConditionsViewArgs {
  termsAndConditions: ApiTermsAndConditions;
}

const TermsAndConditionsView = ({ ...args }: ITermsAndConditionsViewArgs) => (
  <div>
    <Segment>
      <Grid width="16">
        <Grid.Column width="8">
          <Field>
            <FL>ID</FL>
            <FC>
              <div>{args.termsAndConditions.id || "-"}</div>
            </FC>
          </Field>
        </Grid.Column>
        <Grid.Column width="8">
          <Field>
            <FL>Created</FL>
            <FC>
              {args.termsAndConditions.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.termsAndConditions.created_at} label="Created at" /> : "-"}
            </FC>
          </Field>
        </Grid.Column>
      </Grid>
    </Segment>
    <Segment>
      <Markdown source={args.termsAndConditions.content || ""} />
    </Segment>
  </div>
);

interface ITermsAndConditionsDetailsProps extends IWithRefreshProps, RouteComponentProps {
  termsAndConditionsId: string;
}

interface ITermsAndConditionsDetailsState {
  termsAndConditions?: ApiTermsAndConditions;
}

// The component to show T&C
class TermsAndConditionsDetails extends Component<ITermsAndConditionsDetailsProps, ITermsAndConditionsDetailsState> {
  state: ITermsAndConditionsDetailsState = {
    termsAndConditions: undefined,
  };

  reloadTermsAndConditions = async () => {
    const idOptions: ApiIDOptions = { id: this.props.termsAndConditionsId };
    var termsAndConditions = await apiClients.idashboardClient.GetTermsAndConditions(idOptions);
    this.setState({
      termsAndConditions: termsAndConditions,
    });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadTermsAndConditions);
  }

  render() {
    const has_termsAndConditions = !!this.state.termsAndConditions;
    const termsAndConditions = this.state.termsAndConditions || {};

    return (
      <ContentSegment>
        <SecondaryMenu>
          <MenuActionBack />
          <Menu.Item header>Terms &amp; Conditions Details</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        {has_termsAndConditions && (
          <div>
            <Section>
              <SectionHead>
                <SectionHeader title="Details" />
              </SectionHead>
              <SectionContent>
                <TermsAndConditionsView {...this.props} {...this.state} termsAndConditions={termsAndConditions} />
              </SectionContent>
            </Section>
          </div>
        )}
      </ContentSegment>
    );
  }
}

export default withRefresh()(TermsAndConditionsDetails);
