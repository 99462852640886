//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Loader, Menu, Segment, Tab, Button, Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, AuditLogArchiveInfo as ApiAuditLogArchiveInfo } from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  ErrorMessage,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  LoaderBox,
  MenuActionBack,
  SecondaryMenu,
  Section,
  SectionContent,
  TextLink,
} from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import AuditLogArchiveChunkList from "./AuditLogArchiveChunkList";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";

interface IAuditLogArchiveDetailsViewArgs {
  auditLogArchiveInfo: ApiAuditLogArchiveInfo;
  onAuditLogSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onProjectSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  canDeleteAuditLogArchive: boolean;
  onAuditLogArchiveDelete: (id: string) => void;
}

const AuditLogArchiveDetailsView = ({ ...args }: IAuditLogArchiveDetailsViewArgs) => {
  const auditLogArchive = args.auditLogArchiveInfo.audit_log_archive || {};
  const id = auditLogArchive.id || "-";
  const dataclusterId = args.auditLogArchiveInfo.datacluster_id || "-";
  const hasDeploymentId = !!auditLogArchive.deployment_id;
  const deploymentId = auditLogArchive.deployment_id || "-";
  const hasProjectId = !!auditLogArchive.project_id;
  const projectId = auditLogArchive.project_id || "-";
  const auditlogId = auditLogArchive.auditlog_id || "-";
  return (
    <Segment>
      <Grid width="16">
        <Grid.Column width="6">
          <Field>
            <FL>ID</FL>
            <FC>{id}</FC>
          </Field>
          <Field>
            <FL>AuditLog</FL>
            <FC>
              <TextLink label={auditlogId} onClick={() => args.onAuditLogSelected(auditlogId)} />
            </FC>
          </Field>
          <Field>
            <FL>Datacluster</FL>
            <FC>
              <TextLink label={dataclusterId} onClick={() => args.onDataClusterSelected(dataclusterId)} />
            </FC>
          </Field>
          <Field>
            <FL>Region</FL>
            <FC>{args.auditLogArchiveInfo.region_id}</FC>
          </Field>
          {hasProjectId && (
            <Field>
              <FL>Project</FL>
              <FC>
                <TextLink label={projectId} onClick={() => args.onProjectSelected(projectId)} />
              </FC>
            </Field>
          )}
          {hasDeploymentId && (
            <Field>
              <FL>Deployment</FL>
              <FC>
                <TextLink label={deploymentId} onClick={() => args.onDeploymentSelected(deploymentId)} />{" "}
              </FC>
            </Field>
          )}
          <Field>
            <FL>Size</FL>
            <FC>{humanizeFileSize(auditLogArchive.size_in_bytes)}</FC>
          </Field>
          <Field>
            <FL>Size Changed At</FL>
            <FC>
              <DateTimePopupWithUTCAndLocalTime dateTime={auditLogArchive.size_in_bytes_changed_at} label="Size changed at" />
            </FC>
          </Field>
          <Field>
            <FL>Created</FL>
            <FC>{auditLogArchive.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={auditLogArchive.created_at} label="Created at" /> : "-"}</FC>
          </Field>
          {auditLogArchive.is_deleted && (
            <Field>
              <FL>Deleted</FL>
              <FC>{auditLogArchive.deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={auditLogArchive.deleted_at} label="Deleted at" /> : "-"}</FC>
            </Field>
          )}
          {args.canDeleteAuditLogArchive && (
            <Field>
              <FL>{auditLogArchive.is_deleted ? "Re-Delete" : "Delete"}</FL>
              <FC>
                <Button color="red" size="tiny" circular icon onClick={() => args.onAuditLogArchiveDelete(id)}>
                  <Icon name="trash" />
                </Button>
              </FC>
            </Field>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

interface IAuditLogArchiveDetailsProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  onAuditLogSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onProjectSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
}

interface IAuditLogArchiveDetailsState {
  auditLogArchiveInfo?: ApiAuditLogArchiveInfo;
  auditLogArchiveId?: string;
  errorMessage?: string;
}

class AuditLogArchiveDetails extends Component<IAuditLogArchiveDetailsProps, IAuditLogArchiveDetailsState> {
  state: IAuditLogArchiveDetailsState = {
    auditLogArchiveInfo: undefined,
    auditLogArchiveId: undefined,
    errorMessage: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadAuditLogArchive = async () => {
    try {
      const req: ApiIDOptions = {
        id: this.state.auditLogArchiveId,
      };
      const auditLogArchiveInfo = await apiClients.idashboardClient.GetAuditLogArchiveInfo(req);
      if (auditLogArchiveInfo) {
        this.setState({ auditLogArchiveInfo: auditLogArchiveInfo });
      }
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  refreshAuditLogArchive = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchive);
  };

  onAuditLogArchiveDelete = async (id: string) => {
    try {
      const req: ApiIDOptions = {
        id: id,
      };
      await apiClients.idashboardClient.DeleteAuditLogArchive(req);
    } catch (e) {
      this.setState({ errorMessage: e });
    }
    this.refreshAuditLogArchive();
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    const auditLogArchiveId = (this.props.match.params as any).auditLogArchiveId;
    this.setState(
      {
        auditLogArchiveId: auditLogArchiveId,
      },
      () => {
        this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchive);
      }
    );
  }

  render() {
    const auditLogArchiveInfo = this.state.auditLogArchiveInfo;
    const auditLogArchive = (auditLogArchiveInfo && auditLogArchiveInfo.audit_log_archive) || {};
    const canDeleteAuditLogArchive = this.hasPermission("internal-dashboard.auditlogarchive.delete");
    return (
      <div>
        <ContentSegment>
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <SecondaryMenu>
            <MenuActionBack />
            <Menu.Item header>AuditLogArchive Details</Menu.Item>
            <LoaderBox>
              <Loader size="mini" active={this.props.loading} inline />
            </LoaderBox>
          </SecondaryMenu>
          {auditLogArchiveInfo && (
            <Section>
              <SectionContent>
                <AuditLogArchiveDetailsView
                  {...this.props}
                  {...this.state}
                  auditLogArchiveInfo={auditLogArchiveInfo}
                  canDeleteAuditLogArchive={canDeleteAuditLogArchive}
                  onAuditLogArchiveDelete={this.onAuditLogArchiveDelete}
                />
              </SectionContent>
            </Section>
          )}
          {auditLogArchiveInfo && (
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: "Chunks",
                  render: () => (
                    <AuditLogArchiveChunkList
                      {...this.props}
                      deployment_id={auditLogArchive.deployment_id || ""}
                      auditlog_id={auditLogArchive.auditlog_id || ""}
                    />
                  ),
                },
              ]}
            />
          )}
        </ContentSegment>
      </div>
    );
  }
}

export default withRefresh()(AuditLogArchiveDetails);
