//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import styled from "@emotion/styled";
import humanize from "humanize";
import _, { orderBy } from "lodash";
import { Moment } from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Checkbox, Dropdown, Loader, Menu, Message, Placeholder, Popup, Segment, Table } from "semantic-ui-react";
import apiClients, { Cached as cachedApiClients } from "../../api/apiclients";
import { SupportOrganizationID } from "../../constants";
import {
  GetRevenueRequest as ApiGetRevenueRequest,
  ListRegionsRequest as ApiListRegionsRequest,
  Region as ApiRegion,
  Revenue as ApiRevenue,
  NodeSize as ApiNodeSize,
  CPUSize as ApiCPUSize,
} from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  CreationDateFilter,
  ErrorMessage,
  MonthAndWeekAndYearCreationDateFilters,
  SecondaryMenu,
  Section,
  SectionContent,
  SectionHeader,
  momentDefaultFormat,
  momentNow,
  momentStartOfProject,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CreateReportButtonView from "../reports/CreateReportButton";

const CombinedRow = styled(Table.Row)`
  font-weight: bold;
`;

const Forecast = styled("span")`
  font-style: italic;
`;

const RightBorderCell = styled(Table.Cell)`
  border-right: 1px solid silver;
`;

const RightBorderHeaderCell = styled(Table.HeaderCell)`
  border-right: 1px solid silver;
`;

const dateFilters = MonthAndWeekAndYearCreationDateFilters(true);

interface IFilterViewArgs {
  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;

  selectedNodeSizeID: string;
  nodeSizes: ApiNodeSize[];
  cpuSizes: ApiCPUSize[];
  onNodeSizeChanged: (nodeSizeID: string) => void;

  selectedRegionID: string;
  regions: ApiRegion[];
  onRegionChanged: (regionID: string) => void;

  includeNonPayingTiers: boolean;
  onIncludeNonPayingTiersChanged: (includeNonPayingTiers: boolean) => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  const getNodeSizeName = (id: string) => {
    const nodeSize = _.find(args.nodeSizes, (x) => x.id == id) || {};
    const cpuSize = _.find(args.cpuSizes, (cs) => cs.id == nodeSize.cpu_size) || {};
    return `${_.toUpper(nodeSize.name)} (${cpuSize.name})`;
  };
  const orderedNodeSizes = args.nodeSizes;
  const nodeSizeItems = orderedNodeSizes.map((x, i) => {
    const nodeSizeID = x.id || "";
    const included = args.selectedNodeSizeID == nodeSizeID;
    return (
      <Dropdown.Item
        key={`nodeSize-${nodeSizeID}`}
        selected={included}
        text={getNodeSizeName(nodeSizeID)}
        onClick={(e) => {
          if (!included) {
            // Include explicitly
            args.onNodeSizeChanged(nodeSizeID);
          } else {
            // Exclude explicitly
            args.onNodeSizeChanged("");
          }
          e.stopPropagation();
        }}
      />
    );
  });
  nodeSizeItems.unshift(<Dropdown.Item key="nodeSize-all" text="All" value="all" onClick={() => args.onNodeSizeChanged("")} />);
  const getNodeSizeText = () => {
    if (_.isEmpty(args.selectedNodeSizeID)) {
      return "Size: All";
    }
    return `Size: ${getNodeSizeName(args.selectedNodeSizeID)}`;
  };

  const orderedRegions = _.orderBy(args.regions, ["provider_id", "location"]);
  const regionItems = orderedRegions.map((x, i) => {
    const regionID = x.id || "";
    const included = args.selectedRegionID == regionID;
    return (
      <Dropdown.Item
        key={`region-${regionID}`}
        icon={included ? "eye" : "ellipsis horizontal"}
        text={`${_.toUpper(x.provider_id)} | ${x.location || regionID}`}
        onClick={(e) => {
          if (!included) {
            // Include explicitly
            args.onRegionChanged(regionID);
          } else {
            // Exclude explicitly
            args.onRegionChanged("");
          }
          e.stopPropagation();
        }}
      />
    );
  });
  regionItems.unshift(<Dropdown.Item key="region-all" text="All" value="all" onClick={() => args.onRegionChanged("")} />);
  const getRegionText = () => {
    if (_.isEmpty(args.selectedRegionID)) {
      return "Region: All";
    }
    return `Region: ${args.selectedRegionID}`;
  };

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Date: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
      <Dropdown item scrolling text={getNodeSizeText()} closeOnChange={false}>
        <Dropdown.Menu>{nodeSizeItems}</Dropdown.Menu>
      </Dropdown>
      <Dropdown item scrolling text={getRegionText()} closeOnChange={false}>
        <Dropdown.Menu>{regionItems}</Dropdown.Menu>
      </Dropdown>
      <Menu.Item>
        <Checkbox
          toggle
          checked={args.includeNonPayingTiers}
          onClick={(e) => args.onIncludeNonPayingTiersChanged(!args.includeNonPayingTiers)}
          label="Include non-paying tiers"
        />
      </Menu.Item>
      <Menu.Item position="right">
        <CreateReportButtonView include_total_revenue />
      </Menu.Item>
    </Menu>
  );
};

interface IStatisticsGroupViewArgs {
  report: IRevenueReport;
}

const StatisticsGroupsView = ({ ...args }: IStatisticsGroupViewArgs) => {
  const st = args.report.revenue;
  const per_entity = st.per_entity || [];
  const recurringPerEntity = args.report.recurringRevenue.per_entity || [];
  const total = st.total || {};
  const total_currency = total.currency || {};
  const totCurSign = total_currency.sign || "?";
  const recurringTotal = args.report.recurringRevenue.total || {};
  const totFullPeriodDiffers = total.total_amount_excl_taxes != total.full_period_total_amount_excl_taxes;
  return (
    <div>
      <Section>
        <SectionHeader title={args.report.tier_id} />
        <SectionContent>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="3">Entity</Table.HeaderCell>
                <Table.HeaderCell width="3">Total value</Table.HeaderCell>
                <RightBorderHeaderCell width="4" colSpan="4">
                  Per customer (avg/min/max/#)
                </RightBorderHeaderCell>
                <RightBorderHeaderCell width="2">Recurring</RightBorderHeaderCell>
                <Table.HeaderCell width="2">VAT</Table.HeaderCell>
                <Table.HeaderCell width="2">Sales tax</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {per_entity.map((x) => {
                const currency = x.currency || {};
                const curSign = currency.sign || "?";
                const recurring = _.find(recurringPerEntity, (y) => y.entity_id == x.entity_id) || {};
                const fullPeriodDiffers = x.total_amount_excl_taxes != x.full_period_total_amount_excl_taxes;
                return (
                  <Table.Row>
                    <Table.Cell>{x.entity_id || ""}</Table.Cell>
                    <Table.Cell>
                      {`${curSign} ${humanize.numberFormat(x.total_amount_excl_taxes || 0)}`}
                      {fullPeriodDiffers && (
                        <Popup
                          trigger={<Forecast>&nbsp; ({`${curSign} ${humanize.numberFormat(x.full_period_total_amount_excl_taxes || 0)}`})</Forecast>}
                          content="Forecast for entire period"
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell collapsing>{`${curSign} ${humanize.numberFormat(x.average_organization_amount || 0)}`}</Table.Cell>
                    <Table.Cell collapsing>{`${curSign} ${humanize.numberFormat(x.min_organization_amount || 0)}`}</Table.Cell>
                    <Table.Cell collapsing>{`${curSign} ${humanize.numberFormat(x.max_organization_amount || 0)}`}</Table.Cell>
                    <RightBorderCell>{x.organization_count || 0}</RightBorderCell>
                    <RightBorderCell>{`${curSign} ${humanize.numberFormat(recurring.total_amount_excl_taxes || 0)}`}</RightBorderCell>
                    <Table.Cell>{`${curSign} ${humanize.numberFormat(x.total_vat || 0)}`}</Table.Cell>
                    <Table.Cell>{`${curSign} ${humanize.numberFormat(x.total_sales_tax || 0)}`}</Table.Cell>
                  </Table.Row>
                );
              })}
              <CombinedRow>
                <Table.Cell>Combined</Table.Cell>
                <Table.Cell>
                  {`${totCurSign} ${humanize.numberFormat(total.total_amount_excl_taxes || 0)}`}
                  {totFullPeriodDiffers && (
                    <Popup
                      trigger={<Forecast>&nbsp; ({`${totCurSign} ${humanize.numberFormat(total.full_period_total_amount_excl_taxes || 0)}`})</Forecast>}
                      content="Forecast for entire period"
                    />
                  )}
                </Table.Cell>
                <Table.Cell collapsing>{`${totCurSign} ${humanize.numberFormat(total.average_organization_amount || 0)}`}</Table.Cell>
                <Table.Cell collapsing>{`${totCurSign} ${humanize.numberFormat(total.min_organization_amount || 0)}`}</Table.Cell>
                <Table.Cell collapsing>{`${totCurSign} ${humanize.numberFormat(total.max_organization_amount || 0)}`}</Table.Cell>
                <RightBorderCell>{total.organization_count || 0}</RightBorderCell>
                <RightBorderCell>{`${totCurSign} ${humanize.numberFormat(recurringTotal.total_amount_excl_taxes || 0)}`}</RightBorderCell>
                <Table.Cell>{`${totCurSign} ${humanize.numberFormat(total.total_vat || 0)}`}</Table.Cell>
                <Table.Cell>{`${totCurSign} ${humanize.numberFormat(total.total_sales_tax || 0)}`}</Table.Cell>
              </CombinedRow>
            </Table.Body>
          </Table>
        </SectionContent>
      </Section>
    </div>
  );
};

interface IStatisticsViewArgs {
  expectedReports: number;
  reports: IRevenueReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
  selectedNodeSizeID?: string;
}

const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";
  const loading = args.expectedReports > args.reports.length;
  const nodeSizeFilter = _.isEmpty(args.selectedNodeSizeID) ? "(all node sizes)" : `(node size ${args.selectedNodeSizeID})`;
  return (
    <div>
      <Message>
        Revenue {nodeSizeFilter} {timeFilter}.{loading && <Loader inline active size="mini" />}
      </Message>
      {args.reports.map((x) => (
        <StatisticsGroupsView {...args} report={x} />
      ))}
    </div>
  );
};

const PlaceholderView = () => (
  <Segment>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

// Interface describing the invoice list view arguments
export interface IRevenueReportingViewArgs extends IFilterViewArgs, RouteComponentProps {
  expectedReports: number;
  reports?: IRevenueReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
}

export const RevenueReportingView = ({ ...args }: IRevenueReportingViewArgs) => {
  const reports = args.reports || [];
  const has_reports = !_.isEmpty(reports);
  return (
    <div>
      <FilterView {...args} />
      {has_reports && <StatisticsView {...args} reports={reports} />}
      {!has_reports && <PlaceholderView />}
    </div>
  );
};

interface IRevenueReportingProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  organizationID?: string;
}

interface IRevenueReport {
  tier_id: string;
  revenue: ApiRevenue;
  recurringRevenue: ApiRevenue;
}

interface IRevenueReportingState {
  sequence: number;
  errorMessage?: string;
  reports?: IRevenueReport[];
  includeNonPayingTiers: boolean;
  filterFrom?: Moment;
  filterTo?: Moment;
  selectedCreationDateFilterID: string;
  selectedNodeSizeID?: string;
  selectedRegionID?: string;
  nodeSizes?: ApiNodeSize[];
  cpuSizes?: ApiCPUSize[];
  regions?: ApiRegion[];
}

// The component to show revenue reporting.
class RevenueReporting extends Component<IRevenueReportingProps, IRevenueReportingState> {
  state: IRevenueReportingState = {
    sequence: 0,
    errorMessage: undefined,
    filterFrom: undefined,
    filterTo: undefined,
    reports: undefined,
    includeNonPayingTiers: false,
    selectedCreationDateFilterID: dateFilters[0].id,
    selectedNodeSizeID: undefined,
    selectedRegionID: undefined,
    nodeSizes: undefined,
    cpuSizes: undefined,
    regions: undefined,
  };

  reloadNodeSizes = async () => {
    const list = await cachedApiClients.idashboardClient.ListAllNodeSizes({});
    this.setState({ nodeSizes: list.items });
  };

  reloadCPUSizes = async () => {
    const list = await cachedApiClients.authenticationOnly.dataClient.ListCPUSizes({
      project_id: "all",
    });
    this.setState({ cpuSizes: list.items });
  };

  loadRegions = async (providerID: string) => {
    const req: ApiListRegionsRequest = {
      provider_id: providerID,
      organization_id: SupportOrganizationID,
    };
    const list = await cachedApiClients.authenticationOnly.platformClient.ListRegions(req);
    return list.items || [];
  };

  reloadRegions = async () => {
    const orgID = SupportOrganizationID;
    const providers = await cachedApiClients.authenticationOnly.platformClient.ListProviders({ organization_id: orgID });
    const allRegions = await Promise.all(
      _.map(providers.items || [], (p) => {
        return this.loadRegions(p.id || "");
      })
    );
    this.setState({ regions: _.flatten(allRegions) });
  };

  reloadRevenue = async () => {
    this.setState(
      (old) => {
        return {
          sequence: old.sequence + 1,
          reports: undefined,
        };
      },
      async () => {
        const sequence = this.state.sequence;
        let filterFrom = momentStartOfProject();
        let filterTo = momentNow();

        const f = dateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
        if (f) {
          if (f.filterStart) {
            filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          }
          if (f.filterEnd) {
            filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          }
        }

        const loadReport = async (tierID: string) => {
          const report = await this.loadRevenue(tierID, filterFrom, filterTo);
          this.setState((old) => {
            if (old.sequence !== sequence) {
              // This result belong to old request
              return {};
            }
            const reports = old.reports || [];
            reports.push(report);
            return { reports: orderBy(reports, (report) => report.tier_id) };
          });
          console.log(`Received ${tierID} report`, report);
        };

        // Load all tiers
        const tierList = await apiClients.idashboardClient.ListTierDetails({});
        const tiers = tierList.items || [];

        // Load reports for applicable tiers
        tiers.forEach((tier) => {
          if (this.state.includeNonPayingTiers || !!tier.requires_invoice_creation) {
            loadReport(tier.id || "");
          }
        });

        this.setState({
          filterFrom: filterFrom,
          filterTo: filterTo,
        });
      }
    );
  };

  loadRevenue = async (tier_id: string, filterFrom: Moment, filterTo: Moment) => {
    const req: ApiGetRevenueRequest = {
      tier_id: tier_id,
      organization_id: this.props.organizationID,
      from: filterFrom.toDate(),
      to: filterTo.toDate(),
      node_size_id: this.state.selectedNodeSizeID,
      region_id: this.state.selectedRegionID,
    };

    const revenuePromise = new Promise<ApiRevenue>(async (resolve, reject) => {
      try {
        await apiClients.idashboardClient.GetRevenue(req, (x) => {
          if (!!x.message) resolve(x.message);
          else if (!!x.error) {
            reject(x.error);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
    const recurringRevenuePromise = new Promise<ApiRevenue>(async (resolve, reject) => {
      try {
        await apiClients.idashboardClient.GetRecurringRevenue(req, (x) => {
          if (!!x.message) resolve(x.message);
          else if (!!x.error) {
            reject(x.error);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
    const revenue = await revenuePromise;
    const recurringRevenue = await recurringRevenuePromise;
    const result: IRevenueReport = {
      tier_id: tier_id,
      revenue: revenue,
      recurringRevenue: recurringRevenue,
    };
    return result;
  };

  refreshNodeSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadNodeSizes);
  };

  refreshCPUSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCPUSizes);
  };

  refreshRegions = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadRegions);
  };

  refreshRevenue = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadRevenue);
  };

  componentDidMount() {
    this.refreshNodeSizes();
    this.refreshCPUSizes();
    this.refreshRegions();
    this.refreshRevenue();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState(
      {
        selectedCreationDateFilterID: id,
        reports: undefined,
      },
      this.refreshRevenue
    );
  };

  onNodeSizeChanged = (nodeSizeID: string) => {
    this.setState({ selectedNodeSizeID: nodeSizeID }, this.refreshRevenue);
  };

  onRegionChanged = (regionID: string) => {
    this.setState({ selectedRegionID: regionID }, this.refreshRevenue);
  };

  onIncludeNonPayingTiersChanged = (includeNonPayingTiers: boolean) => {
    this.setState({ includeNonPayingTiers: includeNonPayingTiers }, this.refreshRevenue);
  };

  render() {
    const reports = this.state.reports || [];
    const nodeSizes = this.state.nodeSizes || [];
    const cpuSizes = this.state.cpuSizes || [];
    const regions = this.state.regions || [];

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Revenue</Menu.Item>
        </SecondaryMenu>
        <RevenueReportingView
          {...this.props}
          {...this.state}
          expectedReports={2}
          reports={_.isEmpty(reports) ? undefined : reports}
          creationDateFilters={dateFilters}
          nodeSizes={nodeSizes}
          cpuSizes={cpuSizes}
          regions={regions}
          selectedNodeSizeID={this.state.selectedNodeSizeID || ""}
          selectedRegionID={this.state.selectedRegionID || ""}
          onCreationDateFilterChanged={this.onCreationDateFilterChanged}
          onNodeSizeChanged={this.onNodeSizeChanged}
          onRegionChanged={this.onRegionChanged}
          onIncludeNonPayingTiersChanged={this.onIncludeNonPayingTiersChanged}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(RevenueReporting);
