//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Icon, Menu, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ListAuditLogArchiveInfosRequest as ApiListAuditLogArchiveInfosRequest,
  AuditLogArchive as ApiAuditLogArchive,
  AuditLogArchiveChunkList as ApiAuditLogArchiveChunkList,
  AuditLogArchiveChunk as ApiAuditLogArchiveChunk,
  ListAuditLogArchiveChunksRequest as ApiListAuditLogArchiveChunksRequest,
} from "../../api/lib";
import { PagingButtons } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CopyToClipboardButton from "../../ui/_copybutton";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";

interface IFilterViewArgs {
  page: number;
  pageSize: number;
  count: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Menu.Item position="right" fitted="vertically">
        <PagingButtons {...args} size="tiny" />
      </Menu.Item>
    </Menu>
  );
};

// Arguments for header view
interface IHeaderView {}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Bucket Path</Table.HeaderCell>
        <Table.HeaderCell>Encrypted</Table.HeaderCell>
        <Table.HeaderCell>First Event</Table.HeaderCell>
        <Table.HeaderCell>Last Event</Table.HeaderCell>
        <Table.HeaderCell>Consistency Last Checked</Table.HeaderCell>
        <Table.HeaderCell>Consistent</Table.HeaderCell>
        <Table.HeaderCell>File State</Table.HeaderCell>
        <Table.HeaderCell>File Size</Table.HeaderCell>
        <Table.HeaderCell>Number of Events</Table.HeaderCell>
        <Table.HeaderCell>Created</Table.HeaderCell>
        <Table.HeaderCell>Deleted</Table.HeaderCell>
        <Table.HeaderCell>Copy Details</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

// Interface describing a AuditLog
interface IRowView extends IWithRefreshProps {
  item: ApiAuditLogArchiveChunk;
}

const RowView = ({ ...args }: IRowView) => {
  const item = args.item || {};
  const consInfo = item.consistency_info;
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.bucket_path}</Table.Cell>
      <Table.Cell>{!!item.key_hash ? <Icon name="lock" /> : <Icon name="lock open" />}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={item.first_event} label="First Event at" />
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={item.last_event} label="Last Event at" />
      </Table.Cell>
      <Table.Cell>{consInfo ? <DateTimePopupWithUTCAndLocalTime dateTime={consInfo.last_checked_at} label="Last checked at" /> : "-"}</Table.Cell>
      <Table.Cell>{consInfo ? consInfo.is_consistent ? <Icon name="check" color="green" /> : <Icon name="unlink" color="red" /> : "-"}</Table.Cell>
      <Table.Cell>
        {consInfo ? consInfo.object_info && consInfo.object_info.is_locked ? <Icon name="lock" color="green" /> : <Icon name="lock open" /> : "-"}
      </Table.Cell>
      <Table.Cell>{consInfo ? (consInfo.object_info ? humanizeFileSize(consInfo.object_info.size_in_bytes) : "0") : "-"}</Table.Cell>
      <Table.Cell>{consInfo ? consInfo.number_of_events || "0" : "-"}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={item.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>{item.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={item.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
      <Table.Cell>{<CopyToClipboardButton content={consInfo ? consInfo.details : ""} disabled={!consInfo} />}</Table.Cell>
    </Table.Row>
  );
};

// Interface describing the AuditLog list
interface IListView extends IWithRefreshProps {
  items?: ApiAuditLogArchiveChunk[];
}

const ListView = ({ ...args }: IListView) => {
  const items = args.items || [];
  return (
    <Table striped>
      <HeaderView {...args} />
      <Table.Body>
        {items.map((item) => (
          <RowView {...args} key={item && item.id} item={item} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IEmptyViewArgs {}

const EmptyView = ({ ...args }: IEmptyViewArgs) => {
  return <div>No AuditLog Archive Chunks</div>;
};

// Interface describing the AuditLog Archive chunk view arguments
export interface IAuditLogArchiveChunkListViewArgs extends IListView {
  auditLogArchiveChunks?: ApiAuditLogArchiveChunkList;

  page: number;
  pageSize: number;
  count: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const AuditLogArchiveChunkListViewContent = ({ ...args }: IAuditLogArchiveChunkListViewArgs) => {
  const auditLogArchiveChunks = args.auditLogArchiveChunks || {};
  const items = auditLogArchiveChunks.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView {...args} />;
  }
  return <ListView {...args} items={items} />;
};

export const AuditLogArchiveChunkListView = ({ ...args }: IAuditLogArchiveChunkListViewArgs) => {
  if (!args.auditLogArchiveChunks) {
    return <EmptyView />;
  }
  return (
    <div>
      <FilterView {...args} />
      <AuditLogArchiveChunkListViewContent {...args} />
    </div>
  );
};

// Interface decribing the properties of the AuditLog list component
interface IAuditLogArchiveChunkListProps extends IWithRefreshProps, RouteComponentProps {
  deployment_id?: string;
  auditlog_id: string;
}

// Interface decribing the state of the AuditLog list component
interface IAuditLogArchiveChunkListState {
  auditLogArchiveChunks?: ApiAuditLogArchiveChunkList;
  auditLogArchive?: ApiAuditLogArchive;
  reloadListNeeded: boolean;
  errorMessage?: string;
  page: number;
  pageSize: number;
}

// The component to show the auditlog archive infos.
class AuditLogArchiveChunkList extends Component<IAuditLogArchiveChunkListProps, IAuditLogArchiveChunkListState> {
  state: IAuditLogArchiveChunkListState = {
    reloadListNeeded: false,
    auditLogArchiveChunks: undefined,
    auditLogArchive: undefined,
    errorMessage: undefined,
    page: 0,
    pageSize: 50,
  };

  reloadAuditLogArchive = async () => {
    if (this.props.auditlog_id) {
      try {
        const req: ApiListAuditLogArchiveInfosRequest = {
          auditlog_id: this.props.auditlog_id,
          deployment_id: this.props.deployment_id,
          include_deleted: true,
        };
        const auditLogArchiveInfoList = await apiClients.idashboardClient.ListAuditLogArchiveInfos(req);
        if (auditLogArchiveInfoList.items && auditLogArchiveInfoList.items.length > 0) {
          this.setState(
            {
              auditLogArchive: auditLogArchiveInfoList.items[0].audit_log_archive,
            },
            this.reloadAuditLogArchiveChunks
          );
        }
      } catch (e) {
        this.setState({ errorMessage: e });
      }
    }
  };

  reloadAuditLogArchiveChunks = async () => {
    if (this.state.auditLogArchive) {
      try {
        const req: ApiListAuditLogArchiveChunksRequest = {
          auditlogarchive_id: this.state.auditLogArchive.id,
          options: {
            page: this.state.page,
            page_size: this.state.pageSize,
          },
        };
        const list = await apiClients.idashboardClient.ListAuditLogArchiveChunks(req);
        if (list) {
          this.setState({
            auditLogArchiveChunks: list,
          });
        }
      } catch (e) {
        this.setState({ errorMessage: e });
      }
    }
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshAuditLogArchiveChunks
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshAuditLogArchiveChunks
    );
  };

  refreshAuditLogArchiveChunks = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchiveChunks);
  };

  refreshAuditLogArchive = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchive);
  };

  componentDidMount() {
    // TODO: Update to use NATs events, for now use polling
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadAuditLogArchiveChunks, 5000);
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadAuditLogArchive, 5000);
  }

  componentDidUpdate() {
    if (this.state.reloadListNeeded) {
      this.setState({ reloadListNeeded: false }, this.refreshAuditLogArchiveChunks);
    }
  }

  clearError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const chunks = this.state.auditLogArchiveChunks;
    return (
      <AuditLogArchiveChunkListView
        {...this.props}
        {...this.state}
        auditLogArchiveChunks={chunks}
        onNextPage={this.onNextPage}
        onPreviousPage={this.onPreviousPage}
        count={(chunks && chunks.items && chunks.items.length) || 0}
      />
    );
  }
}

export default withRefresh()(AuditLogArchiveChunkList);
