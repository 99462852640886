//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { Modal } from "semantic-ui-react";
import { DataClusterCostBenefits as ApiDataClusterCostBenefits, DataCluster as ApiDataCluster, IDOptions as ApiIDOptions } from "../../api/lib";
import { StyledInlineLoader } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import humanize from "humanize";

interface IDataClusterCostBenefitsViewArgs {
  costBenefits: ApiDataClusterCostBenefits;
}

const DataClusterCostBenefitsView = ({ ...args }: IDataClusterCostBenefitsViewArgs) => {
  let trigger = <span>{humanize.numberFormat(24 * (args.costBenefits.total_costs || 0))} USD</span>;
  return (
    <div>
      <Modal trigger={trigger} size="small">
        <Modal.Header>Costs & Benefits</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Total nodes costs: {humanize.numberFormat(24 * (args.costBenefits.instance_costs || 0))} USD / day
            <br />
            <br />
            Total Benefits (as if all paid, excluding paused): {humanize.numberFormat(24 * (args.costBenefits.total_benefits || 0))} USD / day
            <br />
            Benefits as Invoiced: {humanize.numberFormat(24 * (args.costBenefits.benefits_as_invoiced || 0))} USD / day
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

// Interface decribing the properties of the DataClusterCostBenefits component
interface IDataClusterCostBenefitsProps extends IWithRefreshProps {
  item: ApiDataCluster;
}

// Interface decribing the state of the DataClusterCostBenefits component
interface IDataClusterCostBenefitsState {
  costBenefits?: ApiDataClusterCostBenefits;
}

class DataClusterCostBenefits extends Component<IDataClusterCostBenefitsProps, IDataClusterCostBenefitsState> {
  state: IDataClusterCostBenefitsState = {
    costBenefits: undefined,
  };

  reloadDataClusterCostBenefits = async () => {
    if (!this.props.item.id) {
      return;
    }
    const reqId: ApiIDOptions = {
      id: this.props.item.id,
    };
    const costBenefits = await apiClients.idashboardClient.GetDataClusterCostBenefits(reqId);
    this.setState({
      costBenefits: costBenefits,
    });
  };

  componentDidMount() {
    // Update every 2 minutes only, it's not changing so much
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadDataClusterCostBenefits, 120000);
  }

  render() {
    const costBenefits = this.state.costBenefits;
    if (!!!costBenefits) {
      return <StyledInlineLoader inline />;
    }
    return <DataClusterCostBenefitsView {...this.state} costBenefits={costBenefits} />;
  }
}

export default withRefresh()(DataClusterCostBenefits);
