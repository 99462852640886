//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//
import React, { useState } from "react";
import styled from "@emotion/styled";
import apiClients from "../../api/apiclients";
import DeploymentList from "../deployment/DeploymentList";
import { ContentSegment } from "../../ui/_style";
import { Form, Icon, Input, InputOnChangeData, Loader, Modal, TextArea, TextAreaProps, Checkbox, CheckboxProps } from "semantic-ui-react";
import { isEmpty, omit } from "lodash";
import { withRefresh } from "../../util/WithRefresh";
import { FormActionButtonCancel, FormActionButtonCreate, ErrorMessage } from "../../ui/lib";
import { CreateDeploymentUpdaterViewArgs } from "./types";
import { ListAllDeploymentsRequest as ApiListAllDeploymentsRequest } from "../../api/lib";
import { UpdateJob as ApiUpdateJob, UpdateJob_UpdateRequest as ApiUpdateJob_UpdateRequest } from "../../api/deployment-updater/v1/ideploymentupdater";

const StyledFilterHeader = styled.div`
  display: block;
  color: #879191;
  font-size: 0.85714286rem;
  font-weight: 700;
  text-transform: none;
  padding: 0 24px;
  position: relative;
  top: 20px;
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: -5px;
`;

const CreateDeploymentUpdaterView = (props: CreateDeploymentUpdaterViewArgs) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [apiReq, setApiReq] = useState<Required<ApiUpdateJob_UpdateRequest>>({
    comment: "",
    memory_reserve: {},
    set_memory_reserve: false,
    set_memory_reserve_to_default: false,
    deployment_filter: {},
    max_concurrency: 3,
    set_default_disk_performance: false,
    set_default_custom_storage_class: false,
    update_version: false,
    set_default_communication_method: false,
  });

  const createUpdateJob = async () => {
    if (isEmpty(apiReq.deployment_filter)) {
      setError("Deployment filters cannot be empty");
      return;
    }
    setLoading(true);
    setError(undefined);
    try {
      const req: ApiUpdateJob = {
        req: apiReq,
      };
      await apiClients.idashboardClient.CreateDeploymentUpdateJob(req);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      props.onClose();
    }
  };

  const handleMemoryReserveChangeSetting = (enabled: boolean) => {
    setApiReq({
      ...apiReq,
      set_memory_reserve: enabled,
    });
  };

  const handleMemoryReserveChange = (type: "absolute" | "relative", value: number) => {
    if (type === "absolute") {
      setApiReq({
        ...apiReq,
        memory_reserve: {
          ...apiReq.memory_reserve,
          absolute: value,
        },
      });
      return;
    }
    setApiReq({
      ...apiReq,
      memory_reserve: {
        ...apiReq.memory_reserve,
        relative: value,
      },
    });
  };

  const handleMemoryReserveChangeDefault = (toDefault: boolean) => {
    setApiReq({
      ...apiReq,
      set_memory_reserve_to_default: toDefault,
    });
  };

  const handleSetDefaultDiskPerformanceChange = (value: boolean) => {
    setApiReq({
      ...apiReq,
      set_default_disk_performance: value,
    });
  };

  const handleSetDefaultCustomStorageClassChange = (value: boolean) => {
    setApiReq({
      ...apiReq,
      set_default_custom_storage_class: value,
    });
  };

  const handleUpdateVersionChange = (value: boolean) => {
    setApiReq({
      ...apiReq,
      update_version: value,
    });
  };

  const handleSetDefaultCommunicationMethodChange = (value: boolean) => {
    setApiReq({
      ...apiReq,
      set_default_communication_method: value,
    });
  };

  const handleFilterChange = (filterOptions: ApiListAllDeploymentsRequest) => {
    const req = omit(filterOptions, ["options"]);
    setApiReq({
      ...apiReq,
      deployment_filter: {
        ...req,
      },
    });
  };

  return (
    <>
      <Modal size="fullscreen" open={props.open}>
        <Modal.Header>Create new update job {loading && <Loader inline />}</Modal.Header>
        <Modal.Content scrolling>
          <ContentSegment>
            <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} />
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Max concurrency</label>
                  <Input
                    type="number"
                    value={apiReq.max_concurrency}
                    min={0}
                    disabled={loading}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                      const { value } = data;
                      setApiReq({
                        ...apiReq,
                        max_concurrency: parseInt(value),
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Update Memory Reserves"
                    disabled={loading}
                    checked={apiReq.set_memory_reserve}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      handleMemoryReserveChangeSetting(Boolean(data.checked));
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Update Memory Reserves to Default"
                    disabled={loading || !apiReq.set_memory_reserve}
                    checked={apiReq.set_memory_reserve_to_default}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      handleMemoryReserveChangeDefault(Boolean(data.checked));
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Memory Reserve: Absolute (MB)</label>
                  <Input
                    type="number"
                    min={0}
                    disabled={loading || !apiReq.set_memory_reserve || apiReq.set_memory_reserve_to_default}
                    value={apiReq.memory_reserve.absolute || 0}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                      const { value } = data;
                      handleMemoryReserveChange("absolute", parseInt(value));
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Memory Reserve: Relative (%)</label>
                  <Input
                    type="number"
                    value={apiReq.memory_reserve.relative || 0}
                    disabled={loading || !apiReq.set_memory_reserve || apiReq.set_memory_reserve_to_default}
                    min={0}
                    max={100}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                      const { value } = data;
                      handleMemoryReserveChange("relative", parseInt(value));
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Set Default Disk Performance"
                    checked={apiReq.set_default_disk_performance}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      handleSetDefaultDiskPerformanceChange(Boolean(data.checked));
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Set Default Custom Storage Class"
                    checked={apiReq.set_default_custom_storage_class}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      handleSetDefaultCustomStorageClassChange(Boolean(data.checked));
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Set Default Communication Method"
                    checked={apiReq.set_default_communication_method}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      handleSetDefaultCommunicationMethodChange(Boolean(data.checked));
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Update Version"
                    checked={apiReq.update_version}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      handleUpdateVersionChange(Boolean(data.checked));
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <label>Comment</label>
                <TextArea
                  value={apiReq.comment}
                  disabled={loading}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                    const { value = "" } = data;
                    setApiReq({
                      ...apiReq,
                      comment: value as string,
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </ContentSegment>
          <StyledFilterHeader>
            Deployment filters <StyledIcon name="asterisk" color="orange" size="tiny" />
          </StyledFilterHeader>
          <DeploymentList
            {...props}
            filterView
            onDeploymentSelected={(id: string): void => {}}
            onOrganizationSelected={(id: string): void => {}}
            onProjectSelected={(id: string): void => {}}
            onDataClusterSelected={(id: string): void => {}}
            selectedFilters={apiReq.deployment_filter}
            onFilterChange={handleFilterChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel icon="close" title="Cancel" onClick={props.onClose} disabled={loading} />
          <FormActionButtonCreate primary icon="plus" title="Create Job" onClick={createUpdateJob} disabled={loading} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withRefresh()(CreateDeploymentUpdaterView);
