//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState, useEffect } from "react";
import { isEmpty, split } from "lodash";
import { Modal, Form, TextArea, TextAreaProps } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { ErrorMessage } from "../../ui/_errorMessage";
import { AsyncResult } from "../../util/Types";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";
import { FlexBox } from "../../ui/_flex";
import { StyledInlineLoader } from "../../ui/lib";

interface IDCLabelsEditModelViewArgs extends CommentCreationArgs {
  editLabels: boolean;
  labels: string[];
  onCancelEditLabels: () => void;
  onSaveEditLabels: () => Promise<AsyncResult<void>>;
  onChangeLabels: (newValue: string[]) => void;
}

const DCLabelsEditModalView = (args: IDCLabelsEditModelViewArgs) => {
  const textToStrings = (v: string) => {
    return isEmpty(v) ? [] : split(v, "\n");
  };

  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(args.editLabels);
  const [commentCreationFailed, setCommentCreationFailure] = useState(false);

  useEffect(() => {
    setOpen(args.editLabels);
  }, [args.editLabels]);

  const onSave = async () => {
    setLoading(true);
    const { error } = (!commentCreationFailed && (await args.onSaveEditLabels())) || {};
    if (error) {
      setError(error);
      setLoading(false);
      return;
    }

    const { error: commentError } = await args.createComment(comment);
    if (commentError) {
      setError(commentError);
      setLoading(false);
      setCommentCreationFailure(true);
      return;
    }

    closeModal();
  };

  const closeModal = () => {
    setLoading(false);
    setError("");
    setComment("");
    setCommentCreationFailure(false);
    setOpen(false);
    args.onCancelEditLabels();
  };

  return (
    <Modal open={open}>
      <Modal.Header>
        <FlexBox justify="space-between">
          <span>Edit labels </span>
          {loading && <StyledInlineLoader inline />}
        </FlexBox>
      </Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={error} onDismiss={() => setError("")} />
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>
                Add labels (<i>One lable per line</i>)
              </label>
              <TextArea
                rows="10"
                disabled={commentCreationFailed}
                value={args.labels.join("\n")}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                  const { labels: existingLabels } = args;
                  const newLabels = textToStrings(data.value as string);

                  let newComment = "";
                  if (newLabels.length > 0) {
                    newComment = `Changed labels: ${newLabels.join(", ")}`;
                  } else {
                    newComment = existingLabels.length > 0 ? "Removed labels" : "";
                  }
                  setComment(newComment);
                  args.onChangeLabels(newLabels);
                }}
              />
            </Form.Field>
            <Form.Field required>
              <label>Please provide the reason for this change</label>
              <CommentInputField
                handleAddComment={() => {}}
                handleCommentChange={(value: string) => setComment(value)}
                commentCreationInProcess={false}
                comment={comment}
                showOnlyInputField
              />
              <sub>
                Comment box will be empty if there is no change in the <b>labels</b>. Otherwise, a default comment will be provided which can be changed if
                required.
              </sub>{" "}
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={closeModal} />
        {commentCreationFailed ? (
          <FormActionButtonSave onClick={onSave} disabled={!comment.trim() || loading} primary icon="refresh" title="Retry saving comments" />
        ) : (
          <FormActionButtonSave onClick={onSave} disabled={!comment.trim() || loading} primary />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DCLabelsEditModalView;
