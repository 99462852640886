//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Loader, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  PublicEmailProviderDomain as ApiPublicEmailProviderDomain,
  PublicEmailProviderDomainList as ApiPublicEmailProviderDomainList,
  ListPublicEmailProviderDomainsRequest as ApiListPublicEmailProviderDomainsRequest,
  IDOptions as ApiIDOptions,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import {
  Loading,
  LoaderBoxForTable as LoaderBox,
  ContentSegment,
  SecondaryMenu,
  PagingButtons,
  ErrorMessage,
  ListActionDelete,
  ListActionEdit,
  Processing,
  Confirm,
  ConfirmInfo,
} from "../../ui/lib";

import CreatePublicEmailProviderDomain from "./CreatePublicEmailProviderDomain";
import EditPublicEmailProviderDomain from "./EditPublicEmailProviderDomain";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Domain</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing an organization
interface IRowView {
  item: ApiPublicEmailProviderDomain;
  onClickEdit: () => void;
  onClickDelete: () => void;
  canUpdate: boolean;
  canDelete: boolean;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <div>{args.item.domain}</div>
    </Table.Cell>
    <Table.Cell>
      <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
    </Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        {args.canUpdate && <ListActionEdit onClick={args.onClickEdit} />}
        {args.canDelete && <ListActionDelete onClick={args.onClickDelete} />}
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the organization list
interface IListView {
  items: ApiPublicEmailProviderDomain[];
  loading: boolean;
  page: number;
  pageSize: number;
  canUpdate: boolean;
  canDelete: boolean;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickEdit: (publicEmailDomainProviderId: string) => void;
  onClickDelete: (publicEmailDomainProviderId: string, pattern: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView
          {...args}
          key={item.id}
          item={item}
          onClickEdit={() => args.onClickEdit(item.id || "")}
          onClickDelete={() => args.onClickDelete(item.id || "", item.domain || "")}
          canDelete={args.canDelete}
          canUpdate={args.canUpdate}
        />
      ))}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more public email provider domains" : "No public email provider domains found"}</div>;

// Interface describing the public email provider domain list view arguments
export interface IPublicEmailProviderDomainsListViewArgs extends RouteComponentProps {
  loading: boolean;
  domains?: ApiPublicEmailProviderDomainList;
  page: number;
  pageSize: number;
  canUpdate: boolean;
  canDelete: boolean;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickEdit: (publicEmailDomainProviderId: string) => void;
  onClickDelete: (publicEmailDomainProviderId: string, pattern: string) => void;
}

export const PublicEmailProviderDomainsListView = ({ ...args }: IPublicEmailProviderDomainsListViewArgs) => {
  if (!args.domains) {
    return <Loading />;
  }
  const isEmpty = !args.domains.items || args.domains.items.length === 0;
  const domains = args.domains ? args.domains.items : undefined;
  return (
    <div>
      <ListView {...args} items={domains || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the email pattern list component
interface IPublicEmailProviderDomainListProps extends IWithRefreshProps, RouteComponentProps {}

// Interface decribing the state of the email pattern list component
interface IPublicEmailProviderDomainListState {
  domains?: ApiPublicEmailProviderDomainList;
  page: number;
  pageSize: number;
  createVisible: boolean;
  editVisible: boolean;
  editId: string;
  deleting: boolean;
  errorMessage?: string;
  confirmDeletePublicEmailProviderDomain?: ConfirmInfo;
}

// The component to show email domains as a list.
class PublicEmailProviderDomainList extends Component<IPublicEmailProviderDomainListProps, IPublicEmailProviderDomainListState> {
  state: IPublicEmailProviderDomainListState = {
    domains: undefined,
    page: 0,
    pageSize: 30,
    createVisible: false,
    editVisible: false,
    editId: "",
    deleting: false,
    errorMessage: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadPublicEmailProviderDomains = async () => {
    var req: ApiListPublicEmailProviderDomainsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    const domains = await apiClients.idashboardClient.ListPublicEmailProviderDomains(req);
    this.setState({ domains: domains });
  };

  refreshPublicEmailProviderDomains = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPublicEmailProviderDomains);
  };

  componentDidMount() {
    this.refreshPublicEmailProviderDomains();
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshPublicEmailProviderDomains
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshPublicEmailProviderDomains
    );
  };

  onPublicEmailProviderDomainCreated = (id: string) => {
    this.setState({ createVisible: false });
    this.refreshPublicEmailProviderDomains();
  };

  onPublicEmailProviderDomainSaved = (id: string) => {
    this.setState({
      editVisible: false,
      editId: "",
    });
    this.refreshPublicEmailProviderDomains();
  };

  onClickEdit = (id: string) => {
    this.setState({
      editVisible: true,
      editId: id,
    });
  };

  onClickDelete = async (id: string, domain: string) => {
    const confirmInfo: ConfirmInfo = {
      header: "Delete public email provider domain",
      content: `Do you want to remove the domain '${domain}'?`,
      warning: "This will change who has access to the service without mobile phone verification!",
      onConfirm: async () => {
        this.setState({
          deleting: true,
          confirmDeletePublicEmailProviderDomain: undefined,
        });
        try {
          const req: ApiIDOptions = {
            id: id,
          };
          await apiClients.idashboardClient.DeletePublicEmailProviderDomain(req);
          this.refreshPublicEmailProviderDomains();
        } catch (e) {
          this.setState({ errorMessage: `Domain saving failed: ${e}` });
        }
        this.setState({ deleting: false });
      },
      onDenied: () => {
        this.setState({ confirmDeletePublicEmailProviderDomain: undefined });
      },
    };
    this.setState({
      confirmDeletePublicEmailProviderDomain: confirmInfo,
    });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const domains = this.state.domains;
    const page = this.state.page;
    const has_update = this.hasPermission("internal-dashboard.publicemailproviderdomain.update");
    const has_delete = this.hasPermission("internal-dashboard.publicemailproviderdomain.delete");

    return (
      <ContentSegment>
        <Confirm confirmInfo={this.state.confirmDeletePublicEmailProviderDomain} />
        <Processing active={this.state.deleting} message="Deleting public email provider domain, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Public Email Provider Domains</Menu.Item>
          <CreatePublicEmailProviderDomain
            {...this.props}
            visible={this.state.createVisible}
            onClose={() => {
              this.setState({ createVisible: false });
            }}
            onPublicEmailProviderDomainCreated={this.onPublicEmailProviderDomainCreated}
          />
          <Button icon="plus" size="tiny" basic onClick={() => this.setState({ createVisible: true })} />
          <EditPublicEmailProviderDomain
            {...this.props}
            id={this.state.editVisible ? this.state.editId : ""}
            visible={this.state.editVisible}
            onClose={() => {
              this.setState({ editVisible: false });
            }}
            onPublicEmailProviderDomainSaved={this.onPublicEmailProviderDomainSaved}
          />
        </SecondaryMenu>
        <PublicEmailProviderDomainsListView
          {...this.props}
          domains={domains}
          onNextPage={this.onNextPage}
          onPreviousPage={page > 0 ? this.onPreviousPage : undefined}
          page={page}
          pageSize={this.state.pageSize}
          canDelete={has_delete}
          canUpdate={has_update}
          onClickEdit={this.onClickEdit}
          onClickDelete={this.onClickDelete}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(PublicEmailProviderDomainList);
