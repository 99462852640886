//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Loader, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { ProjectList as ApiProjectList, Project as ApiProject, Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { RouteComponentProps } from "react-router-dom";
import { LoaderBoxForTable as LoaderBox, ContentSegment, SecondaryMenu, ListActionView, Locked, Loading, ErrorMessage, TextLink } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

// Interface describing a project
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a projects
interface IRowView {
  active: boolean;
  item: ApiProject;
  onClickView: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <TextLink label={args.item.name} href={Routes.dashboard_sales_project_detailsWithId(args.item.id as string)} />
      {!!args.item.locked && <Locked />}
    </Table.Cell>
    <Table.Cell>{args.item.description}</Table.Cell>
    <Table.Cell>
      <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
    </Table.Cell>
    <Table.Cell>{args.item.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        <ListActionView disabled={!args.active} onClick={args.onClickView} />
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the project
interface IListView {
  active: boolean;
  items: ApiProject[];
  loading: boolean;
  onClickView: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} onClickView={() => args.onClickView(item.id || "")} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No project inside this organization</div>;

// Interface describing the project list view arguments
export interface IProjectListViewArgs extends RouteComponentProps {
  active: boolean;
  loading: boolean;
  organization: ApiOrganization;
  projects?: ApiProjectList;
  onClickView: (id: string) => void;
}

export const ProjectListView = ({ ...args }: IProjectListViewArgs) => {
  if (!args.projects) {
    return <Loading />;
  }
  if (!args.projects.items || args.projects.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.projects.items} loading={args.loading} />;
};

// Interface decribing the properties of the project list component
interface IProjectListProps extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  onProjectSelected: (projectId: string) => void;
  showHeader: boolean;
}

// Interface decribing the state of the project list component
interface IProjectListState {
  errorMessage?: string;
  processing: boolean;
  projects?: ApiProjectList;
}

// The component to show the projects inside an organization as a list.
class ProjectList extends Component<IProjectListProps, IProjectListState> {
  state: IProjectListState = {
    errorMessage: undefined,
    processing: false,
    projects: undefined,
  };

  reloadProjects = async () => {
    const listOptions: ApiListOptions = { context_id: this.props.organization.id };
    const projects = await apiClients.idashboardClient.ListProjects(listOptions);
    this.setState({ projects: projects });
  };

  getProjectName = (id: string) => {
    const projects = this.state.projects as ApiProjectList | undefined;
    if (projects && projects.items) {
      const project = projects.items.find((p) => p.id == id);
      if (project) {
        return project.name;
      }
    }
    return "";
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadProjects, `${this.props.organization.url}/Project/*`);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        {this.props.showHeader && (
          <SecondaryMenu>
            <Menu.Item header>Projects</Menu.Item>
          </SecondaryMenu>
        )}
        <ProjectListView {...this.props} {...this.state} active={!this.state.processing} onClickView={this.props.onProjectSelected} />
      </ContentSegment>
    );
  }
}

export default withRefresh()(ProjectList);
