//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Currency as ApiCurrency, ConvertRequest as ApiConvertRequest, ConvertResponse as ApiConvertResponse } from "../../api/lib";
import { ErrorMessage } from "../../ui/lib";

interface ISampleArgs {
  currency_id: string;
  system_default_currency: ApiCurrency;
  oneBaseEqualsXCurrency: number;
  oneCurrencyEqualsXBase: number;
}

const SampleView = ({ ...args }: ISampleArgs) => {
  return (
    <div>
      1 {args.currency_id} = {args.oneCurrencyEqualsXBase} {args.system_default_currency.id}
      <br />1 {args.system_default_currency.id} = {args.oneBaseEqualsXCurrency} {args.currency_id}
    </div>
  );
};

interface IAPI {
  Convert: (req: ApiConvertRequest) => Promise<ApiConvertResponse>;
}

// Interface decribing the properties of the conversion sample component
interface IConversionSampleProps {
  api: IAPI;
  system_default_currency: ApiCurrency;
  currency_id: string;
  rate: number;
}

// Interface decribing the state of the conversion sample component
interface IConversionSampleState {
  rate: number;
  currency_id: string;
  errorMessage?: string;
  oneBaseEqualsXCurrency: number;
  oneCurrencyEqualsXBase: number;
  refreshNeeded: boolean;
}

// The component to conversion sample
class ConversionSample extends Component<IConversionSampleProps, IConversionSampleState> {
  state: IConversionSampleState = {
    rate: this.props.rate,
    currency_id: this.props.currency_id,
    errorMessage: undefined,
    oneBaseEqualsXCurrency: 0,
    oneCurrencyEqualsXBase: 0,
    refreshNeeded: false,
  };

  static getDerivedStateFromProps(props: IConversionSampleProps, state: IConversionSampleState) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.rate !== state.rate || props.currency_id !== state.currency_id) {
      return {
        rate: props.rate,
        currency_id: props.currency_id,
        refreshNeeded: true,
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshSamples();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshSamples);
    }
  }

  refreshSamples = async () => {
    try {
      const req1: ApiConvertRequest = {
        amount: 1.0,
        from_currency_id: this.props.system_default_currency.id,
        to_currency_id: this.props.currency_id,
      };
      const oneBaseEqualsXCurrency = await this.props.api.Convert(req1);
      const req2: ApiConvertRequest = {
        amount: 1.0,
        from_currency_id: this.props.currency_id,
        to_currency_id: this.props.system_default_currency.id,
      };
      const oneCurrencyEqualsXBase = await this.props.api.Convert(req2);
      this.setState({
        errorMessage: undefined,
        oneBaseEqualsXCurrency: oneBaseEqualsXCurrency.amount || 0,
        oneCurrencyEqualsXBase: oneCurrencyEqualsXBase.amount || 0,
      });
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  render() {
    return (
      <div>
        <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} />
        <SampleView {...this.props} {...this.state} />
      </div>
    );
  }
}

export default ConversionSample;
