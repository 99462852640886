//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import humanize from "humanize";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dropdown, Menu, Message, Statistic, Table, Modal, Header, Segment, Grid, Input } from "semantic-ui-react";
import apiClients, { Cached as cachedApiClients } from "../../api/apiclients";
import {
  InvoiceStatistics as ApiInvoiceStatistics,
  InvoiceStatistics_CurrencyValue as ApiInvoiceStatistcsCurrencyValue,
  ListAllInvoicesRequest as ApiListAllInvoicesRequest,
  InvoiceInfo as ApiInvoiceInfo,
  InvoiceInfoList as ApiInvoiceInfoList,
} from "../../api/billing/v1/ibilling";
import {
  Currency as ApiCurrency,
  CurrencyList as ApiCurrencyList,
  Invoice as ApiInvoice,
  EntityDetailsList as ApiEntityList,
  GetPreliminaryInvoiceRequest as ApiGetPreliminaryInvoiceRequest,
} from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  CreationDateFilter,
  CreationDateFilters,
  ErrorMessage,
  ListActionDownload,
  Loading,
  PagingButtons,
  SecondaryMenu,
  TextLink,
  Processing,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  FieldSet,
  momentDefaultFormat,
  momentNow,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { DownloadInvoice } from "./DownloadInvoice";
import styled from "@emotion/styled";
import { Routes } from "../../routes";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import CreateReportButtonView from "../reports/CreateReportButton";

const ScrollingSegment = styled(Segment)`
  max-height: 20em;
  overflow-y: scroll;
`;

const AmountSpan = styled("span")`
  min-width: 10em;
  text-align: right;
  display: inline-block;
`;

interface IPreliminaryInvoiceViewArgs {
  invoice: ApiInvoice;
  currency: ApiCurrency;
}

export const PreliminaryInvoiceView = ({ ...args }: IPreliminaryInvoiceViewArgs) => {
  const items = args.invoice.items || [];
  const has_items = !_.isEmpty(items);
  const purchase_order_based = !!args.invoice.purchase_order_based;
  const curSign = args.currency.sign;
  const formatAmount = (amount: number) => (
    <span>
      {humanize.numberFormat(amount)}&nbsp;{curSign}
    </span>
  );
  const formatPercentage = (amount: number) => <span>{humanize.numberFormat(100 * amount)}%</span>;
  return (
    <FieldSet>
      <Header sub>General</Header>
      <Field>
        <FL>Type</FL>
        <FC>{purchase_order_based ? "Purchase order" : "Regular"}</FC>
      </Field>
      <Field>
        <FL>Invoice builder</FL>
        <FC>{args.invoice.invoice_builder_version || "-"}</FC>
      </Field>
      <Field>
        <FL>Currency</FL>
        <FC>
          {args.currency.name}&nbsp;({curSign})
        </FC>
      </Field>
      <Field>
        <FL>Items</FL>
        <FC>{items.length}</FC>
      </Field>
      <ScrollingSegment>
        {has_items && (
          <Grid>
            {items.map((x, i) => (
              <Grid.Row key={`item${i}`} columns={16}>
                <Grid.Column width={14}>{x.description}</Grid.Column>
                <Grid.Column width={2}>{formatAmount(x.amount || 0)}</Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        )}
        {!has_items && <div>Invoice has no items</div>}
      </ScrollingSegment>
      <Field>
        <FL>Total excl taxes</FL>
        <FC>
          <AmountSpan>{formatAmount(args.invoice.total_amount_excl_taxes || 0)}</AmountSpan>
        </FC>
      </Field>
      <Field>
        <FL>VAT ({formatPercentage(args.invoice.vat_percentage_used || 0)})</FL>
        <FC>
          <AmountSpan>{formatAmount(args.invoice.total_vat || 0)}</AmountSpan>
        </FC>
      </Field>
      <Field>
        <FL>Sales tax ({formatPercentage(args.invoice.sales_tax_percentage_used || 0)})</FL>
        <FC>
          <AmountSpan>{formatAmount(args.invoice.total_sales_tax || 0)}</AmountSpan>
        </FC>
      </Field>
      <Field>
        <FL>Total incl taxes</FL>
        <FC>
          <AmountSpan>{formatAmount(args.invoice.total_amount_incl_taxes || 0)}</AmountSpan>
        </FC>
      </Field>
    </FieldSet>
  );
};

export enum Status {
  All = "All",
  Verified = "Verified",
  NonVerified = "NonVerified",
  VerifiedAndPending = "VerifiedAndPending",
  Pending = "Pending",
  Completed = "Completed",
  Rejected = "Rejected",
  RejectedAndCollectible = "RejectedAndCollectible",
  NeedsRebuild = "NeedsRebuild",
  NonCollectible = "NonCollectible",
  Collectible = "Collectible",
}

export enum InvoiceType {
  All = "All",
  Regular = "Regular",
  PurchaseOrder = "PurchaseOrder",
}

interface IFilterViewArgs {
  organization_id?: string;
  onChangeInvoiceNumberInputField: (value: string) => void;
  onChangeOrgIdInputField: (value: string) => void;
  selectedStatus: Status;
  onStatusChanged: (status: string) => void;

  selectedInvoiceType: InvoiceType;
  onInvoiceTypeChanged: (invoiceType: string) => void;

  selectedEntityID?: string;
  entities?: ApiEntityList;
  onEntitySelected: (id: string) => void;

  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;

  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;

  onDownloadCSV: () => void;
  onDownloadInvoicesZIP: () => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const statusText = (status: string) => {
    return _.capitalize(_.replace(status, "Not", "Not "));
  };
  const statusOptions = Object.keys(Status).map((x) => {
    return {
      key: `status-${x}`,
      text: statusText(x),
      value: x,
    };
  });
  const allEntities = [{ key: "", text: "All", value: "" }];
  const entityOptions = allEntities.concat(
    _.map(args.entities && args.entities.items, (entity) => ({
      key: entity.id || "",
      text: entity.name || "",
      value: entity.id || "",
    }))
  );

  const selectedInvoiceTypeOptions = Object.keys(InvoiceType).map((invoiceType) => ({
    key: `invoice-${invoiceType}`,
    text: invoiceType,
    value: invoiceType,
  }));

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown item text="Search">
        <Dropdown.Menu>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Invoice number"
              name="search"
              onChange={(e, d) => args.onChangeInvoiceNumberInputField(d.value)}
              onClick={(e: any) => e.stopPropagation()}
            />
          </Menu.Item>
          <Menu.Item>
            {!args.organization_id && (
              <Input
                icon="search"
                iconPosition="left"
                placeholder="Organization ID"
                name="search"
                onChange={(e, d) => args.onChangeOrgIdInputField(d.value)}
                onClick={(e: any) => e.stopPropagation()}
              />
            )}
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown
        item
        value={args.selectedStatus}
        text={`Status: ${getOptionText(statusOptions, args.selectedStatus) || "?"}`}
        options={statusOptions}
        onChange={(e, d) => args.onStatusChanged(d.value as string)}
      />
      <Dropdown
        item
        value={args.selectedInvoiceType}
        text={`Invoice Type: ${getOptionText(selectedInvoiceTypeOptions, args.selectedInvoiceType) || "?"}`}
        options={selectedInvoiceTypeOptions}
        onChange={(_, input) => args.onInvoiceTypeChanged(input.value as string)}
      />
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Created: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
      <Dropdown
        item
        scrolling
        value={args.selectedEntityID}
        text={`Entity: ${getOptionText(entityOptions, args.selectedEntityID || "") || "?"}`}
        options={entityOptions}
        onChange={(e, d) => args.onEntitySelected(d.value as string)}
      />
      <Menu.Item position="right" fitted="vertically">
        <PagingButtons {...args} size="tiny" />
      </Menu.Item>
      <Menu.Item position="right">
        <CreateReportButtonView include_invoice_statistics include_pending_invoices include_rejected_invoices />
        <Button circular icon="file excel" onClick={args.onDownloadCSV} />
        <Button circular icon="file archive" onClick={args.onDownloadInvoicesZIP} />
      </Menu.Item>
    </Menu>
  );
};

interface InvoiceStatisticsCurrencyValueViewArgs {
  value: ApiInvoiceStatistcsCurrencyValue;
}
const StatistcsCurrencyValuesView = ({ ...args }: InvoiceStatisticsCurrencyValueViewArgs) => {
  const value = args.value;
  return (
    <Statistic.Group widths="7" size="tiny">
      <Statistic label="Currrency" value={value.currency_id} />
      <Statistic label="Total value" value={humanize.numberFormat(value.total_value || 0)} />
      <Statistic label="Total value (incl tax)" value={humanize.numberFormat(value.total_value_including_taxes || 0)} />
      <Statistic label="Total value non collectible" value={humanize.numberFormat(value.total_non_collectible_value || 0)} />
      <Statistic label="Pending" value={humanize.numberFormat(value.pending_value || 0)} />
      <Statistic label="Completed" value={humanize.numberFormat(value.completed_value || 0)} />
      <Statistic label="Rejected" value={humanize.numberFormat(value.rejected_value || 0)} />
    </Statistic.Group>
  );
};

interface IStatisticsViewArgs {
  statistics: ApiInvoiceStatistics;
  filterFrom?: Moment;
  filterTo?: Moment;
}
const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const st = args.statistics;
  const currency_values = st.currency_values || [];
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";
  return (
    <div>
      <Message>Invoices {timeFilter}.</Message>
      <Statistic.Group widths="5" size="tiny">
        <Statistic label="All invoices" value={st.total_invoices || 0} />
        <Statistic label="Verified" value={st.verified_invoices || 0} />
        <Statistic label="Pending" value={st.pending_invoices || 0} />
        <Statistic label="Completed" value={st.completed_invoices || 0} />
        <Statistic label="Rejected" value={st.rejected_invoices || 0} />
      </Statistic.Group>
      {currency_values.map((item) => (
        <StatistcsCurrencyValuesView value={item} key={item.currency_id} />
      ))}
    </div>
  );
};

const HeaderView = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Number</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Organization</Table.HeaderCell>
      <Table.HeaderCell>Amount</Table.HeaderCell>
      <Table.HeaderCell>Amount / Day</Table.HeaderCell>
      <Table.HeaderCell>Verification</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiInvoiceInfo;
  nonCollectible: boolean;
  currency: ApiCurrency;
  onInvoiceSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onDownload: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const invoice = args.item ? args.item.invoice || {} : {};
  const { is_canceled } = args.item;
  const status = invoice.status || {};
  const is_pending = !!status.is_pending;
  const is_completed = !is_pending && status.is_completed;
  const is_rejected = !is_pending && status.is_rejected;
  const needs_verification = !!invoice.requires_manual_verification;
  const is_verified = !!status.is_verified;
  const purchase_order_based = !!invoice.purchase_order_based;
  const curSign = args.currency.sign;
  const formatAmount = (amount: number) => {
    return `${humanize.numberFormat(amount)} ${curSign}`;
  };
  console.log({ from: args.item.usage_items_from, to: args.item.usage_items_to });
  return (
    <Table.Row negative={args.nonCollectible || is_canceled}>
      <Table.Cell>
        <TextLink href={Routes.dashboard_sales_invoices_detailsWithId(invoice.id || "")}>{invoice.invoice_number}</TextLink>
      </Table.Cell>
      <Table.Cell>{purchase_order_based ? "Purchase order" : "Regular"}</Table.Cell>
      <Table.Cell>
        <TextLink href={Routes.dashboard_sales_organization_detailsWithId(invoice.organization_id || "")}>{invoice.organization_name}</TextLink>
      </Table.Cell>
      <Table.Cell textAlign="right">{formatAmount(invoice.total_amount_excl_taxes || 0)}</Table.Cell>
      <Table.Cell textAlign="right">
        {formatAmount((invoice.total_amount_excl_taxes || 0) / moment(args.item.usage_items_to).diff(moment(args.item.usage_items_from), "days"))}
      </Table.Cell>
      <Table.Cell>
        {!needs_verification && <span>Auto verified</span>}
        {needs_verification && <span>{is_verified ? "Verified" : "Not verified"}</span>}
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={invoice.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>
        {is_canceled && <span>CANCELED</span>}
        {!is_canceled && is_pending && <span>Pending</span>}
        {!is_canceled && is_completed && <span>Completed</span>}
        {!is_canceled && is_rejected && <span>Rejected</span>}
      </Table.Cell>
      <Table.Cell>
        <ListActionDownload onClick={args.onDownload} />
      </Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  items: ApiInvoiceInfo[];
  currencies: ApiCurrency[];
  onInvoiceSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onDownload: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView />
    <Table.Body>
      {args.items.map((item) => (
        <RowView
          {...args}
          key={item.invoice && item.invoice.id}
          item={item}
          nonCollectible={item.non_collectible || false}
          currency={_.find(args.currencies, (x) => x.id == (item.invoice ? item.invoice.currency_id : "")) || {}}
          onDownload={() => args.onDownload((item.invoice && item.invoice.id) || "")}
        />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div></div>;

// Interface describing the invoice list view arguments
export interface IInvoiceListViewArgs extends IFilterViewArgs, RouteComponentProps {
  invoiceInfos?: ApiInvoiceInfoList;
  statistics?: ApiInvoiceStatistics;
  currencies?: ApiCurrencyList;
  onInvoiceSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onDownload: (id: string) => void;
  filterFrom?: Moment;
  filterTo?: Moment;
  onDownloadCSV: () => void;
  onDownloadInvoicesZIP: () => void;
}

const InvoiceListContentView = ({ ...args }: IInvoiceListViewArgs) => {
  const invoicesList = args.invoiceInfos || {};
  const invoiceInfos = invoicesList.items || [];
  const currencyList = args.currencies || {};
  const currencies = currencyList.items || [];

  if (_.isEmpty(invoiceInfos)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={invoiceInfos} currencies={currencies} />;
};

export const InvoiceListView = ({ ...args }: IInvoiceListViewArgs) => {
  if (!args.currencies || !args.invoiceInfos) {
    return <Loading />;
  }
  return (
    <div>
      <FilterView {...args} />
      {!args.statistics && <Message>Loading statistics....</Message>}
      {args.statistics && <StatisticsView {...args} statistics={args.statistics || {}} />}
      <InvoiceListContentView {...args} />
    </div>
  );
};

// Interface decribing the properties of the invoice list component
interface IInvoiceListProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  organization_id?: string;
  showHeader: boolean;
  onInvoiceSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
}

// Interface decribing the state of the invoice list component
interface IInvoiceListState {
  errorMessage?: string;
  invoiceInfos?: ApiInvoiceInfoList;
  statistics?: ApiInvoiceStatistics;
  filterFrom?: Moment;
  filterTo?: Moment;
  currencies?: ApiCurrencyList;
  downloading: boolean;
  preliminaryInvoice?: ApiInvoice;
  currency?: ApiCurrency;
  gettingPreliminaryInvoice: boolean;
  selectedStatus: Status;
  selectedInvoiceType: InvoiceType;
  page: number;
  pageSize: number;
  selectedCreationDateFilterID: string;
  entities?: ApiEntityList;
  selectedEntityID: string;
  invoiceNumber: string;
  orgId: string;
}

// The component to show the invoices as a list.
class InvoiceList extends Component<IInvoiceListProps, IInvoiceListState> {
  state: IInvoiceListState = {
    errorMessage: undefined,
    invoiceInfos: undefined,
    statistics: undefined,
    filterFrom: undefined,
    filterTo: undefined,
    downloading: false,
    preliminaryInvoice: undefined,
    currency: undefined,
    gettingPreliminaryInvoice: false,
    selectedStatus: Status.All,
    selectedInvoiceType: InvoiceType.All,
    page: 0,
    pageSize: 10,
    selectedCreationDateFilterID: "all",
    entities: undefined,
    selectedEntityID: "",
    invoiceNumber: "",
    orgId: "",
  };
  buildRequest = async () => {
    const currencies = await cachedApiClients.idashboardClient.ListCurrencies({});
    this.setState({ currencies: currencies });
    const req: ApiListAllInvoicesRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
      invoice_number: this.state.invoiceNumber,
    };
    if (!!this.props.organization_id) {
      req.organization_id = this.props.organization_id;
    }
    if (!!this.state.orgId) {
      req.organization_id = this.state.orgId;
    }
    switch (this.state.selectedStatus) {
      case Status.Verified:
        req.verified_only = true;
        break;
      case Status.NonVerified:
        req.non_verified_only = true;
        break;
      case Status.VerifiedAndPending:
        req.verified_only = true;
        req.pending_only = true;
        break;
      case Status.Pending:
        req.pending_only = true;
        break;
      case Status.Completed:
        req.completed_only = true;
        break;
      case Status.Rejected:
        req.rejected_only = true;
        break;
      case Status.RejectedAndCollectible:
        req.rejected_only = true;
        req.collectible_only = true;
        break;
      case Status.NeedsRebuild:
        req.needs_rebuild_only = true;
        break;
      case Status.Collectible:
        req.collectible_only = true;
        break;
      case Status.NonCollectible:
        req.non_collectible_only = true;
        break;
    }

    switch (this.state.selectedInvoiceType) {
      case InvoiceType.Regular:
        req.type = "Regular";
        break;
      case InvoiceType.PurchaseOrder:
        req.type = "PurchaseOrder";
        break;
    }

    let filterFrom: Moment | undefined = undefined;
    let filterTo: Moment | undefined = undefined;
    if (this.state.selectedCreationDateFilterID != "all") {
      const f = CreationDateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
      if (f) {
        if (f.filterStart) {
          filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          req.from = filterFrom.toDate();
        }
        if (f.filterEnd) {
          filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          req.to = filterTo.toDate();
        }
      }
    }
    this.setState({
      filterFrom: filterFrom,
      filterTo: filterTo,
    });
    req.entity_id = this.state.selectedEntityID;
    return req;
  };

  reloadInvoices = async () => {
    const req = await this.buildRequest();
    const invoiceInfos = await apiClients.idashboardClient.ListAllInvoiceInfos(req);
    this.setState({ invoiceInfos });
    req.options = undefined;
    const statistics = await apiClients.idashboardClient.GetInvoiceStatistics(req);
    this.setState({
      statistics: statistics,
    });
  };

  refreshInvoices = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadInvoices);
  };

  componentDidMount() {
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadInvoices, 60000);
    this.loadEntites();
  }

  loadEntites = async () => {
    this.setState({ errorMessage: undefined });
    try {
      const entities = await apiClients.idashboardClient.ListEntities({});
      this.setState({ entities: entities });
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onDownloadInvoice = async (invoiceID: string) => {
    try {
      this.setState({ downloading: true, errorMessage: undefined });
      DownloadInvoice(invoiceID, this.props.auth);
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ downloading: false });
    }
  };
  onDownloadInvoicesZIP = async () => {
    try {
      this.setState({ downloading: true, errorMessage: undefined });
      const req = await this.buildRequest();
      await apiClients.idashboardClient.CreateInvoicesZIP(req);
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ downloading: false });
    }
  };

  onDownloadCSV = async () => {
    try {
      this.setState({ downloading: true, errorMessage: undefined });
      const req = await this.buildRequest();
      await apiClients.idashboardClient.ListAllInvoicesCSV(req);
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ downloading: false });
    }
  };

  onGetPreliminaryInvoice = async () => {
    try {
      this.setState({ gettingPreliminaryInvoice: true, errorMessage: undefined });
      const req: ApiGetPreliminaryInvoiceRequest = {
        organization_id: this.props.organization_id,
      };
      const invoice = await apiClients.idashboardClient.GetPreliminaryInvoice(req);
      let currencies = this.state.currencies;
      if (!!currencies) {
        currencies = await cachedApiClients.idashboardClient.ListCurrencies({});
        this.setState({ currencies: currencies });
      }
      const items = (currencies && currencies.items) || [];
      const currency = _.find(items, (c) => c.id == invoice.currency_id) || {};
      this.setState({ preliminaryInvoice: invoice, currency: currency });
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ gettingPreliminaryInvoice: false });
    }
  };

  onClosePreliminaryInvoiceModal = () => {
    this.setState({ preliminaryInvoice: undefined, currency: undefined });
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshInvoices
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshInvoices
    );
  };

  onChangeInvoiceNumberInputField = (value: string) => {
    this.setState({ invoiceNumber: value }, this.refreshInvoices);
  };
  onChangeOrgIdInputField = (value: string) => {
    this.setState({ orgId: value }, this.refreshInvoices);
  };

  onStatusChanged = (status: string) => {
    this.setState(
      {
        page: 0,
        selectedStatus: status as Status,
      },
      this.refreshInvoices
    );
  };

  onInvoiceTypeChanged = (invoiceType: string) => {
    this.setState(
      {
        page: 0,
        selectedInvoiceType: invoiceType as InvoiceType,
      },
      this.refreshInvoices
    );
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState(
      {
        selectedCreationDateFilterID: id,
        page: 0,
      },
      this.refreshInvoices
    );
  };
  onEntitySelected = (id: string) => {
    this.setState({ selectedEntityID: id }, this.refreshInvoices);
  };

  render() {
    const invoiceList = this.state.invoiceInfos || {};
    const invoices = invoiceList.items || [];
    const showPreliminaryInvoiceModal = !!this.state.preliminaryInvoice && !!this.state.currency;

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Processing active={this.state.downloading} message="Downloading, please wait..." />
        <Processing active={this.state.gettingPreliminaryInvoice} message="Getting preliminary invoice, please wait..." />
        {this.props.showHeader && (
          <SecondaryMenu>
            <Menu.Item header>Invoices</Menu.Item>
          </SecondaryMenu>
        )}
        {this.props.organization_id && (
          <Button
            content="Show preliminary invoice"
            size="mini"
            labelPosition="right"
            icon="money bill alternate outline"
            onClick={() => this.onGetPreliminaryInvoice()}
          />
        )}
        <Modal open={showPreliminaryInvoiceModal} onClose={() => this.onClosePreliminaryInvoiceModal()} size="fullscreen">
          <Modal.Header>Preliminary invoice</Modal.Header>
          <Modal.Content>
            <PreliminaryInvoiceView invoice={this.state.preliminaryInvoice || {}} currency={this.state.currency || {}} />
          </Modal.Content>
        </Modal>
        <InvoiceListView
          {...this.props}
          {...this.state}
          count={invoices.length}
          creationDateFilters={CreationDateFilters}
          onDownload={this.onDownloadInvoice}
          onDownloadCSV={this.onDownloadCSV}
          onDownloadInvoicesZIP={this.onDownloadInvoicesZIP}
          onStatusChanged={this.onStatusChanged}
          onInvoiceTypeChanged={this.onInvoiceTypeChanged}
          onCreationDateFilterChanged={this.onCreationDateFilterChanged}
          onNextPage={this.onNextPage}
          onPreviousPage={this.onPreviousPage}
          onEntitySelected={this.onEntitySelected}
          onChangeInvoiceNumberInputField={this.onChangeInvoiceNumberInputField}
          onChangeOrgIdInputField={this.onChangeOrgIdInputField}
          entities={this.state.entities}
          selectedEntityID={this.state.selectedEntityID}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(InvoiceList);
