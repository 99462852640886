//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import { RequestEventArgs, Message } from "./Events";
import { v1 as uuidv1 } from "uuid";

class Subscription {
  id: string;
  callback: () => void;

  constructor(id: string, callback: () => void) {
    this.id = id;
    this.callback = callback;
  }
}

export interface IWebSocketRef {
  getWebSocket(): any;
}

export class EventSubscriptionManager {
  private ref: IWebSocketRef;

  private subscriptions = new Array<Subscription>();

  private timerRef: NodeJS.Timeout;
  /**
   * Creates a new EventSubscriptionManager
   */
  constructor(ref: IWebSocketRef) {
    this.ref = ref;

    // Call handleServerEvent every 2 minutes once.
    // Notice that the event meganism doesn't have a QoS, so we use this as fallback
    this.timerRef = setInterval(this.handleServerEvent, 2 * 60 * 1000);
  }

  stopTimer = () => {
    clearInterval(this.timerRef);
  };

  // webSocketURL builds the URL of the event websocket
  webSocketURL = (): string => {
    let protocol = window.location.protocol == "https:" ? "wss:" : "ws:";
    let wsUrl = `${protocol}//${window.location.host}/ws/dashboard/v1/events`;
    return wsUrl;
  };

  // handleServerEvent is called when the server sends a message into the websocket.
  handleServerEvent = (data: any) => {
    //console.log(data);
    this.subscriptions.forEach((s) => s.callback());
  };

  subscribe = async (args: RequestEventArgs, callback: () => void): Promise<string> => {
    const id = await this.requestEvents(args);

    this.subscriptions.push(new Subscription(id, callback));

    return id;
  };

  unsubscribe = async (subscriptionID?: string) => {
    if (subscriptionID) {
      this.subscriptions = this.subscriptions.filter((s) => s.id !== subscriptionID);
      await this.cancelEvents(subscriptionID);
    }
  };

  // requestEvents requests events according to the given arguments.
  // An event subscription ID is returned.
  // To cancel events, pass this subscription ID to cancelEvents.
  private requestEvents = async (args: RequestEventArgs): Promise<string> => {
    const id = uuidv1();
    const msg = {
      type: "RequestEvents",
      subscription_id: id,
      args: args,
    } as Message;
    await this.ref.getWebSocket().sendMessage(JSON.stringify(msg));
    return id;
  };

  // cancelEvents cancels all events send by the server resulting from
  // a call to requestEvents.
  private cancelEvents = async (subscriptionID: string) => {
    let msg = {
      type: "CancelEvents",
      subscription_id: subscriptionID,
    } as Message;
    await this.ref.getWebSocket().sendMessage(JSON.stringify(msg));
  };
}
