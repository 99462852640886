//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import { RouteComponentProps } from "react-router-dom";
import { Button, Dropdown, Header, Icon, Loader } from "semantic-ui-react";
import {
  Deployment as APIDeployment,
  DeploymentInfo as APIDeploymentInfo,
  Deployment_CertificateSpec as ApiDeployment_CertificateSpec,
  Deployment_Status as ApiDeployment_Status,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import CACertificate from "./CACertificate";
import IPAllowlist from "./IPAllowlist";
import styled from "@emotion/styled";

const InlineDiv = styled("div")`
  display: inline-block;
`;

export interface IAccessViewArgs extends IWithRefreshProps, RouteComponentProps {
  certificates?: ApiDeployment_CertificateSpec;
  ipallowlist_id?: string;
  status?: ApiDeployment_Status;
  copiedRootPassword: boolean;
  loadingRootPassword: boolean;
  onCopyRootPassword: () => void;
  can_copy_root_password: boolean;
  deployment?: APIDeployment;
  deploymentInfo?: APIDeploymentInfo;
  can_rotate_credentials: boolean;
  onRotateCredentials: () => void;
  processingRotateCredentials: boolean;
  disable_foxx_authentication: boolean;
  can_edit_foxx_authentication: boolean;
  onUpdateFoxxAuthentication: (disable: boolean) => void;
}

export const AccessView = ({ ...args }: IAccessViewArgs) => {
  const certificates = args.certificates || {};
  const { private_endpoint: isPrivate } = args.deployment || {};
  const { replication } = args.deploymentInfo || {};
  const { enabled: isReplicationEnabled, sync_endpoint: syncEndpoint } = replication || {};
  return (
    <div>
      <Header sub>Access</Header>
      <Field>
        <FL>Endpoint</FL>
        <FC>
          {args.status && args.status.endpoint ? (
            <a href={args.status.endpoint} target="_blank" rel="noopener noreferrer">
              {!!isPrivate && <Icon name="privacy" color="orange" />}
              {args.status.endpoint} <Icon name="external alternate" />{" "}
            </a>
          ) : (
            "?"
          )}
        </FC>
      </Field>
      {isReplicationEnabled && (
        <Field>
          <FL>Sync Endpoint</FL>
          <FC>
            {syncEndpoint ? (
              <a href={syncEndpoint} target="_blank" rel="noopener noreferrer">
                {syncEndpoint} <Icon name="external alternate" />{" "}
              </a>
            ) : (
              "?"
            )}
          </FC>
        </Field>
      )}
      {args.can_copy_root_password && (
        <Field>
          <FL>Root password</FL>
          <FC>
            <InlineDiv>
              <Button basic icon={args.copiedRootPassword ? "check" : "copy"} size="mini" onClick={args.onCopyRootPassword} content="Copy" />
              {args.loadingRootPassword && <Loader size="mini" active />}
            </InlineDiv>
            {args.can_rotate_credentials && (
              <InlineDiv>
                <Button basic icon="sync" size="mini" onClick={args.onRotateCredentials} content="Rotate" />
                {args.processingRotateCredentials && <Loader size="mini" active />}
              </InlineDiv>
            )}
          </FC>
        </Field>
      )}
      <Field>
        <FL>CA Certificate</FL>
        <FC>
          <CACertificate {...args} caCertificateId={certificates.ca_certificate_id} alternateDnsNames={certificates.alternate_dns_names} />
        </FC>
      </Field>
      <Field>
        <FL>IP allowlist</FL>
        <FC>
          <IPAllowlist {...args} ipAllowlistId={args.ipallowlist_id} />
        </FC>
      </Field>
      <Field>
        <FL>Foxx authentication</FL>
        <FC>
          <span>{args.disable_foxx_authentication ? "Disabled" : "Enabled"}</span>
          {args.can_edit_foxx_authentication && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.disable_foxx_authentication && (
                  <Dropdown.Item key="remove-foxx-auth" text="Enable Foxx authentication" onClick={() => args.onUpdateFoxxAuthentication(false)} />
                )}
                {!args.disable_foxx_authentication && (
                  <Dropdown.Item key="set-foxx-auth" text="Disable Foxx authentication" onClick={() => args.onUpdateFoxxAuthentication(true)} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Platform authentication</FL>
        <FC>
          <span>{(args.deployment || {}).is_platform_authentication_enabled ? "Enabled" : "Disabled"}</span>
        </FC>
      </Field>
    </div>
  );
};
