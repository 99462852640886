//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import CommentsListWithInput, { StyledCommentsHolder } from "./CommentListWithInput";
import { CommentViewAccessor } from "./CommentsView";
import { ICommentIsolatedComponentArgs } from "./CommentTypes";

const CommentsSectionInline = ({
  onCommentsPageChange,
  createComment,
  commentCreationInProcess,
  canViewComments,
  canCreateComment,
  commentList,
  commentsFetchError,
  latestComments,
  lastestCommentsTriggered,
}: ICommentIsolatedComponentArgs) => {
  return (
    <>
      {canViewComments && (
        <>
          {canCreateComment && (
            <CommentViewAccessor
              comments={commentList}
              onCommentsPageChange={onCommentsPageChange}
              error={commentsFetchError}
              createComment={createComment}
              canCreate={canCreateComment}
              commentCreationInProcess={commentCreationInProcess}
              lastestCommentsTriggered={lastestCommentsTriggered}
            />
          )}
          <StyledCommentsHolder>
            <CommentsListWithInput
              comments={latestComments}
              onCommentsPageChange={onCommentsPageChange}
              error={commentsFetchError}
              createComment={createComment}
              canCreate={canCreateComment}
              commentCreationInProcess={commentCreationInProcess}
              lastestCommentsTriggered={lastestCommentsTriggered}
            />
          </StyledCommentsHolder>
        </>
      )}
    </>
  );
};
export default CommentsSectionInline;
