//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import CreateReportModalView from "./CreateReportModal";
import { CreateReportRequest_SalesReport as ApiSalesReport } from "../../api/lib";

const CreateReportButtonView = (options: ApiSalesReport) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} icon="mail" circular size="tiny" />
      {open && <CreateReportModalView options={options} onClose={() => setOpen(false)} />}
    </>
  );
};

export default CreateReportButtonView;
