//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import React from "react";

import { Dropdown } from "semantic-ui-react";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../../ui/lib";

import { useDeploymentStore } from "../../../util/storage/DeploymentStore";

import apiclients from "../../../api/apiclients";
import IConfigurationViewItemProps from "./IConigurationViewItemProps";
import { RenderGuard } from "../../../util/RenderGuard";

export const VSTSupportView = ({ ...args }: IConfigurationViewItemProps) => {
  const { deployment } = useDeploymentStore();

  const { requireComment, requirePermission } = args;
  const { idashboardClient } = apiclients;

  const onUpdateDropVSTSupport = async (disable: boolean) => {
    requireComment({
      defaultComment: `${disable ? "Disable" : "Enable"} VST support`,
      loadingMessage: `Updating vst support, please wait...`,
      onCommentConfirmation: async () => {
        await idashboardClient.UpdateDeploymentVSTEnabled({ deployment_id: deployment.id, vst_is_enabled: !disable });
        // TODO: trigger deployment update
      },
    });
  };

  return (
    <Field>
      <FL>VST Support</FL>
      <FC>
        <span>{deployment.drop_vst_support ? "Disabled" : "Enabled"}</span>
        <RenderGuard renderIf={requirePermission("data.deployment.update")}>
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!deployment.drop_vst_support ? (
                <Dropdown.Item key="disable" text="Disable" onClick={() => onUpdateDropVSTSupport(true)} />
              ) : (
                <Dropdown.Item key="enable" text="Enable" onClick={() => onUpdateDropVSTSupport(false)} />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </RenderGuard>
      </FC>
    </Field>
  );
};
