//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import { numberFormat } from "humanize";
import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Loader, Table, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ListUsageItemsRequest as ApiListUsageItemsRequest,
  UsageItem_BackupStorageSize as ApiUsageItemBackupStorageSize,
  UsageItem_DeploymentSize as ApiUsageItemDeploymentSize,
  UsageItem_NetworkTransferSize as ApiUsageItemNetworkTransferSize,
  UsageItem_AuditLogSize as ApiUsageItemAuditLogSize,
  UsageItem_AuditLogStorageSize as ApiUsageItemAuditLogStorageSize,
  UsageItem_NotebookSize as ApiUsageItemNotebookSize,
  UsageItem_Resource as ApiUsageItemResource,
  ListAllUsageItemsRequest as ApiListAllUsageItemsRequest,
  UsageItemInfo as ApiUsageItemInfo,
  Money,
  UsageItemInfoList as ApiUsageItemInfoList,
} from "../../api/lib";
import { ContentSegment, ErrorMessage, LoaderBoxForTable as LoaderBox, Loading, PagingButtons, momentNow } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import InvoiceLink from "../billing/InvoiceLink";
import ReactJson from "react-json-view";
import {
  DateTimeInUTC,
  DateTimeTransformationTypes,
  FormattedDateWithLabel,
  FormattedElapsedTimeWithLabel,
  HumanisedDuration,
  HUMANIZED_DATE_FORMAT,
} from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";
import UsageItemFilterView from "./UsageItemFilterView";

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  selectedKind: string;
  onSelectKind: (kind: string) => void;
}

const moneyAsNumber = (m?: Money): number => {
  if (!m) {
    return 0;
  }
  return Number(m.units || 0) + (m.nanos || 0) / 1e9;
};

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Resource ID</Table.HeaderCell>
        <Table.HeaderCell>Tier</Table.HeaderCell>
        <Table.HeaderCell>Location</Table.HeaderCell>
        <Table.HeaderCell>Usage</Table.HeaderCell>
        <Table.HeaderCell>Start</Table.HeaderCell>
        <Table.HeaderCell>End</Table.HeaderCell>
        <Table.HeaderCell>Duration</Table.HeaderCell>
        <Table.HeaderCell>Invoice</Table.HeaderCell>
        <Table.HeaderCell>CreditBundels</Table.HeaderCell>
        <Table.HeaderCell>Price</Table.HeaderCell>
        <Table.HeaderCell>Credits</Table.HeaderCell>
        <Table.HeaderCell>
          <PagingButtons {...args} size="small" />
          <LoaderBox>
            <Loader size="mini" active={args.loading} inline />
          </LoaderBox>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

interface IResourceViewArgs {
  resource?: ApiUsageItemResource;
}

const ResourceView = ({ ...args }: IResourceViewArgs) => {
  const res = args.resource || {};
  let names: Array<string> = [];
  if (res.organization_name) {
    names.push(res.organization_name);
  } else if (res.organization_id) {
    names.push(`organization: ${res.organization_id}`);
  }
  if (res.project_name) {
    names.push(res.project_name);
  } else if (res.project_id) {
    names.push(`project: ${res.project_id}`);
  }
  if (res.deployment_name) {
    names.push(res.deployment_name);
  } else if (res.deployment_id) {
    names.push(`deployment: ${res.deployment_id}`);
  }
  if (res.deployment_member_name) {
    names.push(res.deployment_member_name);
  }
  return <span>{names.join("/")}</span>;
};

interface IDeploymentSizeView {
  item: ApiUsageItemDeploymentSize;
}

const DeploymentSizeView = ({ ...args }: IDeploymentSizeView) => {
  const item = args.item;
  const totalMem =
    (item.agent_memory_size || 0) * (item.agents || 0) +
    (item.coordinator_memory_size || 0) * (item.coordinators || 0) +
    (item.dbserver_memory_size || 0) * (item.dbservers || 0);
  const totalDisk = (item.agent_disk_size || 0) * (item.agents || 0) + (item.dbserver_disk_size || 0) * (item.dbservers || 0);
  return (
    <span>
      {totalMem} GiB RAM, {totalDisk} GiB disk
    </span>
  );
};

interface INetworkTransferSizeView {
  item: ApiUsageItemNetworkTransferSize;
}

const NetworkTransferSizeView = ({ ...args }: INetworkTransferSizeView) => {
  const item = args.item;
  return (
    <span>
      Destination: {item.destination}, Ingress: {humanizeFileSize(item.total_transfer_ingress_size || 0)}, Egress:
      {humanizeFileSize(item.total_transfer_egress_size || 0)}
    </span>
  );
};

interface IBackupStorageSizeView {
  item: ApiUsageItemBackupStorageSize;
}

const BackupStorageSizeView = ({ ...args }: IBackupStorageSizeView) => {
  const item = args.item;
  return <span>{humanizeFileSize(item.cloud_storage_size || 0)}</span>;
};

interface IAuditLogSizeView {
  item: ApiUsageItemAuditLogSize;
}

const AuditLogSizeView = ({ ...args }: IAuditLogSizeView) => {
  const item = args.item;
  return (
    <span>
      DestinationType: {item.destination_type}, EventCount: {numberFormat(item.event_count || 0, 0)}, EventSize: {humanizeFileSize(item.event_size || 0)},
      HttpsPostCount: {numberFormat(item.https_post_count || 0, 0)}
    </span>
  );
};

interface IAuditLogStorageSizeView {
  item: ApiUsageItemAuditLogStorageSize;
}

const AuditLogStorageSizeView = ({ ...args }: IAuditLogStorageSizeView) => {
  const item = args.item;
  return <span>CloudStorageSize: {humanizeFileSize(item.cloud_storage_size || 0)}</span>;
};

interface INotebookSizeView {
  item: ApiUsageItemNotebookSize;
}

const NotebookSizeView = ({ ...args }: INotebookSizeView) => {
  const item = args.item;
  let gpu = "";
  if (item.gpu_size) {
    gpu = `, Gpu: ${numberFormat(item.gpu_size || 0, 0)}`;
  }
  if (item.is_paused) {
    return (
      <span>
        Model: {item.notebook_model_id}, Disk: {numberFormat(item.disk_size || 0, 0)} Gib, Paused
      </span>
    );
  }
  return (
    <span>
      Model: {item.notebook_model_id}, Disk: {numberFormat(item.disk_size || 0, 0)} Gib, Memory: {numberFormat(item.memory_size || 0)} Gib, Cpu:{" "}
      {numberFormat(item.cpu_size || 0, 0)}
      {gpu}
    </span>
  );
};

// Interface describing a usage item
interface IRowView extends IWithRefreshProps {
  itemInfo: ApiUsageItemInfo;
  onInvoiceSelected: (id: string) => void;
}

const RowView = ({ ...args }: IRowView) => {
  const item = args.itemInfo.usage_item || {};
  const hasPrice = !!args.itemInfo.price;
  const price = args.itemInfo.price || {};
  const hasCredits = !!args.itemInfo.credits;
  const credits = args.itemInfo.credits || {};
  const duration = item.has_ended ? moment(item.ends_at).diff(moment(item.starts_at)) : momentNow().diff(moment(item.starts_at));

  return (
    <Table.Row>
      <Table.Cell>
        <Popup trigger={<u>{item.kind}</u>} content={<ReactJson src={args.itemInfo} collapsed={1} />} on="click" pinned wide="very" position="right center" />
      </Table.Cell>
      <Table.Cell>
        <ResourceView resource={item.resource} />
      </Table.Cell>
      <Table.Cell>{item.tier_id || ""}</Table.Cell>
      <Table.Cell>
        {(item.resource || {}).cloud_provider_id} / {(item.resource || {}).cloud_region_id}
      </Table.Cell>
      <Table.Cell>
        {item.deployment_size && <DeploymentSizeView item={item.deployment_size} />}
        {item.network_transfer_size && <NetworkTransferSizeView item={item.network_transfer_size} />}
        {item.backup_storage_size && <BackupStorageSizeView item={item.backup_storage_size} />}
        {item.auditlog_size && <AuditLogSizeView item={item.auditlog_size} />}
        {item.auditlog_storage_size && <AuditLogStorageSizeView item={item.auditlog_storage_size} />}
        {item.notebook_size && <NotebookSizeView item={item.notebook_size} />}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="mini"
          wide="very"
          content={
            <>
              <FormattedDateWithLabel label="Start" dateTime={item.starts_at} transformTo={DateTimeTransformationTypes.LOCAL} />
              <FormattedElapsedTimeWithLabel label="Start" dateTime={item.starts_at} />
            </>
          }
          trigger={
            <span>
              <DateTimeInUTC dateTime={item.starts_at || ""} format={HUMANIZED_DATE_FORMAT} />
            </span>
          }
        />
      </Table.Cell>
      <Table.Cell>
        {item.has_ended ? (
          <Popup
            size="mini"
            wide="very"
            content={
              <>
                <FormattedDateWithLabel label="End" dateTime={item.ends_at} transformTo={DateTimeTransformationTypes.LOCAL} />
                <FormattedElapsedTimeWithLabel label="End" dateTime={item.ends_at} />
              </>
            }
            trigger={
              <span>
                <DateTimeInUTC dateTime={item.ends_at || ""} format={HUMANIZED_DATE_FORMAT} />
              </span>
            }
          />
        ) : (
          "-"
        )}
      </Table.Cell>
      <Table.Cell>
        <HumanisedDuration duration={duration} />
      </Table.Cell>
      <Table.Cell>
        <InvoiceLink {...args} invoice_id={item.invoice_id} />
      </Table.Cell>
      <Table.Cell>
        {((item.resource || {}).credit_bundle_ids || []).map((id) => {
          return <div>{id}</div>;
        })}
      </Table.Cell>
      <Table.Cell>
        {hasPrice && (
          <Popup
            trigger={<u>{numberFormat(moneyAsNumber(price.total))}</u>}
            content={<ReactJson src={price} />}
            on="click"
            pinned
            wide="very"
            position="left center"
          />
        )}
        {!hasPrice && <span>n/a</span>}
      </Table.Cell>
      <Table.Cell>
        {hasCredits && (
          <Popup
            trigger={<u>{numberFormat(moneyAsNumber(credits.total))}</u>}
            content={<ReactJson src={credits} />}
            on="click"
            pinned
            wide="very"
            position="left center"
          />
        )}
        {!hasCredits && <span>n/a</span>}
      </Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

// Interface describing the usage item list
interface IListView extends IWithRefreshProps {
  items: ApiUsageItemInfo[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onInvoiceSelected: (id: string) => void;
  selectedKind: string;
  onSelectKind: (kind: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={(item.usage_item || {}).id} itemInfo={item} />
      ))}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more usage" : "No usage for this organization in selected period"}</div>;

// Interface describing the usage item list view arguments
export interface IUsageItemListViewArgs extends IWithRefreshProps, RouteComponentProps {
  loading: boolean;
  usageItemInfos?: ApiUsageItemInfoList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onInvoiceSelected: (id: string) => void;
  selectedKind: string;
  onSelectKind: (kind: string) => void;
}

export const UsageItemListView = ({ ...args }: IUsageItemListViewArgs) => {
  if (!args.usageItemInfos) {
    return <Loading />;
  }
  const isEmpty = !args.usageItemInfos.items || args.usageItemInfos.items.length === 0;
  const usageItems = args.usageItemInfos ? args.usageItemInfos.items : undefined;
  return (
    <div>
      <ListView {...args} items={usageItems || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the usage item list component
interface IUsageItemListProps extends IWithRefreshProps, RouteComponentProps {
  organization_id?: string;
  project_id?: string;
  deployment_id?: string;
  onInvoiceSelected: (id: string) => void;
}

// Interface decribing the state of the usage item list component
interface IUsageItemListState {
  usageItemInfos?: ApiUsageItemInfoList;
  page: number;
  pageSize: number;
  from: Date;
  to: Date;
  focusedInput: any;
  organization_id: string;
  project_id: string;
  refreshNeeded: boolean;
  selectedKind: string;
  invoiceID: string;
  deploymentID?: string;
}

// The component to show usage items of an organization as a list.
class UsageItemList extends Component<IUsageItemListProps, IUsageItemListState> {
  state: IUsageItemListState = {
    usageItemInfos: undefined,
    page: 0,
    pageSize: 40,
    from: momentNow().startOf("day").subtract(2, "days").toDate(),
    to: momentNow().endOf("day").toDate(),
    focusedInput: undefined,
    organization_id: "",
    project_id: "",
    refreshNeeded: false,
    selectedKind: "all",
    invoiceID: "",
    deploymentID: this.props.deployment_id,
  };

  reloadUsageItems = async () => {
    const ireq: ApiListUsageItemsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
      from: this.state.from,
      to: this.state.to,
      sort_descending: true,
    };
    if (this.props.organization_id) {
      ireq.organization_id = this.props.organization_id;
    }
    if (this.props.project_id) {
      ireq.project_id = this.props.project_id;
    }
    if (this.state.deploymentID) {
      ireq.deployment_id = this.state.deploymentID;
    }
    if (this.state.selectedKind !== "all") {
      ireq.kind = this.state.selectedKind;
    }
    if (this.state.invoiceID) {
      ireq.invoice_id = this.state.invoiceID;
    }
    const req: ApiListAllUsageItemsRequest = {
      request: ireq,
    };
    const usageItemInfos = await apiClients.idashboardClient.ListAllUsageItemInfos(req);
    this.setState({ usageItemInfos: usageItemInfos });
  };

  refreshUsageItems = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadUsageItems);
  };

  static getDerivedStateFromProps(props: IUsageItemListProps, state: IUsageItemListState) {
    const organization_id = props.organization_id || "";
    const project_id = props.project_id || "";
    if (organization_id != state.organization_id || project_id != state.project_id) {
      return {
        page: 0,
        refreshNeeded: true,
        organization_id: organization_id,
        project_id: project_id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshUsageItems();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshUsageItems);
    }
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshUsageItems
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshUsageItems
    );
  };

  onDatesChange = (args: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    if (args.startDate && args.endDate) {
      this.setState(
        {
          from: args.startDate.toDate(),
          to: args.endDate.toDate(),
          page: 0,
        },
        this.refreshUsageItems
      );
    }
  };

  onInvoiceIDChange = (invoiceID: string) => {
    this.setState({ invoiceID, page: 0 }, this.refreshUsageItems);
  };

  onDeploymentIDChange = (deploymentID: string) => {
    this.setState({ deploymentID, page: 0 }, this.refreshUsageItems);
  };

  onSelectKind = (kind: string) => {
    this.setState({ selectedKind: kind, page: 0 }, this.refreshUsageItems);
  };

  render() {
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <UsageItemFilterView
          invoiceID={this.state.invoiceID}
          onInvoiceIDChange={this.onInvoiceIDChange}
          deploymentID={this.state.deploymentID || ""}
          onDeploymentIDChange={this.onDeploymentIDChange}
          fromDate={this.state.from}
          toDate={this.state.to}
          selectedKind={this.state.selectedKind}
          onDatesChange={this.onDatesChange}
          onKindChange={this.onSelectKind}
        />
        <UsageItemListView
          {...this.props}
          {...this.state}
          onNextPage={this.onNextPage}
          onPreviousPage={this.state.page > 0 ? this.onPreviousPage : undefined}
          onSelectKind={this.onSelectKind}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(UsageItemList);
