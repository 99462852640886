//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Redirect } from "react-router-dom";

type RenderGuardProps = {
  renderIf: boolean;
  children: React.ReactNode;
};

export const RenderGuard = ({ renderIf, children }: RenderGuardProps) => {
  if (!renderIf) {
    return null;
  }

  return <>{children}</>;
};

export const RenderWithRedirection = ({ renderIf, children, redirectWhenCannotRender }: RenderGuardProps & { redirectWhenCannotRender: string }) => {
  if (!renderIf) {
    return <Redirect to={redirectWhenCannotRender} />;
  }

  return <>{children}</>;
};
