//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  OrganizationInfo as ApiOrganizationInfo,
  SetOrganizationRequiresPrepaidDeploymentsRequest as ApiSetOrganizationRequiresPrepaidDeploymentsRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IFieldViewArgs {
  organizationInfo: ApiOrganizationInfo;
  disabled: boolean;
  setValue: (signed: boolean) => void;
}

const FieldView = ({ ...args }: IFieldViewArgs) => {
  const value = !!(args.organizationInfo.organization && args.organizationInfo.organization.requires_prepaid_deployments);
  return (
    <Field>
      <FL>Requires prepaid deployments</FL>
      <FC>
        {value ? "Requires prepaid deployments!" : "Does not require prepaid deployments"}
        {!args.disabled && (
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!value && <Dropdown.Item text="Set Requires prepaid deployments" onClick={() => args.setValue(true)} />}
              {value && <Dropdown.Item text="Unset Requires prepaid deployments" onClick={() => args.setValue(false)} />}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </FC>
    </Field>
  );
};

interface IRequiresPrepaidDeploymentsFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface IRequiresPrepaidDeploymentsFieldState extends IOrganizationDetailsFieldState {
  commentPrompt: ICommentPromptStateForIntegrations;
}

class RequiresPrepaidDeploymentsField extends OrganizationDetailsField<IRequiresPrepaidDeploymentsFieldProps, IRequiresPrepaidDeploymentsFieldState> {
  state: IRequiresPrepaidDeploymentsFieldState = {
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  setValue = async (requiredPrepaidDeployments: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set Requires prepaid deployments to ${requiredPrepaidDeployments ? "true" : "false"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization requires prepaid deployments, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationRequiresPrepaidDeploymentsRequest = {
            organization_id: this.props.organization.id,
            requires_prepaid_deployments: requiredPrepaidDeployments,
          };
          await apiClients.idashboardClient.SetOrganizationRequiresPrepaidDeployments(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const canChange = this.hasPermission("internal-dashboard.organization.set-requires-prepaid-deployments");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <FieldView {...this} {...this.props} {...this.state} disabled={!canChange} />
      </div>
    );
  }
}

export default withRefresh()(RequiresPrepaidDeploymentsField);
