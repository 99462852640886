//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React from "react";
import { Button, Form, Icon, TextArea } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  OrganizationSalesInfo as ApiOrganizationSalesInfo,
  SetOrganizationSalesInfoRequest as ApiSetOrganizationSalesInfoRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL, Processing } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, {
  IOrganizationDetailsFieldProps,
  IOrganizationDetailsFieldState,
  TextAreaContainer,
  Footnote,
} from "./OrganizationDetailsField";

interface ISalesforceURLsFieldViewArgs {
  organization: ApiOrganization;
  salesInfo: ApiOrganizationSalesInfo;
  canEditSalesInfo: boolean;
  editSalesInfo: boolean;
  onEditSalesInfo: () => void;
  onCancelEditSalesInfo: () => void;
  onChangeSalesInfoSalesforceUrls: (salesforce_urls: string[]) => void;
  onCommitSalesInfo: () => void;
}

const SalesforceURLsFieldView = ({ ...args }: ISalesforceURLsFieldViewArgs) => {
  const salesforce_urls = args.salesInfo.salesforce_urls || [];
  const has_salesforce_urls = !_.isEmpty(salesforce_urls);
  const textToSplitedLines = (v: string) => {
    return _.isEmpty(v) ? [] : _.split(v, "\n");
  };
  return (
    <Field>
      <FL>Salesforce URLs</FL>
      <FC>
        {!args.editSalesInfo && (
          <div>
            {has_salesforce_urls && (
              <div>
                {salesforce_urls.map((x, i) => (
                  <div>
                    <a className="text-link" href={x || ""}>
                      {`Link ${i + 1} To Salesforce`}
                    </a>
                    {args.canEditSalesInfo && i == 0 && <Icon name="pencil" onClick={args.onEditSalesInfo} className="edit-pencil" />}
                  </div>
                ))}
              </div>
            )}
            {!has_salesforce_urls && <span>-</span>}
            {args.canEditSalesInfo && !has_salesforce_urls && <Icon name="pencil" onClick={args.onEditSalesInfo} className="edit-pencil" />}
          </div>
        )}
      </FC>
      {args.editSalesInfo && (
        <div>
          <Form.Field>
            <TextAreaContainer>
              <TextArea
                value={salesforce_urls.join("\n")}
                rows={5}
                onChange={(e, d) => args.onChangeSalesInfoSalesforceUrls(textToSplitedLines(d.value as string))}
                placeholder="URLs to this organization in Salesforce"
              />
              <Footnote>One URL per line</Footnote>
              <Button.Group icon basic size="small">
                <Button icon="check" onClick={args.onCommitSalesInfo} />
                <Button icon="cancel" onClick={args.onCancelEditSalesInfo} />
              </Button.Group>
            </TextAreaContainer>
          </Form.Field>
        </div>
      )}
    </Field>
  );
};

interface ISalesforceURLsFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface ISalesforceURLsFieldState extends IOrganizationDetailsFieldState {
  salesInfo?: ApiOrganizationSalesInfo;
  processingSetSalesInfo: boolean;
  editingSalesInfo: boolean;
  commentPrompt: ICommentPromptStateForIntegrations;
}

class SalesforceURLsField extends OrganizationDetailsField<ISalesforceURLsFieldProps, ISalesforceURLsFieldState> {
  state: ISalesforceURLsFieldState = {
    processingSetSalesInfo: false,
    editingSalesInfo: false,
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };
  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      if (this.hasPermission("internal-dashboard.organization.get-sales-info")) {
        const salesInfo = await apiClients.idashboardClient.GetOrganizationSalesInfo(idOptions);
        this.setState({ salesInfo: salesInfo });
      }
    }
  };

  onChangeSalesInfoSalesforceUrls = (urls: string[]) => {
    this.setState((old) => {
      const salesInfo = old.salesInfo ? _.clone(old.salesInfo) : {};
      salesInfo.salesforce_urls = urls;
      return { salesInfo: salesInfo };
    });
  };

  onCommitSalesInfo = async () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Updated Salesforce URLs`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization sales info, please wait...",
        onCommentConfirmation: async () => {
          const salesInfo = this.state.salesInfo || {};
          const req: ApiSetOrganizationSalesInfoRequest = {
            organization_id: this.props.organization.id,
            info: {
              salesforce_urls: salesInfo.salesforce_urls,
            },
          };
          await apiClients.idashboardClient.SetOrganizationSalesInfo(req);
          this.refreshOrganizationInfo();
          this.setState({ editingSalesInfo: false });
        },
      },
    });
  };

  render() {
    const can_edit_sales_info = this.hasPermission("internal-dashboard.organization.set-sales-info");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <Processing active={this.state.processingSetSalesInfo} message="Changing organization sales info, please wait..." />
        <SalesforceURLsFieldView
          {...this}
          {...this.props}
          {...this.state}
          salesInfo={this.state.salesInfo || {}}
          editSalesInfo={this.state.editingSalesInfo}
          canEditSalesInfo={can_edit_sales_info}
          onEditSalesInfo={() => this.setState({ editingSalesInfo: true })}
          onCancelEditSalesInfo={() => this.setState({ editingSalesInfo: false })}
          onChangeSalesInfoSalesforceUrls={this.onChangeSalesInfoSalesforceUrls}
          onCommitSalesInfo={this.onCommitSalesInfo}
        />
      </div>
    );
  }
}

export default withRefresh()(SalesforceURLsField);
