//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions, Organization as ApiOrganization, MemberList as ApiMemberList, Member as ApiMember } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { ContentSegment, SecondaryMenu, Loading, ErrorMessage } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import OrganizationMemberRow, { HeaderView } from "./OrganizationMemberRow";

// Interface describing the members
interface IListView extends IWithRefreshProps, RouteComponentProps {
  active: boolean;
  items: ApiMember[];
  loading: boolean;
  onClickView: (userId: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <OrganizationMemberRow {...args} key={item.user_id} member={item} onMemberSelected={args.onClickView} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No members inside this organization</div>;

// Interface describing the member list view arguments
export interface IOrganizationMemberListViewArgs extends IWithRefreshProps, RouteComponentProps {
  active: boolean;
  loading: boolean;
  organization: ApiOrganization;
  members?: ApiMemberList;
  onClickView: (id: string) => void;
}

export const OrganizationMemberListView = ({ ...args }: IOrganizationMemberListViewArgs) => {
  if (!args.members) {
    return <Loading />;
  }
  if (!args.members.items || args.members.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.members.items} loading={args.loading} />;
};

// Interface decribing the properties of the member list component
interface IOrganizationMemberListProps extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  onMemberSelected: (userId: string) => void;
  showHeader: boolean;
}

// Interface decribing the state of the member list component
interface IOrganizationMemberListState {
  errorMessage?: string;
  processing: boolean;
  members?: ApiMemberList;
}

// The component to show the members of an organization as a list.
class OrganizationMemberList extends Component<IOrganizationMemberListProps, IOrganizationMemberListState> {
  state: IOrganizationMemberListState = {
    errorMessage: undefined,
    processing: false,
    members: undefined,
  };

  reloadMembers = async () => {
    const listOptions: ApiListOptions = { context_id: this.props.organization.id };
    const members = await apiClients.idashboardClient.ListOrganizationMembers(listOptions);
    this.setState({ members: members });
  };

  componentDidMount() {
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadMembers, 60000);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        {this.props.showHeader && (
          <SecondaryMenu>
            <Menu.Item header>Members</Menu.Item>
          </SecondaryMenu>
        )}
        <OrganizationMemberListView {...this.props} {...this.state} active={!this.state.processing} onClickView={this.props.onMemberSelected} />
      </ContentSegment>
    );
  }
}

export default withRefresh()(OrganizationMemberList);
