//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import { Moment } from "moment";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Input, Loader, Menu, Message, Statistic, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ListAllOrganizationsRequest as ApiListAllOrganizationsRequest,
  ListOptions as ApiListOptions,
  OrganizationInfo as ApiOrganizationInfo,
  OrganizationInfoList as ApiOrganizationInfoList,
  Tier as ApiTier,
} from "../../api/lib";
import { Routes } from "../../routes";
import {
  ContentSegment,
  CreationDateFilter,
  CreationDateFilters,
  ErrorMessage,
  ListActionView,
  LoaderBoxForTable as LoaderBox,
  Loading,
  PagingButtons,
  SecondaryMenu,
  SubID,
  Locked,
  momentDefaultFormat,
  momentNow,
  TextLink,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { FreeToTryColumnView } from "./FreeToTryView";

interface IFilterViewArgs {
  onIdChanged: (value: string) => void;
  onNameChanged: (name: string) => void;

  selectedTierID: string;
  tiers: ApiTier[];
  onTierChanged: (tierID: string) => void;

  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;

  isQualifiedLead: boolean;
  isNotQualifiedLead: boolean;
  onSetQualification: (qualified: boolean, notQualified: boolean) => void;

  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const tierOptions = _.orderBy(args.tiers, "name").map((x, i) => {
    return {
      key: `tier-${x.id || ""}`,
      text: x.name || "",
      value: x.id || "",
    };
  });
  tierOptions.unshift({
    key: "tier-all",
    text: "All",
    value: "all",
  });

  const qualificationOptions = [
    {
      key: "only-qualified",
      text: "Only ArangoGraph qualified",
      value: "only-qualified",
    },
    {
      key: "only-not-qualified",
      text: "Only not ArangoGraph qualified",
      value: "only-not-qualified",
    },
    {
      key: "qualification-all",
      text: "All",
      value: "all",
    },
  ];
  const selectedQualification = args.isQualifiedLead ? "only-qualified" : args.isNotQualifiedLead ? "only-not-qualified" : "all";
  const onQualificationChanged = (value: string) => {
    switch (value) {
      case "only-qualified":
        args.onSetQualification(true, false);
        break;
      case "only-not-qualified":
        args.onSetQualification(false, true);
        break;
      default:
        args.onSetQualification(false, false);
        break;
    }
  };

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown item text="Search">
        <Dropdown.Menu>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Name"
              name="search"
              onChange={(e, d) => args.onNameChanged(d.value)}
              // Adding this type as any due to discrepancies in the types of the props of the Input component from Semantic UI
              onKeyDown={(event: any) => {
                if (!event) return;
                if (!event.target) return;
                if (event.keyCode === 32) {
                  event.stopPropagation();
                }
              }}
              onClick={(e: any) => e.stopPropagation()}
            />
          </Menu.Item>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="ID"
              name="search-id"
              onChange={(e, d) => args.onIdChanged(d.value)}
              onClick={(e: any) => e.stopPropagation()}
            />
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown
        item
        value={args.selectedTierID}
        text={`Tier: ${getOptionText(tierOptions, args.selectedTierID) || "?"}`}
        options={tierOptions}
        onChange={(e, d) => args.onTierChanged(d.value as string)}
      />
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Created: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
      <Dropdown
        item
        value={selectedQualification}
        text={`Qualification: ${getOptionText(qualificationOptions, selectedQualification) || "?"}`}
        options={qualificationOptions}
        onChange={(e, d) => onQualificationChanged(d.value as string)}
      />
      <Menu.Item position="right" fitted="vertically">
        <PagingButtons {...args} size="tiny" />
      </Menu.Item>
    </Menu>
  );
};

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  handleSort: (col: string) => void;
  column?: string;
  descending: boolean;
}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={args.column && args.column === "name" ? (args.descending ? "descending" : "ascending") : undefined}
          onClick={args.handleSort("name")}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={args.column && args.column === "description" ? (args.descending ? "descending" : "ascending") : undefined}
          onClick={args.handleSort("description")}
        >
          Description
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={args.column && args.column === "tier" ? (args.descending ? "descending" : "ascending") : undefined}
          onClick={args.handleSort("tier")}
        >
          Tier
        </Table.HeaderCell>
        <Table.HeaderCell>#Free deployments</Table.HeaderCell>
        <Table.HeaderCell>Qualification</Table.HeaderCell>
        <Table.HeaderCell
          sorted={args.column && args.column === "created" ? (args.descending ? "descending" : "ascending") : undefined}
          onClick={args.handleSort("created")}
        >
          Created
        </Table.HeaderCell>
        <Table.HeaderCell>
          Actions
          <LoaderBox>
            <Loader size="mini" active={args.loading} inline />
          </LoaderBox>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

// Interface describing an organization
interface IRowView {
  item: ApiOrganizationInfo;
  onClickView: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const org = args.item.organization || {};
  const is_qualified = !!args.item.is_qualified_lead;
  return (
    <Table.Row>
      <Table.Cell>
        <TextLink label={org.name} href={Routes.dashboard_sales_organization_detailsWithId(org.id || "")} />
        {!!org.locked && <Locked />}
        <SubID>{org.id}</SubID>
      </Table.Cell>
      <Table.Cell>{org.description}</Table.Cell>
      <Table.Cell>{(org.tier || {}).name}</Table.Cell>
      <Table.Cell>
        <FreeToTryColumnView organization={org} />
      </Table.Cell>
      <Table.Cell>{is_qualified ? "OQL" : "-"}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={org.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          <ListActionView onClick={args.onClickView} />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the organization list
interface IListView {
  items: ApiOrganizationInfo[];
  loading: boolean;
  onNameChanged: (name: string) => void;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickView: (organizationId: string) => void;
  selectedTierID: string;
  tiers: ApiTier[];
  onTierChanged: (id: string) => void;
  handleSort: (col: string) => void;
  column?: string;
  descending: boolean;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped sortable>
    <HeaderView {...args} />
    <Table.Body>
      {args.items.map((item) => {
        const org = item.organization || {};
        const id = org.id;
        return <RowView {...args} key={id || ""} item={item} onClickView={() => args.onClickView(id || "")} />;
      })}
    </Table.Body>
  </Table>
);

interface ITierStatistics {
  tier_id?: string;
  tier_name?: string;
  count?: number;
}

interface IStatistics {
  count?: number;
  by_tier?: ITierStatistics[];
}

interface IStatisticsViewArgs {
  statistics?: IStatistics;
  filterFrom?: Moment;
  filterTo?: Moment;
  isQualifiedLead: boolean;
  isNotQualifiedLead: boolean;
}

const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const st = args.statistics || {};
  const by_tier = st.by_tier || [];
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";
  const qualificationFilter = args.isQualifiedLead ? " that are qualified" : args.isNotQualifiedLead ? " that are not qualified" : "";
  return (
    <div>
      <Message>
        Organizations {timeFilter}
        {qualificationFilter}.
      </Message>
      <Statistic.Group widths="5" size="tiny">
        <Statistic label="Total" value={st.count || 0} />
        {_.orderBy(by_tier, "tier_name").map((x) => (
          <Statistic key={x.tier_id || ""} label={x.tier_name || ""} value={x.count || 0} />
        ))}
      </Statistic.Group>
    </div>
  );
};

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more organizations" : "No organizations selected"}</div>;

// Interface describing the organization list view arguments
export interface IOrganizationListViewArgs extends RouteComponentProps {
  loading: boolean;
  organizationInfos?: ApiOrganizationInfoList;
  onIdChanged: (value: string) => void;
  onNameChanged: (name: string) => void;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickView: (organizationId: string) => void;
  selectedTierID: string;
  tiers: ApiTier[];
  onTierChanged: (id: string) => void;
  handleSort: (col: string) => void;
  column?: string;
  descending: boolean;
  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;
  isQualifiedLead: boolean;
  isNotQualifiedLead: boolean;
  onSetQualification: (qualified: boolean, notQualified: boolean) => void;

  statistics?: IStatistics;
  filterFrom?: Moment;
  filterTo?: Moment;
}

export const OrganizationListView = ({ ...args }: IOrganizationListViewArgs) => {
  if (!args.organizationInfos) {
    return <Loading />;
  }
  const isEmpty = !args.organizationInfos.items || args.organizationInfos.items.length === 0;
  const organizationInfos = args.organizationInfos ? args.organizationInfos.items : undefined;
  const count = (organizationInfos || []).length;
  return (
    <div>
      <FilterView {...args} count={count} />
      <StatisticsView {...args} />
      <ListView {...args} items={organizationInfos || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the organization list component
interface IOrganizationListProps extends IWithRefreshProps, RouteComponentProps {
  onOrganizationSelected: (organizationID: string) => void;
  owned_by_user_id?: string;
  member_of_user_id?: string;
  inline?: boolean;
}

// Interface decribing the state of the organization list component
interface IOrganizationListState {
  organizationInfos?: ApiOrganizationInfoList;
  nameFilter: string;
  page: number;
  pageSize: number;
  owned_by_user_id?: string;
  member_of_user_id?: string;
  organizationID: string;
  refreshNeeded: boolean;
  selectedTierID: string;
  tiers?: ApiTier[];
  column?: string;
  descending: boolean;
  selectedCreationDateFilterID: string;
  statistics?: IStatistics;
  filterFrom?: Moment;
  filterTo?: Moment;
  isQualifiedLead: boolean;
  isNotQualifiedLead: boolean;
}

// The component to show organizations as a list.
class OrganizationList extends Component<IOrganizationListProps, IOrganizationListState> {
  state: IOrganizationListState = {
    organizationInfos: undefined,
    nameFilter: "",
    page: 0,
    pageSize: 10,
    owned_by_user_id: undefined,
    member_of_user_id: undefined,
    organizationID: "",
    refreshNeeded: false,
    selectedTierID: "all",
    tiers: undefined,
    column: undefined,
    descending: false,
    selectedCreationDateFilterID: "all",
    statistics: undefined,
    filterFrom: undefined,
    filterTo: undefined,
    isQualifiedLead: false,
    isNotQualifiedLead: false,
  };

  reloadTiers = async () => {
    const req: ApiListOptions = {};
    const list = await apiClients.idashboardClient.ListTiers(req);
    this.setState({ tiers: list.items });
  };

  refreshTiers = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTiers);
  };

  reloadOrganizations = async () => {
    const req: ApiListAllOrganizationsRequest = {
      name: this.state.nameFilter,
      name_like: true,
      is_qualified_lead: this.state.isQualifiedLead,
      is_not_qualified_lead: this.state.isNotQualifiedLead,
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    if (this.props.owned_by_user_id) req.owned_by_user_id = this.props.owned_by_user_id;
    if (this.props.member_of_user_id) req.member_of_user_id = this.props.member_of_user_id;
    if (!_.isEmpty(this.state.organizationID)) {
      req.organization_id = this.state.organizationID;
    }
    if (this.state.selectedTierID != "all") {
      req.tier_id = this.state.selectedTierID;
    }
    let filterFrom: Moment | undefined = undefined;
    let filterTo: Moment | undefined = undefined;
    if (this.state.selectedCreationDateFilterID != "all") {
      const f = CreationDateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
      if (f) {
        if (f.filterStart) {
          filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          req.created_from = filterFrom.toDate();
        }
        if (f.filterEnd) {
          filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          req.created_to = filterTo.toDate();
        }
      }
    }
    // Add sorting
    if (this.state.column) {
      switch (this.state.column) {
        case "name":
          req.sort_by_name = true;
          break;
        case "description":
          req.sort_by_description = true;
          break;
        case "tier":
          req.sort_by_tier = true;
          break;
        case "created":
          req.sort_by_created = true;
          break;
      }
      if (this.state.descending) {
        req.sort_descending = true;
      }
    }
    const organizationInfos = await apiClients.idashboardClient.ListOrganizationInfos(req);
    const count = await apiClients.idashboardClient.CountOrganizations(req);
    const stats: IStatistics = {
      count: count.count,
      by_tier: [],
    };
    const tiers = this.state.tiers || [];
    if (this.state.selectedTierID == "all") {
      await Promise.all(
        tiers.map(async (t) => {
          const treq = _.clone(req);
          treq.tier_id = t.id;
          const count = await apiClients.idashboardClient.CountOrganizations(treq);
          (stats.by_tier || []).push({
            tier_id: t.id,
            tier_name: t.name,
            count: count.count,
          });
        })
      );
    }

    this.setState({
      organizationInfos: organizationInfos,
      statistics: stats,
      filterFrom: filterFrom,
      filterTo: filterTo,
    });
  };

  refreshOrganizations = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadOrganizations);
  };

  onClickView = (id: string) => {
    this.props.onOrganizationSelected(id);
  };

  static getDerivedStateFromProps(props: IOrganizationListProps, state: IOrganizationListState) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.member_of_user_id !== state.member_of_user_id || props.owned_by_user_id !== state.owned_by_user_id) {
      return {
        organizations: undefined,
        refreshNeeded: true,
        member_of_user_id: props.member_of_user_id,
        owned_by_user_id: props.owned_by_user_id,
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshOrganizations();
    this.refreshTiers();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshOrganizations);
    }
  }

  onNameFilterChanged = (name: string) => {
    this.setState(
      {
        nameFilter: name,
        page: 0,
      },
      this.refreshOrganizations
    );
  };

  onIdChanged = (value: string) => {
    this.setState(
      {
        organizationID: value,
        page: 0,
      },
      this.refreshOrganizations
    );
  };

  onTierChanged = (id: string) => {
    this.setState(
      {
        selectedTierID: id,
        page: 0,
      },
      this.refreshOrganizations
    );
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState(
      {
        selectedCreationDateFilterID: id,
        page: 0,
      },
      this.refreshOrganizations
    );
  };

  onSetQualification = (qualified: boolean, notQualified: boolean) => {
    this.setState(
      {
        isQualifiedLead: qualified,
        isNotQualifiedLead: notQualified,
        page: 0,
      },
      this.refreshOrganizations
    );
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshOrganizations
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshOrganizations
    );
  };

  handleSort = (col: string) => () => {
    if (this.state.column !== col) {
      this.setState(
        {
          column: col,
        },
        this.refreshOrganizations
      );
      return;
    }

    // the same column was clicked, reverse the sorting order
    this.setState({ descending: !this.state.descending }, this.refreshOrganizations);
  };

  render() {
    const listView = (
      <OrganizationListView
        {...this.props}
        {...this.state}
        onNameChanged={this.onNameFilterChanged}
        onIdChanged={this.onIdChanged}
        onTierChanged={this.onTierChanged}
        onNextPage={this.onNextPage}
        onPreviousPage={this.state.page > 0 ? this.onPreviousPage : undefined}
        onClickView={this.props.onOrganizationSelected}
        tiers={this.state.tiers || []}
        creationDateFilters={CreationDateFilters}
        onCreationDateFilterChanged={this.onCreationDateFilterChanged}
        onSetQualification={this.onSetQualification}
        handleSort={this.handleSort}
        column={this.state.column}
      />
    );
    if (!!this.props.inline) {
      return listView;
    }
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Organizations</Menu.Item>
        </SecondaryMenu>
        {listView}
      </ContentSegment>
    );
  }
}

export default withRefresh()(OrganizationList);
