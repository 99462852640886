//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Button, Grid, GridColumn, GridRow, Progress, Table } from "semantic-ui-react";
import { ErrorMessage } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import {
  DataClusterOptimizationStatus as APIDataClusterOptimizationStatus,
  DataClusterOptimizationStepStatus as APIDataClusterOptimizationStepStatus,
  DataCluster as APIDataCluster,
} from "../../api/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

export interface IDataClusterOptimizationStatusArgs {
  datacluster?: APIDataCluster;
  can_start_optimization: boolean;
  can_stop_optimization: boolean;
}

const DataClusterOptimizationStepStatusView = (value: APIDataClusterOptimizationStepStatus) => {
  const color = !!value.canceled_at ? "red" : !value.finished_at ? "green" : undefined;
  return (
    <Table.Row>
      <Table.Cell>{value.name}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={value.started_at} label="Started at" />
      </Table.Cell>
      <Table.Cell>
        <Progress percent={value.progress} active={!value.finished_at} color={color}>
          {value.status}
        </Progress>
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={value.finished_at} label="Finished at" />
      </Table.Cell>
    </Table.Row>
  );
};

export const DataClusterOptimizationStatus = ({ ...args }: IDataClusterOptimizationStatusArgs) => {
  const [status, setStatus] = React.useState<APIDataClusterOptimizationStatus | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const load = async () => {
    if (!args.datacluster) {
      return;
    }
    try {
      const status = await apiClients.idashboardClient.GetDataClusterOptimizationStatus({
        datacluster_id: args.datacluster.id,
      });
      setStatus(status);
      setErrorMessage("");
    } catch (e) {
      if (e.status != 404) {
        setErrorMessage(e);
      }
    }
  };

  const startOptimization = async () => {
    setErrorMessage("");
    if (!args.datacluster) {
      return;
    }
    try {
      const status = await apiClients.idashboardClient.StartDataClusterOptimization({
        datacluster_id: args.datacluster.id,
      });
      setStatus(status);
    } catch (e) {
      setErrorMessage(e);
    }
  };

  const cancelOptimization = async () => {
    setErrorMessage("");
    if (!args.datacluster) {
      return;
    }
    try {
      await apiClients.idashboardClient.StopDataClusterOptimization({
        datacluster_id: args.datacluster.id,
      });
      await load();
    } catch (e) {
      setErrorMessage(e);
    }
  };

  React.useEffect(() => {
    // load status on mount
    load();
    const interval = setInterval(load, 10000);
    return () => {
      // return a cleanup function when component unmounts
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <ErrorMessage active={!!errorMessage} message={errorMessage} />
      {!!status && (
        <Grid columns={3} divided>
          <GridRow>
            <GridColumn>
              {!!status.started_at && (
                <p>
                  Started at: <DateTimePopupWithUTCAndLocalTime dateTime={status.started_at} label="Started at" />
                </p>
              )}
              {!!status.finished_at && (
                <p>
                  Finished at: <DateTimePopupWithUTCAndLocalTime dateTime={status.finished_at} label="Finished at" />
                </p>
              )}
              {!!status.canceled_at && (
                <p color="red">
                  Canceled at:
                  <DateTimePopupWithUTCAndLocalTime dateTime={status.canceled_at} label="Canceled at" />
                </p>
              )}
            </GridColumn>
            <GridColumn>
              <p>Old price: {status.old_price || 0} USD/h</p>
              <p>Prediced price: {status.predicted_price || 0} USD/h</p>
            </GridColumn>
            <GridColumn>
              {(!status || !!status.finished_at) && args.can_start_optimization && <Button onClick={startOptimization}>Start optimization</Button>}
              {!!status && !status.finished_at && args.can_stop_optimization && <Button onClick={cancelOptimization}>Cancel optimization</Button>}
            </GridColumn>
          </GridRow>
        </Grid>
      )}

      {!!status && (
        <Table striped compact size="small">
          <Table.Header>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Started at</Table.HeaderCell>
            <Table.HeaderCell>Progress</Table.HeaderCell>
            <Table.HeaderCell>Finished at</Table.HeaderCell>
          </Table.Header>
          <Table.Body>{(status.steps || []).map(DataClusterOptimizationStepStatusView)}</Table.Body>
        </Table>
      )}
    </div>
  );
};
