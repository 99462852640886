//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { AnalyseClusterRequest as ApiAnalyseClusterRequest } from "../../api/lib";
import { ContentActionButton } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

const DataContainer = styled("div")`
  min-height: 20vh;
`;

interface IClusterAnalyseModalViewArgs {
  open: boolean;
  loading: boolean;
  report?: string;
  onClose: () => void;
}

const ClusterAnalyseModalView = ({ ...args }: IClusterAnalyseModalViewArgs) => {
  const empty = _.isEmpty(args.report);

  return (
    <Modal open={args.open} onClose={args.onClose} size="fullscreen">
      <Modal.Content scrolling>
        <DataContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Analysing cluster, please wait
              </Loader>
            </Dimmer>
          )}
          {!args.loading && !empty && <pre>{args.report}</pre>}
          {!args.loading && empty && (
            <span>
              <i>No data</i>
            </span>
          )}
        </DataContainer>
      </Modal.Content>
    </Modal>
  );
};

interface IClusterAnalyseModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
}

interface IClusterAnalyseModalState {
  open: boolean;
  loading: boolean;
  report?: string;
  errorMessage?: string;
}

// Component to show deployment logs
class ClusterAnalyseModal extends Component<IClusterAnalyseModalProps, IClusterAnalyseModalState> {
  state: IClusterAnalyseModalState = {
    open: false,
    loading: false,
    report: undefined,
    errorMessage: undefined,
  };

  reloadClusterAnalyse = async () => {
    this.setState({ loading: true, report: undefined, errorMessage: undefined });
    try {
      const req: ApiAnalyseClusterRequest = {
        deployment_id: this.props.deploymentId,
      };
      const stream = await apiClients.idashboardClient.AnalyseCluster(req, (x) => {
        const err = x.error;
        const msg = x.message;
        if (!!err) {
          this.setState({ errorMessage: x.error });
        } else if (!!msg) {
          const chunk = msg.chunk;
          if (!!chunk) {
            const decoded = atob(chunk);
            this.setState((old) => {
              return { report: (old.report || "") + decoded };
            });
          }
        }
      });
      await stream.closed;
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, report: undefined, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true }, this.reloadClusterAnalyse);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Analyse" icon="stethoscope" />
        <ClusterAnalyseModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(ClusterAnalyseModal);
