//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import history from "../../history";
import { Header, Table, Popup, Icon, Button, Form, CheckboxProps } from "semantic-ui-react";
import {
  MetricsInfo_PrometheusAlertStatus as ApiMetricsInfo_PrometheusAlertStatus,
  MetricsInfo_PrometheusFiringAlert as ApiMetricsInfo_PrometheusFiringAlert,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabel as FL, TextLink } from "../../ui/lib";
import { numberFormat } from "humanize";
import { Routes } from "../../routes";
import { SupportPlanView } from "../deployment/SupportPlanView";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "@emotion/styled";
import { FlexBox } from "../../ui/_flex";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

const Suppressed = styled("span")`
  text-decoration: line-through;
`;
interface ISuppress {
  value?: boolean;
  children?: any;
}

const Suppress = ({ ...args }: ISuppress) => (args.value ? <Suppressed>{args.children}</Suppressed> : <span>{args.children}</span>);

export interface IPrometheusAlertStatusViewArgs {
  status?: ApiMetricsInfo_PrometheusAlertStatus;
  showDataclusterID?: boolean;
  showDeploymentID?: boolean;
  gotoUrl: (url: string) => void;
}

export const PrometheusAlertStatusView = ({ ...args }: IPrometheusAlertStatusViewArgs) => {
  const [showSilenced, setShowSilenced] = useState(false);
  const has_status = !!args.status;
  const status = args.status || {};

  let firing_info = (status.firing_info || []).filter((alert) => !alert.is_silenced);
  if (showSilenced) {
    firing_info = status.firing_info || [];
  }

  const silenced_alerts_count = (status.firing_info || []).filter((alert) => alert.is_silenced).length;
  const by_severity = _.groupBy(firing_info, "severity");
  const by_severity_text = _.map(by_severity, (v, k) => `${k}: ${v.length}`).join(", ");
  const has_firing = firing_info.length > 0;

  return (
    <div>
      <FlexBox justify="space-between">
        <div>
          <Header sub>Prometheus Alert Status</Header>
          <Field>
            <FL>Pending Alerts</FL>
            <FC>{has_status ? status.pending || 0 : "-"}</FC>
          </Field>
          <Field>
            <FL>Silenced Alerts</FL>
            <FC>{silenced_alerts_count}</FC>
          </Field>
          <Field>
            <FL>Firing Alerts</FL>
            <FC>
              {has_status ? firing_info.length || 0 : "-"}
              {!!by_severity_text && ` (${by_severity_text})`}
            </FC>
          </Field>
        </div>

        <Form.Checkbox
          name="silenced"
          checked={showSilenced}
          onChange={(e: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void => {
            setShowSilenced(!!data.checked);
          }}
          label="Show Silenced"
          toggle
        />
      </FlexBox>

      {has_firing && (
        <PrometheusAlertStatusTableView
          {...args}
          status={{
            ...status,
            // override firing_info to show based on silenced filter
            firing_info: firing_info,
          }}
        />
      )}
    </div>
  );
};

const PrometheusAlertStatusTableView = ({ ...args }: IPrometheusAlertStatusViewArgs) => {
  let list = (args.status || {}).firing_info || [];
  if (list.length == 0) {
    return <span>None</span>;
  }
  const sortedList = _.orderBy(list, ["severity", "name"]);
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Severity</Table.HeaderCell>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Active At</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
          {args.showDataclusterID && <Table.HeaderCell>Datacluster ID</Table.HeaderCell>}
          {args.showDeploymentID && <Table.HeaderCell>Deployment ID</Table.HeaderCell>}
          {args.showDeploymentID && <Table.HeaderCell>Model</Table.HeaderCell>}
          {args.showDeploymentID && (
            <Table.HeaderCell>
              <Popup trigger={<Icon name="help circle" />} content="Support plan" />
            </Table.HeaderCell>
          )}
          <Table.HeaderCell>
            <Popup trigger={<Icon name="comment alternate outline" />} content="PromQL" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedList.map((x) => (
          <PrometheusFiringAlertView {...args} key={x.name} firingAlert={x} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IPrometheusFiringAlertView {
  firingAlert: ApiMetricsInfo_PrometheusFiringAlert;
  showDataclusterID?: boolean;
  showDeploymentID?: boolean;
  gotoUrl: (url: string) => void;
}

const PrometheusFiringAlertView = ({ ...args }: IPrometheusFiringAlertView) => {
  const [copied, setCopied] = React.useState(false);
  const onCopied = () => {
    setCopied(true);
    setInterval(() => {
      setCopied(false);
    }, 3000);
  };
  const dc_id = args.firingAlert.datacluster_id;
  const depl_id = args.firingAlert.deployment_id;
  const critical = args.firingAlert.severity == "critical" && !args.firingAlert.is_suppressed;
  return (
    <Table.Row negative={critical}>
      <Table.Cell>
        <Suppress value={args.firingAlert.is_suppressed}>{args.firingAlert.severity}</Suppress>
      </Table.Cell>
      <Table.Cell>{args.firingAlert.category}</Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <span>
              <TextLink
                label={args.firingAlert.name}
                disabled={!args.firingAlert.runbook_url}
                onClick={() => {
                  args.gotoUrl(args.firingAlert.runbook_url || "");
                }}
              />
            </span>
          }
          content={<span>Is Silenced: {args.firingAlert.is_silenced ? "Yes" : "No"}</span>}
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <span>
              <i>
                <DateTimePopupWithUTCAndLocalTime dateTime={args.firingAlert.active_at} label="Active at" />
              </i>
            </span>
          }
          content={
            <span>
              For: {moment.duration(args.firingAlert.for).humanize()}
              <br />
              Notification Channel: {args.firingAlert.notification_channel || "default"}
            </span>
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <span>
              <i>{numberFormat(args.firingAlert.value)}</i>
            </span>
          }
          content={<span>{args.firingAlert.context}</span>}
        />
      </Table.Cell>
      {args.showDataclusterID && (
        <Table.Cell>
          {dc_id && (
            <TextLink
              onClick={() => {
                history.replace(Routes.dashboard_support_datacluster_detailsWithId(dc_id));
              }}
            >
              {dc_id}
            </TextLink>
          )}
        </Table.Cell>
      )}
      {args.showDeploymentID && (
        <Table.Cell>
          {depl_id && (
            <TextLink
              onClick={() => {
                history.replace(Routes.dashboard_support_deployment_detailsWithId(depl_id));
              }}
            >
              {depl_id}
            </TextLink>
          )}
        </Table.Cell>
      )}
      {args.showDeploymentID && <Table.Cell>{args.firingAlert.deployment_model || "?"}</Table.Cell>}
      {args.showDeploymentID && (
        <Table.Cell>
          <SupportPlanView planId={args.firingAlert.deployment_supportplan_id || ""} />
        </Table.Cell>
      )}
      <Table.Cell>
        <CopyToClipboard text={args.firingAlert.promql} onCopy={onCopied}>
          <Button icon={copied ? "check" : "copy"} size="tiny" basic />
        </CopyToClipboard>
      </Table.Cell>
    </Table.Row>
  );
};
