//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { Button, Dropdown, Header, Icon } from "semantic-ui-react";
import {
  KeyValuePair as ApiKeyValuePair,
  Deployment as ApiDeployment,
  Organization as ApiOrganization,
  Project as ApiProject,
  RegionDetails as ApiRegionDetails,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL, TextLink, Locked, Finalizers, Annotations, ObjectJSONModalView } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import UserNameWithId from "../user/UserNameWithId";
import styled from "@emotion/styled";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

const StyledRedeleteAction = styled("span")`
  margin-left: 8px;
`;

const FloatRight = styled("span")`
  float: right;
`;

interface IGeneralViewArgs extends IWithRefreshProps {
  deployment: ApiDeployment;
  createdWith: string;
  finalizers: string[];
  regionDetails: ApiRegionDetails;
  annotations?: ApiKeyValuePair[];
  useSharedLoadbalancer: boolean;
  useInternalLoadbalancer: boolean;
  chaosLevel: string;
  project: ApiProject;
  organization: ApiOrganization;
  onProjectSelected: () => void;
  onOrganizationSelected: () => void;
  can_redelete_deployment: boolean;
  onClickRedeleteDeployment: () => void;
  processingDeploymentRedelete: boolean;
  can_edit_loadbalancer: boolean;
  internal_loadbalancer_allowed: boolean;
  loadBalancers: Array<string>;
  selectedLoadBalancer: string;
  requestLoadBalancerTypes: () => void;
  getDefaultLoadBalancer: () => void;
  onClickUpdateLoadBalancer: (useSharedLoadbalancer: boolean, useInternalLoadbalancer: boolean) => void;
  updateLoadBalancer: (type: string) => void;
  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (finalizer: string) => void;
}

export const GeneralView = ({ ...args }: IGeneralViewArgs) => {
  const disableSharedLoadBalancers = !!args.regionDetails.disableSharedLoadBalancers;

  const { loadBalancers, selectedLoadBalancer, updateLoadBalancer, requestLoadBalancerTypes, getDefaultLoadBalancer, onClickUpdateLoadBalancer } = args;

  return (
    <div>
      <Header sub>
        <FloatRight>{<ObjectJSONModalView value={args.deployment} title={"Deployment " + args.deployment.id} text="details" />}</FloatRight>
        General
      </Header>
      <Field>
        <FL>Name</FL>
        <FC>
          <div>{args.deployment.name || "-"}</div>
          {!!args.project.locked && <Locked />}
        </FC>
      </Field>
      <Field>
        <FL>Description</FL>
        <FC>{args.deployment.description || "-"}</FC>
      </Field>
      <Field>
        <FL>ID</FL>
        <FC>
          <div>{args.deployment.id || ""}</div>
        </FC>
      </Field>
      <Field>
        <FL>Organization / project</FL>
        <FC>
          <TextLink href={Routes.dashboard_sales_organization_detailsWithId(args.organization.id as string)}>{args.organization.name || "-"}</TextLink>
          <span> / </span>
          <TextLink href={Routes.dashboard_sales_project_detailsWithId(args.project.id as string)}>{args.project.name || "-"}</TextLink>
        </FC>
      </Field>
      <Field>
        <FL>Loadbalancer Intent</FL>
        <FC>
          {args.can_edit_loadbalancer && (
            <>
              <span>{args.useInternalLoadbalancer ? "Internal" : args.useSharedLoadbalancer ? "Shared" : "Dedicated"}</span>
              <Dropdown icon="pencil" className="icon tiny edit-pencil" value={selectedLoadBalancer}>
                <Dropdown.Menu>
                  {args.internal_loadbalancer_allowed && !args.useInternalLoadbalancer && (
                    <Dropdown.Item
                      key="set-ilb"
                      text="Use internal loadbalancer for deployment"
                      onClick={() => {
                        getDefaultLoadBalancer();
                        onClickUpdateLoadBalancer(args.useSharedLoadbalancer, true);
                      }}
                    />
                  )}
                  {(args.useSharedLoadbalancer || args.useInternalLoadbalancer) && (
                    <Dropdown.Item
                      key="remove-slb"
                      text="Use dedicated loadbalancer for deployment"
                      onClick={() => {
                        getDefaultLoadBalancer();
                        onClickUpdateLoadBalancer(false, false);
                      }}
                    />
                  )}
                  {(!args.useSharedLoadbalancer || args.useInternalLoadbalancer) && !disableSharedLoadBalancers && (
                    <Dropdown.Item
                      key="set-slb"
                      text="Use shared loadbalancer for deployment"
                      onClick={() => {
                        getDefaultLoadBalancer();
                        onClickUpdateLoadBalancer(true, false);
                      }}
                    />
                  )}
                  {!args.useSharedLoadbalancer && disableSharedLoadBalancers && (
                    <Dropdown.Item key="disabled-slb" text="Shared loadbalancers are disabled for this region" />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Loadbalancer</FL>
        <FC>
          {args.can_edit_loadbalancer && (
            <>
              <span>{selectedLoadBalancer}</span>
              <Dropdown icon="pencil" className="icon tiny edit-pencil" value={selectedLoadBalancer} onOpen={requestLoadBalancerTypes}>
                <Dropdown.Menu>
                  {loadBalancers.map((type) => (
                    <Dropdown.Item key={type} value={type} onClick={() => updateLoadBalancer(type)}>
                      {type} {selectedLoadBalancer === type && <Icon name="check" />}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Finalizers</FL>
        <FC>
          <Finalizers finalizers={args.finalizers} remove={args.canRemoveFinalizer ? args.onRemoveFinalizer : undefined} />
        </FC>
      </Field>
      <Field>
        <FL>Annotations</FL>
        <FC>
          <Annotations annotations={args.annotations} />
        </FC>
      </Field>
      <Field>
        <FL>Created</FL>
        <FC>
          <DateTimePopupWithUTCAndLocalTime dateTime={args.deployment.created_at} label="Created At" />
          <br />
          by: <UserNameWithId {...args} userId={args.deployment.created_by_id} />
          <br />
          with: {args.createdWith}
        </FC>
      </Field>
      {args.deployment.deleted_at ? (
        <Field>
          <FL>Deleting</FL>
          <FC>
            <span>
              <DateTimePopupWithUTCAndLocalTime dateTime={args.deployment.deleted_at} label="Deleted At" />
              <StyledRedeleteAction>
                {args.processingDeploymentRedelete ? (
                  <span>Deployment is re-deleting...</span>
                ) : (
                  <Button color="red" size="tiny" circular icon disabled={!args.can_redelete_deployment} onClick={args.onClickRedeleteDeployment}>
                    <Icon name={args.processingDeploymentRedelete ? "check" : "trash"} />
                  </Button>
                )}
              </StyledRedeleteAction>
            </span>
          </FC>
        </Field>
      ) : undefined}
    </div>
  );
};
