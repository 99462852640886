//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  OrganizationInfo as ApiOrganizationInfo,
  SetOrganizationBusinessAssociateAgreementSignedRequest as ApiSetOrganizationBusinessAssociateAgreementSignedRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IFieldViewArgs {
  organizationInfo: ApiOrganizationInfo;
  canSetBAASigned: boolean;
  setBAASigned: (signed: boolean) => void;
}

const FieldView = ({ ...args }: IFieldViewArgs) => {
  const business_associate_agreement_signed = !!args.organizationInfo.business_associate_agreement_signed;
  return (
    <Field>
      <FL>HIPAA</FL>
      <FC>
        {business_associate_agreement_signed ? "BAA signed!" : "No BAA signed"}
        {args.canSetBAASigned && (
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!business_associate_agreement_signed && <Dropdown.Item text="Set BAA signed" onClick={() => args.setBAASigned(true)} />}
              {business_associate_agreement_signed && <Dropdown.Item text="Unset BAA signed" onClick={() => args.setBAASigned(false)} />}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </FC>
    </Field>
  );
};

interface IBusinessAssociateAgreementSignedFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface IBusinessAssociateAgreementSignedFieldState extends IOrganizationDetailsFieldState {
  commentPrompt: ICommentPromptStateForIntegrations;
}

class BusinessAssociateAgreementSignedField extends OrganizationDetailsField<
  IBusinessAssociateAgreementSignedFieldProps,
  IBusinessAssociateAgreementSignedFieldState
> {
  state: IBusinessAssociateAgreementSignedFieldState = {
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  setBAASigned = async (signed: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${signed ? "Set" : "Unset"} BAA signed`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization business associate agreement signed, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationBusinessAssociateAgreementSignedRequest = {
            organization_id: this.props.organization.id,
            business_associate_agreement_signed: signed,
          };
          await apiClients.idashboardClient.SetOrganizationBusinessAssociateAgreementSigned(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const can_set_baa_signed = this.hasPermission("internal-dashboard.organization.set-business-associate-agreement-signed");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <FieldView {...this} {...this.props} {...this.state} canSetBAASigned={can_set_baa_signed} />
      </div>
    );
  }
}

export default withRefresh()(BusinessAssociateAgreementSignedField);
