//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  Organization as ApiOrganization,
  OrganizationInfo as ApiOrganizationInfo,
  SetOrganizationAutomaticInvoiceVerificationRequest as ApiSetOrganizationAutomaticInvoiceVerificationRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL, Processing, Confirm, ConfirmInfo } from "../../ui/lib";

export interface IAutomaticInvoiceVerifcationFieldToggleArgs {
  canSetOrganizationAutomaticInvoiceVerification: boolean;
  organization: ApiOrganization;
  organizationInfo: ApiOrganizationInfo;
  onError: (message: string) => void;
}

const AutomaticInvoiceVerifcationFieldToggle: React.FC<IAutomaticInvoiceVerifcationFieldToggleArgs> = ({
  canSetOrganizationAutomaticInvoiceVerification,
  organization,
  organizationInfo,
  onError,
}) => {
  const [processing, setIsProcessing] = useState(false);
  const [isInvoiceVerifiedAutomatically, setIsInvoiceVerifiedAutomatically] = useState(!!organizationInfo.is_invoice_verified_automatically);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);

  useEffect(() => {
    if (organizationInfo.is_invoice_verified_automatically !== isInvoiceVerifiedAutomatically) {
      setIsInvoiceVerifiedAutomatically(!!organizationInfo.is_invoice_verified_automatically);
    }
  }, [organizationInfo]);

  const onSetIsInvoiceVerifiedAutomatically = async (isInvoiceVerificationAutomatic: boolean) => {
    setConfirmInfo({
      header: `${isInvoiceVerificationAutomatic ? "Enable" : "Disable"} Automatic Invoice Verification`,
      content: `Are you sure to update this configuration?`,
      warning: `This will ${isInvoiceVerificationAutomatic ? "verify" : "not verify"} invoices automatically moving forward.`,
      confirm: isInvoiceVerificationAutomatic ? "Enable!" : "Disable!",
      onConfirm: async () => {
        const req: ApiSetOrganizationAutomaticInvoiceVerificationRequest = {
          organization_id: organization.id,
          is_invoice_verified_automatically: isInvoiceVerificationAutomatic,
        };
        try {
          setIsProcessing(true);
          await apiClients.idashboardClient.SetOrganizationAutomaticInvoiceVerification(req);
          setIsInvoiceVerifiedAutomatically(isInvoiceVerificationAutomatic);
        } catch (e) {
          onError(`Failed updating automatic invoice verification setting: ${e}`);
        } finally {
          setIsProcessing(false);
          setConfirmInfo(undefined);
        }
      },
      onDenied: () => {
        setConfirmInfo(undefined);
      },
    });
  };

  return (
    <div>
      {confirmInfo && <Confirm confirmInfo={confirmInfo} />}
      <Processing active={processing} message="Changing automatic invoice verification setting, please wait..." />
      <Field>
        <FL>Automatic Invoice Verification</FL>
        <FC>
          {isInvoiceVerifiedAutomatically ? "Enabled" : "Disabled"}
          {canSetOrganizationAutomaticInvoiceVerification && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!isInvoiceVerifiedAutomatically && <Dropdown.Item text="Enable" onClick={() => onSetIsInvoiceVerifiedAutomatically(true)} />}
                {isInvoiceVerifiedAutomatically && <Dropdown.Item text="Disable" onClick={() => onSetIsInvoiceVerifiedAutomatically(false)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
    </div>
  );
};

export default AutomaticInvoiceVerifcationFieldToggle;
