//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

//export function isUnauthorized(e) {
//    return (e.status === 401);
//}

import _ from "lodash";
import moment from "moment";
import Downloader from "js-file-downloader";

export interface IServerStream {
  closed: Promise<void>;
}

export interface IStreamMessage<T> {
  message?: T;
  error?: any;
}

export default {
  token: "",

  queryString(obj: any, exclude: string[], prefix?: string): string {
    if (!obj) return "";

    exclude = exclude || [];
    let qs = "?";

    for (const key in obj as object) {
      const fullKey = `${prefix || ""}${key}`;
      if (!exclude.includes(fullKey)) {
        let value = obj[key];
        if (_.isObject(value) && !_.isDate(value)) {
          const nested = _.trim(
            this.queryString(value, exclude, `${prefix || ""}${key}.`),
            "?"
          );
          if (nested.length) {
            qs += nested + "&";
          }
        } else {
          if (_.isDate(value)) {
            value = moment(value).toISOString();
          }
          qs += `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}&`;
        }
      }
    }

    // Remove the last '&' (or the '?' if nothing was added)
    return qs.substring(0, qs.length - 1);
  },

  // Decode the received result, expecting a JSON object
  async decodeResults(result: Response) {
    const decoded = await result.json();
    if (result.status !== 200) {
      let message = decoded.error;
      if (!message) {
        if (result.status === 401) {
          message = "Unauthorized";
        } else {
          message = `Unexpected status ${result.status}`;
        }
      }
      throw Object.assign(new Error(message), { status: result.status });
    }
    return decoded;
  },

  // api.get performs a GET request on the API with given local URL.
  // The result is decoded from JSON and returned.
  // Notice: We ignore the body object, if any (was easier in generated-code to have this API)
  async get(localURL: string, body?: object) {
    let headers: { [key: string]: string } = {
      Accept: "application/json",
    };
    if (this.token) {
      headers["Authorization"] = `bearer ${this.token}`;
    }

    const result = await fetch(localURL, { headers });
    return this.decodeResults(result);
  },

  // api.getText performs a GET request on the API with given local URL.
  // The result is decoded as plain text and returned.
  async getText(localURL: string) {
    let headers: { [key: string]: string } = {};
    if (this.token) {
      headers["Authorization"] = `bearer ${this.token}`;
    }

    const result = await fetch(localURL, { headers });
    return await result.text();
  },

  // api.post performs a POST request on the API with given local URL and given data.
  // The result is decoded from JSON and returned.
  async post(localURL: string, body?: object) {
    let headers: { [key: string]: string } = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (this.token) {
      headers["Authorization"] = `bearer ${this.token}`;
    }
    const result = await fetch(localURL, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    return this.decodeResults(result);
  },

  // api.patch performs a PATCH request on the API with given local URL and given data.
  // The result is decoded from JSON and returned.
  async patch(localURL: string, body: object) {
    let headers: { [key: string]: string } = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (this.token) {
      headers["Authorization"] = `bearer ${this.token}`;
    }
    const result = await fetch(localURL, {
      method: "PATCH",
      headers,
      body: JSON.stringify(body),
    });
    return this.decodeResults(result);
  },

  // api.put performs a PUT request on the API with given local URL and given data.
  // The result is decoded from JSON and returned.
  async put(localURL: string, body?: object) {
    let headers: { [key: string]: string } = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (this.token) {
      headers["Authorization"] = `bearer ${this.token}`;
    }
    const result = await fetch(localURL, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    });
    return this.decodeResults(result);
  },

  // api.delete performs a DELETE request on the API with given local URL and given data.
  // The result is decoded from JSON and returned.
  async delete(localURL: string, body?: object) {
    let headers: { [key: string]: string } = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (this.token) {
      headers["Authorization"] = `bearer ${this.token}`;
    }
    const result = await fetch(localURL, {
      method: "DELETE",
      headers,
      body: JSON.stringify(body),
    });
    return this.decodeResults(result);
  },

  // api.server_stream performs a POST request on the API with given local URL and given data.
  // The result is decoded from JSON and returned.
  async server_stream<T>(localURL: string, method: string, body: object, cb: (arg: IStreamMessage<T>) => void) {
    const encodedRequest = JSON.stringify(body || {});
    return new Promise<IServerStream>((resolve, reject) => {
      const protocol = window.location.protocol == "https:" ? "wss:" : "ws:";
      const fullURL = protocol + "//" + window.location.host + localURL + "?method=" + method;
      const socket = new WebSocket(fullURL, ["Bearer", this.token]);
      const streamClosed = new Promise<void>((streamClosedResolved) => {
        socket.addEventListener('open', () => {
          socket.send(encodedRequest);
          socket.send("close");
          resolve({
            closed: streamClosed,
          });
        });
        socket.addEventListener('error', reject);
        socket.addEventListener('message', (ev) => {
          const encoded = ev.data as string;
          const obj = JSON.parse(encoded);
          const error = obj['error'];
          if (!!error) {
            cb({ error: error })
          } else {
            cb({ message: obj['result'] });
          }
        })
        socket.addEventListener('close', () => { streamClosedResolved(); });
      })

    });
  },
  async download(localURL: string) {
    const downloader = new Downloader({
      url: localURL,
      headers: [{ name: "Authorization", value: `bearer ${this.token}` }],
      timeout: 0,
    });
    await downloader
  },
};

