//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import moment from "moment";
import _ from "lodash";
import { Duration } from "../api/googleTypes";

export const humanizeDuration = (x: Duration) => {
  const s = _.toNumber(_.toString(x).replace("s", ""));
  if (s == 0) {
    return "zero";
  }
  return moment.duration(s, "seconds").humanize();
};
