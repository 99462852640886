//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import { useEffect, useState } from "react";
import { Button, Icon, Input, Modal, Form, Checkbox } from "semantic-ui-react";
import { UpdateDeploymentTopologyAwarenessRequest as ApiUpdateDeploymentTopologyAwarenessRequest } from "../../api/lib";
import { ErrorMessage } from "../../ui/_errorMessage";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";

interface ITopologyAwarenessEditArgs extends CommentCreationArgs {
  isTopologyAware: boolean;
  numberOfZonesOverride: number;
  onUpdateTopologyAwareness: (req: ApiUpdateDeploymentTopologyAwarenessRequest) => any;
}

const TopologyAwarenessEditModal = ({ ...args }: ITopologyAwarenessEditArgs) => {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [topologyAware, setTopologyAware] = useState(args.isTopologyAware);
  const [numberOfZonesOverride, setNumberOfZonesOverride] = useState(args.numberOfZonesOverride);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    let newComment = "";
    if (args.isTopologyAware !== topologyAware) {
      newComment = `${topologyAware ? "Enabling" : "Disabling"} topology awareness`;
    }
    if (args.numberOfZonesOverride !== numberOfZonesOverride) {
      newComment = `${!!newComment ? `${newComment} with` : ""} ${numberOfZonesOverride} override zone(s) ${!!newComment ? "" : "added"}`;
    }
    setComment(newComment);
  }, [topologyAware, numberOfZonesOverride]);

  const onSave = async () => {
    setError("");
    setLoading(true);
    const req: ApiUpdateDeploymentTopologyAwarenessRequest = {
      topology_aware: topologyAware,
      number_of_zones_override: numberOfZonesOverride,
    };
    const { error } = await args.onUpdateTopologyAwareness(req);

    setError(error);
    if (!error) {
      const { error: commentError } = await args.createComment(comment);

      if (commentError) {
        setError(commentError);
        setLoading(false);
        return;
      }

      setLoading(false);
      setOpen(false);
      return;
    }
    setLoading(false);
  };

  return (
    <Modal
      trigger={<Icon name="pencil" className="edit-pencil" />}
      open={open}
      onClose={async () => {
        await setComment("");
        await setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      size="tiny"
    >
      <Modal.Header>Edit topology settings</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={error} />
        <Form onSubmit={onSave}>
          <Form.Group>
            <Form.Field>
              <label>Topology aware</label>
              <Checkbox toggle checked={topologyAware} onChange={(e, d) => setTopologyAware(d.checked || false)} />
            </Form.Field>
            <Form.Field>
              <label title="Set to 0 to disable override">Number of zones override</label>
              <Input type="number" min={0} max={4} value={numberOfZonesOverride} onChange={(e, d) => setNumberOfZonesOverride(parseInt(d.value as string))} />
            </Form.Field>
          </Form.Group>
          <Form.Field required>
            <label>Provide the reason for this change</label>
            <CommentInputField
              handleAddComment={() => {}}
              commentCreationInProcess={false}
              handleCommentChange={(value: string) => {
                setComment(value);
              }}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>Topology awareness settings</b>. If changed, a default comment will be provided which
              can be changed if required.
            </sub>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Dismiss" labelPosition="right" icon="cancel" onClick={() => setOpen(false)} />
        <Button
          content="Update"
          labelPosition="right"
          disabled={loading || !comment.trim()}
          loading={loading}
          icon="checkmark"
          positive
          onClick={() => onSave()}
        />
      </Modal.Actions>
    </Modal>
  );
};

interface ITopologyAwarenessViewArgs extends CommentCreationArgs {
  isTopologyAware: boolean;
  numberOfZonesOverride: number;
  canUpdateTopologyAwareness: boolean;
  onUpdateTopologyAwareness: (req: ApiUpdateDeploymentTopologyAwarenessRequest) => any;
}

export const TopologyAwarenessView = ({ ...args }: ITopologyAwarenessViewArgs) => {
  return (
    <div>
      {args.isTopologyAware ? "Yes" : "No"} {args.isTopologyAware && args.numberOfZonesOverride > 0 ? "(" + args.numberOfZonesOverride + ")" : ""}
      {args.canUpdateTopologyAwareness && (
        <TopologyAwarenessEditModal
          isTopologyAware={args.isTopologyAware}
          numberOfZonesOverride={args.numberOfZonesOverride}
          onUpdateTopologyAwareness={args.onUpdateTopologyAwareness}
          createComment={args.createComment}
        />
      )}
    </div>
  );
};
