//
// DISCLAIMER
//
// Copyright 2022-2024 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Grid } from "semantic-ui-react";
import { FieldSet } from "../../ui/_style";
import { DeploymentStatusView } from "../status/DeploymentStatusView";
import { AccessView, IAccessViewArgs } from "./AccessViews";
import { ConfigurationView, IConfigurationViewArgs } from "./ConfigurationView";
import { CustomCommandLineArgumentsView, ICustomCommandLineArgumentsViewArgs } from "./CustomCommandLineArguments";
import { DataClusterView, IDataClusterViewArgs } from "./DataClusterView";
import DeploymentEmailAddressesView from "./DeploymentEmailAddressView";
import { EndpointAllowlistsView, IEndpointAllowlistsViewArgs } from "./EndpointAllowlistView";
import { LocationView } from "./LocationView";
import NotificationSettingsView from "./NotificationSettingsView";
import { IVersionsViewArgs, VersionsView } from "./VersionsViews";
import {
  Organization as ApiOrganization,
  Deployment as ApiDeployment,
  MemoryReserve as ApiMemoryReserve,
  DeploymentInfo as ApiDeploymentInfo,
  Deployment_DiskAutoSizeSettings as ApiDeploymentDiskAutoSizeSettings,
  DeploymentEmailAddresses as ApiDeploymentEmailAddresses,
  UpdateDeploymentMemoryReservesRequest as ApiUpdateDeploymentMemoryReservesRequest,
  NodeSizeDetails as ApiNodeSizeDetails,
} from "../../api/lib";
import { CommentCreationArgs } from "../comments/CommentTypes";
import { KubeArangodbAdditionalCommandLineArgumentsView } from "./KubeArangodbArguments";
import { SchedulingPoliciesEditView } from "../schedulingpolicy/SchedulingPoliciesEditView";
import { AsyncResult } from "../../util/Types";

interface IDeploymentDetailsTabContentArgs
  extends IConfigurationViewArgs,
    IDataClusterViewArgs,
    IVersionsViewArgs,
    IEndpointAllowlistsViewArgs,
    ICustomCommandLineArgumentsViewArgs,
    IAccessViewArgs,
    CommentCreationArgs {
  deployment: ApiDeployment;
  loadingRootPassword: boolean;
  copiedRootPassword: boolean;
  onCopyRootPassword: () => void;

  organization: ApiOrganization;
  agentsMemoryReserve?: ApiMemoryReserve;

  coordinatorsMemoryReserve?: ApiMemoryReserve;
  dbserversMemoryReserve?: ApiMemoryReserve;
  onUpdateMemoryReserves: (request: ApiUpdateDeploymentMemoryReservesRequest) => void;
  deploymentInfo: ApiDeploymentInfo;
  diskAutoSizeSettings?: ApiDeploymentDiskAutoSizeSettings;
  nodeSizeDetails: ApiNodeSizeDetails;

  processingUpdateArangodbImage: boolean;
  processingUpdateKubeArangodbImage: boolean;
  processingUpdateNotebookImage: boolean;
  deploymentEmails: ApiDeploymentEmailAddresses;
  isUpToDate: boolean;
  canSetKubeArangodbAdditionalCommandLineArgs: boolean;
  onSaveKubeArangodbAdditionalCommandLineArguments: (kubeArangodbArgs: string[]) => Promise<AsyncResult<void>>;
  onUpdateCPUFactors: (newFactor: string) => Promise<AsyncResult<void>>;
  onUpdateMemoryFactors: (newFactor: string) => Promise<AsyncResult<void>>;
  canUpdateMemoryFactors?: boolean;
  canUpdateCPUFactors?: boolean;
  saveSchedulingPolicies: (ids: string[]) => void;
  canSetSchedulingPolicies?: boolean;
}

const DeploymentDetailsTabContent = (args: IDeploymentDetailsTabContentArgs) => {
  return (
    <div>
      <FieldSet>
        <Grid columns={3} doubling>
          <Grid.Column width="5">
            <DataClusterView {...args} />
          </Grid.Column>
          <Grid.Column width="5">
            <LocationView {...args} regionId={args.deployment.region_id} />
          </Grid.Column>
          <Grid.Column width="6">
            <AccessView
              {...args}
              certificates={args.deployment.certificates}
              ipallowlist_id={args.deployment.ipallowlist_id}
              disable_foxx_authentication={!!args.deployment.disable_foxx_authentication}
              status={args.deployment.status}
              loadingRootPassword={args.loadingRootPassword}
              copiedRootPassword={args.copiedRootPassword}
              onCopyRootPassword={args.onCopyRootPassword}
            />
          </Grid.Column>
        </Grid>
      </FieldSet>
      <FieldSet>
        <Grid columns={3} doubling>
          <Grid.Column width="5">
            <ConfigurationView
              {...args}
              organizationId={args.organization.id || ""}
              servers={args.deployment.servers}
              size={args.deployment.size}
              model={args.deployment.model}
              nodeSizeDetails={args.nodeSizeDetails}
              memoryFactors={args.deploymentInfo.memory_factors}
              cpuFactors={args.deploymentInfo.cpu_factors}
              agentsMemoryReserve={args.agentsMemoryReserve}
              coordinatorsMemoryReserve={args.coordinatorsMemoryReserve}
              dbserversMemoryReserve={args.dbserversMemoryReserve}
              onUpdateMemoryReserve={args.onUpdateMemoryReserves}
              agentsVolumeInfo={args.deploymentInfo.agents_volume_info}
              dbserverVolumeInfo={args.deploymentInfo.dbservers_volume_info}
              diskAutoSizeSettings={args.diskAutoSizeSettings}
              cpuResources={args.deploymentInfo.cpu_resources}
              memoryResources={args.deploymentInfo.memory_resources}
              customStorageClassName={args.deploymentInfo.custom_storage_class_name}
              diskPerformanceId={args.deployment.disk_performance_id}
              diskPerformanceLocked={args.deployment.disk_performance_locked}
              lastDiskPerformanceUpdatedAt={args.deployment.last_disk_performance_updated_at}
              lastDiskSizeUpdatedAt={args.deployment.last_disk_size_updated_at}
              diskRateLimitPeriod={args.deployment.disk_rate_limit_period}
              diskRateLimitActive={args.deployment.disk_rate_limit_active}
              onUpdateCPUFactors={args.onUpdateCPUFactors}
              requireComment={args.requireComment}
              requirePermission={args.requirePermission}
            />
          </Grid.Column>
          <Grid.Column width="5">
            <VersionsView
              {...args}
              organizationId={args.organization.id || ""}
              customKubeArangodbImage={args.customKubeArangodbImage || ""}
              version={args.deployment.version}
              customArangodbImage={args.deployment.custom_image}
              replace_version_by={args.deployment.replace_version_by}
              processingUpdateArangodbImage={args.processingUpdateArangodbImage}
              processingUpdateKubeArangodbImage={args.processingUpdateKubeArangodbImage}
            />
            <KubeArangodbAdditionalCommandLineArgumentsView
              createComment={args.createComment}
              handleCommentChange={args.handleCommentChange}
              kubeArangodbAdditionalArgs={args.deploymentInfo.kube_arangodb_additional_command_line_arguments}
              canSetKubeArangodbAdditionalCommandLineArgs={args.canSetKubeArangodbAdditionalCommandLineArgs}
              onSaveKubeArangodbAdditionalCommandLineArguments={args.onSaveKubeArangodbAdditionalCommandLineArguments}
            />
            <SchedulingPoliciesEditView
              schedulingPolicies={args.deploymentInfo.scheduling_policies || []}
              selected={args.deploymentInfo.scheduling_policies_ids || []}
              onSave={args.saveSchedulingPolicies}
              header={"Scheduling policied for deployment " + args.deployment.id}
              readonly={!args.canSetSchedulingPolicies}
            />
          </Grid.Column>
          <Grid.Column width="6">
            <DeploymentStatusView {...args} createdAt={args.deployment.created_at} status={args.deployment.status} isUpToDate={args.isUpToDate} />
          </Grid.Column>
        </Grid>
      </FieldSet>
      <FieldSet>
        <Grid columns={2} doubling>
          <Grid.Column width="5">
            <EndpointAllowlistsView {...args} />
          </Grid.Column>
          <Grid.Column width="11">
            <CustomCommandLineArgumentsView {...args} model={args.deployment.model} />
          </Grid.Column>
        </Grid>
      </FieldSet>
      <FieldSet>
        <Grid columns={2} doubling>
          <Grid.Column width="5">
            <NotificationSettingsView settings={args.deployment.notification_settings || {}} />
          </Grid.Column>
          <Grid.Column width="11">
            <DeploymentEmailAddressesView emailAddresses={args.deploymentEmails.addresses || {}} />
          </Grid.Column>
        </Grid>
      </FieldSet>
    </div>
  );
};

export default DeploymentDetailsTabContent;
