//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Icon, Popup, Table, Dropdown } from "semantic-ui-react";
import {
  BackupPolicyInfo as ApiBackupPolicyInfo,
  BackupPolicyInfoList as ApiBackupPolicyInfoList,
  Deployment as ApiDeployment,
  Organization as ApiOrganization,
  Project as ApiProject,
  BackupPolicy_HourlySchedule as ApiBackupPolicy_HourlySchedule,
  BackupPolicy_DailySchedule as ApiBackupPolicy_DailySchedule,
  BackupPolicy_MonthlySchedule as ApiBackupPolicy_MonthlySchedule,
  BackupPolicy_Schedule as ApiBackupPolicy_Schedule,
} from "../../api/lib";
import {
  Confirm,
  ConfirmInfo,
  ErrorMessage,
  Loading,
  humanizeDuration,
  SectionHead,
  Section,
  SectionHeader,
  SectionContent,
  IconWithPopup,
  Finalizers,
  SubID,
} from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

export enum BackupNotificationType {
  Never,
  FailureOnly,
  Always,
}

export enum BackupScheduleType {
  Hourly = 1,
  Daily,
  Monthly,
}

// Interface describing a backup policy
interface IHeaderView {}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>
        <IconWithPopup name="calendar" content="Schedule" />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <IconWithPopup name="pause" content="Is Paused" />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <IconWithPopup name="cloud upload" content="Upload" />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <IconWithPopup name="copy" content="Upload mode" />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <Popup
          trigger={
            <Icon.Group>
              <Icon name="clock" />
              <Icon corner name="trash" />
            </Icon.Group>
          }
          content="Retention period"
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <IconWithPopup name="mail" content="Email notification" />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <IconWithPopup name="heartbeat" content="Allow inconsistent" />
      </Table.HeaderCell>
      <Table.HeaderCell>Next backup</Table.HeaderCell>
      <Table.HeaderCell>Finalizers</Table.HeaderCell>
      <Table.HeaderCell>
        <IconWithPopup name="trash" content="Delete" />
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface ISchedule {
  schedule: ApiBackupPolicy_Schedule;
}

const Schedule = ({ ...args }: ISchedule) => {
  switch (args.schedule.schedule_type) {
    case "Hourly":
      return (
        <span>
          {args.schedule.schedule_type} every <b>{args.schedule.hourly_schedule && args.schedule.hourly_schedule.schedule_every_interval_hours}</b> hour(s) with{" "}
          <b>{(args.schedule.hourly_schedule && args.schedule.hourly_schedule.minutes_offset) || 0}</b> minutes offset
        </span>
      );
    case "Daily":
      let days = "";
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.monday) {
        days += "Mon ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.tuesday) {
        days += "Tue ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.wednesday) {
        days += "Wed ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.thursday) {
        days += "Thu ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.friday) {
        days += "Fri ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.saturday) {
        days += "Sat ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.sunday) {
        days += "Sun ";
      }
      let at_daily = "";
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.schedule_at) {
        at_daily = `${args.schedule.daily_schedule.schedule_at.hours || "0"}:${args.schedule.daily_schedule.schedule_at.minutes || "00"}h ${
          args.schedule.daily_schedule.schedule_at.time_zone
        }`;
      }
      return (
        <span>
          {args.schedule.schedule_type} on {days}at {at_daily}
        </span>
      );
    case "Monthly":
      let at_montly = "";
      if (args.schedule.monthly_schedule && args.schedule.monthly_schedule.schedule_at) {
        at_montly = `${args.schedule.monthly_schedule.schedule_at.hours || "0"}:${args.schedule.monthly_schedule.schedule_at.minutes || "00"}h ${
          args.schedule.monthly_schedule.schedule_at.time_zone
        }`;
      }
      return (
        <span>
          {args.schedule.schedule_type} day {args.schedule.monthly_schedule && args.schedule.monthly_schedule.day_of_month} at {at_montly}
        </span>
      );
  }

  return <span>-</span>;
};

// Interface describing a backup policy
interface IRowView {
  isDeploymentPaused: boolean;
  active: boolean;
  item: ApiBackupPolicyInfo;

  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (finalizer: string) => void;

  canUpdateAllowInconsistent: boolean;
  onUpdateAllowInconsistent: (newValue: boolean) => void;

  canUpdateUploadIncremental: boolean;
  onUpdateUploadIncremental: (newValue: boolean) => void;
}

const RowView = ({ ...args }: IRowView) => {
  const policyInfo = args.item;
  const policy = policyInfo.backup_policy || {};
  let nextBackupText =
    policy.status && policy.status.next_backup ? <DateTimePopupWithUTCAndLocalTime dateTime={policy.status.next_backup} label="Next Backup at" /> : "-";
  if (args.isDeploymentPaused) {
    nextBackupText = "Deployment Paused";
  }
  return (
    <Table.Row>
      <Table.Cell>
        <Popup trigger={<span>{policy.name || "-"}</span>} content={<span>{policy.description || "-"}</span>} />
        <SubID>{policy.id || ""}</SubID>
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={policy.created_at} label="Created At" />
      </Table.Cell>
      <Table.Cell>{policy.schedule ? <Schedule schedule={policy.schedule} /> : "-"}</Table.Cell>
      <Table.Cell>{policy.is_paused ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{policy.upload ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>
        {policyInfo.upload_use_incremental_structure ? "incremental" : "full"}
        {args.canUpdateUploadIncremental && (
          <Dropdown icon="pencil" className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {policyInfo.upload_use_incremental_structure && (
                <Dropdown.Item
                  key="remove-ui"
                  text="Disable incremental backup uploads (full backups will be uploaded)"
                  onClick={() => args.onUpdateUploadIncremental(false)}
                />
              )}
              {!policyInfo.upload_use_incremental_structure && (
                <Dropdown.Item key="set-ui" text="Enable incremental backup uploads" onClick={() => args.onUpdateUploadIncremental(true)} />
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.Cell>
      <Table.Cell>{policy.retention_period ? humanizeDuration(policy.retention_period) : "-"}</Table.Cell>
      <Table.Cell>{policy.email_notification ? policy.email_notification : "-"}</Table.Cell>
      <Table.Cell>
        {policyInfo.allow_inconsistent ? "yes" : "no"}
        {args.canUpdateAllowInconsistent && (
          <Dropdown icon="pencil" className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {policyInfo.allow_inconsistent && (
                <Dropdown.Item key="remove-caz" text="Disable allow inconsistent backups" onClick={() => args.onUpdateAllowInconsistent(false)} />
              )}
              {!policyInfo.allow_inconsistent && (
                <Dropdown.Item
                  key="set-caz"
                  text="Enable allow inconsistent backups (this can lead to backups which are NOT consistent!)"
                  onClick={() => args.onUpdateAllowInconsistent(true)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.Cell>
      <Table.Cell>{nextBackupText}</Table.Cell>
      <Table.Cell>
        <Finalizers finalizers={policyInfo.finalizers} remove={args.canRemoveFinalizer ? args.onRemoveFinalizer : undefined} />
      </Table.Cell>
      <Table.Cell>{policy.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={policy.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

// Interface describing the backup policy
interface IListView {
  items: ApiBackupPolicyInfo[];
  isDeploymentPaused: boolean;
  active: boolean;

  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (id: string, finalizer: string) => void;

  canUpdateAllowInconsistent: boolean;
  onUpdateAllowInconsistent: (id: string, newValue: boolean) => void;

  canUpdateUploadIncremental: boolean;
  onUpdateUploadIncremental: (id: string, newValue: boolean) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView />
    <Table.Body>
      {args.items.map((item) => (
        <RowView
          {...args}
          key={(item.backup_policy || {}).id}
          item={item}
          onRemoveFinalizer={(finalizer) => args.onRemoveFinalizer((item.backup_policy || {}).id || "", finalizer)}
          onUpdateAllowInconsistent={(newValue) => args.onUpdateAllowInconsistent((item.backup_policy || {}).id || "", newValue)}
          onUpdateUploadIncremental={(newValue) => args.onUpdateUploadIncremental((item.backup_policy || {}).id || "", newValue)}
        />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No backup policies are configured for this deployment</div>;

// Interface describing the backup policy list view arguments
export interface IBackupPolicyListViewArgs extends RouteComponentProps {
  active: boolean;
  loading: boolean;
  backupPolicies?: ApiBackupPolicyInfoList;
  isDeploymentPaused: boolean;
  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (id: string, finalizer: string) => void;
  canUpdateAllowInconsistent: boolean;
  onUpdateAllowInconsistent: (id: string, newValue: boolean) => void;
  canUpdateUploadIncremental: boolean;
  onUpdateUploadIncremental: (id: string, newValue: boolean) => void;
}

export const BackupPolicyListView = ({ ...args }: IBackupPolicyListViewArgs) => {
  if (!args.backupPolicies) {
    return <Loading />;
  }
  if (!args.backupPolicies.items || args.backupPolicies.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.backupPolicies.items} />;
};

// Interface decribing the properties of the backup policy list component
interface IBackupPolicyListProps extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  project: ApiProject;
  deployment?: ApiDeployment;
  backupPolicies?: ApiBackupPolicyInfoList;
  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (id: string, finalizer: string) => void;
  canUpdateAllowInconsistent: boolean;
  onUpdateAllowInconsistent: (id: string, newValue: boolean) => void;
  canUpdateUploadIncremental: boolean;
  onUpdateUploadIncremental: (id: string, newValue: boolean) => void;
}

// Interface decribing the state of the backup policy list component
interface IBackupPolicyListState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  deploymentId?: string;

  name: string;
  description: string;
  is_paused: boolean;
  upload: boolean;
  email_notification: BackupNotificationType;
  schedule_type: BackupScheduleType;
  hourly_schedule?: ApiBackupPolicy_HourlySchedule;
  daily_schedule?: ApiBackupPolicy_DailySchedule;
  monthly_schedule?: ApiBackupPolicy_MonthlySchedule;
}

// The component to show the backup policies inside a deployment as a list.
class BackupPolicyList extends Component<IBackupPolicyListProps, IBackupPolicyListState> {
  state: IBackupPolicyListState = {
    errorMessage: undefined,
    processing: false,
    confirmInfo: undefined,

    name: "",
    description: "",
    is_paused: false,
    upload: true,
    email_notification: BackupNotificationType.FailureOnly,
    schedule_type: 0 as BackupScheduleType,
    hourly_schedule: undefined,
    daily_schedule: undefined,
    monthly_schedule: undefined,
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <Section>
        <SectionHead>
          <SectionHeader title="Policies" />
        </SectionHead>
        <SectionContent>
          <Confirm confirmInfo={this.state.confirmInfo} />
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <div>
            <BackupPolicyListView
              {...this.props}
              isDeploymentPaused={(this.props.deployment && this.props.deployment.is_paused) || false}
              active={!this.state.processing}
            />
          </div>
        </SectionContent>
      </Section>
    );
  }
}

export default withRefresh()(BackupPolicyList);
