//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Organization as ApiOrganization, SetDeploymentModelsRequest as ApiSetDeploymentModelsRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IFlexibleDeploymentsFieldViewArgs {
  organization: ApiOrganization;
  canEditDeploymentModels: boolean;
  onSetFlexibleDeploymentsEnabled: (enabled: boolean) => void;
}

const FlexibleDeploymentsFieldView = ({ ...args }: IFlexibleDeploymentsFieldViewArgs) => {
  const is_flexible_deployments_enabled = !!args.organization.is_flexible_deployments_enabled;
  return (
    <Field>
      <FL>Flexible deployments</FL>
      <FC>
        {is_flexible_deployments_enabled ? "Enabled" : "Disabled"}
        {args.canEditDeploymentModels && (
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              {!is_flexible_deployments_enabled && <Dropdown.Item text="Enable" onClick={() => args.onSetFlexibleDeploymentsEnabled(true)} />}
              {is_flexible_deployments_enabled && <Dropdown.Item text="Disable" onClick={() => args.onSetFlexibleDeploymentsEnabled(false)} />}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </FC>
    </Field>
  );
};

interface IFlexibleDeploymentsFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface FlexibleDeploymentsFieldState extends IOrganizationDetailsFieldState {
  commentPrompt: ICommentPromptStateForIntegrations;
}

class FlexibleDeploymentsField extends OrganizationDetailsField<IFlexibleDeploymentsFieldProps, FlexibleDeploymentsFieldState> {
  state: FlexibleDeploymentsFieldState = {
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
    refreshNeeded: false,
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  onSetFlexibleDeploymentsEnabled = async (enabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set flexible deployments to ${enabled ? "enabled" : "disabled"}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization deployment models, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetDeploymentModelsRequest = {
            context_id: this.props.organization.id,
            is_flexible_deployments_enabled: enabled,
          };
          await apiClients.idashboardClient.SetOrganizationDeploymentModels(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const can_edit_deployment_models = this.hasPermission("internal-dashboard.organization.set-deployment-models");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <FlexibleDeploymentsFieldView
          {...this}
          {...this.props}
          {...this.state}
          canEditDeploymentModels={can_edit_deployment_models}
          onSetFlexibleDeploymentsEnabled={this.onSetFlexibleDeploymentsEnabled}
        />
      </div>
    );
  }
}

export default withRefresh()(FlexibleDeploymentsField);
