//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import apiClients from "../api/apiclients";
import { ListDataClusterOptions as ApiListDataClusterOptions, Empty as ApiEmpty, User as ApiUser } from "../api/lib";
import { Routes } from "../routes";
import { ContentBelowMenu, Loading } from "../ui/lib";
import { EventSubscriptionManager } from "../util/EventSubscriptionManager";
import { Permission, hasSupportPermission } from "../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import DashboardHome from "./DashboardHome";
import { DashboardTemplate } from "./DashboardTemplate";
import _ from "lodash";
import Auth from "../auth/Auth";

interface IDashboardViewArgs extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  hasUserInfo: boolean;
  eventSubscriptionManager: EventSubscriptionManager;
  environment: string;
  firingAlertsCPInformational: number;
  firingAlertsCPWarning: number;
  firingAlertsCPError: number;
  firingAlertsCPCritical: number;
  firingAlertsDCInformational: number;
  firingAlertsDCWarning: number;
  firingAlertsDCError: number;
  firingAlertsDCCritical: number;
  dcUpdateRunning: boolean;
  dcUpdateNeedsAttention: boolean;
  onClickLogout: () => void;
  canViewDCAlerts: boolean;
  canViewCPAlerts: boolean;
  canViewDCUpdateAlerts: boolean;
}

const DashboardView = ({ ...args }: IDashboardViewArgs) => (
  <DashboardTemplate {...args}>
    <ContentBelowMenu>
      <Route path={Routes.dashboard} render={(props) => (!args.hasUserInfo ? <Loading /> : <DashboardHome {...props} {...args} />)} />
    </ContentBelowMenu>
  </DashboardTemplate>
);

// Interface decribing the properties of the dashboard component
interface IDashboardProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  onClickLogout: () => void;
}

// Interface decribing the state of the dashboard component
interface IDashboardState {
  user?: ApiUser;
  hasUserInfo: boolean;
  environment: string;
  firingAlertsCPInformational: number;
  firingAlertsCPWarning: number;
  firingAlertsCPError: number;
  firingAlertsCPCritical: number;
  firingAlertsDCInformational: number;
  firingAlertsDCWarning: number;
  firingAlertsDCError: number;
  firingAlertsDCCritical: number;
  dcUpdateRunning: boolean;
  dcUpdateNeedsAttention: boolean;
  errorMessage?: string;
}

class Dashboard extends Component<IDashboardProps, IDashboardState> {
  state: IDashboardState = {
    user: undefined,
    hasUserInfo: false,
    environment: "?",
    firingAlertsCPInformational: 0,
    firingAlertsCPWarning: 0,
    firingAlertsCPError: 0,
    firingAlertsCPCritical: 0,
    firingAlertsDCInformational: 0,
    firingAlertsDCWarning: 0,
    firingAlertsDCError: 0,
    firingAlertsDCCritical: 0,
    dcUpdateRunning: false,
    dcUpdateNeedsAttention: false,
    errorMessage: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadUserInfo = async () => {
    const empty: ApiEmpty = {};
    const user = await apiClients.authenticationOnly.iamClient.GetThisUser();
    const isSupportResp = await apiClients.idashboardClient.IsSupportUser(empty);
    const isSupport = isSupportResp.result || false;

    if (!isSupport) {
      // You do not belong on this site
      window.location.href = "https://arangodb.com";
    } else {
      this.setState(
        {
          user: user,
          hasUserInfo: true,
        },
        () => {
          this.reloadControlplaneStatus();
          this.reloadDataClustersStatus();
        }
      );
    }
  };

  reloadControlplaneStatus = async () => {
    const has_controlplane_status = this.hasPermission("internal-dashboard.controlplanestatus.get");
    if (has_controlplane_status) {
      try {
        const controlplaneStatus = await apiClients.idashboardClient.GetControlplaneStatus();
        const alertStatusCP =
          controlplaneStatus &&
          controlplaneStatus.metrics_info &&
          controlplaneStatus.metrics_info.metrics_info &&
          controlplaneStatus.metrics_info.metrics_info.prometheus_alert_status;
        const firing_info = ((alertStatusCP || {}).firing_info || []).filter((alert) => !alert.is_silenced);
        const firingAlertsCPInformational = _.filter(firing_info, ["severity", "informational"]).length;
        const firingAlertsCPWarning = _.filter(firing_info, ["severity", "warning"]).length;
        const firingAlertsCPError = _.filter(firing_info, ["severity", "error"]).length;
        const firingAlertsCPCritical = _.filter(firing_info, ["severity", "critical"]).length;
        this.setState({
          errorMessage: undefined,
          environment: controlplaneStatus.environment || "?",
          firingAlertsCPInformational: firingAlertsCPInformational,
          firingAlertsCPWarning: firingAlertsCPWarning,
          firingAlertsCPError: firingAlertsCPError,
          firingAlertsCPCritical: firingAlertsCPCritical,
        });
      } catch (e) {
        this.setState({
          errorMessage: e,
        });
      }
    } else {
      this.setState({
        errorMessage: undefined,
        firingAlertsCPInformational: 0,
        firingAlertsCPWarning: 0,
        firingAlertsCPError: 0,
        firingAlertsCPCritical: 0,
      });
    }
  };

  reloadDataClustersStatus = async () => {
    const has_datacluster_list = this.hasPermission("internal-dashboard.datacluster.list");
    // For the firing alert counts we should not take the controlplane (as DC) into account.
    const cpID = "_cp";

    if (has_datacluster_list) {
      try {
        const req: ApiListDataClusterOptions = {};
        const dataClusters = await apiClients.idashboardClient.ListDataClusters(req);
        let firingAlertsDCCritical = 0;
        let firingAlertsDCError = 0;
        let firingAlertsDCWarning = 0;
        let firingAlertsDCInformational = 0;
        if (dataClusters.items) {
          dataClusters.items.forEach((dc) => {
            if (dc.id != cpID && dc.status && dc.status.metrics_info && dc.status.metrics_info.prometheus_alert_status) {
              const firing_info = (dc.status.metrics_info.prometheus_alert_status.firing_info || []).filter((alert) => !alert.is_silenced);
              firingAlertsDCInformational += _.filter(firing_info, ["severity", "informational"]).length;
              firingAlertsDCWarning += _.filter(firing_info, ["severity", "warning"]).length;
              firingAlertsDCError += _.filter(firing_info, ["severity", "error"]).length;
              firingAlertsDCCritical += _.filter(firing_info, ["severity", "critical"]).length;
            }
          });
        }

        this.setState({
          errorMessage: undefined,
          firingAlertsDCInformational: firingAlertsDCInformational,
          firingAlertsDCWarning: firingAlertsDCWarning,
          firingAlertsDCError: firingAlertsDCError,
          firingAlertsDCCritical: firingAlertsDCCritical,
        });
      } catch (e) {
        this.setState({
          errorMessage: e,
        });
      }
    } else {
      this.setState({
        errorMessage: undefined,
        firingAlertsDCInformational: 0,
        firingAlertsDCWarning: 0,
        firingAlertsDCError: 0,
        firingAlertsDCCritical: 0,
      });
    }
  };

  reloadDCUpdateStatus = async () => {
    const has_dcupdate_get_status = this.hasPermission("internal-dashboard.dcupdate.get-status");

    if (has_dcupdate_get_status) {
      try {
        const dcUpddateStatus = await apiClients.idashboardClient.GetDCUpdateStatus();

        this.setState({
          errorMessage: undefined,
          dcUpdateRunning: !!dcUpddateStatus.is_running,
          dcUpdateNeedsAttention: !!dcUpddateStatus.has_failures,
        });
      } catch (e) {
        this.setState({
          errorMessage: e,
        });
      }
    } else {
      this.setState({
        errorMessage: undefined,
        dcUpdateRunning: false,
        dcUpdateNeedsAttention: false,
      });
    }
  };

  componentDidMount() {
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadUserInfo, 60000); // Every minute
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadControlplaneStatus, 30000); // Every 30 sec
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadDataClustersStatus, `/Organization/_system/DataCluster/*`);
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadDCUpdateStatus, `"/Organization/_system/DCUpdate`);
  }

  render() {
    const can_list_dataclusters = this.hasPermission("internal-dashboard.datacluster.list");
    const can_get_controlplane_status = this.hasPermission("internal-dashboard.controlplanestatus.get");
    const can_get_dcupdate_status = this.hasPermission("internal-dashboard.dcupdate.get-status");

    return (
      <DashboardView
        {...this.props}
        {...this.state}
        eventSubscriptionManager={this.props.eventSubscriptionManager}
        onClickLogout={this.props.onClickLogout}
        canViewDCAlerts={can_list_dataclusters}
        canViewCPAlerts={can_get_controlplane_status}
        canViewDCUpdateAlerts={can_get_dcupdate_status}
      />
    );
  }
}

export default withRefresh()(Dashboard);
