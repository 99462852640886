//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps, Loader } from "semantic-ui-react";
import {
  DiskPerformanceList as ApiDiskPerformanceList,
  DiskPerformance as ApiDiskPerformance,
  ListAllDiskPerformancesRequest as ApiAllListDiskPerformancesRequest,
  ListDiskPerformancesRequest as ApiListDiskPerformancesRequest,
} from "../api/lib";
import apiClients, { Cached as apiClientsCached } from "../api/apiclients";
import { clone, map } from "lodash";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";

interface IDiskPerformanceInputProps extends IWithRefreshProps {
  id?: string;
  placeholder?: string;
  onChange: (id: string) => void;
  allowAny?: boolean;
  disabled?: boolean;

  notAll?: boolean;
  regionId?: string;
  nodeSizeId?: string;
  nodeDiskSize?: number;
}

interface IDiskPerformanceInputState {
  id: string;
  regionId: string;
  nodeSizeId: string;
  nodeDiskSize: number;
  errorMessage?: string;
  loading: boolean;
  diskPerformances?: ApiDiskPerformanceList;
  refreshNeeded: boolean;
}

class DiskPerformanceInputComp extends Component<IDiskPerformanceInputProps, IDiskPerformanceInputState> {
  state: IDiskPerformanceInputState = {
    id: this.props.id || "",
    regionId: this.props.regionId || "",
    nodeSizeId: this.props.nodeSizeId || "",
    nodeDiskSize: this.props.nodeDiskSize || 0,
    errorMessage: undefined,
    loading: true,
    refreshNeeded: false,
  };

  reloadDiskPerformances = async () => {
    this.setState({ loading: true });
    let diskPerformances: ApiDiskPerformanceList = {};
    if (this.props.notAll) {
      // If no region ID or node size ID is knows, the list should be empty
      if (this.state.regionId != "" && this.state.nodeSizeId != "") {
        const req: ApiListDiskPerformancesRequest = {
          region_id: this.state.regionId,
          node_size_id: this.state.nodeSizeId,
          dbserver_disk_size: this.state.nodeDiskSize,
        };
        diskPerformances = await apiClients.authenticationOnly.dataClient.ListDiskPerformances(req);
      }
    } else {
      const req: ApiAllListDiskPerformancesRequest = {};
      diskPerformances = await apiClientsCached.idashboardClient.ListAllDiskPerformances(req);
    }
    this.setState({ diskPerformances: diskPerformances, loading: false });
  };

  refreshDiskPerformances = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDiskPerformances);
  };

  static getDerivedStateFromProps(props: IDiskPerformanceInputProps, state: IDiskPerformanceInputState) {
    const { id = "", regionId = "", nodeSizeId = "", nodeDiskSize = 0 } = props;
    if (id != state.id || regionId != state.regionId || nodeSizeId != state.nodeSizeId || nodeDiskSize != state.nodeDiskSize) {
      return {
        refreshNeeded: true,
        id: id,
        regionId: regionId,
        nodeSizeId: nodeSizeId,
        nodeDiskSize: nodeDiskSize,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshDiskPerformances();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshDiskPerformances);
    }
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  render() {
    const diskPerformances = this.state.diskPerformances;
    let list = clone((diskPerformances || {}).items || []);
    if (this.props.allowAny) {
      list.push({ id: "", name: "Any" });
    }
    return (
      <div>
        {this.state.loading && <Loader active size="tiny" />}
        {diskPerformances && diskPerformances.items && (
          <Dropdown
            fluid
            selection
            placeholder={this.props.placeholder}
            options={map(list, (x: ApiDiskPerformance) => {
              return {
                key: x.id,
                text: x.name,
                value: x.id,
              };
            })}
            onChange={this.onChange}
            value={this.state.id}
            disabled={this.props.disabled}
          />
        )}
      </div>
    );
  }
}

export const DiskPerformanceInput = withRefresh()(DiskPerformanceInputComp);
