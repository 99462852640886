//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import Websocket from "react-websocket";
import { EventSubscriptionManager } from "../util/EventSubscriptionManager";
import Dashboard from "./Dashboard";
import { PermissionCache } from "../util/PermissionCache";
import Auth from "../auth/Auth";

// Interface decribing the properties of the dashboard bootstrap component
interface IDashboardBootstrapProps extends RouteComponentProps {
  onClickLogout: () => void;
  permissionCache: PermissionCache;
  auth: Auth;
}

// Interface decribing the state of the dashboard bootstrap component
interface IDashboardBootstrapState {
  eventSubscriptionManager: EventSubscriptionManager;
}

// Interface describing the properties for the dasboard bootstrap view
interface IDashboardBootstrapViewArgs extends RouteComponentProps {
  onClickLogout: () => void;
  eventSubscriptionManager: EventSubscriptionManager;
  permissionCache: PermissionCache;
  onWebsocketRef: (x: any) => void;
  auth: Auth;
}

const DashboardBootstrapView = ({ ...args }: IDashboardBootstrapViewArgs) => (
  <div>
    <Websocket url={args.eventSubscriptionManager.webSocketURL()} onMessage={args.eventSubscriptionManager.handleServerEvent} ref={args.onWebsocketRef} />
    <Dashboard {...args} loading={false} />
  </div>
);

class DashboardBootstrap extends Component<IDashboardBootstrapProps, IDashboardBootstrapState> {
  state: IDashboardBootstrapState = {
    eventSubscriptionManager: new EventSubscriptionManager(this),
  };

  refWebSocket: any;

  getWebSocket = (): any => {
    return this.refWebSocket;
  };

  setWebSocket = (x: any): any => {
    this.refWebSocket = x;
  };

  componentWillUnmount() {
    this.state.eventSubscriptionManager.stopTimer();
  }

  render() {
    return <DashboardBootstrapView {...this.props} {...this.state} onWebsocketRef={this.setWebSocket} />;
  }
}

// Notice that here withRefresh() is not used, this is because the Dashboard class creates the
// eventSubscriptionManager which is used as input property for all (child) components.
// This component is not using ReactTimeout, instead it's using the eventSubscriptionManager created here.
export default DashboardBootstrap;
