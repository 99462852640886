//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import _, { isEmpty, omit } from "lodash";
import { Moment } from "moment";
import { Component, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Grid, GridColumn, Icon, Input, Loader, Menu, Message, Popup, Statistic, Table } from "semantic-ui-react";
import apiClients, { Cached as cachedApiClients } from "../../api/apiclients";
import {
  DeploymentInfo as ApiDeploymentInfo,
  DeploymentInfoList as ApiDeploymentInfoList,
  DeploymentModel as ApiDeploymentModel,
  Deployment_Status as ApiDeployment_Status,
  IDOptions as ApiIDOptions,
  ListAllDeploymentsRequest as ApiListAllDeploymentsRequest,
  ListOptions as ApiListOptions,
  ListRegionsRequest as ApiListRegionsRequest,
  NodeSize as ApiNodeSize,
  CPUSize as ApiCPUSize,
  Region as ApiRegion,
  Tier as ApiTier,
  Version as ApiVersion,
  ListLoadBalancerAssignmentsRequest as ApiListLoadBalancerAssignmentsRequest,
  ListAllDeploymentsRequest,
} from "../../api/lib";
import { ListPlansRequest as ApiListPlansRequest, Plan as ApiSupportPlan } from "../../api/support/v1/support";
import {
  ContentSegment,
  CreationDateFilter,
  CreationDateFilters,
  ErrorMessage,
  IconWithPopup,
  LoaderBoxForTable as LoaderBox,
  Loading,
  PagingButtons,
  Processing,
  SecondaryMenu,
  SubID,
  TextLink,
  Locked,
  momentDefaultFormat,
  momentNow,
  formatVersion,
} from "../../ui/lib";
import OrganizationCache from "../../util/OrganizationCache";
import { Permission, ResourceType } from "../../util/PermissionCache";
import ProjectCache, { ProjectName, ProjectOrganizationName, ProjectOrganizationTierIcon } from "../../util/ProjectCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import DeploymentUsage from "./DeploymentUsage";
import Location from "./Location";
import { SupportPlanView } from "./SupportPlanView";
import { SupportOrganizationID } from "../../constants";
import { Routes } from "../../routes";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";

export enum Status {
  All = "All",
  Ready = "Ready",
  Upgrading = "Upgrading",
  Created = "Created",
  Creating = "Creating",
  Paused = "Paused",
  Hibernated = "Hibernated",
  NotHibernated = "NotHibernated",
  InHibernationMode = "InHibernationMode",
  NotInHibernationMode = "NotInHibernationMode",
  InMaintenanceMode = "InMaintenanceMode",
  NotInMaintenanceMode = "NotInMaintenanceMode",
  NotReady = "NotReady",
  NotUpgrading = "NotUpgrading",
  NotCreated = "NotCreated",
  NotCreating = "NotCreating",
  NotPaused = "NotPaused",
  Deleting = "Deleting",
  NotDeleting = "NotDeleting",
}

interface ExpirationFilter {
  id: string;
  name: string;
  hours: number; // 0 means does not expire
}

const expirationFilters: ExpirationFilter[] = [
  { id: "all", name: "All", hours: 0 },
  { id: "h24", name: "In 24hrs", hours: 24 },
  { id: "h48", name: "In 48hrs", hours: 48 },
  { id: "w1", name: "In next week", hours: 24 * 7 },
  { id: "w2", name: "In next 2 weeks", hours: 24 * 14 },
  { id: "notexpire", name: "Does not expire", hours: 0 },
];

interface IFilterViewArgs {
  onChangeDeploymentIdInputField: (value: string) => void;
  onChangeEndpointInputField: (value: string) => void;

  selectedTierID: string;
  tiers: ApiTier[];
  onTierChanged: (tierID: string) => void;

  selectedModelID: string;
  models: ApiDeploymentModel[];
  onModelChanged: (modelID: string) => void;

  selectedNodeSizeIDs: string[];
  nodeSizes: ApiNodeSize[];
  cpuSizes: ApiCPUSize[];
  onNodeSizesChanged: (nodeSizeIDs: string[]) => void;

  selectedSupportPlanID: string;
  supportPlans: ApiSupportPlan[];
  onSupportPlanChanged: (supportPlanID: string) => void;

  includedVersions: string[];
  excludedVersions: string[];
  versions: ApiVersion[];
  onVersionsChanged: (includedVersions: string[], excludedVersions: string[]) => void;

  selectedStatus: Status;
  onStatusChanged: (status: string) => void;

  selectedExpirationFilterID: string;
  expirationFilters: ExpirationFilter[];
  onExpirationFilterChanged: (filterID: string) => void;

  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;

  showRegionFilter: boolean;
  selectedRegionIDs: string[];
  regions: ApiRegion[];
  onRegionsChanged: (regionIDs: string[]) => void;

  page: number;
  pageSize: number;
  count: number;
  onNextPage: () => void;
  onPreviousPage: () => void;

  filterView?: boolean;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const tierOptions = _.orderBy(args.tiers, "name").map((x, i) => {
    return {
      key: `tier-${x.id || ""}`,
      text: x.name || "",
      value: x.id || "",
    };
  });
  tierOptions.unshift({
    key: "tier-all",
    text: "All",
    value: "all",
  });

  const modelOptions = _.orderBy(args.models, "name").map((x, i) => {
    return {
      key: `model-${x.id || ""}`,
      text: x.name || "",
      value: x.id || "",
    };
  });
  modelOptions.unshift({
    key: "model-all",
    text: "All",
    value: "all",
  });

  const getNodeSizeName = (id: string) => {
    const nodeSize = _.find(args.nodeSizes, (x) => x.id == id) || {};
    const cpuSize = _.find(args.cpuSizes, (cs) => cs.id == nodeSize.cpu_size) || {};
    return `${_.toUpper(nodeSize.name)} (${cpuSize.name})`;
  };
  const orderedNodeSizes = args.nodeSizes;
  const nodeSizeItems = orderedNodeSizes.map((x, i) => {
    const nodeSizeID = x.id || "";
    const included = _.includes(args.selectedNodeSizeIDs, nodeSizeID);
    return (
      <Dropdown.Item
        key={`nodeSize-${nodeSizeID}`}
        icon={included ? "eye" : "ellipsis horizontal"}
        text={getNodeSizeName(nodeSizeID)}
        onClick={(e) => {
          if (!included) {
            // Include explicitly
            args.onNodeSizesChanged(_.concat(args.selectedNodeSizeIDs, nodeSizeID));
          } else {
            // Exclude explicitly
            args.onNodeSizesChanged(_.without(args.selectedNodeSizeIDs, nodeSizeID));
          }
          e.stopPropagation();
        }}
      />
    );
  });
  nodeSizeItems.unshift(<Dropdown.Item key="nodeSize-all" text="All" value="all" onClick={() => args.onNodeSizesChanged([])} />);
  const getNodeSizeText = () => {
    if (_.isEmpty(args.selectedNodeSizeIDs)) {
      return "Size: All";
    }
    const names = args.selectedNodeSizeIDs.map((x) => getNodeSizeName(x));
    return `Size: ${joinShort(names, 3)}`;
  };

  const supportPlanOptions = _.orderBy(args.supportPlans, "name").map((x, i) => {
    return {
      key: `supportPlan-${x.id || ""}`,
      text: x.name || "",
      value: x.id || "",
    };
  });
  supportPlanOptions.unshift({
    key: "supportPlan-all",
    text: "All",
    value: "all",
  });

  // Versions are already ordered by the backend
  const versionItems = args.versions.map((x) => {
    const version = x.version || "";
    const included = _.includes(args.includedVersions, version);
    const excluded = _.includes(args.excludedVersions, version);
    return (
      <Dropdown.Item
        key={`version-${version}`}
        icon={included ? "eye" : excluded ? "eye slash" : "ellipsis horizontal"}
        text={formatVersion(x)}
        onClick={(e) => {
          if (!included && !excluded) {
            // Include explicitly
            args.onVersionsChanged(_.concat(args.includedVersions, version), _.without(args.excludedVersions, version));
          } else if (included) {
            // Exclude explicitly
            args.onVersionsChanged(_.without(args.includedVersions, version), _.concat(args.excludedVersions, version));
          } else if (excluded) {
            // Make indeterminate
            args.onVersionsChanged(_.without(args.includedVersions, version), _.without(args.excludedVersions, version));
          }
          e.stopPropagation();
        }}
      />
    );
  });
  versionItems.unshift(<Dropdown.Item key="version-all" text="All" value="all" onClick={() => args.onVersionsChanged([], [])} />);
  const joinShort = (list: string[], maxLength: number) => {
    if (list.length > maxLength) {
      return `${_.join(_.take(list, maxLength), ", ")}...`;
    }
    return _.join(list, ", ");
  };
  const getVersionText = () => {
    if (_.isEmpty(args.includedVersions) && _.isEmpty(args.excludedVersions)) {
      return "Version: All";
    }
    if (_.isEmpty(args.includedVersions)) {
      return `Version: All but ${joinShort(args.excludedVersions, 3)}`;
    }
    if (_.isEmpty(args.excludedVersions)) {
      return `Version: ${joinShort(args.includedVersions, 3)}`;
    }
    return "Versions: ...";
  };

  const orderedRegions = _.orderBy(args.regions, ["provider_id", "location"]);
  const orderedProviders = _.uniq(_.orderBy(args.regions, ["provider_id"]).map((x) => x.provider_id));
  const regionItems = orderedRegions.map((x, i) => {
    const regionID = x.id || "";
    const included = _.includes(args.selectedRegionIDs, regionID);
    return (
      <Dropdown.Item
        key={`region-${regionID}`}
        icon={included ? "eye" : "ellipsis horizontal"}
        text={`${_.toUpper(x.provider_id)} | ${x.location || regionID}`}
        onClick={(e) => {
          if (!included) {
            // Include explicitly
            args.onRegionsChanged(_.concat(args.selectedRegionIDs, regionID));
          } else {
            // Exclude explicitly
            args.onRegionsChanged(_.without(args.selectedRegionIDs, regionID));
          }
          e.stopPropagation();
        }}
      />
    );
  });
  _.reverse(orderedProviders).forEach((providerId) => {
    const providerRegionIds = _.filter(orderedRegions, (x) => x.provider_id == providerId).map((x) => x.id || "");
    regionItems.unshift(
      <Dropdown.Item
        key={`region-${providerId}`}
        text={`All ${_.upperCase(providerId)}`}
        value={`all-${providerId}`}
        onClick={() => args.onRegionsChanged(providerRegionIds)}
      />
    );
  });
  regionItems.unshift(<Dropdown.Item key="region-all" text="All" value="all" onClick={() => args.onRegionsChanged([])} />);
  const getRegionText = () => {
    if (_.isEmpty(args.selectedRegionIDs)) {
      return "Region: All";
    }
    const matchingProvider = _.find(orderedProviders, (providerId) => {
      const providerRegionIds = _.filter(orderedRegions, (x) => x.provider_id == providerId).map((x) => x.id || "");
      return _.isEqual(args.selectedRegionIDs, providerRegionIds);
    });
    if (!!matchingProvider) {
      return `Region: All ${_.upperCase(matchingProvider)}`;
    }
    return `Region: ${joinShort(args.selectedRegionIDs, 3)}`;
  };

  const statusText = (status: string) => {
    return _.capitalize(_.replace(status, "Not", "Not "));
  };
  const statusOptions = Object.keys(Status).map((x) => {
    return {
      key: `status-${x}`,
      text: statusText(x),
      value: x,
    };
  });

  const expirationOptions = args.expirationFilters.map((x, i) => {
    return {
      key: `exp-${x.id || ""}`,
      text: x.name,
      value: x.id,
    };
  });

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  return (
    <Menu borderless pointing stackable className={`${args.filterView ? "flex-wrap minimal-filter-view" : ""}`}>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown item text="Search">
        <Dropdown.Menu>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Deployment ID"
              name="search"
              onChange={(e, d) => args.onChangeDeploymentIdInputField(d.value)}
              onClick={(e: any) => e.stopPropagation()}
            />
          </Menu.Item>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Endpoint"
              name="search"
              onChange={(e, d) => args.onChangeEndpointInputField(d.value)}
              onClick={(e: any) => e.stopPropagation()}
            />
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown
        item
        value={args.selectedTierID}
        text={`Tier: ${getOptionText(tierOptions, args.selectedTierID) || "?"}`}
        options={tierOptions}
        onChange={(e, d) => args.onTierChanged(d.value as string)}
      />
      <Dropdown
        item
        value={args.selectedModelID}
        text={`Model: ${getOptionText(modelOptions, args.selectedModelID) || "?"}`}
        options={modelOptions}
        onChange={(e, d) => args.onModelChanged(d.value as string)}
      />
      <Dropdown item scrolling text={getNodeSizeText()} closeOnChange={false}>
        <Dropdown.Menu>{nodeSizeItems}</Dropdown.Menu>
      </Dropdown>
      <Dropdown
        item
        value={args.selectedSupportPlanID}
        text={`Support plan: ${getOptionText(supportPlanOptions, args.selectedSupportPlanID) || "?"}`}
        options={supportPlanOptions}
        onChange={(e, d) => args.onSupportPlanChanged(d.value as string)}
      />
      <Dropdown item scrolling text={getVersionText()} closeOnChange={false}>
        <Dropdown.Menu>{versionItems}</Dropdown.Menu>
      </Dropdown>
      {args.showRegionFilter && (
        <Dropdown item scrolling text={getRegionText()} closeOnChange={false}>
          <Dropdown.Menu>{regionItems}</Dropdown.Menu>
        </Dropdown>
      )}
      <Dropdown
        item
        value={args.selectedStatus}
        text={`Status: ${getOptionText(statusOptions, args.selectedStatus) || "?"}`}
        options={statusOptions}
        onChange={(e, d) => args.onStatusChanged(d.value as string)}
      />
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Created: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
      <Dropdown
        item
        value={args.selectedExpirationFilterID}
        text={`Expires: ${getOptionText(expirationOptions, args.selectedExpirationFilterID) || "?"}`}
        options={expirationOptions}
        onChange={(e, d) => args.onExpirationFilterChanged(d.value as string)}
      />
      {!args.filterView && (
        <Menu.Item position="right" fitted="vertically">
          <PagingButtons {...args} size="tiny" />
        </Menu.Item>
      )}
    </Menu>
  );
};

// Arguments for header view
interface IHeaderView {
  page: number;
  pageSize: number;
  count: number;
  filterView?: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Organization</Table.HeaderCell>
        {!args.filterView && (
          <>
            {" "}
            <Table.HeaderCell>
              <Popup trigger={<Icon name="paw" />} content="Tier" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Popup trigger={<Icon name="help circle" />} content="Support plan" />
            </Table.HeaderCell>
            <Table.HeaderCell>Version</Table.HeaderCell>
            <Table.HeaderCell>Model</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>
              <Popup trigger={<Icon name="random" />} content="Loadbalancer / Communication method" />
            </Table.HeaderCell>
            <Table.HeaderCell>Region</Table.HeaderCell>
            <Table.HeaderCell>
              <Popup trigger={<Icon name="eye" />} content="Status" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Popup trigger={<Icon name="bed" />} content="Hibernation" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Popup trigger={<Icon name="shield" />} content="Maintenace" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Popup
                trigger={
                  <Icon.Group>
                    <Icon name="clock" />
                    <Icon corner name="trash" />
                  </Icon.Group>
                }
                content="Expires"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>
              <Popup trigger={<Icon name="trash" />} content="Deleted" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Popup trigger={<Icon name="battery half" />} content="Activity" />
            </Table.HeaderCell>
          </>
        )}
      </Table.Row>
    </Table.Header>
  );
};

// Interface describing a status
export interface IStatusCellView {
  status?: ApiDeployment_Status;
  isUpToDate: boolean;
  isUpdating: boolean;
  isPaused: boolean;
  inHibernationMode: boolean;
}

export const StatusCellView = ({ status, isUpToDate, isUpdating, isPaused, inHibernationMode }: IStatusCellView) => {
  if (status) {
    if (!status.bootstrapped) {
      return (
        <Table.Cell>
          <IconWithPopup name="paper plane" color="purple" content="Bootstrapping..." />
        </Table.Cell>
      );
    }
    if (inHibernationMode) {
      return (
        <Table.Cell>
          <IconWithPopup name="bed" color="orange" content="Hibernating" />
        </Table.Cell>
      );
    }
    if (isPaused) {
      return (
        <Table.Cell>
          <IconWithPopup name="pause" color="green" content="Paused" />
        </Table.Cell>
      );
    }
    if (isUpdating) {
      return (
        <Table.Cell>
          <IconWithPopup name="sync" color="purple" content="Updating..." loading />
        </Table.Cell>
      );
    }
    if (!isUpToDate) {
      if (status.upgrading) {
        return (
          <Table.Cell>
            <IconWithPopup name="sync" color="orange" content="Upgrading..." loading />
          </Table.Cell>
        );
      }
      return (
        <Table.Cell>
          <IconWithPopup name="sync" color="orange" content="Updating..." loading />
        </Table.Cell>
      );
    }
    if (status.ready) {
      return (
        <Table.Cell>
          <IconWithPopup name="check" color="green" content="Ready" />
        </Table.Cell>
      );
    }
    if (status.created) {
      return (
        <Table.Cell>
          <IconWithPopup name="rocket" color="orange" content="Created" />
        </Table.Cell>
      );
    }
    return (
      <Table.Cell>
        <IconWithPopup name="rocket" color="orange" content="Creating..." />
        <SubID>{status.description}</SubID>
      </Table.Cell>
    );
  } else {
    return <Table.Cell negative>Unknown</Table.Cell>;
  }
};

export interface IHibernationCellViewArgs {
  item: ApiDeploymentInfo;
}

export const HibernationCellView = ({ ...args }: IHibernationCellViewArgs) => {
  const inHibernationMode = args.item.in_hibernation_mode || false;
  const isHibernated = args.item.is_hibernated || false;
  const isPaused = (args.item.deployment && args.item.deployment.is_paused) || false;
  const stable = inHibernationMode || isPaused == isHibernated;
  const msg = stable ? (inHibernationMode ? "Hibernated" : "Running normal") : inHibernationMode ? "Preparing to hibernate" : "Preparing to run";

  if (isPaused) {
    return (
      <Table.Cell>
        <IconWithPopup loading={!stable} name={"pause"} color={isHibernated ? "orange" : "green"} content={"Paused"} />
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <IconWithPopup
        loading={!stable}
        name={inHibernationMode ? "bed" : "check"}
        color={inHibernationMode || isHibernated ? "orange" : "green"}
        content={msg}
      />
    </Table.Cell>
  );
};

export interface IMaintenanceViewArgs {
  item: ApiDeploymentInfo;
  canUpdateProvisionHash: boolean;
  onClickUpdateProvisionHash: () => void;
}

export const MaintenanceView = ({ ...args }: IMaintenanceViewArgs) => {
  const inMaintenanceMode = args.item.in_maintenance_mode || false;
  const assignment = args.item.assignment || {};
  const provision_info_hash_outdated = !!assignment.provision_hash_outdated;

  if (inMaintenanceMode) {
    return (
      <Table.Cell>
        <IconWithPopup name="shield" color="orange" content="In maintenance mode" />
      </Table.Cell>
    );
  }

  if (provision_info_hash_outdated) {
    return (
      <Table.Cell>
        <IconWithPopup name="shield" color="purple" content={`Provision info hash (${assignment.provision_hash || "-"}) outdated`} />
        {args.canUpdateProvisionHash && (
          <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
            <Dropdown.Menu>
              <Dropdown.Item key="update-provhash" text="Update provision hash" onClick={args.onClickUpdateProvisionHash} />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <IconWithPopup name="check" color="green" content="Running normal" />
    </Table.Cell>
  );
};

// Interface describing a deployment
interface IRowView extends IWithRefreshProps {
  item: ApiDeploymentInfo;
  onClickOrganization: (organizationId: string) => void;
  onClickProject: (projectId: string) => void;
  projectCache: ProjectCache;
  organizationCache: OrganizationCache;
  canUpdateProvisionHash: boolean;
  onClickUpdateProvisionHash: (deployment_id: string) => void;
  nodeSizes: ApiNodeSize[];
  cpuSizes: ApiCPUSize[];
  versions: ApiVersion[];
  filterView?: boolean;
}

const RowView = ({ ...args }: IRowView) => {
  const [selectedLoadBalancer, setSelectedLoadBalancer] = useState<string>("");
  useEffect(() => {
    getDefaultLoadBalancer();
  }, []);
  const depl = args.item.deployment || {};
  const model = depl.model || {};
  const has_assignment = !!args.item.assignment;
  const assignment = args.item.assignment || {};
  const is_updating = assignment.provision_hash != assignment.last_reported_provision_hash;
  const use_shared_loadbalancer = !!args.item.use_shared_loadbalancer;
  const use_internal_loadbalancer = !!args.item.use_internal_loadbalancer;
  const dbserverVolumeInfo = args.item.dbservers_volume_info || {};
  const getNodeSizeName = (id?: string) => {
    if (!id) {
      return "-";
    }
    const nodeSize = _.find(args.nodeSizes, (x) => x.id == id) || {};
    const cpuSize = _.find(args.cpuSizes, (cs) => cs.id == nodeSize.cpu_size) || {};
    return `${_.toUpper(nodeSize.name)} (${cpuSize.name})`;
  };
  const getNodeDiskSize = (size?: number) => {
    if (!size) {
      return "";
    }
    const gb = 1024 * 1024 * 1024;
    return humanizeFileSize((size || 0) * gb);
  };

  const getDefaultLoadBalancer = async () => {
    const req: ApiListLoadBalancerAssignmentsRequest = {
      deployment_id: depl.id,
    };

    const response = await apiClients.idashboardClient.ListLoadBalancerAssignments(req);
    const { items = [] } = response;
    const defaultLoadBalancerAssigment = items.find((item) => item.is_default);
    const { load_balancer } = defaultLoadBalancerAssigment || {};
    const { type = "" } = load_balancer || {};
    setSelectedLoadBalancer(type);
  };

  const version = _.find(args.versions, (x) => x.version === depl.version) || {};
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>
        <TextLink label={depl.name} href={Routes.dashboard_support_deployment_detailsWithId(depl.id || "")} />
        {depl.description && (
          <Popup trigger={<Icon name="paperclip" />} position="right center">
            {depl.description}
          </Popup>
        )}
        {!!depl.locked && <Locked />}
        <SubID>{depl.id || ""}</SubID>
      </Table.Cell>
      <Table.Cell>
        <ProjectOrganizationName
          cache={args.projectCache}
          organizationCache={args.organizationCache}
          projectId={depl.project_id}
          onClickOrganization={args.onClickOrganization}
        />
        <SubID>
          <i>
            <ProjectName cache={args.projectCache} projectId={depl.project_id} onClick={() => args.onClickProject(depl.project_id || "")} />
          </i>
        </SubID>
      </Table.Cell>
      {!args.filterView && (
        <>
          <Table.Cell>
            <ProjectOrganizationTierIcon cache={args.projectCache} organizationCache={args.organizationCache} projectId={depl.project_id} />
          </Table.Cell>
          <Table.Cell>
            <SupportPlanView planId={depl.support_plan_id || ""} />
          </Table.Cell>
          <Table.Cell collapsing>
            {depl.version}
            {version.is_end_of_life && <Popup trigger={<Icon name="bell slash" />} content="End of life" />}
            {depl.custom_image && (
              <Popup trigger={<Icon name="paperclip" />} position="right center">
                {depl.custom_image}
              </Popup>
            )}
          </Table.Cell>
          <Table.Cell collapsing>{model.model || "?"}</Table.Cell>
          <Table.Cell collapsing>
            <div>{getNodeSizeName(model.node_size_id)}</div>
            <SubID>
              <Icon name="disk" size="small" />
              {getNodeDiskSize(model.node_disk_size)} ({dbserverVolumeInfo.type || args.item.custom_storage_class_name || "default"} -{" "}
              {depl.disk_performance_id || "default"})
            </SubID>
          </Table.Cell>
          <Table.Cell collapsing>
            <Popup
              trigger={
                <Icon
                  color={use_internal_loadbalancer ? "orange" : use_shared_loadbalancer ? "olive" : "teal"}
                  name={use_internal_loadbalancer ? "privacy" : use_shared_loadbalancer ? "users" : "user"}
                />
              }
              content={use_internal_loadbalancer ? "Internal loadbalancer" : use_shared_loadbalancer ? "Shared loadbalancer" : "Dedicated loadbalancer"}
            />
            <SubID>
              {args.item.communication_method || ""} || {selectedLoadBalancer}
            </SubID>
          </Table.Cell>
          <Table.Cell>
            <Location {...args} regionId={depl.region_id} showProvider={false} showRegion multiLine />
            <SubID>
              {!has_assignment && <span>{depl.region_id}</span>}
              {has_assignment && <TextLink label={depl.region_id} href={Routes.dashboard_support_datacluster_detailsWithId(assignment.datacluster_id || "")} />}
            </SubID>
          </Table.Cell>
          <StatusCellView
            status={depl.status}
            isUpToDate={!!args.item.is_up_to_date}
            isUpdating={is_updating}
            isPaused={!!depl.is_paused}
            inHibernationMode={!!args.item.in_hibernation_mode}
          />
          <HibernationCellView {...args} />
          <MaintenanceView {...args} onClickUpdateProvisionHash={() => args.onClickUpdateProvisionHash(depl.id || "")} />

          <Table.Cell>
            {depl.expiration ? (
              depl.expiration.expires_at ? (
                <DateTimePopupWithUTCAndLocalTime dateTime={depl.expiration.expires_at} label="Expires at" />
              ) : (
                "-"
              )
            ) : (
              "?"
            )}
          </Table.Cell>
          <Table.Cell>
            <DateTimePopupWithUTCAndLocalTime dateTime={depl.created_at} label="Created at" />
          </Table.Cell>
          <Table.Cell>{depl.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={depl.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
          <Table.Cell>
            <DeploymentUsage {...args} item={args.item} projectCache={args.projectCache} />
          </Table.Cell>
        </>
      )}
    </Table.Row>
  );
};

// Interface describing the deployment list
interface IListView extends IWithRefreshProps {
  items?: ApiDeploymentInfo[];
  selectedModelID: string;
  models: ApiDeploymentModel[];
  onModelChanged: (modelID: string) => void;
  nodeSizes: ApiNodeSize[];
  cpuSizes: ApiCPUSize[];
  includedVersions: string[];
  excludedVersions: string[];
  versions: ApiVersion[];
  onVersionsChanged: (includedVersions: string[], excludedVersions: string[]) => void;
  selectedExpirationFilterID: string;
  expirationFilters: ExpirationFilter[];
  onExpirationFilterChanged: (filterID: string) => void;
  onClickOrganization: (organizationId: string) => void;
  onClickProject: (projectId: string) => void;
  page: number;
  pageSize: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  projectCache: ProjectCache;
  organizationCache: OrganizationCache;
  selectedStatus: Status;
  onStatusChanged: (status: string) => void;
  canUpdateProvisionHash: boolean;
  onClickUpdateProvisionHash: (deployment_id: string) => void;
  filterView?: boolean;
}

const ListView = ({ ...args }: IListView) => {
  const items = args.items || [];
  return (
    <Table striped>
      <HeaderView {...args} count={items.length} />
      <Table.Body>
        {items.map((item) => (
          <RowView {...args} key={item.deployment && item.deployment.id} item={item} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface ITierStatistics {
  tier_id?: string;
  tier_name?: string;
  count?: number;
}

interface IStatistics {
  count?: number;
  by_tier?: ITierStatistics[];
}

interface IStatisticsViewArgs {
  statistics?: IStatistics;
  filterFrom?: Moment;
  filterTo?: Moment;
  filterObject: ListAllDeploymentsRequest;
}

const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const st = args.statistics || {};
  const by_tier = st.by_tier || [];
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";

  return (
    <div>
      <Message>Active deployments {timeFilter}.</Message>
      <Statistic.Group widths="5" size="tiny">
        <Statistic label="Total" value={st.count || 0} />
        {_.orderBy(by_tier, "tier_name").map((x) => (
          <Statistic key={x.tier_id || ""} label={x.tier_name || ""} value={x.count || 0} />
        ))}
      </Statistic.Group>
    </div>
  );
};

interface IEmptyViewArgs {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyViewArgs) => {
  return <div>No {args.page > 0 ? "more deployments" : "deployment"} inside this project</div>;
};

// Interface describing the deployment list view arguments
export interface IDeploymentListViewArgs extends IFilterViewArgs, IListView, IStatisticsViewArgs {
  deploymentInfos?: ApiDeploymentInfoList;
  filterView?: boolean;
  filterObject: ListAllDeploymentsRequest;
}

const DeploymentListViewContent = ({ ...args }: IDeploymentListViewArgs) => {
  const has_filter =
    args.selectedTierID != "all" ||
    args.selectedModelID != "all" ||
    !_.isEmpty(args.includedVersions) ||
    !_.isEmpty(args.excludedVersions) ||
    args.selectedExpirationFilterID != "all" ||
    args.selectedStatus != Status.All;
  const deploymentInfos = args.deploymentInfos || {};
  const items = deploymentInfos.items || [];
  if (!has_filter) {
    if (_.isEmpty(items)) {
      return <EmptyView {...args} />;
    }
  }
  return <ListView {...args} items={items} />;
};

export const DeploymentListView = ({ ...args }: IDeploymentListViewArgs) => {
  if (!args.deploymentInfos) {
    return <Loading />;
  }
  const filtersSelectedInFilterViewMode = omit(args.filterObject, ["options"]);
  if (args.filterView) {
    return (
      <Grid width={16}>
        <GridColumn width={4}>
          <FilterView {...args} />
        </GridColumn>
        <GridColumn width={12}>
          {isEmpty(filtersSelectedInFilterViewMode) ? (
            <Message> Select filters to start seeing deployments that will be a part of this job</Message>
          ) : (
            <>
              <DeploymentListViewContent {...args} />
              <PagingButtons {...args} size="tiny" />
            </>
          )}
        </GridColumn>
      </Grid>
    );
  }
  return (
    <div>
      <FilterView {...args} />
      <StatisticsView {...args} />
      <DeploymentListViewContent {...args} />
    </div>
  );
};

// Interface decribing the properties of the deployment list component
interface IDeploymentListProps extends IWithRefreshProps, RouteComponentProps {
  listVersion?: number; // If this number changes, the list is reloaded
  dataClusterId?: string;
  organization_id?: string;
  project_id?: string;
  filterView?: boolean;
  selectedFilters?: ListAllDeploymentsRequest;
  onDeploymentSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onProjectSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onFilterChange?: (filterOptions: ListAllDeploymentsRequest) => void;
}

// Interface decribing the state of the deployment list component
interface IDeploymentListState {
  listVersion: number;
  reloadListNeeded: boolean;
  deploymentInfos?: ApiDeploymentInfoList;
  page: number;
  pageSize: number;
  projectCache: ProjectCache;
  organizationCache: OrganizationCache;
  selectedTierID: string;
  tiers?: ApiTier[];
  selectedModelID: string;
  models?: ApiDeploymentModel[];
  selectedNodeSizeIDs: string[];
  nodeSizes?: ApiNodeSize[];
  cpuSizes?: ApiCPUSize[];
  selectedSupportPlanID: string;
  supportPlans?: ApiSupportPlan[];
  includedVersions: string[];
  excludedVersions: string[];
  versions?: ApiVersion[];
  selectedExpirationFilterID: string;
  selectedCreationDateFilterID: string;
  deploymentId: string;
  endpoint: string;
  selectedStatus: Status;
  regions?: ApiRegion[];
  selectedRegionIDs: string[];
  processingUpdateProvisionHash: boolean;
  errorMessage?: string;
  statistics?: IStatistics;
  filterFrom?: Moment;
  filterTo?: Moment;
  filterObject: ListAllDeploymentsRequest;
}

// The component to show the ca certificates inside a project as a list.
class DeploymentList extends Component<IDeploymentListProps, IDeploymentListState> {
  state: IDeploymentListState = {
    listVersion: 0,
    reloadListNeeded: false,
    page: 0,
    pageSize: this.props.filterView ? 8 : 10,
    projectCache: new ProjectCache(),
    organizationCache: new OrganizationCache(),
    selectedTierID: "all",
    tiers: undefined,
    selectedModelID: "all",
    models: undefined,
    selectedNodeSizeIDs: [],
    nodeSizes: undefined,
    cpuSizes: undefined,
    selectedSupportPlanID: "all",
    supportPlans: undefined,
    includedVersions: [],
    excludedVersions: [],
    versions: undefined,
    selectedExpirationFilterID: "all",
    selectedCreationDateFilterID: "all",
    deploymentId: "",
    endpoint: "",
    selectedStatus: Status.All,
    regions: undefined,
    selectedRegionIDs: [],
    processingUpdateProvisionHash: false,
    errorMessage: undefined,
    filterObject: {},
  };

  reloadDeployments = async () => {
    const req: ApiListAllDeploymentsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    if (this.props.organization_id) {
      req.organization_id = this.props.organization_id;
    }
    if (this.props.project_id) {
      req.project_id = this.props.project_id;
    }
    if (this.state.selectedTierID != "all") {
      req.tier_id = this.state.selectedTierID;
    }
    if (this.state.selectedModelID != "all") {
      req.model = this.state.selectedModelID;
    }
    if (this.state.selectedSupportPlanID != "all") {
      req.support_plan_id = this.state.selectedSupportPlanID;
    }
    if (!_.isEmpty(this.state.includedVersions)) {
      req.versions = this.state.includedVersions;
    }
    if (!_.isEmpty(this.state.excludedVersions)) {
      req.exclude_versions = this.state.excludedVersions;
    }
    if (!_.isEmpty(this.state.selectedRegionIDs)) {
      req.region_ids = this.state.selectedRegionIDs;
    }
    if (!_.isEmpty(this.state.selectedNodeSizeIDs)) {
      req.node_size_ids = this.state.selectedNodeSizeIDs;
    }
    if (this.state.selectedExpirationFilterID == "notexpire") {
      req.does_not_expire = true;
    } else if (this.state.selectedExpirationFilterID != "all") {
      const f = expirationFilters.find((x) => x.id == this.state.selectedExpirationFilterID);
      if (f) {
        req.expires_before = momentNow().add(f.hours, "hours").toDate();
      }
    }
    let filterFrom: Moment | undefined = undefined;
    let filterTo: Moment | undefined = undefined;

    if (this.state.selectedCreationDateFilterID != "all") {
      const f = CreationDateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
      if (f) {
        if (f.filterStart) {
          filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          req.from = filterFrom.toDate();
        }
        if (f.filterEnd) {
          filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          req.to = filterTo.toDate();
        }
      }
    }
    if (this.state.deploymentId !== "") {
      req.deployment_id = this.state.deploymentId;
    }
    if (this.state.endpoint !== "") {
      req.endpoint = this.state.endpoint;
    }
    if (this.state.selectedStatus != Status.All) {
      switch (this.state.selectedStatus) {
        case Status.Created:
          req.is_created = true;
          break;
        case Status.Ready:
          req.is_ready = true;
          break;
        case Status.Upgrading:
          req.is_upgrading = true;
          break;
        case Status.Creating:
          req.is_creating = true;
          break;
        case Status.Paused:
          req.is_paused = true;
          break;
        case Status.NotCreated:
          req.is_not_created = true;
          break;
        case Status.NotReady:
          req.is_not_ready = true;
          break;
        case Status.NotUpgrading:
          req.is_not_upgrading = true;
          break;
        case Status.NotCreating:
          req.is_not_creating = true;
          break;
        case Status.NotPaused:
          req.is_not_paused = true;
          break;
        case Status.InMaintenanceMode:
          req.is_in_maintenance_mode = true;
          break;
        case Status.NotInMaintenanceMode:
          req.is_not_in_maintenance_mode = true;
          break;
        case Status.Hibernated:
          req.is_hibernated = true;
          break;
        case Status.NotHibernated:
          req.is_not_hibernated = true;
          break;
        case Status.InHibernationMode:
          req.is_in_hibernation_mode = true;
          break;
        case Status.NotInHibernationMode:
          req.is_not_in_hibernation_mode = true;
          break;
        case Status.Deleting:
          req.is_deleting = true;
          break;
        case Status.NotDeleting:
          req.is_not_deleting = true;
          break;
      }
    }

    if (this.props.dataClusterId) {
      req.datacluster_id = this.props.dataClusterId;
    }
    this.setState({ filterObject: req }, () => {
      this.props.onFilterChange && this.props.onFilterChange(req);
    });
    const deploymentInfos = await apiClients.idashboardClient.ListAllDeployments(req);
    const count = await apiClients.idashboardClient.CountDeployments(req);
    const stats: IStatistics = {
      count: count.count,
      by_tier: [],
    };
    const tiers = this.state.tiers || [];
    if (this.state.selectedTierID == "all") {
      await Promise.all(
        tiers.map(async (t) => {
          const treq = _.clone(req);
          treq.tier_id = t.id;
          const count = await apiClients.idashboardClient.CountDeployments(treq);
          (stats.by_tier || []).push({
            tier_id: t.id,
            tier_name: t.name,
            count: count.count,
          });
        })
      );
    }

    this.setState({
      deploymentInfos: deploymentInfos,
      statistics: stats,
      filterFrom: filterFrom,
      filterTo: filterTo,
    });
  };

  reloadTiers = async () => {
    const req: ApiListOptions = {};
    const list = await apiClients.idashboardClient.ListTiers(req);
    this.setState({ tiers: list.items });
  };

  reloadModels = async () => {
    const req: ApiListOptions = {};
    const list = await cachedApiClients.idashboardClient.ListAllDeploymentModels(req);
    this.setState({ models: list.items });
  };

  reloadNodeSizes = async () => {
    const list = await cachedApiClients.idashboardClient.ListAllNodeSizes({});
    this.setState({ nodeSizes: list.items });
  };

  reloadCPUSizes = async () => {
    const list = await cachedApiClients.authenticationOnly.dataClient.ListCPUSizes({
      project_id: "all",
    });
    this.setState({ cpuSizes: list.items });
  };

  reloadSupportPlans = async () => {
    const req: ApiListPlansRequest = {};
    const list = await cachedApiClients.idashboardClient.ListSupportPlans(req);
    this.setState({ supportPlans: list.items });
  };

  reloadVersions = async () => {
    const req: ApiListOptions = {};
    const list = await cachedApiClients.idashboardClient.ListAllVersions(req);
    this.setState({ versions: list.items });
  };

  loadRegions = async (providerID: string) => {
    const req: ApiListRegionsRequest = {
      provider_id: providerID,
      organization_id: SupportOrganizationID,
    };
    const list = await cachedApiClients.authenticationOnly.platformClient.ListRegions(req);
    return list.items || [];
  };

  reloadRegions = async () => {
    const orgID = SupportOrganizationID;
    const providers = await cachedApiClients.authenticationOnly.platformClient.ListProviders({ organization_id: orgID });
    const allRegions = await Promise.all(
      _.map(providers.items || [], (p) => {
        return this.loadRegions(p.id || "");
      })
    );
    this.setState({ regions: _.flatten(allRegions) });
  };

  refreshDeployments = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDeployments);
  };

  refreshTiers = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTiers);
  };

  refreshModels = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadModels);
  };

  refreshNodeSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadNodeSizes);
  };

  refreshCPUSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCPUSizes);
  };

  refreshSupportPlans = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadSupportPlans);
  };

  refreshVersions = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadVersions);
  };

  refreshRegions = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadRegions);
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshDeployments
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshDeployments
    );
  };

  onClickView = (id: string) => {
    this.props.onDeploymentSelected(id);
  };

  onTierChanged = (id: string) => {
    this.setState({ selectedTierID: id }, this.refreshDeployments);
  };

  onModelChanged = (id: string) => {
    this.setState({ selectedModelID: id }, this.refreshDeployments);
  };

  onNodeSizesChanged = (nodeSizeIDs: string[]) => {
    this.setState({ selectedNodeSizeIDs: nodeSizeIDs });
  };

  onSupportPlanChanged = (id: string) => {
    this.setState({ selectedSupportPlanID: id }, this.refreshDeployments);
  };

  onVersionsChanged = (includedVersions: string[], excludedVersions: string[]) => {
    this.setState(
      {
        includedVersions: includedVersions,
        excludedVersions: excludedVersions,
      },
      this.refreshDeployments
    );
  };

  onRegionsChanged = (regionIDs: string[]) => {
    this.setState({ selectedRegionIDs: regionIDs }, this.refreshDeployments);
  };

  onExpirationFilterChanged = (id: string) => {
    this.setState({ selectedExpirationFilterID: id }, this.refreshDeployments);
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState({ selectedCreationDateFilterID: id }, this.refreshDeployments);
  };

  onChangeDeploymentIdInputField = (value: string) => {
    this.setState({ deploymentId: value }, this.refreshDeployments);
  };

  onChangeEndpointInputField = (value: string) => {
    this.setState({ endpoint: value }, this.refreshDeployments);
  };

  onStatusChanged = (status: string) => {
    this.setState({ selectedStatus: status as Status }, this.refreshDeployments);
  };

  static getDerivedStateFromProps(props: IDeploymentListProps, state: IDeploymentListState) {
    const listVersion = props.listVersion || 0;
    if (listVersion != state.listVersion) {
      return {
        reloadListNeeded: true,
        listVersion: listVersion,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    //TODO: Update based on events, for now use polling
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadDeployments, 5000);
    this.refreshRegions();
    this.refreshVersions();
    this.refreshTiers();
    this.refreshModels();
    this.refreshNodeSizes();
    this.refreshCPUSizes();
    this.refreshSupportPlans();
  }

  componentDidUpdate() {
    if (this.state.reloadListNeeded) {
      this.setState({ reloadListNeeded: false }, this.refreshDeployments);
    }
  }

  hasPermission = (p: Permission) => {
    const url = "";
    return !!(this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(url, ResourceType.Deployment, p));
  };

  onClickUpdateProvisionHash = async (deployment_id: string) => {
    try {
      this.setState({ processingUpdateProvisionHash: true, errorMessage: undefined });
      const opts: ApiIDOptions = {
        id: deployment_id,
      };
      await apiClients.idashboardClient.UpdateDataClusterAssignmentProvisionHash(opts);
      this.refreshDeployments();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingUpdateProvisionHash: false });
    }
  };

  onClearError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const deploymentInfos = this.state.deploymentInfos;
    const deploymentItems = (deploymentInfos || {}).items || [];
    const versions = this.state.versions;
    const tiers = this.state.tiers;
    const models = this.state.models;
    const nodeSizes = this.state.nodeSizes;
    const cpuSizes = this.state.cpuSizes;
    const supportPlans = this.state.supportPlans;
    const regions = this.state.regions;
    const can_update_provision_hash = this.hasPermission("internal-dashboard.dataclusterassignment.update-provision-hash");

    const view = (
      <DeploymentListView
        {...this.props}
        {...this.state}
        filterView={this.props.filterView}
        filterObject={this.state.filterObject}
        deploymentInfos={deploymentInfos}
        count={deploymentItems.length}
        versions={versions || []}
        tiers={tiers || []}
        models={models || []}
        nodeSizes={nodeSizes || []}
        cpuSizes={cpuSizes || []}
        supportPlans={supportPlans || []}
        regions={regions || []}
        showRegionFilter={!this.props.dataClusterId}
        canUpdateProvisionHash={can_update_provision_hash}
        expirationFilters={expirationFilters}
        creationDateFilters={CreationDateFilters}
        onTierChanged={this.onTierChanged}
        onModelChanged={this.onModelChanged}
        onNodeSizesChanged={this.onNodeSizesChanged}
        onSupportPlanChanged={this.onSupportPlanChanged}
        onVersionsChanged={this.onVersionsChanged}
        onRegionsChanged={this.onRegionsChanged}
        onExpirationFilterChanged={this.onExpirationFilterChanged}
        onCreationDateFilterChanged={this.onCreationDateFilterChanged}
        onChangeDeploymentIdInputField={this.onChangeDeploymentIdInputField}
        onChangeEndpointInputField={this.onChangeEndpointInputField}
        onClickOrganization={this.props.onOrganizationSelected}
        onClickProject={this.props.onProjectSelected}
        onNextPage={this.onNextPage}
        onPreviousPage={this.onPreviousPage}
        onStatusChanged={this.onStatusChanged}
        onClickUpdateProvisionHash={this.onClickUpdateProvisionHash}
      />
    );

    if (!!this.props.dataClusterId || !!this.props.organization_id || !!this.props.project_id) {
      return view;
    }
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.onClearError} message={this.state.errorMessage} />
        <Processing active={this.state.processingUpdateProvisionHash} message="Updating deployment assignment provision hash" />
        {!this.props.filterView && (
          <SecondaryMenu>
            <Menu.Item header>Deployments</Menu.Item>
            <LoaderBox>
              <Loader size="mini" active={this.props.loading} inline />
            </LoaderBox>
          </SecondaryMenu>
        )}
        {view}
      </ContentSegment>
    );
  }
}

export default withRefresh()(DeploymentList);
