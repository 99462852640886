//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Routes holds a list of local path's that map to the routes
// NOTICE: this should be entered in Go code as well, found in ../pkg/server/routes.go
export class Routes {
  static root = "/";
  static home = "/home";
  static dashboard = "/dashboard";
  static callback = "/callback";

  static dashboard_sales = "/dashboard/sales";

  static dashboard_sales_reports_daily = "/dashboard/sales/reports/daily";

  static dashboard_sales_organizations = "/dashboard/sales/organizations";
  static dashboard_sales_organization_details = "/dashboard/sales/organizations/:organizationId/details";
  static dashboard_sales_organization_detailsWithId = (organizationId: string): string => `/dashboard/sales/organizations/${encodeURI(organizationId)}/details`;
  static dashboard_sales_project_details = "/dashboard/sales/projects/:projectId/details";
  static dashboard_sales_project_detailsWithId = (projectId: string): string => `/dashboard/sales/projects/${encodeURI(projectId)}/details`;
  static dashboard_sales_tiers = "/dashboard/sales/tiers";
  static dashboard_sales_tier_details = "/dashboard/sales/tiers/:tierId/details";
  static dashboard_sales_tier_detailsWithId = (tierId: string): string => `/dashboard/sales/tiers/${encodeURI(tierId)}/details`;
  static dashboard_sales_users = "/dashboard/sales/users";
  static dashboard_sales_user_details = "/dashboard/sales/users/:userId/details";
  static dashboard_sales_user_detailsWithId = (userId: string): string => `/dashboard/sales/users/${encodeURI(userId)}/details`;
  static dashboard_sales_emails = "/dashboard/sales/emails";
  static dashboard_sales_pricingPlans = "/dashboard/sales/pricingplans";
  static dashboard_sales_pricingPlan_details = "/dashboard/sales/pricingplans/:planId/details";
  static dashboard_sales_pricingPlan_detailsWithId = (planId: string): string => `/dashboard/sales/pricingplans/${encodeURI(planId)}/details`;
  static dashboard_sales_currencies = "/dashboard/sales/currencies";

  static dashboard_sales_terms_and_conditions = "/dashboard/sales/terms_and_conditions";
  static dashboard_sales_terms_and_conditions_details = "/dashboard/sales/terms_and_conditions/:termsAndConditionsId/details";
  static dashboard_sales_terms_and_conditions_detailsWithId = (termsAndConditionsId: string): string =>
    `/dashboard/sales/terms_and_conditions/${encodeURI(termsAndConditionsId)}/details`;
  static dashboard_sales_terms_and_conditions_edit = "/dashboard/sales/terms_and_conditions/:termsAndConditionsId/edit";
  static dashboard_sales_terms_and_conditions_editWithId = (termsAndConditionsId: string): string =>
    `/dashboard/sales/terms_and_conditions/${encodeURI(termsAndConditionsId)}/edit`;
  static dashboard_sales_terms_and_conditions_create = "/dashboard/sales/terms_and_conditions/new";

  // upload documents
  static dashboard_uploaded_documents = "/dashboard/uploaded_documents";
  static dashboard_uploaded_document_details = "/dashboard/uploaded_documents/:uploadedDocumentId/details";
  static dashboard_uploaded_document_detailsWithId = (uploadedDocumentId: string): string =>
    `/dashboard/uploaded_documents/${encodeURI(uploadedDocumentId)}/details`;
  static dashboard_create_uploaded_document = "/dashboard/uploaded_documents/new";

  static dashboard_sales_data_processing_addendums = "/dashboard/sales/data_processing_addendums";
  static dashboard_sales_data_processing_addendum_details = "/dashboard/sales/data_processing_addendums/:dpaId/details";
  static dashboard_sales_data_processing_addendum_detailsWithId = (dpaId: string): string =>
    `/dashboard/sales/data_processing_addendum/${encodeURI(dpaId)}/details`;
  static dashboard_sales_data_processing_addendum_edit = "/dashboard/sales/data_processing_addendums/:dpaId/edit";
  static dashboard_sales_data_processing_addendum_editWithId = (dpaId: string): string => `/dashboard/sales/data_processing_addendums/${encodeURI(dpaId)}/edit`;
  static dashboard_sales_data_processing_addendum_create = "/dashboard/sales/data_processing_addendums/new";

  static dashboard_sales_invoices = "/dashboard/sales/invoices";
  static dashboard_sales_invoices_details = "/dashboard/sales/invoices/:invoiceId/details";
  static dashboard_sales_invoices_detailsWithId = (invoiceId: string): string => `/dashboard/sales/invoices/${encodeURI(invoiceId)}/details`;

  static dashboard_sales_revenue = "/dashboard/sales/revenue";
  static dashboard_sales_customer_changes = "/dashboard/sales/customer-changes";
  static dashboard_sales_deployment_cost = "/dashboard/sales/deployments-cost";
  static dashboard_sales_example_installations = "/dashboard/sales/example-installations";

  static dashboard_support = "/dashboard/support";
  static dashboard_support_dataclusters = "/dashboard/support/dataclusters";
  static dashboard_support_dataclusters_firing = "/dashboard/support/dataclusters?firingOnly=true";
  static dashboard_support_datacluster_details = "/dashboard/support/datacluster/:dcId/details";
  static dashboard_support_datacluster_detailsWithId = (dcId: string): string => `/dashboard/support/datacluster/${encodeURI(dcId)}/details`;
  static dashboard_support_comments = "/dashboard/support/comments";
  static dashboard_support_coredumps = "/dashboard/support/coredumps";

  static dashboard_support_deployments = "/dashboard/support/deployments";
  static dashboard_support_notebooks = "/dashboard/support/notebooks";
  static dashboard_support_auditlog = "/dashboard/support/auditlogs";
  static dashboard_support_deployment_changes = "/dashboard/support/deployment-changes";

  static dashboard_support_deployment_details = "/dashboard/support/deployment/:deploymentId/details";
  static dashboard_support_deployment_detailsWithId = (deploymentId: string): string => `/dashboard/support/deployment/${encodeURI(deploymentId)}/details`;

  static dashboard_support_auditlog_details = "/dashboard/support/auditlog/:auditLogId/details";
  static dashboard_support_auditlog_detailsWithId = (auditLogId: string): string => `/dashboard/support/auditlog/${encodeURI(auditLogId)}/details`;
  static dashboard_support_auditlogarchive_details = "/dashboard/support/auditlogarchive/:auditLogArchiveId/details";
  static dashboard_support_auditlogarchive_detailsWithId = (auditLogArchiveId: string): string =>
    `/dashboard/support/auditlogarchive/${encodeURI(auditLogArchiveId)}/details`;

  static dashboard_support_cacertificate_details = "/dashboard/support/cacertificate/:caCertificateId/details";
  static dashboard_support_cacertificate_detailsWithId = (caCertificateId: string): string =>
    `/dashboard/support/cacertificate/${encodeURI(caCertificateId)}/details`;

  static dashboard_support_emailpatterns = "/dashboard/support/emailpatterns";

  static dashboard_support_publicemailproviderdomains = "/dashboard/support/publicemailproviderdomains";

  static dashboard_support_providers = "/dashboard/support/providers";

  static dashboard_integration_tests = "/dashboard/tests";

  static dashboard_dcupdate = "/dashboard/dcupdate";

  static dashboard_deployment_update_job = "/dashboard/deployment-update";
  static dashboard_deployment_update_job_details = "/dashboard/deployment-update/:updateJobId/details";
  static dashboard_deployment_update_job_details_with_id = (updateJobId: string): string => `/dashboard/deployment-update/${encodeURI(updateJobId)}/details`;

  static dashboard_schedulingpolicy_list = "/dashboard/support/schedulingpolicies";
}
