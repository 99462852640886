//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Icon, Table } from "semantic-ui-react";
import { Tier as ApiTier } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { Loading, ContentSegment, Section, SectionHead, SectionHeader, SectionContent } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import FeatureFlags from "../feature/FeatureFlags";

interface IGeneralViewArgs {
  tier: ApiTier;
}

interface IFeatureViewArgs {
  tier: ApiTier;
}

const FeatureView = ({ ...args }: IFeatureViewArgs) => {
  const iconName = (v?: boolean) => {
    return !!v ? "check" : "cancel";
  };

  return (
    <Table striped basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Feature</Table.HeaderCell>
          <Table.HeaderCell>Supported by default</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Requires acceptance of Terms &amp; Conditions</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.requires_terms_and_conditions)} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Has audit logs</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.has_auditlog_feature)} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Has audit log destination cloud</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.has_auditlog_destination_cloud)} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Has audit log destination HTTP</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.has_auditlog_destination_https_post)} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Has backup uploads</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.has_backup_uploads)} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Has support plans</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.has_support_plans)} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Has support severity High</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.has_support_severity_high)} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Has support severity Critical</Table.Cell>
          <Table.Cell>
            <Icon name={iconName(args.tier.has_support_severity_critical)} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

interface ITierDetailsViewArgs extends IGeneralViewArgs, IWithRefreshProps, RouteComponentProps {}

const TierDetailsView = ({ ...args }: ITierDetailsViewArgs) => (
  <div>
    <Section>
      <SectionHead>
        <SectionHeader title={`${args.tier.name}: Default features`} />
      </SectionHead>
      <SectionContent>
        <FeatureView {...args} />
      </SectionContent>
    </Section>
    <Section>
      <SectionHead>
        <SectionHeader title="Beta feature flags" />
      </SectionHead>
      <SectionContent>
        <FeatureFlags {...args} tier_id={args.tier.id} />
      </SectionContent>
    </Section>
  </div>
);

interface ITierDetailsProps extends IWithRefreshProps, RouteComponentProps {
  tier?: ApiTier;
}

interface ITierDetailsState {}

class TierDetails extends Component<ITierDetailsProps, ITierDetailsState> {
  state: ITierDetailsState = {};

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  render() {
    const tier = this.props.tier;

    return <ContentSegment>{tier ? <TierDetailsView {...this.props} tier={tier} /> : <Loading />}</ContentSegment>;
  }
}

export default withRefresh()(TierDetails);
