//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component, SyntheticEvent, ChangeEvent, useState } from "react";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";
import apiClients from "../../api/apiclients";
import {
  Filter as ApiFilter,
  TestList as ApiTestList,
  Test as ApiTest,
  Organization as ApiOrganization,
  Region as ApiRegion,
  Provider as ApiProvider,
  Version as ApiVersion,
} from "../../api/lib";
import { KeyValuePair } from "../../api/common/v1/icommon";
import { Button, Checkbox, Dropdown, Form, Grid, Segment, Table, Modal, Input, InputOnChangeData, Popup, TextArea, TextAreaProps } from "semantic-ui-react";
import { ErrorMessage, Loading, Processing, IconWithPopup, FlexBox, FormActionButtonCancel, FormActionButtonSave } from "../../ui/lib";
import SelectProvider from "./SelectProvider";
import SelectRegion from "./SelectRegion";
import SelectVersion from "./SelectVersion";
import _ from "lodash";
import styled from "@emotion/styled";
import { SupportOrganizationID } from "../../constants";
import ReactJson from "react-json-view";

export const SmallText = styled("span")`
  font-size: 75%;
`;

const apiKeyIDStorageKey = "integrationTestsApiKeyID";

interface ILocationViewArgs extends RouteComponentProps, IWithRefreshProps {
  organization?: ApiOrganization;
  selectedProvider?: ApiProvider;
  selectedRegion?: ApiRegion;
  extraParams: Array<KeyValuePair>;
  onProviderUpdated: (provider?: ApiProvider) => void;
  onRegionUpdated: (region?: ApiRegion) => void;
  setExtraParams: (extraParams: Array<KeyValuePair>) => void;
}

const LocationView = ({ ...args }: ILocationViewArgs) => (
  <div>
    <SelectProvider {...args} provider={args.selectedProvider} organizationId={args.organization && args.organization.id ? args.organization.id : ""} />
    <SelectRegion
      {...args}
      provider={args.selectedProvider}
      region={args.selectedRegion}
      organizationId={args.organization && args.organization.id ? args.organization.id : ""}
    />
    <ExtraParamsModal extraParams={args.extraParams} setExtraParams={args.setExtraParams} />
  </div>
);

interface IExtraParamsModalArgs {
  extraParams: Array<KeyValuePair>;
  setExtraParams: (extraParams: Array<KeyValuePair>) => void;
}

const ExtraParamsModal = ({ ...args }: IExtraParamsModalArgs) => {
  const [isOpen, setIsOpen] = useState(false);
  const [extraParams, setExtraParams] = useState<Array<KeyValuePair>>(args.extraParams);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    // reset extra params
    setExtraParams(args.extraParams);
    setIsOpen(false);
  };

  const onSubmit = () => {
    // update args
    args.setExtraParams(extraParams);
    setIsOpen(false);
  };

  const onValueChange = (index: number, field: string, value: string) => {
    setExtraParams(extraParams.map((param, i) => (i === index ? { ...param, [field]: value } : param)));
  };

  return (
    <div>
      <FlexBox align="center" justify="space-between">
        <Button icon="plus" content="Add Extra Parameters" primary onClick={onOpen} size="tiny" type="button" />
        {extraParams.length > 0 && (
          <Popup
            trigger={<b style={{ cursor: "pointer", textDecoration: "underline" }}>{`${extraParams.length} extra params`}</b>}
            content={<ReactJson src={extraParams} name="extra_params" collapsed />}
            on="click"
            pinned
          />
        )}
      </FlexBox>
      {isOpen && (
        <Modal open={isOpen} onClose={onClose} size="large">
          <Modal.Header>
            <FlexBox justify="space-between" alignItems="center">
              <div>Extra parameters</div>
              <Button
                icon="plus"
                size="tiny"
                content="Add more params"
                primary
                onClick={() => {
                  setExtraParams(_.concat(extraParams, [{ key: "", value: "" }]));
                }}
              />
            </FlexBox>
          </Modal.Header>
          <Modal.Content>
            {extraParams.length === 0 ? (
              <div>
                <span>No extra parameters, please add params</span>
              </div>
            ) : (
              <>
                {(extraParams || []).map((param, index) => (
                  <Form>
                    <Form.Group key={index} widths="equal">
                      <Form.Field
                        control={Input}
                        label="Key"
                        name="Key"
                        placeholder="Key"
                        value={param.key}
                        onChange={(e: SyntheticEvent<HTMLInputElement, Event>, d: InputOnChangeData) => {
                          onValueChange(index, "key", d.value || "");
                        }}
                      />
                      <Form.Field>
                        <label>Value</label>
                        <TextArea
                          rows="1"
                          value={param.value}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>, d: TextAreaProps) => {
                            onValueChange(index, "value", (d.value || "").toString());
                          }}
                        />
                      </Form.Field>
                      <FlexBox align="flex-start" style={{ marginTop: "1.75rem" }}>
                        <Button
                          style={{ height: "3rem" }}
                          icon="close"
                          size="mini"
                          type="button"
                          onClick={() => {
                            setExtraParams(_.without(extraParams, param));
                          }}
                        />
                      </FlexBox>
                    </Form.Group>
                  </Form>
                ))}
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            <FormActionButtonCancel onClick={onClose} title="Close" />
            <FormActionButtonSave icon="save" primary onClick={onSubmit} title="Save" />
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

interface IFilterViewArgs extends RouteComponentProps, IWithRefreshProps {
  organization?: ApiOrganization;
  selectedVersion?: ApiVersion;
  onVersionUpdated: (version?: ApiVersion) => void;
  customArangodbImage?: string;
  onCustomArangodbImageChanged: (newImage: string) => void;
  customKubeArangodbImage?: string;
  onCustomKubeArangodbImageChanged: (newImage: string) => void;
}

const FilterView = ({ ...args }: IFilterViewArgs) => (
  <div>
    <SelectVersion {...args} organizationId={args.organization && args.organization.id ? args.organization.id : SupportOrganizationID} />
    <Form.Input
      label="Custom ArangoDB image"
      name="CustomArangodbImage"
      placeholder="Custom arangodb image"
      value={args.customArangodbImage}
      onChange={(e, d) => args.onCustomArangodbImageChanged(d.value)}
      type="text"
    />
    <Form.Input
      label="Custom Kube-ArangoDB image"
      name="CustomKubeArangodbImage"
      placeholder="Custom kube-arangodb image"
      value={args.customKubeArangodbImage}
      onChange={(e, d) => args.onCustomKubeArangodbImageChanged(d.value)}
      type="text"
    />
  </div>
);

interface IAuthenticationViewArgs extends RouteComponentProps, IWithRefreshProps {
  authKeyId: string;
  authKeySecret: string;
  onApiKeyIDChanged: (value: string) => void;
  onApiKeySecretChanged: (value: string) => void;
}

const AuthenticationView = ({ ...args }: IAuthenticationViewArgs) => (
  <div>
    <Form.Input
      label="API key ID"
      name="AuthKeyID"
      placeholder="authKeyId"
      value={args.authKeyId}
      onChange={(e, d) => args.onApiKeyIDChanged(d.value)}
      type="text"
      required
    />
    <Form.Input
      label="API key secret"
      name="Auth Key Secret"
      placeholder="authKeySecret"
      value={args.authKeySecret}
      onChange={(e, d) => args.onApiKeySecretChanged(d.value)}
      type="password"
      required
    />
  </div>
);

interface IIntegrationTestsListViewArgs extends RouteComponentProps {
  loading: boolean;
  tests?: ApiTestList;
  selectedTestCases: string[];
  onClickSelect: (test: string) => void;
  onClickSelectDefault: () => void;
  onClickSelectAll: () => void;
  onClickSelectNone: () => void;
  onClickSelectCategory: (category: string) => void;
}

export const IntegrationTestsListView = ({ ...args }: IIntegrationTestsListViewArgs) => {
  if (!args.tests) {
    return <Loading />;
  }
  const isEmpty = !args.tests.items || args.tests.items.length === 0;
  // Get all unique categories
  const items = args.tests.items || [];
  let distinctCategories: string[] = [];
  items.forEach((test) => {
    const categories = test.categories || [];
    categories.forEach((c) => {
      if (!distinctCategories.includes(c)) {
        distinctCategories.push(c);
      }
    });
  });

  return (
    <div>
      <ListView {...args} loading={args.loading} tests={args.tests} onClickSelect={args.onClickSelect} categories={distinctCategories} />
      {isEmpty && <EmptyView />}
    </div>
  );
};

interface IEmptyView {}

const EmptyView = ({ ...args }: IEmptyView) => <div>No more test cases</div>;

// Interface describing the integration-tests list
interface IListView {
  loading: boolean;
  tests: ApiTestList;
  selectedTestCases: string[];
  onClickSelect: (test: string) => void;
  onClickSelectDefault: () => void;
  onClickSelectAll: () => void;
  onClickSelectNone: () => void;
  categories: string[];
  onClickSelectCategory: (category: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped compact>
    <HeaderView {...args} />
    <Table.Body>
      {args.tests.items &&
        args.tests.items.map((test, i) => (
          <RowView
            item={test}
            key={`inttest${i}`}
            isSelected={args.selectedTestCases.includes(test.name || "")}
            onClickSelect={() => {
              args.onClickSelect(test.name || "");
            }}
          />
        ))}
    </Table.Body>
  </Table>
);

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  onClickSelectDefault: () => void;
  onClickSelectAll: () => void;
  onClickSelectNone: () => void;
  categories: string[];
  onClickSelectCategory: (category: string) => void;
}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <span>
            <Dropdown button compact icon="check square outline" inline className="icon tiny">
              <Dropdown.Menu>
                <Dropdown.Item onClick={args.onClickSelectDefault} text="Default" />
                <Dropdown.Item onClick={args.onClickSelectAll} text="All" />
                <Dropdown.Item onClick={args.onClickSelectNone} text="None" />
                {args.categories.map((c) => (
                  <Dropdown.Item onClick={() => args.onClickSelectCategory(c)} text={"Category: " + c} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Importance</Table.HeaderCell>
        <Table.HeaderCell>Providers</Table.HeaderCell>
        <Table.HeaderCell>Regions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

interface IImportanceViewArgs {
  importance: string;
}

const ImportanceView = ({ ...args }: IImportanceViewArgs) => {
  switch (args.importance) {
    case "NORMAL":
      return <IconWithPopup name="bars" content="Normal" color="orange" />;
    case "HIGH":
      return <IconWithPopup name="arrow up" content="High" color="red" />;
    case "LOW":
      return <IconWithPopup name="arrow down" content="Low" color="blue" />;
    case "MANUAL":
      return <IconWithPopup name="hand paper outline" content="Manual" color="purple" />;
    default:
      return <IconWithPopup name="question" content={args.importance} />;
  }
};

interface IRegionsViewArgs {
  randomRegion: boolean;
}

const RegionsView = ({ ...args }: IRegionsViewArgs) => {
  switch (args.randomRegion) {
    case true:
      return <IconWithPopup name="random" content="Random per provider" />;
    default:
      return <IconWithPopup name="world" content="All per provider" />;
  }
};

interface IProvidersViewArgs {
  item: ApiTest;
}

const ProvidersView = ({ ...args }: IProvidersViewArgs) => {
  const hasProviders = !_.isEmpty(args.item.providers);
  const providerNames = _.join(args.item.providers, ", ");
  const randomProvider = !!args.item.random_provider;

  if (randomProvider) {
    if (hasProviders) {
      return <IconWithPopup name="random" content={`Random from ${providerNames}`} color="orange" />;
    }
    return <IconWithPopup name="random" content="Random" />;
  }
  if (hasProviders) {
    return <IconWithPopup name="cloud" content={providerNames} color="orange" />;
  }
  return <IconWithPopup name="world" content="All" />;
};

// Interface describing an integration test
interface IRowView {
  item: ApiTest;
  isSelected: boolean;
  onClickSelect: (test: string) => void;
}

const RowView = ({ ...args }: IRowView) => {
  const name = args.item.name || "";
  const description = args.item.description || "";
  const importance = args.item.importance || "";
  const randomRegion = !!args.item.random_region;
  const extraParamInfo = args.item.extra_params_info || [];

  return (
    <Table.Row>
      <Table.Cell textAlign="center" collapsing>
        <Checkbox name="select" checked={args.isSelected} onClick={() => args.onClickSelect(name)} />
      </Table.Cell>
      <Table.Cell collapsing>
        {
          <Popup
            trigger={<span style={{ cursor: "pointer" }}>{name}</span>}
            content={<ReactJson src={extraParamInfo} name="extra_params_info" collapsed />}
            on="click"
            pinned
            position="bottom left"
          />
        }
      </Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <Table.Cell>
        <ImportanceView importance={importance} />
      </Table.Cell>
      <Table.Cell>
        <ProvidersView {...args} />
      </Table.Cell>
      <Table.Cell>
        <RegionsView randomRegion={randomRegion} />
      </Table.Cell>
    </Table.Row>
  );
};

// Interface decribing the properties of the integration tests component
interface IIntegrationTestsRunProps extends IWithRefreshProps, RouteComponentProps {
  organization?: ApiOrganization;
  onRunStarted: () => void;
}

// Interface decribing the state of the integration tests component
interface IIntegrationTestsRunState {
  item?: ApiFilter;
  errorMessage?: string;
  isTestRunning: boolean;
  processingRunTest: boolean;
  tests?: ApiTestList;
  selectedTestCases: Array<string>;
  authKeyId: string;
  authKeySecret: string;
  selectedProvider?: ApiProvider;
  selectedRegion?: ApiRegion;
  selectedVersion?: ApiVersion;
  customArangodbImage: string;
  customKubeArangodbImage: string;
  extraParams: Array<KeyValuePair>;
}

class IntegrationTestsRun extends Component<IIntegrationTestsRunProps, IIntegrationTestsRunState> {
  state: IIntegrationTestsRunState = {
    authKeyId: "",
    authKeySecret: "",
    isTestRunning: false,
    processingRunTest: false,
    errorMessage: undefined,
    item: undefined,
    tests: undefined,
    selectedTestCases: new Array<string>(),
    selectedProvider: undefined,
    selectedRegion: undefined,
    selectedVersion: undefined,
    customArangodbImage: "",
    customKubeArangodbImage: "",
    extraParams: [],
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  componentDidMount() {
    const key = localStorage.getItem(apiKeyIDStorageKey) || "";
    if (!!key) {
      this.setState({ authKeyId: key });
    }
    this.refreshTestCaseList();
  }

  reloadTestCaseList = async () => {
    try {
      const tests = await apiClients.idashboardClient.GetTestCases();
      this.setState({ tests: tests });
    } catch (e) {
      this.setState({ errorMessage: `Failed to load test cases: ${e}` });
    }
  };

  refreshTestCaseList = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTestCaseList);
  };

  onStartTestRun = async () => {
    this.setState({ processingRunTest: true });
    try {
      const filter: ApiFilter = {
        test_cases: this.state.selectedTestCases,
        api_key_id: this.state.authKeyId,
        api_key_secret: this.state.authKeySecret,
        custom_arangodb_image: this.state.customArangodbImage,
        custom_kube_arangodb_image: this.state.customKubeArangodbImage,
      };
      const selectedProvider = this.state.selectedProvider;
      if (selectedProvider) {
        filter.provider = selectedProvider.id;
      }
      const selectedRegion = this.state.selectedRegion;
      if (selectedRegion) {
        filter.region = selectedRegion.id;
      }
      const selectedVersion = this.state.selectedVersion;
      if (selectedVersion) {
        filter.default_version = selectedVersion.version;
      }
      // filter extra params and add to request object
      const extraParams = this.state.extraParams.filter((x) => (x.key || "").trim() && (x.value || "").trim());
      if (extraParams.length) {
        filter.extra_params = extraParams;
      }
      await apiClients.idashboardClient.RunTests({ filter: filter });
      this.props.onRunStarted();
    } catch (e) {
      this.setState({ errorMessage: `Failed to start integration tests: ${e}` });
    }
    this.setState({ processingRunTest: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  setExtraParams = (extraParams: Array<KeyValuePair>) => {
    this.setState({ extraParams: extraParams });
  };

  onClickSelect = (test: string) => {
    this.setState((old) => {
      if (!old.selectedTestCases.includes(test)) {
        return {
          selectedTestCases: _.concat(old.selectedTestCases, test),
        };
      } else {
        return {
          selectedTestCases: _.without(old.selectedTestCases, test),
        };
      }
    });
  };

  onClickSelectAll = () => {
    const tests = this.state.tests || {};
    const testItems = tests.items || [];
    const testCases = testItems.map((x) => x.name || "");
    this.setState({ selectedTestCases: testCases });
  };

  onClickSelectDefault = () => {
    const tests = this.state.tests || {};
    const testItems = tests.items || [];
    const selectedTestItems = _.filter(testItems, (x) => x.importance != "MANUAL" && x.importance != "LOW");
    const testCases = selectedTestItems.map((x) => x.name || "");
    this.setState({ selectedTestCases: testCases });
  };

  onClickSelectNone = () => {
    this.setState({ selectedTestCases: [] });
  };

  onClickSelectCategory = (category: string) => {
    const tests = this.state.tests || {};
    const testItems = tests.items || [];
    const selectedTestItems = _.filter(testItems, (x: ApiTest) => {
      return x.categories ? x.categories.includes(category) : false;
    });
    const testCases = selectedTestItems.map((x) => x.name || "");
    this.setState({ selectedTestCases: testCases });
  };

  onApiKeyIDChanged = (value: string) => {
    this.setState({ authKeyId: value });
    localStorage.setItem(apiKeyIDStorageKey, value);
  };

  onApiKeySecretChanged = (value: string) => {
    this.setState({ authKeySecret: value });
  };

  onProviderUpdated = (provider?: ApiProvider) => {
    this.setState((old) => {
      const oldProviderID = (old.selectedProvider || {}).id;
      const newProviderID = (provider || {}).id;
      return {
        selectedProvider: provider,
        selectedRegion: oldProviderID != newProviderID ? undefined : old.selectedRegion,
      };
    });
  };

  onRegionUpdated = (region?: ApiRegion) => {
    this.setState({ selectedRegion: region });
  };

  onVersionUpdated = (version?: ApiVersion) => {
    this.setState({ selectedVersion: version });
  };

  onCustomArangodbImageChanged = (newImage: string) => {
    this.setState({ customArangodbImage: newImage });
  };

  onCustomKubeArangodbImageChanged = (newImage: string) => {
    this.setState({ customKubeArangodbImage: newImage });
  };

  render() {
    const hasTests = !_.isEmpty(this.state.selectedTestCases);
    const hasApiKeyID = !!this.state.authKeyId;
    const hasApiKeySecret = !!this.state.authKeySecret;
    const canRun = hasTests && hasApiKeyID && hasApiKeySecret;
    const listView = <IntegrationTestsListView {...this.props} {...this.state} {...this} />;
    return (
      <div>
        <Processing active={this.state.processingRunTest} message="Starting test run, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Segment>
          <Form size="tiny">
            <Grid columns="16">
              <Grid.Column width="5">
                <LocationView
                  {...this.props}
                  {...this.state}
                  onProviderUpdated={this.onProviderUpdated}
                  onRegionUpdated={this.onRegionUpdated}
                  extraParams={this.state.extraParams}
                  setExtraParams={this.setExtraParams}
                />
              </Grid.Column>
              <Grid.Column width="4">
                <FilterView
                  {...this.props}
                  {...this.state}
                  onVersionUpdated={this.onVersionUpdated}
                  onCustomArangodbImageChanged={this.onCustomArangodbImageChanged}
                  onCustomKubeArangodbImageChanged={this.onCustomKubeArangodbImageChanged}
                />
              </Grid.Column>
              <Grid.Column width="5">
                <AuthenticationView
                  {...this.props}
                  {...this.state}
                  onApiKeyIDChanged={this.onApiKeyIDChanged}
                  onApiKeySecretChanged={this.onApiKeySecretChanged}
                />
              </Grid.Column>
              <Grid.Column width="2" textAlign="right">
                <Button icon="check" content="Run Tests" primary onClick={this.onStartTestRun} disabled={!canRun} />
              </Grid.Column>
            </Grid>
          </Form>
        </Segment>
        {listView}
      </div>
    );
  }
}

export default withRefresh()(IntegrationTestsRun);
