//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import { Organization } from "../../api/lib";

export type GlobalStoreProps = {
  organization: Organization;
  visibleTabElement: { [key: string]: boolean };
  featureFlags: string[];
  setOrganization: (organization: Organization) => void;
  setVisibleTabElement: (visibleTabElement: { [key: string]: boolean }) => void;
  setFeatureFlags: (featureFlags: string[]) => void;
};

export type GlobalStoreRequiredProps = Omit<GlobalStoreProps, "setOrganization" | "organization">;

export const DATE_TYPE_PREFERENCE = {
  DURATION: "duration",
  DATE: "date",
};

type DatePreference = typeof DATE_TYPE_PREFERENCE;
export type DateTypePreference = keyof DatePreference;

export type PersistentStoreRequiredProps = Pick<GlobalStoreProps, "setOrganization" | "organization"> & {
  dateTypePreference: DateTypePreference;
  setDateTypePreference: (dateTypePreference: DateTypePreference) => void;
};
