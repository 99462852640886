//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React, { Component } from "react";
import { Modal, Icon, Popup, Table, Grid } from "semantic-ui-react";
import {
  AuditLog as ApiAuditLog,
  AuditLog_Destination as ApiAuditLogDestination,
  AuditLog_DestinationStatus as ApiAuditLogDestinationStatus,
  AuditLog_DestinationCounters as ApiAuditLogDestinationCounters,
} from "../../api/lib";
import { Loading, Field, FieldContent as FC, FieldLabelWide as FL, TextLink } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { numberFormat } from "humanize";
import { humanizeFileSize } from "../../util/FileSize";
import { Routes } from "../../routes";

interface IDestinationCountersViewArgs {
  counters?: ApiAuditLogDestinationCounters;
  showHttpsPost: boolean;
}

const DestinationCountersView = ({ ...args }: IDestinationCountersViewArgs) => {
  const counters = args.counters || {};
  return (
    <Grid>
      <Grid.Column>
        <Field>
          <FL>Events</FL>
          <FC>{numberFormat(counters.events || 0, 0)}</FC>
        </Field>
        <Field>
          <FL>Events excluded</FL>
          <FC>{numberFormat(counters.events_excluded || 0, 0)}</FC>
        </Field>
        <Field>
          <FL>Events undeliverable</FL>
          <FC>{numberFormat(counters.events_undeliverable || 0, 0)}</FC>
        </Field>
        <Field>
          <FL>Bytes succeeded</FL>
          <FC>{counters.bytes_succeeded ? humanizeFileSize(counters.bytes_succeeded) : "0"}</FC>
        </Field>
        <Field>
          <FL>Bytes failed</FL>
          <FC>{counters.bytes_failed ? humanizeFileSize(counters.bytes_failed) : "0"}</FC>
        </Field>
        {args.showHttpsPost && (
          <Field>
            <FL>HTTPS posts succeeded</FL>
            <FC>{numberFormat(counters.https_posts_succeeded || 0, 0)}</FC>
          </Field>
        )}
        {args.showHttpsPost && (
          <Field>
            <FL>HTTPS posts failed</FL>
            <FC>{numberFormat(counters.https_posts_failed || 0, 0)}</FC>
          </Field>
        )}
      </Grid.Column>
    </Grid>
  );
};

// Arguments for AuditLog Destination Status header view
interface IAuditLogDestinationStatusHeaderView {}

const AuditLogDestinationStatusHeaderView = ({ ...args }: IAuditLogDestinationStatusHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Deployment ID</Table.HeaderCell>
        <Table.HeaderCell>Has Errors</Table.HeaderCell>
        <Table.HeaderCell>Error Details</Table.HeaderCell>
        <Table.HeaderCell>Updated At</Table.HeaderCell>
        <Table.HeaderCell>Events Since Midnight</Table.HeaderCell>
        <Table.HeaderCell>Events Yesterday</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

// Interface describing the AuditLog Destination Status list
interface IAuditLogDestinationStatusListView {
  items?: ApiAuditLogDestinationStatus[];
  deployment_id?: string;
  showHttpsPost: boolean;
  onDeploymentSelected: (id: string) => void;
}

const AuditLogDestinationStatusListView = ({ ...args }: IAuditLogDestinationStatusListView) => {
  let items = args.items || [];
  // filter on deployment ID (if provided only)
  if (args.deployment_id) {
    items = items.filter((item) => item.deployment_id == args.deployment_id);
  }
  return (
    <Table striped>
      <AuditLogDestinationStatusHeaderView {...args} />
      <Table.Body>
        {items.map((item, i) => (
          <AuditLogDestinationStatusRowView {...args} key={`key-dest-status-${i}`} item={item} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IAuditLogDestinationStatusRowView {
  item: ApiAuditLogDestinationStatus;
  showHttpsPost: boolean;
  onDeploymentSelected: (id: string) => void;
}

const AuditLogDestinationStatusRowView = ({ ...args }: IAuditLogDestinationStatusRowView) => {
  const status = args.item || {};
  let firing_color = "green";
  if (status.has_errors) {
    firing_color = "red";
  }
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>
        <TextLink label={status.deployment_id} href={Routes.dashboard_support_deployment_detailsWithId(status.deployment_id as string)} />
      </Table.Cell>
      <Table.Cell>
        <span style={{ color: firing_color }}>{status.has_errors ? "Yes" : "No"} </span>
      </Table.Cell>
      <Table.Cell>{status.error_details}</Table.Cell>
      <Table.Cell> {status.updated_at ? <DateTimePopupWithUTCAndLocalTime dateTime={status.updated_at} label="Updated at" /> : "-"}</Table.Cell>
      <Table.Cell>
        <DestinationCountersView {...args} counters={status.counters_since_midnight} />
      </Table.Cell>
      <Table.Cell>
        <DestinationCountersView {...args} counters={status.counters_yesterday} />
      </Table.Cell>
    </Table.Row>
  );
};

// Arguments for header view
interface IHeaderView {}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Excluded Topics</Table.HeaderCell>
        <Table.HeaderCell>Url</Table.HeaderCell>
        <Table.HeaderCell>Headers</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

interface ITypeViewArgs {
  typeId?: string;
}

const TypeView = ({ ...args }: ITypeViewArgs) => {
  switch (args.typeId) {
    case "cloud":
      return <Popup trigger={<Icon name="cloud" />} content="Cloud" />;
    case "https-post":
      return <Popup trigger={<Icon name="browser" />} content="Https-post" />;
    default:
      return <span>{args.typeId}</span>;
  }
};

interface IRowView {
  item: ApiAuditLogDestination;
  deployment_id?: string;
  onDeploymentSelected: (id: string) => void;
}

const RowView = ({ ...args }: IRowView) => {
  const destination = args.item || {};
  const headers = destination.http_post ? (destination.http_post.headers ? destination.http_post.headers : []) : [];
  const headersKeyValuePair = _.map(headers, (x) => {
    return `${x.key}=${x.value}`;
  });
  const statuses = destination.Statuses || [];
  let firing_color = "green";
  if (_.some(statuses, (s) => s.has_errors)) {
    firing_color = "red";
  }
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>
        <TypeView typeId={destination.type} />
      </Table.Cell>
      <Table.Cell>{destination.excluded_topics ? destination.excluded_topics.join(",") : "-"}</Table.Cell>
      <Table.Cell>{destination.http_post ? destination.http_post.url : "-"}</Table.Cell>
      <Table.Cell>{headersKeyValuePair ? headersKeyValuePair.join(",") : "-"}</Table.Cell>
      <Table.Cell>
        <Modal
          trigger={
            <span style={{ color: firing_color }}>
              <u>Details</u>
            </span>
          }
          centered
          size="fullscreen"
        >
          <Modal.Header>Status details {args.deployment_id && " (filtered)"}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <AuditLogDestinationStatusListView {...args} items={statuses} showHttpsPost={!!destination.http_post} />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the AuditLog Destination list
interface IListView {
  items?: ApiAuditLogDestination[];
  deployment_id?: string;
  onDeploymentSelected: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const items = args.items || [];
  return (
    <Table striped>
      <HeaderView {...args} />
      <Table.Body>
        {items.map((item, i) => (
          <RowView {...args} key={`key-dest-${i}`} item={item} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IEmptyViewArgs {}

const EmptyView = ({ ...args }: IEmptyViewArgs) => {
  return <div>No destinations</div>;
};

export interface IAuditLogDestinationListViewArgs extends IListView {
  auditLogDestinations?: ApiAuditLogDestination[];
  deployment_id?: string;
  onDeploymentSelected: (id: string) => void;
}

const AuditLogDestinationListViewContent = ({ ...args }: IAuditLogDestinationListViewArgs) => {
  const items = args.auditLogDestinations || [];
  if (_.isEmpty(items)) {
    return <EmptyView {...args} />;
  }
  return <ListView {...args} items={items} />;
};

export const AuditLogDestinationListView = ({ ...args }: IAuditLogDestinationListViewArgs) => {
  if (!args.auditLogDestinations) {
    return <Loading />;
  }
  return (
    <div>
      <AuditLogDestinationListViewContent {...args} />
    </div>
  );
};

interface IAuditLogDestinationListProps {
  auditLog: ApiAuditLog;
  deployment_id?: string;
  onDeploymentSelected: (id: string) => void;
}

interface IAuditLogDestinationListState {}

class AuditLogDestinationList extends Component<IAuditLogDestinationListProps, IAuditLogDestinationListState> {
  state: IAuditLogDestinationListState = {};

  render() {
    const auditLog = this.props.auditLog;
    const destinations = auditLog.destinations || [];
    return <AuditLogDestinationListView {...this.props} auditLogDestinations={destinations} />;
  }
}

export default AuditLogDestinationList;
