//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import { DataCluster as ApiDataCluster } from "../../api/lib";

export class ProvisionInfoStatusResult {
  public warning?: boolean;
  public negative?: boolean;
  public message?: string;
  public allGood?: boolean;
}

export class DataClusterHelper {
  static getProvisionInfoStatus(datacluster: ApiDataCluster): ProvisionInfoStatusResult {
    const result = new ProvisionInfoStatusResult();
    const latest = datacluster.latest_provision_info || {};
    const current = datacluster.target_provision_info || latest;
    const status = datacluster.status || {};
    if (current.provision_info_hash != status.provision_info_hash) {
      result.negative = true;
      result.message = "Updating...";
      return result;
    }
    if (!datacluster.target_provision_info) {
      result.warning = true;
      result.message = "Pass-through";
      return result;
    }
    if (datacluster.target_provision_info.provision_info_hash != latest.provision_info_hash) {
      result.warning = true;
      result.message = "Update available";
      return result;
    }

    result.message = "Up-to-date";
    result.allGood = true;
    return result;
  }
}
