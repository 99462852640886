//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import { Region as ApiRegion, Provider as ApiProvider } from "../../api/platform/v1/platform";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";

interface ILocationViewArgs extends ILocationState {
  showProvider: boolean;
  showRegion: boolean;
  multiLine: boolean;
}

const LocationView = ({ ...args }: ILocationViewArgs) => (
  <span>
    {args.showProvider ? args.provider ? args.provider.name : <Loader size="mini" active={true} inline /> : undefined}
    {args.showProvider && args.showRegion && !args.multiLine ? "-" : undefined}
    {args.showProvider && args.showRegion && args.multiLine && <br />}
    {args.showRegion ? args.region ? args.region.location : <Loader size="mini" active={true} inline /> : undefined}
  </span>
);

// Interface decribing the properties of the location component
interface ILocationProps extends IWithRefreshProps {
  regionId?: string;
  showProvider: boolean;
  showRegion: boolean;
  multiLine?: boolean;
}

// Interface decribing the state of the location component
interface ILocationState {
  region?: ApiRegion;
  provider?: ApiProvider;
}

class Location extends Component<ILocationProps, ILocationState> {
  state: ILocationState = {
    region: undefined,
    provider: undefined,
  };

  reloadProviderInfo = async () => {
    const region = this.state.region as ApiRegion | undefined;
    if (region) {
      const idOptions: ApiIDOptions = { id: region.provider_id };
      const provider = await apiClients.authenticationOnly.platformClient.GetProvider(idOptions);
      this.setState({ provider: provider });
    }
  };

  reloadRegionInfo = async () => {
    const idOptions: ApiIDOptions = { id: this.props.regionId };
    const region = await apiClients.authenticationOnly.platformClient.GetRegion(idOptions);
    this.setState({ region: region }, () => {
      this.props.refreshNow && this.props.refreshNow(this.reloadProviderInfo);
    });
  };

  componentDidMount() {
    if (this.props.regionId) {
      this.props.refreshNow && this.props.refreshNow(this.reloadRegionInfo);
    }
  }

  render() {
    if (!this.props.regionId) {
      return <div>Unknown</div>;
    }

    return <LocationView {...this.props} {...this.state} multiLine={!!this.props.multiLine} />;
  }
}

export default withRefresh()(Location);
