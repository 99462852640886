//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import apiclients from "../api/apiclients";
import { IDOptions as ApiIDOptions, Project as ApiProject } from "../api/lib";
import Cache from "./Cache";
import Callbacks from "./Callbacks";
import OrganizationCache, { OrganizationName, OrganizationTierName, OrganizationTierIcon } from "./OrganizationCache";
import { Routes } from "../routes";
import { TextLink } from "../ui/_textlink";

interface IProjectFieldProps {
  projectId?: string;
  cache: ProjectCache;
  onClick?: () => void;
  onClickOrganization?: (organizationId: string) => void;
}

interface IProjectFieldState {
  project?: ApiProject;
}

export class ProjectField<P extends IProjectFieldProps, S extends IProjectFieldState> extends Component<P, S> {
  state = {
    project: undefined,
  } as S;

  refreshProject = async () => {
    if (this.props.projectId) {
      try {
        const project = await this.props.cache.getProjectById(this.props.projectId as string);
        this.setState({ project: project });
      } catch (e) {
        this.setState({ project: undefined });
      }
    } else {
      this.setState({ project: undefined });
    }
  };

  componentDidMount() {
    this.props.cache.addCallback(this.refreshProject);
    this.refreshProject();
  }

  componentWillUnmount() {
    this.props.cache.removeCallback(this.refreshProject);
  }

  componentDidUpdate(prevProps: P) {
    if (prevProps.projectId != this.props.projectId) {
      this.refreshProject();
    }
  }

  getFieldValue = (project?: ApiProject): string | undefined => {
    return project ? project.id : undefined;
  };

  onClick = () => {
    this.props.onClick && this.props.onClick();
    const project = this.state.project;
    this.props.onClickOrganization && project && this.props.onClickOrganization(project.organization_id || "");
  };

  getClassName = (): string => {
    return this.props.onClick || this.props.onClickOrganization ? "text-link" : "";
  };

  render() {
    return (
      <TextLink className={this.getClassName()} onClick={this.onClick} href={Routes.dashboard_sales_project_detailsWithId(this.props.projectId || "")}>
        {this.getFieldValue(this.state.project)}
      </TextLink>
    );
  }
}

export class ProjectName extends ProjectField<IProjectFieldProps, IProjectFieldState> {
  getFieldValue = (project?: ApiProject): string | undefined => {
    return project ? project.name : undefined;
  };
}

interface IProjectOrganizationFieldProps extends IProjectFieldProps {
  organizationCache: OrganizationCache;
}

export class ProjectOrganizationName extends ProjectField<IProjectOrganizationFieldProps, IProjectFieldState> {
  render() {
    const project = this.state.project || {};
    const onClickOrganization = this.props.onClickOrganization;
    const onClick = !!onClickOrganization
      ? () => {
          onClickOrganization(project.organization_id || "");
        }
      : undefined;
    return <OrganizationName organizationId={project.organization_id} cache={this.props.organizationCache} onClick={onClick} />;
  }
}

export class ProjectOrganizationTierName extends ProjectField<IProjectOrganizationFieldProps, IProjectFieldState> {
  render() {
    const project = this.state.project || {};
    return <OrganizationTierName organizationId={project.organization_id} cache={this.props.organizationCache} onClick={this.props.onClick && this.onClick} />;
  }
}

export class ProjectOrganizationTierIcon extends ProjectField<IProjectOrganizationFieldProps, IProjectFieldState> {
  render() {
    const project = this.state.project || {};
    return <OrganizationTierIcon organizationId={project.organization_id} cache={this.props.organizationCache} onClick={this.props.onClick && this.onClick} />;
  }
}

export default class ProjectCache extends Callbacks {
  private cache: Cache<ApiProject>;

  constructor() {
    super();
    this.cache = new Cache<ApiProject>();
  }

  // getProjectById returns a project with given ID from cache or loads it first.
  getProjectById = async (id: string): Promise<ApiProject | undefined> => {
    const cached = this.cache.get(id);
    if (cached) {
      return cached;
    }
    if (this.cache.has(id)) {
      return undefined;
    }
    this.cache.set(id, undefined);
    const req: ApiIDOptions = {
      id: id,
    };
    const project = await apiclients.idashboardClient.GetProject(req);
    this.cache.set(id, project);
    this.invokeCallbacks();
    return project;
  };
}
