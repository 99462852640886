//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DropdownProps, Form } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import { Provider as ApiProvider, ProviderList as ApiProviderList, ListProvidersRequest as ApiListProvidersRequest } from "../../api/platform/v1/platform";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ISelectProviderViewArgs extends ISelectProviderState {
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

export const createProviderName = (p: ApiProvider) => {
  if (!!p.prerelease) {
    return `${p.name} (Preview)`;
  }
  return p.name;
};

const SelectProviderView = ({ ...args }: ISelectProviderViewArgs) => {
  const providers = args.providers || {};
  const items = providers.items || [];
  const loading = _.isEmpty(items);
  const options = loading
    ? [{ key: "", value: "", text: "" }]
    : items.map((i) => {
        return { key: i.id, text: createProviderName(i), value: i.name };
      });
  return (
    <Form.Dropdown
      compact
      fluid
      selection
      label="Provider"
      placeholder="Select Provider"
      name="provider"
      value={args.selectedProvider && args.selectedProvider.name}
      onChange={args.updateFormDropdownChange}
      loading={loading}
      options={options}
    />
  );
};

// Interface decribing the properties of the SelectProvider component
interface ISelectProviderProps extends IWithRefreshProps, RouteComponentProps {
  organizationId: string;
  provider?: ApiProvider;
  select_first?: boolean;
  onProviderUpdated: (provider?: ApiProvider) => void;
}

// Interface decribing the state of the SelectProvider component
interface ISelectProviderState {
  providers?: ApiProviderList;
  selectedProvider?: ApiProvider;
}

// Component to seelct a provider
class SelectProvider extends Component<ISelectProviderProps, ISelectProviderState> {
  state: ISelectProviderState = {
    providers: undefined,
    selectedProvider: this.props.provider,
  };

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "provider":
        const providers = this.state.providers;
        const selectedProvider = providers && providers.items && providers.items.find((p) => p.name == args.value);
        this.setState({ selectedProvider: selectedProvider });
        this.props.onProviderUpdated(selectedProvider);
    }
  };

  reloadProviders = async () => {
    const listReq: ApiListProvidersRequest = { organization_id: this.props.organizationId };
    const providers = await apiClients.authenticationOnly.platformClient.ListProviders(listReq);
    this.setState(
      (old) => {
        const update: ISelectProviderState = { providers: providers };
        if (!old.selectedProvider && !!this.props.select_first) {
          update.selectedProvider = _.first(providers.items || []);
        }
        return update;
      },
      () => {
        if (!!this.props.select_first) {
          this.props.onProviderUpdated(this.state.selectedProvider);
        }
      }
    );
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadProviders);
  }

  render() {
    return <SelectProviderView {...this.state} updateFormDropdownChange={this.updateFormDropdownChange} />;
  }
}

export default withRefresh()(SelectProvider);
