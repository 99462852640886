//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import React, { Component } from "react";
import "./semantic/semantic.css";
import "./App.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import api from "./api/api";
import Auth from "./auth/Auth";
import { Routes } from "./routes";
import { RouteComponentProps } from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

interface IAppProps extends RouteComponentProps {
  auth: Auth;
}

interface IAppState {}

class App extends Component<IAppProps, IAppState> {
  componentDidMount() {
    this.props.auth.renewSessionIfLoggedIn();
  }

  render() {
    const isAuthenticated = this.props.auth.isAuthenticated();

    if (isAuthenticated) {
      //set token in api, so all calls are authenticated
      api.token = this.props.auth.getAccessToken();
    } else {
      const { pathname, hash } = this.props.location;

      const startIndex = pathname.indexOf("/");
      if (startIndex != -1 && pathname.substr(startIndex).startsWith(Routes.dashboard)) {
        this.props.auth.setRedirectToRoute(pathname + hash);
      }

      //goto login-page if not already there
      if (pathname != Routes.home) {
        return <Redirect to={Routes.home} />;
      }
    }

    return <div />;
  }
}

export default withRouter(App);
