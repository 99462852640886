//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Input, InputOnChangeData, Loader, Table, Menu, Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { User as ApiUser } from "../../api/iam/v1/iam";
import { UserList as ApiUserList, ListUsersRequest as ApiListUsersRequest } from "../../api/iam/v1/iiam";
import { matchPath, RouteComponentProps, useLocation } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, ContentSegment, SecondaryMenu, PagingButtons, ErrorMessage, TextLink, FlexBox } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";
import history from "../../history";

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  onNameChanged: (name: string) => void;
  onEmailChanged: (email: string) => void;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>
        <Input
          label="Name"
          icon="search"
          size="mini"
          placeholder="Search ..."
          onChange={(event: React.SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) => {
            args.onNameChanged(data.value);
          }}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <Input
          label="Email"
          icon="search"
          size="mini"
          placeholder="Search ..."
          onChange={(event: React.SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) => {
            args.onEmailChanged(data.value);
          }}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Last login</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <PagingButtons size="tiny" {...args} />
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a user
interface IRowView {
  item: ApiUser;
  isSelected: boolean;
  onClickView: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  return (
    <Table.Row active={args.isSelected} className={args.isSelected ? "font-bold" : ""}>
      <Table.Cell>
        <TextLink label={args.item.name} href={Routes.dashboard_sales_user_detailsWithId(args.item.id as string)} />
      </Table.Cell>
      <Table.Cell>{args.item.email}</Table.Cell>
      <Table.Cell>{args.item.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" /> : "-"}</Table.Cell>
      <Table.Cell>{args.item.last_login_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.last_login_at} label="Last Login at" /> : "-"}</Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

// Interface describing the user list
interface IListView {
  items: ApiUser[];
  loading: boolean;
  onNameChanged: (name: string) => void;
  onEmailChanged: (email: string) => void;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickView: (userId: string) => void;
  selectedUserID: string;
}

const ListView = ({ ...args }: IListView) => {
  return (
    <Table striped>
      <HeaderView {...args} count={args.items.length} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView isSelected={args.selectedUserID === item.id} {...args} key={item.id} item={item} onClickView={() => args.onClickView(item.id || "")} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more users" : "No users selected"}</div>;

// Interface describing the user list view arguments
export interface IUserListViewArgs extends RouteComponentProps {
  loading: boolean;
  users?: ApiUserList;
  onNameChanged: (name: string) => void;
  onEmailChanged: (email: string) => void;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickView: (userId: string) => void;
  selectedUserID: string;
}

export const UserListView = ({ ...args }: IUserListViewArgs) => {
  if (!args.users) {
    return <Loading />;
  }
  const isEmpty = !args.users.items || args.users.items.length === 0;
  const users = args.users ? args.users.items : undefined;
  return (
    <div>
      <ListView {...args} selectedUserID={args.selectedUserID} items={users || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the user list component
interface IUserListProps extends IWithRefreshProps, RouteComponentProps {
  onUserSelected: (userID: string) => void;
}

// The component to show the projects inside a user as a list.

const UserList = (props: IUserListProps) => {
  const [users, setUsers] = useState<ApiUserList>();
  const [nameFilter, setNameFilter] = useState<string>("");
  const [emailFilter, setEmailFilter] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const pageSize = 10;
  const [error, setError] = useState();
  const location = useLocation();

  const reloadUsers = async () => {
    setError(undefined);
    const req: ApiListUsersRequest = {
      name: nameFilter,
      email: emailFilter,
      options: {
        page: page,
        page_size: pageSize,
      },
    };
    try {
      const users = await apiClients.idashboardClient.ListUsers(req);
      setUsers(users);
    } catch (err) {
      setError(err);
    }
  };

  const refreshUsers = () => {
    props.refreshNow && props.refreshNow(() => reloadUsers());
  };

  const onClickView = (id: string) => {
    props.onUserSelected(id);
  };

  const getSelectedUserId = (): string => {
    const matchObject = matchPath<{
      userId: string;
    }>(location.pathname, {
      path: Routes.dashboard_sales_user_details,
      exact: true,
      strict: true,
    });

    const { params: { userId = "" } = {} } = matchObject || {};

    return userId;
  };

  const isSelectedUserOnTheSamePage = () => {
    if (!getSelectedUserId()) return true;

    const list = (users || {}).items || [];
    return !!list.find((user) => user.id === getSelectedUserId());
  };

  useEffect(() => {
    refreshUsers();
  }, []);

  useEffect(() => {
    refreshUsers();
  }, [nameFilter, page, emailFilter]);

  const onNameFilterChanged = (name: string) => {
    setNameFilter(name);
    setPage(0);
  };

  const onEmailFilterChanged = (email: string) => {
    setEmailFilter(email);
    setPage(0);
  };

  const onNextPage = () => {
    setPage(page + 1);
  };

  const onPreviousPage = () => {
    setPage(page - 1);
  };

  return (
    <ContentSegment>
      <ErrorMessage active={!!props.error || !!error} onDismiss={props.clearError} message={props.error || error} />
      <SecondaryMenu>
        <Menu.Item header className="w-100">
          <FlexBox justify="space-between" width="100%">
            <FlexBox justify="flex-start">
              Users <Loader size="mini" active={props.loading} inline />
            </FlexBox>
            {!isSelectedUserOnTheSamePage() && (
              <span>
                <Icon
                  name="info circle"
                  color="orange"
                  onClick={() => {
                    history.push(Routes.dashboard_sales_users);
                  }}
                />
                Selected User is on a different page in this list.
              </span>
            )}
          </FlexBox>
        </Menu.Item>
      </SecondaryMenu>
      <UserListView
        {...props}
        users={users}
        page={page}
        pageSize={pageSize}
        onNameChanged={onNameFilterChanged}
        onEmailChanged={onEmailFilterChanged}
        onNextPage={onNextPage}
        onPreviousPage={page > 0 ? onPreviousPage : undefined}
        onClickView={onClickView}
        selectedUserID={getSelectedUserId()}
      />
    </ContentSegment>
  );
};

export default withRefresh()(UserList);
