//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useRef, useState } from "react";
import { isEqual, isEmpty } from "lodash";
import IntersectionObserverContainer from "../IntersectionObserver";
import TabMenuItemGroup from "./TabMenuItemGroup";
import { ITabMenuItemsViewArgs, ITabConfig, ElementStates } from "./TabTypes";

const TabMenuItemsWithIntersectionObserver = ({ tabConfig, tabClassForObserving, ...rest }: ITabMenuItemsViewArgs) => {
  const ref = useRef<HTMLDivElement>(null);

  const [updatedTabs, setUpdatedTabs] = useState<ITabConfig[]>(tabConfig);

  useEffect(() => {
    // Ensuring that the state only updates when there is actually a difference in the tabConfig and avoid loose comparison
    if (!isEqual(updatedTabs.map((tab) => tab.menuItem.id).sort(), tabConfig.map((tab) => tab.menuItem.id).sort())) {
      setUpdatedTabs(tabConfig);
    }
  }, [tabConfig]);

  const { id } = rest;

  const handleElementStateUpdates = (visibleElements: ElementStates) => {
    Object.keys(visibleElements).forEach((key) => {
      const isVisible = visibleElements[key];
      const el = document.querySelector(`[data-observerid="${key}"]`) as HTMLElement;
      if (el) {
        el.style.visibility = isVisible ? "visible" : "hidden";
      }

      const newTabConfig = tabConfig.map((tab) => {
        if (tab.menuItem.id === key) {
          tab.menuItem.isHidden = !isVisible;
        }
        return tab;
      });

      if (!isEqual(newTabConfig, updatedTabs) && !isEmpty(newTabConfig)) {
        setUpdatedTabs(newTabConfig);
      }
    });
  };

  return (
    <IntersectionObserverContainer
      id={id}
      options={{
        root: !!tabClassForObserving ? document.querySelector(`.${tabClassForObserving}`) : document.querySelector(".tab-menu-group"),
        rootMargin: "100px 0px", // To ensure that it doesn't change on scroll
        threshold: 1,
        targetObservingElements: (ref.current && ref.current.children) || [],
      }}
      onElementStateUpdated={handleElementStateUpdates}
    >
      <TabMenuItemGroup tabConfig={updatedTabs} tabClassForObserving={tabClassForObserving} {...rest} ref={ref} testId="intersectingMenuGroup" />
    </IntersectionObserverContainer>
  );
};

export default TabMenuItemsWithIntersectionObserver;
