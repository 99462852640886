//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import { Component } from "react";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import { Button, Container, Icon, Loader } from "semantic-ui-react";

interface IHomeViewArgs {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  onClickLogin: () => void;
}

const HomeView = ({ ...args }: IHomeViewArgs) => (
  <div className="container">
    <h1>ArangoGraph Insights Platform</h1>
    <h3>The Managed Cloud For ArangoDB</h3>
    {args.isAuthenticated && <h4>You are logged in!</h4>}
    {!args.isAuthenticated && (
      <h4>
        <div>You are not logged in!</div>
        <div>
          <Button icon positive onClick={args.onClickLogin}>
            <Icon name="unlock" />
            Log In
          </Button>
          {args.isAuthenticating && (
            <div>
              Trying to Auto-authenticate <Loader size="mini" active={true} inline />{" "}
            </div>
          )}
        </div>
      </h4>
    )}
  </div>
);

// Interface decribing the properties of the home component
interface IHomeProps extends RouteComponentProps {
  auth: Auth;
}

// Interface decribing the state of the home component
interface IHomeState {}

// This is the home component
class Home extends Component<IHomeProps, IHomeState> {
  login = () => {
    this.props.auth.login();
  };
  render() {
    return (
      <Container>
        <HomeView isAuthenticated={this.props.auth.isAuthenticated()} isAuthenticating={this.props.auth.isAuthenticating()} onClickLogin={this.login} />
      </Container>
    );
  }
}

export default Home;
