//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import moment from "moment";
import { Dropdown, Header, Icon } from "semantic-ui-react";
import { DataClusterAssignment as ApiDataClusterAssignment } from "../../api/lib";
import { Routes } from "../../routes";
import { Field, FieldContent as FC, FieldLabelWide as FL, TextLink } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { IWithRefreshProps } from "../../util/WithRefresh";

export interface IDataClusterViewArgs extends IWithRefreshProps {
  assignment?: ApiDataClusterAssignment;
  onDataClusterSelected: (id: string) => void;
  canUpdateProvisionHash: boolean;
  onClickUpdateProvisionHash: () => void;
  revision: number;
  revisionUpdatedAt?: Date;
  lastReportedRevision?: number;
  lastReportedRevisionUpdatedAt?: Date;
}

export const DataClusterView = ({ ...args }: IDataClusterViewArgs) => {
  const assignment = args.assignment || {};
  const has_assignment = !!args.assignment;
  const provision_hash_outdated = !!assignment.provision_hash_outdated;
  const last_reported_provision_hash_outdated = has_assignment && assignment.provision_hash != assignment.last_reported_provision_hash;
  const durationProvInfo =
    assignment.provision_hash_changed_at &&
    assignment.last_reported_provision_hash_changed_at &&
    assignment.provision_hash_changed_at <= assignment.last_reported_provision_hash_changed_at
      ? moment.duration(moment(assignment.provision_hash_changed_at).diff(assignment.last_reported_provision_hash_changed_at)).humanize()
      : "-";
  const last_reported_revision_outdated = args.revision != args.lastReportedRevision;
  const durationRevision =
    args.revisionUpdatedAt && args.lastReportedRevisionUpdatedAt && args.revisionUpdatedAt <= args.lastReportedRevisionUpdatedAt
      ? moment.duration(moment(args.revisionUpdatedAt).diff(args.lastReportedRevisionUpdatedAt)).humanize()
      : "-";
  return (
    <div>
      <Header sub>Data cluster</Header>
      <Field>
        <FL>ID</FL>
        <FC>
          {has_assignment && (
            <TextLink label={assignment.datacluster_id} href={Routes.dashboard_support_datacluster_detailsWithId(assignment.datacluster_id as string)} />
          )}
          {!has_assignment && <span>-</span>}
        </FC>
      </Field>
      <Field>
        <FL>Provision info</FL>
        <FC>
          {has_assignment && (
            <div>
              {assignment.provision_hash || "-"} {provision_hash_outdated && <Icon name="exclamation triangle" color="orange" />}
              {args.canUpdateProvisionHash && provision_hash_outdated && (
                <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
                  <Dropdown.Menu>
                    <Dropdown.Item key="update-provhash" text="Update provision hash" onClick={args.onClickUpdateProvisionHash} />
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
          {!has_assignment && <span>-</span>}
        </FC>
      </Field>
      <Field>
        <FL>Last reported provision info</FL>
        <FC>
          {assignment.last_reported_provision_hash || "-"} {last_reported_provision_hash_outdated && <Icon name="exclamation triangle" color="orange" />} <br />
          Updated:{" "}
          {assignment.last_reported_provision_hash_changed_at ? (
            <DateTimePopupWithUTCAndLocalTime dateTime={assignment.last_reported_provision_hash_changed_at} label="Updated on" />
          ) : (
            "-"
          )}
          <br />
          Duration: {durationProvInfo}
        </FC>
      </Field>
      <Field>
        <FL>Last reported deployment revision</FL>
        <FC>
          {args.lastReportedRevision} {last_reported_revision_outdated && <Icon name="sync" color="green" loading />} <br />
          Updated spec: {args.revisionUpdatedAt ? <DateTimePopupWithUTCAndLocalTime dateTime={args.revisionUpdatedAt} label="Updated spec on" /> : "-"}
          <br />
          Duration: {durationRevision}
        </FC>
      </Field>
    </div>
  );
};
