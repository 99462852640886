//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Icon, Label, Checkbox, CheckboxProps } from "semantic-ui-react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Routes } from "../routes";
import apiClients from "../api/apiclients";
import { User as ApiUser } from "../api/iam/v1/iam";
import { upperFirst } from "lodash";
import { usePersistentStorage } from "../util/storage/GobalStore";
import { DateTypePreference, DATE_TYPE_PREFERENCE } from "../util/storage/types";
import styled from "@emotion/styled";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
export interface IDashboardMenuArgs extends IWithRefreshProps, RouteComponentProps {
  user?: ApiUser;
  environment: string;
  firingAlertsDCError: number;
  firingAlertsDCCritical: number;
  firingAlertsDCWarning: number;
  firingAlertsDCInformational: number;
  firingAlertsCPError: number;
  firingAlertsCPCritical: number;
  firingAlertsCPWarning: number;
  firingAlertsCPInformational: number;
  dcUpdateRunning: boolean;
  dcUpdateNeedsAttention: boolean;
  onClickLogout: () => void;
  canViewDCAlerts: boolean;
  canViewCPAlerts: boolean;
  canViewDCUpdateAlerts: boolean;
}
const StyledMenuItemInfo = styled.div`
  font-size: 0.7rem;
  padding: 20px 0 0 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #879191;
`;

const StyledUpdateInfocon = styled(Icon)`
  position: absolute;
  top: -8px;
  right: -16px;
  font-size: 1rem !important;
`;

const DashboardMenu = ({ ...args }: IDashboardMenuArgs) => {
  const [version, setVersion] = useState("");
  const existingVersion = window.VERSION;
  const getVersions = async () => {
    try {
      const version = await apiClients.idashboardClient.GetVersion({});
      const { major = 0, minor = 0, patch = 0 } = version;
      const currentVersion = `v${major}.${minor}.${patch}`;
      setVersion(currentVersion);
    } catch (err) {
      console.warn("Failed to get the version.");
    }
  };

  useEffect(() => {
    const { refreshWithTimer } = args;
    refreshWithTimer && refreshWithTimer(getVersions, 7200);
  }, []);

  const cp_alert_color = args.firingAlertsCPCritical > 0 ? "red" : args.firingAlertsCPError > 0 ? "purple" : args.firingAlertsCPWarning > 0 ? "orange" : "blue";
  const cp_total = args.firingAlertsCPCritical + args.firingAlertsCPError + args.firingAlertsCPWarning + args.firingAlertsCPInformational;
  const dc_alert_color = args.firingAlertsDCCritical > 0 ? "red" : args.firingAlertsDCError > 0 ? "purple" : args.firingAlertsDCWarning > 0 ? "orange" : "blue";
  const dc_total = args.firingAlertsDCCritical + args.firingAlertsDCError + args.firingAlertsDCWarning + args.firingAlertsDCInformational;
  const dc_update_color = args.dcUpdateNeedsAttention ? "red" : "green";
  const envColors: any = {
    development: "grey",
    staging: "teal",
    production: "blue",
  };

  const [dateTypePreference, setDatePreference] = usePersistentStorage((state) => [state.dateTypePreference, state.setDateTypePreference]);
  return (
    <Menu fixed="top" inverted>
      <Menu.Item as={Link} to={Routes.dashboard}>
        <Icon name="home" />
      </Menu.Item>
      <Menu.Item header color={envColors[args.environment]} active>
        {upperFirst(args.environment)}
      </Menu.Item>

      {args.canViewDCAlerts && dc_total > 0 ? (
        <Menu.Item position="right" as={Link} to={Routes.dashboard_support_dataclusters_firing}>
          <Label color={dc_alert_color} circular>
            <Icon name="exclamation triangle" /> {args.firingAlertsDCCritical} / {args.firingAlertsDCError} / {args.firingAlertsDCWarning} /{" "}
            {args.firingAlertsDCInformational}
          </Label>
        </Menu.Item>
      ) : (
        <Menu.Item position="right" />
      )}

      {args.canViewCPAlerts && cp_total > 0 && (
        <Menu.Item as={Link} to={Routes.dashboard_support_datacluster_detailsWithId(`_cp`)}>
          <Label color={cp_alert_color} circular>
            <Icon name="bullhorn" /> {args.firingAlertsCPCritical} / {args.firingAlertsCPError} / {args.firingAlertsCPWarning} /{" "}
            {args.firingAlertsCPInformational}
          </Label>
        </Menu.Item>
      )}

      {args.canViewDCUpdateAlerts && args.dcUpdateRunning && (
        <Menu.Item as={Link} to={Routes.dashboard_dcupdate}>
          <Label color={dc_update_color} circular>
            <Icon name="sync" loading /> DCUpdate
          </Label>
        </Menu.Item>
      )}

      <Dropdown
        item
        icon={
          <span className="relative">
            <Icon name="settings" />
            {version != "" && version !== existingVersion && <StyledUpdateInfocon name="info circle" color="orange" size="small" />}
          </span>
        }
        simple
        direction="left"
      >
        <Dropdown.Menu open>
          <Dropdown.Header>Preferences</Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item>
            <Checkbox
              toggle
              label="Date in formatted date and time string"
              checked={dateTypePreference === DATE_TYPE_PREFERENCE.DATE}
              onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                if (data.checked) {
                  setDatePreference(DATE_TYPE_PREFERENCE.DATE as DateTypePreference);
                } else {
                  setDatePreference(DATE_TYPE_PREFERENCE.DURATION as DateTypePreference);
                }
              }}
            />
            <StyledMenuItemInfo>
              <Icon name="info circle" size="mini" />
              Default date format will be {dateTypePreference === DATE_TYPE_PREFERENCE.DURATION ? "as duration string" : "in date time string"}
            </StyledMenuItemInfo>
          </Dropdown.Item>
          {version != "" && <Dropdown.Divider />}
          {version != "" && version !== existingVersion && (
            <Dropdown.Item onClick={() => window.location.reload()}>
              <Icon name="refresh" />
              <label className="cursor-pointer">Refresh to get the new update</label>
              <StyledMenuItemInfo>
                <Icon name="info circle" size="small" color="orange" /> Current verion -{" "}
                <Label circular basic color="yellow" size="tiny">
                  {existingVersion}
                </Label>
                {"   "}
                New version -
                <Label circular basic color="green" size="tiny">
                  {version}
                </Label>
              </StyledMenuItemInfo>
            </Dropdown.Item>
          )}
          {version === existingVersion && (
            <Dropdown.Item className="no-pointer">
              <StyledMenuItemInfo>
                You are on the latest version -{" "}
                <Label basic circular color="green" size="tiny">
                  {version}
                </Label>
              </StyledMenuItemInfo>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item icon="user" simple direction="left">
        <Dropdown.Menu>
          <Dropdown.Header>Signed in as</Dropdown.Header>
          <Dropdown.Item disabled> {args.user ? args.user.name : "?"}</Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Header>My Account</Dropdown.Header>
          <Dropdown.Item onClick={args.onClickLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};

export default withRefresh()(DashboardMenu);
