//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Form } from "semantic-ui-react";
import moment, { Moment } from "moment";

interface DateTimeInputProps {
  name: string;
  value?: Moment | Date | string | null;
  onChange?: (val: Date) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

export const DateTimeInput = ({ ...args }: DateTimeInputProps) => {
  const initial = moment.utc(args.value);

  const initialDate = initial.isValid() ? initial.format("YYYY-MM-DD") : "";
  const initialTime = initial.isValid() ? initial.format("HH:mm:ss") : "";

  const [date, setDate] = React.useState(initialDate);
  const [time, setTime] = React.useState(initialTime);

  React.useEffect(() => {
    const newVal = moment.utc(date + " " + time, "YYYY-MM-DD HH:mm:ss");
    if (newVal.isValid()) {
      args.onChange && args.onChange(newVal.toDate());
    }
  }, [date, time]);

  return (
    <Form.Field required={args.required || false}>
      <label>{args.label}</label>
      <div className="ui input">
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required={args.required || false} disabled={args.disabled} />
        <input type="time" value={time} onChange={(e) => setTime(e.target.value)} required={args.required || false} disabled={args.disabled} />
      </div>
    </Form.Field>
  );
};

interface DateInputProps extends DateTimeInputProps {
  endOfDay?: boolean;
}

export const DateInput = ({ ...args }: DateInputProps) => {
  const initial = moment.utc(args.value);

  const initialDate = initial.isValid() ? initial.format("YYYY-MM-DD") : "";

  const [date, setDate] = React.useState(initialDate);

  React.useEffect(() => {
    let newVal = moment.utc(date, "YYYY-MM-DD");
    if (newVal.isValid()) {
      if (!!args.endOfDay) {
        newVal = newVal.endOf("day");
      }
      args.onChange && args.onChange(newVal.toDate());
    }
  }, [date]);

  return (
    <Form.Field required={args.required || false}>
      <label>{args.label}</label>
      <div className="ui input">
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required={args.required || false} disabled={args.disabled} />
      </div>
    </Form.Field>
  );
};
