//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import { Dropdown, Header, Popup } from "semantic-ui-react";
import { Deployment_Expiration as ApiDeployment_Expiration } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IExpirationViewArgs {
  expiration: ApiDeployment_Expiration;
  onExpirationAddWeeks: (weeks: number) => void;
  onExpirationOptOut: () => void;
  canOptOutExpiration: boolean;
}

export const ExpirationView = ({ ...args }: IExpirationViewArgs) => (
  <div>
    <Header sub>Expiration</Header>
    <Field>
      <FL>Expires</FL>
      <FC>
        <div>
          {args.expiration.expires_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.expiration.expires_at} label="Expires on" /> : "Does not expire"}
          {args.expiration.expires_at && (
            <Dropdown icon="pencil" inline className="icon tiny edit-expires-at">
              <Dropdown.Menu>
                <Dropdown.Header content="Change expiration date" />
                <Dropdown.Item key="1week" text="Add 1 week" onClick={() => args.onExpirationAddWeeks(1)} />
                <Dropdown.Item key="2weeks" text="Add 2 weeks" onClick={() => args.onExpirationAddWeeks(2)} />
                <Dropdown.Item key="4weeks" text="Add 4 weeks" onClick={() => args.onExpirationAddWeeks(4)} />
                <Dropdown.Item key="min1week" text="Remove 1 week" onClick={() => args.onExpirationAddWeeks(-1)} />
                {args.canOptOutExpiration && <Dropdown.Item key="optOut" text="No expiration (opt-out)" onClick={() => args.onExpirationOptOut()} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </FC>
    </Field>
    <Field>
      <FL>Reason</FL>
      <FC>{args.expiration.reason || "-"}</FC>
    </Field>
    {args.expiration.expires_at ? (
      <Field>
        <FL>Last warning mail send at</FL>
        {args.expiration.last_warning_email_send_at ? (
          <DateTimePopupWithUTCAndLocalTime dateTime={args.expiration.last_warning_email_send_at} label="Last warning mail sent at" />
        ) : (
          "-"
        )}
      </Field>
    ) : undefined}
    {args.expiration.expires_at ? (
      <Field>
        <FL>Last warning mail send to</FL>
        <FC>
          {args.expiration.last_warning_email_send_to ? (
            <span>
              <Popup
                trigger={
                  <span className="cursor-pointer">
                    {args.expiration.last_warning_email_send_to[0]}
                    <b>{args.expiration.last_warning_email_send_to.length > 1 && ` + ${args.expiration.last_warning_email_send_to.length - 1} more`}</b>
                  </span>
                }
                position="right center"
                content={args.expiration.last_warning_email_send_to
                  .slice(0)
                  .filter((email) => !!email)
                  .join(",\n")}
              />
            </span>
          ) : (
            "-"
          )}
        </FC>
      </Field>
    ) : undefined}
  </div>
);
