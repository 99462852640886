//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import _, { isEmpty } from "lodash";
import humanize from "humanize";
import styled from "@emotion/styled";
import React, { Component, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid, Header, Icon, Loader, Menu, Message, Segment, Table, Form, InputOnChangeData, Modal } from "semantic-ui-react";
import {
  Currency as ApiCurrency,
  IDOptions as ApiIDOptions,
  Invoice as ApiInvoice,
  Organization as ApiOrganization,
  Invoice_Item as ApiInvoice_Item,
} from "../../api/lib";
import {
  CompleteInvoiceRequest as ApiCompleteInvoiceRequest,
  InvoiceInfo as ApiInvoiceInfo,
  SetInvoiceNonCollectibleRequest as ApiSetInvoiceNoneCollectibleRequest,
  CancelInvoiceRequest as ApiCancelInvoiceRequest,
} from "../../api/billing/v1/ibilling";
import {
  ContentSegment,
  ErrorMessage,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  FieldSet,
  LoaderBox,
  Loading,
  MenuActionBack,
  Processing,
  SecondaryMenu,
  TextLink,
  FormActionButtonCancel,
  FormActionButtonCreate,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import Auth from "../../auth/Auth";
import { DownloadInvoice } from "./DownloadInvoice";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

const AmountSpan = styled("span")`
  min-width: 10em;
  text-align: right;
  display: inline-block;
`;

interface IGeneralViewArgs extends IWithRefreshProps, RouteComponentProps {
  invoiceInfo: ApiInvoiceInfo;
  organization: ApiOrganization;
  currency: ApiCurrency;
  updateAllowed: boolean;
  canUpdate: boolean;
  updating: boolean;
  editMode: boolean;
  needCalculation: boolean;
  needSave: boolean;
  onOrganizationSelected: () => void;
  updateDescription: (ii: ApiInvoice_Item, newValue: string) => void;
  updateValue: (ii: ApiInvoice_Item, newValue: number) => void;
  addItem: () => void;
  removeItem: (ii: ApiInvoice_Item, idx: number) => void;
  edit: () => void;
  cancelEdit: () => void;
  calculate: () => void;
  save: () => void;
  onDownload: () => void;
}

const GeneralView = ({ ...args }: IGeneralViewArgs) => {
  const { invoice } = args.invoiceInfo;
  if (!invoice) {
    return null;
  }
  const items = invoice.items || [];
  const has_items = !_.isEmpty(items);
  const purchase_order_based = !!invoice.purchase_order_based;
  const curSign = args.currency.sign;
  const roundedTotal = _.sumBy(items, (item) => Math.round((item.amount || 0) * 100) / 100);

  const formatAmount = (amount: number) => (
    <span>
      {humanize.numberFormat(amount)}&nbsp;{curSign}
    </span>
  );
  const formatPercentage = (amount: number) => <span>{humanize.numberFormat(100 * amount)}%</span>;
  return (
    <FieldSet>
      <Header sub>General</Header>
      <Field>
        <FL>Number</FL>
        <FC>{invoice.invoice_number || "-"}</FC>
      </Field>
      <Field>
        <FL>Type</FL>
        <FC>{purchase_order_based ? "Purchase order" : "Regular"}</FC>
      </Field>
      <Field>
        <FL>Organization</FL>
        <FC>
          <TextLink href={Routes.dashboard_sales_organization_detailsWithId(args.organization.id as string)}>{args.organization.name || "-"}</TextLink>
        </FC>
      </Field>
      <Field>
        <FL>Tier</FL>
        <FC>{args.invoiceInfo.organization_tier_id || "-"}</FC>
      </Field>
      <Field>
        <FL>Created</FL>
        <FC>{invoice.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={invoice.created_at} label="Created at" /> : "-"}</FC>
      </Field>
      <Field>
        <FL>Usage items from</FL>
        <FC>
          {args.invoiceInfo.usage_items_from ? <DateTimePopupWithUTCAndLocalTime dateTime={args.invoiceInfo.usage_items_from} label="Usage items from" /> : "-"}
        </FC>
      </Field>
      <Field>
        <FL>Usage items to</FL>
        <FC>
          {args.invoiceInfo.usage_items_to ? <DateTimePopupWithUTCAndLocalTime dateTime={args.invoiceInfo.usage_items_to} label="Usage items to" /> : "-"}
        </FC>
      </Field>
      <Field>
        <FL>Last updated</FL>
        <FC>{invoice.last_updated_at ? <DateTimePopupWithUTCAndLocalTime dateTime={invoice.last_updated_at} label="Last updated at" /> : "-"}</FC>
      </Field>
      <Field>
        <FL>Invoice builder</FL>
        <FC>{invoice.invoice_builder_version || "-"}</FC>
      </Field>
      <Field>
        <FL>
          Items ({items.length}) in currency: {curSign}{" "}
        </FL>
        <FC>
          {args.editMode && (
            <Form.Button
              icon="add"
              name="add"
              onClick={() => {
                args.addItem();
              }}
              size="mini"
            />
          )}
        </FC>
      </Field>
      <Field>
        <FL>Total excl taxes</FL>
        <FC>
          <AmountSpan>
            {formatAmount(invoice.total_amount_excl_taxes || 0)} (roundedSum = {formatAmount(roundedTotal)})
          </AmountSpan>
        </FC>
      </Field>
      <Field>
        <FL>VAT ({formatPercentage(invoice.vat_percentage_used || 0)})</FL>
        <FC>
          <AmountSpan>{formatAmount(invoice.total_vat || 0)}</AmountSpan>
        </FC>
      </Field>
      <Field>
        <FL>Sales tax ({formatPercentage(invoice.sales_tax_percentage_used || 0)})</FL>
        <FC>
          <AmountSpan>{formatAmount(invoice.total_sales_tax || 0)}</AmountSpan>
        </FC>
      </Field>
      <Field>
        <FL>Total incl taxes</FL>
        <FC>
          <AmountSpan>{formatAmount(invoice.total_amount_incl_taxes || 0)}</AmountSpan>
        </FC>
      </Field>
      <Field>
        <FL>Actions</FL>
        <FC>
          <Button.Group>
            {args.updateAllowed && !args.editMode && args.canUpdate && <Form.Button icon="edit" name="edit" onClick={() => args.edit()} size="mini" inline />}
            {args.updateAllowed && args.editMode && <Form.Button icon="undo" name="cancel" onClick={() => args.cancelEdit()} size="mini" inline />}
            {args.updateAllowed && args.editMode && args.needCalculation && (
              <Form.Button icon="calculator" name="calculate" onClick={() => args.calculate()} size="mini" inline />
            )}
            {args.updateAllowed && args.editMode && args.needSave && <Form.Button icon="save" name="save" onClick={() => args.save()} size="mini" inline />}
            <Form.Button icon="download" name="download" onClick={args.onDownload} size="mini" inline />
          </Button.Group>
        </FC>
      </Field>
      <Segment>
        {has_items && (
          <Table>
            {items.map((invoiceItem, idx) => {
              const fontColor = invoiceItem.is_prepaid ? "gray" : "black";
              const amount = invoiceItem.is_prepaid ? "PREPAID" : formatAmount(invoiceItem.amount || 0);
              return (
                <Table.Row key={`item${idx}`} columns={16} className={isEmpty(invoiceItem) ? "padding-20-tb" : ""}>
                  <Table.Cell width={13}>
                    {!args.editMode && <div style={{ color: fontColor }}>{invoiceItem.description}</div>}
                    {args.editMode && (
                      <Form.Input
                        fluid
                        autoFocus
                        className={isEmpty(invoiceItem) ? "editable-invoice-item" : ""}
                        name={`description${idx}`}
                        value={invoiceItem.description}
                        onChange={(event: any, id: InputOnChangeData) => {
                          args.updateDescription(invoiceItem, id.value);
                        }}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {!args.editMode && <div style={{ color: fontColor }}>{amount}</div>}
                    {args.editMode && (
                      <Form.Input
                        fluid
                        className={isEmpty(invoiceItem) ? "editable-invoice-item" : ""}
                        name={`amount${idx}`}
                        value={invoiceItem.amount}
                        onChange={(event: any, id: InputOnChangeData) => {
                          args.updateValue(invoiceItem, _.toNumber(id.value));
                        }}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {args.editMode && (
                      <Form.Button
                        className={isEmpty(invoiceItem) ? "editable-invoice-item" : ""}
                        icon="remove"
                        name={`remove${idx}`}
                        onClick={() => {
                          args.removeItem(invoiceItem, idx);
                        }}
                        size="mini"
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table>
        )}
        {!has_items && <div>Invoice has no items</div>}
      </Segment>
    </FieldSet>
  );
};

interface IVerificationViewArgs {
  invoiceInfo: ApiInvoiceInfo;
  verifying: boolean;
  canVerify: boolean;
  rebuilding: boolean;
  canRebuild: boolean;
  canComplete: boolean;
  canCancel: boolean;
  onCancel: (reason: string) => void;
  canSetNonCollectible: boolean;
  updateAllowed: boolean;
  onVerify: () => void;
  onRebuild: () => void;
  onComplete: () => void;
  onToggleNonCollectible: () => void;
}

const VerificationView = ({ ...args }: IVerificationViewArgs) => {
  const { invoice } = args.invoiceInfo;

  const { is_canceled, canceled_at, cancelation_reason } = args.invoiceInfo;

  const [openCancel, setOpenCancel] = useState(false);
  const requires_manual_verification = !!invoice?.requires_manual_verification;
  const status = invoice?.status || {};
  const rebuild_info = !!status.is_pending || !!status.needs_rebuild;
  const needs_verification = requires_manual_verification && !status.is_verified;
  const can_complete = !needs_verification && !!status.is_pending && !status.is_completed && !status.is_rejected;
  const canSetNonCollectible = args.canSetNonCollectible && (!status.is_completed || status.is_rejected);
  const canCancel = args.canCancel && status.is_pending && !args.invoiceInfo.is_canceled;
  const onCancel = (reason: string) => {
    args.onCancel(reason);
    setOpenCancel(false);
  };

  if (!invoice) {
    return null;
  }

  if (is_canceled) {
    return (
      <FieldSet color="red">
        <Header sub>Invoice Canceled</Header>
        <Field>
          <FL>Canceled at</FL>
          <FC>
            <DateTimePopupWithUTCAndLocalTime dateTime={canceled_at} label="Canceled at" />
          </FC>
        </Field>
        <Field>
          <FL>Reason</FL>
          <FC>{cancelation_reason}</FC>
        </Field>
      </FieldSet>
    );
  }
  return (
    <FieldSet>
      <Header sub>Verification</Header>
      {!requires_manual_verification && <div>Not needed</div>}
      {requires_manual_verification && (
        <Field>
          <FL>Verification</FL>
          <FC>
            {status.is_verified ? "Verified" : "Not verified"}
            &nbsp;
            {!status.is_verified && (
              <div>
                <Button icon="check" onClick={args.onVerify} disabled={!args.canVerify || args.verifying} content="Verify" size="small" />
              </div>
            )}
          </FC>
        </Field>
      )}
      {args.updateAllowed && rebuild_info && (
        <Field>
          <FL>Rebuild Invoice</FL>
          <FC>
            {status.needs_rebuild ? (
              "Rebuilding in progress..."
            ) : (
              <Button icon="redo" onClick={args.onRebuild} disabled={!args.canRebuild || args.rebuilding} content="Rebuild" size="small" />
            )}
          </FC>
        </Field>
      )}
      {can_complete && (
        <Field>
          <FL>Complete Invoice</FL>
          <FC>
            <Button icon="check" onClick={args.onComplete} disabled={!args.canComplete} content="Complete" size="small" />
          </FC>
        </Field>
      )}
      {canCancel && (
        <Field>
          <FL>Cancel Invoice</FL>
          <FC>
            <Button icon="close" onClick={() => setOpenCancel(true)} content="Cancel" size="small" color="red" />
            <CancelationModal open={openCancel} onCancel={onCancel} onClose={() => setOpenCancel(false)} />
          </FC>
        </Field>
      )}
      {canSetNonCollectible && (
        <Field>
          <FL>{args.invoiceInfo.non_collectible ? "Non collectible" : "Collectible"}</FL>
          <FC>
            <Button
              icon="check"
              onClick={args.onToggleNonCollectible}
              disabled={!args.canSetNonCollectible}
              content={args.invoiceInfo.non_collectible ? "Set collectible" : "Set non collectible"}
              size="small"
            />
          </FC>
        </Field>
      )}
    </FieldSet>
  );
};

interface IStatusViewArgs {
  invoice: ApiInvoice;
  canUpdateStatus: boolean;
  updateToPendingAllowed: boolean;
  onSetToPending: () => void;
}

const StatusView = ({ ...args }: IStatusViewArgs) => {
  const status = args.invoice.status || {};
  const payments = args.invoice.payments || [];
  const has_payments = !_.isEmpty(payments);
  const setToPendingDisabled = !args.canUpdateStatus || !args.updateToPendingAllowed;
  return (
    <FieldSet>
      <Header sub>Status</Header>
      <Field>
        <FL>Status</FL>
        <FC>
          {status.is_pending ? (
            "pending"
          ) : status.is_completed ? (
            <p>completed: ({status.completion_reason || "?"})</p>
          ) : status.is_rejected ? (
            <div>
              <p>rejected: ({status.rejection_reason || "?"})</p>
              <Button icon="redo" onClick={args.onSetToPending} disabled={setToPendingDisabled} content="Set To Pending" size="small" />
            </div>
          ) : (
            "unknown"
          )}
        </FC>
      </Field>
      <Segment>
        {has_payments && (
          <Table>
            <Table.Body>
              {payments.map((x, i) => (
                <Table.Row key={`payment${i}`}>
                  <Table.Cell>{x.payment_id}</Table.Cell>
                  <Table.Cell>{x.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={x.created_at} label="Created at" /> : "-"}</Table.Cell>
                  <Table.Cell>
                    {x.is_pending ? "pending" : x.is_completed ? "completed" : x.is_rejected ? <p>rejected: ({x.rejection_reason || "?"})</p> : "unknown"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {!has_payments && <div>Invoice has no payments</div>}
      </Segment>
    </FieldSet>
  );
};

interface ICompletionModalViewArgs {
  showCompletionModal: boolean;
  onCloseCompletionModal: () => void;
  completionReason: string;
  onCompletionReasonChanged: (reason: string) => void;
  onCompleteWithReason: (reason: string) => void;
}

const CompletionModalView = ({ ...args }: ICompletionModalViewArgs) => (
  <Modal open={args.showCompletionModal} onClose={args.onCloseCompletionModal}>
    <Modal.Header>Mark invoice completed</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>
          <strong>This should only be done when payment of invoice has been confirmed!</strong>
        </p>
        <p>Explain why you complete the invoice manually.</p>
        <Form>
          <Form.Input autoFocus label="Reason" value={args.completionReason} width="16" onChange={(e, d) => args.onCompletionReasonChanged(d.value)} />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button icon="cancel" content="Cancel" labelPosition="right" onClick={args.onCloseCompletionModal} />
      <Button
        icon="exclamation"
        content="Complete"
        labelPosition="right"
        onClick={() => args.onCompleteWithReason(args.completionReason)}
        color="orange"
        disabled={!args.completionReason}
      />
    </Modal.Actions>
  </Modal>
);

interface ICancelationModalArgs {
  open: boolean;
  onCancel: (reason: string) => void;
  onClose: () => void;
}

const CancelationModal = ({ ...args }: ICancelationModalArgs) => {
  const [reason, setReason] = useState("");
  return (
    <Modal open={args.open}>
      <Modal.Header>Are you sure you want to mark this invoice as canceled?</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message warning>
            <Icon name="warning circle" />
            <strong>This will mark invoice as canceled. Canceled invoice will not be visible to customer</strong>
          </Message>
          <Form>
            <Form.Input
              autoFocus
              label="Explain why you cancel the invoice manually"
              required
              value={reason}
              width="16"
              onChange={(e, d) => setReason(d.value)}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel icon="cancel" title="No, go back!" onClick={args.onClose} />
        <FormActionButtonCreate primary icon="exclamation triangle" title="Confirm cancellation" onClick={() => args.onCancel(reason)} disabled={!reason} />
      </Modal.Actions>
    </Modal>
  );
};

interface IInvoiceDetailsViewArgs extends IWithRefreshProps, RouteComponentProps, ICompletionModalViewArgs {
  invoiceInfo: ApiInvoiceInfo;
  organization: ApiOrganization;
  currency: ApiCurrency;
  errorMessage?: string;
  verifying: boolean;
  canVerify: boolean;
  rebuilding: boolean;
  canRebuild: boolean;
  canComplete: boolean;
  updateAllowed: boolean;
  canUpdate: boolean;
  updating: boolean;
  calculating: boolean;
  editMode: boolean;
  needCalculation: boolean;
  needSave: boolean;
  canUpdateStatus: boolean;
  canSetNonCollectible: boolean;
  canCancel: boolean;
  updateToPendingAllowed: boolean;
  updatingStatus: boolean;
  handleDismissError: () => void;
  onOrganizationSelected: () => void;
  onVerify: () => void;
  onCancel: (reason: string) => void;
  onRebuild: () => void;
  onComplete: () => void;
  updateDescription: (ii: ApiInvoice_Item, newValue: string) => void;
  updateValue: (ii: ApiInvoice_Item, newValue: number) => void;
  addItem: () => void;
  removeItem: (ii: ApiInvoice_Item, idx: number) => void;
  edit: () => void;
  cancelEdit: () => void;
  calculate: () => void;
  save: () => void;
  onDownload: () => void;
  onSetToPending: () => void;
  onToggleNonCollectible: () => void;
}

const InvoiceDetailsView = ({ ...args }: IInvoiceDetailsViewArgs) => {
  const { is_canceled } = args.invoiceInfo;
  return (
    <ContentSegment>
      <SecondaryMenu>
        <MenuActionBack />
        <Menu.Item header>Invoice Details</Menu.Item>
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
        <Processing active={args.verifying} message="Marking invoice verified..." />
        <Processing active={args.calculating} message="Calculating invoice..." />
        <Processing active={args.updating} message="Updating invoice..." />
        <Processing active={args.updatingStatus} message="Updating invoice status..." />
      </SecondaryMenu>
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <CompletionModalView {...args} />
      <Grid columns={16} doubling>
        <Grid.Column width={10}>
          <GeneralView {...args} />
        </Grid.Column>
        <Grid.Column width={6}>
          <VerificationView {...args} />
          {!is_canceled && <StatusView {...args} invoice={args.invoiceInfo.invoice || {}} />}
        </Grid.Column>
      </Grid>
    </ContentSegment>
  );
};

// Interface decribing the properties of the deployment details component
interface IInvoiceDetailsProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  api: IAPI;
  onOrganizationSelected: (id: string) => void;
}

interface IAPI {
  GetInvoice(req: ApiIDOptions): Promise<ApiInvoice>;
  GetInvoiceInfo(req: ApiIDOptions): Promise<ApiInvoiceInfo>;
  GetOrganization(req: ApiIDOptions): Promise<ApiOrganization>;
  GetCurrency(req: ApiIDOptions): Promise<ApiCurrency>;
  VerifyInvoice(req: ApiIDOptions): Promise<void>;
  RebuildInvoice(req: ApiIDOptions): Promise<void>;
  CompleteInvoice(req: ApiCompleteInvoiceRequest): Promise<void>;
  CalculateInvoice(req: ApiInvoice): Promise<ApiInvoice>;
  UpdateInvoice(req: ApiInvoice): Promise<ApiInvoice>;
  UpdateInvoiceStatus(req: ApiInvoice): Promise<ApiInvoice>;
  SetInvoiceNonCollectible(req: ApiSetInvoiceNoneCollectibleRequest): Promise<void>;
  CancelInvoice(req: ApiCancelInvoiceRequest): Promise<void>;
}

// Interface decribing the state of the invoice details component
interface IInvoiceDetailsState {
  invoiceId?: string;
  invoiceInfo?: ApiInvoiceInfo;
  invoice?: ApiInvoice;
  currency?: ApiCurrency;
  organization?: ApiOrganization;
  errorMessage?: string;
  verifying: boolean;
  rebuilding: boolean;
  updating: boolean;
  updatingStatus: boolean;
  calculating: boolean;
  editMode: boolean;
  needCalculation: boolean;
  needSave: boolean;
  showCompletionModal: boolean;
  completionReason: string;
  completing: boolean;
  downloading: boolean;
}

class InvoiceDetails extends Component<IInvoiceDetailsProps, IInvoiceDetailsState> {
  state: IInvoiceDetailsState = {
    invoiceId: undefined,
    invoiceInfo: undefined,
    invoice: undefined,
    currency: undefined,
    organization: undefined,
    errorMessage: undefined,
    verifying: false,
    rebuilding: false,
    updating: false,
    updatingStatus: false,
    calculating: false,
    editMode: false,
    needCalculation: false,
    needSave: false,
    showCompletionModal: false,
    completionReason: "",
    completing: false,
    downloading: false,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  refreshInvoiceInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadInvoiceInfo);
  };

  reloadInvoiceInfo = async () => {
    if (this.state.editMode) {
      return;
    }

    const invReq: ApiIDOptions = { id: this.state.invoiceId };
    this.setState({ errorMessage: undefined });
    try {
      const invoiceInfo = await this.props.api.GetInvoiceInfo(invReq);
      const { invoice } = invoiceInfo;
      if (!invoice) {
        return;
      }
      if (!this.state.invoiceInfo) {
        this.props.subscribeUrl && this.props.subscribeUrl(this.reloadInvoiceInfo, invoice.url);
      }
      this.setState({ invoiceInfo: invoiceInfo, invoice: invoice, needCalculation: false, needSave: false });
      const orgReq: ApiIDOptions = { id: invoice.organization_id };
      const organization = await this.props.api.GetOrganization(orgReq);
      this.setState({ organization: organization });
      const curReq: ApiIDOptions = { id: invoice.currency_id || "" };
      const currency = await this.props.api.GetCurrency(curReq);
      this.setState({ currency: currency });
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  componentDidMount() {
    const invoiceId = (this.props.match.params as any).invoiceId;
    this.setState(
      {
        invoiceId: invoiceId,
      },
      () => {
        // notice inside the reloadInvoiceInfo (called by refreshInvoiceInfo) we use the invoiceId
        this.refreshInvoiceInfo();
      }
    );
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onVerify = async () => {
    if (this.state.invoiceId) {
      try {
        this.setState({ verifying: true, errorMessage: undefined });
        const req: ApiIDOptions = { id: this.state.invoiceId };
        await this.props.api.VerifyInvoice(req);
        this.refreshInvoiceInfo();
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ verifying: false });
      }
    }
  };

  onCancel = async (reason: string) => {
    if (this.state.invoiceId) {
      try {
        this.setState({ verifying: true, errorMessage: undefined });
        const req: ApiCancelInvoiceRequest = { invoice_id: this.state.invoiceId, reason: reason };
        await this.props.api.CancelInvoice(req);
        this.refreshInvoiceInfo();
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ verifying: false });
      }
    }
  };

  onRebuild = async () => {
    if (this.state.invoiceId) {
      try {
        this.setState({ rebuilding: true, errorMessage: undefined });
        const req: ApiIDOptions = { id: this.state.invoiceId };
        await this.props.api.RebuildInvoice(req);
        this.refreshInvoiceInfo();
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ rebuilding: false });
      }
    }
  };

  onComplete = () => {
    if (this.state.invoiceId) {
      this.setState({ showCompletionModal: true });
    }
  };

  onCloseCompletionModal = () => {
    this.setState({ showCompletionModal: false });
  };

  onCompletionReasonChanged = (reason: string) => {
    this.setState({ completionReason: reason });
  };

  onCompleteWithReason = async (reason: string) => {
    if (this.state.invoiceId) {
      try {
        this.setState({ completing: true, errorMessage: undefined });
        const req: ApiCompleteInvoiceRequest = {
          invoice_id: this.state.invoiceId,
          reason: reason,
        };
        await this.props.api.CompleteInvoice(req);
        this.refreshInvoiceInfo();
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({
          completing: false,
          completionReason: "",
          showCompletionModal: false,
        });
      }
    }
  };

  updateDescription = (ii: ApiInvoice_Item, newValue: string) => {
    ii.description = newValue;
    this.setState({ invoiceInfo: this.state.invoiceInfo });
  };

  updateValue = (ii: ApiInvoice_Item, newValue: number) => {
    ii.amount = newValue;
    this.setState({ invoiceInfo: this.state.invoiceInfo, needCalculation: true, needSave: false });
  };

  addItem = () => {
    const invoice = this.state.invoiceInfo ? this.state.invoiceInfo.invoice : {};
    if (invoice != null) {
      const newItem: ApiInvoice_Item = {};
      if (invoice.items == null) {
        invoice.items = {} as ApiInvoice_Item[];
      }
      invoice.items.push(newItem);
      this.setState({ invoice: this.state.invoice });
    }
  };

  removeItem = (ii: ApiInvoice_Item, idx: number) => {
    const invoice = this.state.invoice;
    if (invoice != null && invoice.items != null) {
      _.pullAt(invoice.items, idx);
      this.setState({ invoice: this.state.invoice, needCalculation: true, needSave: false });
    }
  };

  edit = () => {
    this.setState({ editMode: true });
  };

  cancelEdit = () => {
    this.setState({ editMode: false }, this.refreshInvoiceInfo);
  };

  calculate = async () => {
    if (this.state.invoice) {
      try {
        this.setState({ calculating: true, errorMessage: undefined });
        const invoice = await this.props.api.CalculateInvoice(this.state.invoice);
        this.setState({ invoice: invoice, needCalculation: false, needSave: true });
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ calculating: false });
      }
    }
  };

  save = async () => {
    if (this.state.invoice) {
      try {
        this.setState({ updating: true, errorMessage: undefined, editMode: false });
        const invoice = await this.props.api.UpdateInvoice(this.state.invoice);
        this.setState({ invoice: invoice, needSave: false });
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ updating: false });
      }
    }
  };

  onSetToPending = async () => {
    let { invoice } = this.state.invoiceInfo || {};
    if (invoice) {
      try {
        this.setState({ updatingStatus: true, errorMessage: undefined });
        const status = invoice.status;
        if (status) {
          status.is_rejected = false;
          status.rejected_at = undefined;
          status.is_pending = true;
        }
        invoice = await this.props.api.UpdateInvoiceStatus(invoice);
        this.setState({ invoice: invoice });
      } catch (e) {
        this.setState({ errorMessage: e });
      } finally {
        this.setState({ updatingStatus: false });
      }
    }
  };

  onToggleNonCollectible = async () => {
    if (!this.state.invoiceInfo || !this.state.invoice) {
      return;
    }
    try {
      this.setState({ updatingStatus: true, errorMessage: undefined });
      await this.props.api.SetInvoiceNonCollectible({ invoice_id: this.state.invoice.id, non_collectible: !this.state.invoiceInfo.non_collectible });
      this.refreshInvoiceInfo();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ updatingStatus: false });
    }
  };

  onDownloadInvoice = async (invoiceID: string) => {
    try {
      this.setState({ downloading: true, errorMessage: undefined });
      DownloadInvoice(invoiceID, this.props.auth);
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ downloading: false });
    }
  };

  render() {
    const invoiceInfo = this.state.invoiceInfo;
    if (!invoiceInfo) {
      return <Loading />;
    }
    const { invoice } = invoiceInfo;
    const organization = this.state.organization || {};
    const currency = this.state.currency || {};
    const has_invoice_verify = this.hasPermission("internal-dashboard.invoice.verify");
    const has_invoice_rebuild = this.hasPermission("internal-dashboard.invoice.rebuild");
    const has_invoice_complete = this.hasPermission("internal-dashboard.invoice.complete");
    const has_invoice_update = this.hasPermission("internal-dashboard.invoice.update");
    const has_invoice_update_status = this.hasPermission("internal-dashboard.invoice.update-status");
    const has_invoice_calculate = this.hasPermission("internal-dashboard.invoice.calculate");
    const canSetNonCollectible = this.hasPermission("internal-dashboard.invoice.set-non-collectible");
    const canCancelInvoice = this.hasPermission("internal-dashboard.invoice.cancel");

    if (invoice) {
      const status = invoice.status || {};
      const updateAllowed = !status.is_verified && !status.is_completed && !status.is_rejected && _.isEmpty(invoice.payments);
      const verified = !invoice.requires_manual_verification || status.is_verified;
      const updateToPendingAllowed = (verified && !status.is_completed && !status.is_pending) || false;
      return (
        <InvoiceDetailsView
          {...this.props}
          {...this.state}
          invoiceInfo={invoiceInfo}
          organization={organization}
          currency={currency}
          canVerify={has_invoice_verify}
          canRebuild={has_invoice_rebuild}
          canComplete={has_invoice_complete}
          canSetNonCollectible={canSetNonCollectible}
          updateAllowed={updateAllowed}
          canUpdate={has_invoice_update && has_invoice_calculate}
          canUpdateStatus={has_invoice_update_status}
          updateToPendingAllowed={updateToPendingAllowed}
          onOrganizationSelected={() => this.props.onOrganizationSelected(invoice.organization_id || "")}
          onVerify={this.onVerify}
          onRebuild={this.onRebuild}
          onComplete={this.onComplete}
          onCloseCompletionModal={this.onCloseCompletionModal}
          onCompletionReasonChanged={this.onCompletionReasonChanged}
          onCompleteWithReason={this.onCompleteWithReason}
          updateDescription={this.updateDescription}
          updateValue={this.updateValue}
          addItem={this.addItem}
          removeItem={this.removeItem}
          edit={this.edit}
          cancelEdit={this.cancelEdit}
          calculate={this.calculate}
          save={this.save}
          canCancel={canCancelInvoice}
          onCancel={this.onCancel}
          onSetToPending={this.onSetToPending}
          onDownload={() => this.onDownloadInvoice(invoice.id || "")}
          handleDismissError={this.handleDismissError}
          onToggleNonCollectible={this.onToggleNonCollectible}
        />
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(InvoiceDetails);
