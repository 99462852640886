//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { RouteComponentProps } from "react-router-dom";
import { Header, Icon, Label, Table } from "semantic-ui-react";
import {
  Deployment_ServerStatus as ApiDeployment_ServerStatus,
  DeploymentSize as ApiDeploymentSize,
  NodeSizeDetails as ApiNodeSizeDetails,
  ServerNodeAssignment as ApiServerNodeAssignment,
  DeploymentInfo,
} from "../../api/lib";
import Auth from "../../auth/Auth";
import { FieldSet, ListActionRotate, IconWithPopup } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import AgencyStateModal from "../monitoring/AgencyStateModal";
import AgencyDumpModal from "../monitoring/AgencyDumpModal";
import ClusterAnalyseModal from "../monitoring/ClusterAnalyseModal";
import ClusterHealthModal from "../monitoring/ClusterHealthModal";
import LogsModal from "../monitoring/LogsModal";
import SetLogLevelModal from "../monitoring/SetLogLevelModal";
import DebugClusterModal from "../monitoring/DebugClusterModal";
import RebalanceShardsModal from "../monitoring/RebalanceShardsModal";
import SilentOutOfSyncModal from "../monitoring/SilentOutOfSync";
import { DataVolumeView } from "./DataVolumeView";
import { CPUUsageView } from "./CpuUsageView";
import { MemoryUsageView } from "./MemoryUsageView";
import { ZoneStatusView } from "./ZoneStatusView";
import _ from "lodash";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

export interface IServersStatusViewArgs extends IWithRefreshProps, RouteComponentProps {
  is_paused: boolean;
  deployment: DeploymentInfo;
  reloadDeploymentInfo: () => void;
  depl_size?: ApiDeploymentSize;
  servers?: ApiDeployment_ServerStatus[];
  nodeSizeDetails?: ApiNodeSizeDetails;
  serverNodeAssignments?: ApiServerNodeAssignment[];
  deploymentId?: string;
  auth: Auth;
  can_rotate_server: boolean;
  can_rotate_server_with_force: boolean;
  onClickRotateServer: (serverID: string) => void;
  onClickRotateServerWithForce: (serverID: string) => void;
  isRotatingServer: (serverID: string) => boolean;
}

export const ServersStatusView = ({ ...args }: IServersStatusViewArgs) => {
  const hasDeploymentID = !!args.deploymentId;
  const size = args.depl_size || {};
  const agents = size.agents || 0;
  const num_servers = _.size(args.servers);
  const isCluster = agents > 0 && num_servers > 0;
  return (
    <FieldSet>
      <Header sub>Database Servers</Header>
      <ServersStatusTableView {...args} />
      {hasDeploymentID && <LogsModal {...args} deploymentId={args.deploymentId || ""} servers={args.servers || []} />}
      {hasDeploymentID && (
        <SetLogLevelModal
          {...args}
          deploymentId={args.deploymentId || ""}
          deployment={args.deployment || {}}
          reloadDeploymentInfo={args.reloadDeploymentInfo}
        />
      )}
      {hasDeploymentID && isCluster && <ClusterHealthModal {...args} deploymentId={args.deploymentId || ""} />}
      {hasDeploymentID && isCluster && <ClusterAnalyseModal {...args} deploymentId={args.deploymentId || ""} />}
      {hasDeploymentID && isCluster && <AgencyStateModal {...args} deploymentId={args.deploymentId || ""} />}
      {hasDeploymentID && isCluster && <AgencyDumpModal {...args} deploymentId={args.deploymentId || ""} />}
      {hasDeploymentID && <DebugClusterModal {...args} deploymentId={args.deploymentId || ""} />}
      {hasDeploymentID && isCluster && <RebalanceShardsModal {...args} deploymentId={args.deploymentId || ""} />}
      {hasDeploymentID && isCluster && <SilentOutOfSyncModal {...args} deploymentId={args.deploymentId || ""} />}
    </FieldSet>
  );
};

const ServersStatusTableView = ({ ...args }: IServersStatusViewArgs) => {
  const list = args.servers || [];
  if (list.length == 0) {
    return <span>None</span>;
  }
  const sortedList = _.orderBy(list, (x) => x.id);
  const getServerNodeAssignment = (id: string) => _.find(args.serverNodeAssignments, (item) => item.id === id);

  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>Type</Table.Cell>
          <Table.Cell>Version</Table.Cell>
          <Table.Cell>State</Table.Cell>
          <Table.Cell>Can be deleted</Table.Cell>
          <Table.Cell>Memory</Table.Cell>
          <Table.Cell>CPU</Table.Cell>
          <Table.Cell>Disk Used</Table.Cell>
          <Table.Cell>Last Restarted</Table.Cell>
          <Table.Cell>Created</Table.Cell>
          <Table.Cell>Zone</Table.Cell>
          <Table.Cell>Actions</Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedList.map((x) => (
          <ServerStatusView
            {...args}
            key={x.id}
            server_status={x}
            isRotatingServer={args.isRotatingServer(x.id || "")}
            onClickRotateServer={() => args.onClickRotateServer(x.id || "")}
            onClickRotateServerWithForce={() => args.onClickRotateServerWithForce(x.id || "")}
            serverNodeAssignment={getServerNodeAssignment(x.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IServerStatusViewArgs extends IWithRefreshProps, RouteComponentProps {
  is_paused: boolean;
  server_status?: ApiDeployment_ServerStatus;
  nodeSizeDetails?: ApiNodeSizeDetails;
  serverNodeAssignment?: ApiServerNodeAssignment;
  onClickRotateServer: () => void;
  onClickRotateServerWithForce: () => void;
  can_rotate_server: boolean;
  can_rotate_server_with_force: boolean;
  isRotatingServer: boolean;
}

const ServerStatusView = ({ ...args }: IServerStatusViewArgs) => {
  const isPaused = args.is_paused;
  const status = args.server_status || {};
  const isRotationPending = !!status.rotation_pending;
  const isLeader = !!status.is_leader;
  const zone = (args.serverNodeAssignment && args.serverNodeAssignment.node_zone) || "";
  const nodeName = (args.serverNodeAssignment && args.serverNodeAssignment.node_name) || "";
  const pvName = (args.serverNodeAssignment && args.serverNodeAssignment.persistent_volume_name) || "";
  const isTopologyAware = !!(args.serverNodeAssignment && args.serverNodeAssignment.is_topology_aware);

  return (
    <Table.Row>
      <Table.Cell>{status.id}</Table.Cell>
      <Table.Cell>
        {status.type}
        <span> </span>
        {isLeader && <IconWithPopup name="star" content="Is leader" color="olive" />}
      </Table.Cell>
      <Table.Cell>{status.version}</Table.Cell>
      <Table.Cell>
        {!isPaused && status.ok && (
          <span>
            <Icon name="check" className="primary-text" /> OK
          </span>
        )}
        {!isPaused && status.upgrading && (
          <span>
            <Icon name="sync alternate" className="secondary-text" /> Upgrading
          </span>
        )}
        {!isPaused && status.creating && (
          <span>
            <Icon name="spinner" className="secondary-text" /> Creating
          </span>
        )}
        {!isPaused && status.failed && (
          <span>
            <Icon name="exclamation triangle" className="orange-text" /> Failed
          </span>
        )}
        {!isPaused && status.bad && (
          <span>
            <Icon name="exclamation triangle" className="orange-text" /> Bad
          </span>
        )}
        {isPaused && (
          <span>
            <Icon name="pause" className="primary-text" /> Paused
          </span>
        )}
        {isRotationPending && (
          <span>
            &nbsp;<Label size="small">Rotation pending</Label>
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <span>
          <Icon name={status.can_be_deleted ? "check" : "cancel"} />
        </span>
      </Table.Cell>
      <Table.Cell>
        <MemoryUsageView used={status.last_memory_usage || 0} limit={status.last_memory_limit || 0} />
      </Table.Cell>
      <Table.Cell>
        <CPUUsageView used={status.last_cpu_usage || 0} limit={status.last_cpu_limit || 0} />
      </Table.Cell>
      <Table.Cell>
        <DataVolumeView data_volume_info={status.data_volume_info} />
      </Table.Cell>
      <Table.Cell>
        <div>
          {status.last_started_at ? <DateTimePopupWithUTCAndLocalTime dateTime={status.last_started_at} label="Restarted on" /> : "-"} (
          {status.recent_restarts || 0})
        </div>
      </Table.Cell>
      <Table.Cell>{status.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={status.created_at} label="Created on" /> : "-"}</Table.Cell>

      <Table.Cell>
        {isTopologyAware && <IconWithPopup name="check" color="green" content="Topology aware" />}
        <ZoneStatusView nodeName={nodeName} pvName={pvName} zone={zone}></ZoneStatusView>
      </Table.Cell>

      <Table.Cell>
        <div className="table-action-buttons">
          <ListActionRotate
            disabled={!args.can_rotate_server}
            onClick={args.onClickRotateServer}
            icon={args.isRotatingServer ? "check" : undefined}
            size="small"
            tooltip="Rotate this server gracefully"
          />

          {args.can_rotate_server_with_force && (
            <ListActionRotate
              onClick={args.onClickRotateServerWithForce}
              icon={args.isRotatingServer ? "check" : undefined}
              color="red"
              size="small"
              tooltip="Rotate this server, if needed with force"
            />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};
