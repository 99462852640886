//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Icon, Statistic, Popup } from "semantic-ui-react";
import { Deployment as ApiDeployment } from "../../api/lib";
import { StatisticsContentGroup, Section, SectionContent, SectionHeader } from "../../ui/lib";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface BackupRestoreViewArgs {
  deployment: ApiDeployment;
}

export const BackupRestoreTableView = ({ ...args }: BackupRestoreViewArgs) => {
  const lastRestore = args.deployment.backup_restore && args.deployment.backup_restore.last_updated_at;
  const restoreRevision = (args.deployment.backup_restore && args.deployment.backup_restore.revision) || 0;
  const restoring =
    args.deployment.status && args.deployment.status.backup_restore_status && args.deployment.status.backup_restore_status.status
      ? args.deployment.status.backup_restore_status.restoring
      : restoreRevision > 0;
  let lastStatus = args.deployment.status && args.deployment.status.backup_restore_status && args.deployment.status.backup_restore_status.status;
  const failureReason = args.deployment.status && args.deployment.status.backup_restore_status && args.deployment.status.backup_restore_status.failure_reason;

  if (restoring && !lastStatus) {
    lastStatus = "Pending";
  }

  if (restoreRevision == 0) {
    return (
      <Section>
        <SectionHeader title="Restores" />
        <SectionContent>
          <div>No backup restores for this deployment</div>
        </SectionContent>
      </Section>
    );
  }
  return (
    <Section>
      <SectionHeader title="Restores" />
      <SectionContent>
        <StatisticsContentGroup>
          <Statistic.Group widths="4">
            <Statistic size="tiny">
              <Statistic.Label>
                <Icon name="hashtag" className="secondary-text" /> Restore count
              </Statistic.Label>
              <Statistic.Value>{restoreRevision}</Statistic.Value>
            </Statistic>
            <Statistic size="tiny">
              <Statistic.Label>
                <Icon name="calendar check" className="primary-text" /> Last restore
              </Statistic.Label>
              <Statistic.Value>{lastRestore ? <DateTimePopupWithUTCAndLocalTime dateTime={lastRestore} label="Last restored at" /> : "-"}</Statistic.Value>
            </Statistic>
            <Statistic size="tiny">
              <Statistic.Label>
                <Icon name="undo" className="secondary-text" /> Restoring
              </Statistic.Label>
              <Statistic.Value>{restoring ? "yes" : "no"}</Statistic.Value>
            </Statistic>
            <Statistic size="tiny">
              <Statistic.Label>
                <Icon name="circle" className="secondary-text" /> Last status
              </Statistic.Label>
              <Statistic.Value>
                {failureReason ? <Popup trigger={<span>{lastStatus || "-"}</span>} content={<div>{failureReason}</div>} /> : lastStatus || "-"}
              </Statistic.Value>
            </Statistic>
          </Statistic.Group>
        </StatisticsContentGroup>
      </SectionContent>
    </Section>
  );
};
