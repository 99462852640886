//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import { Form, Input, InputOnChangeData, Modal } from "semantic-ui-react";
import { UpdateJob as ApiUpdateJob } from "../../api/deployment-updater/v1/ideploymentupdater";
import { ErrorMessage, FormActionButtonCancel, FormActionButtonCreate } from "../../ui/lib";
import { UpdateJobDetails } from "./types";
import apiClients from "../../api/apiclients";

const UpdateModalView = ({ jobDetails = {}, onClose }: UpdateJobDetails) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [updateJobData, setUpdateJobDetails] = useState<ApiUpdateJob>(jobDetails);

  const updateJobDetails = async () => {
    setLoading(true);
    setError(undefined);
    try {
      await apiClients.idashboardClient.UpdateDeploymentUpdateJob(updateJobData);
      onClose();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal open>
      <Modal.Header>Edit job detailts</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} />
          <Form>
            <Form.Field>
              <label>Max concurrency</label>
              <Input
                type="number"
                value={(updateJobData.req || {}).max_concurrency}
                min={0}
                disabled={loading}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  setUpdateJobDetails({
                    ...updateJobData,
                    req: {
                      ...updateJobData.req,
                      max_concurrency: parseInt(value),
                    },
                  });
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel icon="close" title="Cancel" onClick={onClose} disabled={loading} />
        <FormActionButtonCreate primary icon="plus" title="Update Job" onClick={updateJobDetails} disabled={loading} />
      </Modal.Actions>
    </Modal>
  );
};

export default UpdateModalView;
