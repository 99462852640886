//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Message, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { DebugClusterRequest as ApiDebugClusterRequest } from "../../api/lib";
import { ContentActionButton, ErrorMessage, Processing } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface IDebugClusterModalViewArgs {
  deploymentId: string;
  open: boolean;
  processing: boolean;
  onClose: () => void;
  errorMessage?: string;
  jobId?: string;
  podName?: string;

  onDebugCluster: () => void;
}

const DebugClusterModalView = ({ ...args }: IDebugClusterModalViewArgs) => {
  const has_podName = !!args.podName;
  const cmd = `kubectl exec -it -n depl-${args.deploymentId} ${args.podName} -- /bin/sh`;
  const [copied, setCopied] = React.useState(false);
  const onCopied = () => {
    setCopied(true);
    setInterval(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Modal open={args.open} onClose={args.onClose}>
      <Modal.Header>
        <h1>Debug cluster</h1>
      </Modal.Header>
      <Modal.Content>
        <Processing active={args.processing} message="Launching debug container..." />
        <ErrorMessage message={args.errorMessage} active={!!args.errorMessage} />
        {!has_podName && (
          <div>
            <p>
              Click <strong>Launch</strong> to start a debug container in the namespace of the deployment.
            </p>
            <p>This container will sleep for 6 hours.</p>
          </div>
        )}
        {has_podName && (
          <div>
            <p>The debug container has been launched. To use it, run:</p>
            <Message>
              <pre>
                <CopyToClipboard text={cmd} onCopy={onCopied}>
                  <Button icon={copied ? "check" : "copy"} floated="right" size="tiny" basic />
                </CopyToClipboard>
                {cmd}
              </pre>
            </Message>
            <p>Note that the container will terminate after 6 hours.</p>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={args.onDebugCluster} content="Launch" disabled={args.processing || has_podName} />
        <Button onClick={args.onClose} content={has_podName ? "Close" : "Cancel"} />
      </Modal.Actions>
    </Modal>
  );
};

interface IDebugClusterModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
}

interface IDebugClusterModalState {
  open: boolean;
  processing: boolean;
  errorMessage?: string;
  jobId?: string;
  podName?: string;
}

// Component to launch a debug container
class DebugClusterModal extends Component<IDebugClusterModalProps, IDebugClusterModalState> {
  state: IDebugClusterModalState = {
    open: false,
    processing: false,
    errorMessage: undefined,
    jobId: undefined,
    podName: undefined,
  };

  onDebugCluster = async () => {
    this.setState({ processing: true, errorMessage: undefined });
    try {
      const req: ApiDebugClusterRequest = {
        deployment_id: this.props.deploymentId,
      };
      const info = await apiClients.idashboardClient.DebugCluster(req);
      this.setState({ processing: false, jobId: info.job_id, podName: info.pod_name });
    } catch (e) {
      this.setState({ processing: false, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true, podName: undefined, jobId: undefined });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Debug cluster" icon="bug" />
        <DebugClusterModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(DebugClusterModal);
