//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  ListOptions as ApiListOptions,
  NodeSize as ApiNodeSize,
  NodeSizeList as ApiNodeSizeList,
  Organization as ApiOrganization,
  OrganizationIAMProviderLimits as ApiOrganizationIAMProviderLimits,
  SetOrganizationAllowedIAMProvidersRequest as ApiSetOrganizationAllowedIAMProvidersRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IIAMProvidersFieldsViewArgs {
  organization: ApiOrganization;
  iamproviderLimits: ApiOrganizationIAMProviderLimits;
  canSetAllowIAMProviders: boolean;
  canSetOrganizationIAMProviderLimits: boolean;
  nodeSizes?: ApiNodeSize[];
  onSetOrganizationAllowedIAMProviders: (enabled: boolean) => void;
  onSetOrganizationIAMProviderLimits: (minNodeSizeID: string) => void;
}

const IAMProvidersFieldsView = ({ ...args }: IIAMProvidersFieldsViewArgs) => {
  const is_iamproviders_allowed = !!args.organization.is_allowed_to_use_iamproviders;
  const hasNodeSizes = !!args.nodeSizes;
  const nodeSizes = args.nodeSizes || [];
  return (
    <div>
      <Field>
        <FL>Using IAM providers</FL>
        <FC>
          {is_iamproviders_allowed ? "Allowed" : "Not allowed"}
          {args.canSetAllowIAMProviders && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!is_iamproviders_allowed && <Dropdown.Item text="Allow" onClick={() => args.onSetOrganizationAllowedIAMProviders(true)} />}
                {is_iamproviders_allowed && <Dropdown.Item text="Do not allow" onClick={() => args.onSetOrganizationAllowedIAMProviders(false)} />}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      {is_iamproviders_allowed && (
        <Field>
          <FL>IAM provider minimum node size</FL>
          <FC>
            {args.iamproviderLimits.minimum_node_size_id || "-"}
            {args.canSetOrganizationIAMProviderLimits && hasNodeSizes && (
              <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
                <Dropdown.Menu
                  content={_.map(nodeSizes, (x: ApiNodeSize) => (
                    <Dropdown.Item text={`${x.name} (${x.cpu_size})`} key={`ns${x.id}`} onClick={() => args.onSetOrganizationIAMProviderLimits(x.id || "")} />
                  )).concat(<Dropdown.Item text="Default limits" onClick={() => args.onSetOrganizationIAMProviderLimits("")} />)}
                />
              </Dropdown>
            )}
          </FC>
        </Field>
      )}
    </div>
  );
};

interface IIAMProvidersFieldsProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface IIAMProvidersFieldsState extends IOrganizationDetailsFieldState {
  iamproviderLimits?: ApiOrganizationIAMProviderLimits;
  nodeSizes?: ApiNodeSizeList;
  commentPrompt: ICommentPromptStateForIntegrations;
}

class IAMProvidersFields extends OrganizationDetailsField<IIAMProvidersFieldsProps, IIAMProvidersFieldsState> {
  state: IIAMProvidersFieldsState = {
    iamproviderLimits: undefined,
    nodeSizes: undefined,
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      if (this.hasPermission("internal-dashboard.organization.get-iamprovider-limits")) {
        const iamproviderLimits = await apiClients.idashboardClient.GetOrganizationIAMProviderLimits(idOptions);
        this.setState({ iamproviderLimits: iamproviderLimits });
      }
    }
  };

  reloadNodeSizes = async () => {
    const listOptions: ApiListOptions = {};
    const nodeSizes = await apiClients.idashboardClient.ListAllNodeSizes(listOptions);
    this.setState({ nodeSizes: nodeSizes });
  };

  refreshNodeSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadNodeSizes);
  };

  componentDidMount() {
    super.componentDidMount();
    this.refreshNodeSizes();
  }

  onSetOrganizationAllowedIAMProviders = async (enabled: boolean) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set organization to ${enabled ? "allow" : "not allow"} IAM providers.`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization allowed to use IAM providers, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationAllowedIAMProvidersRequest = {
            organization_id: this.props.organization.id,
            allowed: enabled,
          };
          await apiClients.idashboardClient.SetOrganizationAllowedIAMProviders(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  onSetOrganizationIAMProviderLimits = async (minNodeSizeID: string) => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set organization IAM provider limits to ${minNodeSizeID ? minNodeSizeID : "default limits"}.`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization IAM provider limits, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiOrganizationIAMProviderLimits = {
            organization_id: this.props.organization.id,
            minimum_node_size_id: minNodeSizeID,
          };
          await apiClients.idashboardClient.SetOrganizationIAMProviderLimits(req);
          this.refreshOrganizationInfo();
        },
      },
    });
  };

  render() {
    const nodeSizeList = this.state.nodeSizes || {};
    const nodeSizes = nodeSizeList.items || [];
    const can_set_allow_iamproviders = this.hasPermission("internal-dashboard.organization.set-allow-iamproviders");
    const can_set_organization_iamprovider_limits = this.hasPermission("internal-dashboard.organization.set-iamprovider-limits");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <IAMProvidersFieldsView
          {...this}
          {...this.props}
          {...this.state}
          iamproviderLimits={this.state.iamproviderLimits || {}}
          canSetAllowIAMProviders={can_set_allow_iamproviders}
          canSetOrganizationIAMProviderLimits={can_set_organization_iamprovider_limits}
          nodeSizes={nodeSizes}
        />
      </div>
    );
  }
}

export default withRefresh()(IAMProvidersFields);
