//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import moment from "moment";
import { Checkbox, CheckboxProps, Icon, Statistic, Popup, Table, Menu } from "semantic-ui-react";
import {
  StatisticsContentGroup,
  ErrorMessage,
  StyledStatsSegment,
  SecondaryMenu,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  FieldSet,
} from "../../../ui/lib";
import { isEmpty } from "lodash";
import { isNotFound } from "../../../api/lib";
import { v4 } from "uuid";
import { CPUUsageView } from "../../status/CpuUsageView";
import apiClients from "../../../api/apiclients";
import { MemoryUsageView } from "../../status/MemoryUsageView";
import { MLServicesInfo } from "../../../api/ml/v1/iml";
import { SetAllowGPUWorkloadsRequest } from "../../../api/ml/v1/iml";
import { MLJobsResourceLimits } from "../MLJobsResourceLimits";

export interface IMLSummaryTableViewArgs {
  canSetAllowGPUWorkloads: boolean;
  canSetJobsResourceLimits: boolean;
  mlServicesInfo: MLServicesInfo;
}

const MLSummaryTableView = ({ ...args }: IMLSummaryTableViewArgs) => {
  const [error, setError] = useState();

  const { mlServicesInfo } = args;

  const { job_resource_limits = {} } = mlServicesInfo;
  const { memory } = job_resource_limits;

  const { ...mlServices } = mlServicesInfo.ml_services;
  const { status = {}, enabled, deployment_id } = mlServices;
  const { services = [], phase, message, last_updated_at } = status;
  const { allow_gpu_workloads } = mlServicesInfo;

  const totalServices = services.length || 0;
  const availableServices = services.filter(({ available }) => available).length || 0;

  const lasUpdateMessage = last_updated_at ? moment(last_updated_at).fromNow() : "Never";

  const calculateNoDataMessage = (message?: string) => (isEmpty(status) || !message ? "-" : message);

  const onSetAllowGPUWorkloads = async (allow: boolean) => {
    try {
      const req: SetAllowGPUWorkloadsRequest = {
        deployment_id: deployment_id,
        allow_gpu_workloads: allow,
      };
      await apiClients.idashboardClient.SetAllowGPUWorkloads(req);
      setError(undefined);
    } catch (err) {
      if (!isNotFound(err)) {
        setError(err);
      }
    }
  };

  return (
    <>
      <ErrorMessage active={!!error} message={error} />
      <FieldSet>
        {args.canSetAllowGPUWorkloads && (
          <Field>
            <FL>Allow GPU Workloads</FL>
            <FC>
              <Checkbox
                fitted
                toggle
                checked={allow_gpu_workloads}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                  const { checked } = data;
                  onSetAllowGPUWorkloads(!!checked);
                }}
              />
            </FC>
          </Field>
        )}
        {args.canSetJobsResourceLimits && (
          <Field>
            <FL>Jobs Memory Limit</FL>
            <FC>
              <MLJobsResourceLimits deploymentID={deployment_id} memoryOverride={memory} />
            </FC>
          </Field>
        )}
      </FieldSet>
      <SecondaryMenu>
        <Menu.Item header>ML status summary</Menu.Item>
      </SecondaryMenu>
      <StyledStatsSegment>
        <StatisticsContentGroup>
          <Statistic.Group widths="4">
            <Statistic size="mini">
              <Statistic.Label>Enabled</Statistic.Label>
              <Statistic.Value>{enabled ? "Yes" : "No"}</Statistic.Value>
            </Statistic>
            <Statistic size="mini">
              <Statistic.Label>
                Phase
                {message && (
                  <Popup
                    content={message}
                    trigger={<Icon name={phase === "Error" ? "warning sign" : "info circle"} className={phase === "Error" ? "orange-text" : "primary-text"} />}
                  />
                )}
              </Statistic.Label>
              <Statistic.Value>{calculateNoDataMessage(phase)}</Statistic.Value>
            </Statistic>
            <Statistic size="mini">
              <Statistic.Label>
                <Icon name="calendar check" className="primary-text" /> Last Update
              </Statistic.Label>
              <Statistic.Value>{calculateNoDataMessage(lasUpdateMessage)}</Statistic.Value>
            </Statistic>
            <Statistic size="mini">
              <Statistic.Label>Total services</Statistic.Label>
              <Statistic.Value>{calculateNoDataMessage(`${totalServices}`)}</Statistic.Value>
            </Statistic>
          </Statistic.Group>
        </StatisticsContentGroup>
      </StyledStatsSegment>
      {!!availableServices && (
        <StyledStatsSegment>
          <Table striped>
            <Table.Header>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Available</Table.HeaderCell>
              <Table.HeaderCell>Failing</Table.HeaderCell>
              <Table.HeaderCell>Memory usage</Table.HeaderCell>
              <Table.HeaderCell>CPU usage</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {services.map(({ type, available, failed, usage = {} }) => (
                <Table.Row key={v4()}>
                  <Table.Cell>{type || "-"}</Table.Cell>
                  <Table.Cell>{available ? "Yes" : "No"}</Table.Cell>
                  <Table.Cell>{failed ? "Yes" : "-"}</Table.Cell>
                  <Table.Cell>
                    <MemoryUsageView limit={usage.last_memory_limit || 0} used={usage.last_memory_usage || 0}></MemoryUsageView>
                  </Table.Cell>
                  <Table.Cell>
                    <CPUUsageView limit={usage.last_cpu_limit || 0} used={usage.last_cpu_usage || 0} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </StyledStatsSegment>
      )}
    </>
  );
};

export default MLSummaryTableView;
