//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Gergely Brautigam
//

import { Component } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { PublicEmailProviderDomain as ApiPublicEmailProviderDomain, IDOptions as ApiIDOptions } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ErrorMessage, Processing } from "../../ui/lib";
import _ from "lodash";

interface IEditPublicEmailProviderDomainViewArgs {
  visible: boolean;
  domain: string;
  canSave: boolean;
  saving: boolean;
  errorMessage?: string;
  onDomainChanged: (value: string) => void;
  dismissErrorHandle: () => void;
  onSave: () => void;
  onCancel: () => void;
}

const EditPublicEmailProviderDomainView = ({ ...args }: IEditPublicEmailProviderDomainViewArgs) => (
  <Modal open={args.visible}>
    <Modal.Header>Edit public email provider domain</Modal.Header>
    <Modal.Content>
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.dismissErrorHandle} message={args.errorMessage} />
      <Modal.Description>
        <Form>
          <Form.Field>
            <Form.Input
              name="domain"
              placeholder="domain"
              value={args.domain}
              onChange={(e, data) => args.onDomainChanged(data.value)}
              disabled={args.saving}
              autoFocus
            />
          </Form.Field>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={args.onCancel} disabled={args.saving} content="Cancel" icon="remove" />
      <Button positive onClick={args.onSave} disabled={!args.canSave || args.saving} content="Save" icon="checkmark" />
    </Modal.Actions>
  </Modal>
);

// Interface decribing the properties of the public email provider domain edit component
interface IEditPublicEmailProviderDomainProps extends IWithRefreshProps, RouteComponentProps {
  id: string;
  visible: boolean;
  onPublicEmailProviderDomainSaved: (id: string) => void;
  onClose: () => void;
}

// Interface decribing the state of the public email provider domain edit component
interface IEditPublicEmailProviderDomainState {
  item?: ApiPublicEmailProviderDomain;
  domain: string;

  errorMessage?: string;
  processing: boolean;

  prev_id: string;
  refreshNeeded: boolean;
}

// The component to show public email provider domain edit modal
class EditPublicEmailProviderDomain extends Component<IEditPublicEmailProviderDomainProps, IEditPublicEmailProviderDomainState> {
  state: IEditPublicEmailProviderDomainState = {
    item: undefined,
    domain: "",
    errorMessage: undefined,
    processing: false,
    refreshNeeded: false,
    prev_id: "",
  };

  reloadPublicEmailProviderDomain = async (id: string) => {
    if (id) {
      const req: ApiIDOptions = {
        id: id,
      };
      const domain = await apiClients.idashboardClient.GetPublicEmailProviderDomain(req);
      this.setState({
        item: domain,
        domain: domain.domain || "",
      });
    } else {
      this.setState({
        item: undefined,
        domain: "",
      });
    }
  };

  refreshPublicEmailProviderDomain = (id: string) => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadPublicEmailProviderDomain(id));
  };

  static getDerivedStateFromProps(props: IEditPublicEmailProviderDomainProps, state: IEditPublicEmailProviderDomainState) {
    if (props.id !== state.prev_id) {
      return {
        refreshNeeded: true,
        prev_id: props.id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshPublicEmailProviderDomain(this.props.id);
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => this.refreshPublicEmailProviderDomain(this.props.id));
    }
  }

  onSavePublicEmailProviderDomain = async () => {
    this.setState({ processing: true });
    try {
      const item = this.state.item;
      if (item) {
        const req = _.clone(item);
        req.domain = this.state.domain;
        await apiClients.idashboardClient.UpdatePublicEmailProviderDomain(req);
        this.props.onPublicEmailProviderDomainSaved(req.id || "");
      }
    } catch (e) {
      this.setState({ errorMessage: `Public email provider domain saving failed: ${e}` });
    }
    this.setState({ processing: false });
  };

  onDomainChanged = (x: string) => {
    this.setState({ domain: x });
  };

  isValid = (): boolean => {
    return this.state.item != undefined && this.state.domain != "";
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <Processing active={this.state.processing} message="Saving public email provider domain, please wait..." />

        <EditPublicEmailProviderDomainView
          {...this.props}
          {...this.state}
          canSave={this.isValid()}
          saving={this.state.processing}
          onSave={this.onSavePublicEmailProviderDomain}
          onCancel={this.props.onClose}
          onDomainChanged={this.onDomainChanged}
          errorMessage={this.state.errorMessage}
          dismissErrorHandle={this.handleDismissError}
        />
      </div>
    );
  }
}
export default withRefresh()(EditPublicEmailProviderDomain);
