//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//
//
import { Component } from "react";
import apiClients from "../../api/apiclients";
import _ from "lodash";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IDOptions as ApiIDOptions, PrepaidDeployment as ApiPrepaidDeployment } from "../../api/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import PrepaidDeploymentModal from "../prepaid/PrepaidDeploymentModal";

export interface IPrepaidDeploymentLinkProps extends IWithRefreshProps {
  organizationID: string;
  prepaidDeploymentID?: string;
}

interface IPrepaidDeploymentLinkState {
  prevPrepaidDeploymentID?: string;
  prepaidDeployment?: ApiPrepaidDeployment;
  editing: boolean;
  refreshNeeded: boolean;
}

class PrepaidDeploymentLink extends Component<IPrepaidDeploymentLinkProps, IPrepaidDeploymentLinkState> {
  state: IPrepaidDeploymentLinkState = {
    prevPrepaidDeploymentID: undefined,
    prepaidDeployment: undefined,
    editing: false,
    refreshNeeded: false,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadPrepaidDeployment = async () => {
    const req: ApiIDOptions = {
      id: this.props.prepaidDeploymentID,
    };
    const prepaidDeploymentInfo = await apiClients.idashboardClient.GetPrepaidDeploymentInfo(req);
    this.setState({ prepaidDeployment: prepaidDeploymentInfo.prepaid_deployment });
  };

  refreshPrepaidDeployment = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPrepaidDeployment);
  };

  static getDerivedStateFromProps(props: IPrepaidDeploymentLinkProps, state: IPrepaidDeploymentLinkState) {
    if (props.prepaidDeploymentID !== state.prevPrepaidDeploymentID) {
      return {
        prevPrepaidDeploymentID: props.prepaidDeploymentID,
        refreshNeeded: true,
        editing: false,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshPrepaidDeployment();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshPrepaidDeployment);
    }
  }

  render() {
    if (_.isEmpty(this.props.prepaidDeploymentID)) {
      return <span>-</span>;
    }

    return (
      <span>
        <span
          className="text-link"
          onClick={() => {
            this.setState({ editing: true });
          }}
        >
          {this.props.prepaidDeploymentID}
        </span>
        {this.state.prepaidDeployment && this.state.editing && (
          <PrepaidDeploymentModal
            {...this.props}
            item={this.state.prepaidDeployment}
            onClose={() => {
              this.setState({ editing: false, prepaidDeployment: undefined });
              this.refreshPrepaidDeployment();
            }}
          />
        )}
      </span>
    );
  }
}

export default withRefresh()(PrepaidDeploymentLink);
