//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Input, InputOnChangeData } from "semantic-ui-react";
import styled from "@emotion/styled";
import _ from "lodash";

interface IFloatInputProps {
  min?: number;
  max?: number;
  value: number;
  ext?: string;
  onChange: (value: number) => void;
  placeholder?: string;
}

interface IFloatInputState {
  value: number;
  valueString: string;
  errorMessage?: string;
}

const StyledGroup = styled("div")`
  display: inline-flex;
  .ui.input input {
    text-align: center;
    width: 6em !important;
  }
  .ui.input.error input {
    background-color: red;
  }
`;

export class FloatInput extends Component<IFloatInputProps, IFloatInputState> {
  state: IFloatInputState = {
    value: this.props.value,
    valueString: `${this.props.value}${this.props.ext || ""}`,
    errorMessage: undefined,
  };

  onNumberChange = (e: any, data: InputOnChangeData) => {
    this.setState({ valueString: data.value });
  };

  onBlur = () => {
    this.validateValueString(this.props.onChange);
  };

  validateValueString = (callback: (newValue: number) => void) => {
    if (this.state.valueString == "") {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.valueString = `${this.props.value}`;
    }
    let numberString = this.state.valueString;
    if (this.props.ext) {
      numberString = _.replace(numberString, this.props.ext, "");
    }
    const x = parseFloat(numberString);
    if (isNaN(x)) {
      this.setState({ errorMessage: "Not a valid number" });
    } else {
      if ((this.props.min != undefined && x < this.props.min) || (this.props.max != undefined && x > this.props.max)) {
        this.setState({ errorMessage: `Number must be in between ${this.props.min} and ${this.props.max}` });
      } else {
        this.setState(
          {
            value: x,
            valueString: `${x}${this.props.ext || ""}`,
            errorMessage: undefined,
          },
          () => {
            callback(x);
          }
        );
      }
    }
  };

  render() {
    return (
      <StyledGroup>
        <Input
          focus
          value={this.state.valueString}
          onChange={this.onNumberChange}
          onBlur={this.onBlur}
          error={!!this.state.errorMessage}
          placeholder={this.props.placeholder}
        />
      </StyledGroup>
    );
  }
}
