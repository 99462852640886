//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import { Button, Form, Icon, Input } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, SetOrganizationDataclusterLabelFilterRequest as ApiSetOrganizationDataclusterLabelFilterRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL, Processing } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";

interface IDataclusterLabelFilterFieldViewArgs {
  canSetDataclusterLabelFilter: boolean;
  editDataclusterLabelFilter: boolean;
  onSaveDataclusterLabelFilter: () => void;
  onEditDataclusterLabelFilter: () => void;
  onCancelEditDataclusterLabelFilter: () => void;
  onChangeDataclusterLabelFilter: (filter: string) => void;
  dataclusterLabelFilter?: string;
}

const DataclusterLabelFilterFieldView = ({ ...args }: IDataclusterLabelFilterFieldViewArgs) => {
  return (
    <Field>
      <FL>Datacluster Label Filter</FL>
      <FC>
        {args.editDataclusterLabelFilter && (
          <div>
            <Form.Field>
              <label>Filter</label>
              <Input key="dc-lbl-flt" value={args.dataclusterLabelFilter || ""} onChange={(e, d) => args.onChangeDataclusterLabelFilter(d.value as string)} />
              <Button.Group icon basic size="small">
                <Button icon="check" onClick={args.onSaveDataclusterLabelFilter} />
                <Button icon="cancel" onClick={args.onCancelEditDataclusterLabelFilter} />
              </Button.Group>
            </Form.Field>
          </div>
        )}
        {!args.editDataclusterLabelFilter && (
          <div>
            <span>{args.dataclusterLabelFilter || "-"}</span>
            {args.canSetDataclusterLabelFilter && <Icon name="pencil" onClick={args.onEditDataclusterLabelFilter} className="edit-pencil" />}
          </div>
        )}
      </FC>
    </Field>
  );
};

interface IDataclusterLabelFilterFieldProps extends IOrganizationDetailsFieldProps, CommentCreationArgs {}

interface IDataclusterLabelFilterFieldState extends IOrganizationDetailsFieldState {
  processingSetDataclusterLabelFilter: boolean;
  editingDataclusterLabelFilter: boolean;
  dataclusterLabelFilter?: string;
  commentPrompt: ICommentPromptStateForIntegrations;
}

class DataclusterLabelFilterField extends OrganizationDetailsField<IDataclusterLabelFilterFieldProps, IDataclusterLabelFilterFieldState> {
  state: IDataclusterLabelFilterFieldState = {
    processingSetDataclusterLabelFilter: false,
    editingDataclusterLabelFilter: false,
    dataclusterLabelFilter: undefined,
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };
  resetCommentPrompt = () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      const organizationInfo = await apiClients.idashboardClient.GetOrganizationInfo(idOptions);
      this.setState({
        dataclusterLabelFilter: organizationInfo.datacluster_label_filter,
      });
    }
  };

  onSaveDataclusterLabelFilter = async () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `Set datacluster label filter to ${this.state.dataclusterLabelFilter}`,
        onCancellingCommentAddition: this.resetCommentPrompt,
        loadingMessage: "Changing organization datacluster label filter, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationDataclusterLabelFilterRequest = {
            organization_id: this.props.organization.id,
            datacluster_label_filter: this.state.dataclusterLabelFilter,
          };
          await apiClients.idashboardClient.SetOrganizationDataclusterLabelFilter(req);
          this.refreshOrganizationInfo();
          this.setState({ editingDataclusterLabelFilter: false });
        },
      },
    });
  };

  onChangeDataclusterLabelFilter = (filter: string) => {
    this.setState({ dataclusterLabelFilter: filter });
  };

  render() {
    const can_set_datacluster_label_filter = this.hasPermission("internal-dashboard.organization.set-datacluster-label-filter");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <Processing active={this.state.processingSetDataclusterLabelFilter} message="Changing organization datacluster label filter, please wait..." />
        <DataclusterLabelFilterFieldView
          {...this}
          {...this.props}
          {...this.state}
          editDataclusterLabelFilter={this.state.editingDataclusterLabelFilter}
          canSetDataclusterLabelFilter={can_set_datacluster_label_filter}
          onEditDataclusterLabelFilter={() => this.setState({ editingDataclusterLabelFilter: true })}
          onCancelEditDataclusterLabelFilter={() => this.setState({ editingDataclusterLabelFilter: false }, this.refreshOrganizationInfo)}
        />
      </div>
    );
  }
}

export default withRefresh()(DataclusterLabelFilterField);
