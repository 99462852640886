//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Table, Menu, Popup, Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  DeploymentJob as ApiDeploymentJob,
  DeploymentJobList as ApiDeploymentJobList,
  ListDeploymentJobsRequest as ApiListDeploymentJobsRequest,
  Deployment as ApiDeployment,
  DeploymentJob_Status as ApiDeploymentJob_Status,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { ContentSegment, SecondaryMenu, Loading, ErrorMessage, LoaderBox, PagingButtons, UserLink } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import _ from "lodash";
import ReactJson from "react-json-view";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { humanizeFileSize } from "../../util/FileSize";

interface IHeaderView {
  loading: boolean;
  page: number;
  count: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Arguments</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Output</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>By</Table.HeaderCell>
      <Table.HeaderCell>Started</Table.HeaderCell>
      <Table.HeaderCell>Terminated</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IStatusView {
  status: ApiDeploymentJob_Status;
}

const StatusView = ({ ...args }: IStatusView) => {
  const status = args.status;
  if (!!status.failed) {
    return <span>Failed: {status.failure_reason || "?"}</span>;
  }
  if (!!status.is_terminated) {
    return <span>Terminated</span>;
  }
  if (!!status.is_started) {
    return <span>Started</span>;
  }
  return <span>Pending</span>;
};

interface IRowView extends IWithRefreshProps {
  active: boolean;
  item: ApiDeploymentJob;
}

const RowView = ({ ...args }: IRowView) => {
  const status = args.item.status || {};
  return (
    <Table.Row>
      <Table.Cell>
        <Popup trigger={<u>{args.item.id || ""}</u>} content={<ReactJson src={args.item} collapsed={1} />} on="click" pinned />
      </Table.Cell>
      <Table.Cell>{args.item.type || ""}</Table.Cell>
      <Table.Cell>{JSON.stringify(args.item.custom_fields)}</Table.Cell>
      <Table.Cell>
        <StatusView status={status} />
      </Table.Cell>
      <Table.Cell>{humanizeFileSize(status.output_bytes || 0)}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>
        <Table.Cell>
          {(args.item.created_by_id || "").includes("oasis-system") ? args.item.created_by_id : <UserLink id={args.item.created_by_id || ""} />}
        </Table.Cell>
      </Table.Cell>
      <Table.Cell>{!!status.started_at ? <DateTimePopupWithUTCAndLocalTime dateTime={status.started_at} label="Started at" /> : "-"}</Table.Cell>
      <Table.Cell>{!!status.terminated_at ? <DateTimePopupWithUTCAndLocalTime dateTime={status.terminated_at} label="Terminated at" /> : "-"}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.deleted_at} label="Deleted at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

interface IListView extends IWithRefreshProps {
  active: boolean;
  items: ApiDeploymentJob[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No jobs</div>;

export interface IDeploymentJobListViewArgs extends RouteComponentProps, IWithRefreshProps {
  active: boolean;
  loading: boolean;
  jobs?: ApiDeploymentJobList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

export const DeploymentJobListView = ({ ...args }: IDeploymentJobListViewArgs) => {
  if (!args.jobs) {
    return <Loading />;
  }
  const items = args.jobs.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={_.orderBy(items, (x) => -moment(x.created_at).unix())} loading={args.loading} />;
};

interface IDeploymentJobListProps extends IWithRefreshProps, RouteComponentProps {
  showHeader: boolean;
  deployment: ApiDeployment;
}

interface IDeploymentJobListState {
  errorMessage?: string;
  processing: boolean;
  jobs?: ApiDeploymentJobList;
  page: number;
  pageSize: number;
}

// The component to show the deployment jobs list.
class DeploymentJobList extends Component<IDeploymentJobListProps, IDeploymentJobListState> {
  state: IDeploymentJobListState = {
    errorMessage: undefined,
    processing: false,
    jobs: undefined,
    page: 0,
    pageSize: 50,
  };

  reloadJobs = async () => {
    const listOptions: ApiListDeploymentJobsRequest = {
      deployment_id: this.props.deployment.id,
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    const jobs = await apiClients.idashboardClient.ListDeploymentJobs(listOptions);
    this.setState({ jobs: jobs });
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.reloadJobs
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.reloadJobs
    );
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadJobs, `${this.props.deployment.url}/DeploymentJob/*`);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        {this.props.showHeader && (
          <SecondaryMenu>
            <Menu.Item header>Jobs</Menu.Item>
          </SecondaryMenu>
        )}
        <DeploymentJobListView
          {...this.props}
          {...this.state}
          active={!this.state.processing}
          onNextPage={this.onNextPage}
          onPreviousPage={this.onPreviousPage}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(DeploymentJobList);
