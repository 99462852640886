//
// DISCLAIMER
//
// Copyright 2021-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component, useState } from "react";
import { Button, Grid, Header, Popup, Table, Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { isNotFound, Deployment as ApiDeployment, PrivateEndpointServiceInfo as ApiPrivateEndpointServiceInfo, IDOptions as ApiIDOptions } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { Confirm, ConfirmInfo, ErrorMessage, Finalizers, Field, FieldContent as FC, FieldLabelWide as FL, FieldSet, Processing, FlexBox } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { isEmpty } from "lodash";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { SubscriptionIDModalView } from "./SubscriptionIDView";

interface IPrivateEndpointServiceViewArgs {
  info: ApiPrivateEndpointServiceInfo;
  deployment?: ApiDeployment;

  canUpdate: boolean;
  canDelete: boolean;
  onDelete: () => void;
  reloadPrivateEndpointService: () => void;
}

const GeneralView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  return (
    <div>
      <Header sub>General</Header>
      <Field>
        <FL>Name</FL>
        <FC>{pes.name || "-"}</FC>
      </Field>
      <Field>
        <FL>Description</FL>
        <FC>{pes.description || "-"}</FC>
      </Field>
      <Field>
        <FL>ID</FL>
        <FC>
          <div>{pes.id || "-"}</div>
        </FC>
      </Field>
      <Field>
        <FL>Finalizers</FL>
        <FC>
          <Finalizers finalizers={info.finalizers} />
        </FC>
      </Field>
      <Field>
        <FL>Loadbalancer ID</FL>
        <FC>
          <FC>
            <div>{info.load_balancer_id || "-"}</div>
          </FC>
        </FC>
      </Field>
      <Field>
        <FL>Created</FL>
        <FC>
          <DateTimePopupWithUTCAndLocalTime dateTime={pes.created_at} label="Created at" />
        </FC>
      </Field>
      {!!pes.is_deleted && (
        <Field>
          <FL>Deleted</FL>
          <FC>
            <DateTimePopupWithUTCAndLocalTime dateTime={pes.deleted_at} label="Deleted at" />
          </FC>
        </Field>
      )}
    </div>
  );
};

const DNSView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const alternateDnsNames = pes.alternate_dns_names || [];
  return (
    <div>
      <Header sub>DNS</Header>
      <Field>
        <FL>Private DNS:</FL>
        <FC>
          <div>{pes.enable_private_dns ? "Enabled" : "Disabled"}</div>
        </FC>
      </Field>
      <Field>
        <FL>Alternate names</FL>
        <FC>
          {isEmpty(alternateDnsNames) && <span>-</span>}
          {alternateDnsNames.map((alternateDnsName) => (
            <div>
              <code>{alternateDnsName}</code>
            </div>
          ))}
        </FC>
      </Field>
    </div>
  );
};

const AKSView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const aks = pes.aks || {};
  const clientSubscriptionIds = aks.client_subscription_ids || [];
  const [showSubscriptionIDModal, toggleShowSubscriptionIDModal] = useState(false);

  const saveSubscriptionID = async (subscriptionIDS: string[]): Promise<{ error: string | undefined } | undefined> => {
    const { idashboardClient } = apiClients;
    const { info } = args;
    const { private_endpoint_service: privateNetworkDetails } = info;
    try {
      await idashboardClient.UpdatePrivateEndpointServiceInfo({
        ...info,
        private_endpoint_service: {
          ...privateNetworkDetails,
          aks: {
            client_subscription_ids: subscriptionIDS,
          },
        },
      });
      await args.reloadPrivateEndpointService();
    } catch (e) {
      return { error: e };
    }
    return await { error: "" };
  };
  return (
    <div>
      <Header sub>Azure</Header>

      <Field>
        <FL>Client subscription IDs</FL>
        <FC>
          {isEmpty(clientSubscriptionIds) && <span>-</span>}
          {clientSubscriptionIds.map((subscriptionId) => (
            <FlexBox justify="flex-start">
              {showSubscriptionIDModal && (
                <SubscriptionIDModalView
                  onClose={() => {
                    toggleShowSubscriptionIDModal(false);
                  }}
                  onSave={saveSubscriptionID}
                  subscriptionIDs={clientSubscriptionIds}
                />
              )}
              <code>{subscriptionId}</code>{" "}
              {args.canUpdate && <Icon name="pencil" className="cursor-pointer" onClick={() => toggleShowSubscriptionIDModal(true)} />}
            </FlexBox>
          ))}
        </FC>
      </Field>
    </div>
  );
};

const AWSView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const aws = pes.aws || {};
  const awsPrincipals = aws.aws_principals || [];
  return (
    <div>
      <Header sub>AWS</Header>
      <Field>
        <FL>Principals</FL>
        <FC>
          {isEmpty(awsPrincipals) && <span>-</span>}
          {awsPrincipals.map((awsPrincipal) => (
            <div>
              <code>{awsPrincipal.account_id}</code>
              <span>
                {awsPrincipal.role_names ? awsPrincipal.role_names.length : 0} Roles:
                {awsPrincipal.role_names && awsPrincipal.role_names.map((roleName) => <code>{roleName}</code>)}
              </span>
              <span>
                {awsPrincipal.user_names ? awsPrincipal.user_names.length : 0} Users:
                {awsPrincipal.user_names && awsPrincipal.user_names.map((userName) => <code>{userName}</code>)}
              </span>
            </div>
          ))}
        </FC>
      </Field>
    </div>
  );
};

const GCPView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const gcp = pes.gcp || {};
  const projects = gcp.projects || [];
  return (
    <div>
      <Header sub>Google</Header>
      <Field>
        <FL>Project IDs</FL>
        <FC>
          {isEmpty(projects) && <span>-</span>}
          {projects.map((project) => (
            <div>
              <code>{project}</code>
            </div>
          ))}
        </FC>
      </Field>
    </div>
  );
};

const StatusView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const status = pes.status || {};
  const isReady = !!status.ready;
  const needsAttention = !!status.needs_attention;
  return (
    <div>
      <Header sub>Status</Header>
      <Field>
        <FL>Ready</FL>
        <FC>
          <div>{isReady ? "Yes" : "No"}</div>
          {isReady && (
            <div>
              <DateTimePopupWithUTCAndLocalTime dateTime={status.ready_at} label="Ready at" />
            </div>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Needs attention</FL>
        <FC>
          <span style={{ color: needsAttention ? "orange" : "inherit" }}>{needsAttention ? "Yes" : "No"}</span>
        </FC>
      </Field>
      <Field>
        <FL>Message</FL>
        <FC>{status.message || "-"}</FC>
      </Field>
    </div>
  );
};

const StatusAKSView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const status = pes.status || {};
  const aks = status.aks || {};
  const connStatuses = aks.private_endpoint_connections || [];
  return (
    <div>
      <Header sub>Azure status</Header>
      <Field>
        <FL>Alias</FL>
        <FC>
          <div>{aks.alias || "-"}</div>
        </FC>
      </Field>
      <Field>
        <FL>Connections</FL>
        <FC>{isEmpty(connStatuses) ? "None" : connStatuses.length}</FC>
      </Field>
      {connStatuses.map((connStat, i) => (
        <div>
          <Header sub>Connection {i + 1} status</Header>
          <Field>
            <FL>ID</FL>
            <FC>
              <div>{connStat.id || "-"}</div>
            </FC>
          </Field>
          <Field>
            <FL>Name</FL>
            <FC>{connStat.name || "-"}</FC>
          </Field>
          <Field>
            <FL>Description</FL>
            <FC>{connStat.description || "-"}</FC>
          </Field>
          <Field>
            <FL>State</FL>
            <FC>{connStat.state || "?"}</FC>
          </Field>
        </div>
      ))}
    </div>
  );
};

const StatusAWSView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const status = pes.status || {};
  const aws = status.aws || {};
  const connStatuses = aws.private_endpoint_connections || [];
  return (
    <div>
      <Header sub>AWS status</Header>
      <Field>
        <FL>Service Name</FL>
        <FC>
          <div>{aws.service_name || "-"}</div>
        </FC>
      </Field>
      <Field>
        <FL>Availability Zones</FL>
        <FC>
          <FC>
            {!aws.availability_zones || isEmpty(aws.availability_zones) ? (
              "None"
            ) : (
              <Popup
                position="right center"
                trigger={<i>{aws.availability_zones.length}</i>}
                content={
                  <Table striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Availability zone ID</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {aws.availability_zones.map((zone, i) => (
                        <Table.Row>{zone}</Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                }
              />
            )}
          </FC>
        </FC>
      </Field>
      <Field>
        <FL>Connections</FL>
        <FC>{isEmpty(connStatuses) ? "None" : connStatuses.length}</FC>
      </Field>
      {connStatuses.map((connStat, i) => (
        <div>
          <Header sub>Connection {i + 1} status</Header>
          <Field>
            <FL>ID</FL>
            <FC>
              <div>{connStat.id || "-"}</div>
            </FC>
          </Field>
          <Field>
            <FL>Owner</FL>
            <FC>{connStat.owner || "-"}</FC>
          </Field>
          <Field>
            <FL>Created</FL>
            <FC>
              <DateTimePopupWithUTCAndLocalTime dateTime={connStat.created_at} label="Created at" />
            </FC>
          </Field>
          <Field>
            <FL>State</FL>
            <FC>{connStat.state || "?"}</FC>
          </Field>
        </div>
      ))}
    </div>
  );
};

const StatusGCPView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const status = pes.status || {};
  const gcp = status.gcp || {};
  const connStatuses = gcp.private_endpoint_connections || [];
  return (
    <div>
      <Header sub>Google status</Header>
      <Field>
        <FL>Service Attachment</FL>
        <FC>
          <div>{gcp.service_attachment || "-"}</div>
        </FC>
      </Field>
      <Field>
        <FL>Connections</FL>
        <FC>{isEmpty(connStatuses) ? "None" : connStatuses.length}</FC>
      </Field>
      {connStatuses.map((connStat, i) => (
        <div>
          <Header sub>Connection {i + 1} status</Header>
          <Field>
            <FL>ID</FL>
            <FC>
              <div>{connStat.id || "-"}</div>
            </FC>
          </Field>
          <Field>
            <FL>Name</FL>
            <FC>{connStat.name || "-"}</FC>
          </Field>
          <Field>
            <FL>State</FL>
            <FC>{connStat.state || "?"}</FC>
          </Field>
        </div>
      ))}
    </div>
  );
};

const PrivateEndpointServiceInfoView = ({ ...args }: IPrivateEndpointServiceViewArgs) => {
  const info = args.info;
  const pes = info.private_endpoint_service || {};
  const { status = {} } = pes;

  return (
    <div>
      <FieldSet>
        <Grid columns={3} doubling>
          <Grid.Column width="5">
            <GeneralView {...args} />
          </Grid.Column>
          <Grid.Column width="5">
            <DNSView {...args} />
            {!!pes.aks && (
              <div>
                <br />
                <AKSView {...args} />
              </div>
            )}
            {!!pes.aws && (
              <div>
                <br />
                <AWSView {...args} />
              </div>
            )}
            {!!pes.gcp && (
              <div>
                <br />
                <GCPView {...args} />
              </div>
            )}
          </Grid.Column>
          <Grid.Column width="6">
            <StatusView {...args} />
            {!!status.aks && (
              <div>
                <br />
                <StatusAKSView {...args} />
              </div>
            )}
            {!!status.aws && (
              <div>
                <br />
                <StatusAWSView {...args} />
              </div>
            )}
            {!!status.gcp && (
              <div>
                <br />
                <StatusGCPView {...args} />
              </div>
            )}
          </Grid.Column>
        </Grid>
      </FieldSet>
      <Button content="Delete!" icon="trash" disabled={!args.canDelete} onClick={args.onDelete} />
    </div>
  );
};

const NoPrivateEndpointServiceInfoView = () => {
  return <span>No Private Endpoint Service found</span>;
};

interface IPrivateEndpointServiceDetailsProps extends IWithRefreshProps, RouteComponentProps {
  deployment?: ApiDeployment;
}

interface IPrivateEndpointServiceDetailsState {
  lastDeploymentID?: string;
  info?: ApiPrivateEndpointServiceInfo;
  refreshNeeded: boolean;
  errorMessage?: string;
  processingDelete: boolean;
  confirmInfo?: ConfirmInfo;
}

// The component to show the private endpoint service for a deployment.
class PrivateEndpointServiceDetails extends Component<IPrivateEndpointServiceDetailsProps, IPrivateEndpointServiceDetailsState> {
  state: IPrivateEndpointServiceDetailsState = {
    lastDeploymentID: (this.props.deployment || {}).id,
    info: undefined,
    errorMessage: undefined,
    refreshNeeded: false,
    processingDelete: false,
    confirmInfo: undefined,
  };

  reloadPrivateEndpointService = async () => {
    const depl = this.props.deployment || {};
    if (!!depl.id) {
      try {
        const req: ApiIDOptions = { id: depl.id };
        const info = await apiClients.idashboardClient.GetPrivateEndpointServiceInfoByDeploymentID(req);
        this.setState({ info: info });
      } catch (e) {
        if (isNotFound(e)) {
          // No private endpoint service found
          this.setState({ info: undefined, errorMessage: undefined });
        } else {
          // Some other error
          this.setState({ info: undefined, errorMessage: `Cannot load Private Endpoint Service: ${e}` });
        }
      }
    } else {
      this.setState({ info: undefined, errorMessage: undefined });
    }
  };

  refreshPrivateEndpointService = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPrivateEndpointService);
  };

  static getDerivedStateFromProps(props: IPrivateEndpointServiceDetailsProps, state: IPrivateEndpointServiceDetailsState) {
    const depl = props.deployment || {};
    if (depl.id !== state.lastDeploymentID) {
      return {
        lastDeploymentID: depl.id,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshPrivateEndpointService();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshPrivateEndpointService);
    }
  }

  dismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onDelete = () => {
    const confirmInfo: ConfirmInfo = {
      header: "Delete Private Endpoint Service",
      content: `Are you sure you want to delete this Private Endpoint Service?`,
      warning: "This can only be done with explicit consent from management!",
      onConfirm: async () => {
        this.setState({ processingDelete: true, errorMessage: undefined });
        try {
          const info = this.state.info || {};
          const pes = info.private_endpoint_service || {};
          const req: ApiIDOptions = { id: pes.id };
          await apiClients.idashboardClient.DeletePrivateEndpointService(req);
          this.setState({ info: undefined });
        } catch (e) {
          this.setState({ errorMessage: `Failed to delete Private Endpoint Service: ${e}` });
        } finally {
          this.setState({ processingDelete: false, confirmInfo: undefined });
        }
      },
      onDenied: () => {
        this.setState({ confirmInfo: undefined });
      },
    };
    this.setState({
      confirmInfo: confirmInfo,
    });
  };

  render() {
    const hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);
    const canDelete = hasPermission("internal-dashboard.privateendpointservice.delete");
    const canUpdate = hasPermission("internal-dashboard.privateendpointservice.update");
    const info = this.state.info;
    const hasInfo = !!info;
    return (
      <div>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.dismissError} />
        <Processing active={this.state.processingDelete} message="Deleting private endpoint service..." />
        {!hasInfo && <NoPrivateEndpointServiceInfoView />}
        {hasInfo && (
          <PrivateEndpointServiceInfoView
            {...this.props}
            {...this.state}
            info={info || {}}
            onDelete={this.onDelete}
            canDelete={canDelete}
            canUpdate={canUpdate}
            reloadPrivateEndpointService={this.reloadPrivateEndpointService}
          />
        )}
      </div>
    );
  }
}

export default withRefresh()(PrivateEndpointServiceDetails);
