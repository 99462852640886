//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany

//
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Loader, Dimmer, Table, Button, Modal, Icon } from "semantic-ui-react";
import { Routes } from "../../routes";
import history from "../../history";
import apiClients from "../../api/apiclients";
import _ from "lodash";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import {
  ListPrepaidDeploymentsRequest,
  PrepaidDeploymentInfo,
  PrepaidDeployment,
  Organization,
  Deployment_ModelSpec,
  IDOptions,
  DeploymentInfo,
  ListAllDeploymentsRequest,
  Deployment,
  AttachDeploymentToPrepaidDeploymentRequest,
  DetachDeploymentFromPrepaidDeploymentRequest,
} from "../../api/lib";
import { TextLink, Confirm, ConfirmInfo, ErrorMessage, FieldSet, ContentSegment } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import PrepaidDeploymentModal from "../prepaid/PrepaidDeploymentModal";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

interface IAttachDeploymentModalProps extends IWithRefreshProps, RouteComponentProps {
  prepaidDeployment: PrepaidDeployment;
  onClose: () => void;
}

const modelDescription = (model?: Deployment_ModelSpec) => (
  <div>
    {(!!model && model.model) || ""} {(model && model.node_size_id) || ""} {(model && model.node_count) || ""} x {(model && model.node_disk_size) || ""} GiB
  </div>
);

const AttachDeploymentModal = ({ ...args }: IAttachDeploymentModalProps) => {
  const onAttach = async (deployment: Deployment) => {
    try {
      setIsLoading(true);
      const req: AttachDeploymentToPrepaidDeploymentRequest = {
        prepaid_deployment_id: args.prepaidDeployment.id,
        deployment_id: deployment.id,
      };
      await apiClients.idashboardClient.AttachDeploymentToPrepaidDeployment(req);
      args.onClose();
    } catch (e) {
      setErrorMessage(e);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidDeploymentInfo = (item: DeploymentInfo): boolean => {
    const model = item.deployment && item.deployment.model;
    const prepaidModel = args.prepaidDeployment.model;
    return (
      !!item.deployment &&
      !!model &&
      !!prepaidModel &&
      model.model === prepaidModel.model &&
      item.deployment.region_id === args.prepaidDeployment.region_id &&
      (model.node_disk_size || 0) <= (prepaidModel.node_disk_size || 0)
    );
  };

  const filterDeploymentInfo = (item: DeploymentInfo): boolean => {
    return showAll || isValidDeploymentInfo(item);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState<DeploymentInfo[] | []>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showAll, setShowAll] = React.useState(false);

  const toggleFilter = () => {
    setShowAll(!showAll);
  };

  const loadDeployments = async () => {
    setIsLoading(true);
    try {
      const req: ListAllDeploymentsRequest = {
        organization_id: args.prepaidDeployment.organization_id,
      };
      const list = await apiClients.idashboardClient.ListAllDeployments(req);
      const items = _.filter(list.items, filterDeploymentInfo) as PrepaidDeploymentInfo[];
      setItems(items);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadDeployments();
  }, [showAll]);

  const Row = (info: DeploymentInfo) => {
    const deployment = info.deployment;
    if (!deployment) {
      return;
    }
    return (
      <Table.Row key={deployment.id}>
        <Table.Cell>{deployment.id}</Table.Cell>
        <Table.Cell>{deployment.name || ""}</Table.Cell>
        <Table.Cell>{deployment.region_id || ""}</Table.Cell>
        <Table.Cell>{modelDescription(deployment.model)}</Table.Cell>
        <Table.Cell>{deployment.version || ""}</Table.Cell>
        <Table.Cell>
          {isValidDeploymentInfo(info) && (
            <Button
              positive
              icon="linkify"
              label="Attach"
              onClick={(e) => {
                e.preventDefault();
                onAttach(deployment);
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };
  const Content = () => {
    if (items.length > 0) {
      return (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Region</Table.HeaderCell>
              <Table.HeaderCell>Model</Table.HeaderCell>
              <Table.HeaderCell>Version</Table.HeaderCell>
              <Table.HeaderCell>
                <Button
                  toggle
                  active={!showAll}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleFilter();
                  }}
                  icon="filter"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{_.map(items, Row)}</Table.Body>
        </Table>
      );
    }
    return isLoading ? (
      <Dimmer inverted active>
        <Loader active inverted>
          Fetching deployments
        </Loader>
      </Dimmer>
    ) : (
      <div>No deployments found</div>
    );
  };

  return (
    <Modal open={true}>
      <Modal.Header>Attach deployment to prepaid deployment</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!errorMessage} onDismiss={() => setErrorMessage("")} message={errorMessage} />
        <FieldSet disabled={isLoading}>{Content()}</FieldSet>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={args.onClose}>
          <Icon name="remove" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export interface IOrganizationPrepaidDeploymentsProps extends IWithRefreshProps, RouteComponentProps {
  organization: Organization;
}

interface IOrganizationPrepaidDeploymentsState {
  processing: boolean;
  items?: PrepaidDeploymentInfo[];
  errorMessage?: string;
  editing: boolean;
  currentItem?: PrepaidDeployment;
  deletingItem?: PrepaidDeployment;
  attachItem?: PrepaidDeployment;
  detachItem?: PrepaidDeployment;
  confirmInfo?: ConfirmInfo;
}

class OrganizationPrepaidDeploymentsView extends Component<IOrganizationPrepaidDeploymentsProps, IOrganizationPrepaidDeploymentsState> {
  state: IOrganizationPrepaidDeploymentsState = {
    errorMessage: undefined,
    processing: false,
    items: undefined,
    editing: false,
    currentItem: undefined,
    deletingItem: undefined,
    confirmInfo: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadItems = async () => {
    this.setState({ processing: true });
    const req: ListPrepaidDeploymentsRequest = { organization_id: this.props.organization.id };
    try {
      const list = await apiClients.idashboardClient.ListAllPrepaidDeployments(req);
      this.setState({ items: list.items });
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processing: false });
    }
  };
  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadItems, this.props.organization.url + "/PrepaidDeployment/*");
  }

  deletePrepaidDeployment = (prepaidDeployment: PrepaidDeployment) => {
    const confirmInfo: ConfirmInfo = {
      header: "Are you sure that you want to delete Prepaid Deployment",
      content: `Are you sure that you want Prepaid deployment ${prepaidDeployment.id} to be deleted?`,
      onDenied: () => {
        this.setState({ confirmInfo: undefined, deletingItem: undefined });
      },
      onConfirm: async () => {
        try {
          const req: IDOptions = { id: prepaidDeployment.id };
          await apiClients.idashboardClient.DeletePrepaidDeployment(req);
        } catch (e) {
          this.setState({ errorMessage: e });
        } finally {
          this.setState({ confirmInfo: undefined, deletingItem: undefined });
        }
        this.reloadItems();
      },
    };
    this.setState({
      deletingItem: prepaidDeployment,
      confirmInfo: confirmInfo,
    });
  };

  createPrepaidDeployment = () => {
    this.setState({ editing: true, currentItem: undefined });
  };

  editPrepaidDeployment = (item?: PrepaidDeployment) => {
    this.setState({ editing: true, currentItem: item });
  };

  attachDetachDeployment = (item: PrepaidDeployment) => {
    // detach confirmation box
    if (item.status && item.status.deployment_id) {
      const confirmInfo: ConfirmInfo = {
        header: "Are you sure that you want to detach deployment from Prepaid Deployment",
        content: `Are you sure that you want to detach deployment ${item.status.deployment_id || "-"} from Prepaid deployment ${item.id}?`,
        onDenied: () => {
          this.setState({ confirmInfo: undefined, detachItem: undefined });
        },
        onConfirm: async () => {
          try {
            const req: DetachDeploymentFromPrepaidDeploymentRequest = {
              prepaid_deployment_id: item.id,
            };
            await apiClients.idashboardClient.DetachDeploymentFromPrepaidDeployment(req);
            await this.reloadItems();
          } catch (e) {
            this.setState({ errorMessage: e });
          } finally {
            this.setState({ confirmInfo: undefined, detachItem: undefined });
          }
        },
      };
      this.setState({
        detachItem: item,
        confirmInfo: confirmInfo,
      });
    } else {
      // show attachment modal
      this.setState({ attachItem: item });
    }
  };

  render() {
    const canUpdate = this.hasPermission("internal-dashboard.prepaiddeployment.update");
    const canCreate = this.hasPermission("internal-dashboard.prepaiddeployment.create");
    const canDelete = this.hasPermission("internal-dashboard.prepaiddeployment.delete");
    const canAttach = this.hasPermission("internal-dashboard.prepaiddeployment.attach");
    const canDetach = this.hasPermission("internal-dashboard.prepaiddeployment.detach");

    const Row = (item: PrepaidDeploymentInfo) => {
      const prepaid_deployment = item.prepaid_deployment || ({} as PrepaidDeployment);
      const attachedDeploymentId = (prepaid_deployment.status && prepaid_deployment.status.deployment_id) || "";
      const onDeploymentClick = () => {
        const id = prepaid_deployment.status && prepaid_deployment.status.deployment_id;
        if (!!id) {
          history.push(Routes.dashboard_support_deployment_detailsWithId(id));
        }
      };
      return (
        <Table.Row key={prepaid_deployment.id}>
          <Table.Cell>
            {canUpdate && <TextLink onClick={() => this.editPrepaidDeployment(item.prepaid_deployment)} label={prepaid_deployment.id} />}
            {!canUpdate && item.prepaid_deployment && item.prepaid_deployment.id}
          </Table.Cell>
          <Table.Cell>
            <span title={prepaid_deployment.description}>{prepaid_deployment.name}</span>
          </Table.Cell>
          <Table.Cell>{modelDescription(prepaid_deployment.model)}</Table.Cell>
          <Table.Cell>{prepaid_deployment.region_id || "-"}</Table.Cell>
          <Table.Cell>
            {prepaid_deployment.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={prepaid_deployment.created_at} label="Created at" /> : "-"}
          </Table.Cell>
          <Table.Cell>
            {prepaid_deployment.starts_at ? <DateTimePopupWithUTCAndLocalTime dateTime={prepaid_deployment.starts_at} label="Starts at" /> : "-"}
          </Table.Cell>
          <Table.Cell>
            {prepaid_deployment.ends_at ? <DateTimePopupWithUTCAndLocalTime dateTime={prepaid_deployment.ends_at} label="Ends at" /> : "-"}
          </Table.Cell>
          <Table.Cell>{prepaid_deployment.is_active ? "Yes" : "No"}</Table.Cell>
          <Table.Cell>{!!attachedDeploymentId && <TextLink label={attachedDeploymentId} onClick={onDeploymentClick} />}</Table.Cell>
          <Table.Cell textAlign="right">
            {!attachedDeploymentId && canAttach && (
              <Button icon="linkify" size="tiny" basic title="Attach deployment" onClick={() => this.attachDetachDeployment(prepaid_deployment)} />
            )}
            {!!attachedDeploymentId && canDetach && (
              <Button icon="unlinkify" size="tiny" basic title="Detach deployment" onClick={() => this.attachDetachDeployment(prepaid_deployment)} />
            )}
            {canDelete && <Button icon="trash" size="tiny" basic onClick={() => this.deletePrepaidDeployment(prepaid_deployment)} />}
          </Table.Cell>
        </Table.Row>
      );
    };

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Model</Table.HeaderCell>
              <Table.HeaderCell>Region</Table.HeaderCell>
              <Table.HeaderCell>Created at</Table.HeaderCell>
              <Table.HeaderCell>Starts at</Table.HeaderCell>
              <Table.HeaderCell>Ends at</Table.HeaderCell>
              <Table.HeaderCell>Is Active</Table.HeaderCell>
              <Table.HeaderCell>Attached deployment</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {canCreate && <Button icon="plus" size="tiny" basic onClick={this.createPrepaidDeployment} />}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{_.map(this.state.items, Row)}</Table.Body>
        </Table>
        {this.state.editing && (
          <PrepaidDeploymentModal
            {...this.props}
            item={this.state.currentItem}
            organizationID={this.props.organization.id || ""}
            onClose={() => {
              this.setState({ editing: false, currentItem: undefined });
              this.reloadItems();
            }}
          />
        )}
        {this.state.attachItem && (
          <AttachDeploymentModal
            {...this.props}
            prepaidDeployment={this.state.attachItem}
            onClose={() => {
              this.setState({ attachItem: undefined });
              this.reloadItems();
            }}
          />
        )}
        {this.state.detachItem && <Confirm confirmInfo={this.state.confirmInfo} />}
        {this.state.deletingItem && <Confirm confirmInfo={this.state.confirmInfo} />}
      </ContentSegment>
    );
  }
}

export default withRefresh()(OrganizationPrepaidDeploymentsView);
