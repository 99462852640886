//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  OrganizationSupportInfo as ApiOrganizationSupportInfo,
  SetOrganizationSupportInfoRequest as ApiSetOrganizationSupportInfoRequest,
} from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";
import SupportRequestCustomer from "../support/RequestCustomer";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";

interface IJiraFieldsViewArgs extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  supportInfo: ApiOrganizationSupportInfo;
  canEditSupportInfo: boolean;
  editSupportInfoURL: boolean;
  editSupportInfoID: boolean;
  onEditSupportInfoURL: () => void;
  onEditSupportInfoID: () => void;
  onCancelEditSupportInfo: () => void;
  onChangeSupportInfoJiraUrl: (jira_url: string) => void;
  onChangeSupportInfoJiraID: (jira_id: string) => void;
  onCommitSupportInfo: () => void;
}

const JiraFieldsView = ({ ...args }: IJiraFieldsViewArgs) => {
  const has_jira_url = !!args.supportInfo.jira_url;
  const has_jira_id = !!args.supportInfo.jira_id;
  return (
    <div>
      <Field>
        <FL>JIRA URL</FL>
        <FC>
          {args.editSupportInfoURL && (
            <div>
              <Form.Field>
                <Input
                  value={args.supportInfo.jira_url || ""}
                  onChange={(e, d) => args.onChangeSupportInfoJiraUrl(d.value)}
                  placeholder="URL to this organization in JIRA"
                />
                <Button.Group icon basic size="small">
                  <Button icon="check" onClick={args.onCommitSupportInfo} />
                  <Button icon="cancel" onClick={args.onCancelEditSupportInfo} />
                </Button.Group>
              </Form.Field>
            </div>
          )}
          {!args.editSupportInfoURL && (
            <div>
              {has_jira_url && (
                <a className="text-link" href={args.supportInfo.jira_url || ""}>
                  {args.supportInfo.jira_url ? "Link To Organization" : "-"}
                </a>
              )}
              {!has_jira_url && <span>-</span>}
              {args.canEditSupportInfo && <Icon name="pencil" onClick={args.onEditSupportInfoURL} className="edit-pencil" />}
            </div>
          )}
          {
            <div>
              <SupportRequestCustomer {...args} />
            </div>
          }
        </FC>
      </Field>
      <Field>
        <FL>JIRA ID</FL>
        <FC>
          {args.editSupportInfoID && (
            <div>
              <Form.Field>
                <Input
                  value={args.supportInfo.jira_id || ""}
                  onChange={(e, d) => args.onChangeSupportInfoJiraID(d.value)}
                  placeholder="ID to this organization in JIRA"
                />
                <Button.Group icon basic size="small">
                  <Button icon="check" onClick={args.onCommitSupportInfo} />
                  <Button icon="cancel" onClick={args.onCancelEditSupportInfo} />
                </Button.Group>
              </Form.Field>
            </div>
          )}
          {!args.editSupportInfoID && (
            <div>
              {has_jira_id && <span>{args.supportInfo.jira_id || "-"}</span>}
              {!has_jira_id && <span>-</span>}
              {args.canEditSupportInfo && <Icon name="pencil" onClick={args.onEditSupportInfoID} className="edit-pencil" />}
            </div>
          )}
        </FC>
      </Field>
    </div>
  );
};

interface IJiraFieldsProps extends IOrganizationDetailsFieldProps, IWithRefreshProps, RouteComponentProps, CommentCreationArgs {}

interface IJiraFieldsState extends IOrganizationDetailsFieldState {
  supportInfo?: ApiOrganizationSupportInfo;
  processingSetSupportInfo: boolean;
  editingSupportInfoURL: boolean;
  editingSupportInfoID: boolean;
  commentPrompt: ICommentPromptStateForIntegrations;
}

class JiraFields extends OrganizationDetailsField<IJiraFieldsProps, IJiraFieldsState> {
  state: IJiraFieldsState = {
    supportInfo: undefined,
    processingSetSupportInfo: false,
    editingSupportInfoURL: false,
    editingSupportInfoID: false,
    refreshNeeded: false,
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  resetCommentPrompt = () => {
    this.setState({
      ...this.state,
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      if (this.hasPermission("internal-dashboard.organization.get-support-info")) {
        const supportInfo = await apiClients.idashboardClient.GetOrganizationSupportInfo(idOptions);
        this.setState({ supportInfo: supportInfo });
      }
    }
  };

  onChangeSupportInfoJiraUrl = (jira_url: string) => {
    this.setState((old) => {
      const supportInfo = old.supportInfo ? _.clone(old.supportInfo) : {};
      supportInfo.jira_url = jira_url;
      return { supportInfo: supportInfo };
    });
  };

  onChangeSupportInfoJiraID = (jira_id: string) => {
    this.setState((old) => {
      const supportInfo = old.supportInfo ? _.clone(old.supportInfo) : {};
      supportInfo.jira_id = jira_id;
      return { supportInfo: supportInfo };
    });
  };

  onCommitSupportInfo = async () => {
    const supportInfo = this.state.supportInfo || {};
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${supportInfo.jira_url ? "JIRA URL: " + supportInfo.jira_url + "\n" : ""}${
          supportInfo.jira_id ? "JIRA ID: " + supportInfo.jira_id : ""
        }`,
        onCancellingCommentAddition: () => {
          this.refreshOrganizationInfo();
          this.resetCommentPrompt();
        },
        loadingMessage: "Changing organization support info, please wait...",
        onCommentConfirmation: async () => {
          const supportInfo = this.state.supportInfo || {};
          const req: ApiSetOrganizationSupportInfoRequest = {
            organization_id: this.props.organization.id,
            info: {
              jira_url: supportInfo.jira_url,
              jira_id: supportInfo.jira_id,
            },
          };
          await apiClients.idashboardClient.SetOrganizationSupportInfo(req);
          this.refreshOrganizationInfo();
          this.setState({ editingSupportInfoURL: false, editingSupportInfoID: false });
        },
      },
    });
  };

  render() {
    const can_edit_support_info = this.hasPermission("internal-dashboard.organization.set-support-info");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <JiraFieldsView
          {...this}
          {...this.props}
          {...this.state}
          supportInfo={this.state.supportInfo || {}}
          editSupportInfoURL={this.state.editingSupportInfoURL}
          editSupportInfoID={this.state.editingSupportInfoID}
          canEditSupportInfo={can_edit_support_info}
          onEditSupportInfoURL={() => this.setState({ editingSupportInfoURL: true })}
          onEditSupportInfoID={() => this.setState({ editingSupportInfoID: true })}
          onCancelEditSupportInfo={() => this.setState({ editingSupportInfoURL: false, editingSupportInfoID: false })}
        />
      </div>
    );
  }
}

export default withRefresh()(JiraFields);
