//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Icon, Loader, Table, Menu } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  EmailPattern as ApiEmailPattern,
  EmailPatternList as ApiEmailPatternList,
  ListEmailPatternsRequest as ApiListEmailPatternsRequest,
  IDOptions as ApiIDOptions,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import {
  Loading,
  LoaderBoxForTable as LoaderBox,
  ContentSegment,
  SecondaryMenu,
  PagingButtons,
  ErrorMessage,
  ListActionDelete,
  ListActionEdit,
  Processing,
  Confirm,
  ConfirmInfo,
} from "../../ui/lib";

import CreateEmailPattern from "./CreateEmailPattern";
import EditEmailPattern from "./EditEmailPattern";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Pattern</Table.HeaderCell>
      <Table.HeaderCell>Behavior</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing an organization
interface IRowView {
  item: ApiEmailPattern;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <pre>{args.item.pattern}</pre>
    </Table.Cell>
    <Table.Cell>
      {}
      <Icon name={args.item.deny ? "lock" : args.item.allow ? "lock open" : "question"} />
    </Table.Cell>
    <Table.Cell>
      <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
    </Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        <ListActionEdit onClick={args.onClickEdit} />
        <ListActionDelete onClick={args.onClickDelete} />
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the organization list
interface IListView {
  items: ApiEmailPattern[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickEdit: (emailPatternId: string) => void;
  onClickDelete: (emailPatternId: string, pattern: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView
          {...args}
          key={item.id}
          item={item}
          onClickEdit={() => args.onClickEdit(item.id || "")}
          onClickDelete={() => args.onClickDelete(item.id || "", item.pattern || "")}
        />
      ))}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more email patterns" : "No email patterns found"}</div>;

// Interface describing the email patterns list view arguments
export interface IEmailPatternsListViewArgs extends RouteComponentProps {
  loading: boolean;
  patterns?: ApiEmailPatternList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onClickEdit: (emailPatternId: string) => void;
  onClickDelete: (emailPatternId: string, pattern: string) => void;
}

export const EmailPatternsListView = ({ ...args }: IEmailPatternsListViewArgs) => {
  if (!args.patterns) {
    return <Loading />;
  }
  const isEmpty = !args.patterns.items || args.patterns.items.length === 0;
  const patterns = args.patterns ? args.patterns.items : undefined;
  return (
    <div>
      <ListView {...args} items={patterns || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the email pattern list component
interface IEmailPatternListProps extends IWithRefreshProps, RouteComponentProps {}

// Interface decribing the state of the email pattern list component
interface IEmailPatternListState {
  patterns?: ApiEmailPatternList;
  page: number;
  pageSize: number;
  createVisible: boolean;
  editVisible: boolean;
  editId: string;
  deleting: boolean;
  errorMessage?: string;
  confirmDeletePattern?: ConfirmInfo;
}

// The component to show email patterns as a list.
class EmailPatternList extends Component<IEmailPatternListProps, IEmailPatternListState> {
  state: IEmailPatternListState = {
    patterns: undefined,
    page: 0,
    pageSize: 30,
    createVisible: false,
    editVisible: false,
    editId: "",
    deleting: false,
    errorMessage: undefined,
  };

  reloadEmailPatterns = async () => {
    var req: ApiListEmailPatternsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    const patterns = await apiClients.idashboardClient.ListEmailPatterns(req);
    this.setState({ patterns: patterns });
  };

  refreshEmailPatterns = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadEmailPatterns);
  };

  componentDidMount() {
    this.refreshEmailPatterns();
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshEmailPatterns
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshEmailPatterns
    );
  };

  onEmailPatternCreated = (id: string) => {
    this.setState({ createVisible: false });
    this.refreshEmailPatterns();
  };

  onEmailPatternSaved = (id: string) => {
    this.setState({
      editVisible: false,
      editId: "",
    });
    this.refreshEmailPatterns();
  };

  onClickEdit = (id: string) => {
    this.setState({
      editVisible: true,
      editId: id,
    });
  };

  onClickDelete = async (id: string, pattern: string) => {
    const confirmInfo: ConfirmInfo = {
      header: "Delete email pattern",
      content: `Do you want to remove the email pattern '${pattern}'?`,
      warning: "This will change who has access to the service!",
      onConfirm: async () => {
        this.setState({
          deleting: true,
          confirmDeletePattern: undefined,
        });
        try {
          const req: ApiIDOptions = {
            id: id,
          };
          await apiClients.idashboardClient.DeleteEmailPattern(req);
          this.refreshEmailPatterns();
        } catch (e) {
          this.setState({ errorMessage: `Email pattern saving failed: ${e}` });
        }
        this.setState({ deleting: false });
      },
      onDenied: () => {
        this.setState({ confirmDeletePattern: undefined });
      },
    };
    this.setState({
      confirmDeletePattern: confirmInfo,
    });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const patterns = this.state.patterns;
    const page = this.state.page;

    return (
      <ContentSegment>
        <Confirm confirmInfo={this.state.confirmDeletePattern} />
        <Processing active={this.state.deleting} message="Deleting email pattern, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>Email patterns</Menu.Item>
          <CreateEmailPattern
            {...this.props}
            visible={this.state.createVisible}
            onClose={() => {
              this.setState({ createVisible: false });
            }}
            onEmailPatternCreated={this.onEmailPatternCreated}
          />
          <Button icon="plus" size="tiny" basic onClick={() => this.setState({ createVisible: true })} />
          <EditEmailPattern
            {...this.props}
            id={this.state.editVisible ? this.state.editId : ""}
            visible={this.state.editVisible}
            onClose={() => {
              this.setState({ editVisible: false });
            }}
            onEmailPatternSaved={this.onEmailPatternSaved}
          />
        </SecondaryMenu>
        <EmailPatternsListView
          {...this.props}
          patterns={patterns}
          onNextPage={this.onNextPage}
          onPreviousPage={page > 0 ? this.onPreviousPage : undefined}
          page={page}
          pageSize={this.state.pageSize}
          onClickEdit={this.onClickEdit}
          onClickDelete={this.onClickDelete}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(EmailPatternList);
