//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Organization as ApiOrganization, OrganizationInfo as ApiOrganizationInfo } from "../../api/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps } from "../../util/WithRefresh";
import styled from "@emotion/styled";

export const TextAreaContainer = styled("div")`
  textarea {
    display: block;
    width: 100%;
  }
`;

export const Footnote = styled("div")`
  font-size: 80%;
`;

export interface IOrganizationDetailsFieldProps extends IWithRefreshProps {
  organization: ApiOrganization;
  organizationInfo: ApiOrganizationInfo;
  onRefreshOrganization: () => void;
  onError: (message: string) => void;
}

export interface IOrganizationDetailsFieldState {
  lastOrganizationId?: string;
  refreshNeeded: boolean;
}

class OrganizationDetailsField<TProps extends IOrganizationDetailsFieldProps, TState extends IOrganizationDetailsFieldState> extends Component<TProps, TState> {
  static getDerivedStateFromProps(props: IOrganizationDetailsFieldProps, state: IOrganizationDetailsFieldState) {
    const orgId = props.organization.id;
    if (orgId != state.lastOrganizationId) {
      return {
        refreshNeeded: true,
        lastOrganizationId: orgId,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshLocalInfo();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshLocalInfo);
    }
  }

  refreshLocalInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadOrganizationInfo);
  };

  // Refresh both local as well as parent info
  refreshOrganizationInfo = () => {
    this.refreshLocalInfo();
    this.props.onRefreshOrganization();
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadOrganizationInfo = async () => {};
}

export default OrganizationDetailsField;
