//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import { create } from "zustand";
import { Deployment, DeploymentInfo } from "../../api/lib";

interface DeploymentStoreRequiredProps {
  deployment: Deployment;
  setDeployment: (dpl: Deployment) => void;
  deploymentInfo: DeploymentInfo;
  setDeploymentInfo: (dpli: DeploymentInfo) => void;
}

export const useDeploymentStore = create<DeploymentStoreRequiredProps>((set) => ({
  deployment: {},
  setDeployment: (dpl: Deployment) => set((state) => ({ ...state, deployment: dpl })),
  deploymentInfo: {},
  setDeploymentInfo: (dpli: DeploymentInfo) => set((state) => ({ ...state, deploymentInfo: dpli })),
}));
