//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import { Moment } from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Loader, Menu, Message, Placeholder, Segment, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ExampleInstallations as ApiExampleInstallations,
  GetExampleInstallationsRequest as ApiGetExampleInstallationsRequest,
  ListExampleDatasetsRequest as ApiListExampleDatasetsRequest,
} from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  CreationDateFilter,
  ErrorMessage,
  MonthAndWeekAndYearCreationDateFilters,
  SecondaryMenu,
  momentDefaultFormat,
  momentNow,
  momentStartOfProject,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

const dateFilters = MonthAndWeekAndYearCreationDateFilters(false);

interface IFilterViewArgs {
  selectedCreationDateFilterID: string;
  creationDateFilters: CreationDateFilter[];
  onCreationDateFilterChanged: (filterID: string) => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const creationDateOptions = _.map(args.creationDateFilters, (x) => {
    return {
      key: `crd-${x.id}`,
      text: x.name,
      value: x.id,
    };
  });

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown
        item
        scrolling
        value={args.selectedCreationDateFilterID}
        text={`Date: ${getOptionText(creationDateOptions, args.selectedCreationDateFilterID) || "?"}`}
        options={creationDateOptions}
        onChange={(e, d) => args.onCreationDateFilterChanged(d.value as string)}
      />
    </Menu>
  );
};

interface IStatisticsGroupViewArgs {
  report: IInstallationReport;
}

const StatisticsGroupsView = ({ ...args }: IStatisticsGroupViewArgs) => {
  return (
    <Table.Row>
      <Table.Cell>{args.report.dataset || "Unknown"}</Table.Cell>
      <Table.Cell>{args.report.installations.installed || 0}</Table.Cell>
      <Table.Cell>{args.report.installations.uninstalled || 0}</Table.Cell>
    </Table.Row>
  );
};

interface IStatisticsViewArgs {
  expectedReports: number;
  reports: IInstallationReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
}

const StatisticsView = ({ ...args }: IStatisticsViewArgs) => {
  const filterFrom = args.filterFrom;
  const filterTo = args.filterTo;
  const timeFilter =
    !!filterFrom && !!filterTo
      ? `from ${filterFrom.format(momentDefaultFormat)} to ${filterTo.format(momentDefaultFormat)}`
      : !!filterFrom
      ? `from ${filterFrom.format(momentDefaultFormat)}`
      : !!filterTo
      ? `to ${filterTo.format(momentDefaultFormat)}`
      : "from all time";
  const loading = args.expectedReports > args.reports.length;
  return (
    <div>
      <Message>
        Example dataset installs/uninstalls {timeFilter}.{loading && <Loader inline active size="mini" />}
      </Message>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Dataset</Table.HeaderCell>
            <Table.HeaderCell>Installed in period</Table.HeaderCell>
            <Table.HeaderCell>Uninstalled in period</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {args.reports.map((x) => (
            <StatisticsGroupsView {...args} report={x} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const PlaceholderView = () => (
  <Segment>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

export interface IInstallationStatisticsViewArgs extends IFilterViewArgs, RouteComponentProps {
  expectedReports: number;
  reports?: IInstallationReport[];
  filterFrom?: Moment;
  filterTo?: Moment;
}

export const InstallationStatisticsView = ({ ...args }: IInstallationStatisticsViewArgs) => {
  const reports = args.reports || [];
  const has_reports = !_.isEmpty(reports);
  return (
    <div>
      <FilterView {...args} />
      {has_reports && <StatisticsView {...args} reports={reports} />}
      {!has_reports && <PlaceholderView />}
    </div>
  );
};

interface IExampleInstallationsReportingProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
}

interface IInstallationReport {
  dataset: string;
  installations: ApiExampleInstallations;
}

interface IExampleInstallationReportingState {
  sequence: number;
  errorMessage?: string;
  filterFrom?: Moment;
  filterTo?: Moment;
  selectedCreationDateFilterID: string;
  reports?: IInstallationReport[];
}

class ExampleInstallationsReporting extends Component<IExampleInstallationsReportingProps, IExampleInstallationReportingState> {
  state: IExampleInstallationReportingState = {
    sequence: 0,
    errorMessage: undefined,
    filterFrom: undefined,
    filterTo: undefined,
    selectedCreationDateFilterID: dateFilters[0].id,
    reports: undefined,
  };

  reloadStatistics = async () => {
    this.setState(
      (old) => {
        return {
          sequence: old.sequence + 1,
          reports: undefined,
        };
      },
      () => {
        const sequence = this.state.sequence;
        let filterFrom = momentStartOfProject();
        let filterTo = momentNow();

        const f = dateFilters.find((x) => x.id == this.state.selectedCreationDateFilterID);
        if (f) {
          if (f.filterStart) {
            filterFrom = f.filterStart(momentNow().add(-f.created_from_hours, "hours"));
          }
          if (f.filterEnd) {
            filterTo = f.filterEnd(momentNow().add(-f.created_to_hours, "hours"));
          }
        }

        const loadReports = async () => {
          const req: ApiListExampleDatasetsRequest = {
            organization_id: "_support",
          };
          const list = await apiClients.idashboardClient.ListAllExampleDatasets(req);
          const reports = [] as IInstallationReport[];
          if (list && list.items) {
            for (const x of list.items) {
              if (x.id) {
                const report = await this.loadStatistics(x.id, filterFrom, filterTo);
                reports.push(report);
              }
            }
          }
          // Unknown dataset (for older event data)
          const report = await this.loadStatistics("", filterFrom, filterTo);
          report.dataset = "Total";
          reports.push(report);
          this.setState((old) => {
            return old.sequence == sequence ? { reports: reports } : {};
          });
        };
        loadReports();
        this.setState({
          filterFrom: filterFrom,
          filterTo: filterTo,
        });
      }
    );
  };

  loadStatistics = async (dataset: string, filterFrom: Moment, filterTo: Moment) => {
    const req: ApiGetExampleInstallationsRequest = {
      from: filterFrom.toDate(),
      to: filterTo.toDate(),
      example_dataset_id: dataset,
    };

    const installs = await new Promise<ApiExampleInstallations>(async (resolve, reject) => {
      try {
        await apiClients.idashboardClient.GetExampleInstallations(req, (x) => {
          if (!!x.message) resolve(x.message);
          else if (!!x.error) {
            reject(x.error);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
    const result: IInstallationReport = {
      dataset: dataset,
      installations: installs,
    };
    return result;
  };

  refreshStatistics = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadStatistics);
  };

  componentDidMount() {
    this.refreshStatistics();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCreationDateFilterChanged = (id: string) => {
    this.setState(
      {
        selectedCreationDateFilterID: id,
        reports: undefined,
      },
      this.refreshStatistics
    );
  };

  render() {
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Example dataset installs/uninstalls</Menu.Item>
        </SecondaryMenu>
        <InstallationStatisticsView
          {...this.props}
          {...this.state}
          expectedReports={3}
          reports={_.isEmpty(this.state.reports) ? undefined : this.state.reports}
          creationDateFilters={dateFilters}
          onCreationDateFilterChanged={this.onCreationDateFilterChanged}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(ExampleInstallationsReporting);
