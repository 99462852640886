//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import { RouteComponentProps } from "react-router-dom";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { SetOrganizationCostCenterRequest as ApiSetOrganizationCostCenterRequest } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import OrganizationDetailsField, { IOrganizationDetailsFieldProps, IOrganizationDetailsFieldState } from "./OrganizationDetailsField";
import CommentsPrompt from "../comments/CommentsPrompt";
import { CommentCreationArgs, ICommentPromptStateForIntegrations } from "../comments/CommentTypes";

interface ICostCenterFieldViewArgs extends IWithRefreshProps, RouteComponentProps {
  costCenter: string;
  editingCostCenter: boolean;
  canEditCostCenter: boolean;
  onEditCostCenter: () => void;
  onCancelEditCostCenter: () => void;
  onChangeCostCenter: (costCenter: string) => void;
  onCommitCostCenter: () => void;
}

const CostCenterFieldView = ({ ...args }: ICostCenterFieldViewArgs) => {
  return (
    <Field>
      <FL>Cost Center</FL>
      <FC>
        {args.editingCostCenter && (
          <div>
            <Form.Field>
              <Input value={args.costCenter || ""} onChange={(e, d) => args.onChangeCostCenter(d.value)} placeholder="Cost center" />
              <Button.Group icon basic size="small">
                <Button icon="check" onClick={args.onCommitCostCenter} />
                <Button icon="cancel" onClick={args.onCancelEditCostCenter} />
              </Button.Group>
            </Form.Field>
          </div>
        )}
        {!args.editingCostCenter && (
          <div>
            {args.costCenter}
            {args.canEditCostCenter && <Icon name="pencil" onClick={args.onEditCostCenter} className="edit-pencil" />}
          </div>
        )}
      </FC>
    </Field>
  );
};

interface ICostCenterFieldProps extends IOrganizationDetailsFieldProps, IWithRefreshProps, RouteComponentProps, CommentCreationArgs {}

interface ICostCenterFieldState extends IOrganizationDetailsFieldState {
  processingSetCostCenter: boolean;
  editingCostCenter: boolean;
  costCenter: string;
  commentPrompt: ICommentPromptStateForIntegrations;
}

class CostCenterField extends OrganizationDetailsField<ICostCenterFieldProps, ICostCenterFieldState> {
  state: ICostCenterFieldState = {
    processingSetCostCenter: false,
    editingCostCenter: false,
    refreshNeeded: false,
    costCenter: "",
    commentPrompt: {
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    },
  };

  resetCommentPrompt = () => {
    this.setState({
      ...this.state,
      commentPrompt: {
        showCommentsPrompt: false,
        defaultCommentOnEvent: "",
        onCommentConfirmation: async () => {},
        onCancellingCommentAddition: () => {},
        loadingMessage: "",
      },
    });
  };

  onChangeCostCenter = (costCenter: string) => {
    this.setState({ costCenter: costCenter });
  };

  onCommitCostCenter = async () => {
    this.setState({
      commentPrompt: {
        showCommentsPrompt: true,
        defaultCommentOnEvent: `${"Cost center: " + this.state.costCenter}`,
        onCancellingCommentAddition: () => {
          this.refreshOrganizationInfo();
          this.resetCommentPrompt();
        },
        loadingMessage: "Changing organization cost center, please wait...",
        onCommentConfirmation: async () => {
          const req: ApiSetOrganizationCostCenterRequest = {
            organization_id: this.props.organization.id,
            cost_center: this.state.costCenter,
          };
          await apiClients.idashboardClient.SetOrganizationCostCenter(req);
          this.refreshOrganizationInfo();
          this.setState({ editingCostCenter: false });
        },
      },
    });
  };

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organization.id;
    if (organizationId) {
      this.setState({ costCenter: this.props.organizationInfo.cost_center || "" });
    }
  };

  render() {
    const canEditCostCenter = this.hasPermission("internal-dashboard.organization.set-cost-center");

    return (
      <div>
        {!!this.state.commentPrompt.showCommentsPrompt && (
          <CommentsPrompt
            commentPrompt={{
              ...this.state.commentPrompt,
              handleAddComment: this.props.createComment,
              onClose: this.resetCommentPrompt,
            }}
          />
        )}
        <CostCenterFieldView
          {...this}
          {...this.props}
          {...this.state}
          canEditCostCenter={canEditCostCenter}
          onEditCostCenter={() => this.setState({ editingCostCenter: true })}
          onCancelEditCostCenter={() => this.setState({ editingCostCenter: false })}
        />
      </div>
    );
  }
}

export default withRefresh()(CostCenterField);
