//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Tomasz Mielech
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { RebalanceDeploymentShardsRequest as ApiRebalanceDeploymentShardsRequest } from "../../api/lib";
import { ContentActionButton, ErrorMessage } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface IRebalanceShardsModalViewArgs {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  errorMessage?: string;
}

const RebalanceShardsModalView = ({ ...args }: IRebalanceShardsModalViewArgs) => {
  return (
    <Modal open={args.open} onClose={args.onClose}>
      <Modal.Content>
        {args.loading && (
          <Dimmer inverted active>
            <Loader active inverted>
              Starting rebalancing job, please wait
            </Loader>
          </Dimmer>
        )}
        <ErrorMessage active={!!args.errorMessage} header="Rebalancing shards" message={args.errorMessage} />
        {!args.loading && !args.errorMessage && (
          <span>
            <i>The rebalancing job has been started</i>
          </span>
        )}
      </Modal.Content>
    </Modal>
  );
};

interface IRebalanceShardsModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
}

interface IRebalanceShardsModalState {
  open: boolean;
  loading: boolean;
  errorMessage?: string;
}

// Component to rebalance shards for a deployment
class RebalanceShardsModal extends Component<IRebalanceShardsModalProps, IRebalanceShardsModalState> {
  state: IRebalanceShardsModalState = {
    open: false,
    loading: false,
    errorMessage: undefined,
  };

  rebalanceShards = async () => {
    this.setState({ loading: true, errorMessage: undefined });
    try {
      const req: ApiRebalanceDeploymentShardsRequest = {
        deployment_id: this.props.deploymentId,
      };
      await apiClients.idashboardClient.RebalanceDeploymentShards(req);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true }, this.rebalanceShards);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Rebalance shards" icon="exchange" />
        <RebalanceShardsModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(RebalanceShardsModal);
