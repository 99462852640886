//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import { numberFormat } from "humanize";
import React from "react";
import { Popup, Table } from "semantic-ui-react";
import { humanizeFileSize } from "../../util/FileSize";

export interface IMemoryUsageViewArgs {
  limit: number;
  used: number;
}

export const MemoryUsageView = ({ ...args }: IMemoryUsageViewArgs) => {
  if (args.limit === 0) {
    return <span>{humanizeFileSize(args.used)}</span>;
  }
  return (
    <Popup
      position="right center"
      trigger={<span>{numberFormat(((args.used || 0) * 100) / args.limit, 1)}%</span>}
      content={
        <Table basic="very" singleLine compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Total:</Table.Cell>
              <Table.Cell textAlign="right">{humanizeFileSize(args.limit)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Used:</Table.Cell>
              <Table.Cell textAlign="right">{humanizeFileSize(args.used)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Available:</Table.Cell>
              <Table.Cell textAlign="right">{humanizeFileSize(args.limit - args.used)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      }
    />
  );
};
