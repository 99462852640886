//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//
import styled from "@emotion/styled";
import { getData } from "country-list";
import React, { useState } from "react";
import { Form, Grid, Icon, Modal, Popup } from "semantic-ui-react";
import { usaStates } from "typed-usa-states";
import apiClients from "../../api/apiclients";
import { Address as ApiAddress } from "../../api/lib";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { ErrorMessage, InlineDropdown } from "../../ui/lib";
import { withRefresh } from "../../util/WithRefresh";

const usaStateOptions = usaStates.map((entry) => ({
  key: entry.abbreviation,
  text: entry.name,
  value: entry.abbreviation,
}));
const StretchedTextArea = styled(Form.TextArea)`
  display: inline-flex !important;
  flex-direction: column !important;
  align-items: stretch !important;
  textarea {
    flex-grow: 1;
  }
`;

const AttachedFooterRow = styled(Grid.Row)`
  padding-top: 0 !important;
`;

const countryOptions = getData().map((entry) => ({
  key: entry.code,
  text: entry.name,
  value: entry.code,
  flag: entry.code.toLowerCase(),
}));

type IEditAddressViewArgs = {
  updatedAddress: ApiAddress;
  handleChange: (changedAddress: ApiAddress) => void;
};
const EditAddressView = ({ updatedAddress, handleChange }: IEditAddressViewArgs) => {
  const addressLines = updatedAddress.address || [];
  const is_us = updatedAddress.country_code === "US";
  const [countryEditingEnabledOverride, setCountryEditingEnabledOverride] = useState<boolean | undefined>(false);
  return (
    <Form>
      <Grid width="16">
        <Grid.Row>
          <Grid.Column width="16">
            <h3>Address</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="5" stretched>
            <StretchedTextArea
              autoFocus
              required
              label="Address"
              placeholder={`Address line 1, ...`}
              name="address_lines"
              value={addressLines.join("\n")}
              onChange={(_, data) => {
                const value = `${data.value || ""}`;
                handleChange({
                  ...updatedAddress,
                  address: value.split("\n"),
                });
              }}
              rows="2"
            />
          </Grid.Column>
          <Grid.Column width="4">
            <Form.Input
              required
              label="City"
              placeholder={`City (e.g. "New York")`}
              name="city"
              value={updatedAddress.city}
              onChange={(_, data) => {
                handleChange({
                  ...updatedAddress,
                  city: data.value,
                });
              }}
            />
            <Popup
              content="WARNING! Editing country will have tax and invoicing effects. This will probably lead to administrative problems. A better way is to ask the customer to do this through the public dashboard."
              trigger={
                <Form.Field required>
                  <label>Country</label>
                  <InlineDropdown
                    required
                    selection
                    fluid
                    search
                    label="Country"
                    placeholder="Select your country"
                    value={updatedAddress.country_code}
                    options={countryOptions}
                    disabled={!countryEditingEnabledOverride}
                    onChange={(_: any, data: { value: string }) => {
                      handleChange({
                        ...updatedAddress,
                        country_code: data.value,
                      });
                    }}
                  />
                </Form.Field>
              }
            />
          </Grid.Column>
          <Grid.Column width="4">
            {!is_us && (
              <Form.Input
                label="State"
                placeholder={`State`}
                name="state"
                value={updatedAddress.state}
                onChange={(_, data) => {
                  handleChange({
                    ...updatedAddress,
                    state: data.value,
                  });
                }}
              />
            )}
            {is_us && (
              <Form.Field required>
                <label>State</label>
                <InlineDropdown
                  required
                  selection
                  fluid
                  search
                  label="State"
                  placeholder="Select your state"
                  value={updatedAddress.state}
                  options={usaStateOptions}
                  onChange={(_: any, data: { value: string }) => {
                    handleChange({
                      ...updatedAddress,
                      state: data.value,
                    });
                  }}
                />
              </Form.Field>
            )}
            <div style={{ paddingTop: 32 }}>
              <Form.Checkbox
                label="Enable country editing"
                checked={countryEditingEnabledOverride}
                onChange={(_, data) => {
                  setCountryEditingEnabledOverride(data.checked);
                }}
              />
            </div>
          </Grid.Column>
          <Grid.Column width="3">
            <Form.Input
              label="ZIP code"
              placeholder={`ZIP code`}
              name="zipcode"
              value={updatedAddress.zipcode}
              onChange={(_, data) => {
                handleChange({
                  ...updatedAddress,
                  zipcode: data.value,
                });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <AttachedFooterRow>
          <Grid.Column width="16">
            <div>Changes to the address only appear on future invoices.</div>
          </Grid.Column>
        </AttachedFooterRow>
      </Grid>
    </Form>
  );
};

interface IBillingAddressEditorProps {
  address: ApiAddress;
  organization_id: string | undefined;
  onCancel: () => void;
  onSuccess: () => void;
}
const BillingAddressEditor = ({ address, onCancel, onSuccess, organization_id }: IBillingAddressEditorProps) => {
  const [updatedAddress, setUpdatedAddress] = React.useState(address);
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleChange = (changedAddress: ApiAddress) => {
    setUpdatedAddress(changedAddress);
  };
  const onSave = async () => {
    try {
      const req = {
        organization_id,
        billing_address: updatedAddress,
      };
      await apiClients.idashboardClient.UpdateBillingAddress(req);
      onSuccess();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };
  return (
    <Modal open={true} size="large">
      <Modal.Header>
        <Icon name="euro" />
        Billing details
      </Modal.Header>
      <Modal.Content>
        <ErrorMessage
          active={!!errorMessage}
          onDismiss={() => {
            setErrorMessage("");
          }}
          message={errorMessage}
        />
        <EditAddressView updatedAddress={updatedAddress} handleChange={handleChange} />
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onCancel} />
        <FormActionButtonSave onClick={onSave} primary />
      </Modal.Actions>
    </Modal>
  );
};

export default withRefresh()(BillingAddressEditor);
