//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Form, Message, Modal, Grid, TextArea } from "semantic-ui-react";
import { IDOptions as ApiIDOptions, Subject as ApiSubject, Organization as ApiOrganization } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/lib";
import { isEmpty, filter, toString } from "lodash";

interface ISelectionViewArgs extends IWithRefreshProps, RouteComponentProps {
  organizationIDs: string;
  organizations?: ApiOrganization[];
  onOrganizationIDsChanged: (ids: string) => void;
  errorMessage?: string;
}

const SelectionView = ({ ...args }: ISelectionViewArgs) => {
  const organizations = args.organizations || [];
  const orgNames = organizations.map((x) => x.name || "?").join("\n");
  const hasError = !!args.errorMessage;
  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column width="8">
            <Form.Field required>
              <label>Organization IDs (one per line)</label>
              <TextArea autoFocus rows={20} value={args.organizationIDs} onChange={(e, d) => args.onOrganizationIDsChanged(toString(d.value))} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="8">
            {hasError && (
              <Message error style={{ display: "block" }}>
                {args.errorMessage}
              </Message>
            )}
            <Form.Field>
              <label>Organization names</label>
              <TextArea rows={20} value={orgNames} disabled />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

interface IAPI {
  GetOrganization: (req: ApiIDOptions) => Promise<ApiOrganization>;
}

interface ISelectPricingPlanAssignmentSubjectsProps extends IWithRefreshProps, RouteComponentProps {
  api: IAPI;
  onClose: () => void;
  onSelectedSubjects: (subjects: ApiSubject[]) => void;
}

interface ISelectPricingPlanAssignmentSubjectsState {
  organizationIDs: string;
  organizations?: ApiOrganization[];
  errorMessage?: string;
}

// The component to select a subject for a pricing plan assignment
class SelectPricingPlanAssignmentSubjects extends Component<ISelectPricingPlanAssignmentSubjectsProps, ISelectPricingPlanAssignmentSubjectsState> {
  state: ISelectPricingPlanAssignmentSubjectsState = {
    organizationIDs: "",
    organizations: undefined,
    errorMessage: undefined,
  };

  reloadOrganizationInfos = async () => {
    try {
      const ids = filter(this.state.organizationIDs.split("\n"), (x) => !isEmpty(x));
      const orgs = await Promise.all(
        ids.map(async (id) => {
          return await this.props.api.GetOrganization({ id: id });
        })
      );
      this.setState({
        organizations: orgs,
        errorMessage: undefined,
      });
    } catch (e) {
      this.setState({
        organizations: undefined,
        errorMessage: `Failed to load organizations: ${e}`,
      });
    }
  };

  onOrganizationIDsChanged = (ids: string) => {
    this.setState(
      {
        organizationIDs: ids,
      },
      this.reloadOrganizationInfos
    );
  };

  onSave = () => {
    const ids = filter(this.state.organizationIDs.split("\n"), (x) => !isEmpty(x));
    const subjects: ApiSubject[] = ids.map((id) => {
      return {
        organization_id: id,
      };
    });
    this.props.onSelectedSubjects(subjects);
  };

  render() {
    const hasIDs = !isEmpty(this.state.organizationIDs);
    return (
      <Modal open>
        <Modal.Header>Select organizations to assign pricing plan to</Modal.Header>
        <Modal.Content>
          <SelectionView {...this.props} {...this.state} onOrganizationIDsChanged={this.onOrganizationIDsChanged} />
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel onClick={this.props.onClose} />
          <FormActionButtonSave primary onClick={this.onSave} disabled={!hasIDs} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRefresh()(SelectPricingPlanAssignmentSubjects);
