//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Checkbox, Icon, Modal, Menu, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Change as ApiChange, ChangeList as ApiChangeList, ListChangesRequest as ApiListChangesRequest } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { ContentSegment, SecondaryMenu, Loading, ErrorMessage } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import _ from "lodash";
import ReactJson from "react-json-view";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import styled from "@emotion/styled";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import moment from "moment";

var stringify = require("@aitodotai/json-stringify-pretty-compact");

const FloatRight = styled("span")`
  float: right;
`;

interface IModalChangeView {
  item: ApiChange;
}

const ModalChangeView = ({ ...args }: IModalChangeView) => {
  return (
    <Modal
      trigger={
        <Button basic size="tiny">
          View
        </Button>
      }
      centered
      size="fullscreen"
    >
      <Modal.Header>
        Deployment JSON at
        <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <ReactJson src={JSON.parse(args.item.json || "{}")} collapsed={2} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

interface IModalChangeDiffView {
  title: string;
  label: any;
  oldItem: ApiChange;
  newItem: ApiChange;
}

const ModalChangeDiffView = ({ ...args }: IModalChangeDiffView) => {
  const [splitView, setSplitView] = React.useState(false);
  const [excludeStatus, setExcludeStatus] = React.useState(true);
  const opts = {
    indent: 4,
  };
  let oldObj = JSON.parse(args.oldItem.json || "{}");
  let newObj = JSON.parse(args.newItem.json || "{}");
  if (excludeStatus) {
    oldObj = _.omit(oldObj, ["deployment.status"]);
    newObj = _.omit(newObj, ["deployment.status"]);
  }
  const oldValue = stringify(oldObj, opts);
  const newValue = stringify(newObj, opts);
  return (
    <Modal trigger={<u>{args.label}</u>} centered size="fullscreen">
      <Modal.Header>
        <FloatRight>
          <Checkbox label="Spec only" checked={excludeStatus} onClick={() => setExcludeStatus(!excludeStatus)} />
          <Checkbox label="Split" checked={splitView} onClick={() => setSplitView(!splitView)} />
        </FloatRight>
        {args.title}
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <ReactDiffViewer splitView={splitView} oldValue={oldValue} newValue={newValue} compareMethod={DiffMethod.WORDS} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>JSON</Table.HeaderCell>
      <Table.HeaderCell>Removed</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>By</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiChange;
  previous?: ApiChange;
  current?: ApiChange;
}

const RowView = ({ ...args }: IRowView) => {
  const hasPrevious = !!args.previous;
  const hasCurrent = !!args.current;
  return (
    <Table.Row>
      <Table.Cell>{args.item.id}</Table.Cell>
      <Table.Cell>
        <ModalChangeView {...args} />{" "}
        {hasPrevious && (
          <ModalChangeDiffView
            title="Changes from previous version"
            label={
              <Button basic size="tiny">
                Compare to <Icon name="arrow down" />
              </Button>
            }
            oldItem={args.previous || {}}
            newItem={args.item}
          />
        )}
        {hasCurrent && (
          <ModalChangeDiffView
            title="Changes from current version"
            label={
              <Button basic size="tiny">
                Compare to current
              </Button>
            }
            oldItem={args.current || {}}
            newItem={args.item}
          />
        )}
      </Table.Cell>
      <Table.Cell>{args.item.removed ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>{args.item.created_by_id || "?"}</Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  items: ApiChange[];
  loading: boolean;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item, index) => (
        <RowView
          {...args}
          key={item.id}
          item={item}
          previous={index + 1 <= args.items.length ? args.items[index + 1] : undefined}
          current={index > 0 ? args.items[0] : undefined}
        />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No changes</div>;

export interface IChangelogListViewArgs extends RouteComponentProps {
  loading: boolean;
  changes?: ApiChangeList;
}

export const ChangelogListView = ({ ...args }: IChangelogListViewArgs) => {
  if (!args.changes) {
    return <Loading />;
  }
  const items = args.changes.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={_.orderBy(items, (x) => -moment(x.created_at).unix())} loading={args.loading} />;
};

interface IChangelogListProps extends IWithRefreshProps, RouteComponentProps {
  showHeader?: boolean;
  url: string;
}

interface IChangelogListState {
  errorMessage?: string;
  processing: boolean;
  changes?: ApiChangeList;
}

// The component to show the a changelog.
class ChangelogList extends Component<IChangelogListProps, IChangelogListState> {
  state: IChangelogListState = {
    errorMessage: undefined,
    processing: false,
    changes: undefined,
  };

  reloadChanges = async () => {
    const req: ApiListChangesRequest = { url: this.props.url };
    const changes = await apiClients.idashboardClient.ListChanges(req);
    this.setState({ changes: changes });
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadChanges, this.props.url);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const showHeader = !!this.props.showHeader;
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        {showHeader && (
          <SecondaryMenu>
            <Menu.Item header>Changes</Menu.Item>
          </SecondaryMenu>
        )}
        <ChangelogListView {...this.props} {...this.state} />
      </ContentSegment>
    );
  }
}

export default withRefresh()(ChangelogList);
