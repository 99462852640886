//
// DISCLAIMER
//
// Copyright 2019-2024 ArangoDB GmbH, Cologne, Germany
//

import { ResourceType } from "./ResourceType";

export type Permission =
  | "backup.backup.create"
  | "backup.backup.delete"
  | "backup.backup.download"
  | "backup.backup.get"
  | "backup.backup.list"
  | "backup.backup.restore"
  | "backup.backup.update"
  | "backup.backuppolicy.create"
  | "backup.backuppolicy.delete"
  | "backup.backuppolicy.get"
  | "backup.backuppolicy.list"
  | "backup.backuppolicy.update"
  | "billing.config.get"
  | "billing.config.set"
  | "billing.invoice.get"
  | "billing.invoice.list"
  | "billing.paymentmethod.create"
  | "billing.paymentmethod.delete"
  | "billing.paymentmethod.get-default"
  | "billing.paymentmethod.get"
  | "billing.paymentmethod.list"
  | "billing.paymentmethod.set-default"
  | "billing.paymentmethod.update"
  | "billing.paymentprovider.list"
  | "crypto.cacertificate.create"
  | "crypto.cacertificate.delete"
  | "crypto.cacertificate.get"
  | "crypto.cacertificate.list"
  | "crypto.cacertificate.set-default"
  | "crypto.cacertificate.update"
  | "data.deployment.create-test-database"
  | "data.deployment.create"
  | "data.deployment.delete"
  | "data.deployment.get"
  | "data.deployment.list"
  | "data.deployment.rebalance-shards"
  | "data.deployment.restore-backup"
  | "data.deployment.resume"
  | "data.deployment.rotate-server"
  | "data.deployment.update"
  | "data.deploymentcredentials.get"
  | "data.limits.get"
  | "data.nodesize.list"
  | "data.presets.list"
  | "example.exampledatasetinstallation.create"
  | "example.exampledatasetinstallation.delete"
  | "iam.group.create"
  | "iam.group.delete"
  | "iam.group.get"
  | "iam.group.list"
  | "iam.group.update"
  | "iam.policy.get"
  | "iam.policy.update"
  | "iam.role.create"
  | "iam.role.delete"
  | "iam.role.get"
  | "iam.role.list"
  | "iam.role.update"
  | "internal-dashboard.apicompat.get"
  | "internal-dashboard.auditlog.delete"
  | "internal-dashboard.auditlog.get"
  | "internal-dashboard.auditlog.list"
  | "internal-dashboard.auditlogarchive.delete"
  | "internal-dashboard.auditlogarchive.get"
  | "internal-dashboard.auditlogarchive.list"
  | "internal-dashboard.backup.copy"
  | "internal-dashboard.backup.create"
  | "internal-dashboard.backup.delete"
  | "internal-dashboard.backup.download"
  | "internal-dashboard.backup.list"
  | "internal-dashboard.backup.manage"
  | "internal-dashboard.backup.restore"
  | "internal-dashboard.backup.update"
  | "internal-dashboard.backuppolicy.list"
  | "internal-dashboard.backuppolicy.manage"
  | "internal-dashboard.backuppolicy.update-allow-inconsistent"
  | "internal-dashboard.backuppolicy.update-upload-incremental"
  | "internal-dashboard.cacertificate.get"
  | "internal-dashboard.change.list"
  | "internal-dashboard.comment.create"
  | "internal-dashboard.comment.list"
  | "internal-dashboard.controlplanestatus.get"
  | "internal-dashboard.conversionrate.list"
  | "internal-dashboard.conversionrate.set"
  | "internal-dashboard.coredump.list"
  | "internal-dashboard.credit.create"
  | "internal-dashboard.credit.discard-usage"
  | "internal-dashboard.credit.list-usage"
  | "internal-dashboard.credit.list"
  | "internal-dashboard.creditbundle.create"
  | "internal-dashboard.creditbundle.update"
  | "internal-dashboard.creditbundle.delete"
  | "internal-dashboard.creditbundle.list"
  | "internal-dashboard.creditbundle.set-lock"
  | "internal-dashboard.credit.set-exhausted"
  | "internal-dashboard.currency.get-default-system"
  | "internal-dashboard.currency.get-default"
  | "internal-dashboard.currency.get"
  | "internal-dashboard.currency.list"
  | "internal-dashboard.customer-changes.get"
  | "internal-dashboard.datacluster.get-credentials"
  | "internal-dashboard.datacluster.get-optimization-status"
  | "internal-dashboard.datacluster.get"
  | "internal-dashboard.datacluster.list"
  | "internal-dashboard.datacluster.manage"
  | "internal-dashboard.datacluster.start-optimization"
  | "internal-dashboard.datacluster.stop-optimization"
  | "internal-dashboard.datacluster.update-core-dump"
  | "internal-dashboard.datacluster.update-disk-sizes"
  | "internal-dashboard.datacluster.update-volume-infos"
  | "internal-dashboard.dataclusterassignment.update-provision-hash"
  | "internal-dashboard.dataprocessingaddendum.create"
  | "internal-dashboard.dataprocessingaddendum.get"
  | "internal-dashboard.dataprocessingaddendum.list"
  | "internal-dashboard.dataprocessingaddendum.set-current"
  | "internal-dashboard.dataprocessingaddendum.update"
  | "internal-dashboard.dcupdate.get-status"
  | "internal-dashboard.dcupdate.start"
  | "internal-dashboard.dcupdate.stop"
  | "internal-dashboard.deployment-changes.get"
  | "internal-dashboard.deployment.clone-from-backup"
  | "internal-dashboard.deployment.delete"
  | "internal-dashboard.deployment.get-kubernetes-resource"
  | "internal-dashboard.deployment.get"
  | "internal-dashboard.deployment.list"
  | "internal-dashboard.deployment.manage"
  | "internal-dashboard.deployment.opt-out-expiration"
  | "internal-dashboard.deployment.redelete"
  | "internal-dashboard.deployment.rotate-server-with-force"
  | "internal-dashboard.deployment.rotate-server"
  | "internal-dashboard.deployment.set-custom-notebook-image"
  | "internal-dashboard.deployment.set-expiration-date"
  | "internal-dashboard.deployment.set-kube-arangodb-additional-command-line-arguments"
  | "internal-dashboard.deployment.set-public-backup-disabled"
  | "internal-dashboard.deployment.set-scheduling-policies-enabled"
  | "internal-dashboard.deployment.set-scheduling-policies"
  | "internal-dashboard.deployment.update-agency-maintenance-mode"
  | "internal-dashboard.deployment.update-agency-resources-factor"
  | "internal-dashboard.deployment.update-allow-traffic"
  | "internal-dashboard.deployment.update-annotations"
  | "internal-dashboard.deployment.update-communication-method"
  | "internal-dashboard.deployment.update-cpu-factors"
  | "internal-dashboard.deployment.update-cpu-ratio"
  | "internal-dashboard.deployment.update-custom-command-line-arguments"
  | "internal-dashboard.deployment.update-disk-size-ignore-maximum-size"
  | "internal-dashboard.deployment.update-disk-size"
  | "internal-dashboard.deployment.update-endpoint-allowlists"
  | "internal-dashboard.deployment.update-envoy-access-log"
  | "internal-dashboard.deployment.update-foxx-authentication"
  | "internal-dashboard.deployment.update-hibernation-mode"
  | "internal-dashboard.deployment.update-vst-enabled"
  | "internal-dashboard.deployment.update-kube-arangodb"
  | "internal-dashboard.deployment.update-kubernetes-resource-patches"
  | "internal-dashboard.deployment.update-loadbalancer"
  | "internal-dashboard.deployment.update-maintenance-mode"
  | "internal-dashboard.deployment.update-memory-factors"
  | "internal-dashboard.deployment.update-memory-ratio"
  | "internal-dashboard.deployment.update-memory-reserves"
  | "internal-dashboard.deployment.update-operator-maintenance-mode"
  | "internal-dashboard.deployment.update-replication"
  | "internal-dashboard.deployment.update-support-plan"
  | "internal-dashboard.deployment.update-suppress-alerts"
  | "internal-dashboard.deployment.update-topology-awareness"
  | "internal-dashboard.deployment.update-use-shard-rebalancer"
  | "internal-dashboard.deployment.update-version"
  | "internal-dashboard.deployment.update-volume-infos"
  | "internal-dashboard.deployment.update-use-jetstream-for-auditlogs"
  | "internal-dashboard.deploymentcredentials.get"
  | "internal-dashboard.deploymentcredentials.rotate"
  | "internal-dashboard.deploymentprofile.create"
  | "internal-dashboard.deploymentprofile.delete"
  | "internal-dashboard.deploymentprofile.get"
  | "internal-dashboard.deploymentprofile.list"
  | "internal-dashboard.deploymentprofile.update"
  | "internal-dashboard.deploymentreplication.get"
  | "internal-dashboard.deploymentreplication.update"
  | "internal-dashboard.deployments-cost.get"
  | "internal-dashboard.deploymentupdatejob.get"
  | "internal-dashboard.emailmessage.list"
  | "internal-dashboard.emailpattern.create"
  | "internal-dashboard.emailpattern.delete"
  | "internal-dashboard.emailpattern.get"
  | "internal-dashboard.emailpattern.list"
  | "internal-dashboard.emailpattern.update"
  | "internal-dashboard.entity.get"
  | "internal-dashboard.entity.list"
  | "internal-dashboard.example-installations.get"
  | "internal-dashboard.exampledatasetinstallation.list"
  | "internal-dashboard.featureflag.get"
  | "internal-dashboard.featureflag.update"
  | "internal-dashboard.invoice.calculate"
  | "internal-dashboard.invoice.cancel"
  | "internal-dashboard.invoice.complete"
  | "internal-dashboard.invoice.get"
  | "internal-dashboard.invoice.list"
  | "internal-dashboard.invoice.rebuild"
  | "internal-dashboard.invoice.set-non-collectible"
  | "internal-dashboard.invoice.update-status"
  | "internal-dashboard.invoice.update"
  | "internal-dashboard.invoice.verify"
  | "internal-dashboard.loadbalancer.list"
  | "internal-dashboard.loadbalancer.update"
  | "internal-dashboard.loadbalancerassignment.list"
  | "internal-dashboard.metricstoken.list"
  | "internal-dashboard.mlservices.get"
  | "internal-dashboard.mlservices.set-allow-gpu-workloads"
  | "internal-dashboard.mlservices.set-jobs-resource-limits"
  | "internal-dashboard.mlservices.set-ml-service-enabled"
  | "internal-dashboard.nodepool.scale"
  | "internal-dashboard.nodepool.list"
  | "internal-dashboard.notebook.list"
  | "internal-dashboard.notebook.set-custom-image"
  | "internal-dashboard.notebook.set-maintenance-mode"
  | "internal-dashboard.organization.get-entity"
  | "internal-dashboard.organization.get-excluded-from-billing"
  | "internal-dashboard.organization.get-iamprovider-limits"
  | "internal-dashboard.organization.get-info"
  | "internal-dashboard.organization.get-members"
  | "internal-dashboard.organization.get-sales-info"
  | "internal-dashboard.organization.get-support-info"
  | "internal-dashboard.organization.get-uses-purchase-orders"
  | "internal-dashboard.organization.get"
  | "internal-dashboard.organization.list"
  | "internal-dashboard.organization.manage-excluded-from-billing"
  | "internal-dashboard.organization.manage-uses-purchase-orders"
  | "internal-dashboard.organization.set-allow-custom-images"
  | "internal-dashboard.organization.set-allow-delinquent-payer"
  | "internal-dashboard.organization.set-allow-iamproviders"
  | "internal-dashboard.organization.set-allow-scim"
  | "internal-dashboard.organization.set-automatic-invoice-verification"
  | "internal-dashboard.organization.set-business-associate-agreement-signed"
  | "internal-dashboard.organization.set-cost-center"
  | "internal-dashboard.organization.set-custom-image-filter"
  | "internal-dashboard.organization.set-custom-invoice-threshold"
  | "internal-dashboard.organization.set-datacluster-label-filter"
  | "internal-dashboard.organization.set-deployment-models"
  | "internal-dashboard.organization.set-disable-custom-cpu"
  | "internal-dashboard.organization.set-disable-custom-disk"
  | "internal-dashboard.organization.set-entity"
  | "internal-dashboard.organization.set-has-private-data-clusters"
  | "internal-dashboard.organization.set-iamprovider-limits"
  | "internal-dashboard.organization.set-internal-contacts"
  | "internal-dashboard.organization.set-kubernetes-access"
  | "internal-dashboard.organization.set-quota"
  | "internal-dashboard.organization.set-requires-prepaid-deployments"
  | "internal-dashboard.organization.set-sales-info"
  | "internal-dashboard.organization.set-support-info"
  | "internal-dashboard.organization.set-technical-account-manager"
  | "internal-dashboard.organization.set-tier"
  | "internal-dashboard.organization.set-uses-credit-bundles"
  | "internal-dashboard.prepaiddeployment.attach"
  | "internal-dashboard.prepaiddeployment.create"
  | "internal-dashboard.prepaiddeployment.delete"
  | "internal-dashboard.prepaiddeployment.detach"
  | "internal-dashboard.prepaiddeployment.get-for-deployment"
  | "internal-dashboard.prepaiddeployment.get-info"
  | "internal-dashboard.prepaiddeployment.list"
  | "internal-dashboard.prepaiddeployment.update"
  | "internal-dashboard.price.calculate"
  | "internal-dashboard.pricingplan.create"
  | "internal-dashboard.pricingplan.delete"
  | "internal-dashboard.pricingplan.get"
  | "internal-dashboard.pricingplan.list"
  | "internal-dashboard.pricingplan.update"
  | "internal-dashboard.pricingplanassignment.create"
  | "internal-dashboard.pricingplanassignment.get"
  | "internal-dashboard.pricingplanassignment.list"
  | "internal-dashboard.pricingplanassignment.update"
  | "internal-dashboard.privateendpointservice.delete"
  | "internal-dashboard.privateendpointservice.update"
  | "internal-dashboard.project.get"
  | "internal-dashboard.project.list"
  | "internal-dashboard.project.set-deployment-models"
  | "internal-dashboard.project.set-quota"
  | "internal-dashboard.publicemailproviderdomain.create"
  | "internal-dashboard.publicemailproviderdomain.delete"
  | "internal-dashboard.publicemailproviderdomain.get"
  | "internal-dashboard.publicemailproviderdomain.list"
  | "internal-dashboard.publicemailproviderdomain.update"
  | "internal-dashboard.regionlimits.get"
  | "internal-dashboard.regionlimits.set"
  | "internal-dashboard.revenue.get"
  | "internal-dashboard.schedulingpolicy.get"
  | "internal-dashboard.schedulingpolicy.list"
  | "internal-dashboard.termsandconditions.create"
  | "internal-dashboard.termsandconditions.get"
  | "internal-dashboard.termsandconditions.list"
  | "internal-dashboard.termsandconditions.set-current"
  | "internal-dashboard.termsandconditions.update"
  | "internal-dashboard.tests.abort"
  | "internal-dashboard.tests.get-status"
  | "internal-dashboard.tests.get-tests"
  | "internal-dashboard.tests.run"
  | "internal-dashboard.tier.get"
  | "internal-dashboard.tier.list"
  | "internal-dashboard.uploaddocument.create"
  | "internal-dashboard.uploaddocument.get"
  | "internal-dashboard.uploaddocument.list"
  | "internal-dashboard.usageitem.list"
  | "internal-dashboard.user.get-verification-code"
  | "internal-dashboard.user.get"
  | "internal-dashboard.user.list"
  | "internal-dashboard.user.reset-mobile-phone"
  | "internal-dashboard.user.send-verification-email"
  | "metrics.token.create"
  | "metrics.token.list"
  | "replication.deployment.clone-from-backup"
  | "resourcemanager.event.list"
  | "resourcemanager.organization-invite.create"
  | "resourcemanager.organization-invite.delete"
  | "resourcemanager.organization-invite.get"
  | "resourcemanager.organization-invite.list"
  | "resourcemanager.organization-invite.update"
  | "resourcemanager.organization.delete"
  | "resourcemanager.organization.get"
  | "resourcemanager.organization.update"
  | "resourcemanager.project.create"
  | "resourcemanager.project.delete"
  | "resourcemanager.project.get"
  | "resourcemanager.project.list"
  | "resourcemanager.project.update"
  | "security.iamprovider.create"
  | "security.iamprovider.delete"
  | "security.iamprovider.get"
  | "security.iamprovider.list"
  | "security.iamprovider.set-default"
  | "security.iamprovider.update"
  | "security.ipallowlist.create"
  | "security.ipallowlist.delete"
  | "security.ipallowlist.get"
  | "security.ipallowlist.list"
  | "security.ipallowlist.update";

export const hasSupportPermission = (permission: Permission, hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean) => {
  const url = "/Organization/_support";
  return !!(hasPermissionByUrl && hasPermissionByUrl(url, ResourceType.Organization, permission));
};
