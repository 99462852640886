//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import React from "react";
import { Header } from "semantic-ui-react";
import { PodStatistics as ApiPodStatistics } from "../../api/data/v1/idata";
import { Field, FieldContent as FC, FieldLabelWide as FLW } from "../../ui/lib";
import moment from "moment";

export interface IPodStatisticsViewArgs {
  stats?: ApiPodStatistics;
}

export const PodStatisticsView = ({ ...args }: IPodStatisticsViewArgs) => {
  const stats = args.stats || {};
  const red = (count: number) => (count > 0 ? "red" : "");
  const orange = (count: number) => (count > 0 ? "orange" : "");
  const not_ready = (stats.running || 0) - (stats.ready || 0);
  const longest_not_ready_time_in_sec = stats.longest_not_ready_time_in_sec || 0;
  return (
    <div>
      <Header sub>Pod Status</Header>
      <Field>
        <FLW>Total pods</FLW>
        <FC>{stats.total || 0}</FC>
      </Field>
      <Field>
        <FLW>Scheduled pods</FLW>
        <FC>{stats.scheduled || 0}</FC>
      </Field>
      <Field>
        <FLW>Initialized pods</FLW>
        <FC>{stats.initialized || 0}</FC>
      </Field>
      <Field>
        <FLW>Running pods</FLW>
        <FC>{stats.running || 0}</FC>
      </Field>
      <Field>
        <FLW>Ready pods</FLW>
        <FC>{stats.ready || 0}</FC>
      </Field>
      <Field>
        <FLW>Succeeded pods</FLW>
        <FC>{stats.succeeded || 0}</FC>
      </Field>
      <br />
      <Field>
        <FLW>Pending pods</FLW>
        <FC>
          <span style={{ color: orange(stats.pending || 0) }}>{stats.pending || 0}</span>
        </FC>
      </Field>
      <Field>
        <FLW>Not Ready pods</FLW>
        <FC>
          <span style={{ color: orange(not_ready) }}>
            {not_ready}
            {!!not_ready && <span>&nbsp;(max delay: {not_ready ? moment.duration(longest_not_ready_time_in_sec, "seconds").humanize() : "-"})</span>}
          </span>
        </FC>
      </Field>
      <Field>
        <FLW>Evicted pods</FLW>
        <FC>
          <span style={{ color: orange(stats.evicted || 0) }}>{stats.evicted || 0}</span>
        </FC>
      </Field>
      <Field>
        <FLW>Failed pods</FLW>
        <FC>
          <span style={{ color: red(stats.failed || 0) }}>{stats.failed || 0}</span>
        </FC>
      </Field>
      <Field>
        <FLW>Unschedulable pods</FLW>
        <FC>
          <span style={{ color: red(stats.unschedulable || 0) }}>{stats.unschedulable || 0}</span>
        </FC>
      </Field>
      <Field>
        <FLW>CrashLoopBackoff pods</FLW>
        <FC>
          <span style={{ color: red(stats.crashloopbackoff || 0) }}>{stats.crashloopbackoff || 0}</span>
        </FC>
      </Field>
    </div>
  );
};
