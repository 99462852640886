//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dropdown, Icon, Input, Loader, Menu, Popup, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  AuditLog as ApiAuditLog,
  AuditLogList as ApiAuditLogList,
  ListAuditLogsRequest as ApiListAuditLogsRequest,
} from "../../api/lib";
import {
  ContentSegment,
  ErrorMessage,
  LoaderBoxForTable as LoaderBox,
  Loading,
  PagingButtons,
  SecondaryMenu,
  Section,
  SectionContent,
  SectionHead,
  SectionHeader,
  SubID,
  TextLink,
} from "../../ui/lib";
import OrganizationCache, { OrganizationName, OrganizationTierIcon } from "../../util/OrganizationCache";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import AuditLogArchiveInfoList from "./AuditLogArchiveInfoList";
import { AuditLogSummaryTableView } from "./AuditLogSummary";
import AuditLogArchiveChunkList from "./AuditLogArchiveChunkList";
import AuditLogDestinationList from "./AuditLogDestinationList";
import { AuditLogDetailsView } from "./AuditLogDetails";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

interface IFilterViewArgs {
  onIdChanged: (value: string) => void;

  page: number;
  pageSize: number;
  count: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Dropdown item text="Search">
        <Dropdown.Menu>
          <Menu.Item>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="AuditLog ID"
              name="search"
              onChange={(e, d) => args.onIdChanged(d.value)}
              onClick={(e: any) => e.stopPropagation()}
            />
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item position="right" fitted="vertically">
        <PagingButtons {...args} size="tiny" />
      </Menu.Item>
    </Menu>
  );
};

// Arguments for header view
interface IHeaderView {
  page: number;
  pageSize: number;
  count: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Organization</Table.HeaderCell>
        <Table.HeaderCell>
          <Popup trigger={<Icon name="paw" />} content="Tier" />
        </Table.HeaderCell>
        <Table.HeaderCell>Default</Table.HeaderCell>
        <Table.HeaderCell># of destinations</Table.HeaderCell>
        <Table.HeaderCell>Created</Table.HeaderCell>
        <Table.HeaderCell>Deleted</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

// Interface describing a AuditLog
interface IRowView extends IWithRefreshProps {
  item: ApiAuditLog;
  onClickView: () => void;
  onClickOrganization: (organizationId: string) => void;
  organizationCache: OrganizationCache;
}

const RowView = ({ ...args }: IRowView) => {
  const auditLog = args.item || {};
  const name = auditLog.name || "-";
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>
        <TextLink label={name} href={Routes.dashboard_support_auditlog_detailsWithId(args.item.id as string)} />
        {auditLog.description && (
          <Popup trigger={<Icon name="paperclip" />} position="right center">
            {auditLog.description}
          </Popup>
        )}
        <SubID>{auditLog.id || ""}</SubID>
      </Table.Cell>
      <Table.Cell>
        <OrganizationName
          cache={args.organizationCache}
          onClick={() => args.onClickOrganization(auditLog.organization_id || "")}
          organizationId={auditLog.organization_id}
        />
      </Table.Cell>
      <Table.Cell>
        <OrganizationTierIcon
          organizationId={auditLog.organization_id}
          cache={args.organizationCache}
          onClick={() => args.onClickOrganization(auditLog.organization_id || "")}
        />
      </Table.Cell>
      <Table.Cell>{auditLog.is_default ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{auditLog.destinations ? auditLog.destinations.length : "0"}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={auditLog.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>{auditLog.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={auditLog.deleted_at} label="Created at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

// Interface describing the AuditLog list
interface IListView extends IWithRefreshProps {
  items?: ApiAuditLog[];
  onClickOrganization: (organizationId: string) => void;
  onClickView: (id: string) => void;
  page: number;
  pageSize: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  organizationCache: OrganizationCache;
}

const ListView = ({ ...args }: IListView) => {
  const items = args.items || [];
  return (
    <Table striped>
      <HeaderView {...args} count={items.length} />
      <Table.Body>
        {items.map((item) => (
          <RowView {...args} key={item && item.id} item={item} onClickView={() => args.onClickView((item && item.id) || "")} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IEmptyViewArgs {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyViewArgs) => {
  return <div>No {args.page > 0 ? "more AuditLogs" : "AuditLog"}</div>;
};

// Interface describing the AuditLog list view arguments
export interface IAuditLogListViewArgs extends IFilterViewArgs, IListView {
  AuditLogs?: ApiAuditLogList;
  onClickView: (id: string) => void;
}

const AuditLogListViewContent = ({ ...args }: IAuditLogListViewArgs) => {
  const AuditLogs = args.AuditLogs || {};
  const items = AuditLogs.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView {...args} />;
  }
  return <ListView {...args} items={items} />;
};

export const AuditLogListView = ({ ...args }: IAuditLogListViewArgs) => {
  if (!args.AuditLogs) {
    return <Loading />;
  }
  return (
    <div>
      <FilterView {...args} />
      <AuditLogListViewContent {...args} />
    </div>
  );
};

// Project Based View Context is used when viewing auditlogs from Project details.
interface IAttachedAuditLogViewArgs extends IWithRefreshProps, RouteComponentProps {
  auditLog?: ApiAuditLog;
  project_id?: string;
  onAuditLogSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
}

export const AttachedAuditLogView = ({ ...args }: IAttachedAuditLogViewArgs) => {
  const auditLog = args.auditLog;
  if (!auditLog) {
    return <div>No attached AuditLog on Project.</div>;
  }
  return (
    <div>
      <Section>
        <SectionHead>
          <SectionHeader title="AuditLog" />
        </SectionHead>
        <SectionContent>
          <AuditLogSummaryTableView {...args} />
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="AuditLog Archives" />
        </SectionHead>
        <SectionContent>
          <AuditLogArchiveInfoList {...args} auditLog={auditLog} />
        </SectionContent>
      </Section>
    </div>
  );
};

// Deployment Based View Context is used when viewing Auditlogs from Deployment Details.
interface IDeploymentAuditLogViewArgs extends IWithRefreshProps, RouteComponentProps {
  auditLog?: ApiAuditLog;
  deployment_id?: string;
  onAuditLogSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
}

export const DeploymentAuditLogView = ({ ...args }: IDeploymentAuditLogViewArgs) => {
  const auditLog = args.auditLog;
  if (!auditLog) {
    return <div>No attached auditLog on Project.</div>;
  }
  return (
    <div>
      <Section>
        <SectionHead>
          <SectionHeader title="Auditlog Details" />
        </SectionHead>
        <SectionContent>
          <AuditLogDetailsView {...args} auditLog={auditLog} />
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Auditlog Destinations" />
        </SectionHead>
        <SectionContent>{<AuditLogDestinationList {...args} auditLog={auditLog} />}</SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Auditlog Archive" />
        </SectionHead>
        <SectionContent>{<AuditLogArchiveInfoList {...args} auditLog={auditLog} />}</SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="AuditLog Archive Chunks" />
        </SectionHead>
        <SectionContent>{<AuditLogArchiveChunkList {...args} auditlog_id={auditLog.id || ""} />}</SectionContent>
      </Section>
    </div>
  );
};

// Interface describing the properties of the AuditLog list component
interface IAuditLogListProps extends IWithRefreshProps, RouteComponentProps {
  organization_id?: string;
  project_id?: string;
  deployment_id?: string;
  onAuditLogSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
}

// Interface describing the state of the AuditLog list component
interface IAuditLogListState {
  organization_id: string;
  AuditLogs?: ApiAuditLogList;
  page: number;
  pageSize: number;
  organizationCache: OrganizationCache;
  errorMessage?: string;
  reloadListNeeded: boolean;
  auditLog?: ApiAuditLog;
  auditLogId?: string;
  subscriptionIdAuditlog?: string;
  subscriptionIdAuditlogs?: string;
}

// The component to show the audit logs.
class AuditLogList extends Component<IAuditLogListProps, IAuditLogListState> {
  state: IAuditLogListState = {
    organization_id: this.props.organization_id || "",
    reloadListNeeded: false,
    AuditLogs: undefined,
    page: 0,
    pageSize: 10,
    organizationCache: new OrganizationCache(),
    errorMessage: undefined,
    auditLog: undefined,
    auditLogId: undefined,
    subscriptionIdAuditlog: undefined,
    subscriptionIdAuditlogs: undefined,
  };

  reloadAuditLogs = async () => {
    if (this.props.organization_id) {
      try {
        const req: ApiListAuditLogsRequest = {
          organization_id: this.props.organization_id,
          include_deleted: true,
          options: {
            page: this.state.page,
            page_size: this.state.pageSize,
            context_id: this.state.auditLogId,
          },
        };
        const auditLogsList = await apiClients.idashboardClient.ListAuditLogs(req);
        this.setState({
          AuditLogs: auditLogsList,
        });
      } catch (e) {
        this.setState({ errorMessage: e });
      }
    }
  };

  refreshAuditLogs = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogs);
  };

  refreshAuditLog = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLog);
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshAuditLogs
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshAuditLogs
    );
  };

  onClickView = (id: string) => {
    this.props.onAuditLogSelected(id);
  };

  async componentDidMount() {
    const subscribeUrl = this.props.subscribeUrl;
    const unsubscribe = this.props.unsubscribe;
    if (subscribeUrl && unsubscribe) {
      if (this.state.subscriptionIdAuditlog) {
        unsubscribe(this.state.subscriptionIdAuditlog);
      }
      const subscriptionIdAuditlogs = await subscribeUrl(this.reloadAuditLogs, `/Organization/${this.props.organization_id}/AuditLog/*`);
      this.setState({ subscriptionIdAuditlogs: subscriptionIdAuditlogs });
      if (!!this.props.project_id) {
        if (this.state.subscriptionIdAuditlog) {
          unsubscribe(this.state.subscriptionIdAuditlog);
        }
        const subscriptionIdAuditlog = await subscribeUrl(this.reloadAuditLog, `/Organization/${this.props.organization_id}/AuditLog/*`);
        this.setState({ subscriptionIdAuditlog: subscriptionIdAuditlog });
      }
    }
  }

  componentDidUpdate() {
    if (this.state.reloadListNeeded) {
      this.componentDidMount();
      this.setState({ reloadListNeeded: false }, this.refreshAuditLogs);
    }
  }

  static getDerivedStateFromProps(props: IAuditLogListProps, state: IAuditLogListState) {
    // Store prevId in state so we can compare when props change.
    if (props.organization_id !== state.organization_id) {
      return {
        organization_id: props.organization_id,
        reloadListNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  hasPermission = (p: Permission) => {
    const url = "";
    return !!(this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(url, ResourceType.AuditLog, p));
  };

  reloadAuditLog = async () => {
    if (!!this.props.project_id) {
      try {
        const req: ApiIDOptions = {
          id: this.props.project_id,
        };
        const auditLog = await apiClients.idashboardClient.GetAuditLogAttachedToProject(req);
        if (auditLog) {
          this.setState({
            auditLog: auditLog,
            errorMessage: undefined,
          });
        }
      } catch (e) {
        if (e.status == 404) {
          // No attached auditLog. Move on.
          this.setState({
            auditLog: undefined,
            errorMessage: undefined,
          });
        } else {
          this.setState({ errorMessage: e });
        }
      }
    }
  };

  clearError = () => {
    this.setState({ errorMessage: undefined });
  };

  onIdChanged = (value: string) => {
    this.setState(
      {
        auditLogId: value,
        page: 0,
      },
      this.refreshAuditLogs
    );
  };

  render() {
    const AuditLogs = this.state.AuditLogs;
    const AuditLogItems = (AuditLogs || {}).items || [];

    const orgView = (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.clearError} message={this.state.errorMessage} />
        <AuditLogListView
          {...this.props}
          {...this.state}
          AuditLogs={AuditLogs}
          count={AuditLogItems.length}
          onClickView={this.onClickView}
          onClickOrganization={this.props.onOrganizationSelected}
          onNextPage={this.onNextPage}
          onPreviousPage={this.onPreviousPage}
          onIdChanged={this.onIdChanged}
        />
      </ContentSegment>
    );
    // loads all auditlogs
    if (!!this.props.organization_id) {
      return (
        <ContentSegment>
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.clearError} message={this.state.errorMessage} />
          {orgView}
        </ContentSegment>
      );
    }
    // project view loads a single attached auditlog and archives
    if (!!this.props.project_id && !this.props.deployment_id) {
      return (
        <ContentSegment>
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.clearError} message={this.state.errorMessage} />
          <AttachedAuditLogView {...this.props} {...this.state} auditLog={this.state.auditLog} />
        </ContentSegment>
      );
    }
    // deployment view -> single archive view with list of chunks.
    if (!!this.props.deployment_id) {
      return (
        <ContentSegment>
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.clearError} message={this.state.errorMessage} />
          <DeploymentAuditLogView {...this.props} {...this.state} auditLog={this.state.auditLog} deployment_id={this.props.deployment_id} />
        </ContentSegment>
      );
    }
    return (
      <ContentSegment>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <SecondaryMenu>
          <Menu.Item header>AuditLogs</Menu.Item>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        {orgView}
      </ContentSegment>
    );
  }
}

export default withRefresh()(AuditLogList);
