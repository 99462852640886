//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { CACertificate as ApiCACertificate } from "../../api/crypto/v1/crypto";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Routes } from "../../routes";
import { SubID, TextLink } from "../../ui/lib";
import _ from "lodash";

interface ICACertificateViewArgs extends ICACertificateState {
  alternateDnsNames?: string[];
}

const CACertificateView = ({ ...args }: ICACertificateViewArgs) => {
  if (!args.caCertificate) {
    return <span>retrieving...</span>;
  }

  const hasAlternateDnsNames = !_.isEmpty(args.alternateDnsNames);
  const alternateDnsNames = args.alternateDnsNames || [];
  return (
    <div>
      <TextLink href={Routes.dashboard_support_cacertificate_detailsWithId(args.caCertificate.id || "")} label={args.caCertificate.name} />
      {hasAlternateDnsNames && (
        <div>
          {alternateDnsNames.map((x) => (
            <pre>{x}</pre>
          ))}
        </div>
      )}
      {!hasAlternateDnsNames && <SubID>(default DNS names)</SubID>}
    </div>
  );
};

// Interface decribing the properties of the CACertificate component
interface ICACertificateProps extends IWithRefreshProps {
  caCertificateId?: string;
  alternateDnsNames?: string[];
}

// Interface decribing the state of the CACertificate component
interface ICACertificateState {
  caCertificate?: ApiCACertificate;
}

class CACertificate extends Component<ICACertificateProps, ICACertificateState> {
  state: ICACertificateState = {
    caCertificate: undefined,
  };

  reloadCACertificateInfo = async () => {
    const idOptions: ApiIDOptions = { id: this.props.caCertificateId };
    const caCertificate = await apiClients.idashboardClient.GetCACertificate(idOptions);
    this.setState({ caCertificate: caCertificate });
  };

  componentDidMount() {
    if (this.props.caCertificateId) {
      this.props.refreshNow && this.props.refreshNow(this.reloadCACertificateInfo);
    }
  }

  render() {
    if (!this.props.caCertificateId) {
      return <div>Unknown</div>;
    }

    return (
      <div>
        <CACertificateView {...this.props} {...this.state} />
        <Loader size="mini" active={this.props.loading} inline />
      </div>
    );
  }
}

export default withRefresh()(CACertificate);
