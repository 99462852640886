//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { Icon, Modal } from "semantic-ui-react";
import ProjectCache from "../../util/ProjectCache";
import { DeploymentInfo as ApiDeploymentInfo, ListUsageItemsRequest as ApiListUsageItemsRequest, Usage, IDOptions as ApiIDOptions } from "../../api/lib";
import { momentNow } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import { humanizeFileSize } from "../../util/FileSize";
import { Timestamp } from "../../api/googleTypes";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import moment from "moment";

// if this amount of data transfer bytes have been accumulated
// by a deployment over the past 3 days we'll consider this
// deployment to be active. 3MB (in binary).
const dataTransferThreshold = 3145728;

// Last N days.
const lastNDays = 3;

interface DeploymentUsageViewArgs {
  networkIn: number;
  networkOut: number;
  updatedFrom?: Timestamp;
  updatedUntil?: Timestamp;
}

const DeploymentUsageView = ({ ...args }: DeploymentUsageViewArgs) => {
  let icon = <Icon name="sync" color="grey" loading={true} />;
  const networkData = Number(args.networkIn) + Number(args.networkOut);
  if (args.networkIn !== undefined && args.networkOut !== undefined) {
    if (networkData == 0) {
      icon = <Icon name="battery empty" />;
    } else if (networkData < dataTransferThreshold) {
      icon = <Icon name="battery half" />;
    } else if (networkData >= dataTransferThreshold) {
      icon = <Icon name="battery full" />;
    }
  }
  const formattedIn = humanizeFileSize(args.networkIn || 0);
  const formattedOut = humanizeFileSize(args.networkOut || 0);
  return (
    <div>
      <Modal trigger={icon} size="small">
        <Modal.Header>Data transfer info</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Network Traffic In: {formattedIn}
            <br />
            Network Traffic Out: {formattedOut}
            <br />
            <br />
            From: <DateTimePopupWithUTCAndLocalTime dateTime={args.updatedFrom} label="From" />
            <br />
            To: <DateTimePopupWithUTCAndLocalTime dateTime={args.updatedUntil} label="Until" />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

// Interface decribing the properties of the deployment usage component
interface IDeploymentUsageProps extends IWithRefreshProps {
  item: ApiDeploymentInfo;
  projectCache?: ProjectCache;
}

// Interface decribing the state of the deployment usage component
interface IDeploymentUsageState {
  networkIn: number;
  networkOut: number;
  updatedFrom?: Timestamp;
  updatedUntil?: Timestamp;
}

class DeploymentUsage extends Component<IDeploymentUsageProps, IDeploymentUsageState> {
  reloadNetworkData = async () => {
    if (!this.props.item.deployment || !this.props.projectCache) {
      return;
    }
    const depl = this.props.item.deployment;
    let from = momentNow().startOf("day").subtract(lastNDays, "days").toDate();
    let to = momentNow().endOf("day").toDate();
    const proj = await this.props.projectCache.getProjectById(depl.project_id || "");
    if (proj) {
      const reqId: ApiIDOptions = {
        id: depl.id,
      };
      const networkTransfer = await apiClients.idashboardClient.GetDeploymentNetworkTransfer(reqId);
      const req: ApiListUsageItemsRequest = {
        from: from,
        to: to,
        deployment_id: depl.id,
        organization_id: proj.organization_id,
      };
      const usageItems = await apiClients.idashboardClient.ListUsageItems(req);
      let totalIngress = +(networkTransfer.ingress_bytes || 0);
      let totalEgress = +(networkTransfer.egress_bytes || 0);
      let updatedFrom = networkTransfer.updated_at;
      if (usageItems.items) {
        usageItems.items.forEach((item) => {
          if (
            item.network_transfer_size &&
            (item.network_transfer_size.destination === Usage.network_transfer_destination_internet ||
              item.network_transfer_size.destination === Usage.network_transfer_destination_private_endpoint)
          ) {
            totalIngress += +(item.network_transfer_size.total_transfer_ingress_size || 0);
            totalEgress += +(item.network_transfer_size.total_transfer_egress_size || 0);
            if (moment.utc(item.starts_at) < moment.utc(updatedFrom)) {
              updatedFrom = item.starts_at;
            }
          }
        });
      }
      this.setState({
        networkIn: totalIngress,
        networkOut: totalEgress,
        updatedFrom: updatedFrom,
        updatedUntil: networkTransfer.updated_at,
      });
    }
  };

  componentDidMount() {
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadNetworkData, 5000);
  }

  render() {
    return <DeploymentUsageView {...this.state} />;
  }
}

export default withRefresh()(DeploymentUsage);
