//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Dropdown, DropdownProps, Loader } from "semantic-ui-react";
import { PlanList as ApiSupportPlanList, Plan as ApiSupportPlan, ListPlansRequest as ApiListPlansRequest } from "../api/support/v1/support";
import apiClients from "../api/apiclients";
import _ from "lodash";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";

interface ISupportPlanInputProps extends IWithRefreshProps {
  id?: string;
  organizationID?: string;
  placeholder?: string;
  onChange: (id: string) => void;
}

interface ISupportPlanInputState {
  id: string;
  organizationID?: string;
  errorMessage?: string;
  loading: boolean;
  plans?: ApiSupportPlanList;
  refreshNeeded: boolean;
}

class SupportPlanInputComp extends Component<ISupportPlanInputProps, ISupportPlanInputState> {
  state: ISupportPlanInputState = {
    id: this.props.id || "",
    organizationID: this.props.organizationID,
    errorMessage: undefined,
    loading: true,
    refreshNeeded: false,
  };

  reloadSupportPlans = async () => {
    this.setState({ loading: true });
    if (!this.props.organizationID) {
      const plans = await apiClients.idashboardClient.ListSupportPlans({});
      this.setState({ plans: plans, loading: false });
    } else {
      const req: ApiListPlansRequest = {
        organization_id: this.props.organizationID,
      };
      const plans = await apiClients.authenticationOnly.supportClient.ListPlans(req);
      this.setState({ plans: plans, loading: false });
    }
  };

  refreshSupportPlans = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadSupportPlans);
  };

  static getDerivedStateFromProps(props: ISupportPlanInputProps, state: ISupportPlanInputState) {
    // Store prevId in state so we can compare when props change.
    if (props.id !== state.id) {
      return {
        id: props.id,
        refreshNeeded: true,
      };
    }

    if (props.organizationID !== state.organizationID) {
      return {
        organizationID: props.organizationID,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshSupportPlans();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshSupportPlans);
    }
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newId = data.value as string;
    this.setState({ id: newId }, () => {
      this.props.onChange(newId);
    });
  };

  render() {
    const plans = this.state.plans;
    let list = _.clone((plans || {}).items || []);
    return (
      <div>
        {this.state.loading && <Loader active size="tiny" />}
        {plans && plans.items && (
          <Dropdown
            fluid
            selection
            placeholder={this.props.placeholder}
            options={_.map(list, (x: ApiSupportPlan) => {
              return {
                key: x.id,
                text: x.name,
                value: x.id,
              };
            })}
            onChange={this.onChange}
            value={this.state.id}
          />
        )}
      </div>
    );
  }
}

export const SupportPlanInput = withRefresh()(SupportPlanInputComp);
