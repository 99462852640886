//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React from "react";
import { Dropdown, Header } from "semantic-ui-react";
import { Common, KeyValuePair as ApiKeyValuePair, Deployment as ApiDeployment } from "../../api/lib";
import { Field, FieldContent as FC, FieldLabelWide as FL } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";

interface ISpecialModesViewArgs extends IWithRefreshProps {
  deployment: ApiDeployment;
  annotations?: ApiKeyValuePair[];
  inHibernationMode: boolean;
  isHibernated: boolean;
  inMaintenanceMode: boolean;
  blockAllIncomingTraffic: boolean;
  agencyInMaintenanceMode: boolean;
  operatorInMaintenanceMode: boolean;
  chaosLevel: string;
  onClickUpdateHibernationMode: (newMode: boolean) => void;
  onClickUpdateMaintenanceMode: (newMode: boolean) => void;
  onClickUpdateAllowTraffic: (newMode: boolean) => void;
  onClickUpdateAgencyMaintenanceMode: (newMode: boolean) => void;
  onClickUpdateOperatorMaintenanceMode: (newMode: boolean) => void;
  onClickDCUpdateMode: (newValue: string) => void;
  can_edit_hibernation_mode: boolean;
  can_edit_maintenance_mode: boolean;
  can_edit_allow_traffic: boolean;
  can_edit_agency_maintenance_mode: boolean;
  can_edit_operator_maintenance_mode: boolean;
  can_edit_dcupdate_mode: boolean;
  can_redelete_deployment: boolean;
  onClickRedeleteDeployment: () => void;
  processingDeploymentRedelete: boolean;
  onClickUpdateChaosLevel: (newLevel: string) => void;
  can_edit_chaos_level: boolean;
  suppressAlerts: boolean;
  canEditSuppressedAlerts: boolean;
  onClickUpdateSuppressedAlerts: (newValue: boolean) => void;
  disabledBackups: boolean;
  canSetPublicBackupsDisabled: boolean;
  onClickSetPublicBackupsDisabled: (disabled: boolean) => void;
  usesLocalSSDs: boolean;
  readOnly: boolean;
  useJetStreamForAuditlogs: boolean;
  canEditJetStreamForAuditlogs: boolean;
  onUpdateDeploymentToUseJetStreamForAuditLogs: (newValue: boolean) => void;
}

export const SpecialModesView = ({ ...args }: ISpecialModesViewArgs) => {
  const annots = args.annotations || [];
  const dcUpdateMode = annots.find((v: ApiKeyValuePair) => {
    return v.key == Common.dcupdate_mode_annotation_key;
  });
  const dcUpdateModeValue = (dcUpdateMode && dcUpdateMode.value) || "";
  let dcUpdateModeDisplay = "";
  switch (dcUpdateModeValue) {
    case Common.dcupdate_mode_exclude_annotation_value:
      dcUpdateModeDisplay = "Excluded";
      break;
    case "":
      dcUpdateModeDisplay = "Healthy only";
      break;
    case Common.dcupdate_mode_force_annotation_value:
      dcUpdateModeDisplay = "Always (even Unhealthy)";
      break;
    case Common.dcupdate_mode_skip_version_update_annotation_value:
      dcUpdateModeDisplay = "Excluded from db version updates";
      break;
  }
  let chaosLevel = _.capitalize(args.chaosLevel);
  if (chaosLevel == "") {
    chaosLevel = "None";
  }
  return (
    <div>
      <Header sub>Special modes</Header>
      <Field>
        <FL>In Hibernation mode</FL>
        <FC>
          <span>{args.inHibernationMode ? "Yes" : "No"}</span>
          {args.can_edit_hibernation_mode && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.inHibernationMode && (
                  <Dropdown.Item
                    key="remove-hm"
                    text="Remove hibernation mode from deployment (data-manager will resume deployment)"
                    onClick={() => args.onClickUpdateHibernationMode(false)}
                  />
                )}
                {!args.inHibernationMode && (
                  <Dropdown.Item
                    key="set-hm"
                    text="Set deployment in hibernation mode (data-manager will delete all arangodb pods)"
                    onClick={() => args.onClickUpdateHibernationMode(true)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Is Hibernated</FL>
        <FC>{args.isHibernated ? (args.deployment.is_paused ? "Paused" : "Hibernation mode") : "No"}</FC>
      </Field>
      <Field>
        <FL>In ArangoGraph maintenance mode</FL>
        <FC>
          <span>{args.inMaintenanceMode ? "Yes" : "No"}</span>
          {args.can_edit_maintenance_mode && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.inMaintenanceMode && (
                  <Dropdown.Item key="remove-mm" text="Remove maintenance mode from deployment" onClick={() => args.onClickUpdateMaintenanceMode(false)} />
                )}
                {!args.inMaintenanceMode && (
                  <Dropdown.Item
                    key="set-mm"
                    text="Set deployment in maintenance mode (data-manager will not update resources anymore)"
                    onClick={() => args.onClickUpdateMaintenanceMode(true)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Agency in maintenance mode</FL>
        <FC>
          <span>{args.agencyInMaintenanceMode ? "Yes" : "No"}</span>
          {args.can_edit_agency_maintenance_mode && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.inMaintenanceMode && (
                  <Dropdown.Item
                    key="remove-mm"
                    text="Remove agency maintenance mode from deployment"
                    onClick={() => args.onClickUpdateAgencyMaintenanceMode(false)}
                  />
                )}
                {!args.inMaintenanceMode && (
                  <Dropdown.Item
                    key="set-mm"
                    text="Set deployment in agency maintenance mode (arangod will not update [several] resources anymore)"
                    onClick={() => args.onClickUpdateAgencyMaintenanceMode(true)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Operator in maintenance mode</FL>
        <FC>
          <span>{args.operatorInMaintenanceMode ? "Yes" : "No"}</span>
          {args.can_edit_maintenance_mode && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.operatorInMaintenanceMode && (
                  <Dropdown.Item
                    key="remove-mm"
                    text="Remove operator maintenance mode from deployment"
                    onClick={() => args.onClickUpdateOperatorMaintenanceMode(false)}
                  />
                )}
                {!args.operatorInMaintenanceMode && (
                  <Dropdown.Item
                    key="set-mm"
                    text="Set operator for deployment in maintenance mode (kube-arangodb will not update resources anymore)"
                    onClick={() => args.onClickUpdateOperatorMaintenanceMode(true)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Block Traffic</FL>
        <FC>
          <span>{args.blockAllIncomingTraffic ? "Yes" : "No"}</span>
          {args.can_edit_allow_traffic && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.blockAllIncomingTraffic && (
                  <Dropdown.Item key="remove-mm" text="Remove blocking traffic from deployment" onClick={() => args.onClickUpdateAllowTraffic(false)} />
                )}
                {!args.blockAllIncomingTraffic && (
                  <Dropdown.Item
                    key="set-mm"
                    text="Set deployment in block traffic (CIDR will be applied to allow CP traffic only)"
                    onClick={() => args.onClickUpdateAllowTraffic(true)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Auto update mode</FL>
        <FC>
          <span>{dcUpdateModeDisplay}</span>
          {args.can_edit_dcupdate_mode && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {dcUpdateModeValue != Common.dcupdate_mode_exclude_annotation_value && (
                  <Dropdown.Item
                    key="exclude"
                    text="Exclude this deployment from automatic updates"
                    onClick={() => args.onClickDCUpdateMode(Common.dcupdate_mode_exclude_annotation_value)}
                  />
                )}
                {dcUpdateModeValue != "" && (
                  <Dropdown.Item
                    key="include"
                    text="Include this deployment for automatic updates (when healthy)"
                    onClick={() => args.onClickDCUpdateMode("")}
                  />
                )}
                {dcUpdateModeValue != Common.dcupdate_mode_force_annotation_value && (
                  <Dropdown.Item
                    key="force"
                    text="Include this deployment for automatic updates (even when unhealthy)"
                    onClick={() => args.onClickDCUpdateMode(Common.dcupdate_mode_force_annotation_value)}
                  />
                )}
                {dcUpdateModeValue != Common.dcupdate_mode_skip_version_update_annotation_value && (
                  <Dropdown.Item
                    key="skip"
                    text="Exlude this deployment from the db version automatic updates"
                    onClick={() => args.onClickDCUpdateMode(Common.dcupdate_mode_skip_version_update_annotation_value)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Chaos level</FL>
        <FC>
          <span>{chaosLevel}</span>
          {args.can_edit_chaos_level && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {chaosLevel != "None" && (
                  <Dropdown.Item key="choas-none" text="Do not introduce any chaos in the deployment" onClick={() => args.onClickUpdateChaosLevel("NONE")} />
                )}
                {chaosLevel != "Low" && (
                  <Dropdown.Item
                    key="choas-low"
                    text="Set deployment in chaos level low (kube-arangodb will delete random pods [every 2 mins with 20%])"
                    onClick={() => args.onClickUpdateChaosLevel("LOW")}
                  />
                )}
                {chaosLevel != "Normal" && (
                  <Dropdown.Item
                    key="choas-normal"
                    text="Set deployment in chaos level normal (kube-arangodb will delete random pods [every min with 50%])"
                    onClick={() => args.onClickUpdateChaosLevel("NORMAL")}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Alerts Suppressed</FL>
        <FC>
          <span>{args.suppressAlerts ? "Yes" : "No"}</span>
          {args.canEditSuppressedAlerts && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {!args.suppressAlerts && (
                  <Dropdown.Item key="suppress-alerts" text="Suppress alerts for deployment" onClick={() => args.onClickUpdateSuppressedAlerts(true)} />
                )}
                {args.suppressAlerts && (
                  <Dropdown.Item key="on-alerts" text="Enable alerts for deployment" onClick={() => args.onClickUpdateSuppressedAlerts(false)} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Backups Disabled</FL>
        <FC>
          <span>{args.disabledBackups ? "Yes" : "No"}</span>
          {args.canSetPublicBackupsDisabled && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.disabledBackups && (
                  <Dropdown.Item key="remove-mm" text="Enable backups for deployment" onClick={() => args.onClickSetPublicBackupsDisabled(false)} />
                )}
                {!args.disabledBackups && (
                  <Dropdown.Item key="set-mm" text="Disable public backups for deployment" onClick={() => args.onClickSetPublicBackupsDisabled(true)} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Uses Local SSDs</FL>
        <FC>
          <span>{args.usesLocalSSDs ? "Yes" : "No"}</span>
        </FC>
      </Field>
      <Field>
        <FL>Use JetStream for Audit Logs</FL>
        <FC>
          <span>{args.useJetStreamForAuditlogs ? "Yes" : "No"}</span>
          {args.canEditJetStreamForAuditlogs && (
            <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
              <Dropdown.Menu>
                {args.useJetStreamForAuditlogs && (
                  <Dropdown.Item
                    key="remove-mm"
                    text="Disable JetStream for auditlogs"
                    onClick={() => args.onUpdateDeploymentToUseJetStreamForAuditLogs(false)}
                  />
                )}
                {!args.useJetStreamForAuditlogs && (
                  <Dropdown.Item key="set-mm" text="Enable JetStream for auditlogs" onClick={() => args.onUpdateDeploymentToUseJetStreamForAuditLogs(true)} />
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FC>
      </Field>
      <Field>
        <FL>Read-only</FL>
        <FC>
          <span>{args.readOnly ? "Yes" : "No"}</span>
        </FC>
      </Field>
    </div>
  );
};
