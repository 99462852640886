//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Tier as ApiTier } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import TierDetails from "./TierDetails";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";

// API used by the TierDetailsWithId component
export interface ITierDetailsWithIdAPI {
  GetTier: (tierId: string) => Promise<ApiTier>;
}

interface ITierDetailsWithIdProps extends IWithRefreshProps, RouteComponentProps {
  tierId?: string;
  api: ITierDetailsWithIdAPI;
}

interface ITierDetailsWithIdState {
  refreshNeeded: boolean;
  prevTierId?: string;
  tier?: ApiTier;
}

class TierDetailsWithId extends Component<ITierDetailsWithIdProps, ITierDetailsWithIdState> {
  state: ITierDetailsWithIdState = {
    refreshNeeded: false,
    tier: undefined,
  };

  reloadTier = async () => {
    const tier = await this.props.api.GetTier(this.props.tierId || "");
    this.setState({ tier: tier });
  };

  refreshTier = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTier);
  };

  static getDerivedStateFromProps(props: ITierDetailsWithIdProps, state: ITierDetailsWithIdState) {
    if (props.tierId !== state.prevTierId) {
      return {
        refreshNeeded: true,
        prevTierId: props.tierId,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshTier();
  }

  componentDidUpdate(prevProps: ITierDetailsWithIdProps) {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshTier);
    }
  }

  render() {
    return <TierDetails {...this.props} {...this.state} />;
  }
}

export default withRefresh()(TierDetailsWithId);
