//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

interface ILoading {
  message?: string;
}

export const Loading = ({ message }: ILoading) => (
  <Segment>
    <Dimmer inverted active>
      <Loader inverted>{message || "Loading..."}</Loader>
    </Dimmer>
    <div style={{ minHeight: "3em" }} />
  </Segment>
);
