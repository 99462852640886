//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Modal, Form, Input, InputOnChangeData, CheckboxProps } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { isEmpty } from "lodash";
import { ErrorMessage, FormActionButtonCancel, FormActionButtonSave, Processing } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { DeploymentProfileInfo, TopologyAwarenessSettings } from "../../api/deployment-profile/v1/ideploymentprofile";
import { DeploymentProfile } from "../../api/deployment-profile/v1/deploymentprofile";
import { SchedulingPoliciesEditView } from "../schedulingpolicy/SchedulingPoliciesEditView";

export enum MemoryReserveType {
  Absolute = "Absolute",
  Relative = "Relative",
}

export enum CustomCommandLineArg {
  Agent = "Agent",
  DbServer = "DbServer",
  Coordinator = "Coordinator",
  KubeArangoDB = "KubeArangoDB",
}

interface IDeploymentProfileFormProps extends IWithRefreshProps {
  isUpdate: boolean;
  errorMessage?: string;
  processing: boolean;
  organizationID: string;

  name: string;
  onNameChanged: (value: string) => void;

  description: string;
  onDescriptionChanged: (value: string) => void;
  memoryRatioDbServer: string;
  memoryRatioCoordinator: string;
  onMemoryRatioChanged: (value: string) => void;

  cpuRatioDbServer: string;
  cpuRatioCoordinator: string;
  onCPURatioChange: (value: string) => void;

  memoryReserveAbsolute?: number;
  memoryReserveRelative?: number;
  onMemoryReserveTypeChanged: (reserveType: MemoryReserveType, value: number) => void;

  minimumArangoDbVersion: string;
  onMinimumVersionChanged: (value: string) => void;
  maximumArangoDbVersion: string;
  onMaximumVersionChanged: (value: string) => void;

  agentCustomArgs: string[];
  dbServerCustomArgs: string[];
  coordinatorCustomArgs: string[];
  kubeArangoDbCustomArgs: string[];
  onCustomCommandLineChanged: (commandType: CustomCommandLineArg, value: string[]) => void;

  schedulingPoliciesID: string[];
  onSchedulingPoliciesChange: (value: string[]) => void;

  showSeparator(coordinator: string, dbserver: string): string;

  canSubmit: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onDismissError: () => void;

  coordinatorFactor: number;
  dbServerFactor: number;
  onCPUFactorChange: (factors: { coordinatorFactor: number; dbServerFactor: number }) => void;

  memCoordinatorFactor: number;
  memDBServerFactor: number;
  onMemoryFactorChange: (factors: { memCoordinatorFactor: number; memDBServerFactor: number }) => void;

  topologyAwarenessSettings: TopologyAwarenessSettings | null;
  handleSetTopologyAwarenessSettings: (value: boolean) => void;

  topologyAware?: boolean;
  onTopologyAwareChanged: (value: boolean) => void;
  numberOfZonesOverride?: number;
  onNumberOfZonesOverrideChanged: (value: number) => void;
  doNotUsePlatformAuthentication?: boolean;
  onDoNotUsePlatformAuthenticationChanged: (value: boolean) => void;
  doNotUseShardRebalancer?: boolean;
  onDoNotUseShardRebalancerChanged: (value: boolean) => void;

  useLocalSSD?: boolean;
  onUseLocalSSDChanged: (value: boolean) => void;
}

export const DeploymentProfileForm = ({ ...args }: IDeploymentProfileFormProps) => {
  return (
    <Modal open={true} size="large">
      <Modal.Header>{args.isUpdate ? "Update " : "Create new"} deployment profile</Modal.Header>
      <Modal.Content scrolling>
        <Processing active={args.processing} message="Saving deployment profile..." />
        <ErrorMessage active={!!args.errorMessage} onDismiss={args.onDismissError} message={args.errorMessage} />
        <Form onSubmit={args.onSubmit}>
          <Form.Field>
            <Form.Input
              name="name"
              value={args.name}
              label="Name"
              placeholder="Name"
              required
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                args.onNameChanged(target.value);
              }}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                name="description"
                value={args.description}
                label="Description"
                placeholder="Description"
                rows="5"
                onChange={({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
                  args.onDescriptionChanged(target.value);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>Platform Authentication</Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Checkbox
                toggle
                label={!!!args.doNotUsePlatformAuthentication ? "Enabled" : "Disabled"}
                checked={!!!args.doNotUsePlatformAuthentication}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                  args.onDoNotUsePlatformAuthenticationChanged(!data.checked || false);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>Shard Rebalancer</Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Checkbox
                toggle
                label={!!!args.doNotUseShardRebalancer ? "Enabled" : "Disabled"}
                checked={!!!args.doNotUseShardRebalancer}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                  args.onDoNotUseShardRebalancerChanged(!data.checked || false);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>Local SSD</Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Checkbox
                toggle
                label={args.useLocalSSD ? "Enabled" : "Disabled"}
                checked={args.useLocalSSD}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                  args.onUseLocalSSDChanged(!!data.checked);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Form.Input
              name="memoryRatio"
              defaultValue={`${args.memoryRatioCoordinator}${args.showSeparator(args.memoryRatioCoordinator, args.memoryRatioDbServer)}${
                args.memoryRatioDbServer
              }`}
              label="Memory ratio (Coordinator/DB server)"
              placeholder="Memory ratio e.g. 25/75"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                args.onMemoryRatioChanged(target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              name="cpuRatio"
              defaultValue={`${args.cpuRatioCoordinator}${args.showSeparator(args.cpuRatioCoordinator, args.cpuRatioDbServer)}${args.cpuRatioDbServer}`}
              label="CPU ratio (Coordinator/DB server)"
              placeholder="Memory ratio e.g. 25/75"
              onChange={(_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                const { value } = data;
                args.onCPURatioChange(String(value));
              }}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field>
              <label>Memory Reserve Absolute (MB)</label>
              <Input
                type="number"
                min={0}
                value={args.memoryReserveAbsolute}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  args.onMemoryReserveTypeChanged(MemoryReserveType.Absolute, parseInt(value));
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Memory Reserve Relative (%)</label>
              <Input
                type="number"
                min={0}
                max={100}
                value={args.memoryReserveRelative}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  args.onMemoryReserveTypeChanged(MemoryReserveType.Relative, parseInt(value));
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>CPU Coordinator factor</label>
              <Input
                type="number"
                value={args.coordinatorFactor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  args.onCPUFactorChange({ coordinatorFactor: parseFloat(value), dbServerFactor: args.dbServerFactor });
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>CPU DBServer factor</label>
              <Input
                type="number"
                value={args.dbServerFactor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  args.onCPUFactorChange({ coordinatorFactor: args.coordinatorFactor, dbServerFactor: parseFloat(value) });
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Memory Coordinator factor</label>
              <Input
                type="number"
                value={args.memCoordinatorFactor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  args.onMemoryFactorChange({ memCoordinatorFactor: parseFloat(value), memDBServerFactor: args.memDBServerFactor });
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Memory DBServer factor</label>
              <Input
                type="number"
                error={true}
                value={args.memDBServerFactor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  args.onMemoryFactorChange({ memCoordinatorFactor: args.memCoordinatorFactor, memDBServerFactor: parseFloat(value) });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Minimum ArangoDB Version</label>
              <Input
                type="string"
                value={args.minimumArangoDbVersion}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => args.onMinimumVersionChanged(target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Maximum ArangoDB Version</label>
              <Input
                type="string"
                value={args.maximumArangoDbVersion}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => args.onMaximumVersionChanged(target.value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                name="agentArgs"
                value={args.agentCustomArgs.join("\n")}
                label="Agent Custom Args"
                placeholder="Custom Args for Agents"
                rows="5"
                onChange={({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
                  args.onCustomCommandLineChanged(CustomCommandLineArg.Agent, target.value.split("\n"));
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                name="dbserverArgs"
                value={args.dbServerCustomArgs.join("\n")}
                label="DbServer Custom Args"
                placeholder="Custom Args for DB Servers"
                rows="5"
                onChange={({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
                  args.onCustomCommandLineChanged(CustomCommandLineArg.DbServer, target.value.split("\n"));
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                name="coordinatorArgs"
                value={args.coordinatorCustomArgs.join("\n")}
                label="Coordinator Custom Args"
                placeholder="Custom Args for Coordinators"
                rows="5"
                onChange={({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
                  args.onCustomCommandLineChanged(CustomCommandLineArg.Coordinator, target.value.split("\n"));
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                name="kubeArangoDbArgs"
                value={args.kubeArangoDbCustomArgs.join("\n")}
                label="Kube ArangodB Custom Args"
                placeholder="Custom Args for Kube ArangoDB"
                rows="5"
                onChange={({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
                  args.onCustomCommandLineChanged(CustomCommandLineArg.KubeArangoDB, target.value.split("\n"));
                }}
              />
            </Form.Field>
          </Form.Group>
          <SchedulingPoliciesEditView
            schedulingPolicies={[]}
            selected={args.schedulingPoliciesID || []}
            onSave={args.onSchedulingPoliciesChange}
            header="Scheduling policies"
            readonly={false}
          />
          <br />
          <br />
          <Form.Field control="h4">Topology awareness settings</Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Checkbox
                toggle
                label="Set Topology Awareness Settings"
                checked={args.topologyAwarenessSettings != null}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                  args.handleSetTopologyAwarenessSettings(data.checked || false);
                }}
              />
            </Form.Field>

            <Form.Field>
              <Form.Checkbox
                label="Topology aware"
                toggle
                disabled={!args.topologyAwarenessSettings}
                checked={args.topologyAware}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                  args.onTopologyAwareChanged(data.checked || false);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label title="Set to 0 to disable override">Number of zones override</label>
              <Input
                disabled={!args.topologyAwarenessSettings}
                type="number"
                min={0}
                max={6}
                value={args.numberOfZonesOverride}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                  const { value } = data;
                  args.onNumberOfZonesOverrideChanged(parseInt(value));
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel icon="cancel" onClick={args.onClose} />
        <FormActionButtonSave primary type="submit" onClick={args.onSubmit} disabled={args.processing || !args.canSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

export interface IDeploymentProfileModalProps extends IWithRefreshProps {
  organizationID: string;
  deploymentProfileItem?: DeploymentProfileInfo;
  onClose?: () => void;
}

interface IDeploymentProfileModalState {
  organizationID: string;
  deploymentProfileID?: string;
  errorMessage?: string;
  processing: boolean;

  name: string;
  description: string;
  memoryRatioDbServer: string;
  memoryRatioCoordinator: string;
  memoryReserveAbsolute?: number;
  memoryReserveRelative?: number;
  minimumArangoDbVersion: string;
  maximumArangoDbVersion: string;
  agentCustomArgs: string[];
  dbServerCustomArgs: string[];
  coordinatorCustomArgs: string[];
  kubeArangoDbCustomArgs: string[];
  coordinatorFactor: number;
  dbServerFactor: number;
  memCoordinatorFactor: number;
  memDBServerFactor: number;
  cpuRatioDbServer: string;
  cpuRatioCoordinator: string;
  schedulingPoliciesIDs: string[];
  topologyAware?: boolean;
  numberOfZonesOverride?: number;
  topologyAwarenessSettings: TopologyAwarenessSettings | null;
  doNotUsePlatformAuthentication?: boolean;
  doNotUseShardRebalancer?: boolean;
  useLocalSSD?: boolean;
}
class DeploymentProfileModal extends Component<IDeploymentProfileModalProps, IDeploymentProfileModalState> {
  state = DeploymentProfileModal.stateFromProps(this.props);

  static stateFromProps = (props: IDeploymentProfileModalProps): IDeploymentProfileModalState => {
    const deploymentProfileItem = props.deploymentProfileItem || {};
    const deploymentProfile = deploymentProfileItem.deployment_profile || {};
    const memoryRatio =
      (deploymentProfileItem.memory_ratio &&
        deploymentProfileItem.memory_ratio.coordinator_percent &&
        deploymentProfileItem.memory_ratio.dbserver_percent && {
          coordinator_percent: deploymentProfileItem.memory_ratio.coordinator_percent.toString(),
          dbserver_percent: deploymentProfileItem.memory_ratio.dbserver_percent.toString(),
        }) ||
      {};
    const cpuRatio =
      (deploymentProfileItem.cpu_ratio &&
        deploymentProfileItem.cpu_ratio.coordinator_percent &&
        deploymentProfileItem.cpu_ratio.dbserver_percent && {
          dbserver_percent: deploymentProfileItem.cpu_ratio.dbserver_percent.toString(),
          coordinator_percent: deploymentProfileItem.cpu_ratio.coordinator_percent.toString(),
        }) ||
      {};

    return {
      organizationID: props.organizationID,
      deploymentProfileID: deploymentProfile.id,
      errorMessage: undefined,
      processing: false,

      name: deploymentProfile.name || "",
      description: deploymentProfile.description || "",
      memoryRatioCoordinator: memoryRatio.coordinator_percent || "",
      memoryRatioDbServer: memoryRatio.dbserver_percent || "",
      minimumArangoDbVersion: deploymentProfileItem.minimum_arangodb_version || "",
      maximumArangoDbVersion: deploymentProfileItem.maximum_arangodb_version || "",
      agentCustomArgs: deploymentProfileItem.agents_custom_command_line_arguments || [],
      dbServerCustomArgs: deploymentProfileItem.dbservers_custom_command_line_arguments || [],
      coordinatorCustomArgs: deploymentProfileItem.coordinators_custom_command_line_arguments || [],
      kubeArangoDbCustomArgs: deploymentProfileItem.kube_arangodb_additional_command_line_arguments || [],
      coordinatorFactor: (deploymentProfileItem.cpu_factors || {}).coordinator_factor || 1.0,
      dbServerFactor: (deploymentProfileItem.cpu_factors || {}).dbserver_factor || 1.0,
      memCoordinatorFactor: (deploymentProfileItem.memory_factors || {}).coordinator_factor || 1.0,
      memDBServerFactor: (deploymentProfileItem.memory_factors || {}).dbserver_factor || 1.0,
      memoryReserveAbsolute: (deploymentProfileItem.memory_reserve || {}).absolute,
      memoryReserveRelative: (deploymentProfileItem.memory_reserve || {}).relative,
      cpuRatioDbServer: cpuRatio.dbserver_percent || "",
      cpuRatioCoordinator: cpuRatio.coordinator_percent || "",
      schedulingPoliciesIDs: deploymentProfileItem.scheduling_policies_ids || [],
      topologyAware: (deploymentProfileItem.topology_awareness_settings || {}).topology_aware,
      numberOfZonesOverride: (deploymentProfileItem.topology_awareness_settings || {}).number_of_zones_override,
      topologyAwarenessSettings: deploymentProfileItem.topology_awareness_settings || null,
      doNotUsePlatformAuthentication: deploymentProfileItem.do_not_use_platform_authentication,
      doNotUseShardRebalancer: deploymentProfileItem.do_not_use_shard_rebalancer,
      useLocalSSD: deploymentProfileItem.use_local_ssd,
    };
  };

  onCPURatioChange = (value: string) => {
    const [cpuRatioCoordinator, cpuRatioDbServer] = value.split("/");
    this.setState({ cpuRatioCoordinator, cpuRatioDbServer });
  };

  onNameChanged = (value: string) => {
    this.setState({ name: value.trim() });
  };

  onMemoryRatioChanged = (value: string) => {
    const [coordinator, dbServer] = value.split("/");
    this.setState({ memoryRatioCoordinator: coordinator, memoryRatioDbServer: dbServer });
  };

  onMemoryReserveTypeChanged = (memoryType: MemoryReserveType, value: number) => {
    switch (memoryType) {
      case MemoryReserveType.Absolute: {
        this.setState({ memoryReserveAbsolute: value });
        break;
      }
      case MemoryReserveType.Relative: {
        this.setState({ memoryReserveRelative: value });
        break;
      }
      default: {
        break; // do nothing
      }
    }
  };

  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };

  onMinimumVersionChanged = (value: string) => {
    this.setState({ minimumArangoDbVersion: value });
  };

  onMaximumVersionChanged = (value: string) => {
    this.setState({ maximumArangoDbVersion: value });
  };

  onCustomCommandLineChanged = (commandType: CustomCommandLineArg, value: string[]) => {
    switch (commandType) {
      case CustomCommandLineArg.Agent: {
        this.setState({ agentCustomArgs: value });
        break;
      }
      case CustomCommandLineArg.DbServer: {
        this.setState({ dbServerCustomArgs: value });
        break;
      }
      case CustomCommandLineArg.Coordinator: {
        this.setState({ coordinatorCustomArgs: value });
        break;
      }
      case CustomCommandLineArg.KubeArangoDB: {
        this.setState({ kubeArangoDbCustomArgs: value });
        break;
      }
      default: {
        break; // do nothing
      }
    }
  };

  onCPUFactorChange = ({ coordinatorFactor, dbServerFactor }: { coordinatorFactor: number; dbServerFactor: number }) => {
    this.setState({ coordinatorFactor, dbServerFactor });
  };

  onMemoryFactorChange = ({ memCoordinatorFactor, memDBServerFactor }: { memCoordinatorFactor: number; memDBServerFactor: number }) => {
    this.setState({ memCoordinatorFactor, memDBServerFactor });
  };
  onSchedulingPoliciesChange = (policies: string[]) => {
    this.setState({ schedulingPoliciesIDs: policies });
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onClose = () => {
    this.props.onClose && this.props.onClose();
  };

  onTopologyAwareChange = (isTopologyAware: boolean) => {
    this.setState({
      topologyAware: isTopologyAware,
      topologyAwarenessSettings: { ...this.state.topologyAwarenessSettings, topology_aware: isTopologyAware },
    });
  };

  onNumberOfZonesOverrideChange = (numberOfZones: number) => {
    this.setState({
      numberOfZonesOverride: numberOfZones,
      topologyAwarenessSettings: { ...this.state.topologyAwarenessSettings, number_of_zones_override: numberOfZones },
    });
  };

  handleSetTopologyAwarenessSettings = (enabled: boolean) => {
    if (enabled) {
      this.setState({
        topologyAwarenessSettings: {
          topology_aware: this.state.topologyAware,
          number_of_zones_override: this.state.numberOfZonesOverride,
        },
      });
    } else {
      this.setState({ topologyAwarenessSettings: null });
    }
  };

  onDoNotUsePlatformAuthenticationChanged = (enabled: boolean) => {
    this.setState({ doNotUsePlatformAuthentication: enabled });
  };

  onDoNotUseShardRebalancerChanged = (enabled: boolean) => {
    this.setState({ doNotUseShardRebalancer: enabled });
  };

  onUseLocalSSDChanged = (enabled: boolean) => {
    this.setState({ useLocalSSD: enabled });
  };

  onSubmit = () => {
    this.setState({ processing: true, errorMessage: undefined }, async () => {
      if (this.state.coordinatorFactor < 0.5 || this.state.dbServerFactor < 0.5 || this.state.coordinatorFactor > 1 || this.state.dbServerFactor > 1) {
        this.setState({
          errorMessage: `CPU factors can only be between 0.5 and 1`,
          processing: false,
        });
        return;
      }
      if (
        this.state.memCoordinatorFactor < 0.5 ||
        this.state.memDBServerFactor < 0.5 ||
        this.state.memCoordinatorFactor > 1 ||
        this.state.memDBServerFactor > 1
      ) {
        this.setState({
          errorMessage: `Memory factors can only be between 0.5 and 1`,
          processing: false,
        });
        return;
      }
      const isUpdate = !!this.props.deploymentProfileItem;
      const req = (this.props.deploymentProfileItem || {}) as DeploymentProfileInfo;
      const deploymentProfile: DeploymentProfile = {
        organization_id: this.props.organizationID,
        name: this.state.name,
        description: this.state.description,
      };
      req.deployment_profile = deploymentProfile;
      req.use_local_ssd = this.state.useLocalSSD;
      req.do_not_use_platform_authentication = this.state.doNotUsePlatformAuthentication;
      req.do_not_use_shard_rebalancer = this.state.doNotUseShardRebalancer;
      if (this.state.memoryRatioDbServer || this.state.memoryRatioDbServer) {
        req.memory_ratio = {
          coordinator_percent: parseInt(this.state.memoryRatioCoordinator),
          dbserver_percent: parseInt(this.state.memoryRatioDbServer),
        };
      }
      if (this.state.cpuRatioCoordinator || this.state.cpuRatioDbServer) {
        req.cpu_ratio = {
          coordinator_percent: parseInt(this.state.cpuRatioCoordinator),
          dbserver_percent: parseInt(this.state.cpuRatioDbServer),
        };
      }

      if (this.state.memoryReserveAbsolute || this.state.memoryReserveRelative) {
        req.memory_reserve = {
          absolute: this.state.memoryReserveAbsolute,
          relative: this.state.memoryReserveRelative,
        };
      }

      if (this.state.minimumArangoDbVersion) {
        req.minimum_arangodb_version = this.state.minimumArangoDbVersion;
      }

      if (this.state.maximumArangoDbVersion) {
        req.maximum_arangodb_version = this.state.maximumArangoDbVersion;
      }

      if (this.state.agentCustomArgs) {
        req.agents_custom_command_line_arguments = this.state.agentCustomArgs;
      }

      if (this.state.dbServerCustomArgs) {
        req.dbservers_custom_command_line_arguments = this.state.dbServerCustomArgs;
      }

      if (this.state.coordinatorCustomArgs) {
        req.coordinators_custom_command_line_arguments = this.state.coordinatorCustomArgs;
      }

      if (this.state.kubeArangoDbCustomArgs) {
        req.kube_arangodb_additional_command_line_arguments = this.state.kubeArangoDbCustomArgs;
      }

      if (this.state.coordinatorFactor || this.state.dbServerFactor) {
        req.cpu_factors = {
          coordinator_factor: this.state.coordinatorFactor,
          dbserver_factor: this.state.dbServerFactor,
        };
      }

      if (this.state.memCoordinatorFactor || this.state.memDBServerFactor) {
        req.memory_factors = {
          coordinator_factor: this.state.memCoordinatorFactor,
          dbserver_factor: this.state.memDBServerFactor,
        };
      }
      req.scheduling_policies_ids = this.state.schedulingPoliciesIDs;

      if (this.state.topologyAwarenessSettings) {
        req.topology_awareness_settings = this.state.topologyAwarenessSettings || undefined;
      }

      try {
        if (isUpdate) {
          deploymentProfile.id = this.state.deploymentProfileID;
          await apiClients.idashboardClient.UpdateDeploymentProfile(req);
        } else {
          await apiClients.idashboardClient.CreateDeploymentProfile(req);
        }
        this.props.onClose && this.props.onClose();
      } catch (e) {
        this.setState({ errorMessage: `Failed to save deployment profile: ${e}` });
      } finally {
        this.setState({ processing: false });
      }
    });
  };

  showSeparator(coordinator: string, dbserver: string): string {
    if (coordinator || dbserver) {
      return "/";
    }
    return "";
  }

  render() {
    const isUpdate = !!this.props.deploymentProfileItem;
    const canSubmit = !isEmpty(this.state.name);
    return (
      <DeploymentProfileForm
        onNameChanged={this.onNameChanged}
        onDescriptionChanged={this.onDescriptionChanged}
        onMemoryRatioChanged={this.onMemoryRatioChanged}
        onMemoryReserveTypeChanged={this.onMemoryReserveTypeChanged}
        onMinimumVersionChanged={this.onMinimumVersionChanged}
        onMaximumVersionChanged={this.onMaximumVersionChanged}
        onCustomCommandLineChanged={this.onCustomCommandLineChanged}
        onSubmit={this.onSubmit}
        onUseLocalSSDChanged={this.onUseLocalSSDChanged}
        onDismissError={this.onDismissError}
        onClose={this.onClose}
        eventSubscriptionManager={this.props.eventSubscriptionManager}
        permissionCache={this.props.permissionCache}
        loading={this.props.loading}
        processing={this.state.processing}
        organizationID={this.state.organizationID}
        name={this.state.name}
        description={this.state.description}
        memoryRatioDbServer={this.state.memoryRatioDbServer}
        memoryRatioCoordinator={this.state.memoryRatioCoordinator}
        memoryReserveAbsolute={this.state.memoryReserveAbsolute}
        memoryReserveRelative={this.state.memoryReserveRelative}
        minimumArangoDbVersion={this.state.minimumArangoDbVersion}
        maximumArangoDbVersion={this.state.maximumArangoDbVersion}
        agentCustomArgs={this.state.agentCustomArgs}
        dbServerCustomArgs={this.state.dbServerCustomArgs}
        coordinatorCustomArgs={this.state.coordinatorCustomArgs}
        errorMessage={this.state.errorMessage}
        kubeArangoDbCustomArgs={this.state.kubeArangoDbCustomArgs}
        isUpdate={isUpdate}
        canSubmit={canSubmit}
        showSeparator={this.showSeparator}
        coordinatorFactor={this.state.coordinatorFactor}
        dbServerFactor={this.state.dbServerFactor}
        onCPUFactorChange={this.onCPUFactorChange}
        memCoordinatorFactor={this.state.memCoordinatorFactor}
        memDBServerFactor={this.state.memDBServerFactor}
        onMemoryFactorChange={this.onMemoryFactorChange}
        onCPURatioChange={this.onCPURatioChange}
        cpuRatioCoordinator={this.state.cpuRatioCoordinator}
        cpuRatioDbServer={this.state.cpuRatioDbServer}
        onSchedulingPoliciesChange={this.onSchedulingPoliciesChange}
        schedulingPoliciesID={this.state.schedulingPoliciesIDs}
        topologyAware={this.state.topologyAware}
        onTopologyAwareChanged={this.onTopologyAwareChange}
        numberOfZonesOverride={this.state.numberOfZonesOverride}
        onNumberOfZonesOverrideChanged={this.onNumberOfZonesOverrideChange}
        topologyAwarenessSettings={this.state.topologyAwarenessSettings}
        handleSetTopologyAwarenessSettings={this.handleSetTopologyAwarenessSettings}
        doNotUsePlatformAuthentication={this.state.doNotUsePlatformAuthentication}
        onDoNotUsePlatformAuthenticationChanged={this.onDoNotUsePlatformAuthenticationChanged}
        doNotUseShardRebalancer={this.state.doNotUseShardRebalancer}
        onDoNotUseShardRebalancerChanged={this.onDoNotUseShardRebalancerChanged}
        useLocalSSD={this.state.useLocalSSD}
      />
    );
  }
}

export default withRefresh()(DeploymentProfileModal);
