//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

class CacheEntry<T> {
  entry?: T;
}

export default class Cache<T> {
  private cache: Map<string, CacheEntry<T>>;

  constructor() {
    this.cache = new Map<string, CacheEntry<T>>();
  }

  has = (id: string): boolean => {
    return this.cache.has(id);
  };

  get = (id: string): T | undefined => {
    const cached = this.cache.get(id);
    if (cached) {
      return cached.entry;
    }
    return undefined;
  };

  set = (id: string, entry?: T) => {
    this.cache.set(id, { entry: entry } as CacheEntry<T>);
  };
}
