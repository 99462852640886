//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

export const isForbidden = (e: any) => {
  return e.status == 403;
};

export const isNotFound = (e: any) => {
  return e.status == 404;
};
