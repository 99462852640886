//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, Invoice as ApiInvoice } from "../../api/lib";
import { Routes } from "../../routes";
import { TextLink } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";

// Interface decribing the properties of the invoice link component
interface IInvoiceLinkProps extends IWithRefreshProps {
  invoice_id?: string;
  onInvoiceSelected: (id: string) => void;
}

// Interface decribing the state of the invoice link component
interface IInvoiceLinkState {
  invoice?: ApiInvoice;
  invoice_id?: string;
  refreshNeeded: boolean;
}

// The component to show the invoice as a link.
class InvoiceLink extends Component<IInvoiceLinkProps, IInvoiceLinkState> {
  state: IInvoiceLinkState = {
    invoice: undefined,
    invoice_id: undefined,
    refreshNeeded: false,
  };

  reloadInvoice = async () => {
    if (!!this.props.invoice_id) {
      const req: ApiIDOptions = { id: this.props.invoice_id };
      const invoice = await apiClients.idashboardClient.GetInvoice(req);
      this.setState({ invoice: invoice });
    } else {
      this.setState({ invoice: undefined });
    }
  };

  refreshInvoice = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadInvoice);
  };

  static getDerivedStateFromProps(props: IInvoiceLinkProps, state: IInvoiceLinkState) {
    if (props.invoice_id != state.invoice_id) {
      return {
        invoice: undefined,
        refreshNeeded: true,
        invoice_id: props.invoice_id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshInvoice();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshInvoice);
    }
  }

  render() {
    const has_invoice = !!this.props.invoice_id;
    const invoice = this.state.invoice || {};
    if (!has_invoice) {
      return <span>-</span>;
    }
    return <TextLink href={Routes.dashboard_sales_invoices_detailsWithId(this.props.invoice_id as string)}>{invoice.invoice_number || "?"}</TextLink>;
  }
}

export default withRefresh()(InvoiceLink);
