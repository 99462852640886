//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
// Author Ewout Prangsma
//

export enum ResourceType {
  Backup,
  BackupPolicy,
  CACertificate,
  DataCluster,
  Deployment,
  Group,
  IAMProvider,
  IPAllowlist,
  Policy,
  Role,
  OrganizationInvite,
  Organization,
  Project,
  AuditLog,
  AuditLogArchive,
  AuditLogArchiveChunk,
}
