//
// DISCLAIMER
//
// Copyright 2021-2023 ArangoDB GmbH, Cologne, Germany
//

import { toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { ErrorMessage } from "../../ui/lib";
import CommentInputField from "../comments/CommentInputField";
import { CommentCreationArgs } from "../comments/CommentTypes";

interface IVolumeInfoViewArgs extends CommentCreationArgs {
  title: string;
  volumeType?: string;
  volumeIOPS?: number;
  volumeThroughput?: number;
  canEdit: boolean;
  onEdit: () => void;
  editing: boolean;
  onCancelEdit: () => void;
  onSaveEdit: (newVolumeType: string, newVolumeIOPS: number, newVolumeThroughput: number) => Promise<{ errorMessage: string }>;
  processingUpdate: boolean;
}

export const VolumeInfoView = ({ ...args }: IVolumeInfoViewArgs) => {
  const [volumeType, setVolumeType] = useState<string>(args.volumeType || "");
  const [volumeIOPS, setVolumeIOPS] = useState<number>(args.volumeIOPS || 0);
  const [volumeThroughput, setVolumeThroughput] = useState<number>(args.volumeThroughput || 0);
  const [error, setError] = React.useState<string>("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  useEffect(() => {
    let newComment = "";
    if (args.volumeType !== volumeType && volumeType !== "") {
      newComment = `${args.title} Volume Info: Type changed to ${volumeType}`;
    }
    if (args.volumeIOPS !== volumeIOPS && volumeIOPS !== 0) {
      newComment = `${!!newComment ? `${newComment} \n` : ""}${args.title} Volume Info: IOPS changed to ${volumeIOPS}`;
    }
    if (args.volumeThroughput !== volumeThroughput && volumeThroughput !== 0) {
      newComment = `${!!newComment ? `${newComment} \n` : ""}${args.title} Volume Info: Throughput changed to ${volumeThroughput}`;
    }
    setComment(newComment);
  }, [volumeIOPS, volumeType, volumeThroughput]);

  const handleVolumeTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolumeType(e.target.value);
  };
  const handleVolumeIOPSChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolumeIOPS(toNumber(e.target.value));
  };
  const handleVolumeThroughputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolumeThroughput(toNumber(e.target.value));
  };

  const handleSave = () => {
    setLoading(true);

    const req = (!commentCreationFailed && args.onSaveEdit(volumeType, volumeIOPS, volumeThroughput)) || ({} as Promise<{ errorMessage: string }>);

    req.then(async (res) => {
      const { errorMessage } = res;
      if (errorMessage) {
        setError(errorMessage);
        setLoading(false);
      } else {
        const { error: commentError } = await args.createComment(comment);

        if (commentError) {
          setError(commentError);
          setCommentCreationFailed(true);
        }
        setLoading(false);
      }
    });
  };

  const hasChanges = () => {
    return args.volumeType != volumeType || args.volumeIOPS != volumeIOPS || args.volumeThroughput != volumeThroughput;
  };

  return (
    <span>
      <div>
        <span>Type: {args.volumeType || "default"},</span>
        <br />
        <span>IOPS: {args.volumeIOPS || "default"},</span>
        <br />
        <span>Throughput: {args.volumeThroughput || "default"}</span>
        {args.canEdit && (
          <span>
            <Icon
              name="pencil"
              onClick={() => {
                args.onEdit();
              }}
              className="edit-pencil"
            />
          </span>
        )}
      </div>
      <Modal open={args.editing} size="tiny">
        <Modal.Header content={`Edit ${args.title} Volume Info`} />
        <Modal.Content>
          <Modal.Description>
            <ErrorMessage active={!!error} message={error} onDismiss={() => setError("")} />
            <Form>
              <Form.Input label="Type" value={volumeType} onChange={handleVolumeTypeChange} disabled={commentCreationFailed} />
              <Form.Input label="IOPS" value={volumeIOPS} onChange={handleVolumeIOPSChange} type="number" disabled={commentCreationFailed} />
              <Form.Input label="Throughput" value={volumeThroughput} onChange={handleVolumeThroughputChange} type="number" disabled={commentCreationFailed} />
              <Form.Field required>
                <label>Please provide the reason for this change</label>

                <CommentInputField
                  handleAddComment={() => {}}
                  handleCommentChange={(value: string) => setComment(value)}
                  commentCreationInProcess={false}
                  comment={comment}
                  showOnlyInputField
                />
                <sub>
                  Comment box will be empty if there is no change in the <b>Volume Info settings</b>. Otherwise, a default comment will be provided which can be
                  changed if required.
                </sub>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={args.onCancelEdit} negative content="Cancel" />
          {commentCreationFailed ? (
            <Button
              onClick={handleSave}
              positive
              labelPosition="right"
              icon="comment"
              content="Retry saving comment"
              loading={loading}
              disabled={!comment.trim()}
            />
          ) : (
            <Button
              onClick={handleSave}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Save"
              loading={loading}
              disabled={!hasChanges() || !comment.trim()}
            />
          )}
        </Modal.Actions>
      </Modal>
    </span>
  );
};
