//
// DISCLAIMER
//
// Copyright 2020-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { User as ApiUser } from "../../api/lib";
import { Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { LoaderBox } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";

// Interface decribing the properties of the user component
interface IUserNameWithIdProps extends IWithRefreshProps {
  userId?: string;
}

// Interface decribing the state of the user component
interface IUserNameWithIdState {
  user?: ApiUser;
}

class UserNameWithId extends Component<IUserNameWithIdProps, IUserNameWithIdState> {
  state: IUserNameWithIdState = {
    user: undefined,
  };

  reloadUser = async () => {
    const req: ApiIDOptions = { id: this.props.userId };
    const user = await apiClients.idashboardClient.GetUser(req);
    this.setState({ user: user });
  };

  refreshUser = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadUser);
  };

  componentDidMount() {
    this.refreshUser();
  }

  componentDidUpdate(prevProps: IUserNameWithIdProps) {
    if (prevProps.userId != this.props.userId) {
      this.refreshUser();
    }
  }

  render() {
    if (!this.state.user) {
      return (
        <LoaderBox>
          <Loader size="mini" active={true} inline />
        </LoaderBox>
      );
    }
    return <span>{this.state.user.name || "-"}</span>;
  }
}

export default withRefresh()(UserNameWithId);
