//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Dropdown, DropdownProps, Input, Popup, Table } from "semantic-ui-react";
import { MemberLogLevel } from "../../api/lib";
import { FlexBox } from "../../ui/lib";
import moment from "moment";
import { DateTimeTransformationTypes, FormattedDateWithLabel } from "../../util/dateAndTimeUtils/DateTime";

const LogLevelList = ({
  memberLogLevels,
  setMemberLogLevels,
  setErrorMessage,
}: {
  memberLogLevels: MemberLogLevel[];
  setMemberLogLevels: Dispatch<SetStateAction<MemberLogLevel[]>>;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [expireAt, setExpireAt] = useState<string | undefined>(undefined);
  const levelValues = ["TRACE", "DEBUG", "INFO", "WARN", "ERROR", "FATAL"];

  const levelValueOptions = levelValues.map((level, i) => {
    return {
      key: `value-${level}`,
      text: level,
      value: level,
    };
  });

  const onRemoveLogLevel = async ({ topic, role }: { topic: MemberLogLevel["topic"]; role: MemberLogLevel["role"] }) => {
    const newLevels = memberLogLevels.filter((memberLogLevel) => memberLogLevel.topic !== topic || memberLogLevel.role !== role) || [];
    setMemberLogLevels(newLevels);
  };

  const onExpireAtChanged = ({ topic, role, expire_at }: MemberLogLevel) => {
    const newLevels = memberLogLevels.map((memberLogLevel) => {
      if (memberLogLevel.topic === topic && memberLogLevel.role === role) {
        return { ...memberLogLevel, expire_at: expire_at ? expire_at : undefined };
      }
      return memberLogLevel;
    });
    setMemberLogLevels(newLevels);
  };

  const onLevelChanged = ({ topic, level, role }: MemberLogLevel) => {
    const newLevels = memberLogLevels.map((memberLogLevel) => {
      if (memberLogLevel.topic === topic && memberLogLevel.role === role) {
        return { ...memberLogLevel, level };
      }
      return memberLogLevel;
    });
    setMemberLogLevels(newLevels);
  };

  if (_.isEmpty(memberLogLevels)) {
    return <div>No components</div>;
  }

  return (
    <Table striped compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Component</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell>Level</Table.HeaderCell>
          <Table.HeaderCell>Expires at (DD/MM/YYYY HH:mm)</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {memberLogLevels.map(({ topic, level, expire_at, role }, idx) => (
          <Table.Row key={`row${idx}`}>
            <Table.Cell>{topic}</Table.Cell>
            <Table.Cell>{role ? role : "all"}</Table.Cell>
            <Table.Cell>
              <Dropdown
                fluid
                value={level}
                text={level}
                options={levelValueOptions}
                onChange={(_, data: DropdownProps) => {
                  onLevelChanged({ topic, level: data.value as string, role });
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <FlexBox justify="space-between" align="center">
                <FormattedDateWithLabel label=" " format={"DD/MM/YYYY HH:mm"} dateTime={expire_at} transformTo={DateTimeTransformationTypes.UTC} />
                <FormattedDateWithLabel label=" " format={"DD/MM/YYYY HH:mm"} dateTime={expire_at} transformTo={DateTimeTransformationTypes.LOCAL} />
                <Popup
                  content={
                    <FlexBox direction="column">
                      <label>Expires at (local time):</label>
                      <FlexBox margin="10px 0 0 0">
                        <Input
                          type="datetime-local"
                          value={expire_at}
                          onChange={(e) => {
                            if (moment(e.target.value).isAfter(moment())) {
                              setExpireAt(e.target.value);
                            } else {
                              setErrorMessage("Expiration date cannot be in the past");
                            }
                          }}
                        />
                        <Button onClick={() => onExpireAtChanged({ topic, role, expire_at: expireAt ? moment(expireAt).toDate() : undefined })} type="button">
                          Set date
                        </Button>
                      </FlexBox>
                    </FlexBox>
                  }
                  on="click"
                  pinned
                  position="top center"
                  trigger={
                    <span>
                      <Button icon="clock" type="button" basic circular size="tiny" />
                    </span>
                  }
                />
              </FlexBox>
            </Table.Cell>
            <Table.Cell>
              <Button type="button" icon="trash" basic size="tiny" circular onClick={() => onRemoveLogLevel({ topic, role })} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export { LogLevelList };
