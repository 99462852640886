//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import { Component } from "react";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ListPlanAssignmentsRequest as ApiListPlanAssignmentsRequest,
  PlanAssignment as ApiPlanAssignment,
  PlanAssignmentList as ApiPlanAssignmentList,
  Subject as ApiSubject,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, LoaderBoxForTable as LoaderBox, PagingButtons, ErrorMessage } from "../../ui/lib";
import PricingPlanAssignmentRow from "./PricingPlanAssignmentRow";

// Arguments for header view
interface IHeaderView {
  loading: boolean;
  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Subject</Table.HeaderCell>
      <Table.HeaderCell>Discounts</Table.HeaderCell>
      <Table.HeaderCell>Period</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <PagingButtons {...args} />
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing an organization
// Interface describing the pricing plan assignment list
interface IListView extends IWithRefreshProps, RouteComponentProps {
  items: ApiPlanAssignment[];
  loading: boolean;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} count={args.items.length} />
    <Table.Body>
      {args.items.map((item) => (
        <PricingPlanAssignmentRow {...args} key={item.id} item={item} api={apiClients.idashboardClient} />
      ))}
    </Table.Body>
  </Table>
);

interface IEmptyView {
  page: number;
}

const EmptyView = ({ ...args }: IEmptyView) => <div>{args.page > 0 ? "No more pricing plan assignments" : "No pricing plan assignments selected"}</div>;

// Interface describing the pricing plan assignment list view arguments
export interface IPricingPlanAssignmentListViewArgs extends IWithRefreshProps, RouteComponentProps {
  loading: boolean;
  assignments?: ApiPlanAssignmentList;
  page: number;
  pageSize: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

export const PricingPlanAssignmentListView = ({ ...args }: IPricingPlanAssignmentListViewArgs) => {
  if (!args.assignments) {
    return <Loading />;
  }
  const isEmpty = !args.assignments.items || args.assignments.items.length === 0;
  const assignments = args.assignments ? args.assignments.items : undefined;
  return (
    <div>
      <ListView {...args} items={assignments || []} />
      {isEmpty && <EmptyView page={args.page} />}
    </div>
  );
};

// Interface decribing the properties of the pricing plan assignment list component
interface IPricingPlanAssignmentListProps extends IWithRefreshProps, RouteComponentProps {
  plan_id?: string;
  subject?: ApiSubject;
  version: number; // increment to reload
}

// Interface decribing the state of the pricing plan assignment list component
interface IPricingPlanAssignmentListState {
  assignments?: ApiPlanAssignmentList;
  page: number;
  pageSize: number;
  version: number;
  refreshNeeded: boolean;
}

// The component to show pricing plan assignments as a list.
class PricingPlanAssignmentList extends Component<IPricingPlanAssignmentListProps, IPricingPlanAssignmentListState> {
  state: IPricingPlanAssignmentListState = {
    assignments: undefined,
    page: 0,
    pageSize: 10,
    version: 0,
    refreshNeeded: false,
  };

  static getDerivedStateFromProps(props: IPricingPlanAssignmentListProps, state: IPricingPlanAssignmentListState) {
    if (props.version != state.version) {
      return {
        version: props.version,
        refreshNeeded: true,
      };
    }

    // No state update necessary
    return null;
  }

  reloadAssignments = async () => {
    var req: ApiListPlanAssignmentsRequest = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    if (this.props.plan_id) {
      req.plan_id = this.props.plan_id;
    }
    if (this.props.subject) {
      req.subject = this.props.subject;
    }
    const assignments = await apiClients.idashboardClient.ListPlanAssignments(req);
    this.setState({ assignments: assignments });
  };

  refreshAssignments = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAssignments);
  };

  componentDidMount() {
    this.refreshAssignments();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshAssignments);
    }
  }

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshAssignments
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshAssignments
    );
  };

  render() {
    const page = this.state.page;

    return (
      <div>
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <PricingPlanAssignmentListView
          {...this.state}
          onNextPage={this.onNextPage}
          onPreviousPage={page > 0 ? this.onPreviousPage : undefined}
          {...this.props}
        />
      </div>
    );
  }
}

export default withRefresh()(PricingPlanAssignmentList);
