//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Loader, Menu, Segment, Tab, Button, Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, AuditLog as ApiAuditLog } from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  ErrorMessage,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  LoaderBox,
  MenuActionBack,
  SecondaryMenu,
  Section,
  SectionContent,
  TextLink,
} from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import AuditLogDestinationList from "./AuditLogDestinationList";
import AuditLogArchiveInfoList from "./AuditLogArchiveInfoList";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

interface IAuditLogDetailsViewArgs {
  auditLog: ApiAuditLog;
  onAuditLogSelected: (id: string) => void;
  canDeleteAuditLog?: boolean;
  onAuditLogDelete?: (id: string) => void;
}

export const AuditLogDetailsView = ({ ...args }: IAuditLogDetailsViewArgs) => {
  const name = args.auditLog.name || "-";
  const desc = args.auditLog.description || "-";
  const is_default = args.auditLog.is_default ? (args.auditLog.is_default ? "Yes" : "No") : "No";
  const is_deleted = !!args.auditLog.is_deleted;
  // TODO: add a bunch of other things, like enable, disable etc...
  return (
    <Segment>
      <Grid width="16">
        <Grid.Column width="6">
          <Field>
            <FL>Name</FL>
            <FC>{name}</FC>
          </Field>
          <Field>
            <FL>Description</FL>
            <FC>{desc}</FC>
          </Field>
          <Field>
            <FL>ID</FL>
            <FC>
              <TextLink label={args.auditLog.id} href={Routes.dashboard_support_auditlog_detailsWithId(args.auditLog.id as string)} />
            </FC>
          </Field>
          <Field>
            <FL>Created</FL>
            <FC>{args.auditLog.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.auditLog.created_at} label="Created at" /> : "-"}</FC>
          </Field>
          {is_deleted && (
            <Field>
              <FL>Deleted</FL>
              <FC>{args.auditLog.deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.auditLog.deleted_at} label="Deleted at" /> : "-"}</FC>
            </Field>
          )}
          <Field>
            <FL>Default</FL>
            <FC>{is_default}</FC>
          </Field>
          {args.canDeleteAuditLog && (
            <Field>
              <FL>{is_deleted ? "Re-Delete" : "Delete"}</FL>
              <FC>
                <Button color="red" size="tiny" circular icon onClick={() => args.onAuditLogDelete && args.onAuditLogDelete(args.auditLog.id as string)}>
                  <Icon name="trash" />
                </Button>
              </FC>
            </Field>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

interface IAuditLogDetailsProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  onAuditLogSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  onProjectSelected: (id: string) => void;
}

interface IAuditLogDetailsState {
  auditLogId?: string;
  auditLog?: ApiAuditLog;
  errorMessage?: string;
}

class AuditLogDetails extends Component<IAuditLogDetailsProps, IAuditLogDetailsState> {
  state: IAuditLogDetailsState = {
    auditLogId: undefined,
    auditLog: undefined,
    errorMessage: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadAuditLog = async () => {
    try {
      const req: ApiIDOptions = {
        id: this.state.auditLogId,
      };
      const auditLog = await apiClients.idashboardClient.GetAuditLog(req);
      if (auditLog) {
        this.setState({ auditLog: auditLog });
      }
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  refreshAuditLog = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLog);
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    const auditLogId = (this.props.match.params as any).auditLogId;
    this.setState(
      {
        auditLogId: auditLogId,
      },
      () => {
        this.props.refreshNow && this.props.refreshNow(this.reloadAuditLog);
      }
    );
  }

  onAuditLogDelete = async (id: string) => {
    try {
      const req: ApiIDOptions = {
        id: this.state.auditLogId,
      };
      await apiClients.idashboardClient.DeleteAuditLog(req);
    } catch (e) {
      this.setState({ errorMessage: e });
    }
    this.refreshAuditLog();
  };

  render() {
    const auditLog = this.state.auditLog;
    const canDeleteAuditLog = this.hasPermission("internal-dashboard.auditlog.delete");
    return (
      <div>
        <ContentSegment>
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <SecondaryMenu>
            <MenuActionBack />
            <Menu.Item header>AuditLog Details</Menu.Item>
            <LoaderBox>
              <Loader size="mini" active={this.props.loading} inline />
            </LoaderBox>
          </SecondaryMenu>
          {auditLog && (
            <Section>
              <SectionContent>
                <AuditLogDetailsView
                  {...this.props}
                  {...this.state}
                  auditLog={auditLog}
                  canDeleteAuditLog={canDeleteAuditLog}
                  onAuditLogDelete={this.onAuditLogDelete}
                />
              </SectionContent>
            </Section>
          )}
          {auditLog && (
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: "Destinations",
                  render: () => <AuditLogDestinationList {...this.props} auditLog={auditLog} />,
                },
                {
                  menuItem: "Archives",
                  render: () => <AuditLogArchiveInfoList {...this.props} auditLog={auditLog} with_deployment_id_flipper />,
                },
              ]}
            />
          )}
        </ContentSegment>
      </div>
    );
  }
}

export default withRefresh()(AuditLogDetails);
