//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { Component } from "react";
import { Form, Message, Modal } from "semantic-ui-react";
import { PlanAssignment as ApiPlanAssignment, Subject as ApiSubject } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { DateInput, Processing, FormActionButtonCancel, FormActionButtonSave, NumberInput, momentNow } from "../../ui/lib";
import moment, { Moment } from "moment";

interface IHeaderViewArgs {
  subject: ApiSubject;
}

const HeaderView = ({ ...args }: IHeaderViewArgs) => {
  if (!!args.subject.organization_id) {
    if (!!args.subject.project_id) {
      if (!!args.subject.deployment_id) {
        return (
          <div>
            Create pricing plan assignment for deployment {args.subject.deployment_id} in project {args.subject.project_id} of organization{" "}
            {args.subject.organization_id}
          </div>
        );
      }
      return (
        <div>
          Create pricing plan assignment for project {args.subject.project_id} of organization {args.subject.organization_id}
        </div>
      );
    }
    return <div>Create pricing plan assignment for organization {args.subject.organization_id}</div>;
  }
  return <div>Create pricing plan assignment for all organizations</div>;
};

interface IAssignmentViewArgs extends IWithRefreshProps, RouteComponentProps {
  starts_at: Moment | null;
  onStartsAtChange: (date: Date) => void;
  ends_at: Moment | null;
  onEndsAtChange: (date: Date | null) => void;
  showDiscounts: boolean;
  discount_license_memory_percent: number;
  discount_license_disk_percent: number;
  onChangeDiscountLicenseMemoryPercent: (value: number) => void;
  onChangeDiscountLicenseDiskPercent: (value: number) => void;
}

const AssignmentView = ({ ...args }: IAssignmentViewArgs) => (
  <Form>
    <DateInput name="starts_at" label="Starts at" value={args.starts_at} onChange={args.onStartsAtChange} required />
    <DateInput name="ends_at" label="Ends at" value={args.ends_at} onChange={args.onEndsAtChange} endOfDay />
    {args.showDiscounts && (
      <div>
        <h2>Discounts</h2>
        <Form.Field>
          <label>License-memory</label>
          <NumberInput min={0} max={100} ext="%" value={args.discount_license_memory_percent} onChange={args.onChangeDiscountLicenseMemoryPercent} />
        </Form.Field>
        <Form.Field>
          <label>License-disk</label>
          <NumberInput min={0} max={100} ext="%" value={args.discount_license_disk_percent} onChange={args.onChangeDiscountLicenseDiskPercent} />
        </Form.Field>
      </div>
    )}
  </Form>
);

interface IAPI {
  CreatePlanAssignment: (req: ApiPlanAssignment) => Promise<ApiPlanAssignment>;
}

// Interface decribing the properties of the pricing plan assignment creator component
interface ICreatePricingPlanAssignmentProps extends IWithRefreshProps, RouteComponentProps {
  api: IAPI;
  plan_id: string;
  subjects?: ApiSubject[];
  onClose: () => void;
}

// Interface decribing the state of the pricing plan assignment creator component
interface ICreatePricingPlanAssignmentState {
  starts_at: Moment | null;
  ends_at: Moment | null;
  discount_license_memory_percent: number;
  discount_license_disk_percent: number;
  errorMessage?: string;
  creating: boolean;
}

// The component to pricing plan assignments
class CreatePricingPlanAssignment extends Component<ICreatePricingPlanAssignmentProps, ICreatePricingPlanAssignmentState> {
  state: ICreatePricingPlanAssignmentState = {
    starts_at: null,
    ends_at: null,
    errorMessage: undefined,
    discount_license_memory_percent: 0,
    discount_license_disk_percent: 0,
    creating: false,
  };

  onStartsAtChange = (date: Date | null) => {
    this.setState({ starts_at: date != null ? moment(date) : null });
  };
  onEndsAtChange = (date: Date | null) => {
    this.setState({ ends_at: date != null ? moment(date) : null });
  };

  onChangeDiscountLicenseMemoryPercent = (value: number) => {
    this.setState({ discount_license_memory_percent: value });
  };
  onChangeDiscountLicenseDiskPercent = (value: number) => {
    this.setState({ discount_license_disk_percent: value });
  };

  onSave = async () => {
    const starts_at = this.state.starts_at || momentNow();
    const ends_at = this.state.ends_at;
    try {
      this.setState({ creating: true, errorMessage: undefined });
      const subjects = this.props.subjects || [];
      subjects.forEach(async (subject) => {
        const req: ApiPlanAssignment = {
          plan_id: this.props.plan_id,
          starts_at: starts_at.toDate(),
          subject: subject,
          discounts: {
            license_memory: this.state.discount_license_memory_percent / 100,
            license_disk: this.state.discount_license_disk_percent / 100,
          },
        };
        if (ends_at) {
          req.ends_at = ends_at.toDate();
        }
        await this.props.api.CreatePlanAssignment(req);
      });
      this.props.onClose();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ creating: false });
    }
  };

  render() {
    const all_valid = !!this.state.starts_at && this.state.starts_at.isValid() && (!this.state.ends_at || this.state.ends_at.isValid());
    const subjects = this.props.subjects || [];
    const showDiscounts = subjects.every((x) => !x.all);
    return (
      <Modal open>
        <Modal.Header>
          {subjects.map((x) => (
            <HeaderView subject={x} />
          ))}
        </Modal.Header>
        <Modal.Content>
          <Processing active={this.state.creating} message="Creating assignment..." />
          {this.state.errorMessage && <Message error content={`${this.state.errorMessage}`} />}
          <AssignmentView
            {...this.props}
            {...this.state}
            showDiscounts={showDiscounts}
            onStartsAtChange={this.onStartsAtChange}
            onEndsAtChange={this.onEndsAtChange}
            onChangeDiscountLicenseDiskPercent={this.onChangeDiscountLicenseDiskPercent}
            onChangeDiscountLicenseMemoryPercent={this.onChangeDiscountLicenseMemoryPercent}
          />
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel onClick={this.props.onClose} />
          <FormActionButtonSave onClick={this.onSave} disabled={!all_valid && !this.state.creating} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRefresh()(CreatePricingPlanAssignment);
