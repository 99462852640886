//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Table, Button } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { map } from "lodash";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { Organization, IDOptions } from "../../api/lib";
import { TextLink, Confirm, ConfirmInfo, ErrorMessage, ContentSegment } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";

import { DeploymentProfile } from "../../api/deployment-profile/v1/deploymentprofile";

import { DeploymentProfileInfo, ListDeploymentProfileInfosRequest } from "../../api/deployment-profile/v1/ideploymentprofile";
import DeploymentProfileModal from "../deployment-profile/DeploymentProfileModal";

export interface IOrganizationDeploymentProfileProps extends IWithRefreshProps, RouteComponentProps {
  organization: Organization;
}

interface IOrganizationDeploymentProfileState {
  processing: boolean;
  items?: DeploymentProfileInfo[];
  errorMessage?: string;
  editing: boolean;
  currentItem?: DeploymentProfileInfo;
  deletingItem?: DeploymentProfile;
  confirmInfo?: ConfirmInfo;
}

class OrganizationDeploymentProfileView extends Component<IOrganizationDeploymentProfileProps, IOrganizationDeploymentProfileState> {
  state: IOrganizationDeploymentProfileState = {
    errorMessage: undefined,
    processing: false,
    items: undefined,
    editing: false,
    currentItem: undefined,
    deletingItem: undefined,
    confirmInfo: undefined,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadItems = async () => {
    this.setState({ processing: true });
    const req: ListDeploymentProfileInfosRequest = { organization_id: this.props.organization.id };
    try {
      const list = await apiClients.idashboardClient.ListDeploymentProfileInfos(req);
      this.setState({ items: list.items });
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processing: false });
    }
  };
  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadItems, this.props.organization.url + "/DeploymentProfile/*");
  }

  deleteDeploymentProfile = ({ deployment_profile }: DeploymentProfileInfo) => {
    const confirmInfo: ConfirmInfo = {
      header: "Delete Deployment Profile",
      content: `Are you sure that you want Deployment Profile to be deleted?`,
      onDenied: () => {
        this.setState({ confirmInfo: undefined, deletingItem: undefined });
      },
      onConfirm: async () => {
        try {
          let id = deployment_profile && deployment_profile.id;
          const req: IDOptions = { id: id };
          await apiClients.idashboardClient.DeleteDeploymentProfile(req);
        } catch (error) {
          this.setState({ errorMessage: error });
        } finally {
          this.setState({ confirmInfo: undefined, deletingItem: undefined });
        }
        this.reloadItems();
      },
    };
    this.setState({
      deletingItem: deployment_profile,
      confirmInfo: confirmInfo,
    });
  };

  createDeploymentProfile = () => {
    this.setState({ editing: true, currentItem: undefined });
  };

  editDeploymentProfile = (item?: DeploymentProfileInfo) => {
    this.setState({ editing: true, currentItem: item });
  };

  render() {
    const canUpdate = this.hasPermission("internal-dashboard.deploymentprofile.update");
    const canCreate = this.hasPermission("internal-dashboard.deploymentprofile.create");
    const canDelete = this.hasPermission("internal-dashboard.deploymentprofile.delete");

    const Row = (item: DeploymentProfileInfo) => {
      const deployment_profile = item.deployment_profile || {};

      return (
        <Table.Row key={deployment_profile.id}>
          <Table.Cell>
            {canUpdate && <TextLink onClick={() => this.editDeploymentProfile(item)} label={deployment_profile.id} />}
            {!canUpdate && item.deployment_profile && item.deployment_profile.id}
          </Table.Cell>
          <Table.Cell>
            <span title={deployment_profile.description}>{deployment_profile.name}</span>
          </Table.Cell>
          <Table.Cell>
            {deployment_profile.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={deployment_profile.created_at} label="Created at" /> : "-"}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {canUpdate && <Button icon="edit" size="tiny" basic title="Update deployment profile" onClick={() => this.editDeploymentProfile(item)} />}
            {canDelete && <Button icon="trash" size="tiny" basic onClick={() => this.deleteDeploymentProfile(item)} />}
          </Table.Cell>
        </Table.Row>
      );
    };

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Created at</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {canCreate && <Button icon="plus" size="tiny" basic onClick={this.createDeploymentProfile} />}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{map(this.state.items, Row)}</Table.Body>
        </Table>
        {this.state.editing && (
          <DeploymentProfileModal
            loading={this.props.loading}
            permissionCache={this.props.permissionCache}
            eventSubscriptionManager={this.props.eventSubscriptionManager}
            deploymentProfileItem={this.state.currentItem}
            organizationID={this.props.organization.id || ""}
            onClose={() => {
              this.setState({ editing: false, currentItem: undefined });
              this.reloadItems();
            }}
          />
        )}
        {this.state.deletingItem && <Confirm confirmInfo={this.state.confirmInfo} />}
      </ContentSegment>
    );
  }
}

export default withRefresh()(OrganizationDeploymentProfileView);
