//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//
import React, { useEffect, useState } from "react";
import { ContentSegment, Field, FieldLabelWide as FL, FieldContent as FC, LoaderBox, SecondaryMenu } from "../../ui/_style";
import apiClients from "../../api/apiclients";
import { Condition, UpdateJob } from "../../api/deployment-updater/v1/ideploymentupdater";
import { Grid, Header, Icon, Loader, Menu, Segment } from "semantic-ui-react";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { RouteComponentProps, useParams } from "react-router-dom";
import { ITabConfig } from "../../components/Tab/TabTypes";
import TabLayoutWithRouter from "../../components/Tab/TabWithRouter";
import { IDOptions as ApiIDOptions, MemoryReserve as ApiMemoryReserve } from "../../api/lib";
import history from "../../history";
import { Routes } from "../../routes";
import ConditionsView from "./ConditionsView";
import DeploymentUpdaterStatusView from "./DeploymentUpdaterStatusView";
import { TextLink, ErrorMessage } from "../../ui/lib";
import AssignedFiltersForJobView from "./AssignedFiltersForJobView";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import UpdateModalView from "./UpdateModalView";

const DeploymentUpdaterDetailsView = (props: IWithRefreshProps & RouteComponentProps) => {
  const [jobDetails, setJobDetail] = useState<UpdateJob>({});
  const { updateJobId: id } = useParams<{ updateJobId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [showFilterDetailsView, toggleFitlerDetailsView] = useState(false);
  const [showEditModal, toggleEditModal] = useState(false);

  const getJobDetails = async () => {
    setLoading(true);
    try {
      const apiReq: ApiIDOptions = {
        id,
      };
      const response = await apiClients.idashboardClient.GetDeploymentUpdateJob(apiReq);
      setJobDetail(response);
      console.log(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { subscribeUrl } = props;
    subscribeUrl && subscribeUrl(getJobDetails);
  }, []);

  const getMemoryReserve = (memoryReserve: ApiMemoryReserve) => {
    const { absolute, relative } = memoryReserve;
    let view = "";
    if (absolute) {
      view += `Absolute - ${absolute} / `;
    }
    if (relative) {
      view += `Relative - ${relative}`;
    }

    return view;
  };

  const getTabConfig = (): ITabConfig[] => {
    const { status = {} } = jobDetails;
    const { deployment_status: deploymentStatus, conditions } = status;

    const panes = [
      {
        menuItem: { id: "deploymentStatus", route: "deploymentStatus", children: "Deployment status" },
        content: () => <DeploymentUpdaterStatusView deploymentStatus={deploymentStatus as any} />,
      },
      {
        menuItem: { id: "conditions", route: "conditions", children: "Conditions" },
        content: () => <ConditionsView conditions={conditions as Required<Condition[]>} />,
      },
    ];

    return panes;
  };
  return (
    <ContentSegment>
      <SecondaryMenu>
        <Menu.Item onClick={() => history.push(Routes.dashboard_deployment_update_job)}>
          <Icon name="arrow left" />
        </Menu.Item>

        <Menu.Item header>
          <span>Update job details</span>
          <Loader size="mini" active={loading} inline />
        </Menu.Item>
        <LoaderBox>
          <Loader size="mini" active={false} inline />
        </LoaderBox>
      </SecondaryMenu>
      <ErrorMessage active={!!error} message={error} />
      {showEditModal && <UpdateModalView jobDetails={jobDetails} onClose={() => toggleEditModal(false)} />}
      {showFilterDetailsView && (
        <AssignedFiltersForJobView
          onClose={() => {
            toggleFitlerDetailsView(false);
          }}
          deploymentFilters={(jobDetails.req || {}).deployment_filter}
        />
      )}
      <Segment>
        <Grid columns={16}>
          <Grid.Column width={6}>
            <Header sub>General details</Header>
            <Field>
              <FL>ID</FL>
              <FC>
                <TextLink label={id || "-"} />
              </FC>
            </Field>
            <Field>
              <FL>Created At</FL>
              <FC>
                <DateTimePopupWithUTCAndLocalTime dateTime={jobDetails.created_at} label="Created At" />
              </FC>
            </Field>
            <Field>
              <FL>Started</FL>
              <FC>{jobDetails.started ? "Yes" : "No"}</FC>
            </Field>
            <Field>
              <FL>Paused</FL>
              <FC>{jobDetails.paused ? "Yes" : "No"}</FC>
            </Field>
            <Field>
              <FL>Paused Reason</FL>
              <FC>{jobDetails.paused_reason || "-"}</FC>
            </Field>
            <Field>
              <FL>Stopped</FL>
              <FC>{jobDetails.stopped ? "Yes" : "No"}</FC>
            </Field>
            <Field>
              <FL>Stopped Reason</FL>
              <FC>{jobDetails.stopped_reason || "-"}</FC>
            </Field>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header sub>Status</Header>
            <Field>
              <FL>Running</FL>
              <FC>{(jobDetails.status || {}).is_running ? "Yes" : "No"}</FC>
            </Field>
            <Field>
              <FL>Done</FL>
              <FC>{(jobDetails.status || {}).done || "-"}</FC>
            </Field>
            <Field>
              <FL>Total</FL>
              <FC>{(jobDetails.status || {}).total || "-"}</FC>
            </Field>
            <Field>
              <FL>Current Concurrency Level</FL>
              <FC>{(jobDetails.status || {}).current_concurrency_level || "-"}</FC>
            </Field>
            <Field>
              <FL>Finished At</FL>
              <FC>
                {(jobDetails.status || {}).finished_at ? (
                  <DateTimePopupWithUTCAndLocalTime dateTime={(jobDetails.status || {}).finished_at} label="Created At" />
                ) : (
                  "-"
                )}
              </FC>
            </Field>
            <Field>
              <FL>Last updated At</FL>
              <FC>
                {(jobDetails.status || {}).last_updated_at ? (
                  <DateTimePopupWithUTCAndLocalTime dateTime={(jobDetails.status || {}).last_updated_at} label="Last updated At" />
                ) : (
                  "-"
                )}
              </FC>
            </Field>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header sub>Request Specification</Header>
            <Field>
              <FL>Max concurrency</FL>
              <FC>
                {(jobDetails.req || {}).max_concurrency || "-"}
                {"  "}
                <Icon name="pencil" onClick={() => toggleEditModal(!showFilterDetailsView)} className="cursor-pointer" />
              </FC>
            </Field>
            <Field>
              <FL>Set memory reserve</FL>
              <FC>
                <span>{(jobDetails.req || {}).set_memory_reserve ? "Yes" : "No"}</span>
              </FC>
            </Field>
            <Field>
              <FL>Set memory reserve to default</FL>
              <FC>
                <span>{(jobDetails.req || {}).set_memory_reserve_to_default ? "Yes" : "No"}</span>
              </FC>
            </Field>
            <Field>
              <FL>Memory reserve</FL>
              <FC>{getMemoryReserve((jobDetails.req || {}).memory_reserve || {})}</FC>
            </Field>
            <Field>
              <FL>Deployment filters</FL>
              <FC>
                {Object.keys((jobDetails.req || {}).deployment_filter || {}).length || "-"}
                {"  "}
                <Icon name="eye" onClick={() => toggleFitlerDetailsView(!showFilterDetailsView)} className="cursor-pointer" />
              </FC>
            </Field>
            <Field>
              <FL>Set Default Disk Performance</FL>
              <FC>
                <span>{(jobDetails.req || {}).set_default_disk_performance ? "Yes" : "No"}</span>
              </FC>
            </Field>
            <Field>
              <FL>Set Default Custom Storage Class</FL>
              <FC>
                <span>{(jobDetails.req || {}).set_default_custom_storage_class ? "Yes" : "No"}</span>
              </FC>
            </Field>
            <Field>
              <FL>Set Default Communication Method</FL>
              <FC>
                <span>{(jobDetails.req || {}).set_default_communication_method ? "Yes" : "No"}</span>
              </FC>
            </Field>
            <Field>
              <FL>Update Version</FL>
              <FC>
                <span>{(jobDetails.req || {}).update_version ? "Yes" : "No"}</span>
              </FC>
            </Field>
            <Field>
              <FL>Comment</FL>
              <FC>{(jobDetails.req || {}).comment || "-"}</FC>
            </Field>
          </Grid.Column>
        </Grid>
      </Segment>
      <TabLayoutWithRouter tabConfig={getTabConfig()} id={"deploymentUpdaterTabs"} />
    </ContentSegment>
  );
};

export default withRefresh()(DeploymentUpdaterDetailsView);
