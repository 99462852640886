//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Popup, IconProps, Table } from "semantic-ui-react";

export interface IZoneStatusView extends IconProps {
  zone: string;
  nodeName: string;
  pvName: string;
}

export const ZoneStatusView = ({ ...args }: IZoneStatusView) => (
  <Popup
    content={
      <Table basic="very" singleLine compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Node Name:</Table.Cell>
            <Table.Cell>{args.nodeName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>PV Name:</Table.Cell>
            <Table.Cell>{args.pvName}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    }
    trigger={<p>{args.zone}</p>}
  />
);
