//
// DISCLAIMER
//
// Copyright 2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useState } from "react";
import { Form, Input, InputOnChangeData, Modal } from "semantic-ui-react";
import { EditIcon, ErrorMessage, FlexBox, FormActionButtonCancel, FormActionButtonSave } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import { ResourceLimits, SetJobsResourceLimitsRequest } from "../../api/ml/v1/iml";

export const MLJobsResourceLimits = ({ deploymentID, onOverridesSave, memoryOverride = 0 }: any) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [memory, setMemory] = useState(memoryOverride || 0);
  const [open, toggleVisibility] = useState(false);

  const onSave = async () => {
    setLoading(true);
    try {
      const limits: ResourceLimits = {
        memory: memory || 0,
      };
      const req: SetJobsResourceLimitsRequest = {
        deployment_id: deploymentID,
        limits: limits,
      };
      await apiClients.idashboardClient.SetMLJobsResourceLimits(req);
      onOverridesSave && onOverridesSave({ deploymentID, memory });
      toggleVisibility(false);
      setError(undefined);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data;
    setMemory(value);
  };

  const onCancel = () => {
    toggleVisibility(false);
    setMemory(memoryOverride || 0);
    setError(undefined);
  };

  return (
    <Modal
      centered
      open={open}
      trigger={
        <span>
          {memory ? `${memory}Gi` : "-"}
          <EditIcon
            onClick={() => {
              toggleVisibility(true);
            }}
          />
        </span>
      }
    >
      <Modal.Header>
        <FlexBox justify="space-between">Override resource limits (for ML jobs)</FlexBox>
      </Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} message={error} />
        <Form>
          <Form.Field>
            <label>Memory (in Gi)</label>
            <Input type="number" value={memory} onChange={onInputChange} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onCancel} disabled={loading} />
        <FormActionButtonSave primary onClick={onSave} disabled={loading} />
      </Modal.Actions>
    </Modal>
  );
};
