//
// DISCLAIMER
//
// Copyright 2020-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { isEmpty, split } from "lodash";
import { Form, Header, Modal, TextArea, TextAreaProps } from "semantic-ui-react";
import { EditIcon, ErrorMessage, FlexBox, FormActionButtonCancel, FormActionButtonSave, StyledInlineLoader } from "../../ui/lib";
import styled from "@emotion/styled";
import { CommentCreationArgs } from "../comments/CommentTypes";
import CommentInputField from "../comments/CommentInputField";
import { AsyncResult } from "../../util/Types";
import { Model, Deployment_ModelSpec as ApiDeployment_ModelSpec } from "../../api/lib";

export interface ICustomCommandLineArgumentsViewArgs extends CommentCreationArgs {
  model?: ApiDeployment_ModelSpec;
  can_edit_custom_command_line_args: boolean;
  onSaveCustomCommandLineArguments: (agentArgs: string[], dbserverArgs: string[], coordinatorArgs: string[]) => Promise<AsyncResult<void>>;
  agents_custom_command_line_args?: string[];
  dbservers_custom_command_line_args?: string[];
  coordinators_custom_command_line_args?: string[];
}

const CmdlineArgument = styled("pre")`
  font-style: italic;
`;

export const CustomCommandLineArgumentsView = ({ ...args }: ICustomCommandLineArgumentsViewArgs) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editAgentsArgs, setAgentsArgs] = useState(args.agents_custom_command_line_args || []);
  const [editDbserversArgs, setDbserversArgs] = useState(args.dbservers_custom_command_line_args || []);
  const [editCoordinatorsArgs, setCoordinatorsArgs] = useState(args.coordinators_custom_command_line_args || []);

  const [comment, setComment] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [commentCreationFailed, setCommentCreationFailed] = useState(false);

  const model = args.model || {};
  const hasAgency = Model.hasAgency(model.model || "");
  const isCluster = Model.isCluster(model.model || "");

  const agents_args = args.agents_custom_command_line_args || [];
  const dbservers_args = args.dbservers_custom_command_line_args || [];
  const coordinators_args = args.coordinators_custom_command_line_args || [];
  const hasAgentsArgs = !isEmpty(agents_args);
  const hasDbserversArgs = !isEmpty(dbservers_args);
  const hasCoordinatorsArgs = !isEmpty(coordinators_args);
  const canEditCustomCommandLineArgs = args.can_edit_custom_command_line_args;

  useEffect(() => {
    let newComment = "";
    if (args.agents_custom_command_line_args !== editAgentsArgs) {
      newComment = `Setting agents custom command line arguments to ${editAgentsArgs.join(", ") || "none"}\n`;
    }
    if (args.dbservers_custom_command_line_args !== editDbserversArgs) {
      newComment = `${!!newComment ? `${newComment}` : ""}Setting dbservers custom command line arguments to ${editDbserversArgs.join(", ") || "none"}\n`;
    }
    if (args.coordinators_custom_command_line_args !== editCoordinatorsArgs) {
      newComment = `${!!newComment ? `${newComment}` : ""}Setting coordinators custom command line arguments to ${editCoordinatorsArgs.join(", ") || "none"}\n`;
    }
    setComment(newComment);
  }, [editAgentsArgs, editDbserversArgs, editCoordinatorsArgs]);

  const textToSplitedLines = (v: string) => {
    return isEmpty(v) ? [] : split(v, "\n");
  };

  const startEditing = () => {
    setAgentsArgs(args.agents_custom_command_line_args || []);
    setDbserversArgs(args.dbservers_custom_command_line_args || []);
    setCoordinatorsArgs(args.coordinators_custom_command_line_args || []);
    setIsEditing(true);
  };
  const cancelEditing = () => {
    setIsEditing(false);
    setLoading(false);
    setError(undefined);
    setCommentCreationFailed(false);
  };
  const saveEdits = async () => {
    setLoading(true);
    const { error: apiError } = await args.onSaveCustomCommandLineArguments(editAgentsArgs, editDbserversArgs, editCoordinatorsArgs);
    setCommentCreationFailed(false);

    if (!!apiError) {
      setError(apiError);
      setLoading(false);
      return;
    }

    const { error: commentsError } = await args.createComment(comment);
    if (!!commentsError) {
      setCommentCreationFailed(true);
      setError("Comment creation failed. You can retry saving the comment.");
      setLoading(false);
      return;
    }
    setLoading(false);
    setIsEditing(false);
  };

  return (
    <Modal
      centered
      open={isEditing}
      trigger={
        <span>
          <Header sub>Custom Command Line Arguments</Header>
          <FlexBox>
            {!isEditing && (
              <div>
                {hasAgency && (
                  <div>
                    {!hasAgentsArgs && <span>No agent arguments</span>}
                    {hasAgentsArgs && (
                      <div>
                        Agent arguments:{" "}
                        {agents_args.map((x, i) => (
                          <CmdlineArgument key={`acmdl${i}`}>{x}</CmdlineArgument>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <div>
                  {!hasDbserversArgs && <span>No dbserver arguments</span>}
                  {hasDbserversArgs && (
                    <div>
                      DbServer arguments:{" "}
                      {dbservers_args.map((x, i) => (
                        <CmdlineArgument key={`dcmdl${i}`}>{x}</CmdlineArgument>
                      ))}
                    </div>
                  )}
                </div>
                {isCluster && (
                  <div>
                    {!hasCoordinatorsArgs && <span>No coordinator arguments</span>}
                    {hasCoordinatorsArgs && (
                      <div>
                        Coordinator arguments:{" "}
                        {coordinators_args.map((x, i) => (
                          <CmdlineArgument key={`ccmdl${i}`}>{x}</CmdlineArgument>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {!isEditing && canEditCustomCommandLineArgs && <EditIcon onClick={startEditing} />}
          </FlexBox>
        </span>
      }
    >
      <Modal.Header>
        <FlexBox justify="space-between">
          <span>Custom Command Line Arguments </span>
          {loading && <StyledInlineLoader inline />}
        </FlexBox>
      </Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} message={error} />
        <Form>
          <Form.Field disabled={commentCreationFailed}>
            <label>Agent Arguments</label>
            <TextArea
              key="agents"
              rows="10"
              value={editAgentsArgs.join("\n")}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => setAgentsArgs(textToSplitedLines(data.value as string))}
            />
          </Form.Field>

          <Form.Field disabled={commentCreationFailed}>
            <label>DbServer Arguments</label>
            <TextArea
              key="dbservers"
              rows="10"
              value={editDbserversArgs.join("\n")}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => setDbserversArgs(textToSplitedLines(data.value as string))}
            />
          </Form.Field>
          <Form.Field disabled={commentCreationFailed}>
            <label>Coordinator Arguments</label>
            <TextArea
              key="coordinators"
              rows="10"
              value={editCoordinatorsArgs.join("\n")}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => setCoordinatorsArgs(textToSplitedLines(data.value as string))}
            />
          </Form.Field>
          <Form.Field required>
            <label>Please provide the reason for this change </label>
            <CommentInputField
              handleAddComment={() => {}}
              handleCommentChange={(value: string) => setComment(value)}
              commentCreationInProcess={false}
              comment={comment}
              showOnlyInputField
            />
            <sub>
              Comment box will be empty if there is no change in the <b>custom command line arguments</b>. Otherwise, a default comment will be provided which
              can be changed if required.
            </sub>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={cancelEditing} />
        {commentCreationFailed ? (
          <FormActionButtonSave primary onClick={saveEdits} icon="comment" disabled={!comment.trim() || loading} title="Retry saving comment" />
        ) : (
          <FormActionButtonSave
            primary
            onClick={saveEdits}
            icon={!!error ? "refresh" : "check"}
            disabled={!comment.trim() || loading}
            title={!!error ? "Retry" : "Save"}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};
