//
// DISCLAIMER
//
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import React from "react";
import { Route, Router } from "react-router-dom";
import App from "./App";
import Home from "./home/Home";
import Callback from "./callback/Callback";
import Auth from "./auth/Auth";
import history from "./history";
import DashboardBootstrap from "./dashboard/DashboardBootstrap";
import { PermissionCache } from "./util/PermissionCache";
import api from "./api/api";

const auth = new Auth();
const permissionCache = new PermissionCache();

const handleAuthentication = (location: any) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

const logout = () => {
  auth.logout();
  permissionCache.clear();
  api.token = "";
};

export const makeMainRoutes = () => {
  return (
    <Router history={history}>
      <div>
        <Route path="/" render={(props) => <App {...props} auth={auth} />} />
        <Route path="/home" render={(props) => <Home {...props} auth={auth} />} />
        <Route path="/dashboard" render={(props) => <DashboardBootstrap {...props} auth={auth} permissionCache={permissionCache} onClickLogout={logout} />} />
        <Route
          path="/callback"
          render={(props) => {
            handleAuthentication(props.location);
            return <Callback />;
          }}
        />
      </div>
    </Router>
  );
};
