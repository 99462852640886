//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component, useEffect, useState } from "react";

import { Dropdown, Loader, Table, Menu, Form, Modal } from "semantic-ui-react";
import apiClients, { Cached as cachedAPIClients } from "../../api/apiclients";
import {
  Common,
  DataClusterList as ApiDataClusterList,
  DataCluster as ApiDataCluster,
  DataCluster_Status as ApiDataCluster_Status,
  ListDataClusterOptions as ApiListDataClusterOptions,
  SetDataClusterUnassignableRequest as ApiSetDataClusterUnassignableRequest,
  Provider as ApiProvider,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Routes } from "../../routes";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import {
  IconWithPopup,
  Loading,
  LoaderBoxForTable as LoaderBox,
  ContentSegment,
  SecondaryMenu,
  PagingButtons,
  ErrorMessage,
  Processing,
  SubID,
  TextLink,
  momentNow,
} from "../../ui/lib";
import { PrometheusAlertStatusView } from "../status/PrometheusAlertStatus";
import { PrometheusAlertSummaryView } from "../status/PrometheusAlertSummary";
import Location from "../deployment/Location";
import _ from "lodash";
import ProvisionInfoCell from "./ProvisionInfoCell";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { cpuParser, memoryParser } from "kubernetes-resource-parser";
import { humanizeFileSize } from "../../util/FileSize";
import DataClusterCostBenefits from "./DataClusterCostBenefits";

interface IFilterViewArgs {
  filterFiringOnly: boolean;
  onFilterFiringOnlyChanged: (value: boolean) => void;

  selectedProviderID: string;
  providers: ApiProvider[];
  onProviderChanged: (id: string) => void;

  page: number;
  pageSize: number;
  count: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

const FilterView = ({ ...args }: IFilterViewArgs) => {
  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const providerOptions = _.orderBy(args.providers, "name").map((x, i) => {
    return {
      key: `prov-${x.id || ""}`,
      text: x.name || "",
      value: x.id || "",
    };
  });
  providerOptions.unshift({
    key: "prov-all",
    text: "All",
    value: "all",
  });

  return (
    <Menu borderless pointing stackable>
      <Menu.Item header>Filter</Menu.Item>
      <Menu.Item>
        <Form.Checkbox
          name="firingonly"
          checked={args.filterFiringOnly}
          onChange={(e, d) => args.onFilterFiringOnlyChanged(!!d.checked)}
          label="Firing Only"
          toggle
        />
      </Menu.Item>
      <Dropdown
        item
        value={args.selectedProviderID}
        text={`Provider: ${getOptionText(providerOptions, args.selectedProviderID) || "?"}`}
        options={providerOptions}
        onChange={(e, d) => args.onProviderChanged(d.value as string)}
      />
      <Menu.Item position="right" fitted="vertically">
        <PagingButtons {...args} size="tiny" />
      </Menu.Item>
    </Menu>
  );
};

// Arguments for header view
interface IHeaderView {}

const HeaderView = ({ ...args }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Region</Table.HeaderCell>
      <Table.HeaderCell>Labels</Table.HeaderCell>
      <Table.HeaderCell>Last ping</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Provision Info</Table.HeaderCell>
      <Table.HeaderCell>Deployments</Table.HeaderCell>
      <Table.HeaderCell>Nodes</Table.HeaderCell>
      <Table.HeaderCell>CPU</Table.HeaderCell>
      <Table.HeaderCell>Memory</Table.HeaderCell>
      <Table.HeaderCell>Costs / day</Table.HeaderCell>
      <Table.HeaderCell>Kubernetes</Table.HeaderCell>
      <Table.HeaderCell>Firing Alerts</Table.HeaderCell>
      <Table.HeaderCell>Assignable</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a status
export interface IStatusCellView {
  status?: ApiDataCluster_Status;
  in_maintenance_mode: boolean;
}

export const StatusCellView = ({ status, in_maintenance_mode }: IStatusCellView) => {
  if (status) {
    const postFix = in_maintenance_mode ? " (in maintenance mode)" : "";
    if (status.is_failing) {
      return <Table.Cell error>Failing...{postFix}</Table.Cell>;
    }
    if (status.is_upgrading) {
      return <Table.Cell warning>Upgrading master...{postFix}</Table.Cell>;
    }
    if (status.is_nodepool_upgrading) {
      return <Table.Cell warning>Upgrading nodepools...{postFix}</Table.Cell>;
    }
    if (status.is_running) {
      if (status.is_unavailable) {
        return <Table.Cell>Running but unavailable{postFix}</Table.Cell>;
      }
      if (status.is_reboot_nodes_running) {
        return <Table.Cell warning>Rebooting nodes...{postFix}</Table.Cell>;
      }
      return (
        <Table.Cell>
          <IconWithPopup name="check" color="green" content="Running" />
        </Table.Cell>
      );
    } else {
      return <Table.Cell>Creating{postFix}</Table.Cell>;
    }
  } else {
    return <Table.Cell negative>Unknown</Table.Cell>;
  }
};

// Interface describing a datacluster
interface IRowView extends IWithRefreshProps {
  item: ApiDataCluster;
  status: ApiDataCluster_Status;
  onClickView: () => void;
  onClickSetAssignable: () => void;
  onClickSetUnassignable: () => void;
  gotoUrl: (url: string) => void;
}

const RowView = ({ ...args }: IRowView) => {
  const [deploymentCount, setDeploymentCount] = useState<string>("tbd");

  const nodes = (args.status.nodes || {}).items || [];
  const k8sVersions = _.orderBy(_.uniq(nodes.map((n) => (n.info || {}).kubeletVersion || "")));
  const status = args.item.status || {};
  const metrics_info = status.metrics_info || {};
  const prometheus_alert_status = metrics_info.prometheus_alert_status || {};
  const firing_info = prometheus_alert_status.firing_info || [];
  const has_firing_alerts = firing_info.length > 0;
  const privateToOrganizations = args.item.private_to_organizations || [];
  const now = momentNow();
  const last_ping_diff = now.diff(moment(args.status.last_ping_at), "seconds");
  let last_ping_ok = !!args.status.last_ping_at && last_ping_diff >= 0 && last_ping_diff < 180;
  let last_ping_content = args.status.last_ping_at ? moment(args.status.last_ping_at).fromNow() : "-";
  if (!args.status.data_cluster_provisioner_found_compatible_dataclusterd) {
    last_ping_ok = false;
    last_ping_content += " (data-cluster-provisioner found incompatible dataclusterd)";
  }
  if (!args.status.data_manager_found_compatible_dataclusterd) {
    last_ping_ok = false;
    last_ping_content += " (data-manager found incompatible dataclusterd)";
  }

  const requestDeploymentCount = async () => {
    try {
      const result = await apiClients.idashboardClient.ListAllDeployments({ datacluster_id: args.item.id });
      if (result) {
        setDeploymentCount(`${result.items ? result.items.length : 0}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // on mount
  useEffect(() => {
    requestDeploymentCount();
  }, []);

  let totalCPU = 0;
  let totalMemory = 0;
  nodes.forEach((n) => {
    if (n.capacity) {
      totalCPU = totalCPU + cpuParser(n.capacity.cpu || "0");
      totalMemory = totalMemory + memoryParser(n.capacity.memory || "0");
    }
  });

  let backgroundColor = privateToOrganizations.length > 0 && "rgba(255, 0, 0, 0.1)";
  if (args.item.annotations) {
    args.item.annotations.forEach((kv) => {
      if (kv.key === Common.free_deployments_only_annotation_key && kv.value === Common.free_deployments_only_annotation_value_true) {
        backgroundColor = "rgba(0, 0, 128, 0.1)";
      }
    });
  }

  return (
    <Table.Row
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <Table.Cell>
        <TextLink label={args.item.id} href={Routes.dashboard_support_datacluster_detailsWithId(args.item.id as string)} />
        {privateToOrganizations.length > 0 && (
          <SubID>
            Private To:{" "}
            {privateToOrganizations.map((orgID, i) => (
              <span key={`private_dc_${orgID}`}>
                <TextLink label={orgID} href={Routes.dashboard_sales_organization_detailsWithId(orgID)} />
                {i < privateToOrganizations.length - 1 && ", "}
              </span>
            ))}
          </SubID>
        )}
      </Table.Cell>
      <Table.Cell>
        <Location {...args} showProvider={false} showRegion regionId={args.item.region_id} />
        <SubID>{args.item.region_id || "-"}</SubID>
      </Table.Cell>
      <Table.Cell>{args.item.labels && !_.isEmpty(args.item.labels) ? args.item.labels.join(", ") : "-"}</Table.Cell>
      <Table.Cell>
        <IconWithPopup name={last_ping_ok ? "check" : "warning sign"} color={last_ping_ok ? "green" : "orange"} content={last_ping_content} />
      </Table.Cell>
      <StatusCellView status={args.item.status} in_maintenance_mode={args.item.in_maintenance_mode || false} />
      <ProvisionInfoCell {...args} datacluster={args.item} />
      <Table.Cell>{deploymentCount}</Table.Cell>
      <Table.Cell>{nodes.length}</Table.Cell>
      <Table.Cell>{totalCPU}</Table.Cell>
      <Table.Cell>{humanizeFileSize(totalMemory)}</Table.Cell>
      <Table.Cell>
        <DataClusterCostBenefits {...args} item={args.item} />
      </Table.Cell>
      <Table.Cell>{k8sVersions.join(", ")}</Table.Cell>
      <Table.Cell>
        {has_firing_alerts && (
          <Modal
            trigger={
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                <PrometheusAlertSummaryView {...args} status={prometheus_alert_status} />
              </span>
            }
            centered
            size="fullscreen"
          >
            <Modal.Header>Firing alerts</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                <PrometheusAlertStatusView {...args} status={prometheus_alert_status} showDeploymentID />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {!has_firing_alerts && <PrometheusAlertSummaryView {...args} status={prometheus_alert_status} />}
      </Table.Cell>
      <Table.Cell>{args.item.is_unassignable ? "No" : "Yes"}</Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />
      </Table.Cell>
      <Table.Cell>{args.item.is_deleted ? <DateTimePopupWithUTCAndLocalTime dateTime={args.item.deleted_at} label="Created at" /> : "-"}</Table.Cell>
    </Table.Row>
  );
};

// Interface describing the datacluster list
interface IListView extends IWithRefreshProps {
  items: ApiDataCluster[];
  onClickView: (id: string) => void;
  onClickSetAssignable: (id: string) => void;
  onClickSetUnassignable: (id: string) => void;
  page: number;
  pageSize: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  filterMatch: (item: ApiDataCluster) => boolean;
  gotoUrl: (url: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView {...args} />
    <Table.Body>
      {args.items.map(
        (item) =>
          args.filterMatch(item) && (
            <RowView
              {...args}
              key={item.id}
              item={item}
              status={item.status || {}}
              onClickView={() => args.onClickView(item.id || "")}
              onClickSetAssignable={() => args.onClickSetAssignable(item.id || "")}
              onClickSetUnassignable={() => args.onClickSetUnassignable(item.id || "")}
            />
          )
      )}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No data cluster inside this project</div>;

// Interface describing the datacluster list view arguments
export interface IDataClusterListViewArgs extends IFilterViewArgs, IWithRefreshProps {
  dataclusters?: ApiDataClusterList;
  onClickView: (id: string) => void;
  onClickSetAssignable: (id: string) => void;
  onClickSetUnassignable: (id: string) => void;
  page: number;
  pageSize: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  filterMatch: (item: ApiDataCluster) => boolean;
  gotoUrl: (url: string) => void;
}

const DataClusterListContentView = ({ ...args }: IDataClusterListViewArgs) => {
  const dataclustersList = args.dataclusters || {};
  const dataclusters = dataclustersList.items || [];
  if (_.isEmpty(dataclusters)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={dataclusters} />;
};

export const DataClusterListView = ({ ...args }: IDataClusterListViewArgs) => {
  if (!args.dataclusters) {
    return <Loading />;
  }
  return (
    <div>
      <FilterView {...args} />
      <DataClusterListContentView {...args} />
    </div>
  );
};

// Interface decribing the properties of the datacluster list component
interface IDataClusterListProps extends IWithRefreshProps, RouteComponentProps {
  onDataClusterSelected: (id: string) => void;
}

// Interface decribing the state of the datacluster list component
interface IDataClusterListState {
  dataclusters?: ApiDataClusterList;
  page: number;
  pageSize: number;
  filterFiringOnly: boolean;
  errorMessage?: string;
  processingSetUnassignable: boolean;
  location_search: string;
  refreshNeeded: boolean;
  providers?: ApiProvider[];
  selectedProviderID: string;
}

// The component to show the dataclusters as a list.
class DataClusterList extends Component<IDataClusterListProps, IDataClusterListState> {
  state: IDataClusterListState = {
    dataclusters: undefined,
    page: 0,
    pageSize: 20,
    filterFiringOnly: false,
    errorMessage: undefined,
    processingSetUnassignable: false,
    location_search: "",
    refreshNeeded: false,
    providers: undefined,
    selectedProviderID: "all",
  };

  reloadDataClusters = async () => {
    const listOptions: ApiListDataClusterOptions = {
      options: {
        page: this.state.page,
        page_size: this.state.pageSize,
      },
    };
    if (this.state.selectedProviderID != "all") {
      listOptions.provider_ids = [this.state.selectedProviderID];
    }
    const dataclusters = await apiClients.idashboardClient.ListDataClusters(listOptions);
    this.setState({ dataclusters: dataclusters });
  };

  refreshDataClusters = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDataClusters);
  };

  reloadProviders = async () => {
    const list = await cachedAPIClients.authenticationOnly.platformClient.ListProviders({});
    this.setState({ providers: list.items });
  };

  refreshProviders = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadProviders);
  };

  onNextPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page + 1,
      }),
      this.refreshDataClusters
    );
  };

  onPreviousPage = () => {
    this.setState(
      (prev) => ({
        page: prev.page - 1,
      }),
      this.refreshDataClusters
    );
  };

  static getDerivedStateFromProps(props: IDataClusterListProps, state: IDataClusterListState) {
    if (props.location.search !== state.location_search) {
      return {
        dataclusters: undefined,
        refreshNeeded: true,
        location_search: props.location.search,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadDataClusters, `/Organization/_system/DataCluster/*`);
    this.refreshProviders();
    this.refreshFilter(this.props.location.search);
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => this.refreshFilter(this.props.location.search));
    }
  }

  onClickView = (id: string) => {
    this.props.onDataClusterSelected(id);
  };

  onClickChangeUnassignable = async (id: string, value: boolean) => {
    try {
      this.setState({ processingSetUnassignable: true, errorMessage: undefined });
      const req: ApiSetDataClusterUnassignableRequest = {
        datacluster_id: id,
        is_unassignable: value,
      };
      await apiClients.idashboardClient.SetDataClusterUnassignable(req);
      this.refreshDataClusters();
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processingSetUnassignable: false });
    }
  };

  refreshFilter(search: string) {
    // No need to parse complete search string now, we only use 1
    this.setState({ filterFiringOnly: search == "?firingOnly=true" });
  }

  gotoUrl = (url: string) => {
    window.open(url);
  };

  filterMatch = (item: ApiDataCluster): boolean => {
    if (!this.state.filterFiringOnly) {
      return true;
    }
    if (item.status && item.status.metrics_info && item.status.metrics_info.prometheus_alert_status) {
      return !!item.status.metrics_info.prometheus_alert_status.firing;
    }
    return false;
  };

  dismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onProviderChanged = (id: string) => {
    this.setState({ selectedProviderID: id }, this.refreshDataClusters);
  };

  onFilterFiringOnlyChanged = (value: boolean) => {
    this.setState({ filterFiringOnly: value });
  };

  render() {
    const dataclustersList = this.state.dataclusters;
    const dataclusters = (dataclustersList || {}).items || [];
    const providers = this.state.providers;

    return (
      <ContentSegment>
        <Processing active={this.state.processingSetUnassignable} message="Changing assignable state, please wait..." />
        <ErrorMessage active={!!this.props.error} onDismiss={this.props.clearError} message={this.props.error} />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.dismissError} message={this.state.errorMessage} />
        <SecondaryMenu>
          <Menu.Item header>Data Clusters</Menu.Item>
          <span></span>
          <LoaderBox>
            <Loader size="mini" active={this.props.loading} inline />
          </LoaderBox>
        </SecondaryMenu>
        <DataClusterListView
          {...this.props}
          {...this.state}
          count={dataclusters.length}
          dataclusters={dataclustersList}
          providers={providers || []}
          onClickView={this.onClickView}
          onClickSetAssignable={(id: string) => this.onClickChangeUnassignable(id, false)}
          onClickSetUnassignable={(id: string) => this.onClickChangeUnassignable(id, true)}
          onProviderChanged={this.onProviderChanged}
          onFilterFiringOnlyChanged={this.onFilterFiringOnlyChanged}
          onNextPage={this.onNextPage}
          onPreviousPage={this.onPreviousPage}
          filterMatch={this.filterMatch}
          gotoUrl={this.gotoUrl}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(DataClusterList);
