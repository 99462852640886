//
// DISCLAIMER
//
// Copyright 2021-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Button, Modal, Form, Icon, CheckboxProps, TextAreaProps, Message } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { isEmpty, split, without } from "lodash";
import { PrepaidDeployment as ApiPrepaidDeployment, Deployment_ModelSpec as ApiDeployment_ModelSpec } from "../../api/lib";
import {
  DateTimeInput,
  DiskPerformanceInput,
  ErrorMessage,
  ModelInput,
  momentNow,
  NodeSizeInput,
  NumberInput,
  Processing,
  ProviderInput,
  RegionInput,
  SupportPlanInput,
} from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import * as googleTypes from "../../api/googleTypes";
import moment from "moment";
import styled from "@emotion/styled";

interface IPrepaidDeploymentFormProps extends IWithRefreshProps {
  isUpdate: boolean;
  hasAttachedDeployment: boolean;
  errorMessage?: string;
  processing: boolean;
  organizationID: string;

  name: string;
  onNameChanged: (value: string) => void;
  description: string;
  onDescriptionChanged: (value: string) => void;
  startsAt: googleTypes.Timestamp;
  onStartsAtChanged: (value: googleTypes.Timestamp) => void;
  endsAt: googleTypes.Timestamp;
  onEndsAtChanged: (value: googleTypes.Timestamp) => void;
  addons: string[];
  onAddonsChanged: (value: string[]) => void;
  definedAddons: string[];
  onPrivateEndpointAddonChanged: (value: boolean) => void;
  onIAMProviderAddonChanged: (value: boolean) => void;
  onAuditLogAddonChanged: (value: boolean) => void;

  model: string;
  onModelChanged: (value: string) => void;
  nodeSizeID: string;
  onNodeSizeIDChanged: (value: string) => void;
  nodeCount: number;
  onNodeCountChanged: (value: number) => void;
  nodeDiskSize: number;
  onNodeDiskSizeChanged: (value: number) => void;

  supportPlanID: string;
  onSupportPlanIDChanged: (value: string) => void;
  providerID: string;
  onProviderIDChanged: (value: string) => void;
  regionID: string;
  onRegionIDChanged: (value: string) => void;
  diskPerformanceID: string;
  onDiskPerformanceChanged: (value: string) => void;

  canSubmit: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onDismissError: () => void;
}

const StyleAddonLabelHolder = styled(Form.Field)`
  margin-bottom: 14px !important;
`;

const StyledAddonContainer = styled(Message)`
  padding-bottom: 24px !important;
`;

export const PrepaidDeploymentForm = ({ ...args }: IPrepaidDeploymentFormProps) => {
  const min_node_count = (model?: string): number => (model === "developer" ? 1 : 3);
  const max_node_count = (model?: string): number => (model === "developer" ? 1 : model === "oneshard" ? 3 : 20);
  const canSetNodeCount = (model?: string): boolean => model != "developer" && model != "oneshard";

  const textToStrings = (v: string) => {
    return isEmpty(v) ? [] : split(v, "\n");
  };

  return (
    <Modal open={true} size="large">
      <Modal.Header>{args.isUpdate ? "Update " : "Create new"} prepaid deployment</Modal.Header>
      <Modal.Content scrolling>
        <Processing active={args.processing} message="Saving prepaid deployment..." />
        <ErrorMessage active={!!args.errorMessage} onDismiss={args.onDismissError} message={args.errorMessage} />
        <Form onSubmit={args.onSubmit}>
          <Form.Field>
            <Form.Input
              name="name"
              value={args.name}
              label="Name"
              placeholder="Name"
              required
              onChange={(e) => {
                args.onNameChanged(e.target.value);
              }}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                name="description"
                value={args.description}
                label="Description"
                placeholder="Description"
                rows="5"
                onChange={(e) => {
                  args.onDescriptionChanged(e.target.value);
                }}
              />
            </Form.Field>
          </Form.Group>

          <StyledAddonContainer>
            <StyleAddonLabelHolder>
              <label>Addons</label>
            </StyleAddonLabelHolder>
            <Form.Group>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  label="Private endpoint service"
                  checked={args.definedAddons.includes("privateendpointservice")}
                  onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                    const { checked } = data;
                    args.onPrivateEndpointAddonChanged(!!checked);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  checked={args.definedAddons.includes("iamprovider")}
                  label="IAMProvider"
                  onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                    const { checked } = data;
                    args.onIAMProviderAddonChanged(!!checked);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  checked={args.definedAddons.includes("auditlog")}
                  toggle
                  label="Auditlog"
                  onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                    const { checked } = data;
                    args.onAuditLogAddonChanged(!!checked);
                  }}
                />
              </Form.Field>
            </Form.Group>
          </StyledAddonContainer>

          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                label="Additional Addons (one label per line)"
                rows="5"
                value={(without(args.addons, "iamprovider", "auditlog", "privateendpointservice") || []).join("\n")}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => args.onAddonsChanged(textToStrings(data.value as string))}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <DateTimeInput
              name="starts_at"
              value={args.startsAt}
              label="Starts At (UTC)"
              onChange={args.onStartsAtChanged}
              required
              disabled={args.hasAttachedDeployment}
            />
            <DateTimeInput name="ends_at" value={args.endsAt} label="Ends At (UTC)" onChange={args.onEndsAtChanged} required />
          </Form.Group>
          <Form.Field required>
            <label>Support Plan</label>
            <SupportPlanInput {...args} id={args.supportPlanID} organizationID={args.organizationID} onChange={args.onSupportPlanIDChanged} />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Provider</label>
              <ProviderInput
                {...args}
                onChange={args.onProviderIDChanged}
                id={args.providerID}
                placeholder="Cloud provider"
                disabled={args.hasAttachedDeployment}
              />
            </Form.Field>
            <Form.Field required>
              <label>Region</label>
              <RegionInput
                {...args}
                excludePrerelease
                onChange={args.onRegionIDChanged}
                providerId={args.providerID}
                id={args.regionID}
                placeholder="Cloud region"
                disabled={args.hasAttachedDeployment}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Model</label>
              <ModelInput {...args} id={args.model} onChange={args.onModelChanged} placeholder="Model" />
            </Form.Field>
            <Form.Field required>
              <label>Node Size</label>
              <NodeSizeInput
                {...args}
                id={args.nodeSizeID}
                onChange={args.onNodeSizeIDChanged}
                model={args.model}
                region_id={args.regionID}
                organizationID={args.organizationID}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Node count</label>
              <NumberInput
                min={min_node_count(args.model)}
                max={max_node_count(args.model)}
                disabled={!canSetNodeCount}
                value={args.nodeCount}
                onChange={args.onNodeCountChanged}
              />
            </Form.Field>
            <Form.Field required>
              <label>Node disk size</label>
              <NumberInput min={8} max={4194304} value={args.nodeDiskSize} onChange={args.onNodeDiskSizeChanged} ext=" GiB" />
            </Form.Field>
            <Form.Field required>
              <label>Disk performance</label>
              <DiskPerformanceInput
                {...args}
                placeholder="disk performance"
                id={args.diskPerformanceID}
                onChange={args.onDiskPerformanceChanged}
                notAll
                regionId={args.regionID}
                nodeSizeId={args.nodeSizeID}
                nodeDiskSize={args.nodeDiskSize}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={args.onClose}>
          <Icon name="remove" />
          Cancel
        </Button>
        <Button positive onClick={args.onSubmit} disabled={args.processing || !args.canSubmit}>
          <Icon name="checkmark" />
          {args.isUpdate ? "Update " : "Create"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export interface IPrepaidDeploymentModalProps extends IWithRefreshProps {
  organizationID: string;
  item?: ApiPrepaidDeployment;
  onClose?: () => void;
}

interface IPrepaidDeploymentModalState {
  organizationID: string;
  prepaidDeploymentID?: string;
  errorMessage?: string;
  processing: boolean;

  name: string;
  description: string;
  startsAt: googleTypes.Timestamp;
  endsAt: googleTypes.Timestamp;

  model: string;
  nodeSizeID: string;
  nodeCount: number;
  nodeDiskSize: number;
  diskPerformanceID: string;

  supportPlanID: string;
  providerID: string;
  regionID: string;
  addons: string[];
  definedAddons: string[];
}

class PrepaidDeploymentModal extends Component<IPrepaidDeploymentModalProps, IPrepaidDeploymentModalState> {
  state = PrepaidDeploymentModal.stateFromProps(this.props);

  static stateFromProps = (props: IPrepaidDeploymentModalProps) => {
    const item = props.item || {};
    const model = item.model || {};

    return {
      organizationID: props.organizationID,
      prepaidDeploymentID: item.id,
      errorMessage: undefined,
      processing: false,

      name: item.name || "",
      description: item.description || "",
      startsAt: item.starts_at || momentNow(),
      endsAt: item.ends_at || momentNow().add(1, "year"),

      model: model.model || "oneshard",
      nodeSizeID: model.node_size_id || "",
      nodeCount: model.node_count || 3,
      nodeDiskSize: model.node_disk_size || 8,
      diskPerformanceID: item.disk_performance_id || "",

      supportPlanID: item.support_plan_id,
      providerID: "",
      regionID: item.region_id,
      addons: without(item.addons || [], "iamprovider", "auditlog", "privateendpointservice") || [],
      definedAddons: (item.addons || []).filter((addon) => addon === "iamprovider" || addon === "auditlog" || addon === "privateendpointservice") || [],
    } as IPrepaidDeploymentModalState;
  };

  static getDerivedStateFromProps(props: IPrepaidDeploymentModalProps, state: IPrepaidDeploymentModalState) {
    const id = (props.item || {}).id;
    if (id !== state.prepaidDeploymentID || props.organizationID != state.organizationID) {
      const newState = PrepaidDeploymentModal.stateFromProps(props);
      return newState;
    }
    // No state update necessary
    return null;
  }

  async componentDidUpdate() {
    if (isEmpty(this.state.providerID) && !isEmpty(this.state.regionID)) {
      // Load region to get provider ID
      const region = await apiClients.authenticationOnly.platformClient.GetRegion({ id: this.state.regionID });
      this.setState({ providerID: region.provider_id || "" });
    }
  }

  onNameChanged = (value: string) => {
    this.setState({ name: value });
  };
  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };
  onStartsAtChanged = (value: googleTypes.Timestamp) => {
    this.setState({ startsAt: value });
  };
  onEndsAtChanged = (value: googleTypes.Timestamp) => {
    this.setState({ endsAt: value });
  };

  onModelChanged = (value: string) => {
    this.setState({ model: value });
  };
  onNodeSizeIDChanged = (value: string) => {
    this.setState({ nodeSizeID: value });
  };
  onNodeCountChanged = (value: number) => {
    this.setState({ nodeCount: value });
  };
  onNodeDiskSizeChanged = (value: number) => {
    this.setState({ nodeDiskSize: value });
  };

  onSupportPlanIDChanged = (value: string) => {
    this.setState({ supportPlanID: value });
  };
  onProviderIDChanged = (value: string) => {
    this.setState({ providerID: value });
  };
  onRegionIDChanged = (value: string) => {
    this.setState({ regionID: value });
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onDiskPerformanceChanged = (value: string) => {
    this.setState({ diskPerformanceID: value });
  };

  onClose = () => {
    this.props.onClose && this.props.onClose();
  };

  onAddonsChanged = (value: string[]) => {
    this.setState({ addons: without(value, "iamprovider", "auditlog", "privateendpointservice") || [] });
  };

  onPrivateEndpointAddonChanged = (value: boolean) => {
    if (value) {
      if (!this.state.definedAddons.includes("privateendpointservice")) {
        this.setState({ definedAddons: [...this.state.definedAddons, "privateendpointservice"] });
      }
    } else {
      this.setState({ definedAddons: without(this.state.definedAddons, "privateendpointservice") || [] });
    }
  };

  onIAMProviderAddonChanged = (value: boolean) => {
    if (value) {
      if (!this.state.definedAddons.includes("iamprovider")) {
        this.setState({ definedAddons: [...this.state.definedAddons, "iamprovider"] });
      }
    } else {
      this.setState({ definedAddons: without(this.state.definedAddons, "iamprovider") || [] });
    }
  };

  onAuditLogAddonChanged = (value: boolean) => {
    if (value) {
      if (!this.state.definedAddons.includes("auditlog")) {
        this.setState({ definedAddons: [...this.state.definedAddons, "auditlog"] });
      }
    } else {
      this.setState({ definedAddons: without(this.state.definedAddons, "auditlog") || [] });
    }
  };

  onSubmit = () => {
    this.setState({ processing: true, errorMessage: undefined }, async () => {
      const isUpdate = !!this.props.item;
      const req = this.props.item || {};
      req.organization_id = this.props.organizationID;
      req.name = this.state.name;
      const model: ApiDeployment_ModelSpec = {
        model: this.state.model,
        node_size_id: this.state.nodeSizeID,
        node_count: this.state.nodeCount,
        node_disk_size: this.state.nodeDiskSize,
      };
      req.model = model;

      req.starts_at = this.state.startsAt;
      req.ends_at = this.state.endsAt;
      req.support_plan_id = this.state.supportPlanID;
      req.region_id = this.state.regionID;
      req.description = this.state.description;
      req.addons = [...this.state.addons, ...this.state.definedAddons];
      req.disk_performance_id = this.state.diskPerformanceID;
      try {
        if (isUpdate) {
          await apiClients.idashboardClient.UpdatePrepaidDeployment(req);
        } else {
          await apiClients.idashboardClient.CreatePrepaidDeployment(req);
        }
        this.props.onClose && this.props.onClose();
      } catch (e) {
        this.setState({ errorMessage: `Failed to save prepaid deployment: ${e}` });
      } finally {
        this.setState({ processing: false });
      }
    });
  };

  render() {
    const isUpdate = !!this.props.item;
    const prepaidDeployment = this.props.item || {};
    const prepaidDeploymentStatus = prepaidDeployment.status || {};
    const hasAttachedDeployment = !!prepaidDeploymentStatus.deployment_id;
    const canSubmit =
      !isEmpty(this.state.name) &&
      !isEmpty(this.state.model) &&
      !isEmpty(this.state.nodeSizeID) &&
      moment(this.state.startsAt).isValid() &&
      moment(this.state.endsAt).isValid() &&
      !isEmpty(this.state.supportPlanID) &&
      !isEmpty(this.state.regionID) &&
      this.state.nodeDiskSize > 0;
    return (
      <PrepaidDeploymentForm
        {...this}
        {...this.props}
        {...this.state}
        isUpdate={isUpdate}
        hasAttachedDeployment={hasAttachedDeployment}
        canSubmit={canSubmit}
      />
    );
  }
}

export default withRefresh()(PrepaidDeploymentModal);
