//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//
import React, { useState } from "react";
import { Checkbox, Icon, Message, Table } from "semantic-ui-react";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { FlexBox, TextLink } from "../../ui/lib";
import { Routes } from "../../routes";
import { isEmpty } from "lodash";
import { DeploymnetUpdaterStatusViewArgs } from "./types";
import styled from "@emotion/styled";
import { Timestamp } from "../../api/googleTypes";

const StyledCheckbox = styled(Checkbox)`
  margin: 0px 16px;
`;

const StyledTableHeaderCell = styled(Table.HeaderCell)`
  cursor: pointer !important;
`;

type UpdateStatusObjectProperties = "update_finished_at" | "update_started_at" | "phase" | "message";

const DeploymentUpdaterStatusView = ({ deploymentStatus = {} }: DeploymnetUpdaterStatusViewArgs) => {
  const [excludedPhases, setExcludedPhases] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<UpdateStatusObjectProperties>();
  const [isAscendantSort, setIsAscendantSort] = useState<boolean>(true);

  const sortByKey = (key1: string, key2: string) => {
    if (key1 > key2) return isAscendantSort ? 1 : -1;
    if (key1 < key2) return isAscendantSort ? -1 : 1;
    return 0;
  };

  const sortByProperty = (property1: string | Timestamp, property2: string | Timestamp) => {
    if (property1 > property2) return isAscendantSort ? 1 : -1;
    if (property1 < property2) return isAscendantSort ? -1 : 1;
    return 0;
  };

  const statusKeys: string[] = Object.keys(deploymentStatus);
  const filteredStatusKeys: string[] = statusKeys.filter((statusKey) => !excludedPhases.includes(deploymentStatus[statusKey].phase || ""));

  const filteredSortedStatusKeys: string[] = filteredStatusKeys.sort((a, b) => {
    if (!sortBy) return sortByKey(a, b);

    const deploymentStatusA = deploymentStatus[a][sortBy];
    const deploymentStatusB = deploymentStatus[b][sortBy];

    if (!deploymentStatusA || !deploymentStatusB) return 0;

    return sortByProperty(deploymentStatusA, deploymentStatusB);
  });

  const handleTogglePhase = (phase: string) => {
    excludedPhases.includes(phase)
      ? setExcludedPhases(excludedPhases.filter((excludedPhase) => excludedPhase !== phase))
      : setExcludedPhases([...excludedPhases, phase]);
  };

  const handleSortPhases = (newSort: UpdateStatusObjectProperties | undefined) => {
    if (sortBy === newSort) {
      setIsAscendantSort(!isAscendantSort);
    } else {
      setIsAscendantSort(true);
      setSortBy(newSort);
    }
  };

  if (isEmpty(deploymentStatus)) return <Message>No deployment statuses found</Message>;

  return (
    <>
      <FlexBox>
        <StyledCheckbox fitted toggle checked={!excludedPhases.includes("Pending")} label="Pending" onChange={() => handleTogglePhase("Pending")} />
        <StyledCheckbox fitted toggle checked={!excludedPhases.includes("Skipped")} label="Skipped" onChange={() => handleTogglePhase("Skipped")} />
        <StyledCheckbox fitted toggle checked={!excludedPhases.includes("Completed")} label="Completed" onChange={() => handleTogglePhase("Completed")} />
        <StyledCheckbox fitted toggle checked={!excludedPhases.includes("Failed")} label="Failed" onChange={() => handleTogglePhase("Failed")} />
      </FlexBox>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <StyledTableHeaderCell onClick={() => handleSortPhases(undefined)}>
              Deployment ID {!sortBy && <Icon name={`chevron ${isAscendantSort ? "down" : "up"}`} />}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell onClick={() => handleSortPhases("update_started_at")}>
              Update Started At {sortBy === "update_started_at" && <Icon name={`chevron ${isAscendantSort ? "down" : "up"}`} />}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell onClick={() => handleSortPhases("update_finished_at")}>
              Update Finished At {sortBy === "update_finished_at" && <Icon name={`chevron ${isAscendantSort ? "down" : "up"}`} />}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell onClick={() => handleSortPhases("phase")}>
              Phase {sortBy === "phase" && <Icon name={`chevron ${isAscendantSort ? "down" : "up"}`} />}
            </StyledTableHeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredSortedStatusKeys.map((key: string) => {
            const state = deploymentStatus[key];
            const { update_finished_at: finishedAt, update_started_at: startedAt, phase, message } = state;
            return (
              <Table.Row key={Math.random()}>
                <Table.Cell>
                  <TextLink href={Routes.dashboard_support_deployment_detailsWithId(key)} label={key} />
                </Table.Cell>

                <Table.Cell>
                  <DateTimePopupWithUTCAndLocalTime dateTime={startedAt} label="Update started At" />
                </Table.Cell>
                <Table.Cell>
                  <DateTimePopupWithUTCAndLocalTime dateTime={finishedAt} label="Update finished At" />
                </Table.Cell>
                <Table.Cell>{phase || "-"}</Table.Cell>
                <Table.Cell>{message || "-"}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default DeploymentUpdaterStatusView;
