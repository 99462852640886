//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import { isEqual } from "lodash";
import React from "react";
import { useEffect, useState } from "react";

interface IIntersectionObserverInitArgs extends IntersectionObserverInit {
  targetObservingElements: HTMLCollection | never[];
}

interface IIntersectionObserverArgs {
  id: string;
  options: IIntersectionObserverInitArgs;
  onElementStateUpdated: (visibleElements: { [key: string]: boolean }) => void;
}

const IntersectionObserverContainer: React.FC<IIntersectionObserverArgs> = ({ children, id, options, onElementStateUpdated }) => {
  const [visibleElements, updateVisibleElements] = useState({});

  useEffect(() => {
    const previousState = localStorage.getItem(id);
    const stringifiedObject = JSON.stringify(visibleElements);

    if (!isEqual(previousState, stringifiedObject)) {
      localStorage.setItem(id, stringifiedObject);
      onElementStateUpdated && onElementStateUpdated(visibleElements);
    }
  }, [visibleElements]);

  const handleElementIntersection: IntersectionObserverCallback = (entries) => {
    const updatedVisibleStates: { [key: string]: boolean } = {};

    entries.forEach((entry: IntersectionObserverEntry) => {
      const { target = {} } = entry;
      const { dataset } = target as HTMLElement;
      const observerID = dataset.observerid;
      if (observerID) {
        updatedVisibleStates[observerID] = entry.isIntersecting;
      }
    });

    updateVisibleElements({ ...visibleElements, ...updatedVisibleStates });
  };

  useEffect(() => {
    const { targetObservingElements } = options;
    const observer = new IntersectionObserver(handleElementIntersection, options);

    Array.from(targetObservingElements).forEach((el) => {
      const { dataset = {} } = el as HTMLElement;
      const observerID = dataset.observerid;
      if (observerID) {
        observer.observe(el as Element);
      }

      return () => {
        observer.disconnect();
      };
    });
  }, [options]);

  return <span className="observing-wrapper">{children}</span>;
};

export default IntersectionObserverContainer;
