//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import {
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  PlanAssignment as ApiPlanAssignment,
  Project as ApiProject,
  DeploymentInfo as ApiDeploymentInfo,
} from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { SubID } from "../../ui/lib";
import { DateTime, DateTimePopupWithUTCAndLocalTime, DateTimeTransformationTypes } from "../../util/dateAndTimeUtils/DateTime";

// Interface describing an organization
interface IRowView {
  item: ApiPlanAssignment;
  organization?: ApiOrganization;
  project?: ApiProject;
  deployment?: ApiDeploymentInfo;
}

const RowView = ({ ...args }: IRowView) => {
  const subject = args.item.subject || {};
  const discounts = args.item.discounts || {};
  const organization = args.organization || {};
  const project = args.project || {};
  const deployment = args.deployment || {};
  return (
    <Table.Row>
      <Table.Cell>{args.item.plan_id}</Table.Cell>
      <Table.Cell>
        {subject.all && <div>ALL</div>}
        {subject.organization_id && (
          <div>
            Organization {organization.name || ""}
            <SubID>{subject.organization_id}</SubID>
          </div>
        )}
        {subject.project_id && (
          <div>
            Project {project.name || ""}
            <SubID>{subject.project_id}</SubID>
          </div>
        )}
        {subject.deployment_id && (
          <div>
            Deployment {(deployment.deployment || {}).name || ""}
            <SubID>{subject.deployment_id}</SubID>
          </div>
        )}
      </Table.Cell>
      <Table.Cell>
        License-mem: {`${100 * (discounts.license_memory || 0)} %`}
        <br />
        License-disk: {`${100 * (discounts.license_disk || 0)} %`}
      </Table.Cell>
      <Table.Cell>
        {args.item.starts_at ? <DateTime dateTime={args.item.starts_at} transformTo={DateTimeTransformationTypes.UTC} /> : "-"}
        <span> ... </span>
        {args.item.ends_at ? <DateTime dateTime={args.item.ends_at} transformTo={DateTimeTransformationTypes.UTC} /> : "-"}
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={args.item.created_at} label="Created at" />

        <br />
        {args.item.created_by_id || "?"}
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons"></div>
      </Table.Cell>
    </Table.Row>
  );
};

interface IAPI {
  GetOrganization: (req: ApiIDOptions) => Promise<ApiOrganization>;
  GetProject: (req: ApiIDOptions) => Promise<ApiProject>;
  GetDeployment: (req: ApiIDOptions) => Promise<ApiDeploymentInfo>;
}

interface IPricingPlanAssignmentRowProps extends IWithRefreshProps, RouteComponentProps {
  api: IAPI;
  item: ApiPlanAssignment;
}

interface IPricingPlanAssignmentRowState {
  organization?: ApiOrganization;
  project?: ApiProject;
  deployment?: ApiDeploymentInfo;
}

// The component to show an assignment row of a pricing plan assignments list.
class PricingPlanAssignmentRow extends Component<IPricingPlanAssignmentRowProps, IPricingPlanAssignmentRowState> {
  state: IPricingPlanAssignmentRowState = {
    organization: undefined,
    project: undefined,
    deployment: undefined,
  };

  reloadOrganization = async () => {
    const subject = this.props.item.subject || {};
    if (!!subject.organization_id) {
      try {
        const org = await this.props.api.GetOrganization({ id: subject.organization_id });
        this.setState({ organization: org });
      } catch {
        this.setState({ organization: undefined });
      }
    } else {
      this.setState({ organization: undefined });
    }
  };

  reloadProject = async () => {
    const subject = this.props.item.subject || {};
    if (!!subject.project_id) {
      try {
        const proj = await this.props.api.GetProject({ id: subject.project_id });
        this.setState({ project: proj });
      } catch {
        this.setState({ project: undefined });
      }
    } else {
      this.setState({ project: undefined });
    }
  };

  reloadDeployment = async () => {
    const subject = this.props.item.subject || {};
    if (!!subject.deployment_id) {
      try {
        const depl = await this.props.api.GetDeployment({ id: subject.deployment_id });
        this.setState({ deployment: depl });
      } catch {
        this.setState({ deployment: undefined });
      }
    } else {
      this.setState({ deployment: undefined });
    }
  };

  componentDidMount() {
    this.reloadOrganization();
    this.reloadProject();
    this.reloadDeployment();
  }

  render() {
    return <RowView {...this.state} {...this.props} />;
  }
}

export default withRefresh()(PricingPlanAssignmentRow);
