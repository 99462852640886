//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import React, { Component } from "react";
import { Tab } from "semantic-ui-react";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";
import apiClients from "../../api/apiclients";
import { Organization as ApiOrganization, ListTestRunsRequest as ApiListTestRunsRequest, TestRun as ApiTestRun } from "../../api/lib";
import { ContentSegment } from "../../ui/_style";
import { Processing } from "../../ui/_processing";
import IntegrationTestsStatus from "./IntegrationTestsGetStatus";
import IntegrationTestsRun from "./IntegrationTestsRun";
import _ from "lodash";
import { ITabConfig } from "../../components/Tab/TabTypes";
import TabLayoutWithRouter from "../../components/Tab/TabWithRouter";

// Interface decribing the properties of the integration tests component
interface IIntegrationTestsRunProps extends IWithRefreshProps, RouteComponentProps {
  organization?: ApiOrganization;
}

// Interface decribing the state of the integration tests component
interface IIntegrationTestsState {
  errorMessage?: string;
  testRuns?: ApiTestRun[];
  activeIndex: number;
}

class IntegrationTests extends Component<IIntegrationTestsRunProps, IIntegrationTestsState> {
  state: IIntegrationTestsState = {
    errorMessage: undefined,
    testRuns: undefined,
    activeIndex: 0,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadTestRunList, "/Organization/_system/Test");
  }

  reloadTestRunList = async () => {
    try {
      const req: ApiListTestRunsRequest = {};
      const runs = await apiClients.idashboardClient.ListTestRuns(req);
      this.setState({ testRuns: runs.items || [] });
    } catch (e) {
      this.setState({ errorMessage: `Failed to load test runs: ${e}` });
    }
  };

  refreshTestRunList = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadTestRunList);
  };

  getTabConfig = (): ITabConfig[] => {
    const testRuns = this.state.testRuns || [];

    const panes: ITabConfig[] = [
      {
        menuItem: {
          children: "Run",
          id: "runTab",
          route: "testRun",
        },
        content: () => (
          <Tab.Pane>
            <IntegrationTestsRun {...this.props} onRunStarted={this.refreshTestRunList} />
          </Tab.Pane>
        ),
      },
    ];

    const resultPanes = _.orderBy(testRuns, "id").map((testRun) => {
      return {
        menuItem: { children: testRun.id || "", id: `${testRun.id}Tab`, route: testRun.id },
        content: () => (
          <Tab.Pane>
            <IntegrationTestsStatus {...this.props} run={testRun} onDeletedTestRun={this.refreshTestRunList} />
          </Tab.Pane>
        ),
      };
    });

    const tabPanes = _.concat(panes, resultPanes as ITabConfig[]);

    return tabPanes;
  };

  render() {
    const loading = !this.state.testRuns;

    if (loading) {
      return (
        <ContentSegment>
          <Processing active message="Loading test status, please wait..." />
        </ContentSegment>
      );
    }

    return (
      <ContentSegment>
        <TabLayoutWithRouter
          tabConfig={this.getTabConfig()}
          id="integrationTestRunnersTabView"
          allowOverflow
          activeIndex={this.state.activeIndex}
          onTabChange={(activeIndex: number) => this.setState({ activeIndex })}
        />
      </ContentSegment>
    );
  }
}

export default withRefresh()(IntegrationTests);
