//
// DISCLAIMER
//
// Copyright 2021 ArangoDB GmbH, Cologne, Germany
//
// Author Nikhil Varma
//

import { sortBy } from "lodash";
import React from "react";
import { Divider, Header } from "semantic-ui-react";
import { EmailAddress as ApiEmailAddress, EmailAddressList as ApiEmailAddressList } from "../../api/lib";

interface IDeploymentEmailAddressesArgs {
  emailAddresses: ApiEmailAddressList;
}

const DeploymentEmailAddressesView = ({ emailAddresses }: IDeploymentEmailAddressesArgs) => {
  const { addresses = [] } = emailAddresses;

  return (
    <>
      <Header sub>Deployment Email addresses</Header>
      {!addresses.length ? (
        <Header disabled as="h5">
          No deploymeny email addresses configured
        </Header>
      ) : (
        sortBy(addresses, "address").map((emailAddress: ApiEmailAddress, index: number) => {
          return <div key={index}>{emailAddress.address}</div>;
        })
      )}
      <Divider hidden />
    </>
  );
};

export default DeploymentEmailAddressesView;
