//
// DISCLAIMER
//
// Copyright 2020-2021 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { Button, SemanticFLOATS } from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTimeout, { ReactTimeoutProps } from "react-timeout";

interface ICopyToClipboardButtonProps extends ReactTimeoutProps {
  content?: string;
  disabled?: boolean;
  floated?: SemanticFLOATS;
}

interface ICopyToClipboardButtonState {
  copiedContent: boolean;
}

class CopyToClipboardButton extends Component<ICopyToClipboardButtonProps, ICopyToClipboardButtonState> {
  state: ICopyToClipboardButtonState = {
    copiedContent: false,
  };

  onCopiedDetails = () => {
    this.setState({ copiedContent: true }, () => {
      const setTimeout = this.props.setTimeout;
      setTimeout &&
        setTimeout(() => {
          this.setState({ copiedContent: false });
        }, 3000);
    });
  };

  render() {
    return (
      <CopyToClipboard onCopy={this.onCopiedDetails} text={this.props.content}>
        <Button disabled={!!this.props.disabled} icon={this.state.copiedContent ? "check" : "copy"} size="tiny" circular floated={this.props.floated} />
      </CopyToClipboard>
    );
  }
}

export default ReactTimeout(CopyToClipboardButton);
