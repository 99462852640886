//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Icon, Popup, IconProps } from "semantic-ui-react";

interface IIconWithPopupArgs extends IconProps {
  content: any;
}

export const IconWithPopup = ({ ...args }: IIconWithPopupArgs) => {
  return <Popup content={args.content} trigger={<Icon {...args} />} />;
};
