//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//
import React from "react";
import { Dropdown } from "semantic-ui-react";

export const NotebookMaintenance = ({
  notebookID,
  onSaveMaintenanceMode,
  currentMaintenanceMode = false,
}: {
  notebookID: string | undefined;
  onSaveMaintenanceMode: (notebookID: string | undefined, mode: boolean) => void;
  currentMaintenanceMode: boolean | undefined;
}) => {
  return (
    <>
      <span>{currentMaintenanceMode ? "Yes" : "No"}</span>
      <Dropdown icon="pencil" inline className="icon tiny edit-pencil">
        <Dropdown.Menu>
          {!currentMaintenanceMode && (
            <Dropdown.Item key="enable-nb-maintenance" text="Enable maintenance mode" onClick={() => onSaveMaintenanceMode(notebookID, true)} />
          )}
          {currentMaintenanceMode && (
            <Dropdown.Item key="disable-nb-maintenance" text="Disable maintenance mode" onClick={() => onSaveMaintenanceMode(notebookID, false)} />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
