//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
// Author Robert Stam
//

import { Empty as ApiEmpty, IDOptions as ApiIDOptions, ListOptions as ApiListOptions, URLOptions as ApiURLOptions } from "./common/v1/common";
export type Empty = ApiEmpty;
export type IDOptions = ApiIDOptions;
export type ListOptions = ApiListOptions;
export type URLOptions = ApiURLOptions;

export * from "./api-compat/v1/api_compat";
export * from "./backup/v1/backup";
export * from "./backup/v1/ibackup";
export * from "./billing/v1/billing";
export * from "./changelog/v1/ichangelog";
export * from "./common/v1/icommon";
export * from "./crypto/v1/crypto";
export * from "./crypto/v1/icrypto";
export * from "./currency/v1/currency";
export * from "./currency/v1/icurrency";
export * from "./dashboard/v1/idashboard";
export * from "./data/v1/data";
export * from "./data/v1/idata";
export * from "./datacluster-optimize/v1/idatacluster-optimize";
export * from "./dcupdate/v1/dc_update";
// Do not export types from idatacluster, those shouldn't be needed in idashboard.
// export * from "./datacluster/v1/idatacluster";
export * from "./dcupdate/v1/dc_update";
export * from "./dns/v1/idns";
export * from "./email/v1/iemail";
export * from "./feature/v1/ifeature";
export * from "./iam/v1/iam";
export * from "./iam/v1/iiam";
export * from "./job/v1/ijob";
export * from "./integration-tests/v1/integration_tests";
export * from "./messaging/v1/imessaging";
export * from "./monitoring/v1/imonitoring";
// Do not export types from iplatform, those conflict with types found in idashboard.
// export * from "./platform/v1/iplatform";
export * from "./platform/v1/platform";
export * from "./pricing/v1/ipricing";
export * from "./reporting/v1/ireporting";
export * from "./resourcemanager/v1/iresourcemanager";
// Do not export types from resourcemanager, those conflict with types found in data.
export type {
  DataProcessingAddendum,
  Event,
  EventList,
  ListEventOptions,
  Member,
  MemberList,
  Organization,
  Project,
  ProjectList,
  TermsAndConditions,
  Tier,
} from "./resourcemanager/v1/resourcemanager";
export * from "./scheduling/v1/ischeduling";
export * from "./security/v1/isecurity";
export * from "./usage/v1/iusage";
export * from "./usage/v1/usage";
export * from "./kube-access/v1/ikube-access";
export * from "./example/v1/example";
export * from "./audit/v1/audit";
export * from "./audit/v1/iaudit";
export * from "./network/v1/inetwork";
export * from "./prepaid/v1/prepaid";
export * from "./prepaid/v1/iprepaid";

export * from "./_errors";

export class Usage {
  static network_transfer_destination_internet = "Internet";
  static network_transfer_destination_in_cluster = "InCluster";
  static network_transfer_destination_private_endpoint = "PrivateEndpoint";
}

export class Common {
  // The annotation used to store the dc-update mode
  static dcupdate_mode_annotation_key = "cloud.arangodb.com/dc-update-mode";
  // The value set to indicate that this object (data cluster or deployment) should be excluded from the update process
  static dcupdate_mode_exclude_annotation_value = "Exclude";
  // The value set to indicate that this object (data cluster or deployment) should be forced to be update in the update process
  // (even if it has errors would normally prevent this)
  static dcupdate_mode_force_annotation_value = "Force";
  // The value set to indicate that this object (deployment only) should be skipped in the update process when the version is updated
  static dcupdate_mode_skip_version_update_annotation_value = "SkipVersionUpdate";
  // FreeDeploymentsOnlyAnnotation is an annotation on a DC that indicates that
  // only free deployments may be scheduled there.
  static free_deployments_only_annotation_key = "cloud.arangodb.com/free-deployments-only";
  // Value for FreeDeploymentsOnlyAnnotation.
  static free_deployments_only_annotation_value_true = "True";
}

// Deployment model
export class Model {
  static developer = "developer";
  static onshard = "oneshard";
  static sharded = "sharded";
  static flexible = "flexible";

  static isSingleInstance(model: string) {
    return model == this.developer;
  }
  static isCluster(model: string) {
    return model != this.developer;
  }
  static hasAgency(model: string) {
    return model != this.developer;
  }
}
