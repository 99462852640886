//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Ewout Prangsma
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import ReactJson from "react-json-view";
import { RouteComponentProps } from "react-router-dom";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { GetClusterHealthRequest as ApiGetClusterHealthRequest } from "../../api/lib";
import { ContentActionButton } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

const DataContainer = styled("div")`
  min-height: 20vh;
`;

interface IClusterHealthModalViewArgs {
  open: boolean;
  loading: boolean;
  health?: string;
  onClose: () => void;
}

const ClusterHealthModalView = ({ ...args }: IClusterHealthModalViewArgs) => {
  const empty = _.isEmpty(args.health);
  let healthParsed: any;
  let hasParsedHealth = false;
  if (!empty) {
    try {
      healthParsed = JSON.parse(args.health || "");
      hasParsedHealth = true;
    } catch (e) {
      // Ignore
      console.log(`JSON health parsing failed: ${e}`);
    }
  }

  return (
    <Modal open={args.open} onClose={args.onClose} size="fullscreen">
      <Modal.Content scrolling>
        <DataContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Fetching cluster health, please wait
              </Loader>
            </Dimmer>
          )}
          {!args.loading && !empty && hasParsedHealth && <ReactJson src={healthParsed} />}
          {!args.loading && !empty && !hasParsedHealth && <pre>{args.health}</pre>}
          {!args.loading && empty && (
            <span>
              <i>No data</i>
            </span>
          )}
        </DataContainer>
      </Modal.Content>
    </Modal>
  );
};

interface IClusterHealthModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
}

interface IClusterHealthModalState {
  open: boolean;
  loading: boolean;
  health?: string;
  errorMessage?: string;
}

// Component to show deployment logs
class ClusterHealthModal extends Component<IClusterHealthModalProps, IClusterHealthModalState> {
  state: IClusterHealthModalState = {
    open: false,
    loading: false,
    health: undefined,
    errorMessage: undefined,
  };

  reloadHealth = async () => {
    this.setState({ loading: true, health: undefined, errorMessage: undefined });
    try {
      const req: ApiGetClusterHealthRequest = {
        deployment_id: this.props.deploymentId,
      };
      const stream = await apiClients.idashboardClient.GetClusterHealth(req, (x) => {
        const err = x.error;
        const msg = x.message;
        if (!!err) {
          this.setState({ errorMessage: x.error });
        } else if (!!msg) {
          const chunk = msg.chunk;
          if (!!chunk) {
            const decoded = atob(chunk);
            this.setState((old) => {
              return { health: (old.health || "") + decoded };
            });
          }
        }
      });
      await stream.closed;
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, health: undefined, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true }, this.reloadHealth);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Cluster health" icon="stethoscope" />
        <ClusterHealthModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(ClusterHealthModal);
