//
// DISCLAIMER
//
// Copyright 2020 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import styled from "@emotion/styled";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dimmer, Loader, Modal, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  ServiceDescriptor as ApiServiceDescriptor,
  ServiceAPI as ApiServiceAPI,
  AllServiceDescriptors as ApiAllServiceDescriptors,
  AllServiceDescriptorsRequest as ApiAllServiceDescriptorsRequest,
} from "../../api/lib";
import { Version as ApiVersion } from "../../api/common/v1/common";
import { ContentActionButton, ErrorMessage } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import _ from "lodash";

const DataContainer = styled("div")`
  min-height: 20vh;
`;

const formatVersion = (v?: ApiVersion) => {
  if (!v) {
    return "?";
  }
  return `${v.major || 0}.${v.minor || 0}.${v.patch || 0}`;
};

interface IServiceAPIListViewArgs {
  list: ApiServiceAPI[];
}

const ServiceAPIListView = ({ ...args }: IServiceAPIListViewArgs) => {
  const list = _.orderBy(args.list, "id");
  return (
    <Table basic="very" compact="very" size="small">
      <Table.Body>
        {list.map((x, i) => (
          <Table.Row key={`row${i}`}>
            <Table.Cell collapsing>{x.id || ""}</Table.Cell>
            <Table.Cell textAlign="right">{formatVersion(x.version)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

interface IServiceDescriptionListViewArgs {
  serviceDescriptors: ApiServiceDescriptor[];
}

const ServiceDescriptionListView = ({ ...args }: IServiceDescriptionListViewArgs) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Service</Table.HeaderCell>
          <Table.HeaderCell>Exposes</Table.HeaderCell>
          <Table.HeaderCell>Requires</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {args.serviceDescriptors.map((x, i) => (
          <Table.Row key={`row${i}`} verticalAlign="top">
            <Table.Cell>
              {x.name || ""} ({formatVersion(x.version)})
            </Table.Cell>
            <Table.Cell>
              <ServiceAPIListView list={x.exposes || []} />{" "}
            </Table.Cell>
            <Table.Cell>
              <ServiceAPIListView list={x.requires || []} />{" "}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

interface IServiceDescriptorModalViewArgs {
  open: boolean;
  loading: boolean;
  errorMessage?: string;
  serviceDescriptors?: ApiAllServiceDescriptors;
  onClose: () => void;
}

const ServiceDescriptorModalView = ({ ...args }: IServiceDescriptorModalViewArgs) => {
  const serviceDescriptors = args.serviceDescriptors || {};
  const serviceDescriptorList = serviceDescriptors.service_descriptors || [];
  return (
    <Modal open={args.open} onClose={args.onClose} size="large">
      <Modal.Content scrolling>
        <DataContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Getting service descriptors, please wait
              </Loader>
            </Dimmer>
          )}
          {!args.loading && <ErrorMessage active={!!args.errorMessage} message={`${args.errorMessage}`} />}
          {!args.loading && args.serviceDescriptors && <ServiceDescriptionListView serviceDescriptors={serviceDescriptorList} />}
        </DataContainer>
      </Modal.Content>
    </Modal>
  );
};

interface IServiceDescriptorsModalProps extends IWithRefreshProps, RouteComponentProps {}

interface IServiceDescriptorsModalState {
  open: boolean;
  loading: boolean;
  serviceDescriptors?: ApiAllServiceDescriptors;
  errorMessage?: string;
}

// Component to show service descriptors
class ServiceDescriptorsModal extends Component<IServiceDescriptorsModalProps, IServiceDescriptorsModalState> {
  state: IServiceDescriptorsModalState = {
    open: false,
    loading: false,
    serviceDescriptors: undefined,
    errorMessage: undefined,
  };

  reloadServersDescriptors = async () => {
    this.setState({ loading: true, errorMessage: undefined });
    try {
      const req: ApiAllServiceDescriptorsRequest = {};
      const serviceDescriptors = await apiClients.idashboardClient.GetAllServiceDescriptors(req);
      this.setState({ loading: false, serviceDescriptors: serviceDescriptors });
    } catch (e) {
      this.setState({ loading: false, serviceDescriptors: undefined, errorMessage: e });
    }
  };

  onOpen = () => {
    this.setState({ open: true }, this.reloadServersDescriptors);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Get Service Descriptors" icon="stethoscope" />
        <ServiceDescriptorModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(ServiceDescriptorsModal);
