//
// DISCLAIMER
//
// Copyright 2019-2021 ArangoDB GmbH, Cologne, Germany
//
// Author Robert Stam
//

import * as React from "react";
import _ from "lodash";
import ReactTimeout, { ReactTimeoutProps } from "react-timeout";
import { EventSubscriptionManager } from "./EventSubscriptionManager";
import { RequestEventArgs } from "./Events";
import { ResourceType, Permission, PermissionCache } from "./PermissionCache";

export interface IWithRefreshProps extends IWithRefreshInjectedProps {
  eventSubscriptionManager: EventSubscriptionManager;
  permissionCache: PermissionCache;
}

// Interface decribing the external properties of the withRefresh HOC (higher-order component)
interface IWithRefreshExternalProps extends ReactTimeoutProps {
  eventSubscriptionManager: EventSubscriptionManager;
  permissionCache: PermissionCache;
}

// Interface decribing the properties of the withRefresh HOC (higher-order component)
interface IWithRefreshInjectedProps extends ReactTimeoutProps {
  loading: boolean;
  error?: any;
  subscribe?: (callback: () => Promise<void>, args: RequestEventArgs) => Promise<string>;
  subscribeUrl?: (callback: () => Promise<void>, url?: string) => Promise<string>;
  unsubscribe?(id: string): Promise<void>;
  refreshWithTimer?: (callback: () => Promise<void>, seconds: number) => Promise<void>;
  refreshNow?: (callback: () => Promise<void>) => Promise<void>;
  refreshNowAll?: () => void;
  clearError?: () => void;
  permissionCacheId?: number;
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
}

// Interface decribing the state of the withRefresh HOC (higher-order component)
interface IWithRefreshState {
  loading: boolean;
  error?: any;
  subscriptions: Array<string>;
  callbacks: Array<() => Promise<void>>;
  activePermissionUrls: Array<string>;
  permissionCacheId: number;
}

interface IWithRefreshOptions {
  warning?: boolean;
  debug?: boolean;
}

export const withRefresh = ({ warning = true, debug = false }: IWithRefreshOptions = {}) => <TOriginalProps extends {}>(
  WrappedComponent: React.ComponentClass<TOriginalProps & IWithRefreshInjectedProps> | React.StatelessComponent<TOriginalProps & IWithRefreshInjectedProps>
) => {
  type ResultProps = TOriginalProps & IWithRefreshExternalProps;
  const result = class WithRefresh extends React.Component<ResultProps, IWithRefreshState> {
    state: IWithRefreshState = {
      loading: false,
      error: undefined,
      subscriptions: new Array<string>(),
      callbacks: new Array<() => Promise<void>>(),
      activePermissionUrls: new Array<string>(),
      permissionCacheId: this.props.permissionCache.getId(),
    };

    // Define how your HOC is shown in ReactDevTools
    static displayName = `WithRefresh(${WrappedComponent.displayName || WrappedComponent.name})`;

    // Set when the component is going to unmount, so we can prevent additonal execution of the execute method.
    unmount = false;

    // Subscribe to the provided filter (URL of resource to request events for) and wait until the initial call has been executed.
    // The returned ID can be used to unsubscribe (can be en empty string meaning that a timer will be used)
    subscribeUrl = async (callback: () => Promise<void>, url?: string): Promise<string> => {
      if (debug && !url) {
        console.log(`Subscribing to URL without specifying url`);
      }

      const args: RequestEventArgs = { resource_url: url };
      return this.subscribe(callback, args);
    };

    // Subscribe to the provided filter (request event args) and wait until the initial call has been executed.
    // The returned ID can be used to unsubscribe (can be en empty string meaning that a timer will be used)
    subscribe = async (callback: () => Promise<void>, args: RequestEventArgs): Promise<string> => {
      // Do not wait for the result
      const result = this.trySubscribe(callback, args, 0);

      // Store callback in collection
      this.state.callbacks.push(callback);

      // Initially call the callback once.
      this.execute(callback, 0);

      return result;
    };

    // Unsubscribe to the ID provided by subscribe[Url]
    // It's allowed to unsubscribe with an empty string, meaning NOP.
    unsubscribe = async (id: string): Promise<void> => {
      if (id == "") {
        // For an empty id we are done
        return;
      }
      if (debug) {
        console.log(`Unsubscribing from id='${id}' ...`);
      }
      try {
        await this.props.eventSubscriptionManager.unsubscribe(id);
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.subscriptions = _.remove(this.state.subscriptions, id);
        if (debug) {
          console.log(`Unsubscribed from id='${id}' ...`);
        }
      } catch (e) {
        if (warning || debug) {
          console.log(`Unsubscribing from id='${id}' failed with exception: ${e}`);
        }
      }
    };

    // Execute the callback every interval
    refreshWithTimer = async (callback: () => Promise<void>, ms: number): Promise<void> => {
      if (debug) {
        console.log(`Auto-refesh every ${ms} ms ...`);
      }

      // Store callback in collection
      this.state.callbacks.push(callback);

      // Initially call the callback once (and repeat after).
      return this.execute(callback, 0, ms);
    };

    refreshNow = (callback: () => Promise<void>): Promise<void> => {
      // Call the callback once now.
      return this.execute(callback, 0);
    };

    refreshNowAll = () => {
      this.state.callbacks.forEach((c) => {
        this.execute(c, 0);
      });
    };

    clearError = () => {
      this.setState({ error: undefined });
    };

    private trySubscribe = async (callback: () => Promise<void>, args: RequestEventArgs, retryCount: number): Promise<string> => {
      try {
        if (debug) {
          console.log(`Subscribing (${retryCount}) to ${JSON.stringify(args)} ...`);
        }
        const id = await this.props.eventSubscriptionManager.subscribe(args, () => {
          this.execute(callback, 0);
        });
        this.state.subscriptions.push(id);
        if (debug) {
          console.log(`Subscribed (${retryCount}) to ${JSON.stringify(args)} with id='${id}'`);
        }
        return id;
      } catch (e) {
        if (debug) {
          console.log(`Subscribing (${retryCount}) to ${JSON.stringify(args)} failed with exception: ${e}`);
        }
        if (retryCount < 5) {
          // Retry a few time only (depending on retryCount)
          this.props.setTimeout &&
            this.props.setTimeout(() => {
              return this.trySubscribe(callback, args, retryCount + 1);
            }, this.retryPeriodInMs(retryCount));
        } else {
          // Fallback to execute every 10 seconds
          this.execute(callback, 0, 10000);
        }
        return "";
      }
    };

    hasPermissionByUrl = (url: string, type: ResourceType, permission: Permission): boolean => {
      if (!this.state.activePermissionUrls.includes(url)) {
        this.state.activePermissionUrls.push(url);
      }

      const cacheResult = this.props.permissionCache.checkPermission(url, permission);
      return cacheResult.getResult();
    };

    private execute = async (callback: () => Promise<void>, retryCount: number, everyMs?: number): Promise<void> => {
      if (this.unmount) {
        if (warning) {
          console.warn(`Executing (${retryCount}) [every ${everyMs} ms]  - refused: componented unmounted!`);
        }
        return;
      }

      if (debug) {
        console.log(`Executing (${retryCount}) [every ${everyMs} ms]  ...`);
      }

      try {
        this.setState({
          loading: true,
        });

        await callback();

        this.setState({
          loading: false,
          error: undefined,
        });

        if (everyMs) {
          // Re-execute after given time (as if it is the first call again)
          this.props.setTimeout &&
            this.props.setTimeout(() => {
              this.execute(callback, 0, everyMs);
            }, everyMs);
        }
      } catch (e) {
        if (warning || debug) {
          console.warn(`Executing callback (${retryCount}) failed with exception: ${e}`);
        }

        this.setState({
          loading: false,
          error: e,
        });

        // Retry (depending on retryCount)
        this.props.setTimeout &&
          this.props.setTimeout(() => {
            this.execute(callback, retryCount + 1, everyMs);
          }, this.retryPeriodInMs(retryCount));
      }
    };

    private retryPeriodInMs = (retryCount: number): number => {
      return retryCount * 500 + 100;
    };

    componentDidCatch(error: any, errorInfo: any) {
      if (warning || debug) {
        console.warn(`__WithRefresh.componentDidCatch '${error}': ${errorInfo}`);
      }
    }

    componentDidMount() {
      this.refreshPermissions();
    }

    componentDidUpdate() {
      this.refreshPermissions();
    }

    async refreshPermissions() {
      let result = await this.props.permissionCache.updatePermissions(this.state.activePermissionUrls);
      if (result.getUpdated()) {
        this.setState({ permissionCacheId: result.getId() });
      }
    }

    componentWillUnmount() {
      this.unmount = true;
      this.state.subscriptions.forEach(async (id) => {
        if (debug) {
          console.log(`Unsubscribing from id='${id}' ...`);
        }
        try {
          await this.props.eventSubscriptionManager.unsubscribe(id);
          if (debug) {
            console.log(`Unsubscribed from id='${id}' ...`);
          }
        } catch (e) {
          if (warning || debug) {
            console.log(`Unsubscribing from id='${id}' failed with exception: ${e}`);
          }
        }
      });
    }

    render(): JSX.Element {
      // render the wrapped component, passing the props and state
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          subscribe={this.subscribe}
          subscribeUrl={this.subscribeUrl}
          unsubscribe={this.unsubscribe}
          refreshWithTimer={this.refreshWithTimer}
          refreshNow={this.refreshNow}
          refreshNowAll={this.refreshNowAll}
          clearError={this.clearError}
          hasPermissionByUrl={this.hasPermissionByUrl}
        />
      );
    }
  };

  return ReactTimeout(result);
};
