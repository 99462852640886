//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import styled from "@emotion/styled";
import history from "../history";

type PartialAnchor = Partial<HTMLAnchorElement>;

interface ITextLink extends PartialAnchor {
  onClick?: () => void;
  label?: string;
  children?: any;
  disabled?: boolean;
  href?: string;
}

const StyledLink = styled("a")`
  cursor: pointer;
`;

const StyledDisabledLink = styled("a")`
  cursor: not-allowed;
`;

export const TextLink = ({ ...args }: ITextLink) => {
  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    args.onClick && args.onClick();

    if (args.href) {
      history.push(args.href);
    }
    return false;
  };

  return args.disabled ? (
    <StyledDisabledLink className="text-link">
      {args.label}
      {args.children}
    </StyledDisabledLink>
  ) : (
    <StyledLink className="text-link" onClick={handleOnClick} href={args.href}>
      {args.label}
      {args.children}
    </StyledLink>
  );
};
