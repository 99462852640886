//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import React, { useEffect, useState } from "react";
import { Dropdown, Form, Menu, Table } from "semantic-ui-react";
import { isEmpty } from "lodash";
import moment from "moment";

import { Comment, ListCommentsRequest } from "../../api/comment/v1/icomment";
import { Loading } from "../../ui/_loading";
import { ContentSegment, SecondaryMenu } from "../../ui/_style";
import { PagingButtons, TextLink } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import UserCache, { UserWithDefinedID } from "../../util/UserCache";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { Routes } from "../../routes";

const ResourceTypes = {
  Organization: "Organization",
  Deployment: "Deployment",
  DataCluster: "DataCluster",
};

interface ICommentProps {
  currentUserId: string;
}

interface ResourceDetailInfo {
  link: string;
  resourceType: string;
  resourceID: string;
}

interface CommentItemProps {
  comment: Comment;
}

const getResourceDetails = (resourceURL: string): ResourceDetailInfo => {
  const resourceURLParts = (resourceURL || "").split("/");
  const resourceType = resourceURLParts[resourceURLParts.length - 2];
  const resourceID = resourceURLParts[resourceURLParts.length - 1];

  let link = "";
  switch (resourceType) {
    case ResourceTypes.Organization:
      link = Routes.dashboard_sales_organization_detailsWithId(resourceID);
      break;
    case ResourceTypes.DataCluster:
      link = Routes.dashboard_support_datacluster_detailsWithId(resourceID);
      break;
    case ResourceTypes.Deployment:
      link = Routes.dashboard_support_deployment_detailsWithId(resourceID);
      break;
    default:
      link = "";
  }
  return {
    link,
    resourceType,
    resourceID,
  };
};

const CommentItem = ({ comment }: CommentItemProps) => {
  const [user, setUser] = useState<UserWithDefinedID>();
  const [loading, setLoading] = useState(true);

  const getUser = async () => {
    const { created_by_id: userID = "" } = comment;
    const cache = new UserCache();
    setLoading(true);
    const user = await cache.getUser(userID);
    setLoading(false);
    setUser(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  const { link, resourceType, resourceID } = getResourceDetails(comment.resource_url || ``);

  return (
    <Table.Row>
      <Table.Cell>{comment.comment}</Table.Cell>
      <Table.Cell>
        <div>{resourceType}</div>
        <TextLink label={resourceID} href={link} />
      </Table.Cell>
      <Table.Cell>
        <DateTimePopupWithUTCAndLocalTime dateTime={comment.created_at} />
      </Table.Cell>
      <Table.Cell>{loading ? `Fetching user..` : (user || {}).name || "Unknown"}</Table.Cell>
    </Table.Row>
  );
};

// used to reset pagination when filter is changed / initialized
const defaultPaginationOption = {
  page: 0,
  page_size: 10,
};

const CommentsRoute = (props: ICommentProps) => {
  const [loadingComments, setLoadingComments] = useState(false);
  const [listCommentRequest, setListCommentRequest] = useState<ListCommentsRequest>({
    options: defaultPaginationOption,
  });
  const [comments, setComments] = useState<Comment[]>([]);

  useEffect(() => {
    let isMounted = true;
    async function getComments() {
      try {
        if (isMounted) {
          setLoadingComments(true);
        }

        const commentsList = await apiClients.idashboardClient.ListComments(listCommentRequest);

        if (isMounted) {
          setComments(commentsList.items || []);
          setLoadingComments(false);
        }
      } catch (err) {
        if (isMounted) {
          setLoadingComments(false);
        }
      }
    }

    getComments();

    return () => {
      isMounted = false;
    };
  }, [listCommentRequest]);

  const dateRangeStart = (listCommentRequest.data_range || {}).start || null;
  const dateRangeEnd = (listCommentRequest.data_range || {}).end || null;
  const dateRangeStartValue = dateRangeStart ? moment.utc(dateRangeStart).format("YYYY-MM-DD") : ``;
  const dateRangeEndValue = dateRangeEnd ? moment.utc(dateRangeEnd).format("YYYY-MM-DD") : ``;
  const resourceTypeFilterOptions = [
    { key: `all`, text: `All`, value: `all` },
    ...Object.values(ResourceTypes).map((item) => ({ key: item, text: item, value: item })),
  ];

  return (
    <ContentSegment>
      <SecondaryMenu>
        <Menu.Item header>Comments</Menu.Item>
      </SecondaryMenu>
      <Menu borderless pointing stackable>
        <Menu.Item header>Filter</Menu.Item>
        <Menu.Item>
          <Form.Checkbox
            name="mycomment"
            label="Show my comments"
            toggle
            checked={listCommentRequest.created_by_id === props.currentUserId}
            onChange={() => {
              setListCommentRequest({
                ...listCommentRequest,
                created_by_id: listCommentRequest.created_by_id === props.currentUserId ? undefined : props.currentUserId,
                options: defaultPaginationOption,
              });
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            item
            value={listCommentRequest.resource_type}
            text={`Resource Type: ${listCommentRequest.resource_type || "All"}`}
            options={resourceTypeFilterOptions}
            onChange={(event, { value }) => {
              setListCommentRequest({
                ...listCommentRequest,
                resource_type: `${value === `all` ? `` : value}`,
                options: defaultPaginationOption,
              });
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Form.Field>
            <label>Comments After</label>
            <div className="ui input">
              <input
                type="date"
                value={dateRangeStartValue}
                onChange={(event) => {
                  setListCommentRequest({
                    ...listCommentRequest,
                    data_range: {
                      ...(listCommentRequest.data_range || {}),
                      start: event.target.value ? moment.utc(event.target.value, "YYYY-MM-DD").startOf("day").toDate() : undefined,
                    },
                    options: defaultPaginationOption,
                  });
                }}
              />
            </div>
          </Form.Field>
        </Menu.Item>
        <Menu.Item>
          <Form.Field>
            <label>Comments Before</label>
            <div className="ui input">
              <input
                type="date"
                value={dateRangeEndValue}
                onChange={(event) => {
                  setListCommentRequest({
                    ...listCommentRequest,
                    data_range: {
                      ...(listCommentRequest.data_range || {}),
                      end: event.target.value ? moment.utc(event.target.value, "YYYY-MM-DD").endOf("day").toDate() : undefined,
                    },
                    options: defaultPaginationOption,
                  });
                }}
              />
            </div>
          </Form.Field>
        </Menu.Item>
        <Menu.Item position="right" fitted="vertically">
          <PagingButtons
            page={(listCommentRequest.options || {}).page || 0}
            pageSize={(listCommentRequest.options || {}).page_size || 10}
            count={comments.length}
            size="tiny"
            onNextPage={() => {
              setListCommentRequest({
                ...listCommentRequest,
                options: {
                  ...listCommentRequest.options,
                  page: ((listCommentRequest.options || {}).page || 0) + 1,
                },
              });
            }}
            onPreviousPage={() => {
              setListCommentRequest({
                ...listCommentRequest,
                options: {
                  ...listCommentRequest.options,
                  page: ((listCommentRequest.options || {}).page || 0) - 1,
                },
              });
            }}
          />
        </Menu.Item>
      </Menu>
      {loadingComments ? (
        <Loading />
      ) : (
        <>
          {isEmpty(comments) ? (
            <div>No comments found</div>
          ) : (
            <>
              <Table striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={10}>Comment</Table.HeaderCell>
                    <Table.HeaderCell>Resource Type</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                    <Table.HeaderCell>Created By</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {comments.map((comment) => (
                    <CommentItem key={comment.id} comment={comment} />
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </>
      )}
    </ContentSegment>
  );
};

export default CommentsRoute;
